import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type DeleteCommentByIdRequest, type DeleteCommentByIdResponse} from '../../../models/IComment';
import {deleteCommentById} from '../../../api/commentApi';

const deleteCommentByIdAction = createActionTemplateAxios<
DeleteCommentByIdResponse,
DeleteCommentByIdRequest
>('comments/deleteCommentById', deleteCommentById, 'userToken');

export default deleteCommentByIdAction;
