import React, {useEffect, useState} from 'react';
import {
	CardRegister,
	OtpCodeRegister,
	PhoneNumberRegistration,
} from '../../components';
import styles from './Register.module.css';
import logo from '../../assets/login/LogoAuth.png';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {useNavigate} from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import {getUserAction} from '../../redux/action/users';
import {getOrganizationAction} from '../../redux/action/organization';
import {type Department} from '../../models/Schemas';
import Loader from '../../components/Loader/Loader';
import {paths} from '../../paths';

type ComponentMap = Record<string, JSX.Element>;

export const Register = () => {
	const [currentComponent, setCurrentComponent] = useState<number>(
		parseInt(localStorage.getItem('currentComponent') ?? '1', 10),
	);

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [patronymic, setPatronymic] = useState('');
	const [dateBirth, setDateBirth] = useState<string | undefined>(undefined);
	const [department, setDepartment] = useState<Department | undefined>(
		undefined,
	);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [isAccess, setIsAccess] = useState(true);
	const [isLoadingUser, setIsLoadingUser] = useState(true);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const {isLoading, isError} = useAppSelector(state => state.refreshToken);

	const user = useAppSelector(state => state.getUser);
	const {status} = useAppSelector(state => state.organization);

	const nextComponent = () => {
		if (currentComponent < componentsCount) {
			localStorage.setItem(
				'currentComponent',
				(currentComponent + 1).toString(),
			);
			setCurrentComponent(currentComponent + 1);
		}
	};

	const previousComponent = () => {
		if (currentComponent > 1) {
			localStorage.setItem(
				'currentComponent',
				(currentComponent - 1).toString(),
			);
			setCurrentComponent(currentComponent - 1);
		}
	};

	useEffect(() => {
		if (user.isCompleted && user.user) {
			setIsLoadingUser(false);
			if (user.user.is_activated) {
				// Переадресация на главную если пользователь активирован
				navigate(paths.home);
			} else {
				// Установка начальных значений, если они есть.
				setName(user.user.first_name ?? '');
				setSurname(user.user.last_name ?? '');
				setPatronymic(user.user.middle_name ?? '');
				setPhoneNumber(user.user.phone_number ?? '');
				setDateBirth(user.user.birth_date);
				setDepartment(user.user.department);
			}
		}
	}, [user.isLoading]);

	useEffect(() => {
		void dispatch(getUserAction());
		void dispatch(getOrganizationAction());
	}, []);

	useEffect(() => {
		if (isError) {
			setIsAccess(false);
		}
	}, [isLoading]);

	const components: ComponentMap = {
		c1: (
			<CardRegister
				nextComponent={nextComponent}
				name={name}
				setName={setName}
				surname={surname}
				setSurname={setSurname}
				patronymic={patronymic}
				setPatronymic={setPatronymic}
				dateBirth={dateBirth}
				setDateBirth={setDateBirth}
				department={department}
				setDepartment={setDepartment}
			/>
		),
		c2: (
			<PhoneNumberRegistration
				nextComponent={nextComponent}
				previousComponent={previousComponent}
				phoneNumber={phoneNumber}
				setPhoneNumber={setPhoneNumber}
			/>
		),
		c3: (
			<OtpCodeRegister
				previousComponent={previousComponent}
				phone={phoneNumber}
			/>
		),
	};
	const componentsCount = Object.keys(components).length;

	const renderComponent = () => {
		const key = `c${currentComponent}`;
		return components[key];
	};

	if (isLoadingUser) {
		return (
			<div className={styles.container}>
				<Loader isLoading/>
			</div>
		);
	}

	if (status === 403) {
		return <NotFoundPage message='Доступ запрещен' status={403}/>;
	}

	return (
		<div className={styles.container}>
			<img className={styles['info-company']} src={logo} alt='' />
			{renderComponent()}
		</div>
	);
};
