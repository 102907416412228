import React from 'react';
import styles from './SearchReport.module.css';
import {FiSearch} from 'react-icons/fi';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {LinkUrl} from '../../../UiKit/Div/LinkUrl/LinkUrl';
import ReportBlockTemplate from '../ReportBlockTemplate';

export const SearchReport = () => {
	const registries = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.registries);
	const getColorText = (isWanted: boolean, result: string) => {
		let additionalClass = '';
		if (isWanted) {
			additionalClass = isWanted ? styles['text-red'] : styles['text-green'];
		}

		const className = `${styles.text} ${additionalClass}`;
		return <span className={className}>{result}</span>;
	};

	const getRegistryName = (value: string) => {
		if (value === 'terrorist_person') {
			return <span>В реестре террористов:</span>;
		}

		if (value === 'fns_disqualified') {
			return <span>В реестре дисквалифицированных лиц фнс:</span>;
		}

		if (value === 'minjust_person') {
			return <span>В реестре федерального розыска:</span>;
		}

		if (value === 'fsin_person') {
			return	<span>В реестре розыска фсин:</span>;
		}

		if (value === 'mvd_wanted') {
			return <span>В реестре розыска МВД:</span>;
		}

		return null;
	};

	return (
		<ReportBlockTemplate
			blocksId='registries'
			blockIcon={FiSearch}
			blockTitle='Реестры'
			alerts={registries?.additionalData.alerts}
			visible={registries && registries.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['report-group']}>
					{registries?.data?.map((info, index) => (
						<div className={styles['search-report']} key={index}>
							<div className={styles.info}>
								{getRegistryName(info.item.type)}
								<span>{getColorText(info.item.is_wanted, info.item.result)}</span>
							</div>
							{info.item.description && (
								<div className={styles.info}>
									<p>Описание:
										<span>{info.item.description}</span>
									</p>
								</div>
							)}
							{
								info.item.url_photo && (
									<div className={styles.info}>
										<p>Фотография:</p>
										<LinkUrl url={info.item.url_photo} />
									</div>
								)
							}
						</div>
					))}
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
