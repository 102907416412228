import React, {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import {type Department} from '../../../models/Schemas';
import styles from './DepartmentsTreeTable.module.css';
import commonTableStyles from '../CommonTableStyles.module.css';
import ModalAddDepartment from '../../Modal/ModalAddDepartment';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../paths';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {deleteDepartmentAction, getDepartmentSubordinateAction} from '../../../redux/action/department';
import {GreenBorderButton, TitleButton} from '../../UiKit/Button';
import {TableIconButton} from '../../UiKit/Button';
import {Input} from '../../UiKit/Inputs';
import {ModalConfirmation, ModalEditingEmployee, ModalEditingProfile} from '../../Modal';
import {deleteDepartmentResetState, filterDepartmentSubordinateTableData, type GetDepartmentSubordinateResponseEmployeeTableItem} from '../../../redux/slice/department';
import {type ColumnsProps, type ContextMenuItem, type RowData, type RowGroup, Table} from '../../UiKit/Tables';
import {UserIcon} from '../../UiKit/Div';
import {stringDateSorting} from '../../../utils/sorting/dateSorting';
import {Email, Phone} from '../../UiKit/Message';
import {iconSVGDelete, iconSVGEdit2, iconSVGEye, iconSVGSearch, iconSVGUser, iconSVGUserEdit} from '../../UiKit/Icons/SVG/IconSVG';
import {SelectDepartment} from '../../CardRegister/SelectDepartment';
import {checkPermission} from '../../../utils/permissions/permissions';
import {typePermissions} from '../../../models/Enum';
import ModalCheckCreated, {type CheckPermissions} from '../../Modal/ModalCheckCreated/ModalCheckCreated';
import {FiUser, FiEdit2, FiSearch} from 'react-icons/fi';
import {LiaUserEditSolid} from 'react-icons/lia';
import ModalEditContacts, {type EditContactsPermissions} from '../../Modal/ModalEditContacts/ModalEditContacts';

type DepartmentsTreeTableProps = {
	departments?: GetDepartmentSubordinateResponseEmployeeTableItem[];
	isLoading: boolean;
	departmentRead: boolean;
	departmentSubordinateRead: boolean;
	departmentUpdate: boolean;
	departmentSubordinateUpdate: boolean;
	departmentDelete: boolean;
	departmentSubordinateDelete: boolean;
	departmentCreate: boolean;
	departmentSubordinateCreate: boolean;
	userRead: boolean;
	userSubordinateRead: boolean;
	employeeRead: boolean;
	employeeSubordinateRead: boolean;
};

const DepartmentsTreeTable: React.FC<DepartmentsTreeTableProps> = ({...props}) => {
	const [globalFilter, setGlobalFilter] = useState<string>('');
	const checkedPermissions = useRef<CheckPermissions>({});
	const editContactsPermissions = useRef<EditContactsPermissions>({
		isCreate: false,
		isDelete: false,
		isUpdate: false,
	});

	// Для сотрудника
	const [isEditContactOpen, setIsEditContactOpen] = useState(false);
	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
	const [isOpenCheckCreated, setIsOpenCheckCreated] = useState(false);
	const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | undefined>(undefined);
	const [isEditEmployeeOpen, setIsEditEmployeeOpen] = useState(false);

	// Для отдела
	const [selectDepartment, setSelectDepartment] = useState<Department>();
	const [isOpenEditDepartment, setIsOpenEditDepartment] = useState(false);
	const [isAddDepartment, setIsAddDepartment] = useState(false);
	const [isOpenDeleteDepartment, setIsOpenDeleteDepartment] = useState(false);
	const [showValidationDeleteDepartment, setShowValidationDeleteDepartment] = useState(false);
	const [currentSelectedDepartment, setCurrentSelectedDepartment] = useState<Department>();
	const [isValidCurrentSelectedDepartment, setIsValidCurrentSelectedDepartment] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const putEmployee = useAppSelector(state => state.putEmployee);
	const putContact = useAppSelector(state => state.putContact);
	const postContact = useAppSelector(state => state.postContact);
	const deleteContact = useAppSelector(state => state.deleteContact);

	const myEmployeeId = useAppSelector(state => state.getUser.user?.employee_id);
	const myDepartment = useAppSelector(state => state.getUser.user?.department);
	const getDepartmentSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const putDepartment = useAppSelector(state => state.postDepartment);
	const postDepartment = useAppSelector(state => state.putDepartment);

	const deleteDepartmentState = useAppSelector(state => state.deleteDepartment);
	const {groupPermissions} = useAppSelector(state => state.getPermissions);

	// Перейти в профиль
	const openProfile = (id?: number) => {
		if (typeof id === 'number') {
			if (myEmployeeId === id) {
				navigate(paths.profile);
			} else {
				navigate(paths.profileUser.replace(':id', id.toString()));
			}
		}
	};

	// Открыть изменение контактов
	const openEditContact = (id: number, permissions: EditContactsPermissions) => {
		setIsEditContactOpen(true);
		setSelectedEmployeeId(id);
		editContactsPermissions.current = permissions;
	};

	// Открыть окно создания проверки
	const openCreateCheck = (id: number, permissions: CheckPermissions) => {
		setIsOpenCheckCreated(true);
		setSelectedEmployeeId(id);
		checkedPermissions.current = permissions;
	};

	// Открыть изменение профиля
	const openEditProfile = (id: number) => {
		if (myEmployeeId === id) {
			setIsEditProfileOpen(true);
		} else {
			setIsEditEmployeeOpen(true);
			setSelectedEmployeeId(id);
		}
	};

	// Открытие модального окна обновления отдела
	const openEditDepartment = (department: Department) => {
		setIsAddDepartment(false);
		setSelectDepartment(department);
		setIsOpenEditDepartment(true);
	};

	// Открытие модального окна добавления отдела
	const openAddDepartment = (department?: Department) => {
		setIsAddDepartment(true);
		setSelectDepartment(department ?? myDepartment);
		setIsOpenEditDepartment(true);
	};

	// Открытие страницы просмотра отдела
	const openDepartmentPage = (id: number) => {
		navigate(paths.departmentPositions.replace(':id', id.toString()));
	};

	// Удаление отдела
	const deleteDepartment = () => {
		if (!showValidationDeleteDepartment) {
			setShowValidationDeleteDepartment(true);
		}

		if (selectDepartment && currentSelectedDepartment) {
			void dispatch(
				deleteDepartmentAction({
					body: {target_department_id: currentSelectedDepartment.id},
					departmentId: selectDepartment.id,
				}),
			);
		}
	};

	// Открытие модального окна удаления отдела
	const openDeleteDepartment = (department: Department) => {
		setSelectDepartment(department);
		setIsOpenDeleteDepartment(true);
		if (showValidationDeleteDepartment) {
			setShowValidationDeleteDepartment(false);
		}
	};

	// Столбцы таблицы
	const columns = useMemo<ColumnsProps[]>(() => [
		{
			caption: 'Название',
			dataField: 'department.name',
			groupIndex: 0,
			groupCellRender(rowData) {
				const items = rowData.row.isExpanded ? rowData.row.data?.items : rowData.row.data?.collapsedItems;
				if (items && items.length > 0) {
					const {department, employeeCount} = (items[0] as GetDepartmentSubordinateResponseEmployeeTableItem);
					const isMyDepartment = department.id === myDepartment?.id;

					return (
						<div className={styles['row-group']}>
							<div className={styles['row-group-start']}>
								<div className={styles.department}>
									<span>{department.name}</span>
									<div className={styles.counter}>
										{employeeCount}
									</div>
								</div>

								<div className={styles.separator}></div>

								{department.director && (
									<div className={styles.employee}>
										<UserIcon
											size='small'
											label={department.director.first_name[0]}
											round
										/>
										<span
											onClick={() => {
												if (isMyDepartment ? props.employeeRead : props.employeeSubordinateRead) {
													openProfile(department.director?.id);
												}
											}}
											className={department.director?.user_id ? styles['director-full-name'] : undefined}
										>
											{department.director.full_name}
										</span>
										<span className={styles['director-position-name']}>
											Руководитель
										</span>
									</div>
								)}
							</div>
							<div className={styles['row-group-end']}>
								<TitleButton
									onClick={() => {
										navigate(paths.departmentPositions.replace(':id', department.id.toString()));
									}}
									text='Перейти в отдел'
								/>
							</div>
						</div>
					);
				}
			},
		},
		{
			caption: 'Сотрудник',
			dataField: 'employee.full_name',
			cellRender(rowData) {
				const {employee, department} = rowData.row.data as GetDepartmentSubordinateResponseEmployeeTableItem;

				if (employee) {
					const isMyDepartment = department.id === myDepartment?.id;

					return (
						<div className={styles.employee}>
							<UserIcon
								size='small'
								label={employee.first_name[0]}
								round
							/>
							<span
								onClick={() => {
									if (isMyDepartment ? props.employeeRead : props.employeeSubordinateRead) {
										openProfile(employee.id);
									}
								}}
								className={`${commonTableStyles['column-text']} ${employee.user_id ? styles['director-full-name'] : ''}`}
							>
								{employee.full_name}
							</span>
						</div>
					);
				}
			},
		},
		{
			caption: 'Должность',
			dataField: 'employee.department_position.name',
		},
		{
			caption: 'Дата рождения',
			dataField: 'employee.birth_date',
			sortingMethod: stringDateSorting,
		},
		{
			caption: 'Электронный адрес',
			dataField: 'employeeEmail',
			cellRender(rowData) {
				const {employeeEmail} = rowData.row.data as GetDepartmentSubordinateResponseEmployeeTableItem;
				if (employeeEmail) {
					return <Email email={employeeEmail} />;
				}
			},
		},
		{
			caption: 'Телефон',
			dataField: 'employeePhone',
			cellRender(rowData) {
				const {employeePhone} = rowData.row.data as GetDepartmentSubordinateResponseEmployeeTableItem;
				if (employeePhone) {
					return <Phone phone={employeePhone} formatPhone />;
				}
			},
		},
		{
			width: 120,
			type: 'buttons',
			allowSorting: false,
			cellRender(rowData) {
				const {department, employee} = rowData.row.data as GetDepartmentSubordinateResponseEmployeeTableItem;

				if (employee) {
					const parametersCheckPermission = {
						groupPermissions,
						isMyDepartment: department.id === myDepartment?.id,
						isMe: myEmployeeId === employee?.id,
					};

					const isUpdate = checkPermission(
						employee.user_id ? typePermissions.user_update : typePermissions.employee_update,
						parametersCheckPermission,
					);
					const isRead = checkPermission(
						employee.user_id
							? typePermissions.user_read : typePermissions.employee_read,
						parametersCheckPermission,
					);

					const isCheckRequestCreate = checkPermission(
						typePermissions.create_check_request,
						parametersCheckPermission,
					);
					const isZeroReportCreate = checkPermission(
						typePermissions.zero_report_create,
						parametersCheckPermission,
					);

					const employeeRead = checkPermission(
						typePermissions.employee_read,
						parametersCheckPermission,
					);
					const contactDelete = checkPermission(
						typePermissions.contact_delete,
						parametersCheckPermission,
					);
					const contactUpdate = checkPermission(
						typePermissions.contact_update,
						parametersCheckPermission,
					);
					const contactCreate = checkPermission(
						typePermissions.contact_create,
						parametersCheckPermission,
					);

					const checkStart = checkPermission(typePermissions.check_start, parametersCheckPermission);

					const isEditContacts = employeeRead && (contactDelete || contactUpdate || contactCreate);
					const isCheck = isUpdate && (isZeroReportCreate || isCheckRequestCreate);
					const permissions: CheckPermissions = {
						isCreateCheckRequest: isCheckRequestCreate,
						isZeroReportCreate,
						isStartCheck: checkStart,
					};

					return (
						<div className={commonTableStyles['column-action']}>
							{isRead && (
								<TableIconButton
									onClick={() => {
										openProfile(employee.id);
									}}
									Icon={FiUser}
									TooltipMessage='Перейти в профиль'
								/>
							)}
							{isUpdate && isRead && (
								<TableIconButton
									onClick={() => {
										openEditProfile(employee.id);
									}}
									Icon={LiaUserEditSolid}
									TooltipMessage='Изменить данные профиля'
								/>
							)}
							{isEditContacts && (
								<TableIconButton
									onClick={() => {
										openEditContact(
											employee.id,
											{
												isDelete: contactDelete,
												isUpdate: contactUpdate,
												isCreate: contactCreate,
											},
										);
									}}
									Icon={FiEdit2}
									TooltipMessage='Изменить контактные данные'
								/>
							)}
							{isCheck && (
								<TableIconButton
									onClick={() => {
										openCreateCheck(employee.id, permissions);
									}}
									Icon={FiSearch}
									TooltipMessage='Создать заявку на проверку'
								/>
							)}
						</div>
					);
				}
			},
		},
	], [myEmployeeId, myDepartment, props]);

	// Контекстное меню для сотрудника
	const contextMenuDataItems = useCallback<(e: RowData<GetDepartmentSubordinateResponseEmployeeTableItem>) => ContextMenuItem[]>(e => {
		const {department, employee} = e.row.data;

		if (employee) {
			const parametersCheckPermission = {
				groupPermissions,
				isMyDepartment: department.id === myDepartment?.id,
				isMe: myEmployeeId === employee?.id,
			};

			const isUpdate = checkPermission(
				employee.user_id ? typePermissions.user_update : typePermissions.employee_update,
				parametersCheckPermission,
			);
			const isRead = checkPermission(
				employee.user_id
					? typePermissions.user_read : typePermissions.employee_read,
				parametersCheckPermission,
			);

			const isCheckRequestCreate = checkPermission(
				typePermissions.create_check_request,
				parametersCheckPermission,
			);
			const isZeroReportCreate = checkPermission(
				typePermissions.zero_report_create,
				parametersCheckPermission,
			);

			const employeeRead = checkPermission(
				typePermissions.employee_read,
				parametersCheckPermission,
			);
			const contactDelete = checkPermission(
				typePermissions.contact_delete,
				parametersCheckPermission,
			);
			const contactUpdate = checkPermission(
				typePermissions.contact_update,
				parametersCheckPermission,
			);
			const contactCreate = checkPermission(
				typePermissions.contact_create,
				parametersCheckPermission,
			);

			const checkStart = checkPermission(typePermissions.check_start, parametersCheckPermission);

			const isEditContacts = employeeRead && (contactDelete || contactUpdate || contactCreate);
			const isCheck = isUpdate && (isZeroReportCreate || isCheckRequestCreate);
			const permissions: CheckPermissions = {
				isCreateCheckRequest: isCheckRequestCreate,
				isZeroReportCreate,
				isStartCheck: checkStart,
			};

			return ([
				{
					text: 'Перейти в профиль',
					icon: iconSVGUser,
					onItemClick() {
						openProfile(employee.id);
					},
					visibility: isRead,
				},
				{
					text: 'Изменить данные профиля',
					icon: iconSVGUserEdit,
					onItemClick() {
						openEditProfile(employee.id);
					},
					visibility: isUpdate && isRead,
				},
				{
					text: 'Изменить контактные данные',
					icon: iconSVGEdit2,
					onItemClick() {
						openEditContact(
							employee.id,
							{
								isDelete: contactDelete,
								isUpdate: contactUpdate,
								isCreate: contactCreate,
							},
						);
					},
					visibility: isEditContacts,
				},
				{
					text: 'Создать заявку на проверку',
					icon: iconSVGSearch,
					onItemClick() {
						openCreateCheck(employee.id, permissions);
					},
					visibility: isCheck,
				},
			]);
		}

		return [];
	}, [myEmployeeId, myEmployeeId]);

	// Контекстное меню для отдела
	const contextMenuGroupItems = useCallback<(e: RowGroup<GetDepartmentSubordinateResponseEmployeeTableItem>) => ContextMenuItem[]>(e => {
		const data = e.row?.isExpanded ? e.row.data?.items : e.row.data?.collapsedItems;

		if (data && data.length > 0) {
			const {department} = data[0];
			const isMyDepartment = department.id === myDepartment?.id;

			return [
				{
					text: 'Изменить информацию о отделе',
					icon: iconSVGEdit2,
					visibility: isMyDepartment ? props.departmentUpdate : props.departmentSubordinateUpdate,
					onItemClick() {
						openEditDepartment(department);
					},
				},
				{
					text: 'Открыть страницу отдела',
					icon: iconSVGEye,
					visibility: isMyDepartment ? props.departmentRead : props.departmentSubordinateRead,
					onItemClick() {
						openDepartmentPage(department.id);
					},
				},
				{
					text: 'Удалить отдел',
					icon: iconSVGDelete,
					visibility: isMyDepartment ? props.departmentDelete : props.departmentSubordinateDelete,
					onItemClick() {
						openDeleteDepartment(department);
					},
				},
			];
		}

		return [];
	}, [myDepartment, props]);

	// Закрыть модальное окно после удаления отдела
	useEffect(() => {
		if (
			deleteDepartmentState.responseStatus.isCompleted
			&& deleteDepartmentState.response
			&& selectDepartment !== undefined
		) {
			setIsOpenDeleteDepartment(false);
			dispatch(deleteDepartmentResetState());
			void dispatch(getDepartmentSubordinateAction());
		}
	}, [deleteDepartmentState.responseStatus.isLoading]);

	// Очистка состояний после закрытия окна удаления
	useEffect(() => {
		if (!isOpenDeleteDepartment) {
			setSelectDepartment(undefined);
			setCurrentSelectedDepartment(undefined);
		}
	}, [isOpenDeleteDepartment]);

	// Обновление отделов
	useEffect(() => {
		const loadingStatuses = [
			putDepartment.responseStatus.isLoading,
			postDepartment.responseStatus.isLoading,
			putContact.responseStatus.isLoading,
			postContact.responseStatus.isLoading,
			deleteContact.responseStatus.isLoading,
			putEmployee.responseStatus.isLoading,
		];

		const completedStatuses = [
			putDepartment.responseStatus.isCompleted,
			postDepartment.responseStatus.isCompleted,
			putContact.responseStatus.isCompleted,
			postContact.responseStatus.isCompleted,
			deleteContact.responseStatus.isCompleted,
			putEmployee.responseStatus.isCompleted,
		];

		const isLoading = loadingStatuses.some(status => status);
		const isCompleted = completedStatuses.some(status => status);

		if (!isLoading && isCompleted && !getDepartmentSubordinate.responseStatus.isLoading) {
			void dispatch(getDepartmentSubordinateAction());
		}
	}, [
		putDepartment.responseStatus.isLoading,
		postDepartment.responseStatus.isLoading,
		putContact.responseStatus.isLoading,
		postContact.responseStatus.isLoading,
		deleteContact.responseStatus.isLoading,
		putEmployee.responseStatus.isLoading,
	]);

	useEffect(() => {
		dispatch(filterDepartmentSubordinateTableData(globalFilter));
	}, [globalFilter]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles['header-left']}>
						<span className='h1-text'>Подчиненные отделы</span>
					</div>
					<div className={styles['header-right']}>
						<Input
							value={globalFilter}
							setValue={setGlobalFilter}
							inputStyles={{
								placeholder: 'Поиск в таблице',
							}}
							inputButtons={{
								searchButton: true,
							}}
						/>
						{props.departmentCreate && <GreenBorderButton
							onClick={() => {
								openAddDepartment();
							}}
							text={'Создать отдел'}
						/>}
					</div>
				</div>
				<div className={styles.table}>
					<Table
						columns={columns}
						dataGridProps={{
							dataSource: props.departments,
							allowColumnResizing: true,
							hoverStateEnabled: true,
							activeStateEnabled: true,
						}}
						getContextMenuDataItems={contextMenuDataItems}
						getContextMenuGroupItems={contextMenuGroupItems}
					/>
					<ModalConfirmation
						visible={isOpenDeleteDepartment}
						setVisible={setIsOpenDeleteDepartment}
						confirmation={deleteDepartment}
						confirmationText='Удалить'
						cancelText='Отмена'
						ConfirmationByInput={selectDepartment?.name}
						isError={deleteDepartmentState.responseStatus.isError}
						errorMessage={deleteDepartmentState.responseStatus.errorMessage}
						isLoading={deleteDepartmentState.responseStatus.isLoading}
					>
						<SelectDepartment
							selectedItem={currentSelectedDepartment}
							setSelectedItem={setCurrentSelectedDepartment}
							departmentOptions={getDepartmentSubordinate.response?.filter(
								item => item.id !== selectDepartment?.id,
							)}
							visible={isOpenDeleteDepartment}
							placeholder='Выберите отдел'
							isRequired
							isValid={isValidCurrentSelectedDepartment}
							setSelectedDepartmentIsValid={setIsValidCurrentSelectedDepartment}
							showValidation={showValidationDeleteDepartment}
						/>
					</ModalConfirmation>
					<ModalAddDepartment
						visible={isOpenEditDepartment}
						setVisible={setIsOpenEditDepartment}
						departmentId={isAddDepartment ? undefined : selectDepartment?.id}
						parentDepartment={isAddDepartment ? selectDepartment : undefined}
					/>
					<ModalEditingProfile
						visible={isEditProfileOpen}
						setVisible={setIsEditProfileOpen}
					/>
					<ModalEditContacts
						visible={isEditContactOpen}
						setVisible={setIsEditContactOpen}
						employeeId={selectedEmployeeId}
						permissions={editContactsPermissions}
						permissionsClean
					/>
					<ModalEditingEmployee
						visible={isEditEmployeeOpen}
						setVisible={setIsEditEmployeeOpen}
						employeeId={selectedEmployeeId}
					/>
					<ModalCheckCreated
						visible={isOpenCheckCreated}
						setVisible={setIsOpenCheckCreated}
						employeeId={selectedEmployeeId}
						permissions={checkedPermissions}
					/>
				</div>
			</div>
		</>
	);
};

export default DepartmentsTreeTable;
