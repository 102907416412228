import React, {useEffect, useRef, useState} from 'react';
import styles from './AlertMessage.module.css';
import {type Alert} from '../../../../report/reportModelV3/reportModelV3';
import {IoMdAlert} from 'react-icons/io';
import {TooltipTemplate} from '..';

type AlertMessageProps = {
	alerts: Alert[] | undefined;
	showTooltip?: boolean;
};

const AlertMessage: React.FC<AlertMessageProps> = ({
	alerts,
	showTooltip = true,
}) => {
	const [maxAlertLevel, setMaxAlertLevel] = useState(0);
	const [visible, setVisible] = useState(false);
	const allerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (alerts && alerts.length > 0) {
			setVisible(true);

			for (const alert of alerts) {
				if (alert.level === 2) {
					setMaxAlertLevel(2);
					break;
				}

				if (alert.level === 1) {
					setMaxAlertLevel(1);
				}
			}
		} else {
			setVisible(false);
		}
	}, [alerts]);

	return (
		<div
			style={{visibility: visible ? 'visible' : 'hidden'}}
			ref={allerRef}
			className={styles.content}
		>
			<IoMdAlert
				className={`${styles.icon} ${styles[`level-${maxAlertLevel}`]}`}
			/>
			<TooltipTemplate
				targetRef={allerRef}
				showTooltip={showTooltip}
			>
				<div className={styles['alert-list']}>
					{alerts?.map((item, index) => (
						<div className={`${styles['alert-row']} ${styles[`level-${item.level}`]}`} key={index}>
							<span className={styles['alert-dot']} />
							<p className={styles['alert-text']}>{item.message}</p>
						</div>
					))}
				</div>
			</TooltipTemplate>
		</div>
	);
};

export default AlertMessage;
