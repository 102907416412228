import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {
	type DeleteContactRequest,
	type DeleteContactResponse,
} from '../../../models/IContacts';
import {deleteContact} from '../../../api/contactsApi';

const deleteContactAction = createActionTemplateAxios<
DeleteContactResponse,
DeleteContactRequest
>('contacts/deleteContact/action', deleteContact, 'userToken');

export default deleteContactAction;
