import React, {useEffect} from 'react';
import styles from '../ModalReport.module.css';
import ModalWindow from '../../../ModalWindow';
import {CopyToClipboardButton} from '../../../../UiKit/Button';
import {possibleNameFields} from './possibleNameFields';
import {FaMobileAlt} from 'react-icons/fa';
import {Phone} from '../../../../UiKit/Message';
import {type CheckNicknamesFromContacts} from '../../../../../report/ReportModel';
import {
	ModalSelectCheckButton,
} from '../../../../UiKit/Button/ReportButtons/ModalSelectCheckButton/ModalSelectCheckButton';

type ModalPossibleNameProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	setVisibleSubModal: (value: boolean) => void;
	contact: CheckNicknamesFromContacts | undefined;
	containerId: string | undefined;
};

export const ModalPossibleName: React.FC<ModalPossibleNameProps> = ({
	contact,
	visible,
	setVisible,
	setVisibleSubModal,
	containerId,
}) => {
	const openSubModal = () => {
		setVisibleSubModal(true);
	};

	useEffect(() => {
		setVisibleSubModal(false);
	}, [visible]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			header={
				<div className={styles.title}>
					<FaMobileAlt/>
					<span>Номер телефона</span>
				</div>
			}
			footerItems={[
				<ModalSelectCheckButton
					setVisible={setVisible}
					openSubModal={openSubModal}
					containerId={containerId}
					key='modalSelectCheckButton'
				/>,
			]}
			footerItemsLocation='after'
			closeOnOutsideClick
			width='min(800px, 90%)'
		>
			<div className={styles.wrapper}>
				{contact && (
					<div className={styles.content}>
						{possibleNameFields.map((field, idx) => {
							const contactField = contact[field.key];
							if (contactField) {
								return (
									<React.Fragment key={idx}>
										<span className={styles.abbreviation}>
											{field.label}:
										</span>
										<div className={styles['result-container']}>
											{field.key === 'phone'
												? (
													<>
														<Phone phone={contactField} formatPhone/>
														{field.clipboard
                                                            && <CopyToClipboardButton text={contactField} format='phone'/>}
													</>
												)
												: (
													<>
														<span className={styles.result}>
															{contactField}
														</span>
														{field.clipboard
                                                            && <CopyToClipboardButton text={contactField}/>}
													</>
												)
											}
										</div>
									</React.Fragment>
								);
							}

							return null;
						})}
					</div>
				)}
			</div>
		</ModalWindow>
	);
};
