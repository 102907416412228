import {postInteractiveGo} from '../../../api/interactive';
import {type PostInteractiveGoResponse, type PostInteractiveGoRequest} from '../../../models/IInteractive';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postInteractiveGoAction = createActionTemplateAxios<
PostInteractiveGoResponse,
PostInteractiveGoRequest
>('Interactive/postInteractiveGo/action', postInteractiveGo, 'userToken');

export default postInteractiveGoAction;
