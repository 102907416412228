import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {
	type DeleteCommentByIdRequest, type DeleteCommentByIdResponse,
	type GetCommentByIdRequest,
	type GetCommentByIdResponse,
	type PostCommentRequest,
	type PostCommentResponse, type PutCommentRequest, type PutCommentResponse,
} from '../models/IComment';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/comment';

// Получение комментариев в проверки
export async function getCommentById(data: GetCommentByIdRequest): Promise<AxiosResponse<GetCommentByIdResponse>> {
	return axios.get(`${domain}${urlPrefix}/check/${data.check_id}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Создание комментария в отчете
export async function postComment(data: PostCommentRequest): Promise<AxiosResponse<PostCommentResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Обновление комментария в отчете
export async function putComment(data: PutCommentRequest): Promise<AxiosResponse<PutCommentResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.comment_id}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Удаление комментария в отчете
export async function deleteCommentById(data: DeleteCommentByIdRequest): Promise<AxiosResponse<DeleteCommentByIdResponse>> {
	return axios.delete(`${domain}${urlPrefix}/${data.comment_id}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
