import React, {useMemo} from 'react';
import styles from './ChecksTable.module.css';
import {type Check} from '../../../models/Schemas';
import {type CheckPermissions} from '../../Modal/ModalCheckCreated/ModalCheckCreated';
import {typePermissions} from '../../../models/Enum';
import {useAppSelector} from '../../../redux/redux-hooks';
import {type ProcessedChecksResponse} from '../../../redux/slice/checks';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {ChipStatus, ActionsCheck} from '../../UiKit/Chips';
import {IoMdCheckmark} from 'react-icons/io';
import {PiWarningOctagonLight} from 'react-icons/pi';
import {MdOutlineDoNotDisturbOn} from 'react-icons/md';
import {stringDateSorting} from '../../../utils/sorting/dateSorting';

type ValueType = ProcessedChecksResponse;

type ChecksTableProps = {
	value: ValueType[];
	openModalStartCheck: (check: Check, checkPermissions: CheckPermissions) => void;
	checkPermission: (permissionType: string, isMe: boolean, isMyDepartment: boolean) => boolean;
};

const statusCheck = (status: string) => {
	if (status === 'in_progress') {
		return (
			<ChipStatus text='На проверке' type='blue' />
		);
	}

	if (status === 'verified') {
		return (
			<ChipStatus text='Проверен' type='green' />
		);
	}

	if (status === 'collect') {
		return (
			<ChipStatus text='Сбор данных' type='orange' />
		);
	}

	if (status === 'wait') {
		return (
			<ChipStatus text='Ожидает проверки' type='violet' />
		);
	}
};

const criticalFactors = (indicator: number | undefined) => {
	if (indicator === 0) {
		return <IoMdCheckmark className={`${styles.factor} ${styles.green}`} />;
	}

	if (indicator === 1) {
		return <PiWarningOctagonLight className={`${styles.factor} ${styles.yellow}`} />;
	}

	if (indicator === 2) {
		return <MdOutlineDoNotDisturbOn className={`${styles.factor} ${styles.red}`} />;
	}

	return null;
};

export const ChecksTable: React.FC<ChecksTableProps> = ({
	value,
	openModalStartCheck,
	checkPermission,
}) => {
	const meId = useAppSelector(state => state.getUser.user?.id);
	const myDepartmentId = useAppSelector(state => state.getUser.user?.department?.id);

	const columns = useMemo<ColumnsProps[]>(() => [
		{
			caption: 'Название заявки',
			dataField: 'name',
		},
		{
			caption: 'Создал(а) заявку',
			dataField: 'authorFullName',
		},
		{
			caption: 'Дата заявки',
			dataField: 'check_created_at',
			sortingMethod: stringDateSorting,
		},
		{
			caption: 'Дата проверки',
			dataField: 'report_created_at',
			sortingMethod: stringDateSorting,
		},
		{
			caption: 'Статус',
			dataField: 'status',
			cellRender(rowData) {
				const check = rowData.row.data as ProcessedChecksResponse;
				return statusCheck(check.status);
			},
		},
		{
			caption: 'Крит. факторы',
			dataField: 'critical_factor',
			alignment: 'left',
			cellRender(rowData) {
				const check = rowData.row.data as ProcessedChecksResponse;
				return criticalFactors(check.criticalFactor);
			},
		},
		{
			cellRender(rowData) {
				const check = rowData.row.data as ProcessedChecksResponse;
				const isMe = check.author_id === meId;
				const isMyDepartment = check.author.department?.id === myDepartmentId;
				const isUpdateCheck = checkPermission(typePermissions.check_update, isMe, isMyDepartment);
				const isStartCheck = checkPermission(typePermissions.check_start, isMe, isMyDepartment);
				const isZeroReportUpdate = checkPermission(typePermissions.zero_report_update, isMe, isMyDepartment);

				return (
					<div className={styles.comment}>
						<ActionsCheck
							status={check.status}
							isCard={false}
							check={check}
							openModalStartCheck={openModalStartCheck}
							checkPermissions={{
								isUpdateCheck,
								isStartCheck,
								isZeroReportUpdate,
							}}
						/>
					</div>
				);
			},
		},
	], [meId, myDepartmentId]);

	return (
		<Table
			columns={columns}
			dataGridProps={{
				dataSource: value,
				allowColumnResizing: true,
			}}
		/>
	);
};
