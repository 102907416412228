import React from 'react';
import styles from './CompanyItem.module.css';
import {type NavigationCompany} from '../../../../../../models/Navigation/Navigation';
import socratLogo from '../../../../../../assets/logo/socratLogo.svg';

type CompanyFieldProps = {
	company: NavigationCompany;
};

const CompanyItem: React.FC<CompanyFieldProps> = ({
	company,
}) => (
	<div
		className={styles.container}
	>
		<img src={socratLogo} className={styles.logo} />
		<span className={styles.name}>{company.name_short}</span>
	</div>
);

export default CompanyItem;
