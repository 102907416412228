import React, {useState, useEffect} from 'react';
import styles from './ModalEditingCandidate.module.css';
import ModalWindow from '../ModalWindow';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {
	getCandidateAction,
	putCandidateAction,
} from '../../../redux/action/candidates';
import {
	getChallengerAction,
	putChallengerAction,
} from '../../../redux/action/challengers';
import {ErrorMessage} from '../../UiKit/Message';
import {statusCandidate} from './StatusCandidate';
import {Dropdown, InputName} from '../../UiKit/Inputs';

type ModalEditingCandidateProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	candidateId: number | undefined;
	positionId: number | undefined;
	isEditCandidate: React.MutableRefObject<boolean>;
	isEditChallenger: React.MutableRefObject<boolean>;
};
export const ModalEditingCandidate: React.FC<ModalEditingCandidateProps> = ({
	visible,
	setVisible,
	candidateId,
	positionId,
	isEditCandidate,
	isEditChallenger,
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [name, setName] = useState('');
	const [isValidName, setIsValidName] = useState(false);
	const [lastName, setLastName] = useState('');
	const [isValidLastName, setIsValidLastName] = useState(false);
	const [middleName, setMiddleName] = useState('');
	const [isValidMiddleName, setIsValidMiddleName] = useState(false);
	const [status, setStatus] = useState<string | undefined>(undefined);
	const [isValidStatus, setIsValidStatus] = useState(false);

	const putCandidate = useAppSelector(state => state.putCandidate);

	const putChallenger = useAppSelector(state => state.putChallenger);

	const getCandidate = useAppSelector(state => state.getCandidate);

	const getChallenger = useAppSelector(state => state.getChallenger);

	const dispatch = useAppDispatch();

	const handleCancel = () => {
		setVisible(false);
	};

	const handleSave = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isEditCandidate.current && candidateId && isValidLastName && isValidMiddleName && isValidName) {
			void dispatch(
				putCandidateAction({
					body: {
						first_name: name,
						last_name: lastName,
						middle_name: middleName ?? null,
						recommender_id: undefined,
					},
					candidateId,
				}),
			);
		}

		if (isEditChallenger.current && isValidStatus && status && getChallenger.response) {
			void dispatch(
				putChallengerAction({
					body: {status},
					challenger_id: getChallenger.response.id,
				}),
			);
		}
	};

	useEffect(() => {
		if (visible) {
			if (candidateId && positionId) {
				if (isEditCandidate.current) {
					void dispatch(getCandidateAction({candidateId}));
				}

				if (isEditChallenger.current) {
					void dispatch(
						getChallengerAction({
							body: {candidate_id: candidateId, position_id: positionId},
						}),
					);
				}
			}
		} else {
			setName('');
			setLastName('');
			setMiddleName('');
			setStatus(undefined);
			setIsValidLastName(false);
			setIsValidMiddleName(false);
			setIsValidName(false);
			setIsValidStatus(false);
			setShowValidation(false);
		}
	}, [visible]);

	useEffect(() => {
		if (getChallenger.responseStatus.isCompleted && getChallenger.response) {
			const {status} = getChallenger.response;
			setStatus(statusCandidate.filter(item => item.label === status)[0].data);
		}
	}, [getChallenger.responseStatus.isLoading]);

	useEffect(() => {
		if (getCandidate.responseStatus.isCompleted && getCandidate.response) {
			const {response} = getCandidate;
			setName(response.first_name);
			setLastName(response.last_name);
			setMiddleName(response.middle_name ?? '');
		}
	}, [getCandidate.responseStatus.isLoading]);

	useEffect(() => {
		if (
			(putChallenger.responseStatus.isCompleted || !isEditChallenger.current)
      && (putCandidate.responseStatus.isCompleted || !isEditCandidate.current)
		) {
			setVisible(false);
		}
	}, [
		putChallenger.responseStatus.isLoading,
		putCandidate.responseStatus.isLoading,
	]);

	return (
		<ModalWindow
			width='50%'
			visible={visible}
			setVisible={setVisible}
			closable
			header={(
				<div className={styles.title}>
					<h1>Редактирование кандидата</h1>
					<span>Для редактирования кандидата введите необходимые данные</span>
				</div>
			)}
			footerItems={[
				<ButtonCancel key='ButtonCancel' text='Отменить' onclickHandler={handleCancel} />,
				<ButtonConfirm key='ButtonConfirm' text='Сохранить' onclickHandler={handleSave} />,
			]}
			isLoading={
				getChallenger.responseStatus.isLoading
        || getCandidate.responseStatus.isLoading
        || putChallenger.responseStatus.isLoading
        || putCandidate.responseStatus.isLoading
			}
		>
			<div className={styles.content}>
				<div className={styles['input-group']}>
					{isEditCandidate.current && <>
						<span>Личные данные кандидата</span>
						<div></div>
						<InputName
							value={lastName}
							setValue={setLastName}
							title='Фамилия'
							inputStyles={{
								placeholder: 'Фамилия кандидата',
							}}
							inputValidation={{
								isValid: isValidLastName,
								setIsValid: setIsValidLastName,
								isRequired: true,
								minLength: 2,
								showValidation,
								visible,
							}}
							parts={['PATRONYMIC']}
						/>
						<InputName
							value={name}
							setValue={setName}
							title='Имя'
							inputStyles={{
								placeholder: 'Имя кандидата',
							}}
							inputValidation={{
								isValid: isValidName,
								setIsValid: setIsValidName,
								isRequired: true,
								minLength: 2,
								showValidation,
								visible,
							}}
							parts={['NAME']}
						/>
						<InputName
							value={middleName}
							setValue={setMiddleName}
							title='Отчество'
							inputStyles={{
								placeholder: 'Отчество кандидата',
							}}
							inputValidation={{
								isValid: isValidMiddleName,
								setIsValid: setIsValidMiddleName,
								minLength: 2,
								showValidation,
								visible,
							}}
							parts={['PATRONYMIC']}
						/>
					</>}
					{isEditChallenger.current
					&& <>
						<span>Описание</span>
						<div></div>
						<Dropdown
							selectedValue={status}
							setSelectedValue={setStatus}
							options={statusCandidate}
							displayValueKey='label'
							setValueKey='data'
							placeholder='Статус'
							emptyMessage='Не найдено'
							title='Статус'
							isRequired
							setIsValid={setIsValidStatus}
							isValid={isValidStatus}
							showValidation={showValidation}
							visible={visible}
						/>
					</>
					}
				</div>

				<ErrorMessage
					message={putCandidate.responseStatus.errorMessage}
					isError={putCandidate.responseStatus.isError}
				/>
				<ErrorMessage
					message={putChallenger.responseStatus.errorMessage}
					isError={putChallenger.responseStatus.isError}
				/>
				<ErrorMessage
					message={getChallenger.responseStatus.errorMessage}
					isError={getChallenger.responseStatus.isError}
				/>
			</div>
		</ModalWindow>
	);
};
