import {type InputButton} from './InputsTypes';

export const emailButton: InputButton = {
	name: 'email',
	options: {
		icon: 'email',
		stylingMode: 'text',
		disabled: true,
	},
	location: 'before',
};

export const searchButton: InputButton = {
	name: 'search',
	options: {
		icon: `<svg
			stroke="var(--input-icon-color)"
			fill="none"
			stroke-width="2"
			viewBox="0 0 24 24"
			stroke-linecap="round"
			stroke-linejoin="round"
			height="18px"
			width="18px"
			xmlns="http://www.w3.org/2000/svg"
		>
		<circle cx="11" cy="11" r="8"></circle>
		<line x1="21" y1="21" x2="16.65" y2="16.65"></line>
		</svg>
		`,
		stylingMode: 'text',
		disabled: true,
	},
	location: 'before',
};

export const userButton: InputButton = {
	name: 'user',
	options: {
		icon: `<svg
			stroke="var(--input-icon-color)"
			fill="none"
			stroke-width="2"
			viewBox="0 0 24 24"
			aria-hidden="true"
			height="18px"
			width="18px"
			xmlns="http://www.w3.org/2000/svg"
		>
		<path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
		</svg>`,
		stylingMode: 'text',
		disabled: true,
	},
	location: 'before',
};

export const calendarButton: InputButton = {
	name: 'calendar',
	options: {
		icon: `
		<svg
			stroke="var(--input-icon-color)"
			fill="none"
			stroke-width="2"
			viewBox="0 0 24 24"
			aria-hidden="true"
			height="18px"
			width="18px"
			xmlns="http://www.w3.org/2000/svg"
		>
		<path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
		</svg>`,
		stylingMode: 'text',
		disabled: true,
	},
	location: 'before',
};

export const FactCheckButton: InputButton = {
	name: 'FactCheck',
	options: {
		icon: `<svg
			stroke="var(--input-icon-color)"
			fill="var(--input-icon-color)"
			stroke-width="0"
			viewBox="0 0 24 24"
			height="18px"
			width="18px"
			xmlns="http://www.w3.org/2000/svg"
		>
		<path fill="none" d="M0 0h24v24H0z"></path>
		<g fill-rule="evenodd">
			<path d="M20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H4V5h16v14z"></path>
			<path d="M19.41 10.42 17.99 9l-3.17 3.17-1.41-1.42L12 12.16 14.82 15zM5 7h5v2H5zM5 11h5v2H5zM5 15h5v2H5z"></path>
		</g>
		</svg>`,
		stylingMode: 'text',
		disabled: true,
	},
	location: 'before',
};

export const BriefcaseButton: InputButton = {
	name: 'Briefcase',
	options: {
		icon: `<svg
			stroke="var(--input-icon-color)"
			fill="none"
			stroke-width="2"
			viewBox="0 0 24 24"
			aria-hidden="true"
			height="18px"
			width="18px"
			xmlns="http://www.w3.org/2000/svg"
		>
		<path stroke-linecap="round" stroke-linejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
		</svg>`,
		stylingMode: 'text',
		disabled: true,
	},
	location: 'before',
};
