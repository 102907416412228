import React, {type ReactNode, useRef} from 'react';
import styles from './ButtonTemplate.module.css';
import {type IconType} from 'react-icons';
import Loader from '../../../Loader/Loader';
import {type TooltipPosition, TooltipTemplate} from '../../Message';

export type ButtonTemplateProps = {
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	text?: string;
	title?: string;
	type?: 'submit' | 'reset' | 'button';
	role?: React.AriaRole;
	className?: string;
	styleType?: ButtonTemplateStyleType;
	borderRadius?: ButtonTemplateBorderRadius;
	width?: 'min(240px, 100%)';
	color?: ButtonTemplateColor;
	textStyle?: string;
	Icon?: IconType;
	iconStyleType?: ButtonTemplateIconType;
	TooltipMessage?: string;
	TooltipPosition?: TooltipPosition;
	disable?: boolean;
	isLoading?: boolean;
	children?: ReactNode;
};

export type ButtonTemplateIconType = 'small' | 'medium';
export type ButtonTemplateStyleType = (
	'block'
	| 'large'
	| 'small'
	| 'medium'
	| 'medium-large'
	| 'long'
	| 'auto'
	| 'small-table'
	| 'icon'
	| 'icon-bg'
	| 'ellipse-button'
);
export type ButtonTemplateColor = (
	'primary'
	| 'primary-transparent'
	| 'primary-outline'
	| 'primary-disabled'
	| 'green-fill'
	| 'green-outline'
	| 'red'
	| 'asphalt-transparent'
	| 'dark-blue'
	| 'gray'
);
export type ButtonTemplateBorderRadius = 'unset' | '8px' | '16px' | '29px' | '100%';

const ButtonTemplate: React.FC<ButtonTemplateProps> = ({
	onClick,
	text,
	type = 'button',
	role = 'button',
	className = '',
	styleType = 'auto',
	borderRadius = '8px',
	width,
	color = 'primary',
	textStyle,
	iconStyleType,
	Icon,
	TooltipMessage,
	TooltipPosition = 'top',
	disable,
	isLoading = false,
	children,
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	return (
		<button
			type={type}
			className={`${styles.template} ${className} ${styles[styleType]} ${styles[color]} ${isLoading && styles.loading}`}
			onClick={e => {
				if (onClick) {
					e.stopPropagation();
					onClick(e);
				}
			}}
			style={{borderRadius, width}}
			disabled={isLoading || disable}
			role={role}
			ref={buttonRef}
		>
			<div className={styles['button-content']}>
				{text && <span className={textStyle}>{text}</span>}
				{Icon && <Icon className={iconStyleType && styles[`icon-${iconStyleType}`]} />}
				{children}
			</div>
			<Loader isLoading={isLoading} isFloating borderRadius={borderRadius} />
			<TooltipTemplate
				targetRef={buttonRef}
				position={TooltipPosition}
			>
				{TooltipMessage && <span>{TooltipMessage}</span>}
			</TooltipTemplate>
		</button>
	);
};

export default ButtonTemplate;
