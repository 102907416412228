import React, {useEffect, useState} from 'react';
import {notifyMessage} from '../../Toast/notifyMessage';
import {MdContentCopy} from 'react-icons/md';
import {formatPhoneNumber} from '../../../../utils';
import {ButtonTemplate} from '..';

type CopyToClipboardProps = {
	text: string | number;
	format?: 'phone';
};

const CopyToClipboardButton: React.FC<CopyToClipboardProps> = ({text, format}) => {
	const [textFormat, setTextFormat] = useState('');

	useEffect(() => {
		if (typeof text === 'number') {
			text = text.toString();
		}

		if (format === 'phone') {
			setTextFormat(formatPhoneNumber(text));
		} else {
			setTextFormat(text);
		}
	}, [text]);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(textFormat)
			.then(() => {
				notifyMessage('info', 'Информация скопирована в буфер обмена');
			})
			.catch(() => {
				notifyMessage('error', 'Не удалось скопировать информацию');
			});
	};

	return (
		<ButtonTemplate
			onClick={copyToClipboard}
			iconStyleType='medium'
			Icon={MdContentCopy}
			color={'primary-transparent'}
			styleType='icon'
		/>
	);
};

export default CopyToClipboardButton;
