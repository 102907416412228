import React from 'react';
import styles from './ResolutionStatus.module.css';
import {useAppSelector} from '../../../redux/redux-hooks';
import {HiOutlineCheckCircle} from 'react-icons/hi';
import {IoMdCloseCircleOutline} from 'react-icons/io';

const ResolutionStatus = () => {
	const processedResponse = useAppSelector(state => state.getCheckReportById.processedResponse);

	if (typeof processedResponse?.resolution_result === 'boolean') {
		const result = processedResponse?.resolution_result;
		const comment = processedResponse?.resolution_comment;

		return (
			<div
				className={styles.container}
				data-result={result}
			>
				<div className={styles.icon}>
					{result ? <HiOutlineCheckCircle /> : <IoMdCloseCircleOutline />}
				</div>
				<div className={styles.result}>
					{result
						? 'Кандидат согласован службой безопасности'
						: 'Кандидат не рекомендуется к трудоустройству'
					}
				</div>
				{comment && <div className={styles.comment}>
					{comment}
				</div>}
			</div>
		);
	}

	return null;
};

export default ResolutionStatus;
