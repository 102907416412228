import React from 'react';
import {CardSettings} from '../../components';
import styles from './Settings.module.css';
import {ButtonCancel, ButtonConfirm} from '../../components/UiKit/Button';

export const Settings = () => (
	<div className={styles.content}>
		<span className='h1-text'>Настройки</span>
		<CardSettings />
	</div>
);
