import {postCreatePhone} from '../../../api/checkApi';
import {type PostCheckCreatePhoneResponse, type PostCheckCreatePhoneRequest} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckCreatePhoneAction = createActionTemplateAxios<
PostCheckCreatePhoneResponse,
PostCheckCreatePhoneRequest>(
	'checks/postCreatePhone/action',
	postCreatePhone,
	'userToken',
);

export default postCheckCreatePhoneAction;
