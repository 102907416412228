import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type PutCommentRequest, type PutCommentResponse} from '../../../models/IComment';
import {putComment} from '../../../api/commentApi';

const putCommentAction = createActionTemplateAxios<
PutCommentResponse,
PutCommentRequest
>('comments/putComment', putComment, 'userToken');

export default putCommentAction;
