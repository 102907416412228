import React from 'react';
import styles from '../BasicInfo.module.css';
import {type PersonV3} from '../../../../report/reportModelV3/reportModelV3';
import {HiOutlineUser} from 'react-icons/hi';
import {IoMdCard} from 'react-icons/io';
import {IoCalendarOutline} from 'react-icons/io5';
import {RiWallet3Line} from 'react-icons/ri';
import {calculateAge} from '../../../../utils/calculateAge';
import {getAgeString} from '../../../../utils/getAgeString';
import PersonPhoto from './PersonPhoto/PersonPhoto';
import PersonBirthPlace from './PersonBirthPlace/PersonBirthPlace';
import {getFullNameFromObject} from '../../../../utils';

type PersonBasicInfProps = {
	person: PersonV3;
};

const PersonBasicInf: React.FC<PersonBasicInfProps> = ({
	person,
}) => {
	const fullName = getFullNameFromObject(person.name, 'LFM');

	return (
		<div className={styles['data-group']}>
			<PersonPhoto person={person} />
			<div className={styles.data}>
				{fullName && (
					<>
						<div className={styles.info}>
							<HiOutlineUser className={styles.icon} />
							<span className={styles.abbreviation}>
								ФИО:
							</span>
						</div>
						<span className={styles.result}>
							{fullName}
						</span>
					</>
				)}
				{person?.birth_date && (
					<>
						<div className={styles.info}>
							<IoCalendarOutline className={styles.icon} />
							<span className={styles.abbreviation}>
								Дата рождения:
							</span>
						</div>
						<span className={styles.result}>
							{person.birth_date} ({getAgeString(calculateAge(person.birth_date))})
						</span>
					</>
				)}
				<PersonBirthPlace />
				{person?.inn && (
					<>
						<div className={styles.info}>
							<RiWallet3Line className={styles.icon} />
							<span className={styles.abbreviation}>
								ИНН:
							</span>
						</div>
						<span className={styles.result}>
							{person.inn}
						</span>
					</>
				)}
				{person?.snils && (
					<>
						<div className={styles.info}>
							<IoMdCard className={styles.icon} />
							<span className={styles.abbreviation}>
								СНИЛС:
							</span>
						</div>
						<span className={styles.result}>
							{person.snils}
						</span>
					</>
				)}
			</div>
		</div>
	);
};

export default PersonBasicInf;
