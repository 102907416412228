import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type postNotificationsResponse} from '../../../models/INotifications';
import {postNotificationsAction} from '../../action/notifications';

type PostNotificationsSliceState = {
	responseStatus: ResponseStatus;
	response: postNotificationsResponse | undefined;
};

const initialState: PostNotificationsSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postNotificationsSlice
	= createSlice({
		name: 'notifications/postNotifications',
		initialState,
		reducers: {
			postNotificationsResetState: () => initialState,
			postNotificationsResetStatus(state) {
				state.responseStatus = initialState.responseStatus;
				return state;
			},
		},
		extraReducers(builder) {
			builder
				.addCase(postNotificationsAction.pending, state => {
					state.responseStatus.isLoading = true;
					state.responseStatus.isCompleted = false;
					state.responseStatus.isError = false;
				})
				.addCase(postNotificationsAction.fulfilled, (state, action) => {
					state.responseStatus.isLoading = false;
					state.responseStatus.isCompleted = true;
					state.responseStatus.isError = false;
					state.response = action.payload;
				})
				.addCase(postNotificationsAction.rejected, (state, action) => {
					state.responseStatus.isLoading = false;
					state.responseStatus.isCompleted = false;
					state.responseStatus.isError = true;
					state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
					state.responseStatus.status = action.payload?.status;
				});
		},
	});

export const {postNotificationsResetState, postNotificationsResetStatus} = postNotificationsSlice.actions;

export default postNotificationsSlice.reducer;
