import {
	type PutDepartmentResponse,
	type PutDepartmentRequest,
} from '../../../models/IDepartment';
import {putDepartment} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {updateDepartmentSubordinate} from '../../slice/department';

const putDepartmentAction = createActionTemplateAxios<
PutDepartmentResponse,
PutDepartmentRequest>(
	'department/putDepartment/action',
	putDepartment,
	'userToken',
	updateDepartmentSubordinate,
);

export default putDepartmentAction;
