import React, {useRef} from 'react';
import styles from './SelectNavigationCompany.module.css';
import {DropDownBox, List} from 'devextreme-react';
import {useAppDispatch, useAppSelector} from '../../../../../redux/redux-hooks';
import CompanyField from './CompanyField/CompanyField';
import CompanyItem from './CompanyItem/CompanyItem';
import {type NavigationCompany} from '../../../../../models/Navigation/Navigation';
import {getNavigationCompaniesAction} from '../../../../../redux/action/navigation';
import Loader from '../../../../Loader/Loader';

type SelectNavigationCompanyPops = {
	selectedCompany: NavigationCompany | undefined;
	setSelectedCompany: (item: NavigationCompany) => void;
};

const SelectNavigationCompany: React.FC<SelectNavigationCompanyPops> = ({
	selectedCompany,
	setSelectedCompany,
}) => {
	const dispatch = useAppDispatch();

	const dropDownBoxRef = useRef<DropDownBox>(null);
	const getNavigationCompanies = useAppSelector(state => state.getNavigationCompanies);

	const onCompanyClick = (item: NavigationCompany | undefined) => {
		dropDownBoxRef.current?.instance.close();

		if (item) {
			setSelectedCompany(item);
		}
	};

	const itemRender = (company: NavigationCompany) => (
		<CompanyItem
			company={company}
		/>
	);

	const fieldRender = () => (
		<CompanyField
			selectedCompany={selectedCompany}
		/>
	);

	const contentRender = () => (
		<>
			<List
				dataSource={getNavigationCompanies.response}
				itemRender={itemRender}
				scrollingEnabled
				noDataText='Копании не найдены'
				onItemClick={e => {
					onCompanyClick(e.itemData);
				}}
				className={styles['company-list']}
				focusStateEnabled={false}
			/>
			<Loader
				isLoading={getNavigationCompanies.responseStatus.isLoading}
				isFloating
			/>
		</>
	);

	const onOpened = () => {
		void dispatch(getNavigationCompaniesAction());
	};

	return (
		<DropDownBox
			ref={dropDownBoxRef}
			fieldRender={fieldRender}
			items={getNavigationCompanies.response}
			displayExpr='name_short'
			stylingMode='underlined'
			contentRender={contentRender}
			dropDownOptions={{
				wrapperAttr: {
					class: styles['dropdown-wrapper'],
				},
				width: 248,
				position: {
					my: 'left top',
					at: 'left bottom',
					of: '#dropdownBoxNavigation',
					offset: {
						x: -16,
						y: 8,
					},
				},
			}}
			onOpened={onOpened}
			id='dropdownBoxNavigation'
		/>
	);
};

export default SelectNavigationCompany;
