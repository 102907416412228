const cookiesKeys = {
	debug: 'debug',
	navCompanyHash: 'navigation_company_hash',
	navDepartmentHash: 'navigation_department_hash',
	userToken: 'token',
	userTokenExpires: 'token_expires',
	userRefreshToken: 'refresh_token',
	userRefreshTokenExpires: 'refresh_token_expires',
	guestToken: 'token_guest',
	guestTokenExpiresDate: 'token_guest_expires_date',
};

export default cookiesKeys;
