import React, {type RefObject} from 'react';
import {
	ChartPieTemplate,
	type ChartTooltip,
} from '../../UiKit/Charts';
import {returnIfNumber} from '../../../utils/checkTypes/checkNumber';

type ChartChecksInDayProps = {
	checksReady?: number;
	checksNotReady?: number;
	checksCount?: number;
	containerRef?: RefObject<HTMLDivElement>;
};

const tooltip: ChartTooltip = {
	enabled: true,
};

const ChartStatusChecks: React.FC<ChartChecksInDayProps> = ({checksReady, checksNotReady, checksCount, containerRef}) => {
	const dataSource = [
		{
			count: returnIfNumber(checksReady, ''),
			title: 'Проверено',
		},
		{
			count: returnIfNumber(checksNotReady, ''),
			title: 'Не проверено',
		},
	];

	const centerRenderData = {
		content: `${returnIfNumber(checksReady, '')}/${returnIfNumber(checksNotReady, '')}`,
		label: 'Заявки',
	};

	return (
		<ChartPieTemplate
			dataSource={dataSource}
			valueField='count'
			argumentField={'title'}
			centerRenderData={centerRenderData}
			customizeLabel={i => String(i?.point?.argument ?? '')}
			containerRef={containerRef}
			tooltip={tooltip}
			height={230}
		/>
	);
};

export default ChartStatusChecks;
