import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putEmployee} from '../../../api/employeesApi';
import {
	type PutEmployeeRequest,
	type PutEmployeeResponse,
} from '../../../models/IEmployees';

const putEmployeeAction = createActionTemplateAxios<
PutEmployeeResponse,
PutEmployeeRequest
>('employees/putEmployee/action', putEmployee, 'userToken');

export default putEmployeeAction;
