import React from 'react';
import styles from './SummaryDataItem.module.css';
import {FaCheck} from 'react-icons/fa';
import {type SummaryDataStatus, type SummaryDataType} from '../../../../report/reportModelEnum';
import {type SummaryData} from '../../../../report/reportModelV3/reportModelV3';

type SummaryDataItemProps = {
	summaryData: SummaryData;
};

export const SummaryDataItem: React.FC<SummaryDataItemProps> = ({summaryData}) => {
	const getStatusIndicator = (status: SummaryDataStatus, type: SummaryDataType) => {
		if (type === 'point' && status === true) {
			return <span className={`${styles.dot} ${styles.green}`}></span>;
		}

		if (type === 'point' && status === false) {
			return <span className={`${styles.dot} ${styles.red}`}></span>;
		}

		if (type === 'point' && status === undefined) {
			return <span className={`${styles.dot} ${styles.orange}`}></span>;
		}

		if (type === 'checkbox' && status === true) {
			return (
				<span className={styles.checkbox}>
					<FaCheck className={styles['checkbox-checked']} />
				</span>
			);
		}

		if (type === 'checkbox' && status === false) {
			return (
				<span className={styles.checkbox}></span>
			);
		}

		if (type === 'checkbox' && status === undefined) {
			return <span className={`${styles.dot} ${styles.orange}`}></span>;
		}
	};

	if (summaryData.block_key) {
		return (
			<a href={`#${summaryData.block_key}`} className={`${styles.summary} ${styles.click}`}>
				<div className={styles.indicator}>{getStatusIndicator(summaryData.status, summaryData.type)}</div>
				<span>{summaryData.name}</span>
			</a>
		);
	}

	return (
		<div className={styles.summary}>
			<div className={styles.indicator}>{getStatusIndicator(summaryData.status, summaryData.type)}</div>
			<span>{summaryData.name}</span>
		</div>
	);
};
