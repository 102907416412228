/* eslint-disable @typescript-eslint/ban-types */
import {type GetCheckReportByIdResponse} from '../../models/ICheck';
import {
	type Block,
	type ArrayData,
	type ProcessedCheckReport,
	type DocumentsV3Block,
	type DocumentsBlock,
	type AddressBlock,
	type ContactsBlock,
	type ContactsEmailsV3Block,
	type ContactsPhonesV3Block,
	type TransportsBlock,
	type SocialAccountsBlock,
	type PersonsBlock,
} from '../ProcessedReport/ProcessedReportTypes';
import {
	type CheckTransports,
	type CheckContacts,
	type CheckAddresses,
	type CheckDocuments,
	type Source,
	type Field,
} from '../ReportModel';
import {blocksIds} from '../reportModelEnum';
import {
	processRegistry,
	processTransport,
	processCourtDecision,
	processDocument,
	processDocumentV3,
	processMicroloan,
	processSummaryData,
	processNameHistory,
} from './getProcessedCheckReportBlockData';
import {
	type ContainersList,
	type Alert,
} from './reportModelV3';

// Сырые данные
function getRawData(rawData: Source[]) {
	const sourceReport: Source[] = [];

	if (rawData && rawData.length > 0) {
		rawData.forEach((item, index) => {
			const fieldsReport: Field[] = [];

			item.fields.forEach(field => {
				if (field.translation) {
					fieldsReport.push({
						...field,
						translation: field.translation.concat(':'),
					});
				}
			});

			if (fieldsReport.length > 0) {
				sourceReport.push({
					...item,
					key: item.key.concat(index.toString()).replace(/[^a-zA-Zа-яА-ЯёЁ0-9_-]/g, '_'),
					fields: fieldsReport,
				});
			}
		});
	}

	return sourceReport;
}

// Блоки без контейнера
function getBlock<T>(
	data: T[] | undefined,
	dataProcessor?: (
		(item: T) => T
	) | (
		(item: T, index: number) => T
	)) {
	const block: Block<ArrayData<T>> = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};
	if (data && data.length > 0) {
		data.forEach((item, index) => (
			block.data.push(
				{
					item: dataProcessor ? dataProcessor(item, index) : item,
					additionalData: {
						alerts: [],
					},
				},
			)
		));
	}

	return block;
}

// Контейнеры
function getTransports(
	transports: CheckTransports[] | string[] | undefined | null,
	containers: ContainersList | undefined,
): [TransportsBlock, Alert[]] {
	const block: TransportsBlock = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};
	const mainAlerts: Alert[] = [];

	if (transports && transports.length > 0) {
		transports.forEach(transport => {
			if (typeof transport === 'string') {
				if (containers) {
					const container = containers[transport];
					if (container && container.entity_type === 'car') {
						container.additional_data.alerts.forEach(alert => {
							if (alert.block_id) {
								block.additionalData.alerts.push(alert);
							} else {
								mainAlerts.push(alert);
							}
						});
						block.data.push(
							{
								item: processTransport(container.entity_data),
								additionalData: {
									alerts: [],
								},
								containerId: container.container_id,
							},
						);
					}
				}
			} else {
				block.data.push(
					{
						item: processTransport(transport),
						additionalData: {
							alerts: [],
						},
					},
				);
			}
		});
	}

	return [block, mainAlerts];
}

function getContacts(
	contacts: CheckContacts[] | string[] | undefined | null,
	containers: ContainersList | undefined | null,
): [ContactsBlock, Alert[], ContactsEmailsV3Block, ContactsPhonesV3Block] {
	const block: ContactsBlock = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};

	const blockEmails: ContactsEmailsV3Block = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};

	const blockPhones: ContactsPhonesV3Block = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};

	const mainAlerts: Alert[] = [];

	if (contacts && contacts.length > 0) {
		contacts.forEach(contact => {
			if (typeof contact === 'string') {
				if (containers) {
					const container = containers[contact];
					if (container?.entity_type === 'phone') {
						mainAlerts.push(...container.additional_data.alerts.filter(item => !item.block_id));
						blockPhones.additionalData.alerts.push(...container.additional_data.alerts.filter(item => item.block_id));
						blockPhones.data.push(
							{
								item: container.entity_data,
								additionalData: {
									alerts: [],
								},
								containerId: container.container_id,
							},
						);
					} else if (container?.entity_type === 'email') {
						mainAlerts.push(...container.additional_data.alerts.filter(item => !item.block_id));
						blockEmails.additionalData.alerts.push(...container.additional_data.alerts.filter(item => item.block_id));
						blockEmails.data.push(
							{
								item: container.entity_data,
								additionalData: {
									alerts: [],
								},
								containerId: container.container_id,
							},
						);
					}
				}
			} else {
				block.data.push(
					{
						item: contact,
						additionalData: {
							alerts: [],
						},
					},
				);
			}
		});
	}

	return [block, mainAlerts, blockEmails, blockPhones];
}

function getAddress(
	addresses: CheckAddresses[] | string[] | undefined | null,
	containers: ContainersList | undefined,
): [AddressBlock, Alert[]] {
	const block: AddressBlock = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};

	const mainAlerts: Alert[] = [];

	if (addresses && addresses.length > 0) {
		addresses.forEach(address => {
			if (typeof address === 'string') {
				if (containers) {
					const container = containers[address];
					if (container?.entity_type === 'address') {
						container.additional_data.alerts.forEach(alert => {
							if (alert.block_id) {
								block.additionalData.alerts.push(alert);
							} else {
								mainAlerts.push(alert);
							}
						});
						block.data.push(
							{
								item: container.entity_data,
								additionalData: {
									alerts: [],
								},
								containerId: container.container_id,
							},
						);
					}
				}
			} else {
				block.data.push(
					{
						item: address,
						additionalData: {
							alerts: [],
						},
					},
				);
			}
		});
	}

	return [block, mainAlerts];
}

function getDocuments(
	documents: CheckDocuments[] | string[] | undefined | null,
	containers: ContainersList | undefined,
): [DocumentsBlock, Alert[], DocumentsV3Block] {
	const block: DocumentsBlock = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};

	const blockPassportV3: DocumentsV3Block = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};

	const mainAlerts: Alert[] = [];

	if (documents && documents.length > 0) {
		documents.forEach(document => {
			if (typeof document === 'string') {
				if (containers) {
					const container = containers[document];
					if (container && container.entity_type === 'passport_rf') {
						container.additional_data.alerts.forEach(alert => {
							if (alert.block_id) {
								blockPassportV3.additionalData.alerts.push(alert);
							} else {
								mainAlerts.push(alert);
							}
						});
						blockPassportV3.data.push(
							{
								item: processDocumentV3(container.entity_data),
								additionalData: {
									alerts: [],
								},
								containerId: container.container_id,
							},
						);
					}
				}
			} else {
				block.data.push(
					{
						item: processDocument(document),
						additionalData: {
							alerts: [],
						},
					},
				);
			}
		});
	}

	return [block, mainAlerts, blockPassportV3];
}

function getSocialAccounts(
	socialAccounts: string[] | undefined | null,
	containers: ContainersList | undefined | null,
): [SocialAccountsBlock, Alert[]] {
	const block: SocialAccountsBlock = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};
	const mainAlerts: Alert[] = [];

	if (socialAccounts && socialAccounts.length > 0 && containers) {
		socialAccounts.forEach(socialAccount => {
			if (typeof socialAccount === 'string') {
				const container = containers[socialAccount];

				if (container && container.entity_type === 'social_account') {
					container.additional_data.alerts.forEach(alert => {
						if (alert.block_id) {
							block.additionalData.alerts.push(alert);
						} else {
							mainAlerts.push(alert);
						}
					});
					block.data.push(
						{
							item: container.entity_data,
							additionalData: {
								alerts: [],
							},
							containerId: container.container_id,
						},
					);
				}
			}
		});
	}

	return [block, mainAlerts];
}

function getPersons(
	personIds: string[] | undefined | null,
	containers: ContainersList | undefined | null,
): [PersonsBlock, Alert[]] {
	const block: PersonsBlock = {
		data: [],
		additionalData: {
			alerts: [],
		},
	};
	const mainAlerts: Alert[] = [];

	if (personIds && personIds.length > 0 && containers) {
		personIds.forEach(personId => {
			if (typeof personId === 'string') {
				const container = containers[personId];

				if (container && container.entity_type === 'person') {
					container.additional_data.alerts.forEach(alert => {
						if (alert.block_id) {
							block.additionalData.alerts.push(alert);
						} else {
							mainAlerts.push(alert);
						}
					});
					block.data.push(
						{
							item: container.entity_data,
							additionalData: {
								alerts: [],
							},
							containerId: container.container_id,
						},
					);
				}
			}
		});
	}

	return [block, mainAlerts];
}

export function getProcessedCheckReportV3(response: GetCheckReportByIdResponse): ProcessedCheckReport {
	const {report_data} = response;
	const blocks = report_data?.blocks;
	const raw_data = report_data?.raw_data;

	const main_container = report_data?.containers[report_data?.main_container_id];

	let transportAlerts: Alert[] = [];

	const report: ProcessedCheckReport = {
		...response,
		report_data: {
			mainAlerts: [],
			mainInf: main_container,
			summary_data: processSummaryData(report_data?.summary_data),
		},
	};

	if (raw_data && raw_data.length > 0) {
		report.report_data.raw_data = getRawData(raw_data);
	}

	if (main_container?.entity_type === 'person') {
		report.report_data.accounts = getBlock(main_container.entity_data.accounts);
		report.report_data.advertisements = getBlock(main_container.entity_data.advertisements);
		report.report_data.court_decisions = getBlock(main_container.entity_data.court_decisions, processCourtDecision);
		report.report_data.workplaces = getBlock(main_container.entity_data.workplaces);
		report.report_data.microlans = getBlock(main_container.entity_data.microlans, processMicroloan);
		report.report_data.name_history = getBlock(main_container.entity_data.name_history, processNameHistory);
		report.report_data.nicknames = getBlock(main_container.entity_data.nicknames);
		report.report_data.public_groups = getBlock(main_container.entity_data.public_groups);
		report.report_data.registries = getBlock(main_container.entity_data.registries, processRegistry);

		// Место рождения
		report.report_data.birth_place = getAddress(main_container.entity_data.birth_place_ids, report_data?.containers)[0];

		// Транспорт
		const [
			transports,
			transportsAlert,
		] = getTransports(main_container.entity_data.transports, report_data?.containers);
		report.report_data.transports = transports;
		transportAlerts = transportsAlert; // Потом переместить в blocks

		if (!raw_data || raw_data.length === 0) {
			// Сырые данные
			report.report_data.raw_data = getRawData(main_container.entity_data.raw_data);
		}
	}

	// Контакты
	const [
		contacts,
		contactsAlert,
		contactsEmailV3,
		contactsPhoneV3,
	] = getContacts(blocks?.contacts, report_data?.containers);
	report.report_data.contacts = contacts;
	report.report_data.contactsEmailsV3 = contactsEmailV3;
	report.report_data.contactsPhonesV3 = contactsPhoneV3;

	// Документы
	const [
		documents,
		documentsAlert,
		documentsV3,
	] = getDocuments(blocks?.documents, report_data?.containers);
	report.report_data.documents = documents;
	report.report_data.documentsV3 = documentsV3;

	// Адреса
	const [
		address,
		addressAlert,
	] = getAddress(blocks?.addresses, report_data?.containers);
	report.report_data.address = address;

	// Социальные Аккаунты
	const [
		socialAccounts,
		socialAccountsAlert,
	] = getSocialAccounts(blocks?.accounts, report_data?.containers);
	report.report_data.socialAccounts = socialAccounts;

	// Персоны
	const [
		persons,
		personsAlert,
	] = getPersons(blocks?.persons, report_data?.containers);
	report.report_data.persons = persons;

	// Алерты основной сущности
	main_container?.additional_data.alerts.forEach(item => {
		const {block_id} = item;
		switch (block_id) {
			case blocksIds.address:
				report.report_data.address?.additionalData.alerts.push(item);
				break;
			case blocksIds.documents:
				report.report_data.documents?.additionalData.alerts.push(item);
				break;
			case blocksIds.accounts:
				report.report_data.accounts?.additionalData.alerts.push(item);
				break;
			case blocksIds.transports:
				report.report_data.transports?.additionalData.alerts.push(item);
				break;
			case blocksIds.contacts:
				report.report_data.contacts?.additionalData.alerts.push(item);
				break;
			case blocksIds.advertisements:
				report.report_data.advertisements?.additionalData.alerts.push(item);
				break;
			case blocksIds.courtDecisions:
				report.report_data.court_decisions?.additionalData.alerts.push(item);
				break;
			case blocksIds.workplaces:
				report.report_data.workplaces?.additionalData.alerts.push(item);
				break;
			case blocksIds.microlans:
				report.report_data.microlans?.additionalData.alerts.push(item);
				break;
			case blocksIds.nameHistory:
				report.report_data.name_history?.additionalData.alerts.push(item);
				break;
			case blocksIds.nicknames:
				report.report_data.nicknames?.additionalData.alerts.push(item);
				break;
			case blocksIds.publicGroups:
				report.report_data.public_groups?.additionalData.alerts.push(item);
				break;
			case blocksIds.registries:
				report.report_data.registries?.additionalData.alerts.push(item);
				break;
			case blocksIds.socialAccounts:
				report.report_data.socialAccounts?.additionalData.alerts.push(item);
				break;
			case blocksIds.persons:
				report.report_data.persons?.additionalData.alerts.push(item);
				break;
			default:
				report.report_data.mainAlerts.push(item);
		}
	});

	report.report_data.mainAlerts.push(
		...contactsAlert,
		...documentsAlert,
		...transportAlerts, // Изменить
		...addressAlert,
		...socialAccountsAlert,
		...personsAlert,
	);

	return report;
}
