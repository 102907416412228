import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../../models/IAuth';
import {type PutReportResolutionResponse} from '../../../../models/Report/Resolution/IResolution';
import {putReportResolutionAction} from '../../../action/report';

const initialState: {
	responseStatus: ResponseStatus;
	response: PutReportResolutionResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const putReportResolutionSlice = createSlice({
	name: 'report/resolution/putReportResolution',
	initialState,
	reducers: {
		putReportResolutionResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(putReportResolutionAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(putReportResolutionAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(putReportResolutionAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {putReportResolutionResetState} = putReportResolutionSlice.actions;
export default putReportResolutionSlice.reducer;
