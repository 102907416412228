import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetTokenPerson152fzResponse} from '../../../models/Guest/GuestToken/IPerson152fzToken';
import getTokenPerson152fzAction from '../../action/person/getTokenPerson152fzAction';
import {saveGuestToken} from '../../../utils/cookies/guestToken/saveGuestToken';
import {removeGuestToken} from '../../../utils/cookies/guestToken/removeGuestToken';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetTokenPerson152fzResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getTokenPerson152fzSlice = createSlice({
	name: 'person/getTokenPerson152fz',
	initialState,
	reducers: {
		getTokenPerson152fzResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getTokenPerson152fzAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getTokenPerson152fzAction.fulfilled, (state, action) => {
				saveGuestToken(action.payload);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(getTokenPerson152fzAction.rejected, (state, action) => {
				removeGuestToken();
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.requestId = action.meta.requestId;
			});
	},
});

export const {getTokenPerson152fzResetState} = getTokenPerson152fzSlice.actions;
export default getTokenPerson152fzSlice.reducer;
