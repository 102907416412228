import DataGrid, {Column, GroupPanel, Paging, Scrolling, SearchPanel} from 'devextreme-react/cjs/data-grid';
import React, {type ReactNode, useMemo, useRef} from 'react';
import styles from './Table.module.css';
import tableStyles from './../Table.module.css';
import Loader from '../../../Loader/Loader';
import {type ContextMenuItem, type RowGroup, type RowData} from '..';
import {type ContextMenuPreparingEvent, type RowClickEvent, type RowDblClickEvent} from 'devextreme/ui/data_grid';

export type TableProps = {
	columns: ColumnsProps[];
	headerCellRenderDefault?: (columnRowData: RowData) => React.ReactNode;
	cellRenderDefault?: (columnRowData: RowData) => React.ReactNode;
	groupCellRenderDefault?: (columnRowData: RowGroup) => React.ReactNode;
	groupPanel?: boolean;
	searchPanel?: boolean;
	dataGridProps: DataGridProps;
	pagingProps?: PagingProps;
	scrolling?: 'standard' | 'virtual' | 'infinite';
	height?: string;
	loadingMessage?: string;
	isLoading?: boolean;
	children?: ReactNode;
	maxHeight?: string;
	getContextMenuHederItems?: (e: RowData) => ContextMenuItem[];
	getContextMenuGroupItems?: (e: RowGroup) => ContextMenuItem[];
	getContextMenuDataItems?: (e: RowData) => ContextMenuItem[];
};

export type DataGridProps = {
	dataSource: any[] | undefined;
	allowColumnReordering?: boolean;
	allowColumnResizing?: boolean;
	columnAutoWidth?: boolean;
	hoverStateEnabled?: boolean;
	activeStateEnabled?: boolean;
	dateSerializationFormat?: string;
	onRowDblClick?: (e: RowDblClickEvent) => void;
	onRowClick?: (e: RowClickEvent) => void;
};

export type ColumnsProps = {
	alignment?: 'left' | 'right' | 'center';
	caption?: string;
	dataField?: string;
	groupIndex?: number;
	allowFiltering?: boolean;
	allowSorting?: boolean;
	fixed?: boolean;
	fixedPosition?: 'left' | 'right';
	width?: string | number;
	headerCellRender?: (columnRowData: RowData) => React.ReactNode;
	cellRender?: (columnRowData: RowData) => React.ReactNode;
	groupCellRender?: (columnRowData: RowGroup) => React.ReactNode;
	name?: string;
	type?: 'selection' | 'buttons' | 'adaptive' | 'detailExpand' | 'groupExpand' | 'drag';
	dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime';
	allowEditing?: boolean;
	sortingMethod?: (value1: string, value2: string) => number;
};

export type PagingProps = {
	enabled: boolean;
	defaultPageSize?: number;
};

const Table: React.FC<TableProps> = ({
	groupPanel = false,
	searchPanel = false,
	scrolling = 'virtual',
	height = '100%',
	maxHeight = 'unset',
	isLoading = false,
	loadingMessage = 'Загрузка...',
	pagingProps = {
		enabled: false,
	},
	dataGridProps,
	columns,
	children,
	getContextMenuHederItems,
	getContextMenuGroupItems,
	getContextMenuDataItems,
	...props
}) => {
	const dataGridRef = useRef<DataGrid>(null);

	const columnsList = useMemo(() => columns.map((column, index) => (<Column
		key={index}
		headerCellRender={column.headerCellRender ?? props.headerCellRenderDefault}
		cellRender={column.cellRender ?? props.cellRenderDefault}
		groupCellRender={column.groupCellRender ?? props.groupCellRenderDefault}
		{...column}
	/>))
	, [columns]);

	const onContextMenuPreparing = (e: ContextMenuPreparingEvent) => {
		const contextMenuItems: Record<string, ((e: any) => ContextMenuItem[]) | undefined> = {
			header: getContextMenuHederItems,
			group: getContextMenuGroupItems,
			data: getContextMenuDataItems,
		};

		const rowType = e.row?.rowType;

		if (rowType) {
			const getItems = contextMenuItems[rowType];
			if (getItems) {
				e.items = getItems(e);
			}
		}
	};

	return (
		<div className={tableStyles.wrapper}>
			<DataGrid
				ref={dataGridRef}
				height={height}
				className={`${tableStyles['table-dx']} ${styles['data-grid']}`}
				{...dataGridProps}
				style={{maxHeight}}
				onContextMenuPreparing={onContextMenuPreparing}
				columnMinWidth={50}
				loadPanel={{
					enabled: false,
				}}
			>
				<SearchPanel visible={searchPanel} />
				<Paging {...pagingProps}/>
				<Scrolling mode={scrolling} />
				<GroupPanel visible={groupPanel} />
				{children}
				{columnsList}
			</DataGrid>
			<Loader isFloating isLoading={isLoading} text={loadingMessage} />
		</div>
	);
};

export default Table;
