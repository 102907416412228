import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetCheckResponse} from '../../../models/ICheck';
import {getCheckAction} from '../../action/checks';
import {getFullName} from '../../../utils';
import {type Check} from '../../../models/Schemas';
import {DateTime} from 'luxon';
import {checkStatus} from '../../../models/Enum';

export type ProcessedChecksResponse = Check & {
	authorFullName: string;
	statusName: string;
	check_created_at: string;
	report_created_at: string;
	dateStringCheckCreateAt: string | undefined;
	dateStringReportCreateAt: string | undefined;
	criticalFactor: number | undefined;
	isAllowRestart: boolean;
};

export type ChecksMetrics = {
	countChecks: number;
	countUncheckedChecks: number;
	verifiedChecks: number;
};

export type filtersCheckResponse = {
	authorFullName?: string;
	checkName?: string;
	statusName?: string;
	checkCreatedAt: string[];
	reportCreatedAt: string[];
	verdict?: string;
};

type GetCheckSliceState = {
	responseStatus: ResponseStatus;
	response: GetCheckResponse | undefined;
	processedResponse: ProcessedChecksResponse[] | undefined;
	filteredResponse: ProcessedChecksResponse[] | undefined;
	checksMetrics: ChecksMetrics;
};

const initialState: GetCheckSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	filteredResponse: undefined,
	processedResponse: undefined,
	checksMetrics: {
		countChecks: 0,
		countUncheckedChecks: 0,
		verifiedChecks: 0,
	},
};

const getProcessedResponse = (response: GetCheckResponse) => {
	const now = DateTime.now();

	return response.map<ProcessedChecksResponse>(item => {
		const authorFullName = getFullName(
			item.author.first_name,
			item.author.middle_name,
			item.author.full_name_genitive?.split(' ')[0] ?? item.author.last_name,
			'LsFsM',
		);

		const dateStringCheckCreateAt = item.check_created_at?.split('T')[0] ?? undefined;
		const dateStringReportCreateAt = item.report_created_at?.split('T')[0] ?? undefined;

		const criticalFactor = item.critical_factor ?? undefined;

		let isAllowRestart = false;
		if (item.status === 'collect' || item.status === 'in_progress') {
			const dateDiff = item.check_created_at ? now.diff(DateTime.fromISO(item.check_created_at), ['minutes']).minutes : undefined;
			isAllowRestart = dateDiff !== undefined && dateDiff > 5;
		}

		const check_created_at = item.check_created_at
			? DateTime.fromISO(item.check_created_at).toLocaleString(DateTime.DATE_SHORT)
			: '-';
		const report_created_at = item.report_created_at
			? DateTime.fromISO(item.report_created_at).toLocaleString(DateTime.DATE_SHORT)
			: '-';
		const statusName = checkStatus[item.status];

		return ({
			...item,
			check_created_at,
			report_created_at,
			authorFullName,
			statusName,
			dateStringCheckCreateAt,
			dateStringReportCreateAt,
			criticalFactor,
			isAllowRestart,
		});
	});
};

const getChecksMetrics = (response: GetCheckResponse): ChecksMetrics => {
	const countChecks = response.length;
	const verifiedChecks = response.filter(item => item.status === 'verified').length;
	const countUncheckedChecks = countChecks - verifiedChecks;

	return {
		countChecks,
		verifiedChecks,
		countUncheckedChecks,
	};
};

const getCheckSlice = createSlice({
	name: 'check/getCheck',
	initialState,
	reducers: {
		filteredCheckResponse(state, action: PayloadAction<filtersCheckResponse>) {
			const {
				authorFullName,
				statusName,
				checkCreatedAt,
				checkName,
				reportCreatedAt,
				verdict,
			} = action.payload;

			const [checkCreatedAtFirst, checkCreatedAtSecond] = checkCreatedAt.map(item => item ? new Date(item) : undefined);
			const [reportCreatedAtFirst, reportCreatedAtSecond] = reportCreatedAt.map(item => item ? new Date(item) : undefined);

			// eslint-disable-next-line complexity
			state.filteredResponse = state.processedResponse?.filter(item => {
				const itemCheckCreatedAt = item.dateStringCheckCreateAt ? new Date(item.dateStringCheckCreateAt) : undefined;
				const itemReportCreatedAt = item.dateStringReportCreateAt ? new Date(item.dateStringReportCreateAt) : undefined;
				return (
					(!authorFullName || item.authorFullName === authorFullName)
				&& (!statusName || item.statusName === statusName)
				&& (!checkCreatedAtFirst || (
					itemCheckCreatedAt && itemCheckCreatedAt >= checkCreatedAtFirst
				))
				&& (!checkCreatedAtSecond || (
					itemCheckCreatedAt && itemCheckCreatedAt <= checkCreatedAtSecond
				))
				&& (!reportCreatedAtFirst || (
					itemReportCreatedAt && itemReportCreatedAt >= reportCreatedAtFirst
				))
				&& (!reportCreatedAtSecond || (
					itemReportCreatedAt && itemReportCreatedAt <= reportCreatedAtSecond
				))
				&& (!checkName || item.name === checkName)
				&& (!verdict || item.verdict === verdict)
				);
			});

			return state;
		},
		getCheckUpdateIsAllowRestart(state) {
			const now = DateTime.now();

			state.filteredResponse = state.filteredResponse?.map(item => {
				if (item.status === 'collect' || item.status === 'in_progress') {
					const createDate = item.dateStringCheckCreateAt ? DateTime.fromISO(item.dateStringCheckCreateAt) : undefined;
					if (createDate) {
						const dateDiff = now.diff(createDate, 'minutes').minutes;
						item.isAllowRestart = dateDiff > 5;
					}
				}

				return item;
			});
			state.processedResponse = state.processedResponse?.map(item => {
				if (item.status === 'collect' || item.status === 'in_progress') {
					const createDate = item.dateStringCheckCreateAt ? DateTime.fromISO(item.dateStringCheckCreateAt) : undefined;
					if (createDate) {
						const dateDiff = now.diff(createDate, 'minutes').minutes;
						item.isAllowRestart = dateDiff > 5;
					}
				}

				return item;
			});

			return state;
		},

	},
	extraReducers(builder) {
		builder
			.addCase(getCheckAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckAction.fulfilled, (state, action) => {
				state.response = action.payload;
				state.processedResponse = getProcessedResponse(action.payload);
				state.filteredResponse = state.processedResponse;
				state.checksMetrics = getChecksMetrics(action.payload);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {filteredCheckResponse, getCheckUpdateIsAllowRestart} = getCheckSlice.actions;
export default getCheckSlice.reducer;
