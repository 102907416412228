import {
	type PostDepartmentPositionsRequest,
	type PostDepartmentPositionsResponse,
} from '../../../models/IDepartment';
import {postDepartmentPositions} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {addPosition} from '../../slice/department/getDepartmentPositionsSlice';

const postDepartmentPositionsAction = createActionTemplateAxios<
PostDepartmentPositionsResponse,
PostDepartmentPositionsRequest>(
	'department/postDepartmentPositions/action',
	postDepartmentPositions,
	'userToken',
	addPosition,
);

export default postDepartmentPositionsAction;
