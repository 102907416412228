import React, {useState, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../redux/redux-hooks';
import styles from '../ModalConfirmationEmail/ModalConfirmationEmail.module.css';
import {ButtonConfirm} from '../../../../UiKit/Button';
import ModalConfirmationSubCheck from '../ModalConfirmationSubCheck';
import {ErrorMessage} from '../../../../UiKit/Message';
import {postCheckCreatePassportAction} from '../../../../../redux/action/checks';
import {notifyMessage} from '../../../../UiKit/Toast/notifyMessage';
import {postCheckCreatePassportSliceResetState} from '../../../../../redux/slice/checks';

type ModalConfirmationPassportProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	passport: string | undefined;
	containerId: string | undefined;
};

const passportRegex = /^\d{10}$/;

const ModalConfirmationPassport: React.FC<ModalConfirmationPassportProps> = ({
	passport,
	visible,
	setVisible,
	containerId,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isValidPassport, setIsValidPassport] = useState(false);

	const postCheckCreatePassportRequestId = useRef<string | undefined>(undefined);

	const getUser = useAppSelector(state => state.getUser);
	const getCheckReportById = useAppSelector(state => state.getCheckReportById);
	const postCheckCreatePassport = useAppSelector(state => state.postCheckCreatePassport);

	const dispatch = useAppDispatch();

	const cleanPassport = (passport: string | undefined) => passport ? passport.replace(/\D/g, '') : '';

	const validatePassport = () => {
		const cleanedPassport = cleanPassport(passport);
		setIsValidPassport(cleanedPassport ? passportRegex.test(cleanedPassport) : false);
	};

	const sendPostCheckCreatePassport = () => {
		const parent_check_id = getCheckReportById.response?.id;
		const author_id = getUser.user?.id;

		if (passport && isValidPassport && parent_check_id && author_id && containerId) {
			const employee_id = getCheckReportById.response?.employee?.id ?? null;
			const candidate_id = getCheckReportById.response?.candidate?.id ?? null;
			const person_id = getCheckReportById.response?.person?.id ?? null;

			postCheckCreatePassportRequestId.current = dispatch(postCheckCreatePassportAction({
				employee_id,
				candidate_id,
				person_id,
				author_id,
				check_type_id: null,
				parent_check_id,
				passport: cleanPassport(passport),
				container_id: containerId,
			})).requestId;
		}
	};

	useEffect(() => {
		if (postCheckCreatePassportRequestId && postCheckCreatePassportRequestId.current === postCheckCreatePassport.responseStatus.requestId) {
			if (postCheckCreatePassport.responseStatus.isCompleted) {
				notifyMessage('success', 'Дополнительная проверка успешно запущена.');
				setVisible(false);
			} else if (postCheckCreatePassport.responseStatus.isLoading) {
				setIsLoading(true);
			} else if (postCheckCreatePassport.responseStatus.isError) {
				notifyMessage('error', postCheckCreatePassport.responseStatus.errorMessage);
				setVisible(false);
			}
		}
	}, [postCheckCreatePassport.responseStatus.isLoading]);

	useEffect(() => {
		dispatch(postCheckCreatePassportSliceResetState());
		postCheckCreatePassportRequestId.current = undefined;
		if (visible) {
			validatePassport();
		} else {
			setIsValidPassport(false);
			setIsLoading(false);
		}
	}, [visible]);

	return (
		<ModalConfirmationSubCheck
			visible={visible}
			setVisible={setVisible}
			header={
				<span className={styles.header}>
					Запуск дополнительной проверки по паспортным данным
				</span>
			}
			footerItems={[
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={sendPostCheckCreatePassport}
					text={'Подтвердить проверку'}
					disable={!isValidPassport}
				/>,
			]}
			isLoading={isLoading}
			loadingMessage='Запуск дополнительной проверки'
			width='50%'
			minWidth={400}
		>
			<div>
				{passport && (
					<div className={styles.container}>
						<span>
							Запустить запрос на проверку по паспортным данным <span
								className={styles.result}>{passport}</span>?
						</span>
					</div>
				)}
				<ErrorMessage
					isError={!isValidPassport && visible}
					message={'Паспортные данные не соответствует требуемому формату, для проверки'}
				/>
			</div>
		</ModalConfirmationSubCheck>
	);
};

export default ModalConfirmationPassport;
