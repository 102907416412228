import React, {useEffect, useState} from 'react';
import {InputAutocomplete, type InputAutocompleteCommonProps} from '../..';
import {useAppSelector, useAppDispatch} from '../../../../../redux/redux-hooks';
import {getEmployeesAutofillAction} from '../../../../../redux/action/employees';
import InputEmployeesAutofillItem from './InputEmployeesAutofillItem/InputEmployeesAutofillItem';
import {type GetEmployeesAutofillItem} from '../../../../../models/IEmployees';

type InputEmployeesAutofillProps = InputAutocompleteCommonProps & {
	employeeRef: React.MutableRefObject<GetEmployeesAutofillItem | undefined>;
};

const InputEmployeesAutofill: React.FC<InputEmployeesAutofillProps> = ({
	employeeRef,
	inputStyles,
	...props
}) => {
	const [name, setName] = useState<string>();

	const getEmployeesAutofill = useAppSelector(state => state.getEmployeesAutofill);
	const dispatch = useAppDispatch();

	const requestAutocompleteData = () => {
		if (name && name.length > 1) {
			return dispatch(getEmployeesAutofillAction({query: name})).requestId;
		}
	};

	const onOut = () => {
		if (employeeRef.current === undefined) {
			setName('');
		} else if (name === '') {
			employeeRef.current = undefined;
		}
	};

	const onItemSelect = (item: GetEmployeesAutofillItem) => {
		employeeRef.current = item;
	};

	useEffect(() => {
		setName(employeeRef.current?.name);
	}, [employeeRef.current]);

	return (
		<InputAutocomplete
			value={name}
			setValue={setName}
			onOut={onOut}
			onItemSelect={onItemSelect}
			requestAutocompleteData={requestAutocompleteData}
			responseAutocompleteData={getEmployeesAutofill.response?.data}
			responseStatus={getEmployeesAutofill.responseStatus}
			itemRender={InputEmployeesAutofillItem}
			inputStyles={{
				...inputStyles,
				dropDownOptions: {
					minHeight: 60,
				},
				showClearButton: true,
			}}
			maxItemCount={10}
			{...props}
		/>
	);
};

export default InputEmployeesAutofill;
