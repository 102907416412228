import React, {useEffect, useRef, useState} from 'react';
import styles from './PersonUuid.module.css';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {getTokenPerson152fzAction} from '../../redux/action/person';
import {getTokenPerson152fzResetState} from '../../redux/slice/person';
import {paths} from '../../paths';
import Loader from '../../components/Loader/Loader';
import {ErrorMessage} from '../../components/UiKit/Message';
import {useEffectHandlerLoadingCurrentRequest} from '../../utils/hooks';

export const PersonUuid = () => {
	const getTokenPerson152fzRequestId = useRef<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const getTokenPerson152fz = useAppSelector(state => state.getTokenPerson152fz);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {uuid} = useParams();

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	// Отобразить ошибку
	const showError = (message?: string) => {
		setIsError(true);
		setErrorMessage(message);
	};

	// Скрыть ошибку
	const hiddenError = () => {
		setIsError(false);
		setErrorMessage(undefined);
	};

	// Сброс состояний компонента
	const resetComponentStates = () => {
		hiddenError();
		hiddenLoading();

		getTokenPerson152fzRequestId.current = undefined;
		dispatch(getTokenPerson152fzResetState());
	};

	// Первый рендер компонента
	useEffect(() => {
		resetComponentStates();
		if (uuid) {
			// Запрос на получение токена
			getTokenPerson152fzRequestId.current = dispatch(getTokenPerson152fzAction({uuid})).requestId;
		}
	}, []);

	// Получение токена
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getTokenPerson152fzRequestId,
		state: getTokenPerson152fz,
		onLoading() {
			showLoading('Авторизация...');
			hiddenError();
		},
		onStopLoading: hiddenLoading,
		onComplete() {
			navigate(paths.person);
		},
		onError: showError,
	});

	return (
		<div className={styles.container}>
			<Loader
				isLoading={isLoading}
				text={loadingMessage}
				isFloating
				shading='full'
			/>
			<ErrorMessage isError={isError} message={errorMessage} />
		</div>
	);
};
