import React, {useEffect, useRef, useState} from 'react';
import styles from './ModalConfirmationEmail.module.css';
import ModalConfirmationSubCheck from '../ModalConfirmationSubCheck';
import {ButtonConfirm} from '../../../../UiKit/Button';
import {Email, ErrorMessage} from '../../../../UiKit/Message';
import {useAppDispatch, useAppSelector} from '../../../../../redux/redux-hooks';
import {postCheckCreateEmailAction} from '../../../../../redux/action/checks';
import {notifyMessage} from '../../../../UiKit/Toast/notifyMessage';
import {postCheckCreateEmailSliceResetState} from '../../../../../redux/slice/checks';

type ModalConfirmationEmailProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	email: string | undefined;
	containerId: string | undefined;
};

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;

const ModalConfirmationEmail: React.FC<ModalConfirmationEmailProps> = ({
	setVisible,
	visible,
	email,
	containerId,
}) => {
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const postCheckCreateEmailRequestId = useRef<string | undefined>(undefined);

	const dispatch = useAppDispatch();
	const postCheckCreateEmail = useAppSelector(state => state.postCheckCreateEmail);
	const getCheckReportById = useAppSelector(state => state.getCheckReportById);
	const getUser = useAppSelector(state => state.getUser);

	const sendPostCheckCreateEmail = () => {
		const parent_check_id = getCheckReportById.response?.id;
		const author_id = getUser.user?.id;
		if (email && isValidEmail && parent_check_id && author_id && containerId) {
			const employee_id = getCheckReportById.response?.employee?.id ?? null;
			const candidate_id = getCheckReportById.response?.candidate?.id ?? null;
			const person_id = getCheckReportById.response?.person?.id ?? null;

			postCheckCreateEmailRequestId.current = dispatch(postCheckCreateEmailAction({
				employee_id,
				candidate_id,
				person_id,
				author_id,
				check_type_id: null,
				email,
				parent_check_id,
				container_id: containerId,
			})).requestId;
		}
	};

	useEffect(() => {
		if (postCheckCreateEmailRequestId && postCheckCreateEmailRequestId.current === postCheckCreateEmail.responseStatus.requestId) {
			if (postCheckCreateEmail.responseStatus.isCompleted) {
				notifyMessage('success', 'Дополнительная проверка успешно запущена.');
				setVisible(false);
			} else if (postCheckCreateEmail.responseStatus.isError) {
				notifyMessage('error', postCheckCreateEmail.responseStatus.errorMessage);
				setVisible(false);
			} else if (postCheckCreateEmail.responseStatus.isLoading) {
				setIsLoading(true);
			}
		}
	}, [postCheckCreateEmail.responseStatus.isLoading]);

	useEffect(() => {
		dispatch(postCheckCreateEmailSliceResetState());
		postCheckCreateEmailRequestId.current = undefined;
		if (visible) {
			setIsValidEmail(email ? emailRegExp.test(email) : false);
		} else {
			setIsValidEmail(false);
			setIsLoading(false);
		}
	}, [visible]);

	return (
		<ModalConfirmationSubCheck
			visible={visible}
			setVisible={setVisible}
			header={
				<span className={styles.header}>
					Запуск дополнительной проверки по электронному адресу
				</span>
			}
			footerItems={[
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={sendPostCheckCreateEmail}
					text={'Подтвердить проверку'}
					disable={!isValidEmail}
				/>,
			]}
			isLoading={isLoading}
			loadingMessage='Запуск дополнительной проверки'
			width='50%'
			minWidth={400}
		>
			<div>
				{email && (
					<div className={styles.container}>
						<span>
							Запустить запрос на проверку по электронному адресу {<Email email={email}/>}?
						</span>
					</div>
				)}
				<ErrorMessage isError={!isValidEmail}
					message={'Электронный адрес не соответствует требуемому формату, для проверки'}/>
			</div>
		</ModalConfirmationSubCheck>
	);
};

export default ModalConfirmationEmail;
