/* eslint-disable @typescript-eslint/ban-types */
import React, {useEffect, useRef, useState} from 'react';
import styles from './CandidateVerificationCard.module.css';
import {type Check} from '../../../../models/Schemas';
import {type CheckPermissions} from '../../../Modal/ModalCheckCreated/ModalCheckCreated';
import {type ProcessedChecksResponse} from '../../../../redux/slice/checks';
import {ChipStatus, ActionsCheck} from '../../Chips';
import {IoReload} from 'react-icons/io5';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {postCheckRestartAction} from '../../../../redux/action/checks';
import Loader from '../../../Loader/Loader';
import {HiOutlineDotsVertical} from 'react-icons/hi';
import {FiAlertCircle} from 'react-icons/fi';
import {HiOutlineChatAlt} from 'react-icons/hi';
import {CardTemplate} from '../index';

type CandidateVerificationCardProps = {
	openModalStartCheck: (check: Check, checkPermissions: CheckPermissions) => void;
	checkPermissions: CheckPermissions;
} & ProcessedChecksResponse;

const statusCheck = (status: string) => {
	if (status === 'collect') {
		return <ChipStatus text='Сбор данных' type='orange' />;
	}

	if (status === 'in_progress') {
		return <ChipStatus text='На проверке' type='blue' />;
	}

	if (status === 'verified') {
		return <ChipStatus text='Проверен' type='green' />;
	}

	if (status === 'wait') {
		return <ChipStatus text='Ожидает проверки' type='violet' />;
	}

	return null;
};

const criticalFactor = (indicator: number | undefined) => {
	if (indicator === 0) {
		return <span className={`${styles.factor} ${styles.green}`}>Нет</span>;
	}

	if (indicator === 1) {
		return <span className={`${styles.factor} ${styles.yellow}`}>-</span>;
	}

	if (indicator === 2) {
		return <span className={`${styles.factor} ${styles.red}`}>Да</span>;
	}

	return null;
};

export const CandidateVerificationCard: React.FC<
CandidateVerificationCardProps
> = ({
	openModalStartCheck,
	checkPermissions,
	...props
}) => {
	const dispatch = useAppDispatch();
	const postCheckRestart = useAppSelector(state => state.postCheckRestart);
	const requestId = useRef<string | undefined>(undefined);
	const [isRestarted, setIsRestarted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const reload = () => {
		if (!isLoading && !isRestarted) {
			requestId.current = dispatch(postCheckRestartAction({checkId: props.id})).requestId;
		}
	};

	const getRoleText = (
		candidateId: number | undefined | null,
		employeeId: number | undefined | null,
	) => {
		if (candidateId) {
			return 'Кандидат';
		}

		if (employeeId) {
			return 'Сотрудник';
		}

		return 'Физ. лицо';
	};

	useEffect(() => {
		const {responseStatus} = postCheckRestart;
		if (requestId.current && requestId.current === responseStatus.requestId) {
			if (responseStatus.isLoading) {
				setIsLoading(true);
				setErrorMessage(undefined);
			} else if (responseStatus.isCompleted) {
				requestId.current = undefined;
				setIsLoading(false);
				setErrorMessage(undefined);
				setIsRestarted(true);
			} else if (responseStatus.isError) {
				requestId.current = undefined;
				setIsLoading(false);
				setErrorMessage(responseStatus.errorMessage);
			}
		}
	}, [postCheckRestart.responseStatus]);

	return (
		<CardTemplate styleType='check'>
			<div className={styles.card}>
				<div className={styles.info}>
					<div className={styles.status}>
						{statusCheck(props.status)}
						<div className={styles['status-left']}>
							{props.isAllowRestart && <IoReload
								onClick={reload}
								className={`${styles.reload} ${isRestarted && styles['reload-disabled']}`} />
							}
							<div className={styles['vertica-eEllipsis']}>
								<HiOutlineDotsVertical />
							</div>
						</div>
					</div>

					<div className={styles['employee-group']}>

						<span className={styles['text-light']}>{getRoleText(props.candidate_id, props.employee_id)}</span>

						<div className={styles['data-group']}>
							<div className={styles.candidate}>
								<span>#{props.id}</span>
								<span>-</span>
								<span>{props.name}</span>
							</div>

							<div className={styles.employee}>
								<span>от: {props.authorFullName}</span>
								<span>{props.report_created_at}</span>
							</div>
						</div>
					</div>

					<div className={styles.parameters}>
						<div className={styles.parameter}>
							<div className={styles.abbreviation}>
								<FiAlertCircle />
								<span>Критические факторы:</span>
							</div>
							{criticalFactor(props.criticalFactor)}
						</div>

						<div className={styles.parameter}>
							<div className={styles.abbreviation}>
								<HiOutlineChatAlt />
								<span>Комментарий:</span>
							</div>
							-
						</div>
					</div>
				</div>

				<ActionsCheck
					status={props.status}
					isCard
					check={props}
					openModalStartCheck={openModalStartCheck}
					checkPermissions={checkPermissions}
				/>
			</div>
			<Loader isFloating isLoading={isLoading} />
		</CardTemplate>
	);
};
