import React from 'react';
import styles from './EmployeeStaffCard.module.css';
import {UserIcon} from '../../../Div';
import {Email, Phone} from '../../../Message';
import {typePermissions} from '../../../../../models/Enum';
import {TableIconButton} from '../../../Button';
import {useAppSelector} from '../../../../../redux/redux-hooks';
import {checkPermission} from '../../../../../utils/permissions/permissions';
import {FiEdit2, FiSearch, FiUser} from 'react-icons/fi';
import {LiaUserEditSolid} from 'react-icons/lia';
import {type CheckPermissions} from '../../../../Modal/ModalCheckCreated/ModalCheckCreated';
import {type EditContactsPermissions} from '../../../../Modal/ModalEditContacts/ModalEditContacts';
import {CardTemplate} from '../../CardTemplate/CardTemplate';
import {type GetDepartmentEmployeesProcessedResponseItem} from '../../../../../redux/slice/department';
import {type Department} from '../../../../../models/Schemas';

type EmployeeStaffCardProps = {
	employee: GetDepartmentEmployeesProcessedResponseItem;
	className?: string;
	openProfile: (id: number) => void;
	openEditContact: (id: number, permissions: EditContactsPermissions) => void;
	openCreateCheck: (id: number, permissions: CheckPermissions) => void;
	openEditProfile: (id: number) => void;
};
const EmployeeStaffCard: React.FC<EmployeeStaffCardProps> = ({
	employee,
	openProfile,
	openEditContact,
	openCreateCheck,
	openEditProfile,
	...props}) => {
	const myId = useAppSelector(state => state.getUser.user?.employee_id);
	const myDepartmentId = useAppSelector(state => state.getUser.user?.department?.id);
	const {groupPermissions} = useAppSelector(state => state.getPermissions);
	const isUser = typeof (employee.user_id) === 'number';

	const parametersCheckPermission = {
		groupPermissions,
		isMyDepartment: employee.department_id === myDepartmentId,
		isMe: myId === employee.id,
	};
	const isUpdate = checkPermission(
		isUser
			? typePermissions.user_update : typePermissions.employee_update,
		parametersCheckPermission,
	);
	const isRead = checkPermission(
		employee.user_id
			? typePermissions.user_read : typePermissions.employee_read,
		parametersCheckPermission,
	);
	const isCheckRequestCreate = checkPermission(
		typePermissions.create_check_request,
		parametersCheckPermission,
	);
	const isZeroReportCreate = checkPermission(
		typePermissions.zero_report_create,
		parametersCheckPermission,
	);
	const employeeRead = checkPermission(
		typePermissions.employee_read,
		parametersCheckPermission,
	);
	const contactDelete = checkPermission(
		typePermissions.contact_delete,
		parametersCheckPermission,
	);
	const contactUpdate = checkPermission(
		typePermissions.contact_update,
		parametersCheckPermission,
	);
	const contactCreate = checkPermission(
		typePermissions.contact_create,
		parametersCheckPermission,
	);
	const checkStart = checkPermission(typePermissions.check_start, parametersCheckPermission);

	const isEditContacts = employeeRead && (contactDelete || contactUpdate || contactCreate);
	const isCheck = isUpdate && (isZeroReportCreate || isCheckRequestCreate);
	const permissions: CheckPermissions = {
		isCreateCheckRequest: isCheckRequestCreate,
		isZeroReportCreate,
		isStartCheck: checkStart,
	};

	return (
		<CardTemplate styleType='employee'>
			<div className={`${styles.container} ${props.className} ${isUser ? styles.user : ''}`}>
				<div className={styles.header}>
					<UserIcon round label={employee.last_name[0]} />
					<div className={styles['header-name']}>
						<span className={styles.fullName} aria-roledescription='fullName'>{employee.fullName}</span>
						{employee.department_position?.name && (
							<span className={styles.position}>
								{employee.department_position.name}
							</span>
						)}
					</div>
				</div>
				<div className={styles.content}>
					<div>
						<span className={styles.title}>Телефон:</span>
						<Phone phone={employee.phone} formatPhone/>
					</div>
					<div>
						<span className={styles.title}>Электронный адрес:</span>
						<div className={styles.email}>
							<Email email={employee.email} />
						</div>
					</div>
					<div>
						<span className={styles.title}>Дата рождения:</span>
						<span>{employee.birth_date}</span>
					</div>
				</div>
				<div className={styles.action}>
					{isRead
					&& <TableIconButton
						onClick={() => {
							openProfile(employee.id);
						}}
						color='asphalt-transparent'
						Icon={FiUser}
						iconStyleType='medium'
						TooltipMessage='Просмотреть профиль'
						TooltipPosition='top'
					/>}
					{isUpdate && isRead
					&& <TableIconButton
						onClick={() => {
							openEditProfile(employee.id);
						}}
						color='asphalt-transparent'
						Icon={LiaUserEditSolid}
						iconStyleType='medium'
						TooltipMessage='Редактировать профиль'
						TooltipPosition='top'
					/>
					}
					{isEditContacts
					&& <TableIconButton
						onClick={() => {
							openEditContact(
								employee.id,
								{
									isDelete: contactDelete,
									isUpdate: contactUpdate,
									isCreate: contactCreate,
								},
							);
						}}
						color='asphalt-transparent'
						Icon={FiEdit2}
						iconStyleType='medium'
						TooltipMessage='Редактировать контакты'
						TooltipPosition='top'
					/>
					}
					{isCheck
					&& <TableIconButton
						onClick={() => {
							openCreateCheck(employee.id, permissions);
						}}
						color='asphalt-transparent'
						Icon={FiSearch}
						iconStyleType='medium'
						TooltipMessage='Создать заявку на проверку'
						TooltipPosition='top'
					/>}
				</div>
			</div>
		</CardTemplate>
	);
};

export default EmployeeStaffCard;
