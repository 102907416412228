import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {getNavigationCompaniesAction} from '../../action/navigation';
import {type GetNavigationCompaniesResponse} from '../../../models/Navigation/Navigation';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetNavigationCompaniesResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getNavigationCompaniesSlice = createSlice({
	name: 'navigation/getNavigationCompanies',
	initialState,
	reducers: {
		getNavigationCompaniesResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getNavigationCompaniesAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getNavigationCompaniesAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(getNavigationCompaniesAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getNavigationCompaniesResetState} = getNavigationCompaniesSlice.actions;
export default getNavigationCompaniesSlice.reducer;
