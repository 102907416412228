import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostCheckUpdateResponse} from '../../../models/ICheck';
import {postCheckUpdateAction} from '../../action/checks';

type postCheckUpdateState = {
	responseStatus: ResponseStatus & {errors?: Record<string, string>};
	response: PostCheckUpdateResponse | undefined;
};

const initialState: postCheckUpdateState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
		errors: undefined,
	},
	response: undefined,
};

const postCheckUpdateSlice = createSlice({
	name: 'checks/postCheckUpdate',
	initialState,
	reducers: {
		postCheckUpdateSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postCheckUpdateAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.errors = undefined;
				state.responseStatus.errorMessage = '';
				state.responseStatus.status = undefined;
			})
			.addCase(postCheckUpdateAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.errors = undefined;
				state.responseStatus.errorMessage = '';
				state.responseStatus.status = undefined;
				state.response = action.payload;
			})
			.addCase(postCheckUpdateAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
				state.responseStatus.errors = action.payload?.errors;
			});
	},
});

export const {postCheckUpdateSliceResetState} = postCheckUpdateSlice.actions;
export default postCheckUpdateSlice.reducer;
