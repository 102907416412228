import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './EmailsUsersTables.module.css';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {createRequestUsersAction} from '../../../redux/action/users';
import Loader from '../../Loader/Loader';
import {ErrorMessage} from '../../UiKit/Message';
import {createRequestUsersResetState} from '../../../redux/slice/users/createRequestUsersSlice';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {Editing, Item, KeyboardNavigation, Summary, Toolbar, TotalItem} from 'devextreme-react/cjs/data-grid';
import {ButtonConfirm, GreenBorderButton, TableIconButton} from '../../UiKit/Button';
import {MdDelete} from 'react-icons/md';

type TableEmailRowData = {
	email: string;
	error: string;
	isSending: boolean;
	id: number;
};

type TableEmailData = TableEmailRowData[];

const EmailsUsersTables = () => {
	const [emails, setEmails] = useState<TableEmailData>([]);
	const lastId = useRef(0);
	const [countInvited, setCountInvited] = useState<number | undefined>(undefined);

	const dispatch = useAppDispatch();

	const requestUsers = useAppSelector(
		state => state.requestUsers,
	);

	// Получение столбцов
	const columns = useMemo<ColumnsProps[]>(() => (
		[
			{
				caption: 'Электронные адреса',
				dataField: 'email',
				name: 'emails',
				allowEditing: true,
				cellRender(rowData) {
					const email = rowData.row.data as TableEmailRowData;
					if (email.email) {
						return email.email;
					}

					return <span className={`${styles.text} ${styles['not-send']}`}>Введите электронный адрес</span>;
				},
			},
			{
				caption: 'Результат',
				name: 'result',
				dataField: 'error',
				allowEditing: false,
				cellRender(rowData) {
					const email = rowData.row.data as TableEmailRowData;
					if (email.isSending) {
						if (email.error) {
							return <span className={`${styles.text} ${styles.error}`}>{email.error}</span>;
						}

						return <span className={`${styles.text} ${styles.successfully}`}>Успешно отправлено</span>;
					}

					return <span className={`${styles.text} ${styles['not-send']}`}>Не отправлено</span>;
				},
			},
			{
				type: 'buttons',
				width: 50,
				allowEditing: false,
				cellRender(rowData) {
					const email = rowData.row.data as TableEmailRowData;
					return (
						<TableIconButton
							onClick={() => {
								removeRow(email.id);
							}}
							Icon={MdDelete}
						/>
					);
				},
			},
		]
	), []);

	const handleSubmit = () => {
		const email = emails.filter(item => item.email && (!item.isSending || item.error)).map(item => item.email);

		if (email.length > 0) {
			void dispatch(
				createRequestUsersAction({
					email,
				}),
			);
		}
	};

	const getMessage = () => {
		if (requestUsers.response) {
			const {invited, errors} = requestUsers.response;

			if (emails.length === invited && invited > 0) {
				notifyMessage('success', 'Приглашения на регистрацию успешно отправлены');
			} else {
				notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
			}

			const newList = emails.map(item => {
				if (item.email && (!item.isSending || item.error)) {
					item.isSending = true;
					item.error = '';
				}

				return item;
			});

			const numericEmailRegex = /^\d+$/;

			for (const key in errors) {
				if (Object.hasOwn(errors, key)) {
					const error = errors[key];
					newList.forEach(item => {
						if (numericEmailRegex.test(item.email)) {
							if (`@${item.email}` === key) {
								item.error = error;
							}
						} else if (item.email === key) {
							item.error = error;
						}
					});
				}
			}

			setCountInvited(newList.filter(item => item.isSending && !item.error).length);
			setEmails(newList);
		}
	};

	// Добавить строку
	const addRow = () => {
		setEmails(p => {
			const id = lastId.current++;

			const newRow = {
				email: '',
				error: '',
				isSending: false,
				id,
			};

			return [...p, newRow];
		});
	};

	// Удалить строку
	const removeRow = (id: number) => {
		setEmails(p => {
			const index = p.findIndex(item => item.id === id);
			if (index !== -1) {
				p.splice(index, 1);
				return [...p];
			}

			return p;
		});
	};

	useEffect(() => {
		dispatch(createRequestUsersResetState());
	}, []);

	useEffect(() => {
		if (requestUsers.responseStatus.isCompleted) {
			getMessage();
		}
	}, [requestUsers.responseStatus.isLoading]);

	return (
		<div className={styles.wrapper}>
			<h2>Введите электронные адреса пользователей</h2>
			<div className={styles.table}>
				<div>
					<Table
						columns={columns}
						dataGridProps={{
							dataSource: emails,
						}}
					>
						<Editing
							mode='cell'
							editColumnName='emails'
							allowUpdating={true}
							allowAdding={true}
							allowDeleting={true}
						/>
						<KeyboardNavigation
							editOnKeyPress={true}
							enterKeyAction='startEdit'
							enterKeyDirection='column'
						/>
						<Toolbar>
							<Item
								name='addRowButton'
								location='after'
							>
								<GreenBorderButton
									onClick={addRow}
									text='Добавить пользователя'
								/>
							</Item>
						</Toolbar>
						<Summary>
							<TotalItem
								column='email'
								summaryType='count'
							/>
							<TotalItem
								column='result'

								customizeText={i => {
									if (countInvited !== undefined) {
										return `Кол-во успешно отправленных: ${countInvited}`;
									}

									return '';
								}}
							/>
						</Summary>
					</Table>
				</div>
			</div>
			<ErrorMessage
				message={requestUsers.responseStatus.errorMessage}
				isError={requestUsers.responseStatus.isError}
			/>
			<div className={styles['btn-group']}>
				<ButtonConfirm
					onclickHandler={handleSubmit}
					text='Отправить'
				/>
			</div>
			<Loader text='Добавление пользователей...' isFloating isLoading={requestUsers.responseStatus.isLoading} />
		</div>
	);
};

export default EmailsUsersTables;
