import React, {useEffect, useState} from 'react';
import styles from './InputNumberField.module.css';
import {type Field} from '../ModalCheckCreated';
import {InputNumber} from '../../../UiKit/Inputs';
import {type CheckFieldData, type SendCheckField} from '../../../../models/Schemas';
import {checKAdditionalValidation} from '.';
import {useAppSelector} from '../../../../redux/redux-hooks';

type InputFieldProps = {
	updateValue: (sendCheckField: SendCheckField, isValid: boolean) => void;
	initValue: CheckFieldData;
	showValidation: boolean;
} & Field;

const convertValueToNumber = (value: CheckFieldData, index: number): number | undefined => {
	if (typeof (value) === 'string') {
		return parseInt(value, 10);
	}

	if (typeof (value) === 'number') {
		return value;
	}

	if (Array.isArray(value)) {
		return convertValueToNumber(value[index], index);
	}

	return undefined;
};

const InputNumberField: React.FC<InputFieldProps> = ({...props}) => {
	const [firstValue, setFirstValue] = useState(convertValueToNumber(props.initValue, 0));
	const [secondValue, setSecondValue] = useState(convertValueToNumber(props.initValue, 1));

	const [isValidFirstValue, setIsValidFirstValue] = useState(false);
	const [isValidSecondValue, setIsValidSecondValue] = useState(false);

	const [isValidAdditional, setIsValidAdditional] = useState(true);
	const [invalidMessageAdditional, setInvalidMessageAdditional] = useState('');
	const postCheckCreateErrors = useAppSelector(state => state.postCheckCreate.responseStatus.errors);
	const postCheckUpdateErrors = useAppSelector(state => state.postCheckUpdate.responseStatus.errors);
	const postCheckStartErrors = useAppSelector(state => state.postCheckStart.responseStatus.errors);

	useEffect(() => {
		let data;
		let isValid;

		if (props.type === 'range') {
			data = [firstValue ?? null, secondValue ?? null];
			isValid = isValidFirstValue && isValidSecondValue;
		} else {
			data = firstValue ?? null;
			isValid = isValidFirstValue;
		}

		const sendCheckField: SendCheckField = {
			id: props.field.id,
			value: props.field.value,
			data,
		};

		props.updateValue(sendCheckField, isValid);
	}, [firstValue, secondValue, isValidFirstValue, isValidSecondValue]);

	useEffect(() => {
		if (!isValidAdditional) {
			setIsValidAdditional(true);
		}
	}, [firstValue, secondValue]);

	useEffect(() => {
		if (postCheckCreateErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckCreateErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckCreateErrors]);

	useEffect(() => {
		if (postCheckUpdateErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckUpdateErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckUpdateErrors]);

	useEffect(() => {
		if (postCheckStartErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckStartErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckStartErrors]);

	if (props.type === 'range') {
		return (
			<>
				<span>{props.field.name}</span>
				<div className={styles['range-inputs']}>
					<InputNumber
						value={firstValue}
						setValue={setFirstValue}
						inputMask={{
							mask: props.mask,
						}}
						inputStyles={{
							placeholder: 'От',
						}}
						inputValidation={{
							isValid: isValidFirstValue,
							isRequired: props.isAllRequired || props.isRequired,
							setIsValid: setIsValidFirstValue,
							showValidation: props.showValidation,
							customRule: [{
								message: invalidMessageAdditional,
								validationCallback() {
									return isValidAdditional;
								},
							}],
						}}
					/>
					<InputNumber
						value={secondValue}
						setValue={setSecondValue}
						inputMask={{
							mask: props.mask,
						}}
						inputStyles={{
							placeholder: 'До',
						}}
						inputValidation={{
							isValid: isValidSecondValue,
							isRequired: props.isAllRequired || props.isRequired,
							setIsValid: setIsValidSecondValue,
							showValidation: props.showValidation,
							customRule: [{
								message: invalidMessageAdditional,
								validationCallback() {
									return isValidAdditional;
								},
							}],
						}}
					/>
				</div>
			</>
		);
	}

	return <InputNumber
		value={firstValue}
		setValue={setFirstValue}
		title={props.field.name}
		inputMask={{
			mask: props.mask,
		}}
		inputStyles={{
			placeholder: props.field.name,
		}}
		inputValidation={{
			isRequired: props.isAllRequired || props.isRequired,
			isValid: isValidFirstValue,
			setIsValid: setIsValidFirstValue,
			showValidation: props.showValidation,
			customRule: [{
				message: invalidMessageAdditional,
				validationCallback() {
					return isValidAdditional;
				},
			}],
		}}
	/>;
};

export default InputNumberField;
