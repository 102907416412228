import {postCreateAddress} from '../../../api/checkApi';
import {type PostCheckCreateAddressResponse, type PostCheckCreateAddressRequest} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckCreateAddressAction = createActionTemplateAxios<
PostCheckCreateAddressResponse,
PostCheckCreateAddressRequest>(
	'checks/postCreateAddress/action',
	postCreateAddress,
	'userToken',
);

export default postCheckCreateAddressAction;
