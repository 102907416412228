import React, {useEffect, useRef, useState} from 'react';
import {ModalWindow} from '../../../Modal';
import {
	type InteractiveSwitches,
	type InteractiveSwitch,
	type InteractiveFieldValues,
	type InteractiveScreen,
} from '../../../../models/IInteractive';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {
	postInteractiveFinishAction,
	postInteractiveGoAction,
	postInteractiveScreenGoAction,
} from '../../../../redux/action/Interactive';
import {ButtonTemplate} from '../../../UiKit/Button';
import {mockAddress, mockAgeScreen, mockEmail, mockNameScreen, mockPhone} from './mock';
import CreateCheckIndividualFirstForm from '../FirstForm/CreateCheckIndividualFirstForm';
import CreateCheckIndividualPhoneForm from '../PhoneForm/CreateCheckIndividualPhoneForm';
import CreateCheckIndividualSecondForm from '../SecondForm/CreateCheckIndividualSecondForm';
import CreateCheckIndividualAddressForm from '../AddressForm/CreateCheckIndividualAddressForm';
import CreateCheckIndividualEmailForm from '../EmailForm/CreateCheckIndividualEmailForm';
import {CreateIndividualDataForm} from './CreateIndividualDataForm';

type CreateCheckIndividualMainFormProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	isModal?: boolean;
};

const interactiveId = 'person_check';

const CreateCheckIndividualMainForm: React.FC<CreateCheckIndividualMainFormProps> = ({
	visible,
	setVisible,
	isModal,
}) => {
	const currentPostInteractiveGoRequestId = useRef<string>();
	const currentPostInteractiveScreenGoRequestId = useRef<string>();
	const currentPostInteractiveFinishRequestId = useRef<string>();
	const currentScreen = useRef<InteractiveScreen>();
	const fields = useRef<InteractiveFieldValues>({});

	const dispatch = useAppDispatch();
	const postInteractiveGo = useAppSelector(state => state.postInteractiveGo);
	const postInteractiveScreenGo = useAppSelector(state => state.postInteractiveScreenGo);
	const postInteractiveFinish = useAppSelector(state => state.postInteractiveFinish);

	const [showValidation, setShowValidation] = useState(false);
	const [currentForm, setCurrentForm] = useState<JSX.Element>();
	const [currentFooterItems, setCurrentFooterItems] = useState<JSX.Element[]>([]);
	const [currentSize, setCurrentSize] = useState<number>();

	// Выбор формы по ключу
	const selectForm = (screenId: string | undefined) => {
		switch (screenId) {
			case 'name_screen':
				setCurrentSize(650);
				return (
					<CreateCheckIndividualFirstForm
						fields={fields}
						currentScreen={currentScreen}
						showValidation={showValidation}
					/>
				);
			case 'second_screen':
				setCurrentSize(650);
				return (
					<CreateCheckIndividualSecondForm
						fields={fields}
						currentScreen={currentScreen}
						showValidation={showValidation}
					/>
				);
			case 'phone_screen':
				setCurrentSize(300);
				return (
					<CreateCheckIndividualPhoneForm
						fields={fields}
						currentScreen={currentScreen}
						showValidation={showValidation}
					/>
				);
			case 'email_screen':
				setCurrentSize(450);
				return (
					<CreateCheckIndividualEmailForm
						fields={fields}
						currentScreen={currentScreen}
						showValidation={showValidation}
					/>
				);
			case 'address_screen':
				setCurrentSize(650);
				return (
					<CreateCheckIndividualAddressForm
						fields={fields}
						currentScreen={currentScreen}
						showValidation={showValidation}
					/>
				);
			default:
				return <div>Что-то пошло не так</div>;
		}
	};

	// Переход к одной из форм
	const goToSwitch = (switchKey: string, switchFields: InteractiveSwitch, screenId: string) => {
		currentPostInteractiveScreenGoRequestId.current = dispatch(postInteractiveScreenGoAction({
			id: interactiveId,
			screenId,
			switch: switchKey,
			body: {
				fields: fields.current,
			},
		})).requestId;
		setShowValidation(true);
		// ИЗМЕНИТЬ убрать
		if (switchKey === 'next') {
			setCurrentForm(selectForm('second_screen'));
			if (currentScreen.current?.screen_id) {
				const {current_screen} = mockPhone;
				currentScreen.current = current_screen;
				setCurrentForm(selectForm(current_screen.screen_id));
				setCurrentFooterItems(getFooterItems(
					current_screen.switch,
					current_screen.screen_id,
				));
				fields.current = mockPhone.current_screen.default_values;
			}
		} else if (switchKey === 'back') {
			const {current_screen} = mockAddress;
			currentScreen.current = current_screen;
			setCurrentForm(selectForm(current_screen.screen_id));
			setCurrentFooterItems(getFooterItems(
				current_screen.switch,
				current_screen.screen_id,
			));
			fields.current = mockPhone.current_screen.default_values;
		}
	};

	// Завершение работы
	const onCloseButtonClick = () => {
		currentPostInteractiveFinishRequestId.current = dispatch(postInteractiveFinishAction(interactiveId)).requestId;
	};

	// Получение кнопок
	const getFooterItems = (switches: InteractiveSwitches, screenId: string) => {
		const items = [];
		for (const item in switches) {
			if (Object.hasOwn(switches, item)) {
				if (item === 'next') {
					items.push(
						<ButtonTemplate
							key={item}
							onClick={() => {
								goToSwitch(item, switches[item], screenId);
							}}
							text='Далее'
							color={'primary'}
						/>,
					);
				} else if (item === 'back') {
					items.push(
						<ButtonTemplate
							key={item}
							onClick={() => {
								goToSwitch(item, switches[item], screenId);
							}}
							text='Назад'
							color={'gray'}
						/>,
					);
				} else {
					items.push(
						<ButtonTemplate
							key={item}
							onClick={() => {
								goToSwitch(item, switches[item], screenId);
							}}
							text={item}
							color={'primary'}
						/>,
					);
				}
			}
		}

		return items;
	};

	// Очистка данных
	const clearFormData = () => {
		currentPostInteractiveGoRequestId.current = undefined;
		currentPostInteractiveScreenGoRequestId.current = undefined;
		currentPostInteractiveFinishRequestId.current = undefined;
		currentScreen.current = undefined;
		fields.current = {};
		setShowValidation(false);
		setCurrentForm(undefined);
		setCurrentFooterItems([]);
		setCurrentSize(undefined);
	};

	// Запрос начальных данных
	useEffect(() => {
		if (visible) {
			currentPostInteractiveGoRequestId.current = dispatch(postInteractiveGoAction(interactiveId)).requestId;
			// ИЗМЕНИТЬ убрать
			const {current_screen} = mockNameScreen;
			currentScreen.current = {...current_screen};
			setCurrentForm(selectForm(current_screen.screen_id));
			setCurrentFooterItems(getFooterItems(
				current_screen.switch,
				current_screen.screen_id,
			));
			fields.current = mockNameScreen.current_screen.default_values;
		} else {
			clearFormData();
		}
	}, [visible]);

	// Обработка запроса postInteractiveGo
	useEffect(() => {
		if (postInteractiveGo.responseStatus.isCompleted
            && currentPostInteractiveGoRequestId.current
            && currentPostInteractiveGoRequestId.current === postInteractiveGo.responseStatus.requestId
            && postInteractiveGo.response
		) {
			currentPostInteractiveGoRequestId.current = undefined;
			const {current_screen} = postInteractiveGo.response;
			currentScreen.current = current_screen;
			setCurrentForm(selectForm(current_screen.screen_id));
			setCurrentFooterItems(getFooterItems(
				current_screen.switch,
				current_screen.screen_id,
			));
		}
	}, [postInteractiveGo.responseStatus.isLoading]);

	// Обработка запроса postInteractiveScreenGo
	useEffect(() => {
		if (postInteractiveScreenGo.responseStatus.isCompleted
            && currentPostInteractiveScreenGoRequestId.current
            && currentPostInteractiveScreenGoRequestId.current === postInteractiveGo.responseStatus.requestId
            && postInteractiveScreenGo.response
		) {
			currentPostInteractiveScreenGoRequestId.current = undefined;
			const {current_screen} = postInteractiveScreenGo.response;
			currentScreen.current = current_screen;
			setCurrentForm(selectForm(current_screen.screen_id));
			setCurrentFooterItems(getFooterItems(
				current_screen.switch,
				current_screen.screen_id,
			));
		}
	}, [postInteractiveScreenGo.responseStatus.isLoading]);

	if (!isModal) {
		return (
			<CreateIndividualDataForm
				isLoading={false}
				width={currentSize}
				footerItems={currentFooterItems}
			>
				{currentForm}
			</CreateIndividualDataForm>
		);
	}

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			footerItems={currentFooterItems}
			closable
			onCloseButtonClick={onCloseButtonClick}
			width={currentSize}
			isLoading={
				postInteractiveScreenGo.responseStatus.isLoading
			|| postInteractiveScreenGo.responseStatus.isLoading
			}
			header={(
				<>
					<h1>
						{currentScreen.current?.screen_id}
					</h1>
					<div>
					1/2/34/56/6
					</div>
				</>
			)}
		>
			{currentForm}
		</ModalWindow>
	);
};

export default CreateCheckIndividualMainForm;
