import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {getPersonalizedPdAgreementAction} from '../../action/person';

const initialState: {
	responseStatus: ResponseStatus;
	response: string | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getPersonalizedPdAgreementSlice = createSlice({
	name: 'person/getPersonalizedPdAgreement',
	initialState,
	reducers: {
		getPersonalizedPdAgreementResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getPersonalizedPdAgreementAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				if (state.response) {
					URL.revokeObjectURL(state.response);
				}
			})
			.addCase(getPersonalizedPdAgreementAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = URL.createObjectURL(action.payload);
			})
			.addCase(getPersonalizedPdAgreementAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getPersonalizedPdAgreementResetState} = getPersonalizedPdAgreementSlice.actions;
export default getPersonalizedPdAgreementSlice.reducer;
