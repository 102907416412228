import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putGuest} from '../../../api/Guest/guestApi';
import {type PutGuestRequest, type PutGuestResponse} from '../../../models/Guest/Guest/IGuest';

const putGuestAction = createActionTemplateAxios<
PutGuestResponse,
PutGuestRequest
>(
	'guest/putGuest/action',
	putGuest,
	'guestToken',
);

export default putGuestAction;
