import React from 'react';
import styles from './Email.module.css';

type EmailProps = {
	email: string | string[] | undefined;
	emailStyle?: string;
};

const Email: React.FC<EmailProps> = ({email, emailStyle}) => {
	const getEmail = () => {
		if (email) {
			if (Array.isArray(email)) {
				return (
					<div className={styles.container}>
						{email.map((item, index) => (
							<a href={`mailto:${item}`} key={index} className={`${styles.email} ${emailStyle}`}>
								{item}
							</a>))}
					</div>);
			}

			return (
				<a href={`mailto:${email}`} className={`${styles.email} ${emailStyle}`}>
					{email}
				</a>
			);
		}

		return null;
	};

	return (
		getEmail()
	);
};

export default Email;
