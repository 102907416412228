import React from 'react';
import styles from './FieldsEmployee.module.css';
import {InputDate, InputName} from '../../UiKit/Inputs';

type FieldsEmployeeProps = {
	name: string;
	surname: string;
	patronymic: string;
	dateBirth: string | undefined;
	isValidDate: boolean;
	isValidName: boolean;
	isValidSurname: boolean;
	isValidPatronymic: boolean;
	showValidation: boolean;
	setName: React.Dispatch<React.SetStateAction<string>>;
	setSurname: React.Dispatch<React.SetStateAction<string>>;
	setPatronymic: React.Dispatch<React.SetStateAction<string>>;
	setDateBirth: (value: string | undefined) => void;
	setIsValidDate: (value: boolean) => void;
	setIsValidName: (value: boolean) => void;
	setIsValidSurname: (value: boolean) => void;
	setIsValidPatronymic: (value: boolean) => void;
};

export const FieldsEmployee: React.FC<FieldsEmployeeProps> = ({...props}) => (
	<form className={styles.employee}>
		<h4>Введите данные</h4>
		<InputName
			value={props.surname}
			setValue={props.setSurname}
			inputStyles={{
				placeholder: 'Фамилия',
			}}
			inputValidation={{
				isValid: props.isValidSurname,
				setIsValid: props.setIsValidSurname,
				lettersOnly: true,
				minLength: 2,
				isRequired: true,
				showValidation: props.showValidation,
			}}
			parts={['SURNAME']}
		/>

		<InputName
			value={props.name}
			setValue={props.setName}
			inputStyles={{
				placeholder: 'Имя',
			}}
			inputValidation={{
				isValid: props.isValidName,
				setIsValid: props.setIsValidName,
				lettersOnly: true,
				isRequired: true,
				minLength: 2,
				showValidation: props.showValidation,
			}}
			parts={['NAME']}
		/>
		<InputName
			value={props.patronymic}
			setValue={props.setPatronymic}
			inputStyles={{
				placeholder: 'Отчество',
			}}
			inputValidation={{
				isValid: props.isValidPatronymic,
				setIsValid: props.setIsValidPatronymic,
				lettersOnly: true,
				minLength: 2,
				showValidation: props.showValidation,
			}}
			parts={['PATRONYMIC']}
		/>
		<InputDate
			value={props.dateBirth}
			setValueString={props.setDateBirth}
			inputValidation={{
				isRequired: true,
				setIsValid: props.setIsValidDate,
				isValid: props.isValidDate,
				showValidation: props.showValidation,
			}}
		/>
	</form>
);
