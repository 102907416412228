import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetUserAuthHistoryResponse} from '../../../models/IUsers';
import getUserAuthHistoryAction from '../../action/users/getUserAuthHistoryAction';
import {DateTime} from 'luxon';

type UserAuthHistoryState = {
	responseStatus: ResponseStatus;
	response: GetUserAuthHistoryResponse | undefined;
	processedResponse: GetUserAuthHistoryResponse | undefined;
	lastAuth: string;
};

const initialState: UserAuthHistoryState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
	processedResponse: undefined,
	lastAuth: '',
};

const getLastAuth = (response: GetUserAuthHistoryResponse) => DateTime.fromISO(response[0].auth_at).toRelative() ?? '';
const formatData = (response: GetUserAuthHistoryResponse) => response.map(item => ({
	...item,
	auth_at: DateTime.fromISO(item.auth_at).toLocaleString(DateTime.DATETIME_SHORT) ?? '',
}));

const getUserAuthHistorySlice = createSlice({
	name: 'user/getUserAuthHistory',
	initialState,
	reducers: {
		getUserAuthHistoryResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getUserAuthHistoryAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getUserAuthHistoryAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
				state.processedResponse = formatData(state.response);
				state.lastAuth = getLastAuth(state.response);
			})
			.addCase(getUserAuthHistoryAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {getUserAuthHistoryResetState} = getUserAuthHistorySlice.actions;
export default getUserAuthHistorySlice.reducer;
