import React from 'react';
import styles from './WidgetCard.module.css';
import {SalesIcon} from '../../../../assets/icons';

type WidgetCardProps = {
	title: string;
	className?: string;
	children: React.ReactNode;
};

const WidgetCard: React.FC<WidgetCardProps> = ({...props}) => (
	<div className={`${styles.container} ${props.className}`}>
		<div className={styles.title}>
			<SalesIcon className={styles['title-icon']} />
			<span>{props.title}</span>
		</div>
		{props.children}
	</div>
);

export default WidgetCard;
