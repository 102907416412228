import React, {useEffect} from 'react';
import {ModalWindow} from '../../../';
import styles from '../ModalReport.module.css';
import {FaHome} from 'react-icons/fa';
import {phoneFields} from './phoneFields';
import {type ReportModelV3Phone} from '../../../../../report/reportModelV3/reportModelV3';
import {CopyToClipboardButton} from '../../../../UiKit/Button';
import {Phone} from '../../../../UiKit/Message';
import {ModalSelectCheckButton} from '../../../../UiKit/Button/ReportButtons/ModalSelectCheckButton/ModalSelectCheckButton';

type ModalPhoneProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	setVisibleSubModal: (value: boolean) => void;
	phone: ReportModelV3Phone & {tags: string[]} | undefined;
	containerId: string | undefined;
};

export const ModalPhone: React.FC<ModalPhoneProps> = ({
	visible,
	setVisible,
	setVisibleSubModal,
	phone,
	containerId,
}) => {
	const openSubModal = () => {
		setVisibleSubModal(true);
	};

	useEffect(() => {
		setVisibleSubModal(false);
	}, [visible]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			header={
				<div className={styles.title}>
					<FaHome/>
					<span>Номера телефона</span>
				</div>
			}
			footerItems={[
				<ModalSelectCheckButton
					setVisible={setVisible}
					openSubModal={openSubModal}
					containerId={containerId}
					key='modalSelectCheckButton'
				/>,
			]}
			footerItemsLocation='after'
			width='min(800px, 90%)'
			closeOnOutsideClick
		>
			<div className={styles.wrapper}>
				{phone && (
					<div className={styles.content}>
						{phoneFields.map((field, idx) => {
							const phoneFields = phone[field.key];
							if (phoneFields) {
								return (
									<React.Fragment key={idx}>
										<span className={styles.abbreviation}>
											{field.label}:
										</span>
										<div className={styles['result-container']}>
											{field.key === 'phone_number' ? (
												<Phone phone={phoneFields} formatPhone/>
											) : (
												<span className={styles.result}>
													{phoneFields}
												</span>
											)}
											{field.clipboard
                                                && <CopyToClipboardButton text={phoneFields} format='phone'/>}
										</div>
									</React.Fragment>
								);
							}

							return null;
						})}
						{phone.tags.length > 0 && (
							<>
								<span className={styles.abbreviation}>
                                    Из записной книжки:
								</span>
								<div className={styles['content-group']}>
									{phone.tags.map((item, index) => (
										item.trim() !== '' && (
											<span key={index}>
                                                # <span className={styles.result}>
													{item}
												</span>
											</span>
										)
									))}
								</div>
							</>
						)}
					</div>
				)}
			</div>
		</ModalWindow>
	);
};
