import React from 'react';
import {AuthHistoryTable} from '../../Tables/AuthHistoryTable/AuthHistoryTable';
import {useAppSelector} from '../../../redux/redux-hooks';

export const LoginHistory = () => {
	const authHistory = useAppSelector(state => state.getUserAuthHistory);

	return (
		<>
			<AuthHistoryTable
				value={authHistory.processedResponse}
				isLoading={authHistory.responseStatus.isLoading}
			/>
		</>
	);
};
