import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../../redux/redux-hooks';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../../../paths';
import {ButtonConfirm} from '../../index';
import {type SubCheckReport} from '../../../../../models/ICheck';

type ModalSelectCheckButtonProps = {
	setVisible: (value: boolean) => void;
	openSubModal: () => void;
	containerId: string | undefined;
};

export const ModalSelectCheckButton: React.FC<ModalSelectCheckButtonProps> = ({containerId, openSubModal, setVisible}) => {
	const subCheck = useAppSelector(state => state.getCheckReportById.processedResponse?.subchecks);
	const [selectedSubCheck, setSelectedSubCheck] = useState<SubCheckReport | undefined>(undefined);

	const navigate = useNavigate();

	useEffect(() => {
		if (subCheck && containerId) {
			setSelectedSubCheck(subCheck[containerId]);
		}
	}, [subCheck]);

	const goToSubCheck = () => {
		if (typeof selectedSubCheck?.check_id === 'number') {
			setVisible(false);
			navigate(paths.report.replace(':id', selectedSubCheck.check_id.toString()));
		}
	};

	return (
		<>
			{containerId && (
				selectedSubCheck
					? (
						<ButtonConfirm
							key='openReport'
							onclickHandler={goToSubCheck}
							text='Открыть отчет'
						/>
					)
					: (
						<ButtonConfirm
							key='ButtonConfirm'
							onclickHandler={openSubModal}
							text='Запустить подпроверку'
						/>
					)
			)}
		</>
	);
};
