import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putUserPermissions} from '../../../api/permissionsApi';
import {
	type PutUserPermissionsResponse,
	type PutUserPermissionsRequest,
} from '../../../models/IPermissions';

const putUserPermissionsAction = createActionTemplateAxios<
PutUserPermissionsResponse,
PutUserPermissionsRequest
>('permissions/putUserPermission/action', putUserPermissions, 'userToken');

export default putUserPermissionsAction;
