import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetNotificationsResponse} from '../../../models/INotifications';
import getNotificationsAction from '../../action/notifications/getNotificationsAction';
import {getFullName} from '../../../utils';
import {DateTime} from 'luxon';

export type SenderType = 'Все' | 'Системные' | 'Пользовательские';

type FiltersNotification = {
	senderType: SenderType[];
};

type GetNotificationsSliceState = {
	responseStatus: ResponseStatus;
	response: GetNotificationsResponse | undefined;
	processedResponse: {
		response: GetNotificationsResponse;
		unreadCount: number;
	} | undefined;
	filteredResponse: GetNotificationsResponse;
	filters: FiltersNotification;
	dropdownFilteredResponse: GetNotificationsResponse;
	newNotificationId: number | undefined;
};

const initialState: GetNotificationsSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	processedResponse: undefined,
	filteredResponse: [],
	dropdownFilteredResponse: [],
	filters: {
		senderType: ['Все', 'Системные', 'Пользовательские'],
	},
	newNotificationId: getNotificationId(),
};

function getNotificationId() {
	const notification = localStorage.getItem('newNotificationId');
	return notification
		? parseInt(notification, 10) : undefined;
}

const getProcessedResponse = (response: GetNotificationsResponse) => {
	const processedResponse = response.map(item => ({
		...item,
		creator: {
			full_name: getFullName(item.creator.first_name, item.creator.middle_name, item.creator.last_name, 'LF'),
			first_name: item.creator.first_name,
			middle_name: item.creator.middle_name,
			last_name: item.creator.last_name,
			department_name: item.creator.department_name,
		},
		created_date: DateTime.fromISO(item.created_date).toRelative() ?? '',
	}));

	const unreadCount = processedResponse.filter(notification => !notification.viewed).length;

	return {
		response: processedResponse,
		unreadCount,
	};
};

const filteredResponse = (response: GetNotificationsResponse, senderType: SenderType) => {
	if (senderType === 'Все') {
		return response;
	}

	if (senderType === 'Пользовательские') {
		return response.filter(notification => notification.creator.full_name);
	}

	if (senderType === 'Системные') {
		return response.filter(notification => !notification.creator.full_name);
	}

	return [];
};

const saveNewNotificationId = (notificationId: number) => {
	localStorage.setItem('newNotificationId', JSON.stringify(notificationId));
};

const getNotificationsSlice
	= createSlice({
		name: 'notifications/getNotifications',
		initialState,
		reducers: {
			getNotificationsResetState: () => initialState,
			getNotificationsUpdateUnReadCount(state, action: PayloadAction<number>) {
				if (state.processedResponse) {
					state.processedResponse.unreadCount -= 1;
					state.processedResponse.response = state.processedResponse.response.map(
						item =>
							item.id === action.payload
								? {
									...item,
									viewed: true,
								}
								: item);
				}

				state.filteredResponse = state.filteredResponse.map(
					item =>
						item.id === action.payload
							? {
								...item,
								viewed: true,
							}
							: item);

				return state;
			},
			getNotificationsFilters(state, action: PayloadAction<SenderType>) {
				if (state.processedResponse) {
					state.filteredResponse = filteredResponse(state.processedResponse.response, action.payload);
				}

				return state;
			},
			getNotificationsAllRead(state) {
				if (state.filteredResponse) {
					state.filteredResponse = state.filteredResponse.map(item => ({...item, viewed: true}));
				}

				if (state.processedResponse) {
					state.processedResponse.response = state.processedResponse.response.map(item => ({...item, viewed: true}));
					state.processedResponse.unreadCount = 0;
				}

				return state;
			},
			getNotificationsDropdownFilters(state, action: PayloadAction<SenderType>) {
				if (state.processedResponse) {
					state.dropdownFilteredResponse = filteredResponse(state.processedResponse.response, action.payload);
				}

				return state;
			},
		},
		extraReducers(builder) {
			builder
				.addCase(getNotificationsAction.pending, state => {
					state.responseStatus.isLoading = true;
					state.responseStatus.isCompleted = false;
					state.responseStatus.isError = false;
				})
				.addCase(getNotificationsAction.fulfilled, (state, action) => {
					state.responseStatus.isLoading = false;
					state.responseStatus.isCompleted = true;
					state.responseStatus.isError = false;
					state.response = action.payload;
					state.processedResponse = getProcessedResponse(action.payload);
					state.filteredResponse = state.processedResponse.response;
					state.dropdownFilteredResponse = state.processedResponse.response;
					const newNotificationId = action.payload[0]?.id;
					if (!action.payload[0]?.viewed && state.newNotificationId !== newNotificationId) {
						saveNewNotificationId(newNotificationId);
						state.newNotificationId = newNotificationId;
					}
				})
				.addCase(getNotificationsAction.rejected, (state, action) => {
					state.responseStatus.isLoading = false;
					state.responseStatus.isCompleted = false;
					state.responseStatus.isError = true;
					state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
					state.responseStatus.status = action.payload?.status;
				});
		},
	});

export const {
	getNotificationsResetState,
	getNotificationsUpdateUnReadCount,
	getNotificationsFilters,
	getNotificationsAllRead,
	getNotificationsDropdownFilters,
} = getNotificationsSlice.actions;

export default getNotificationsSlice.reducer;
