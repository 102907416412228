import React, {useState, useEffect} from 'react';
import styles from './ModalEditingEmployee.module.css';
import ModalWindow from '../ModalWindow';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {Dropdown, InputDate, InputName} from '../../UiKit/Inputs';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {
	getEmployeeAction,
	putEmployeeAction,
} from '../../../redux/action/employees';
import {ErrorMessage} from '../../UiKit/Message';
import {
	getPermissionsRolesAction,
	putPermissionsAction,
} from '../../../redux/action/permissions';
import {type Role} from '../../../models/Schemas';
import {getUserByIdAction} from '../../../redux/action/users';
import {getEmployeesResetState, putEmployeeResetStatus} from '../../../redux/slice/employees';
import {resetGetUserByIdState} from '../../../redux/slice/users';
import {checkPermission} from '../../../utils/permissions/permissions';
import {typePermissions} from '../../../models/Enum';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import {putPermissionsResetStatus} from '../../../redux/slice/permissions';

type ModalEditingEmployeeProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	employeeId: number | undefined;
};

export const ModalEditingEmployee: React.FC<ModalEditingEmployeeProps> = ({
	visible,
	setVisible,
	employeeId,
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [dateBirth, setDateBirth] = useState<string | undefined>(undefined);
	const [roleType, setRoleType] = useState<Role | undefined>(undefined);
	const [roleOptions, setRoleOptions] = useState<Role[] | undefined>(undefined);
	const [isRoleUpdate, setIsRoleUpdate] = useState(false);

	const [isValidName, setIsValidName] = useState(false);
	const [isValidLastName, setIsValidLastName] = useState(false);
	const [isValidMiddleName, setIsValidMiddleName] = useState(false);
	const [isValidDateBirth, setIsValidDateBirth] = useState(false);

	const getEmployee = useAppSelector(state => state.getEmployee);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const myId = useAppSelector(state => state.getUser.user?.id);
	const myDepartment = useAppSelector(state => state.getUser.user?.department?.id);

	const putEmployee = useAppSelector(state => state.putEmployee);

	const getRoles = useAppSelector(state => state.getPermissionsRoles);

	const getUserById = useAppSelector(state => state.getUserById);
	const putPermissions = useAppSelector(state => state.putPermissions);

	const dispatch = useAppDispatch();

	// Кнопка отмена
	const handleCancel = () => {
		setVisible(false);
	};

	// Кнопка сохранить
	const handleSave = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (employeeId) {
			if (dateBirth && isValidDateBirth && isValidLastName && isValidMiddleName && isValidName) {
				void dispatch(
					putEmployeeAction({
						employeeId,
						body: {
							first_name: name,
							last_name: lastName,
							middle_name: middleName,
							birth_date: dateBirth,
						},
					}),
				);
			} else {
				notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
			}
		}
	};

	// Очистка данных окна
	const cleanWindowData = () => {
		dispatch(getEmployeesResetState());
		dispatch(resetGetUserByIdState());
		dispatch(putPermissionsResetStatus());
		dispatch(putEmployeeResetStatus());
		setName('');
		setLastName('');
		setMiddleName('');
		setDateBirth(undefined);
		setRoleType(undefined);
		setRoleOptions(undefined);
		setIsValidName(false);
		setIsValidLastName(false);
		setIsValidMiddleName(false);
		setIsValidDateBirth(false);
		setIsRoleUpdate(false);
		setShowValidation(false);
	};

	// Открытие и закрытие окна
	useEffect(() => {
		cleanWindowData();
		if (visible && employeeId) {
			void dispatch(getEmployeeAction({employeeId}));
		}
	}, [visible]);

	// Получение данных о сотруднике
	useEffect(() => {
		if (visible && getEmployee.responseStatus.isCompleted && getEmployee.response) {
			const {response} = getEmployee;
			const userId = response.user_id;
			const isMe = userId === myId;
			const isMyDepartment = response.department_id === myDepartment;
			const isRoleUpdate = checkPermission(
				typePermissions.role_update,
				{
					groupPermissions: getPermissions.groupPermissions,
					isMe,
					isMyDepartment,
				},
			);
			setIsRoleUpdate(isRoleUpdate);

			setName(response.first_name);
			setLastName(response.last_name);
			setMiddleName(response.middle_name);
			setDateBirth(response.birth_date);

			if (userId && isRoleUpdate) {
				void dispatch(getUserByIdAction({userId}));
				void dispatch(
					getPermissionsRolesAction({
						organization_id: getEmployee.response.organization_id,
					}),
				);
			}
		}
	}, [getEmployee.responseStatus.isLoading]);

	// Обработка обновления сотрудника
	useEffect(() => {
		if (visible) {
			if (putEmployee.responseStatus.isCompleted) {
				notifyMessage('success', 'Информация о сотруднике обновлена');
				setVisible(false);
			} else if (putEmployee.responseStatus.isError) {
				notifyMessage('error', 'Ошибка. Что-то пошло не так.');
			}
		}
	}, [putEmployee.responseStatus.isLoading]);

	// Обработка обновления роли
	useEffect(() => {
		if (visible) {
			if (putPermissions.responseStatus.isCompleted) {
				notifyMessage('success', 'Роль обновлена');
			} else if (putPermissions.responseStatus.isError) {
				notifyMessage('error', 'Ошибка. Что-то пошло не так.');
			}
		}
	}, [putPermissions.responseStatus.isLoading]);

	// Получения Текущей роли и списка ролей
	useEffect(() => {
		if (visible && getRoles.responseStatus.isCompleted && getRoles.response
			&& getUserById.responseStatus.isCompleted && getUserById.response
		) {
			const userRoleId = getUserById.response.role_id;

			setRoleOptions(getRoles.response);
			setRoleType(getRoles.response.find(item => item.id === userRoleId));
		}
	}, [getRoles.responseStatus.isLoading,
		getUserById.responseStatus.isLoading]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			header={(
				<div className={styles.title}>
					<h1>Редактирование сотрудника</h1>
					<span>Для редактирования сотрудника введите необходимые данные</span>
				</div>
			)}
			footerItems={[
				<ButtonCancel key='ButtonCancel' text='Отменить' onclickHandler={handleCancel} />,
				<ButtonConfirm key='ButtonConfirm' text='Сохранить' onclickHandler={handleSave} />,
			]}
			width='50%'
			closable
			height='auto'
			isLoading={
				getEmployee.responseStatus.isLoading
				|| putEmployee.responseStatus.isLoading
			}
		>
			<div className={styles.card}>
				<div className={styles['input-group']}>
					<span>Личные данные</span>
					<div></div>
					<InputName
						value={lastName}
						setValue={setLastName}
						title='Фамилия'
						inputStyles={{
							placeholder: 'Фамилия сотрудника',
						}}
						inputValidation={{
							isValid: isValidName,
							setIsValid: setIsValidLastName,
							lettersOnly: true,
							isRequired: true,
							minLength: 2,
							showValidation,
							visible,
						}}
						parts={['SURNAME']}
					/>
					<InputName
						value={name}
						setValue={setName}
						title='Имя'
						inputStyles={{
							placeholder: 'Имя сотрудника',
						}}
						inputValidation={{
							isValid: isValidName,
							setIsValid: setIsValidName,
							lettersOnly: true,
							isRequired: true,
							minLength: 2,
							showValidation,
							visible,
						}}
						parts={['NAME']}
					/>
					<InputName
						value={middleName}
						setValue={setMiddleName}
						title='Отчество'
						inputStyles={{
							placeholder: 'Отчество сотрудника',
						}}
						inputValidation={{
							isValid: isValidMiddleName,
							setIsValid: setIsValidMiddleName,
							lettersOnly: true,
							minLength: 2,
							showValidation,
							visible,
						}}
						parts={['PATRONYMIC']}
					/>
					<InputDate
						title='Дата рождения'
						value={dateBirth}
						setValueString={setDateBirth}
						inputValidation={{
							isRequired: true,
							isValid: isValidDateBirth,
							setIsValid: setIsValidDateBirth,
							showValidation,
							visible,
						}}
					/>

					{isRoleUpdate && getEmployee.response?.user_id && (
						<Dropdown
							selectedValue={roleType}
							setSelectedValue={e => {
								if (getEmployee.response?.user_id) {
									const value = e as Role;

									void dispatch(
										putPermissionsAction({
											body: {user_role_id: (value).id},
											user_id: getEmployee.response.user_id,
										}),
									);
									setRoleType(value);
								}
							}}
							displayValueKey='name'
							title='Роль'
							options={roleOptions}
							placeholder='Выбрать роль'
							emptyMessage='Роли не найдены'
							isLoading={
								getUserById.responseStatus.isLoading
								|| putPermissions.responseStatus.isLoading
								|| getRoles.responseStatus.isLoading
							}
						/>
					)}
				</div>
				<ErrorMessage
					message={getRoles.responseStatus.errorMessage}
					isError={getRoles.responseStatus.isError}
				/>
				<ErrorMessage
					message={putPermissions.responseStatus.errorMessage}
					isError={putPermissions.responseStatus.isError}
				/>
				<ErrorMessage
					message={getUserById.responseStatus.errorMessage}
					isError={getUserById.responseStatus.isError}
				/>
				<ErrorMessage
					message={putEmployee.responseStatus.errorMessage}
					isError={putEmployee.responseStatus.isError}
				/>
				<ErrorMessage
					message={getEmployee.responseStatus.errorMessage}
					isError={getEmployee.responseStatus.isError}
				/>
			</div>
		</ModalWindow>
	);
};
