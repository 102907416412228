import {createAsyncThunk} from '@reduxjs/toolkit';
import {continueTimer, setTimer, stopTimer, updateTimer} from '../slice/timerSlice';
import {type TimerAction} from '../../models/Timer';
import {type RootState} from '../store';

export const startTimerAction = createAsyncThunk(
	'timer/startTimer',
	async (payload: TimerAction, {dispatch, getState}) => {
		if (payload.isContinuation) {
			dispatch(continueTimer());
		} else {
			dispatch(setTimer(payload));
		}

		let time = payload.value;
		const intervalId = setInterval(() => {
			if ((getState() as RootState).timer.isStop) {
				clearInterval(intervalId);
			} else if (time > 0) {
				time -= 1;
				dispatch(updateTimer(time));
			} else {
				dispatch(stopTimer());
				clearInterval(intervalId);
			}
		}, 1000);
	},
);
