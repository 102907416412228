import React, {useState} from 'react';
import styles from './ReportHeader.module.css';
import {ArrowButton, ButtonTemplate} from '../../UiKit/Button';
import {ModalReportUpdateResolution} from '../../Modal/Report';
import {useAppSelector} from '../../../redux/redux-hooks';

type ReportHeaderProps = {
	back: () => void;
};

const ReportHeader: React.FC<ReportHeaderProps> = ({back}) => {
	const [visibleModalResolution, setVisibleModalResolution] = useState(false);
	const processedResponse = useAppSelector(state => state.getCheckReportById.processedResponse);

	return (
		<>
			<ArrowButton
				onclickHandler={back}
				text={'Отчет проверки'}
				textStyle={styles['back-button-text']}
			/>
			{processedResponse?.candidate && typeof processedResponse.parent_check_id !== 'number' && (
				<div>
					<ButtonTemplate
						text={typeof processedResponse.resolution_result === 'boolean'
							? 'Обновить резолюцию'
							: 'Вынести резолюцию'
						}
						color='primary'
						onClick={() => {
							setVisibleModalResolution(true);
						}}
					/>
					<ModalReportUpdateResolution
						visible={visibleModalResolution}
						setVisible={setVisibleModalResolution}
					/>
				</div>
			)}
		</>
	);
};

export default ReportHeader;
