import React, {useRef, useState, useEffect} from 'react';
import DropDownButton from 'devextreme-react/drop-down-button';
import icon from '../../../../assets/notifications.svg';
import styles from './DropDownNotifications.module.css';
import {CustomNotificationItem} from '../../../Notification/CustomNotificationItem';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {getNotificationsDropdownFilters} from '../../../../redux/slice/notifications';
import {type SenderType} from '../../../../redux/slice/notifications/getNotificationsSlice';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../../paths';
import {List} from 'devextreme-react';
import {type Notification} from '../../../../models/INotifications';
import {ModalNotification} from '../../../Modal';

const DropDownNotifications: React.FC = () => {
	const [selectedNotifications, setSelectedNotifications] = useState<Notification | undefined>(undefined);
	const [visibleModalNotification, setVisibleModalNotification] = useState(false);

	const [senderType, setSenderType] = useState<SenderType>('Все');

	const [countNotifications, setCountNotifications] = useState(0);
	const [selectedItem, setSelectedItem] = useState(0);

	const getNotifications = useAppSelector(state => state.getNotifications);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const dropDownButtonRef = useRef<DropDownButton>(null);
	const isOpenedPopup = useRef(false);

	const notificationClassName = `${styles['dx-notification']}`;

	const handleItemClick = (index: number, senderType: SenderType) => {
		setSenderType(senderType);
		setSelectedItem(index);
	};

	useEffect(() => {
		setCountNotifications(getNotifications?.processedResponse?.unreadCount ?? 0);
	}, [getNotifications?.processedResponse?.unreadCount]);

	const handleSpanClick = () => {
		if (dropDownButtonRef.current && !isOpenedPopup.current) {
			void dropDownButtonRef.current.instance.open();
		}
	};

	const goToNotificationsPage = () => {
		if (dropDownButtonRef.current && isOpenedPopup.current) {
			void dropDownButtonRef.current.instance.close();
		}

		navigate(paths.notifications);
	};

	const renderDropDownContent = () => {
		if (getNotifications?.response) {
			return (
				<div className={styles.wrapper}>
					<div className={styles.title}>
						<h3>Уведомления</h3>
						<span onClick={goToNotificationsPage}>Все</span>
					</div>

					<ul className={styles.nav}>
						{getNotifications.filters.senderType.map((item, index) => (
							<li key={index}
								className={`${styles['nav-item']} ${selectedItem === index ? styles.active : ''}`}
								onClick={() => {
									handleItemClick(index, item);
								}}
							>
								{item}
							</li>
						))}
					</ul>
					<div className={styles.notifications}>
						<List
							dataSource={getNotifications.dropdownFilteredResponse}
							itemRender={(data: Notification) => <CustomNotificationItem item={data} isDropdown />}
							scrollingEnabled
							height={184}
							noDataText='Уведомления не найдены'
							onItemClick={e => {
								setVisibleModalNotification(true);
								setSelectedNotifications(e.itemData);
							}}
						/>
					</div>
				</div>
			);
		}
	};

	useEffect(() => {
		if (getNotifications.responseStatus.isCompleted) {
			dispatch(getNotificationsDropdownFilters(senderType));
		}
	}, [senderType, getNotifications.responseStatus.isLoading]);

	return (
		<div className={styles.content}>
			{countNotifications > 0 && <span onClick={handleSpanClick}>{countNotifications > 99 ? '99' : countNotifications}</span>}
			<DropDownButton
				dropDownOptions={{
					wrapperAttr: {class: notificationClassName},
					onShown() {
						isOpenedPopup.current = true;
					},
					onHidden() {
						isOpenedPopup.current = false;
					},
					hideOnParentScroll: true,
				}}
				icon={icon}
				showArrowIcon={false}
				useItemTextAsTitle={false}
				className={notificationClassName}
				noDataText='У вас нет новых уведомлений'
				ref={dropDownButtonRef}
				dropDownContentRender={renderDropDownContent}
			/>
			<ModalNotification
				visible={visibleModalNotification}
				setVisible={setVisibleModalNotification}
				notification={selectedNotifications}
			/>
		</div>
	);
};

export default DropDownNotifications;
