import {postCreateEmail} from '../../../api/checkApi';
import {type PostCheckCreateEmailResponse, type PostCheckCreateEmailRequest} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckCreateEmailAction = createActionTemplateAxios<
PostCheckCreateEmailResponse,
PostCheckCreateEmailRequest>(
	'checks/postCreateEmail/action',
	postCreateEmail,
	'userToken',
);

export default postCheckCreateEmailAction;
