import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../InputDevextreme.module.css';
import {DateRangeBox, Validator} from 'devextreme-react';
import {type TextBoxType} from 'devextreme/ui/text_box';
import {
	type InputValidation,
	type InputStyles,
	type InputMask,
	type InputAttr,
	type InputButton,
} from '../InputsTypes';
import {type KeyDownEvent} from 'devextreme/ui/date_range_box';
import {CustomRule, PatternRule, RequiredRule} from 'devextreme-react/cjs/validator';
import {Label, type LabelCommonProps} from '../../../Common';

export type InputRangeDateProps = DateRangeValue & DateRangeStyles & InputMask & {
	onKeyDown?: (e: KeyDownEvent) => void;
	focusOnInput?: boolean;

	inputValidation?: InputValidation;

	endDateInputAttr?: InputAttr;
	startDataInputAttr?: InputAttr;
};

type DateRangeValue = {
	value: string[];
	setValue: (value: string[]) => void;
	dateSerializationFormat?: 'yyyy-MM-dd' | 'yyyy-MM-ddTHH:mm:ss' | 'yyyy-MM-ddTHH:mm:ssZ';
	max?: Date | number | string;
	min?: Date | number | string;
};

type DateRangeStyles = InputStyles & {
	styleType?: 'large' | 'medium' | 'small';
	disabled?: boolean;
	mode?: TextBoxType;
	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	icons?: InputButton[];
} & LabelCommonProps;

const InputRangeDate: React.FC<InputRangeDateProps> = ({
	value,
	setValue,
	dateSerializationFormat = 'yyyy-MM-dd',
	styleType = 'small',
	title,
	titleColor = 'asphalt',
	focusOnInput,
	disabled,
	inputValidation,
	onKeyDown,
	startDataInputAttr,
	endDateInputAttr,
	icons,
	...props
}) => {
	const [firstInput, setFirstInput] = useState(false);
	const [firstFocusOut, setFirstFocusOut] = useState(false);

	const dateBoxRef = useRef<DateRangeBox>(null);
	const validatorRef = useRef<Validator>(null);

	const wrapperClassName = `${styles.wrapper} ${styles['not-icons']}`;
	const inputClassName = `${styles['input-dx']} ${styles[styleType]}`;

	const validationMessagePosition = inputValidation?.validationMessagePosition ?? 'top';

	const validationMessageMode = inputValidation?.validationMessageMode ?? 'auto';

	const validation = useMemo(() => {
		if (inputValidation) {
			const {
				setIsValid,
				isRequired,
				customPatternRule,
				customRule,
			} = inputValidation;
			return (
				<Validator
					ref={validatorRef}
					onValidated={e => {
						if (setIsValid) {
							setIsValid(e.isValid ?? false);
						}
					}}
				>
					{isRequired
						&& <RequiredRule
							message='Поле должно быть заполнено'
						/>}
					{customPatternRule?.map((item, index) => (
						<PatternRule key={'patternRule' + index} {...item} />
					))}
					{customRule?.map((item, index) => (
						<CustomRule key={`CustomRule ${index}`} {...item} />
					))}
				</Validator>
			);
		}

		return undefined;
	}, [inputValidation?.setIsValid,
		inputValidation?.isRequired,
		inputValidation?.requiredLength,
		inputValidation?.minLength,
		inputValidation?.lettersOnly,
		inputValidation?.customPatternRule,
		inputValidation?.customRule]);

	// Получение массива кнопок
	const buttons = useMemo(() => {
		const buttons: InputButton[] = [];

		if (icons) {
			buttons.push(...icons);
		}

		buttons.push({name: 'clear'}, {name: 'dropDown'});

		return buttons;
	}, [icons]);

	const onValueChange = useCallback((value: Array<Date | number | string>) => {
		const stringValue = value.filter(item => typeof item === 'string');

		setValue(stringValue as string[]);
	}, [setValue]);

	const validate = useCallback(() => {
		if (validatorRef) {
			validatorRef.current?.instance.validate();
		}
	}, []);

	const onInput = useCallback(() => {
		if (!firstInput) {
			setFirstInput(true);
		}
	}, [setFirstInput]);

	const onFocusOut = useCallback(() => {
		if (!firstFocusOut) {
			setFirstFocusOut(true);
		}

		validate();
	}, [firstFocusOut, setFirstFocusOut, validate]);

	useEffect(() => {
		if (focusOnInput) {
			dateBoxRef.current?.instance.focus();
		}
	}, [focusOnInput]);

	useEffect(() => {
		validate();
	}, []);

	useEffect(() => {
		if (inputValidation?.showValidation === false || inputValidation?.visible === false) {
			setFirstInput(false);
			setFirstFocusOut(false);
		}
	}, [inputValidation?.showValidation, inputValidation?.visible]);

	return (
		<>
			<Label
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				disabled={disabled}
				isRequired={inputValidation?.isRequired}
			/>
			<div className={wrapperClassName}>
				<DateRangeBox
					ref={dateBoxRef}
					className={inputClassName}
					dropDownOptions={{
						wrapperAttr: {
							class: styles['calendar-dropdown'],
						},
					}}
					value={value}
					onValueChange={onValueChange}
					onKeyDown={onKeyDown}
					applyValueMode='instantly'
					showClearButton
					showDropDownButton
					useMaskBehavior
					showMaskMode='always'
					displayFormat='dd.MM.yyyy'
					dateSerializationFormat={dateSerializationFormat}
					defaultValue={value}
					disabled={disabled}
					onClosed={validate}
					onChange={validate}
					onFocusOut={onFocusOut}
					onInput={onInput}
					validationMessageMode={validationMessageMode}
					validationMessagePosition={validationMessagePosition}
					isValid={
						firstInput || inputValidation?.showValidation
							? inputValidation?.isValid ?? true
							: true}
					buttons={buttons}
					endDateInputAttr={{...endDateInputAttr, autocomplete: 'new-password'}}
					startDateInputAttr={{...startDataInputAttr, autocomplete: 'new-password'}}

					{...props}
				>
					{validation}
				</DateRangeBox>
			</div>
		</>
	);
};

export default InputRangeDate;
