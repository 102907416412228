import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getStatisticsHome} from '../../../api/statistics';
import {type GetStatisticsResponse} from '../../../models/IStatistics';

const getStatisticsHomeAction = createActionTemplateAxios<GetStatisticsResponse, void>(
	'statistics/getStatisticsHome/action',
	getStatisticsHome,
	'userToken',
);

export default getStatisticsHomeAction;
