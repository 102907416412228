import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postDepartmentPositionsOpen} from '../../../api/departmentPositionsApi';
import {
	type PostDepartmentPositionsOpenRequest,
	type PostDepartmentPositionsOpenResponse,
} from '../../../models/IDepartmentPositions';

const postDepartmentPositionsOpenAction = createActionTemplateAxios<
PostDepartmentPositionsOpenResponse,
PostDepartmentPositionsOpenRequest
>(
	'departmentPositions/postDepartmentPositionsOpen/action',
	postDepartmentPositionsOpen,
	'userToken',
);

export default postDepartmentPositionsOpenAction;
