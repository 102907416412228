import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getEmployeesAutofill} from '../../../api/employeesApi';
import {
	type GetEmployeesAutofillRequest,
	type GetEmployeesAutofillResponse,
} from '../../../models/IEmployees';

const getEmployeesAutofillAction = createActionTemplateAxios<
GetEmployeesAutofillResponse,
GetEmployeesAutofillRequest
>('employees/getEmployeesAutofill/action', getEmployeesAutofill, 'userToken');

export default getEmployeesAutofillAction;
