import React from 'react';
import styles from './PublicGroups.module.css';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {TiGroup} from 'react-icons/ti';
import {AccountLink} from '../../../UiKit/Message';
import {ReportBlockTemplate} from '..';

export const PublicGroups = () => {
	const publicGroups = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.public_groups);

	return (
		<ReportBlockTemplate
			blockIcon={TiGroup}
			blockTitle='Публичные группы'
			alerts={publicGroups?.additionalData.alerts}
			blocksId='public_groups'
			visible={publicGroups && publicGroups.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['public-groups']}>
					{publicGroups?.data.map((publicGroup, index) => (
						<div key={index} className={styles.group}>
							<AccountLink key={index} link={publicGroup.item.url} textLink={publicGroup.item.name} type='telegram' />
						</div>
					))}
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
