import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type GetCommentByIdRequest, type GetCommentByIdResponse} from '../../../models/IComment';
import {getCommentById} from '../../../api/commentApi';

const getCommentByIdAction = createActionTemplateAxios<
GetCommentByIdResponse,
GetCommentByIdRequest
>('comments/getCommentById', getCommentById, 'userToken');

export default getCommentByIdAction;
