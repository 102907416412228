import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutContactResponse} from '../../../models/IContacts';
import {putContactAction} from '../../action/contacts';

type putContactSliceState = {
	responseStatus: ResponseStatus;
	response: PutContactResponse | undefined;
};

const initialState: putContactSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const putContactSlice = createSlice({
	name: 'contacts/putContact',
	initialState,
	reducers: {
		putContactSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(putContactAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putContactAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putContactAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {putContactSliceResetState} = putContactSlice.actions;
export default putContactSlice.reducer;
