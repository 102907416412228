import {Cookies} from 'react-cookie';
import {type GuestToken} from '../../../models/Guest/GuestToken/IPerson152fzToken';
import {cookiesKeys} from '..';

export const saveGuestToken = (token: GuestToken) => {
	const cookies = new Cookies();
	const tokenExpiresDate = new Date(token.expires_at);

	cookies.set(
		cookiesKeys.guestToken,
		token.token,
		{
			path: '/',
			expires: tokenExpiresDate,
			sameSite: 'strict',
			secure: true,
		},
	);
	cookies.set(
		cookiesKeys.guestTokenExpiresDate,
		token.expires_at,
		{
			path: '/',
			expires: tokenExpiresDate,
			sameSite: 'strict',
			secure: true,
		});
};
