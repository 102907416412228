export const paths = {
	home: '/',
	registration: '/registration',
	authNoActiveUser: '/registration/:id/:hash',
	auth: '/auth',
	checks: '/checks',
	analytics: '/analytics',
	control: '/control',
	subordinate: '/subordinate',
	addUsers: '/subordinate/add-users',
	addEmployees: '/subordinate/add-employees',
	profile: '/profile',
	profileUser: '/profile/:id',
	departments: '/',
	departmentPositions: '/departments/:id/positions',
	jobs: '/jobs/',
	JobCandidates: '/jobs/:id/Candidates',
	settings: '/settings',
	report: '/checks/:id',
	notifications: '/notifications',
	person: '/person',
	personUuid: '/person/:uuid',
	personLinkUuid: '/link/person/:uuid',
};
