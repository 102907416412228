import {createSlice} from '@reduxjs/toolkit';
import {sendSmsPhoneNumberAction} from '../../action/users';
import {type Token, type ResponseStatus} from '../../../models/IAuth';

type SmsCode = ResponseStatus & {token: Token | undefined};

const initialState: SmsCode = {
	isLoading: false,
	isCompleted: false,
	isError: false,
	errorMessage: '',
	token: undefined,
};

const smsPhoneNumberSlice = createSlice({
	name: 'smsCode',
	initialState,
	reducers: {
		smsCodeReset: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(sendSmsPhoneNumberAction.pending, state => {
				state.isCompleted = false;
				state.isLoading = true;
				state.isError = false;
				state.isCompleted = false;
				state.errorMessage = '';
				state.token = undefined;
			})
			.addCase(sendSmsPhoneNumberAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.token = action.payload;
			})
			.addCase(sendSmsPhoneNumberAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.token = undefined;
			});
	},
});

export const {smsCodeReset} = smsPhoneNumberSlice.actions;

export default smsPhoneNumberSlice.reducer;
