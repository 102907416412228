import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type GetPermissionsResponse} from '../../../models/IUsers';
import {getPermissions} from '../../../api/usersApi';

const getPermissionsAction = createActionTemplateAxios<
GetPermissionsResponse,
void
>('user/getPermissions/action', getPermissions, 'userToken');

export default getPermissionsAction;
