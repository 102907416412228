import React, {useState, useRef, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../redux/redux-hooks';
import {postCheckCreatePhoneAction} from '../../../../../redux/action/checks';
import {notifyMessage} from '../../../../UiKit/Toast/notifyMessage';
import {postCheckCreatePhoneSliceResetState} from '../../../../../redux/slice/checks';
import styles from '../ModalConfirmationEmail/ModalConfirmationEmail.module.css';
import {ButtonConfirm} from '../../../../UiKit/Button';
import ModalConfirmationSubCheck from '../ModalConfirmationSubCheck';
import {Phone} from '../../../../UiKit/Message';

type ModalConfirmationPhoneProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	phone: string | undefined;
	containerId: string | undefined;
};

const ModalConfirmationPhone: React.FC<ModalConfirmationPhoneProps> = ({phone, visible, setVisible, containerId}) => {
	const [isLoading, setIsLoading] = useState(false);

	const postCheckCreatePhoneRequestId = useRef<string | undefined>(undefined);

	const getUser = useAppSelector(state => state.getUser);
	const getCheckReportById = useAppSelector(state => state.getCheckReportById);
	const postCreatePhone = useAppSelector(state => state.postCheckCreatePhone);

	const dispatch = useAppDispatch();

	const sendPostCheckCreatePhone = () => {
		const parent_check_id = getCheckReportById.response?.id;
		const author_id = getUser.user?.id;

		if (phone && parent_check_id && author_id && containerId) {
			const employee_id = getCheckReportById.response?.employee?.id ?? null;
			const candidate_id = getCheckReportById.response?.candidate?.id ?? null;
			const person_id = getCheckReportById.response?.person?.id ?? null;

			postCheckCreatePhoneRequestId.current = dispatch(postCheckCreatePhoneAction({
				employee_id,
				candidate_id,
				person_id,
				author_id,
				check_type_id: null,
				parent_check_id,
				phone,
				container_id: containerId,
			})).requestId;
		}
	};

	useEffect(() => {
		if (postCheckCreatePhoneRequestId && postCheckCreatePhoneRequestId.current === postCreatePhone.responseStatus.requestId) {
			if (postCreatePhone.responseStatus.isCompleted) {
				notifyMessage('success', 'Дополнительная проверка успешно запущена.');
				setVisible(false);
			} else if (postCreatePhone.responseStatus.isLoading) {
				setIsLoading(true);
			} else if (postCreatePhone.responseStatus.isError) {
				notifyMessage('error', postCreatePhone.responseStatus.errorMessage);
				setVisible(false);
			}
		}
	}, [postCreatePhone.responseStatus.isLoading]);

	useEffect(() => {
		dispatch(postCheckCreatePhoneSliceResetState());
		postCheckCreatePhoneRequestId.current = undefined;

		if (!visible) {
			setIsLoading(false);
		}
	}, [visible]);

	return (
		<ModalConfirmationSubCheck
			visible={visible}
			setVisible={setVisible}
			header={
				<span className={styles.header}>
					Запуск дополнительной проверки по номеру телефона
				</span>
			}
			footerItems={[
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={sendPostCheckCreatePhone}
					text={'Подтвердить проверку'}
				/>,
			]}
			isLoading={isLoading}
			loadingMessage='Запуск дополнительной проверки'
			width='50%'
			minWidth={400}
		>
			<div>
				{phone && (
					<div className={styles.container}>
						<span>
							Запустить запрос на проверку по номеру телефона {<Phone phone={phone} formatPhone />}?
						</span>
					</div>
				)}
			</div>
		</ModalConfirmationSubCheck>
	);
};

export default ModalConfirmationPhone;
