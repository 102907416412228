import React, {useEffect, useState} from 'react';
import {ButtonTemplate} from '../../Button';
import styles from './BlockMenu.module.css';
import {Label, type LabelCommonProps} from '../../Common';
import {MenuButton} from '..';
import {type DropDownBox, List} from 'devextreme-react';

type BlockMenuProps = {
	items: BlockMenuItem[];
	selectedItem: BlockMenuItem | undefined;
	setSelectedItem: (value: BlockMenuItem | undefined) => void;
	countVisibleItems: number;
} & LabelCommonProps;

export type BlockMenuItem = {
	key: string;
	value: string;
};

const BlockMenu: React.FC<BlockMenuProps> = ({
	items,
	selectedItem,
	setSelectedItem,
	countVisibleItems,
	...props
}) => {
	const [visibleItems, setVisibleItems] = useState<BlockMenuItem[]>([]);
	const [dropdownItems, setDropdownItems] = useState<BlockMenuItem[]>([]);

	const handleItemClick = (item: BlockMenuItem | undefined) => {
		setSelectedItem(selectedItem?.key === item?.key ? undefined : item);
	};

	const buttonRender = () => (
		<ButtonTemplate
			text={'Ещё...'}
			color={selectedItem && dropdownItems.some(item => item.key === selectedItem.key) ? 'asphalt-transparent' : 'primary-outline'}
		/>
	);

	const contentRender = (ref?: React.RefObject<DropDownBox>) => (
		<List
			dataSource={dropdownItems}
			itemRender={(item: BlockMenuItem) => (
				<div
					className={`${styles.item} ${item.key === selectedItem?.key ? styles['item-selected'] : undefined}`}
				>
					{item.value}
				</div>
			)}
			scrollingEnabled
			onItemClick={e => {
				ref?.current?.instance.close();
				handleItemClick(e.itemData);
			}}
			selectedItem={selectedItem}
			searchEnabled
			searchMode='startswith'
			searchExpr='value'
			focusStateEnabled={false}
			activeStateEnabled={false}
			className={styles['dropdown-list']}
		/>
	);

	useEffect(() => {
		setVisibleItems(items.slice(0, countVisibleItems));
		setDropdownItems(items.slice(countVisibleItems));
	}, [items]);

	return (
		<>
			<Label {...props}/>
			<div className={styles.container}>
				{visibleItems?.map(item => (
					<ButtonTemplate
						key={item.key}
						text={item.value}
						onClick={() => {
							handleItemClick(item);
						}}
						color={selectedItem?.key === item.key ? 'asphalt-transparent' : 'primary-outline'}
					/>
				))}
				{dropdownItems.length > 0 && (
					<MenuButton
						contentRender={contentRender}
						buttonRender={buttonRender}
					/>)}
			</div>
		</>
	);
};

export default BlockMenu;
