import {type Token} from '../../../models/IAuth';
import {refreshToken} from '../../../api/authApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const refreshTokenAction = createActionTemplateAxios<
Token,
void>(
	'auth/refreshToken',
	refreshToken,
	'no',
);

export default refreshTokenAction;
