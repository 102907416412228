import React from 'react';
import styles from './ExecutiveOfficeWork.module.css';
import {HiOutlineClipboardDocumentList} from 'react-icons/hi2';
import {type CheckCourtDecisions} from '../../../../report/ReportModel';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {type ColumnsProps, Table} from '../../../UiKit/Tables';
import {Summary, TotalItem} from 'devextreme-react/data-grid';
import ReportBlockTemplate from '../ReportBlockTemplate';

type ExecutiveOfficeWorkProps = {
	courtDecisions?: CheckCourtDecisions[] | undefined;
};

const columns: ColumnsProps[] = [
	{
		caption: '№',
		allowSorting: true,
		dataField: 'item.rowNumber',
		width: 50,
	},
	{
		caption: 'Дата',
		dataField: 'item.doc_date',
		allowSorting: true,
		width: 100,
	},
	{
		caption: 'Номер документа',
		dataField: 'item.doc_number',
		allowSorting: true,
	},
	{
		caption: 'Номер дела',
		dataField: 'item.case_number',
		allowSorting: true,
	},
	{
		caption: 'Сумма сбора исполнителя',
		name: 'total',
		dataField: 'item.bailiff_total',
		allowSorting: true,
		cellRender(rowData) {
			const bailiffTotal = rowData.row.data.item.bailiff_total as number;
			const formatter = new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2});
			return bailiffTotal === 0 ? '-' : formatter.format(bailiffTotal);
		},
	},
];

export const ExecutiveOfficeWork: React.FC<ExecutiveOfficeWorkProps> = () => {
	const courtDecisions = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.court_decisions);

	return (
		<ReportBlockTemplate
			blockIcon={HiOutlineClipboardDocumentList}
			blockTitle='Исполнительные делопроизводства'
			alerts={courtDecisions?.additionalData.alerts}
			blocksId='court_decisions'
			visible={courtDecisions && courtDecisions?.data.length > 0}
		>
			<div className={styles.content}>
				<Table
					columns={columns}
					dataGridProps={{
						dataSource: courtDecisions?.data,
						allowColumnResizing: true}
					}
					maxHeight='400px'
				>
					<Summary >
						<TotalItem
							column='total'
							summaryType='sum'
							customizeText={({value}) => {
								const formattedValue = typeof value === 'number' ? new Intl.NumberFormat('ru-RU').format(value) : '0';
								return `Итого: ${formattedValue} ₽`;
							}}
						/>
					</Summary>
				</Table>
			</div>
		</ReportBlockTemplate>
	);
};
