import React, {useEffect, useState} from 'react';
import {type Notification} from '../../models/INotifications';
import styles from './CustomNotificationItem.module.css';
import {UserIcon} from '../UiKit/Div';

type CustomNotificationItemProps = {
	item: Notification;
	isDropdown?: boolean;
};

export const CustomNotificationItem: React.FC<CustomNotificationItemProps> = ({item, isDropdown}) => {
	const [priority, setPriority] = useState({text: '', additionalClass: ''});
	const [truncatedText, setTruncatedText] = useState('');

	useEffect(() => {
		const getPriority = (severity: string) => {
			if (severity === 'high') {
				return {text: 'Высокий приоритет', additionalClass: styles.high};
			}

			if (severity === 'normal') {
				return {text: 'Средний приоритет', additionalClass: styles.normal};
			}

			if (severity === 'low') {
				return {text: 'Низкий приоритет', additionalClass: styles.low};
			}

			return {text: 'Неизвестный приоритет', additionalClass: styles.unknown};
		};

		setPriority(getPriority(item.severity));
	}, [item.severity]);

	useEffect(() => {
		if (isDropdown) {
			const maxLength = 50;
			if (item.text.length > maxLength) {
				setTruncatedText(item.text.slice(0, maxLength) + '...');
			} else {
				setTruncatedText(item.text);
			}
		} else {
			setTruncatedText(item.text);
		}
	}, [item.text]);

	return (
		<div className={styles.content}>
			<div className={`${styles.priority} ${priority.additionalClass}`}>
				<span></span>
				<span>{priority.text}</span>
			</div>

			{item.creator.full_name ? (
				<div className={styles.author}>
					<UserIcon label={item.creator.full_name[0]} round size='small' />
					<span>{item.creator.full_name}</span>
				</div>
			) : <span className={styles.systemic}>Системное уведомление</span>

			}

			<p className={isDropdown ? styles['dropdown-message'] : styles.message}>{truncatedText}</p>

			<div className={isDropdown ? styles['dropdown-date'] : styles.date}>
				{item.creator.department_name && <span>{item.creator.department_name}</span>}
				<span>{item.created_date}</span>
			</div>
		</div>
	);
};
