import React, {useMemo, useRef, useState} from 'react';
import commonTableStyles from '../CommonTableStyles.module.css';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../paths';
import {Email, Phone} from '../../UiKit/Message';
import {TableIconButton} from '../../UiKit/Button';
import {ModalCheckCreated} from '../../Modal';
import {MdPersonSearch} from 'react-icons/md';
import {type CheckPermissions} from '../../Modal/ModalCheckCreated/ModalCheckCreated';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {type GetDepartmentEmployeesProcessedResponseItem} from '../../../redux/slice/department';
import {UserIcon} from '../../UiKit/Div';

type EmployeesTableProps = {
	employees?: GetDepartmentEmployeesProcessedResponseItem[];
	isLoading: boolean;
	employeeRead: boolean;
	isCheckRequestCreate: boolean;
	isZeroReportCreate: boolean;
	isCheckUser: boolean;
	isCheckEmployee: boolean;
	checkStart: boolean;
};

const EmployeesTable: React.FC<EmployeesTableProps> = ({
	employees,
	isLoading,
	employeeRead,
	isCheckRequestCreate,
	isZeroReportCreate,
	isCheckUser,
	isCheckEmployee,
	checkStart,
}) => {
	const navigate = useNavigate();
	const checkPermissions = useRef<CheckPermissions>({});

	const [isOpenCheckCreated, setIsOpenCheckCreated] = useState(false);
	const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | undefined>(undefined);

	// Открыть окно создания проверки
	const openCreateCheck = (id: number, permissions: CheckPermissions) => {
		setIsOpenCheckCreated(true);
		setSelectedEmployeeId(id);
		checkPermissions.current = permissions;
	};

	const columns: ColumnsProps[] = useMemo(() => [
		{
			caption: 'Сотрудник',
			dataField: 'fullName',
			cellRender(rowData) {
				const employee = rowData.row.data as GetDepartmentEmployeesProcessedResponseItem;

				if (employee.fullName) {
					return (
						<div
							className={commonTableStyles['table-link']}
							onClick={() => {
								if (employeeRead) {
									openEmployeeProfile(employee.id);
								}
							}}
						>
							<UserIcon
								label={employee.fullName[0]}
								size='medium'
								round
							/>
							<span
								className={`${commonTableStyles['column-text']} ${employee.user_id ? commonTableStyles['mint-text'] : ''}`}
								title={employee.fullName}
							>
								{employee.fullName}
							</span>
						</div>
					);
				}
			},
		},
		{
			caption: 'Телефон',
			dataField: 'phone',
			cellRender(rowData) {
				const employee = rowData.row.data as GetDepartmentEmployeesProcessedResponseItem;
				if (employee.phone) {
					return (
						<Phone phone={employee.phone} formatPhone />
					);
				}
			},
		},
		{
			caption: 'Электронный адрес',
			dataField: 'email',
			cellRender(rowData) {
				const employee = rowData.row.data as GetDepartmentEmployeesProcessedResponseItem;
				if (employee.email) {
					return (
						<Email email={employee.email} />
					);
				}
			},
		},
		{
			type: 'buttons',
			width: 50,
			cellRender(rowData) {
				const employee = rowData.row.data as GetDepartmentEmployeesProcessedResponseItem;
				const isCheck = (employee.user_id ? isCheckUser : isCheckEmployee) && (isCheckRequestCreate || isZeroReportCreate);

				const permissions: CheckPermissions = {
					isCreateCheckRequest: isCheckRequestCreate,
					isZeroReportCreate,
					isStartCheck: checkStart,
				};

				return (
					<div className={commonTableStyles['column-action']}>
						{isCheck && (
							<TableIconButton
								onClick={() => {
									openCreateCheck(employee.id, permissions);
								}}
								Icon={MdPersonSearch}
								TooltipMessage='Создать заявку на проверку'
							/>
						)}
					</div>
				);
			},
		},
	], [employees, isCheckUser, isCheckEmployee, isCheckRequestCreate, isZeroReportCreate]);

	const openEmployeeProfile = (id: number) => {
		if (employeeRead) {
			navigate(paths.profileUser.replace(':id', id.toString()));
		}
	};

	return (
		<>
			<Table
				columns={columns}
				dataGridProps={{
					dataSource: employees,
					allowColumnResizing: true,
				}}
				isLoading={isLoading}
			/>
			<ModalCheckCreated
				visible={isOpenCheckCreated}
				setVisible={setIsOpenCheckCreated}
				employeeId={selectedEmployeeId}
				permissions={checkPermissions}
			/>
		</>
	);
};

export default EmployeesTable;
