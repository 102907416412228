import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './ModalCreateNotification.module.css';
import ModalWindow from '../../ModalWindow';
import {Dropdown, TextArea} from '../../../UiKit/Inputs';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {getDepartmentSubordinateAction} from '../../../../redux/action/department';
import {ButtonCancel, ButtonConfirm} from '../../../UiKit/Button';
import {getNotificationsAction, postNotificationsAction} from '../../../../redux/action/notifications';
import {optionsNotificationsSeverity, optionsNotificationTypes} from '../../../../models/Enum';
import {ErrorMessage} from '../../../UiKit/Message';
import {DateTime} from 'luxon';
import {SelectDepartment} from '../../../CardRegister/SelectDepartment';

type ModalCreateNotificationProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
};

const ModalCreateNotification: React.FC<ModalCreateNotificationProps> = ({...props}) => {
	const [showValidation, setShowValidation] = useState(false);
	const dispatch = useAppDispatch();
	const getDepartmentsSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const organizationID = useAppSelector(state => state.organization.organization?.id);
	const postNotifications = useAppSelector(state => state.postNotifications);

	const [notificationText, setNotificationText] = useState<string>('');
	const [isValidNotificationText, setIsValidNotificationText] = useState(false);

	const [notificationSeverity, setNotificationSeverity] = useState(optionsNotificationsSeverity[0].key);
	const [isValidNotificationSeverity, setIsValidNotificationSeverity] = useState(false);
	const [notificationType, setNotificationType] = useState(optionsNotificationTypes[0].key);
	const [isValidNotificationType, setIsValidNotificationType] = useState(false);
	const [departmentId, setDepartmentId] = useState<number | undefined>(undefined);
	const [isValidDepartmentId, setIsValidDepartmentId] = useState(false);

	const header = useMemo(() => (
		<div className={styles.header}>
			<span className={styles.title}>Создание уведомления</span>
			<span className={styles.time}>{DateTime.now().toLocaleString()}</span>
		</div>
	), [props.visible]);

	const cancel = useCallback(() => {
		props.setVisible(false);
	}, [props.setVisible]);

	const save = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isValidNotificationText) {
			if (notificationType === 'organization' && organizationID !== undefined) {
				void dispatch(postNotificationsAction({
					text: notificationText,
					type: notificationType,
					severity: notificationSeverity,
					organization_id: organizationID,
				}));
			} else if (notificationType === 'department' && departmentId !== undefined) {
				void dispatch(postNotificationsAction({
					text: notificationText,
					type: notificationType,
					severity: notificationSeverity,
					department_id: departmentId,
				}));
			}
		}
	};

	const cleanWindowData = () => {
		setNotificationText('');
		setNotificationSeverity(optionsNotificationsSeverity[0].key);
		setNotificationType(optionsNotificationTypes[0].key);
		setDepartmentId(undefined);
		setShowValidation(false);
		setIsValidDepartmentId(false);
		setIsValidNotificationSeverity(false);
		setIsValidNotificationText(false);
		setIsValidNotificationType(false);
	};

	useEffect(() => {
		cleanWindowData();
		if (props.visible) {
			void dispatch(getDepartmentSubordinateAction());
		}
	}, [props.visible]);

	useEffect(() => {
		if (postNotifications.responseStatus.isCompleted) {
			props.setVisible(false);
			void dispatch(getNotificationsAction());
		}
	}, [postNotifications.responseStatus.isLoading]);

	return (
		<ModalWindow
			header={header}
			width='50%'
			visible={props.visible}
			setVisible={props.setVisible}
			closable
			footerItems={[
				<ButtonCancel key='ButtonCancel' text='Отмена' onclickHandler={cancel} />,
				<ButtonConfirm key='ButtonConfirm' text='Создать уведомление' onclickHandler={save} />,
			]}
			isLoading={postNotifications.responseStatus.isLoading}
			loadingMessage='Отправка уведомления'
		>
			<div className={styles.container}>
				<div className={styles.content}>
					<Dropdown
						title='Важность'
						selectedValue={notificationSeverity}
						setSelectedValue={setNotificationSeverity}
						options={optionsNotificationsSeverity}
						displayValueKey='text'
						setValueKey='key'
						isRequired
						isValid={isValidNotificationSeverity}
						setIsValid={setIsValidNotificationSeverity}
						showValidation={showValidation}
						visible={props.visible}
					/>
					<Dropdown
						title='Уведомление'
						selectedValue={notificationType}
						setSelectedValue={setNotificationType}
						options={optionsNotificationTypes}
						displayValueKey='text'
						setValueKey='key'
						isRequired
						isValid={isValidNotificationType}
						setIsValid={setIsValidNotificationType}
						showValidation={showValidation}
						visible={props.visible}
					/>
					{notificationType === 'department' && (
						<>
							<span>Отделы</span><div></div>
							<SelectDepartment
								title='Отдел'
								selectedId={departmentId}
								setSelectedId={setDepartmentId}
								departmentOptions={getDepartmentsSubordinate.response}
								departmentOptionsIsLoading={getDepartmentsSubordinate.responseStatus.isLoading}
								showValidation={showValidation}
								isValid={isValidDepartmentId}
								setSelectedDepartmentIsValid={setIsValidDepartmentId}
								isRequired
								visible={props.visible}
							/>
						</>)}
					<TextArea
						value={notificationText}
						setValue={setNotificationText}
						inputStyles={{
							placeholder: 'Введите текст уведомления',
						}}
						inputValidation={{
							isValid: isValidNotificationText,
							setIsValid: setIsValidNotificationText,
							isRequired: true,
							minLength: 2,
							showValidation,
							visible: props.visible,
						}}
						title='Описание'
					/>
				</div>
				<ErrorMessage
					isError={getDepartmentsSubordinate.responseStatus.isError}
					message={getDepartmentsSubordinate.responseStatus.errorMessage}
				/>
				<ErrorMessage
					isError={postNotifications.responseStatus.isError}
					message={postNotifications.responseStatus.errorMessage}
				/>
			</div>
		</ModalWindow>
	);
};

export default ModalCreateNotification;
