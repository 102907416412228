import {
	type GetDepartmentEmployeesRequest,
	type GetDepartmentEmployeesResponse,
} from '../../../models/IDepartment';
import {getDepartmentEmployees} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getDepartmentEmployeesAction = createActionTemplateAxios<
GetDepartmentEmployeesResponse,
GetDepartmentEmployeesRequest>(
	'department/getDepartmentEmployee/action',
	getDepartmentEmployees,
	'userToken',
);

export default getDepartmentEmployeesAction;
