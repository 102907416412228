import React from 'react';
import ModalWindow from '../ModalWindow';
import {type Contact} from '../../../models/Schemas';
import {Contacts} from '../../Contacts';

type ModalContactsProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	contacts: Contact[] | undefined;
	title?: string;
	isLoading?: boolean;
	loadingMessage?: string;
	contactDelete: boolean;
};

const ModalContacts: React.FC<ModalContactsProps> = ({
	visible,
	setVisible,
	contacts,
	title,
	isLoading,
	loadingMessage,
	contactDelete,
}) => (
	<ModalWindow
		visible={visible}
		setVisible={setVisible}
		header={<span>{title}</span>}
		isLoading={isLoading}
		loadingMessage={loadingMessage}
		closable={true}
		closeOnOutsideClick
	>
		<Contacts
			contacts={contacts}
			contactDelete={contactDelete}
		/>
	</ModalWindow>
);

export default ModalContacts;
