import React, {useEffect, useState} from 'react';
import {type CheckBox} from '../ModalCheckCreated';
import {CheckboxFiled} from '../../../UiKit/Checkbox';
import {type SendCheckParameter} from '../../../../models/Schemas';

type CheckBoxFieldProps = {
	updateValue: (sendCheckParameter: SendCheckParameter) => void;
	initValue: boolean;
} & CheckBox;

const CriterionField: React.FC<CheckBoxFieldProps> = ({...props}) => {
	const [isChecked, setIsChecked] = useState(props.initValue);

	useEffect(() => {
		props.updateValue({id: props.field.id, value: props.field.value, data: isChecked});
	}, [isChecked]);

	return (
		<CheckboxFiled
			label={props.field.name}
			checked={isChecked}
			setChecked={setIsChecked}
			helpMessage={props.field.description}
		/>
	);
};

export default CriterionField;
