import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getEmployee} from '../../../api/employeesApi';
import {
	type GetEmployeeRequest,
	type GetEmployeeResponse,
} from '../../../models/IEmployees';

const getEmployeeAction = createActionTemplateAxios<
GetEmployeeResponse,
GetEmployeeRequest
>('employees/getEmployee/action', getEmployee, 'userToken');

export default getEmployeeAction;
