import React from 'react';
import styles from './Transports.module.css';
import {type CheckTransports} from '../../../../report/ReportModel';
import {FaCar} from 'react-icons/fa';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {headersTransports} from './HeadersTransports';
import ReportBlockTemplate from '../ReportBlockTemplate';

type TransportsProps = {
	transports?: CheckTransports[] | undefined;
};

export const Transports: React.FC<TransportsProps> = () => {
	const transports = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.transports);

	return (
		<ReportBlockTemplate
			blocksId='transports'
			blockIcon={FaCar}
			blockTitle='Транспортные средства'
			alerts={transports?.additionalData.alerts}
			visible={transports && transports.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['transport-group']}>
					{transports?.data.map((transport, index) => (
						<div key={index} className={styles.transport}>
							{Object.entries(transport.item).map(([key, value]) => {
								if (typeof value === 'string') {
									return <div className={styles.info} key={key}>
										<p>{headersTransports[key]}
											<span>{value}</span>
										</p>
									</div>;
								}

								if (Array.isArray(value)) {
									if (typeof value[0] === 'string') {
										return <div className={styles.info} key={key}>
											<p>{headersTransports[key]}
												{value.map((item, index) => {
													if (typeof item === 'string') {
														return <span key={index}>{item}</span>;
													}

													return null;
												},
												)}

											</p>
										</div>;
									}

									if (value[0] && typeof value[0] === 'object') {
										return <div key={key} className={styles.info}>
											<p>{headersTransports[key]}
												{value.map((item, index) => {
													if (item && typeof item === 'object') {
														return <span key={index}>{item.full_name}</span>;
													}

													return null;
												})}

											</p>
										</div>;
									}
								}

								if (value && typeof value === 'object' && !Array.isArray(value)) {
									return <div key={key} className={styles.info}><p>{headersTransports[key]}
										<span>{value.full_name}</span>
									</p>
									</div>;
								}

								return null;
							},

							)}
						</div>
					))}

				</div>
			</div>
		</ReportBlockTemplate>
	);
};
