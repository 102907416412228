import {type CheckWorkplaces, type HeadersCheckReport} from '../../../../report/ReportModel';

export const headersWorkplaces: Array<HeadersCheckReport<keyof CheckWorkplaces>> = [
	{label: 'Организация:', key: 'organization'},
	{label: 'Дата трудоустройства:', key: 'start_work_date'},
	{label: 'Дата увольнения:', key: 'end_work_date'},
	{label: 'Заработная плата:', key: 'salary'},
	{label: 'Валюта:', key: 'currency'},
	{label: 'Должность:', key: 'post'},
	{label: 'Статус работника:', key: 'employment_status'},
	{label: 'Рабочий телефон:', key: 'work_phones'},
	{label: 'Дополнительный номер:', key: 'extension_phone_numbers'},
];
