import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetDepartmentEmployeesResponse} from '../../../models/IDepartment';
import {getDepartmentEmployeesAction} from '../../action/department';
import {type Employee} from '../../../models/Schemas';
import {getFullNameFromObject} from '../../../utils';
import {DateTime} from 'luxon';
import {contactType} from '../../../models/Enum';

type GetDepartmentSliceState = {
	responseStatus: ResponseStatus;
	response: GetDepartmentEmployeesProcessedResponseItem[] | undefined;
	filteredTableData: GetDepartmentEmployeesProcessedResponseItem[] | undefined;
};

export type GetDepartmentEmployeesProcessedResponseItem = Employee & {
	fullName?: string;
	phone?: string;
	email?: string;
};

export type FiltersGetDepartmentEmployeesTable = {
	fullName?: string;
};

const initialState: GetDepartmentSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	filteredTableData: undefined,
};

const getProcessedResponse = (response: GetDepartmentEmployeesResponse) => response.map<GetDepartmentEmployeesProcessedResponseItem>(
	employee => ({
		...employee,
		birth_date: employee.birth_date
			? DateTime.fromFormat(employee.birth_date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
			: '',
		fullName: getFullNameFromObject(employee, 'LFM'),
		phone: employee.contacts?.find(item => item.contact_type === contactType.phone)?.contact ?? '',
		email: employee.contacts?.find(item => item.contact_type === contactType.email)?.contact ?? '',
	}),
);

const GetDepartmentSlice = createSlice({
	name: 'department/getDepartmentEmployee',
	initialState,
	reducers: {
		resetState: () => initialState,
		filteredDepartmentEmployee(state, action: PayloadAction<FiltersGetDepartmentEmployeesTable>) {
			const {fullName} = action.payload;

			state.filteredTableData = state.response?.filter(item => (
				!fullName || item.full_name?.toLocaleLowerCase().includes(fullName.toLocaleLowerCase())
			));

			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getDepartmentEmployeesAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getDepartmentEmployeesAction.fulfilled, (state, action) => {
				state.responseStatus.requestId = action.meta.requestId;
				state.response = getProcessedResponse(action.payload);
				state.filteredTableData = state.response;
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getDepartmentEmployeesAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {resetState, filteredDepartmentEmployee} = GetDepartmentSlice.actions;
export default GetDepartmentSlice.reducer;
