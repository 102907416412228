import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

type ThemeState = {
	theme: string;
};

const setTheme = (theme: string) => {
	document.documentElement.dataset.theme = theme;
	localStorage.setItem('theme', theme);
};

const getTheme = () => {
	const theme = `${window?.localStorage?.getItem('theme')}`;
	let currentThem;

	if (['light', 'dark'].includes(theme)) {
		currentThem = theme;
	} else {
		const userMedia = window.matchMedia('(prefers-color-scheme: light)');
		currentThem = userMedia.matches ? 'light' : 'dark';
	}

	// ИЗМЕНИТЬ вернуть авто выбор темы
	currentThem = 'dark';
	setTheme(currentThem);
	return currentThem;
};

const initialState: ThemeState = {
	theme: getTheme(),
};

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		changeTheme(state, action: PayloadAction<string>) {
			state.theme = action.payload;
			setTheme(state.theme);
		},
	},
});

export default themeSlice.reducer;
