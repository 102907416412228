import axios, {type AxiosResponse} from 'axios';
import {
	type GetPersonalizedPdAgreementRequest,
	type GetPersonalizedPdAgreementResponse,
	type GetPerson152fzDocumentsRequest,
	type GetPerson152fzDocumentsResponse,
	type PostPerson152fzDocumentsRequest, type PostPerson152fzRequest, type PostPerson152fzResponse,
} from '../../models/Person/PersonDocuments/IPersonDocuments';
import {type GetTokenPerson152fzResponse, type GeTokenPerson152fzRequest} from '../../models/Guest/GuestToken/IPerson152fzToken';
import {getGuestToken} from '../../utils/cookies/guestToken/getGuestToken';
import {getToken} from '../../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/person152fz';

// Получение документов
export async function getPerson152fzDocuments({params}: GetPerson152fzDocumentsRequest): Promise<AxiosResponse<GetPerson152fzDocumentsResponse>> {
	return axios.get(
		`${domain}${urlPrefix}/documents`,
		{
			params,
			headers: {
				Authorization: `Bearer ${getGuestToken().token}`,
			},
		},
	);
}

// Сохранение документов
export async function postPerson152fzDocuments({body}: PostPerson152fzDocumentsRequest): Promise<AxiosResponse<void>> {
	return axios.post(
		`${domain}${urlPrefix}/documents`,
		body,
		{
			headers: {
				Authorization: `Bearer ${getGuestToken().token}`,
			},
		},
	);
}

// Получить токен
export async function getTokenPerson152fz(data: GeTokenPerson152fzRequest): Promise<AxiosResponse<GetTokenPerson152fzResponse>> {
	return axios.get(`${domain}${urlPrefix}/uuid/${data.uuid}`);
}

// Получение персонализированного согласия на обработку ПД
export async function getPersonalizedPdAgreement({params}: GetPersonalizedPdAgreementRequest): Promise<AxiosResponse<GetPersonalizedPdAgreementResponse>> {
	return axios.get(`${domain}${urlPrefix}/agreement`, {
		params,
		headers: {
			Authorization: `Bearer ${getGuestToken().token}`,
		},
		responseType: 'blob',
	});
}

// Отправка ссылки кандидату/сотруднику для заполнения ПД
export async function postPerson152fz({body}: PostPerson152fzRequest): Promise<AxiosResponse<PostPerson152fzResponse>> {
	return axios.post(`${domain}${urlPrefix}`, body, {
		headers: {
			Authorization: `Bearer ${getToken().token}`,
		},
	});
}
