import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getCandidate} from '../../../api/candidatesApi';
import {type GetCandidateRequest, type GetCandidateResponse} from '../../../models/ICandidates';

const getCandidateAction = createActionTemplateAxios<
GetCandidateResponse,
GetCandidateRequest>(
	'candidates/getCandidate/action',
	getCandidate,
	'userToken',
);

export default getCandidateAction;
