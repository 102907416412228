import React, {useState} from 'react';
import styles from './CheckAddress.module.css';
import {FaHome} from 'react-icons/fa';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {type CheckAddresses} from '../../../../report/ReportModel';
import {ModalCheckAddresses, ModalConfirmationAddress} from '../../../Modal/Report';
import {CopyToClipboardButton} from '../../../UiKit/Button';
import {SelectCheckButton} from '../../../UiKit/Button/ReportButtons';
import {ReportBlockTemplate} from '..';

export const CheckAddress = () => {
	const [visibleModalCheckAddress, setVisibleModalCheckAddress] = useState(false);
	const [visibleModalSubCheckFromAddress, setVisibleModalSubCheckFromAddress] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState<CheckAddresses | undefined>(undefined);
	const [selectedContainerId, setSelectedContainerId] = useState<string>();

	const addresses = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.address);

	const openModalCheckAddresses = (address: CheckAddresses, containerId?: string) => {
		setSelectedAddress(address);
		setVisibleModalCheckAddress(true);
		setSelectedContainerId(containerId);
	};

	const openModalSubCheckFromAddress = (address: CheckAddresses, containerId?: string) => {
		setSelectedAddress(address);
		setVisibleModalSubCheckFromAddress(true);
		setSelectedContainerId(containerId);
	};

	return (
		<ReportBlockTemplate
			blocksId='address'
			blockIcon={FaHome}
			blockTitle='Адреса'
			visible={addresses && addresses.data.length > 0}
			alerts={addresses?.additionalData.alerts}
		>
			<div className={styles.content}>
				<div className={styles.address}>
					<div className={styles.info}>
						<p>
							Возможные адреса:
						</p>
						<div className={styles['result-group']}>
							{addresses?.data.map((address, index) => {
								if (address.item.result) {
									return (
										<div key={index} className={styles.result}>
											<span onClick={() => {
												openModalCheckAddresses(address.item, address.containerId);
											}}>
												{address.item.result}
											</span>
											<CopyToClipboardButton text={address.item.result} />
											<SelectCheckButton
												openSubCheck={() => {
													openModalSubCheckFromAddress(address.item, address.containerId);
												}}
												containerId={address.containerId}
											/>
										</div>
									);
								}

								return null;
							})}
						</div>
					</div>
				</div>
			</div>
			<ModalCheckAddresses
				visible={visibleModalCheckAddress}
				setVisible={setVisibleModalCheckAddress}
				address={selectedAddress}
				setVisibleSubModal={setVisibleModalSubCheckFromAddress}
				containerId={selectedContainerId}
			/>
			<ModalConfirmationAddress
				visible={visibleModalSubCheckFromAddress}
				setVisible={setVisibleModalSubCheckFromAddress}
				address={selectedAddress}
				containerId={selectedContainerId}
			/>
		</ReportBlockTemplate>
	);
};
