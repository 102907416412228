import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/Manrope/stylesheet.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import {CookiesProvider} from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
	<CookiesProvider defaultSetOptions={{path: '/'}}>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	</CookiesProvider>,
);
