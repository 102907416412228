import './styles/dx.generic.custom-scheme.css';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {paths} from './paths';
import {
	Register,
	Auth,
	Layout,
	Profile,
	AuthNoActivatedUserPage,
	DepartmentPositions,
	AddUsers,
	AddEmployees,
	JobsCandidates,
	Settings,
	CheckReport,
	NotFoundPage,
	Notifications,
	Person,
	PersonUuid,
} from './pages';
import {navigationData} from './components/Navigation';
import ruMessages from 'devextreme/localization/messages/ru.json';
import {locale, loadMessages} from 'devextreme/localization';
import {headerNavigationData} from './components/Navigation/HeaderNavigation/HeaderNavigationData';

loadMessages(ruMessages);
locale(navigator.language);

function App() {
	return (
		<Routes>
			<Route
				path={paths.authNoActiveUser}
				element={<AuthNoActivatedUserPage />}
			/>
			<Route path={paths.registration} element={<Register />} />
			<Route path={paths.auth} element={<Auth />} />
			<Route path={paths.person} element={<Person />} />
			{/* ИЗМЕНИТЬ, когда будет известно на какой роут */}
			<Route path={paths.personUuid} element={<PersonUuid />} />
			<Route path={paths.personLinkUuid} element={<PersonUuid />} />
			<Route path={paths.home} element={<Layout />}>
				{navigationData.map(item => (
					<Route key={item.id} path={item.path} element={<item.component />} />
				))}
				{headerNavigationData.map(item => (
					<Route key={item.id} path={item.path} element={<item.component />} />
				))}
				<Route path={paths.departmentPositions} element={<DepartmentPositions />}/>
				<Route path={paths.profile} element={<Profile />} />
				<Route path={paths.profileUser} element={<Profile />} />
				<Route path={paths.addUsers} element={<AddUsers />} />
				<Route path={paths.addEmployees} element={<AddEmployees />} />
				<Route path={paths.JobCandidates} element={<JobsCandidates />} />
				<Route path={paths.settings} element={<Settings />} />
				<Route path={paths.report} element={<CheckReport />} />
				<Route path={paths.notifications} element={<Notifications />} />
			</Route>
			<Route path='*' element={<NotFoundPage message='Страница не найдена' status={404}/>} />
		</Routes>
	);
}

export default App;
