export const getAgeString = (age: number): string => {
	const lastDigit = age % 10;
	const lastTwoDigits = age % 100;

	if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
		return `${age} лет`;
	}

	const suffix = lastDigit === 1
		? 'год' : lastDigit >= 2 && lastDigit <= 4
			? 'года' : 'лет';

	return `${age} ${suffix}`;
};

