import React from 'react';
import styles from '../../ReportCard.module.css';
import {type ReportModelV3Email} from '../../../../../../../report/reportModelV3/reportModelV3';
import {Email} from '../../../../../Message';
import {CopyToClipboardButton} from '../../../../../Button';
import {SelectCheckButton} from '../../../../../Button/ReportButtons';

type EmailCardProps = {
	email: ReportModelV3Email;
	onClick: () => void;
	onClickSub: () => void;
	containerId: string | undefined;
};

const EmailCard: React.FC<EmailCardProps> = ({
	email,
	onClick,
	onClickSub,
	containerId,
}) => (
	<div className={`${styles.card} ${styles.click}`} onClick={onClick}>
		<div className={styles.header}>
			{email.type}
		</div>
		<div className={styles.content}>
			<div className={styles.info}>
				<span className={`${styles.contact} ${styles['text-overflow']}`}>
					<Email email={email.email}/>
				</span>
				<CopyToClipboardButton text={email.email}/>
				<SelectCheckButton containerId={containerId} openSubCheck={onClickSub}/>
			</div>
		</div>
	</div>
);

export default EmailCard;
