export const formatPhoneNumber = (phoneNumber: string | undefined) => {
	if (!phoneNumber) {
		return '';
	}

	const cleaned = String(phoneNumber).replace(/\D/g, '');
	const match = /^(7|8)?(\d{3})(\d{3})(\d{2})(\d{2})/.exec(cleaned);

	// Проверяем, начинается ли номер телефона с 8 или 7
	if (match && (match[1] === '8' || match[1] === '7')) {
		return `+7 (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
	}

	// Если номер телефона не начинается с 8 или 7, возвращаем его как есть
	return phoneNumber;
};
