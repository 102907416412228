import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type getDepartmentsResponse} from '../../../models/IOrganization';
import getDepartmentsAction from '../../action/organization/getDepartmentsAction';

type DepartmentsSliceState = ResponseStatus & {
	departments: getDepartmentsResponse | undefined;
};

const initialState: DepartmentsSliceState = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	requestId: undefined,
	errorMessage: '',
	departments: undefined,
	status: undefined,
};

const DepartmentsSlice = createSlice({
	name: 'organization/getDepartments',
	initialState,
	reducers: {
		departmentsResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getDepartmentsAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.requestId = undefined;
			})
			.addCase(getDepartmentsAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.departments = action.payload;
				state.requestId = action.meta.requestId;
			})
			.addCase(getDepartmentsAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.status = action.payload?.status;
				state.requestId = action.meta.requestId;
			});
	},
});

export const {departmentsResetState} = DepartmentsSlice.actions;
export default DepartmentsSlice.reducer;
