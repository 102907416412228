import React, {useEffect, useState} from 'react';
import styles from '../OtpCode.module.css';
import arrow from '../../../assets/login/arrowLeft.svg';
import SixDigitalCode from '../SixDigitalCode';
import {startTimerAction} from '../../../redux/action/timerAction';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {useNavigate} from 'react-router-dom';
import {type ConfirmationPhoneNumber} from '../../../models/IUsers';
import {sendSmsPhoneNumberAction} from '../../../redux/action/users';
import {useCookies} from 'react-cookie';
import {smsCodeReset} from '../../../redux/slice/users/smsPhoneNumberSlice';
import {type TimerAction} from '../../../models/Timer';
import {sendSmsRegistrationAction} from '../../../redux/action/users';
import {verifyReset} from '../../../redux/slice/auth/verifySlice';
import {getUserAction} from '../../../redux/action/users';
import {stopTimer} from '../../../redux/slice/timerSlice';
import {ErrorMessage} from '../../UiKit/Message';
import {ButtonTemplate} from '../../UiKit/Button';
import {paths} from '../../../paths';

type OtpCodeRegisterProps = {
	phone: string;
	previousComponent?: () => void;
	isEditingPhoneNumber?: boolean;
	closingModals?: () => void;
	isModal?: boolean;
	visible?: boolean;
};

export const OtpCodeRegister: React.FC<OtpCodeRegisterProps> = ({
	phone,
	previousComponent,
	isEditingPhoneNumber,
	closingModals,
	isModal = false,
	visible,
}) => {
	const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);
	const [cookies] = useCookies(['debug']);

	const [isDisabledSendCode, setIsDisabledSendCode] = useState(true);
	const navigate = useNavigate();

	// Redux
	const dispatch = useAppDispatch();
	const {isStop, value, isContinuation} = useAppSelector(
		state => state.timer,
	);
	const {isError, isLoading, isCompleted, errorMessage} = useAppSelector(
		state => state.smsCode,
	);
	const {sms_sent_at, phone_last_charts, code_number} = useAppSelector(
		state => state.smsRegistration,
	);

	useEffect(() => {
		if (!visible) {
			setCode(['', '', '', '', '', '']);
		}
	}, [visible]);

	useEffect(() => {
		if (phone_last_charts) {
			localStorage.setItem('phoneLastCharts', phone_last_charts);
		}
	}, [phone_last_charts]);

	const getPhoneFirstCharts = () => {
		if (phone_last_charts !== '') {
			return phone_last_charts.slice(0, 2);
		}

		return localStorage.getItem('phoneLastCharts')?.slice(0, 2);
	};

	const getPhoneLastCharts = () => {
		if (phone_last_charts !== '') {
			return phone_last_charts.slice(2);
		}

		return localStorage.getItem('phoneLastCharts')?.slice(2);
	};

	const cleanCode = () => {
		setCode(['', '', '', '', '', '']);
		dispatch(verifyReset());
	};

	const getCodeNumber = () => {
		// Получение номера смс
		if (code_number !== '') {
			return code_number;
		}

		return localStorage.getItem('codeNumber');
	};

	useEffect(() => {
		// Обработка получения смс
		if (code_number) {
			localStorage.setItem('codeNumber', code_number);
			startTimer();
		}
	}, [code_number]);

	useEffect(() => {
		// Продолжить таймера, если остановлен
		if (isContinuation) {
			startTimer();
		}
	}, [isContinuation]);

	const checkCode = () => {
		// Отправка кода на сервер
		const enteredCode: string = code.join('');

		const data: ConfirmationPhoneNumber = {
			code: enteredCode,
			phone,
			debug: Boolean(cookies.debug),
		};

		void dispatch(sendSmsPhoneNumberAction(data));
	};

	const checkCodeFull = () => {
		// Проверка что код введён полностью
		for (const item of code) {
			if (item === '') {
				return false;
			}
		}

		return true;
	};

	useEffect(() => {
		// Включение кнопки и отправка кода
		if (checkCodeFull()) {
			setIsDisabledSendCode(false);
			checkCode();
		} else {
			setIsDisabledSendCode(true);
		}
	}, [code]);

	const getNewSms = () => {
		// Запросить код в смс
		cleanCode();
		void dispatch(
			sendSmsRegistrationAction({
				phone,
				debug: Boolean(cookies.debug),
			}),
		);
	};

	const startTimer = () => {
		// Запуск таймера если остановлен или новая смс
		if (isContinuation) {
			const requestOptions: TimerAction = {
				value,
				startDate: 0,
				isContinuation,
			};
			void dispatch(startTimerAction(requestOptions));
		} else if (sms_sent_at) {
			const requestOptions: TimerAction = {
				value: 60,
				startDate: sms_sent_at,
				isContinuation,
			};
			void dispatch(startTimerAction(requestOptions));
		}
	};

	useEffect(() => {
		if (isStop) {
			startTimer();
		}
	}, []);

	const handleResponse = () => {
		localStorage.removeItem('phone');
		localStorage.removeItem('lastPhone');
		localStorage.removeItem('codeNumber');
		localStorage.removeItem('currentComponent');
		localStorage.removeItem('phoneLastCharts');
		dispatch(smsCodeReset());
		dispatch(stopTimer());
		if (isEditingPhoneNumber) {
			if (closingModals) {
				void dispatch(getUserAction());
				closingModals();
			}
		} else {
			navigate(paths.home);
		}
	};

	useEffect(() => {
		if (isCompleted) {
			handleResponse();
		}
	}, [isLoading]);

	const formatTime = (time: number) => {
		if (time > 59) {
			const minutes = Math.floor(time / 60);
			const seconds = time % 60;
			return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
		}

		return time < 10 ? `0${time}` : time.toString();
	};

	return (
		<div className={isEditingPhoneNumber ? '' : styles.container}>
			{isEditingPhoneNumber ? null : (
				<div className={styles.back} onClick={previousComponent}>
					<img src={arrow} alt='' />
					<span>Назад</span>
				</div>
			)}

			<div className={styles.card}>
				<div className={styles.title}>
					{!isModal && <h2>Введите код из СМС №{getCodeNumber()}</h2>}

					<p>
            На ваш номер телефона{' '}
						<strong>
              +7(9**) ***-{getPhoneFirstCharts()}-{getPhoneLastCharts()}
						</strong>{' '}
            отправлен 6 значный код для входа в личный кабинет, введите его ниже
					</p>
				</div>

				<SixDigitalCode
					code={code}
					setCode={setCode}
					isError={isError}
					visible={visible}
				/>

				<div className={styles['btn-group']}>
					<ButtonTemplate
						onClick={checkCode}
						disable={isDisabledSendCode}
						role='send-code'
						color='primary'
						text='Подтвердить'
					/>

					<button
						className={styles['btn-timer']}
						onClick={getNewSms}
						disabled={!isStop}
					>
						<span>Отправить новый код</span>{' '}
						<span>
							{' '}
							{value > 59
								? `0${formatTime(value)}`
								: `00:${formatTime(value)}`}{' '}
						</span>
					</button>
					<ErrorMessage isError={isError} message={errorMessage} />
				</div>
			</div>
		</div>
	);
};
