import React, {useEffect, useState} from 'react';
import styles from './EditingPhoneNumber.module.css';
import {HiOutlineInformationCircle} from 'react-icons/hi';
import ModalEditingPhoneNumber from '../../../ModalEditingPhoneNumber';
import {Email, Phone} from '../../../../UiKit/Message';
import {SplitLine} from '../../../../UiKit/Div';

type EditingPhoneNumberProps = {
	phoneNumber: string;
	email: string;
};

export const EditingPhoneNumber: React.FC<EditingPhoneNumberProps> = ({
	phoneNumber,
	email,
}) => {
	const [visible, setVisible] = useState(false);
	const [visibleModalCode, setVisibleModalCode] = useState(false);

	const closingModals = () => {
		setVisible(false);
	};

	useEffect(() => {
		setVisibleModalCode(false);
	}, [visible]);

	return (
		<>
			<SplitLine className={styles.container}/>
			<span className='text-title-1'>Контактные данные</span>
			<div className={styles.content}>
				<span className={styles.title}>Номер телефона</span>
				<div className={styles.field}>
					<Phone phone={phoneNumber} formatPhone/>
				</div>
				<button
					className={styles['edit-phone']}
					onClick={() => {
						setVisible(true);
					}}
				>
          Изменить номер
				</button>

				<span className={styles.title}>Электронный адрес</span>
				<div className={styles.field}>
					<Email email={email} />
				</div>
				<div></div>
				<div className={styles.info}>
					<HiOutlineInformationCircle className={styles.icon} />
					<span>
            Для смены электронного адреса обратитесь к администратору
					</span>
				</div>
			</div>
			<ModalEditingPhoneNumber
				visible={visible}
				setVisible={setVisible}
				setVisibleModalCode={setVisibleModalCode}
				visibleModalCode={visibleModalCode}
				closingModals={closingModals}
			/>
		</>
	);
};
