import {postInteractiveFinish} from '../../../api/interactive';
import {type PostInteractiveFinishResponse, type PostInteractiveFinishRequest} from '../../../models/IInteractive';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postInteractiveFinishAction = createActionTemplateAxios<
PostInteractiveFinishResponse,
PostInteractiveFinishRequest
>('Interactive/postInteractiveFinish/action', postInteractiveFinish, 'userToken');

export default postInteractiveFinishAction;
