import React, {useState, useEffect} from 'react';
import EditingFullName from './EditingFullName';
import EditingPhoneNumber from './EditingPhoneNumber';
import styles from './EditingProfile.module.css';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {putUserAction} from '../../../../redux/action/users';
import {ErrorMessage} from '../../../UiKit/Message';
import EditingRoles from './EditingRoles';
import {
	getPermissionsRolesAction,
} from '../../../../redux/action/permissions';
import {checkPermission} from '../../../../utils/permissions/permissions';
import {typePermissions} from '../../../../models/Enum';
import {ButtonCancel, ButtonConfirm} from '../../../UiKit/Button';
import {ModalWindow} from '../..';
import {notifyMessage} from '../../../UiKit/Toast/notifyMessage';
import {type Role} from '../../../../models/Schemas';
import {InputDate} from '../../../UiKit/Inputs';

type EditingProfileProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditingProfile: React.FC<EditingProfileProps> = ({
	visible,
	setVisible,
}) => {
	const {user, isLoading, isCompleted, isError} = useAppSelector(
		state => state.getUser,
	);
	const putUserState = useAppSelector(state => state.putUser);
	const getRoles = useAppSelector(state => state.getPermissionsRoles);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const putPermissions = useAppSelector(state => state.putPermissions);

	const [showValidation, setShowValidation] = useState(false);
	const [name, setName] = useState(user?.first_name ?? '');
	const [surname, setSurname] = useState(user?.last_name ?? '');
	const [middleName, setMiddleName] = useState(user?.middle_name ?? '');
	const [dateBirth, setDateBirth] = useState(user?.birth_date);
	const [isValidDateBirth, setIsValidDateBirth] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(user?.phone_number ?? '');
	const [email, setEmail] = useState(user?.email ?? '');

	const [roleType, setRoleType] = useState<Role | undefined>(undefined);
	const [roleOptions, setRoleOptions] = useState<Role[] | undefined>(undefined);
	const [isRoleUpdate, setIsRoleUpdate] = useState(false);

	const [isValidName, setIsValidName] = useState(false);
	const [isValidMiddleName, setIsValidMiddleName] = useState(false);
	const [isValidSurname, setIsValidSurname] = useState(false);

	const [validationError, setValidationError] = useState('');

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (visible) {
			setName(user?.first_name ?? '');
			setSurname(user?.last_name ?? '');
			setMiddleName(user?.middle_name ?? '');
			setDateBirth(user?.birth_date);
		}
	}, [visible]);

	useEffect(() => {
		setPhoneNumber(user?.phone_number ?? '');
	}, [user]);

	useEffect(() => {
		if (user?.organization_id) {
			void dispatch(
				getPermissionsRolesAction({organization_id: user.organization_id}),
			);
		}
	}, []);

	useEffect(() => {
		if (getRoles.responseStatus.isCompleted && getRoles.response) {
			const isRoleUpdate = checkPermission(
				typePermissions.role_update,
				{
					groupPermissions: getPermissions.groupPermissions,
					isMe: true,
					isMyDepartment: true,
				},
			);
			setIsRoleUpdate(isRoleUpdate);
			if (isRoleUpdate) {
				const userRoleId = user?.role_id;

				setRoleOptions(getRoles.response);
				setRoleType(getRoles.response.find(item => item.id === userRoleId));
			}
		}
	}, [getRoles.responseStatus.isLoading]);

	const handlerDataOnPage = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isCompleted) {
			if (dateBirth && isValidDateBirth) {
				setValidationError('');
				if (isValidName && isValidMiddleName && isValidSurname && user) {
					void dispatch(
						putUserAction({
							user: {
								first_name: name,
								middle_name: middleName,
								last_name: surname,
								birth_date: dateBirth,
							},
						}),
					);

					setVisible(false);
				} else {
					notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
				}
			} else {
				notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
			}
		}
	};

	useEffect(() => {
		if (putUserState.isCompleted) {
			notifyMessage('success', 'Информация обновлена');
		} else if (putUserState.isError) {
			notifyMessage('error', 'Ошибка. Что-то пошло не так.');
		}
	}, [putUserState.isLoading]);

	useEffect(() => {
		setShowValidation(false);
	}, [visible]);

	return (
		<ModalWindow
			width='52%'
			visible={visible}
			setVisible={setVisible}
			closable
			header={(
				<span className='text-title-1'>Личные данные</span>
			)}
			closeOnOutsideClick
			footerItems={[
				<ButtonCancel
					key={'ButtonCancel'}
					onclickHandler={() => {
						setVisible(false);
					}}
					text='Отмена'
				/>,
				<ButtonConfirm
					key={'ButtonConfirm'}
					onclickHandler={handlerDataOnPage}
					text='Сохранить изменения'
				/>,
			]}
		>
			<div className={styles.card}>
				<div className={styles['input-group']}>
					<div className={styles.content}>
						<EditingFullName
							name={name}
							surname={surname}
							middleName={middleName}
							isValidName={isValidName}
							isValidSurname={isValidSurname}
							isValidMiddleName={isValidMiddleName}
							setName={setName}
							setSurname={setSurname}
							setMiddleName={setMiddleName}
							setIsValidName={setIsValidName}
							setIsValidMiddleName={setIsValidMiddleName}
							setIsValidSurname={setIsValidSurname}
							showValidation={showValidation}
							visible={visible}
						/>
						<InputDate
							title='Дата рождения'
							value={dateBirth}
							setValueString={setDateBirth}
							inputValidation={{
								isRequired: true,
								isValid: isValidDateBirth,
								setIsValid: setIsValidDateBirth,
								showValidation,
								visible,
							}}
						/>
						<EditingRoles
							roleType={roleType}
							setRoleType={setRoleType}
							roleOptions={roleOptions}
							isLoading={getRoles.responseStatus.isLoading || putPermissions.responseStatus.isLoading}
						/>
					</div>
					<EditingPhoneNumber phoneNumber={phoneNumber} email={email} />
				</div>
				<ErrorMessage
					message={validationError}
					isError={Boolean(validationError)}
				/>
				<ErrorMessage
					message={putUserState.errorMessage}
					isError={putUserState.isError}
				/>
			</div>
		</ModalWindow>
	);
};
