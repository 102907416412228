import React from 'react';
import styles from '../BasicInfo.module.css';
import {type ReportModelV3PassportRf} from '../../../../report/reportModelV3/reportModelV3';
import {passportRFBasicInfoFields} from './passportRFBasicInfoFields';
import {CopyToClipboardButton} from '../../../UiKit/Button';

type PassportRFBasicInfoProps = {
	passport: ReportModelV3PassportRf;
};

const PassportRFBasicInfo: React.FC<PassportRFBasicInfoProps> = ({
	passport,
}) => (
	<div className={styles.data}>
		{passportRFBasicInfoFields.map((field, idx) => {
			const passportField = passport[field.key];
			if (passportField) {
				return (
					<React.Fragment key={idx}>
						<span className={styles.abbreviation}>
							{field.label}:
						</span>
						<div className={styles['result-container']}>
							<span className={styles.result}>
								{passportField}
							</span>
							{field.clipboard && <CopyToClipboardButton text={passportField.toString()} />}
						</div>
					</React.Fragment>
				);
			}

			return undefined;
		})}
		<span className={styles.abbreviation}>Действительность:</span>
		<span className={styles.result}>{passport.is_valid ? 'Действителен' : 'Недействителен'}</span>
	</div>
);

export default PassportRFBasicInfo;
