import {createRequestUsers} from '../../../api/usersApi';
import {type CreateUsers, type CreateUsersResponse} from '../../../models/IUsers';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const createRequestUsersAction = createActionTemplateAxios<
CreateUsersResponse,
CreateUsers
>('auth/createRequestUsers/action', createRequestUsers, 'userToken');

export default createRequestUsersAction;
