import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putGuestSignDocs} from '../../../api/Guest/guestApi';
import {type PutGuestSignDocsRequest, type PutGuestSignDocsResponse} from '../../../models/Guest/Guest/IGuest';

const putGuestSignDocsAction = createActionTemplateAxios<
PutGuestSignDocsResponse,
PutGuestSignDocsRequest
>(
	'guest/putGuestSignDocs/action',
	putGuestSignDocs,
	'guestToken',
);

export default putGuestSignDocsAction;
