import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getPersonalizedPdAgreement} from '../../../api/Person/person152fzApi';
import {type GetPersonalizedPdAgreementRequest, type GetPersonalizedPdAgreementResponse} from '../../../models/Person/PersonDocuments/IPersonDocuments';

const getPersonalizedPdAgreementAction = createActionTemplateAxios<
GetPersonalizedPdAgreementResponse,
GetPersonalizedPdAgreementRequest
>(
	'person/getPersonalizedPdAgreement/action',
	getPersonalizedPdAgreement,
	'guestToken',
);

export default getPersonalizedPdAgreementAction;
