import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetCheckFieldsResponse} from '../../../models/ICheck';
import {getCheckFieldsAction} from '../../action/checks';

type GetCheckParametersState = {
	responseStatus: ResponseStatus;
	response: GetCheckFieldsResponse | undefined;
	processedResponse: GetCheckFieldsResponse | undefined;
};

const initialState: GetCheckParametersState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	processedResponse: undefined,
};

const getProcessedResponse = (response: GetCheckFieldsResponse) => {
	response.sort((a, b) => a.order > b.order ? 1 : -1);

	return response;
};

const getCheckFieldsSlice = createSlice({
	name: 'checks/getCheckFields',
	initialState,
	reducers: {
		getCheckFieldsSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getCheckFieldsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckFieldsAction.fulfilled, (state, action) => {
				state.response = action.payload;
				state.processedResponse = getProcessedResponse(action.payload);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckFieldsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {getCheckFieldsSliceResetState} = getCheckFieldsSlice.actions;
export default getCheckFieldsSlice.reducer;
