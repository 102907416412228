import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getDepartmentPositionsOpened} from '../../../api/departmentPositionsApi';
import {type GetDepartmentPositionsOpenedResponse} from '../../../models/IDepartmentPositions';

const getDepartmentPositionsOpenedAction = createActionTemplateAxios<
GetDepartmentPositionsOpenedResponse,
void>(
	'departmentPositions/getDepartmentPositionsOpened/action',
	getDepartmentPositionsOpened,
	'userToken',
);

export default getDepartmentPositionsOpenedAction;
