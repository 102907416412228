import React, {useState, type ChangeEvent, useEffect} from 'react';
import styles from './Appearance.module.css';
import dark from '../../../assets/dark-theme.png';
import light from '../../../assets/light-theme.png';
import {Dropdown} from '../../UiKit/Inputs';
import {CheckBoxDevEx} from '../../UiKit/Checkbox';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {setIsFixedSidebar} from '../../../redux/slice/settingsSlice';

export const Appearance = () => {
	const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(undefined);
	const [theme, setTheme] = useState(true);

	const settings = useAppSelector(state => state.settings);
	const [fixSidebar, setFixSidebar] = useState(settings.isFixedSidebar);

	const dispatch = useAppDispatch();

	const handleThemeChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTheme(event.target.value === 'true');
	};

	const toggleTheme = () => {
		setTheme(prevTheme => !prevTheme);
	};

	useEffect(() => {
		dispatch(setIsFixedSidebar(fixSidebar));
	}, [fixSidebar]);

	return (
		<div className={styles.content}>
			<div className={styles['side-menu']}>
				<CheckBoxDevEx isChecked={fixSidebar} setIsChecked={setFixSidebar} />
				<span>Фиксированное боковое меню</span>
			</div>
			<div className={styles.language}>
				<Dropdown
					selectedValue={selectedLanguage}
					setSelectedValue={setSelectedLanguage}
					options={['Русский', 'Белорусский']}
					placeholder='Язык'
					width={250}
					title='Локализация'
					titleColor='main'
					readOnly={true}
				/>
			</div>
			<div className={styles.decor}>
				<span>Оформление</span>
				<div className={styles['theme-group']}>
					<div className={styles.theme} onClick={toggleTheme}>
						<div className={styles.checkbox}>
							<img src={dark} alt='' />
							{/* <input */}
							{/*	type='radio' */}
							{/*	value='true' */}
							{/*	checked={theme} */}
							{/*	onChange={handleThemeChange} */}
							{/*	disabled={true} */}
							{/* /> */}
						</div>
						<span>Темная тема</span>
					</div>

					<div className={styles.theme} onClick={toggleTheme}>
						<div className={styles.checkbox}>
							<img src={light} alt='' />
							{/* <input */}
							{/*	type='radio' */}
							{/*	value='false' */}
							{/*	checked={!theme} */}
							{/*	onChange={handleThemeChange} */}
							{/*	disabled={true} */}
							{/* /> */}
						</div>
						<span>Светлая тема</span>
					</div>
				</div>
			</div>
		</div>
	);
};
