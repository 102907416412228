import {type ResponseStatus} from '../../../models/IAuth';
import {type DeleteEmployeeResponse} from '../../../models/IEmployees';
import {createSlice} from '@reduxjs/toolkit';
import {deleteEmployeeAction} from '../../action/employees';

const initialState: {
	responseStatus: ResponseStatus;
	response: DeleteEmployeeResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const deleteEmployeeSlice = createSlice({
	name: 'employees/deleteEmployee',
	initialState,
	reducers: {
		deleteEmployeeResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(deleteEmployeeAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(deleteEmployeeAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(deleteEmployeeAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {deleteEmployeeResetState} = deleteEmployeeSlice.actions;
export default deleteEmployeeSlice.reducer;
