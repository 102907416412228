import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetNameFillResponse} from '../../../models/Dadata/IDadata';
import {getNameFillAction} from '../../action/dadata';

type GetNameFillSliceState = {
	responseStatus: ResponseStatus;
	response: GetNameFillResponse;
};

const initialState: GetNameFillSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
		requestId: undefined,
	},
	response: {
		suggestions: [],
	},
};

const getNameFillSlice = createSlice({
	name: 'dadata/getNameFill',
	initialState,
	reducers: {
		getNameFillResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getNameFillAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = undefined;
			})
			.addCase(getNameFillAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getNameFillAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
				state.responseStatus.requestId = action.meta.requestId;
			});
	},
});

export const {getNameFillResetState} = getNameFillSlice.actions;
export default getNameFillSlice.reducer;
