import React from 'react';
import styles from './NotFoundPage.module.css';
import logo from '../../assets/login/LogoWhite.svg';
type NotFoundPageProps = {
	message?: string;
	status?: number;
};

export const NotFoundPage: React.FC<NotFoundPageProps> = ({
	message,
	status,
}) => (
	<div className={styles.wrapper}>
		<div className={styles.content}>
			<img src={logo} alt='' />
			<span>Экосистема продуктов для бизнеса</span>

			<h1>{status}</h1>
			<span>{message}</span>
		</div>
	</div>
);
