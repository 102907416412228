import React from 'react';
import styles from '../../ReportCard.module.css';
import {type CheckContacts} from '../../../../../../../report/ReportModel';
import {Email, Phone} from '../../../../../Message';
import {CopyToClipboardButton} from '../../../../../Button';

type ContactCardProps = {
	contact: CheckContacts;
};

const ContactCard: React.FC<ContactCardProps> = ({
	contact,
}) => {
	if (contact.type === 'email') {
		return (
			<div className={styles.card}>
				<div className={styles.header}>
					Электронный адрес
				</div>
				<div className={styles.content}>
					<div className={styles.info}>
						<span className={styles.contact}>
							<Email email={contact.value} />
						</span>
						<CopyToClipboardButton text={contact.value} />
					</div>
				</div>
			</div>
		);
	}

	if (contact.type === 'phone' || contact.type === 'mobile') {
		return (
			<div className={styles.card}>
				<div className={styles.header}>
					Телефон
				</div>
				<div className={styles.content}>
					<div className={styles.info}>
						<span className={styles.contact}>
							<Phone phone={contact.value} formatPhone/>
						</span>
						<CopyToClipboardButton text={contact.value} format='phone' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.card}>
			<div className={styles.header}>
				{contact.type}
			</div>
			<div className={styles.content}>
				<span className={styles.contact}>
					{contact.value}
				</span>
			</div>
		</div>
	);
};

export default ContactCard;
