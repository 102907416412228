import {type GroupsPermissions} from '../../models/Enum';

export const getGroupPermission = (key: string, groupsPermission: GroupsPermissions | undefined) => {
	if (groupsPermission) {
		const group = groupsPermission[key];
		if (group) {
			return group;
		}
	}

	return undefined;
};

// Проверка прав
export const checkPermission = (
	permissionType: string,
	parameters: {
		groupPermissions: GroupsPermissions | undefined;
		isMe: boolean;
		isMyDepartment: boolean;
	},
) => {
	const permission = getGroupPermission(permissionType, parameters.groupPermissions);
	if (permission?.permit) {
		if (permission.permission_zone === 'all') {
			return true;
		}

		if (permission.permission_zone === 'only_my_department') {
			if (parameters.isMyDepartment) {
				return true;
			}

			return false;
		}

		if (permission.permission_zone === 'only_owner') {
			if (parameters.isMe || parameters.isMyDepartment) {
				return true;
			}

			return false;
		}
	}

	return false;
};

// Проверка прав для профиля
export const checkPermissionProfile = (
	permissionType: string,
	groupPermissions: GroupsPermissions | undefined,
	isMe: boolean,
	isMyDepartment: boolean,
) => {
	const permission = getGroupPermission(permissionType, groupPermissions);

	if (permission?.permit) {
		if (permission.permission_zone === 'all') {
			return true;
		}

		if (permission.permission_zone === 'only_my_department') {
			if (isMe || isMyDepartment) {
				return true;
			}

			return false;
		}

		if (permission.permission_zone === 'only_owner') {
			if (isMe) {
				return true;
			}

			return false;
		}
	}

	return false;
};

// Проверка прав для навигации
export const checkPermissionPermit = (
	permissionType: string,
	groupPermissions: GroupsPermissions | undefined,
) => {
	const permission = getGroupPermission(permissionType, groupPermissions);
	if (permission?.permit) {
		return true;
	}

	return false;
};
