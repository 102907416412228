import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './Subordinate.module.css';
import {IoAdd} from 'react-icons/io5';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getUserStaffAction} from '../../redux/action/users';
import {SubordinateEmployeeTable} from '../../components/Tables';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../paths';
import {ButtonTemplate, ToggleCardOrTableView} from '../../components/UiKit/Button';
import {typePermissions} from '../../models/Enum';
import {checkPermissionPermit} from '../../utils/permissions/permissions';
import {Input} from '../../components/UiKit/Inputs';
import {EmployeeStaffCard} from '../../components/UiKit/Cards';
import Loader from '../../components/Loader/Loader';
import {ModalCheckCreated, ModalEditContacts, ModalEditingEmployee, ModalEditingProfile} from '../../components/Modal';
import {type CheckPermissions} from '../../components/Modal/ModalCheckCreated/ModalCheckCreated';
import {type EditContactsPermissions} from '../../components/Modal/ModalEditContacts/ModalEditContacts';
import {filteredDepartmentEmployee} from '../../redux/slice/department/getDepartmentEmployeesSlice';
import {getDepartmentEmployeesAction} from '../../redux/action/department';

// Получение последнего состояния
const defaultCardViewValue = () => {
	const storedValue = localStorage.getItem('cardViewEmployees');
	return storedValue === null || storedValue === 'true';
};

export const Subordinate = () => {
	const myId = useAppSelector(state => state.getUser.user?.employee_id);
	// Права
	const [employeeSearch, setEmployeeSearch] = useState('');
	const [isCardView, setIsCardView] = useState(defaultCardViewValue());
	const [employeeCreate, setEmployeeCreate] = useState(false);
	const [userCreate, setUserCreate] = useState(false);
	const [userStaffRead, setUserStaffRead] = useState(false);

	// Модальные окна
	const [isEditContactOpen, setIsEditContactOpen] = useState(false);
	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
	const [isOpenCheckCreated, setIsOpenCheckCreated] = useState(false);
	const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | undefined>(undefined);
	const [isEditEmployeeOpen, setIsEditEmployeeOpen] = useState(false);

	const checkedPermissions = useRef<CheckPermissions>({});
	const editContactsPermissions = useRef<EditContactsPermissions>({
		isCreate: false,
		isDelete: false,
		isUpdate: false,
	});

	// Состояния
	const getDepartmentEmployees = useAppSelector(state => state.getDepartmentEmployees);
	const navigationContext = useAppSelector(state => state.navigationContext);
	const getPermissions = useAppSelector(state => state.getPermissions);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// Перейти в профиль
	const openProfile = (id: number) => {
		if (myId === id) {
			navigate(paths.profile);
		} else {
			navigate(paths.profileUser.replace(':id', id.toString()));
		}
	};

	// Открыть изменение контактов
	const openEditContact = (id: number, permissions: EditContactsPermissions) => {
		setIsEditContactOpen(true);
		setSelectedEmployeeId(id);
		editContactsPermissions.current = permissions;
	};

	// Открыть окно создания проверки
	const openCreateCheck = (id: number, permissions: CheckPermissions) => {
		setIsOpenCheckCreated(true);
		setSelectedEmployeeId(id);
		checkedPermissions.current = permissions;
	};

	// Открыть изменение профиля
	const openEditProfile = (id: number) => {
		if (myId === id) {
			setIsEditProfileOpen(true);
		} else {
			setIsEditEmployeeOpen(true);
			setSelectedEmployeeId(id);
		}
	};

	// Список карточек
	const cards = useMemo(() => (
		getDepartmentEmployees.filteredTableData?.map((item, index) => (
			<EmployeeStaffCard
				key={index}
				employee={item}
				openProfile={openProfile}
				openEditContact={openEditContact}
				openCreateCheck={openCreateCheck}
				openEditProfile={openEditProfile}
			/>
		))
	), [getDepartmentEmployees.filteredTableData]);

	// Проверка прав
	const checkPermissions = () => {
		const {groupPermissions} = getPermissions;
		setEmployeeCreate(checkPermissionPermit(typePermissions.employee_create, groupPermissions));
		setUserCreate(checkPermissionPermit(typePermissions.user_create, groupPermissions));
		setUserStaffRead(checkPermissionPermit(typePermissions.user_staff_read, groupPermissions));
	};

	const handleClickAddUsers = () => {
		navigate(paths.addUsers);
	};

	const handleClickAddEmployees = () => {
		navigate(paths.addEmployees);
	};

	useEffect(() => {
		if (userStaffRead) {
			void dispatch(getUserStaffAction());
		}
	}, [userStaffRead]);

	useEffect(() => {
		if (getDepartmentEmployees.responseStatus.isCompleted) {
			void dispatch(filteredDepartmentEmployee({
				fullName: employeeSearch,
			}));
		}
	}, [getDepartmentEmployees?.responseStatus.isLoading, employeeSearch]);

	// Обновление прав
	useEffect(() => {
		if (getPermissions.responseStatus.isCompleted) {
			checkPermissions();
		}
	}, [getPermissions.response, getPermissions.responseStatus.isLoading]);

	// Очистка selectedEmployeeId при закрытие окон
	useEffect(() => {
		if (!(isEditContactOpen || isOpenCheckCreated || isEditProfileOpen || isEditEmployeeOpen)) {
			setSelectedEmployeeId(undefined);
		}
	}, [isEditContactOpen, isOpenCheckCreated, isEditProfileOpen, isEditEmployeeOpen]);

	// Обновление данных при закрытие окон
	useEffect(() => {
		if (!(isEditContactOpen || isEditProfileOpen || isEditEmployeeOpen) && navigationContext.department) {
			void dispatch(getDepartmentEmployeesAction({
				departmentId: navigationContext.department.id,
			}));
		}
	}, [isEditContactOpen, isEditProfileOpen, isEditEmployeeOpen, navigationContext.department?.id]);

	useEffect(() => {
		localStorage.setItem('cardViewEmployees', JSON.stringify(isCardView));
	}, [isCardView]);

	return (
		<div className={styles.content}>
			<div className={styles.header}>
				<span className='h1-text'>Сотрудники</span>
				<div className={styles['toll-bar']}>
					<div className={styles['toll-bar-left']}>
						<Input
							value={employeeSearch}
							setValue={setEmployeeSearch}
							inputStyles={{
								placeholder: 'ФИО сотрудника',
								width: '235px',
							}}
							inputButtons={{
								searchButton: true,
							}}

						/>

						{userCreate && <ButtonTemplate
							onClick={handleClickAddUsers}
							styleType={'auto'}
							color={'green-outline'}
							borderRadius='16px'
							Icon={IoAdd}
							text='Добавить пользователей'
						/>}
						{employeeCreate && <ButtonTemplate
							onClick={handleClickAddEmployees}
							styleType={'auto'}
							color={'green-outline'}
							borderRadius='16px'
							Icon={IoAdd}
							text='Добавить сотрудников'
						/>}
					</div>
					<div className={styles['toll-bar-right']}>
						<ToggleCardOrTableView
							isCardView={isCardView}
							setIsCardView={setIsCardView}
						/>
					</div>
				</div>
			</div>
			{userStaffRead && (
				isCardView
					? <div className={styles['wrapper-cards']}>
						<div className={styles.cards}>
							{cards}
						</div>
					</div>
					: <div className={styles.table}>
						<SubordinateEmployeeTable
							value={getDepartmentEmployees.filteredTableData}
							openProfile={openProfile}
							openEditContact={openEditContact }
							openCreateCheck={openCreateCheck}
							openEditProfile={openEditProfile}
						/>
					</div>
			)}
			<ModalEditingProfile
				visible={isEditProfileOpen}
				setVisible={setIsEditProfileOpen}
			/>
			<ModalEditContacts
				visible={isEditContactOpen}
				setVisible={setIsEditContactOpen}
				employeeId={selectedEmployeeId}
				permissions={editContactsPermissions}
				permissionsClean
			/>
			<ModalEditingEmployee
				visible={isEditEmployeeOpen}
				setVisible={setIsEditEmployeeOpen}
				employeeId={selectedEmployeeId}
			/>
			<ModalCheckCreated
				visible={isOpenCheckCreated}
				setVisible={setIsOpenCheckCreated}
				employeeId={selectedEmployeeId}
				permissions={checkedPermissions}
			/>
			<Loader isFloating isLoading={getDepartmentEmployees.responseStatus.isLoading} text='Загрузка сотрудников...' />
		</div>
	);
};
