import React from 'react';
import {
	type GetUserAuthHistoryResponse,
} from '../../../models/IUsers';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {stringDateSorting} from '../../../utils/sorting/dateSorting';

type AuthHistoryTableProps = {
	value: GetUserAuthHistoryResponse | undefined;
	isLoading: boolean;
};

const columns: ColumnsProps[] = [
	{
		caption: 'Дата входа',
		dataField: 'auth_at',
		sortingMethod: stringDateSorting,
	},
	{
		caption: 'IP-адрес',
		dataField: 'ip_address',
		allowSorting: false,
	},
	{
		caption: 'Устройство',
		dataField: 'device',
		allowSorting: false,
	},
	{
		caption: 'Местоположение',
		dataField: 'geoposition',
		allowSorting: false,
	},
];

export const AuthHistoryTable: React.FC<AuthHistoryTableProps> = ({
	value,
	isLoading,
}) => (
	<>
		<Table
			columns={columns}
			dataGridProps={{
				dataSource: value,
				allowColumnResizing: true,
			}}
			isLoading={isLoading}
			loadingMessage={'Получение данных'}
		/>
	</>
);
