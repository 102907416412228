import React from 'react';
import styles from './Phone.module.css';
import {formatPhoneNumber} from '../../../../utils';

type PhoneProps = {
	phone: string | string[] | undefined;
	phoneStyle?: string;
	formatPhone?: boolean;
};

const Phone: React.FC<PhoneProps> = ({
	phone,
	phoneStyle,
	formatPhone = false,
}) => {
	const getPhone = () => {
		if (phone) {
			if (Array.isArray(phone)) {
				return (
					<div className={styles.container}>
						{phone.map((item, index) => phoneRender(item, index))}
					</div>
				);
			}

			return phoneRender(phone);
		}

		return null;
	};

	const phoneRender = (phone: string, key?: string | number) => {
		const textPhone = formatPhone ? formatPhoneNumber(phone) : phone;

		return (
			<a href={`tel:${phone}`} key={key} className={`${styles.phone} ${phoneStyle}`} title={textPhone}>
				{textPhone}
			</a>
		);
	};

	return (
		getPhone()
	);
};

export default Phone;
