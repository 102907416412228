import {Cookies} from 'react-cookie';
import {type Token} from '../../../models/IAuth';
import {cookiesKeys} from '..';

export const getToken = () => {
	const cookies = new Cookies();

	const token: Token = {
		token: cookies.get(cookiesKeys.userToken) as string,
		refresh_token: cookies.get(cookiesKeys.userRefreshToken) as string,
		token_expires: cookies.get(cookiesKeys.userTokenExpires) as string,
		refresh_token_expires: cookies.get(cookiesKeys.userRefreshTokenExpires) as string,
	};

	return token;
};
