import React, {useMemo, useState} from 'react';
import styles from './Documents.module.css';
import {type CheckDocuments} from '../../../../report/ReportModel';
import {IoDocument} from 'react-icons/io5';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {PassportCardV1, PassportCardV3} from '../../../UiKit/Cards';
import {ModalConfirmationPassport, ModalPassport} from '../../../Modal/Report';
import {type ReportModelV3PassportRf} from '../../../../report/reportModelV3/reportModelV3';
import ReportBlockTemplate from '../ReportBlockTemplate';

type DocumentsProps = {
	documents?: CheckDocuments[] | undefined;
};

export const Documents: React.FC<DocumentsProps> = () => {
	const [visibleModalPassport, setVisibleModalPassport] = useState(false);
	const [visibleSubModalPassport, setVisibleSubModalPassport] = useState(false);
	const [selectedPassport, setSelectedSelectedPassport] = useState<string | undefined>(undefined);
	const [selectedPassportV3, setSelectedSelectedPassportV3] = useState<ReportModelV3PassportRf | undefined>(undefined);
	const [selectedContainerId, setSelectedContainerId] = useState<string>();

	const documents = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.documents);
	const documentsV3 = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.documentsV3);

	const openModalPassport = (passport: ReportModelV3PassportRf, containerId?: string) => {
		setSelectedSelectedPassportV3(passport);
		setSelectedSelectedPassport(passport.passport);
		setVisibleModalPassport(true);
		setSelectedContainerId(containerId);
	};

	const openSubModalPassport = (passport: string | undefined, containerId?: string) => {
		setSelectedSelectedPassport(passport);
		setVisibleSubModalPassport(true);
		setSelectedContainerId(containerId);
	};

	const CardsDocumentsV1 = useMemo(() => {
		if (documents?.data) {
			return documents.data.map((document, index) => (
				<PassportCardV1
					passport={document.item}
					key={index}
				/>
			));
		}

		return [];
	}, [documents]);

	const CardsDocumentsV3 = useMemo(() => {
		if (documentsV3?.data) {
			return documentsV3.data.map((document, index) => (
				<PassportCardV3
					passport={document.item}
					key={index}
					onClick={() => {
						openModalPassport(document.item, document.containerId);
					}}
					onClickSub={() => {
						openSubModalPassport(document.item.passport, document.containerId);
					}}
					containerId={document.containerId}
				/>
			));
		}

		return [];
	}, [documentsV3]);

	return (
		<ReportBlockTemplate
			blockIcon={IoDocument}
			blockTitle='Документы'
			alerts={[
				...documents?.additionalData.alerts ?? [],
				...documentsV3?.additionalData.alerts ?? [],
			]}
			styleType='outline'
			blocksId='documents'
			visible={CardsDocumentsV1.length > 0 || CardsDocumentsV3.length > 0}
		>
			<div className={styles['docs-group']}>
				{CardsDocumentsV1 && (
					<div className={styles.group}>
						{CardsDocumentsV1}
					</div>
				)}

				{CardsDocumentsV3 && (
					<div className={styles.group}>
						{CardsDocumentsV3}
					</div>
				)}
			</div>
			<ModalPassport
				visible={visibleModalPassport}
				setVisible={setVisibleModalPassport}
				passport={selectedPassportV3}
				setVisibleSubModal={setVisibleSubModalPassport}
				containerId={selectedContainerId}
			/>
			<ModalConfirmationPassport
				visible={visibleSubModalPassport}
				setVisible={setVisibleSubModalPassport}
				passport={selectedPassport}
				containerId={selectedContainerId}
			/>
		</ReportBlockTemplate>
	);
};
