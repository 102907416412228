import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {postPerson152fzAction} from '../../action/person';
import {type PostPerson152fzResponse} from '../../../models/Person/PersonDocuments/IPersonDocuments';

const initialState: {
	responseStatus: ResponseStatus;
	response: PostPerson152fzResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const postPerson152fzSlice = createSlice({
	name: 'person/postPerson152fz',
	initialState,
	reducers: {
		postPerson152fzResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postPerson152fzAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postPerson152fzAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postPerson152fzAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {postPerson152fzResetState} = postPerson152fzSlice.actions;
export default postPerson152fzSlice.reducer;
