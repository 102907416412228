import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutUserPermissionsResponse} from '../../../models/IPermissions';
import {putUserPermissionsAction} from '../../action/permissions';

const initialState: {
	responseStatus: ResponseStatus;
	response: PutUserPermissionsResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const putUserPermissionsSlice = createSlice({
	name: 'permissions/putUserPermissions',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(putUserPermissionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putUserPermissionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putUserPermissionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export default putUserPermissionsSlice.reducer;
