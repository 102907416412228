import React from 'react';
import styles from './PersonsReportBlock.module.css';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {ReportBlockTemplate} from '..';
import {MdOutlineAccountCircle} from 'react-icons/md';
import PersonCard from '../../../UiKit/Cards/Report/Cards/PersonCard/PersonCard';

const PersonsReportBlock = () => {
	const persons = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.persons);

	return (
		<ReportBlockTemplate
			blocksId='persons'
			blockIcon={MdOutlineAccountCircle}
			blockTitle='Люди'
			visible={persons?.data && persons.data.length > 0}
			alerts={persons?.additionalData.alerts}
			styleType='outline'
		>
			<div className={styles.container}>
				{persons?.data.map(({item}, index) => <PersonCard key={index} person={item} />)}
			</div>
		</ReportBlockTemplate>
	);
};

export default PersonsReportBlock;
