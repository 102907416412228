import React, {useEffect, useRef, useState} from 'react';
import styles from './ModalSendLinkToFillPd.module.css';
import {ModalWindow} from '../../index';
import {ButtonCancel, ButtonConfirm} from '../../../UiKit/Button';
import {InputEmail} from '../../../UiKit/Inputs';
import {CheckBoxDevEx} from '../../../UiKit/Checkbox';
import {useEffectHandlerLoadingCurrentRequest} from '../../../../utils/hooks';
import {postPerson152fzResetState} from '../../../../redux/slice/person';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import Loader from '../../../Loader/Loader';
import {ErrorMessage} from '../../../UiKit/Message';
import {postPerson152fzAction} from '../../../../redux/action/person';
import {getCandidateAction} from '../../../../redux/action/candidates';
import {getCandidateResetState} from '../../../../redux/slice/candidates';
import {notifyMessage} from '../../../UiKit/Toast/notifyMessage';

type ModalSendLinkToFillPdProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	candidateId: number | undefined;
};

export const ModalSendLinkToFillPd: React.FC<ModalSendLinkToFillPdProps> = ({visible, setVisible, candidateId}) => {
	const [email, setEmail] = useState('');
	const [isLinkToSend, setIsLinkToSend] = useState(true);

	const [showValidation, setShowValidation] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);

	const postPerson152fz = useAppSelector(state => state.postPerson152fz);
	const getCandidate = useAppSelector(state => state.getCandidate);

	const postPerson152fzRequestId = useRef<string>();
	const getCandidateRequestId = useRef<string>();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const dispatch = useAppDispatch();

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	// Отобразить ошибку
	const showError = (message?: string) => {
		setIsError(true);
		setErrorMessage(message);
	};

	// Скрыть ошибку
	const hiddenError = () => {
		setIsError(false);
		setErrorMessage(undefined);
	};

	// Сброс состояний компонента
	const resetComponentStates = () => {
		hiddenError();
		hiddenLoading();

		postPerson152fzRequestId.current = undefined;
		getCandidateRequestId.current = undefined;
		dispatch(postPerson152fzResetState());
		dispatch(getCandidateResetState());
		setShowValidation(false);
		setEmail('');
		setIsLinkToSend(true);
	};

	const openGuestAccess = () => {
		setShowValidation(true);
		if (candidateId && isValidEmail) {
			postPerson152fzRequestId.current = dispatch(postPerson152fzAction({body: {candidate_id: candidateId, send_link: isLinkToSend, email}})).requestId;
		}
	};

	useEffect(() => {
		if (visible && candidateId) {
			getCandidateRequestId.current = dispatch(getCandidateAction({candidateId})).requestId;
		} else {
			resetComponentStates();
		}
	}, [visible]);

	// Получение ссылки кандидату/сотруднику для заполнения ПД
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: postPerson152fzRequestId,
		state: postPerson152fz,
		onLoading() {
			showLoading('Отправка данных...');
			hiddenError();
		},
		onComplete() {
			notifyMessage('success', 'Ссылка успешно отправлена!');
			setVisible(false);
		},
		onStopLoading: hiddenLoading,
		onError(errorMessage) {
			showError(errorMessage);
		},
	});

	// Получение кандидата
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getCandidateRequestId,
		state: getCandidate,
		onLoading() {
			showLoading('Получение кандидата...');
			hiddenError();
		},
		onStopLoading: hiddenLoading,
		onComplete(response) {
			setEmail(response?.email ?? '');
		},
		onError(errorMessage) {
			showError(errorMessage);
		},
	});

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			closable
			footerItems={[
				<ButtonCancel
					key={'ButtonCancel'}
					onclickHandler={() => {
						setVisible(false);
					}}
					text='Отмена'
				/>,
				<ButtonConfirm
					key={'ButtonConfirm'}
					onclickHandler={openGuestAccess}
					text={'Открыть гостевой доступ'}
				/>,
			]}
			header={
				<span className='text-title-1'>Отправка формы для сбора ПД </span>
			}
			width='min(450px, 90%)'
		>
			<div className={styles.content}>
				<div className={styles.inputs}>
					<InputEmail
						value={email}
						setValue={setEmail}
						isValid={isValidEmail}
						setIsValid={setIsValidEmail}
						showValidation={showValidation}
						title='Почта'
						inputStyles={{
							placeholder: 'Введите почту',
						}}
						isRequired
					/>
				</div>
				<CheckBoxDevEx
					isChecked={isLinkToSend}
					setIsChecked={setIsLinkToSend}
					text='Отправить ссылку'
				/>
				<ErrorMessage
					isError={isError}
					message={errorMessage}
				/>
				<Loader
					isLoading={isLoading}
					isFloating
					text={loadingMessage}
				/>
			</div>
		</ModalWindow>
	);
};
