import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {logoutAction} from '../../action/auth';
import {removeToken} from '../../../utils/cookies/token/removeToken';
import {paths} from '../../../paths';

const initialState: ResponseStatus = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
};

const logoutSlice = createSlice({
	name: 'auth/logout',
	initialState,
	reducers: {
		resetLogout(state) {
			state.isCompleted = false;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(logoutAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
			})
			.addCase(logoutAction.fulfilled, state => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				removeToken();
				window.location.href = paths.auth;
			})
			.addCase(logoutAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {resetLogout} = logoutSlice.actions;
export default logoutSlice.reducer;
