import React, {useEffect, useMemo, useRef, useState} from 'react';
import commonTableStyles from '../CommonTableStyles.module.css';
import {ModalCheckCreated, ModalContacts, ModalEditContacts, ModalEditingCandidate} from '../../Modal';
import {getDepartmentPositionsCandidatesAction} from '../../../redux/action/department_positions';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {useParams} from 'react-router-dom';
import {TableIconButton} from '../../UiKit/Button';
import {FaUserEdit} from 'react-icons/fa';
import {BiSolidContact} from 'react-icons/bi';
import {getCandidateAction} from '../../../redux/action/candidates';
import {type Contact} from '../../../models/Schemas';
import {Email, Phone} from '../../UiKit/Message';
import {contactType, typePermissions} from '../../../models/Enum';
import {MdPersonSearch} from 'react-icons/md';
import {type CheckPermissions} from '../../Modal/ModalCheckCreated/ModalCheckCreated';
import {checkPermission} from '../../../utils/permissions/permissions';
import {type EditContactsPermissions} from '../../Modal/ModalEditContacts/ModalEditContacts';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {type GetDepartmentPositionsCandidatesProceedResponseItem} from '../../../redux/slice/departmentPositions';
import {RiMailSendLine} from 'react-icons/ri';
import {ModalSendLinkToFillPd} from '../../Modal/Person152fz/ModalSendLinkToFillPd/ModalSendLinkToFillPd';

// Получение контакта
const getContact = (contact?: Contact) => {
	if (contact) {
		if (contact.contact_type === contactType.phone) {
			return <Phone phone={contact.contact} formatPhone />;
		}

		if (contact.contact_type === contactType.email) {
			return <Email email={contact.contact} />;
		}

		return <span>{contact.contact}</span>;
	}

	return null;
};

const JobCandidateTable = () => {
	const [visible, setVisible] = useState(false);
	const [contacts, setContacts] = useState<Contact[] | undefined>(undefined);
	const [isOpenContacts, setIsOpenContacts] = useState(false);
	const [isOpenEditContacts, setIsOpenEditContacts] = useState(false);
	const [isContactDelete, setIsContactDelete] = useState(false);
	const [candidateId, setCandidateId] = useState<number | undefined>(undefined);
	const [positionId, setPositionId] = useState<number | undefined>(undefined);
	const [isMyDepartment, setIsMyDepartment] = useState(false);
	const [isOpenCheckCreated, setIsOpenCheckCreated] = useState(false);
	const isEditChallenger = useRef(false);
	const isEditCandidate = useRef(false);
	const checkPermissions = useRef<CheckPermissions>({});
	const editContactsPermissions = useRef<EditContactsPermissions>({
		isCreate: false,
		isDelete: false,
		isUpdate: false,
	});
	const getDepartmentPositionsCandidates = useAppSelector(state => state.getDepartmentPositionsCandidates);
	const getPosition = useAppSelector(state => state.getPosition);
	const getCandidate = useAppSelector(state => state.getCandidate);
	const {groupPermissions} = useAppSelector(state => state.getPermissions);
	const getUser = useAppSelector(state => state.getUser);

	const [candidateIdForPd, setCandidateIdForPd] = useState<number>();
	const [isOpenSendLinkToPd, setIsOpenSendLinkToPd] = useState(false);

	const {id} = useParams();
	const dispatch = useAppDispatch();

	// Открытие окна изменения кандидата
	const openEditCandidate = (id: number, candidate: boolean, challenger: boolean) => {
		setCandidateId(id);
		setVisible(true);
		isEditCandidate.current = candidate;
		isEditChallenger.current = challenger;
	};

	// Открыть окно создания проверки
	const openCreateCheck = (id: number, permissions: CheckPermissions) => {
		setIsOpenCheckCreated(true);
		setCandidateId(id);
		checkPermissions.current = permissions;
	};

	// Открытие окна список контактов
	const openContacts = (candidateId: number, contactDelete: boolean) => {
		void dispatch(getCandidateAction({candidateId}));
		setIsContactDelete(contactDelete);
		setIsOpenContacts(true);
	};

	// Открытие окна изменения контактов
	const openEditContacts = (candidateId: number, permissions: EditContactsPermissions) => {
		setCandidateId(candidateId);
		setIsOpenEditContacts(true);
		editContactsPermissions.current = permissions;
	};

	const openSendLinkPerson152fz = (candidateId: number) => {
		setCandidateIdForPd(candidateId);
		setIsOpenSendLinkToPd(true);
	};

	const columns = useMemo<ColumnsProps[]>(() => [
		{
			caption: 'Кандидат',
			dataField: 'personFullName',
		},
		{
			caption: 'Автор',
			dataField: 'authorFullName',
		},
		{
			caption: 'Статус',
			dataField: 'response.status.text',
		},
		{
			caption: 'Контакт',
			cellRender(rowData) {
				const {firstContact} = (rowData.row.data as GetDepartmentPositionsCandidatesProceedResponseItem);
				return getContact(firstContact);
			},
		},
		{
			width: 125,
			type: 'buttons',
			cellRender(rowData) {
				const {response} = (rowData.row.data as GetDepartmentPositionsCandidatesProceedResponseItem);
				const parametersCheckPermission = {
					groupPermissions,
					isMe: response.author.id === getUser.user?.department?.id,
					isMyDepartment,
				};
				const candidateRead = checkPermission(typePermissions.candidate_read, parametersCheckPermission);
				const candidateCheck = checkPermission(typePermissions.candidate_check, parametersCheckPermission);
				const checkRequestCreate = checkPermission(typePermissions.create_check_request, parametersCheckPermission);
				const isCheckRequestCreate = checkPermission(typePermissions.create_check_request, parametersCheckPermission);
				const zeroReportCreate = checkPermission(typePermissions.zero_report_create, parametersCheckPermission);
				const contactDelete = checkPermission(typePermissions.contact_delete, parametersCheckPermission);
				const contactUpdate = checkPermission(typePermissions.contact_update, parametersCheckPermission);
				const contactCreate = checkPermission(typePermissions.contact_create, parametersCheckPermission);
				const checkStart = checkPermission(typePermissions.check_start, parametersCheckPermission);

				const isCheck = candidateCheck && (checkRequestCreate || zeroReportCreate);
				const isEditContacts = (contactDelete || contactUpdate || contactCreate) && candidateRead;

				const permissions: CheckPermissions = {
					isCreateCheckRequest: isCheckRequestCreate,
					isZeroReportCreate: zeroReportCreate,
					isStartCheck: checkStart,
				};

				return (
					<div className={commonTableStyles['column-action']}>
						{candidateRead && (
							<TableIconButton
								onClick={() => {
									openContacts(response.person.candidate_id, contactDelete);
								}}
								Icon={BiSolidContact}
								TooltipMessage='Просмотреть контактные данные'
							/>
						)}
						{isEditContacts && (
							<TableIconButton
								onClick={() => {
									openEditContacts(
										response.person.candidate_id,
										{
											isCreate: contactCreate,
											isDelete: contactDelete,
											isUpdate: contactUpdate,
										},
									);
								}}
								Icon={FaUserEdit}
								TooltipMessage='Изменить контактные данные'
							/>
						)}
						{isCheck && (
							<TableIconButton
								onClick={() => {
									openCreateCheck(response.person.candidate_id, permissions);
								}}
								Icon={MdPersonSearch}
								TooltipMessage='Создать заявку на проверку'
							/>
						)}
						{response.status.id === 'wait_check' && response.person.candidate_id && (
							<TableIconButton
								Icon={RiMailSendLine}
								TooltipMessage='Отправить ссылку для заполнения ПД'
								onClick={() => {
									openSendLinkPerson152fz(response.person.candidate_id);
								}}
							/>
						)}
					</div>
				);
			},
		},
	], [getUser, groupPermissions, isMyDepartment]);

	useEffect(() => {
		if (id) {
			setPositionId(parseInt(id, 10));
		}
	}, []);

	useEffect(() => {
		if (getPosition.responseStatus.isCompleted && getUser.isCompleted) {
			if (typeof getUser.user?.department?.id === 'number'
				&& typeof getPosition.response?.department_id === 'number'
			) {
				setIsMyDepartment(getPosition.response.department_id === getUser.user.department.id);
			} else {
				setIsMyDepartment(false);
			}
		} else if (getCandidate.responseStatus.errorMessage) {
			setIsMyDepartment(false);
		}
	}, [
		getPosition.responseStatus.isLoading,
		getUser.isLoading,
	]);

	useEffect(() => {
		if (!visible && !isOpenEditContacts && positionId) {
			void dispatch(
				getDepartmentPositionsCandidatesAction({
					positionId,
				}),
			);
			setCandidateId(undefined);
			isEditCandidate.current = false;
			isEditChallenger.current = false;
		}
	}, [visible, isOpenEditContacts]);

	useEffect(() => {
		if (!isOpenCheckCreated) {
			setCandidateId(undefined);
		}
	}, [isOpenCheckCreated]);

	useEffect(() => {
		if (!isOpenContacts && positionId) {
			void dispatch(
				getDepartmentPositionsCandidatesAction({
					positionId,
				}),
			);
			setContacts(undefined);
		}
	}, [isOpenContacts]);

	useEffect(() => {
		if (getCandidate.responseStatus.isCompleted) {
			setContacts(getCandidate.response?.contacts);
		}
	}, [getCandidate.responseStatus.isLoading]);

	return (
		<>
			<Table
				columns={columns}
				dataGridProps={{
					dataSource: getDepartmentPositionsCandidates.processedResponse,
					allowColumnResizing: true,
					onRowClick(e) {
						const {response} = (e.data as GetDepartmentPositionsCandidatesProceedResponseItem);
						const parametersCheckPermission = {
							groupPermissions,
							isMe: response.author.id === getUser.user?.department?.id,
							isMyDepartment,
						};
						const candidateRead = checkPermission(typePermissions.candidate_read, parametersCheckPermission);
						const candidateUpdate = checkPermission(typePermissions.candidate_update, parametersCheckPermission);
						const challengerRead = checkPermission(typePermissions.challenger_read, parametersCheckPermission);
						const challengerUpdate = checkPermission(typePermissions.challenger_update, parametersCheckPermission);

						const isEditCandidate = candidateUpdate && candidateRead;
						const isEditChallenger = challengerRead && challengerUpdate;

						if (isEditCandidate || isEditChallenger) {
							openEditCandidate(response.person.candidate_id, isEditCandidate, isEditChallenger);
						}
					},
					hoverStateEnabled: true,
					activeStateEnabled: true,
				}}
				isLoading={getDepartmentPositionsCandidates.responseStatus.isLoading}
			/>
			<ModalEditingCandidate
				visible={visible}
				setVisible={setVisible}
				candidateId={candidateId}
				positionId={positionId}
				isEditChallenger={isEditChallenger}
				isEditCandidate={isEditCandidate}
			/>
			<ModalContacts
				visible={isOpenContacts}
				setVisible={setIsOpenContacts}
				contacts={contacts}
				isLoading={getCandidate.responseStatus.isLoading}
				loadingMessage='Получение контактов'
				contactDelete={isContactDelete}
			/>
			<ModalEditContacts
				visible={isOpenEditContacts}
				setVisible={setIsOpenEditContacts}
				candidateId={candidateId}
				permissions={editContactsPermissions}
				permissionsClean
			/>
			<ModalCheckCreated
				visible={isOpenCheckCreated}
				setVisible={setIsOpenCheckCreated}
				candidateId={candidateId}
				permissions={checkPermissions}
			/>
			<ModalSendLinkToFillPd
				visible={isOpenSendLinkToPd}
				setVisible={setIsOpenSendLinkToPd}
				candidateId={candidateIdForPd}
			/>
		</>
	);
};

export default JobCandidateTable;
