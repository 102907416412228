import React, {useEffect, useRef, useState} from 'react';
import AdditionalContact from './AdditionalContacts';
import {IoAdd} from 'react-icons/io5';
import styles from './ModalEditContacts.module.css';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {deleteContactAction, postContactAction, putContactAction} from '../../../redux/action/contacts';
import ModalWindow from '../ModalWindow';
import {ErrorMessage} from '../../UiKit/Message';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {type AdditionalContactProps, type ItemContact} from './AdditionalContacts/AdditionalContacts';
import {getEmployeeAction} from '../../../redux/action/employees';
import {
	deletePostContactSliceResetState,
	postContactSliceResetState,
	putContactSliceResetState,
} from '../../../redux/slice/contacts';
import {getEmployeesResetState} from '../../../redux/slice/employees';
import {getCandidateAction} from '../../../redux/action/candidates';
import {getUserAction} from '../../../redux/action/users';

export type EditContactsPermissions = {
	isDelete: boolean;
	isCreate: boolean;
	isUpdate: boolean;
};

type ModalEditContactsProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	employeeId?: number;
	candidateId?: number;
	permissions: React.MutableRefObject<EditContactsPermissions>;
	permissionsClean?: boolean;
};

const ModalEditContacts: React.FC<ModalEditContactsProps> = ({
	visible,
	setVisible,
	employeeId,
	candidateId,
	permissions,
	permissionsClean,
}) => {
	const isUpdated = useRef(false);
	const [showValidation, setShowValidation] = useState(false);
	const [contactComponent, setContactComponent] = useState<AdditionalContactProps[]>([]);
	const [contacts, setContacts] = useState<ItemContact[]>([]);
	const [keyContactCard, setKeyContactCard] = useState<number>(0);

	const [startContacts, setStartContacts] = useState<ItemContact[]>([]);
	const [updateContactsComponent, setUpdateContactsComponent] = useState<React.JSX.Element[]>([]);
	const [updateContacts, setUpdateContacts] = useState<ItemContact[]>([]);
	const [deleteContacts, setDeleteContacts] = useState<ItemContact[]>([]);

	const [isRequestsLoading, setIsRequestsLoading] = useState(false);
	const [currentRequestLoading, setCurrentRequestLoading] = useState('');

	const [currentContacts, setCurrentContacts] = useState<number | undefined>(undefined);

	const dispatch = useAppDispatch();

	const postContact = useAppSelector(state => state.postContact);
	const putContact = useAppSelector(state => state.putContact);
	const deleteContact = useAppSelector(state => state.deleteContact);
	const getCandidate = useAppSelector(state => state.getCandidate);
	const getEmployee = useAppSelector(state => state.getEmployee);
	const userId = useAppSelector(state => state.getUser.user?.employee_id);

	// Обновление при закрытии
	const getNewContacts = () => {
		if (employeeId) {
			if (employeeId === userId) {
				void dispatch(getUserAction());
			} else {
				void dispatch(getEmployeeAction({employeeId}));
			}
		} else if (candidateId) {
			void dispatch(getCandidateAction({candidateId}));
		}
	};

	// Закрытие окна
	const autoCloseModal = () => {
		setVisible(false);
	};

	// Проверка валидности измененных контактов
	const checkContacts = () => {
		const IsValidNewContacts = contacts.find(item => !item.isValid) === undefined;
		const IsValidUpdateContacts = updateContacts.find(item => !item.isValid) === undefined;
		return IsValidNewContacts && IsValidUpdateContacts;
	};

	// Изменение контакта
	const changeContacts = (
		prevContacts: ItemContact[],
		newContact: ItemContact,
	) => {
		const contactIndex = prevContacts.findIndex(
			c => c.item.key === newContact.item.key,
		);
		if (contactIndex !== -1) {
			const newContacts = [...prevContacts];
			newContacts[contactIndex] = newContact;
			return newContacts;
		}

		return [...prevContacts, newContact];
	};

	// Изменение новых контактов
	const handleChangeContacts = (contact: ItemContact) => {
		setContacts(prevContacts => changeContacts(prevContacts, contact));
		setContactComponent(p => {
			const index = p.findIndex(item => item.index === contact.item.key);
			if (index !== -1) {
				p[index].itemContact = contact;
			}

			return p;
		});
	};

	// Изменение существующих контактов
	const changeUpdateContacts = (contact: ItemContact) => {
		setUpdateContacts(prevContacts => changeContacts(prevContacts, contact));
	};

	// Отправка изменений контактов
	const handleSubmit = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (typeof (employeeId ?? candidateId) === 'number' && checkContacts()) {
			setCurrentContacts(0);
			setIsRequestsLoading(true);
			if (permissions.current.isUpdate && updateContacts.length !== 0) {
				dispatchPutContact(updateContacts[0]);
			} else if (permissions.current.isDelete && deleteContacts.length !== 0) {
				dispatchDeleteContact(deleteContacts[0]);
			} else if (contacts.length === 0) {
				setIsRequestsLoading(false);
				setCurrentRequestLoading('');
			} else if (permissions.current.isCreate) {
				dispatchPostContact(contacts[0]);
			}
		}
	};

	// Добавление контакта
	const dispatchPostContact = (item: ItemContact) => {
		const contact = item.item;
		if (employeeId ?? candidateId) {
			setCurrentRequestLoading(`Добавление контакта: ${contact.contact}`);
			void dispatch(
				postContactAction({
					body: {
						contact_type: contact.contact_type,
						contact: contact.contact,
						description: contact.description,
						employee_id: employeeId,
						candidate_id: candidateId,
					},
				}),
			);
		}
	};

	// Обновление контакта
	const dispatchPutContact = (item: ItemContact) => {
		const contact = item.item;
		if ((employeeId ?? candidateId) && contact.contactId) {
			setCurrentRequestLoading(`Обновление контакта: ${contact.contact}`);
			void dispatch(
				putContactAction({
					body: {
						contact: contact.contact,
						description: contact.description,
						employee_id: employeeId,
						candidate_id: candidateId,
					},
					contactId: contact.contactId,
				}),
			);
		}
	};

	// Удаление контакта
	const dispatchDeleteContact = (item: ItemContact) => {
		const contact = item.item;
		if ((employeeId ?? candidateId) && contact.contactId) {
			setCurrentRequestLoading(`Удаление контакта: ${contact.contact}`);
			void dispatch(
				deleteContactAction({
					id: contact.contactId,
				}),
			);
		}
	};

	// Добавить компоненту нового контакта
	const addContact = () => {
		setContactComponent(prevContacts => [
			...prevContacts,
			{
				key: `AddContact${keyContactCard}`,
				index: keyContactCard,
				removeContact,
				handleChangeContacts,
				isUpdate: true,
				isDelete: true,
				showValidation,
			},
		]);
		setKeyContactCard(prevState => prevState + 1);
	};

	// Добавление компоненту существующего контакта
	const addUpdateContact = (
		contact: ItemContact,
		id: number,
	) => {
		setUpdateContactsComponent(prevContacts => [
			...prevContacts,
			<AdditionalContact
				key={'updateContact' + id}
				index={id}
				removeContact={removeUpdateContact}
				handleChangeContacts={changeUpdateContacts}
				itemContact={contact}
				isDelete={permissions.current.isDelete}
				isUpdate={permissions.current.isUpdate}
				showValidation={showValidation}
			/>,
		]);
	};

	// Удаление нового контакта
	const removeContact = (indexToRemove: number) => {
		setContactComponent(prevContacts =>
			prevContacts.filter(item => item.key !== `AddContact${indexToRemove}`),
		);
		setContacts(prevContacts =>
			prevContacts.filter(item => item?.item.key !== indexToRemove),
		);
	};

	// Добавление id в созданный контакт
	const addIdInContact = (index: number, id: number) => {
		setContacts(prevContacts => {
			prevContacts[index].item.contactId = id;
			prevContacts[index].item.key = id;

			return prevContacts;
		});
	};

	// Перемещение нового контакта в существующие
	const moveContact = (count: number) => {
		setContactComponent(prevContacts => prevContacts.slice(count));
		const moveItems = contacts.slice(0, count);
		moveItems.forEach(newContact => {
			addUpdateContact(
				newContact,
				newContact.item.key,
			);
		});
		setContacts(prevContacts => prevContacts.slice(count));
	};

	// Удаление существующего контакта
	const removeUpdateContact = (indexToRemove: number) => {
		setUpdateContactsComponent(prevContacts =>
			prevContacts.filter(
				item => item.key !== `updateContact${indexToRemove}`,
			),
		);
		const removeContact = startContacts.find(
			item => item.item.contactId === indexToRemove,
		);
		if (removeContact) {
			setDeleteContacts(prevContacts => [...prevContacts, removeContact]);
			setUpdateContacts(prevContacts =>
				prevContacts.filter(item => item.item.key !== indexToRemove),
			);
		}
	};

	// Обработка закрытия/открытия модального окна
	useEffect(() => {
		if (visible) {
			dispatch(postContactSliceResetState());
			dispatch(deletePostContactSliceResetState());
			dispatch(putContactSliceResetState());
			dispatch(getEmployeesResetState());
			setIsRequestsLoading(true);
			setCurrentRequestLoading('Получение контактов');
			if (employeeId) {
				void dispatch(getEmployeeAction({employeeId}));
			} else if (candidateId) {
				void dispatch(getCandidateAction({candidateId}));
			}

			isUpdated.current = false;
		} else {
			if (permissionsClean) {
				permissions.current = {
					isCreate: false,
					isDelete: false,
					isUpdate: false,
				};
			}

			if (isUpdated.current) {
				getNewContacts();
				isUpdated.current = false;
			}

			setIsRequestsLoading(false);
			setCurrentRequestLoading('');
			setCurrentContacts(undefined);

			setContactComponent([]);
			setContacts([]);
			setKeyContactCard(0);

			setUpdateContactsComponent([]);
			setUpdateContacts([]);
			setDeleteContacts([]);
			setShowValidation(false);
		}
	}, [visible]);

	// Обработка получение данных о сотруднике
	useEffect(() => {
		if (visible && !getEmployee.responseStatus.isLoading && !getCandidate.responseStatus.isLoading) {
			if (
				getEmployee.responseStatus.isError
        || getCandidate.responseStatus.isError
			) {
				setIsRequestsLoading(false);
				setCurrentRequestLoading('');
			} else if (
				getEmployee.responseStatus.isCompleted
        && getEmployee.response
			) {
				const {contacts} = getEmployee.response;
				if (contacts.length > 0) {
					setUpdateContactsComponent([]);
					setStartContacts(
						contacts.map(item => (
							{
								isValid: true,
								item: {
									contact_type: item.contact_type,
									contact: item.contact,
									description: item.description ?? '',
									key: item.id,
									contactId: item.id,
								}}
						)));
				} else {
					setIsRequestsLoading(false);
					setCurrentRequestLoading('');
				}
			} else if (
				getCandidate.responseStatus.isCompleted
        && getCandidate.response
			) {
				const {contacts} = getCandidate.response;
				if (contacts.length > 0) {
					setUpdateContactsComponent([]);
					setStartContacts(
						contacts.map(item => ({
							isValid: true,
							item: {
								contact_type: item.contact_type,
								contact: item.contact,
								description: item.description ?? '',
								key: item.id,
								contactId: item.id,
							},
						}
						)));
				} else {
					setIsRequestsLoading(false);
					setCurrentRequestLoading('');
				}
			} else {
				setIsRequestsLoading(false);
				setCurrentRequestLoading('');
			}
		}
	}, [
		getEmployee.responseStatus.isLoading,
		getCandidate.responseStatus.isLoading,
	]);

	// Добавление существующих контактов на страницу
	useEffect(() => {
		startContacts.forEach(item => {
			addUpdateContact(item, item.item.contactId!);
		});
		setIsRequestsLoading(false);
		setCurrentRequestLoading('');
	}, [startContacts]);

	// Обработка добавления обновления контакта
	useEffect(() => {
		if (putContact.responseStatus.isError) {
			setIsRequestsLoading(false);
			setCurrentRequestLoading('');
			if (employeeId) {
				void dispatch(getEmployeeAction({employeeId}));
			} else if (candidateId) {
				void dispatch(getCandidateAction({candidateId}));
			}
		} else if (
			putContact.responseStatus.isCompleted
      && currentContacts !== undefined
		) {
			isUpdated.current = true;
			if (currentContacts < updateContacts.length - 1) {
				dispatchPutContact(updateContacts[currentContacts + 1]);
				setCurrentContacts(currentContacts + 1);
			} else if (permissions.current.isDelete && deleteContacts.length > 0) {
				setCurrentContacts(0);
				dispatchDeleteContact(deleteContacts[0]);
			} else if (permissions.current.isCreate && contacts.length > 0) {
				setCurrentContacts(0);
				dispatchPostContact(contacts[0]);
			} else {
				autoCloseModal();
			}
		}
	}, [putContact.responseStatus.isLoading]);

	// Обработка добавления удаления контакта
	useEffect(() => {
		if (deleteContact.responseStatus.isError) {
			setIsRequestsLoading(false);
			setCurrentRequestLoading('');
		} else if (
			deleteContact.responseStatus.isCompleted
      && currentContacts !== undefined
		) {
			isUpdated.current = true;
			if (currentContacts < deleteContacts.length - 1) {
				dispatchDeleteContact(deleteContacts[currentContacts + 1]);
				setCurrentContacts(currentContacts + 1);
			} else if (permissions.current.isCreate && contacts.length > 0) {
				setCurrentContacts(0);
				dispatchPostContact(contacts[0]);
			} else {
				autoCloseModal();
			}
		}
	}, [deleteContact.responseStatus.isLoading]);

	// Обработка добавления нового контакта
	useEffect(() => {
		if (postContact.responseStatus.isError) {
			if (currentContacts !== undefined && currentContacts > 0) {
				moveContact(currentContacts);
			}

			setIsRequestsLoading(false);
			setCurrentRequestLoading('');
		} else if (
			postContact.responseStatus.isCompleted
      && postContact.response
      && currentContacts !== undefined
		) {
			isUpdated.current = true;
			addIdInContact(currentContacts, postContact.response.id);
			if (currentContacts < contacts.length - 1) {
				dispatchPostContact(contacts[currentContacts + 1]);
				setCurrentContacts(currentContacts + 1);
			} else {
				autoCloseModal();
			}
		}
	}, [postContact.responseStatus.isLoading]);

	// Обновление компонентов
	useEffect(() => {
		setContactComponent(p => p.map(item => {
			item.showValidation = showValidation;

			return item;
		}));
	}, [showValidation]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			width='50%'
			header={(
				<span className='text-title-1'>Изменить контактные данные</span>
			)}
			footerItems={[
				<ButtonCancel
					key='ButtonCancel'
					onclickHandler={() => {
						autoCloseModal();
					}}
					text='Назад'
				/>,
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={handleSubmit}
					text='Отправить'
				/>,
			]}
			isLoading={isRequestsLoading}
			loadingMessage={currentRequestLoading}
			closable
			closeOnOutsideClick
		>
			<div className={styles.content}>
				{(permissions.current.isUpdate || permissions.current.isDelete)
					&& <>
						{updateContactsComponent.length > 0 && <>
							{updateContactsComponent.map((contact, index) => (
								<div key={index}>
									<h1 className='text-title-3'>Контакт №{index + 1}</h1>
									{contact}
								</div>
							))}
						</>}
					</>}
				{permissions.current.isCreate
				&& <>
					{contactComponent.length > 0 && <>
						{contactComponent.map((contactProps, index) => (
							<div key={index}>
								<h1 className='text-title-3'>Новый контакт №{index + 1}</h1>
								<AdditionalContact
									{...contactProps}
								/>
							</div>
						))}
					</>}
					<div className={styles['add-contact']}>
						<div className={styles['add-button']} onClick={addContact}>
							<IoAdd />
							<span className='medium-text'>Добавить дополнительный контакт</span>
						</div>
					</div>
				</>}

				<ErrorMessage
					message={getEmployee.responseStatus.errorMessage}
					isError={getEmployee.responseStatus.isError}
				/>
				<ErrorMessage
					message={postContact.responseStatus.errorMessage}
					isError={postContact.responseStatus.isError}
				/>
			</div>
		</ModalWindow>
	);
};

export default ModalEditContacts;
