import styles from './TreeTable.module.css';
import tableStyles from '../Table.module.css';
import React, {type ReactNode, useEffect, useMemo, useRef} from 'react';
import TreeList, {
	Column,
	type IItemProps,
	type IPagingProps,
	type IToolbarProps,
	Scrolling,
} from 'devextreme-react/cjs/tree-list';
import {type RowData} from '..';
import {type EventOnClickRow} from '../TableType';
import Loader from '../../../Loader/Loader';

export type TreeTableProps = {
	columns: TreeListColumnsProps[];
	headerCellRenderDefault?: (columnRowData: RowData) => React.ReactNode;
	cellRenderDefault?: (columnRowData: RowData) => React.ReactNode;

	treeListProps: TreeListProps;
	pagingProps?: TreeTablePagingProps;
	itemProps?: IItemProps;

	height?: string | number;
	width?: string | number;
	type?: 'table' | 'list';

	loadingMessage?: string;
	isLoading?: boolean;

	children?: ReactNode;
	visible?: boolean;

	onExpandedRowKeysChange?: (value: any[]) => void;
};

export type TreeTableToolbarProps = IToolbarProps;
export type TreeTablePagingProps = IPagingProps;

export type TreeListProps = {
	dataSource: any[] | undefined;
	parentIdExpr: string;
	keyExpr?: string;

	allowColumnReordering?: boolean;
	allowColumnResizing?: boolean;
	columnAutoWidth?: boolean;
	hoverStateEnabled?: boolean;
	activeStateEnabled?: boolean;
	showColumnHeaders?: boolean;
	autoNavigateToFocusedRow?: boolean;
	autoExpandAll?: boolean;

	expandedRowKeys?: any[];
	defaultExpandedRowKeys?: any[];
	selectedRowKeys?: any[];
	defaultSelectedRowKeys?: any[];
	focusedRowKey?: any[];
	defaultFocusedRowKey?: any[];

	onRowClick?: (e: EventOnClickRow) => void;
	onRowDblClick?: (e: EventOnClickRow) => void;
};

export type TreeListColumnsProps = {
	caption?: string;
	dataField?: string;
	groupIndex?: number;
	allowFiltering?: boolean;
	allowSorting?: boolean;
	headerCellRender?: (columnRowData: RowData) => React.ReactNode;
	cellRender?: (columnRowData: RowData) => React.ReactNode;
};

const TreeTable: React.FC<TreeTableProps> = ({
	height = '100%',
	isLoading = false,
	loadingMessage = 'Загрузка',
	treeListProps,
	columns,
	type = 'table',
	children,
	visible = true,
	onExpandedRowKeysChange,
	...props
}) => {
	const dataGridRef = useRef<TreeList>(null);
	const columnsList = useMemo(() => columns.map((column, index) => (<Column
		key={index}
		headerCellRender={column.headerCellRender ?? props.headerCellRenderDefault}
		cellRender={column.cellRender ?? props.cellRenderDefault}
		{...column}
	/>)), [columns]);

	return (
		<div className={tableStyles.wrapper}>
			<TreeList
				id='tree'
				ref={dataGridRef}
				height={height}
				className={`${styles[type]} ${type === 'table' && tableStyles['table-dx']} ${styles['tree-list']}`}
				{...treeListProps}
				loadPanel={{
					enabled: false,
				}}
				onExpandedRowKeysChange={onExpandedRowKeysChange}
			>
				<Scrolling mode='virtual'/>
				{children}
				{columnsList}
			</TreeList>
			<Loader isFloating isLoading={isLoading} text={loadingMessage}/>
		</div>
	);
};

export default TreeTable;
