import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostChallengersResponse} from '../../../models/IChallengers';
import {postChallengersAction} from '../../action/challengers';

type PostChallengersSliceState = {
	responseStatus: ResponseStatus;
	response: PostChallengersResponse | undefined;
};

const initialState: PostChallengersSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postChallengersSlice = createSlice({
	name: 'challengers/postChallengers',
	initialState,
	reducers: {
		postChallengersResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postChallengersAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postChallengersAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postChallengersAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postChallengersResetState} = postChallengersSlice.actions;

export default postChallengersSlice.reducer;
