import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postPerson152fzDocuments} from '../../../api/Person/person152fzApi';
import {type PostPerson152fzDocumentsRequest} from '../../../models/Person/PersonDocuments/IPersonDocuments';

const postPerson152fzDocumentsAction = createActionTemplateAxios<
void,
PostPerson152fzDocumentsRequest
>(
	'person/postPerson152fzDocuments/action',
	postPerson152fzDocuments,
	'guestToken',
);

export default postPerson152fzDocumentsAction;
