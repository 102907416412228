import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetOfficialPermissionsResponse} from '../../../models/IPermissions';
import {getOfficialPermissionsAction} from '../../action/permissions';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetOfficialPermissionsResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getOfficialPermissionsSlice = createSlice({
	name: 'permissions/getOfficialPermissions',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getOfficialPermissionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getOfficialPermissionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(getOfficialPermissionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export default getOfficialPermissionsSlice.reducer;
