import React, {useState, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getDepartmentSubordinateAction} from '../../redux/action/department';
import {DepartmentsTreeTable} from '../../components';
import styles from './Departments.module.css';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../paths';
import {typePermissions} from '../../models/Enum';
import {getGroupPermission} from '../../utils';
import Loader from '../../components/Loader/Loader';
import {getPermissionsAction, getUserAction} from '../../redux/action/users';
import {checkPermissionPermit} from '../../utils/permissions/permissions';
import {notifyMessage} from '../../components/UiKit/Toast/notifyMessage';
import {TitleCardDepartment} from '../../components/UiKit/Cards/title/TitleCardDepartment';

export const Departments = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const getDepartmentsSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const putDepartment = useAppSelector(state => state.putDepartment);
	const getUser = useAppSelector(state => state.getUser);
	const getPermissions = useAppSelector(state => state.getPermissions);

	const [departmentSubordinateTableRead, setDepartmentSubordinateTableRead] = useState(false);
	const [departmentRead, setDepartmentRead] = useState(false);
	const [departmentSubordinateRead, setDepartmentSubordinateRead] = useState(false);
	const [departmentUpdate, setDepartmentUpdate] = useState(false);
	const [departmentSubordinateUpdate, setDepartmentSubordinateUpdate] = useState(false);
	const [departmentDelete, setDepartmentDelete] = useState(false);
	const [departmentSubordinateDelete, setDepartmentSubordinateDelete] = useState(false);
	const [departmentCreate, setDepartmentCreate] = useState(false);
	const [departmentSubordinateCreate, setDepartmentSubordinateCreate] = useState(false);
	const [userRead, setUserRead] = useState(false);
	const [userSubordinateRead, setUserSubordinateRead] = useState(false);
	const [employeeRead, setEmployeeRead] = useState(false);
	const [employeeSubordinateRead, setEmployeeSubordinateRead] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const requestIdRef = useRef<string | undefined>(undefined);
	const [errorMessage, setErrorMessage] = useState<string>('');

	// Отобразить загрузку
	const startLoading = (message: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const stopLoading = () => {
		setIsLoading(false);
		setLoadingMessage('');
	};

	// Открыть свой отдел
	const openMyDepartment = () => {
		if (getUser.user?.department?.id) {
			navigate(paths.departmentPositions.replace(':id', getUser.user?.department?.id.toString()));
		}
	};

	// Установка права
	const setPermission = (
		permissionType: string,
		set: React.Dispatch<React.SetStateAction<boolean>>,
		setSubordinate: React.Dispatch<React.SetStateAction<boolean>>,
	) => {
		const permission = getGroupPermission(permissionType, getPermissions.groupPermissions);
		if (permission?.permit) {
			if (permission?.permission_zone === 'all') {
				set(true);
				setSubordinate(true);
			} else if (permission?.permission_zone === 'only_my_department') {
				set(true);
				setSubordinate(false);
			} else if (permission?.permission_zone === 'only_owner') {
				set(true);
				setSubordinate(true);
			}
		} else {
			set(false);
			setSubordinate(false);
		}
	};

	// Проверка прав
	const checkPermissions = () => {
		setPermission(typePermissions.department_read, setDepartmentRead, setDepartmentSubordinateRead);
		setPermission(typePermissions.department_update, setDepartmentUpdate, setDepartmentSubordinateUpdate);
		setPermission(typePermissions.department_delete, setDepartmentDelete, setDepartmentSubordinateDelete);
		setPermission(typePermissions.department_create, setDepartmentCreate, setDepartmentSubordinateCreate);
		setPermission(typePermissions.department_create, setDepartmentCreate, setDepartmentSubordinateCreate);
		setPermission(typePermissions.department_create, setDepartmentCreate, setDepartmentSubordinateCreate);
		setPermission(typePermissions.department_create, setDepartmentCreate, setDepartmentSubordinateCreate);
		setPermission(typePermissions.user_read, setUserRead, setUserSubordinateRead);
		setPermission(typePermissions.employee_read, setEmployeeRead, setEmployeeSubordinateRead);
		setDepartmentSubordinateTableRead(checkPermissionPermit(typePermissions.department_employees_read, getPermissions.groupPermissions));
		stopLoading();
	};

	// Проверка прав при открытии страницы
	useEffect(() => {
		void dispatch(getDepartmentSubordinateAction());
		if (!getPermissions.responseStatus.isCompleted && !getPermissions.responseStatus.isLoading) {
			void dispatch(getPermissionsAction());
		}
	}, []);

	// Проверка прав при изменении прав
	useEffect(() => {
		startLoading('Получение отделов...');
		if (getPermissions.responseStatus.isCompleted
			&& getDepartmentsSubordinate.responseStatus.isCompleted) {
			checkPermissions();
		}
	}, [getPermissions.responseStatus.isLoading,
		getDepartmentsSubordinate.responseStatus.isLoading]);

	useEffect(() => {
		if (putDepartment.responseStatus.isCompleted && putDepartment.response?.id === getUser.user?.department?.id) {
			requestIdRef.current = dispatch(getUserAction()).requestId;
		}
	}, [putDepartment.responseStatus.isLoading]);

	useEffect(() => {
		if (requestIdRef.current && requestIdRef.current === getUser.requestId) {
			if (getUser.isLoading) {
				startLoading('Обновление информации о пользователе');
			} else if (getUser.isCompleted) {
				requestIdRef.current = undefined;
				stopLoading();
			} else if (getUser.isError) {
				requestIdRef.current = undefined;
				setErrorMessage(getUser.errorMessage);
				notifyMessage('error', errorMessage);
				stopLoading();
			}
		}
	}, [getUser.isLoading, getUser.isCompleted, getUser.isError, getUser.requestId]);

	return (
		<>
			<div className={styles.container}>
				<TitleCardDepartment
					departmentRead={departmentRead}
					onClick={openMyDepartment}
					departmentId={getUser.user?.department?.id}
					hiddenLoader={isLoading}
				/>
				{departmentSubordinateTableRead && (
					<DepartmentsTreeTable
						departments={getDepartmentsSubordinate.filteredEmployeeTableItems}
						isLoading={getDepartmentsSubordinate.responseStatus.isLoading && !isLoading}
						departmentRead={departmentRead}
						departmentSubordinateRead={departmentSubordinateRead}
						departmentUpdate={departmentUpdate}
						departmentSubordinateUpdate={departmentSubordinateUpdate}
						departmentDelete={departmentDelete}
						departmentSubordinateDelete={departmentSubordinateDelete}
						departmentCreate={departmentCreate}
						departmentSubordinateCreate={departmentSubordinateCreate}
						userRead={userRead}
						userSubordinateRead={userSubordinateRead}
						employeeRead={employeeRead}
						employeeSubordinateRead={employeeSubordinateRead}
					/>
				)}
			</div>
			<Loader isFloating isLoading={isLoading} text={loadingMessage} />
		</>

	);
};
