import React, {useEffect} from 'react';
import {ModalWindow} from '../../../';
import styles from '../ModalReport.module.css';
import {FaHome} from 'react-icons/fa';
import {emailFields} from './emailFields';
import {type ReportModelV3Email} from '../../../../../report/reportModelV3/reportModelV3';
import {CopyToClipboardButton} from '../../../../UiKit/Button';
import {ModalSelectCheckButton} from '../../../../UiKit/Button/ReportButtons/ModalSelectCheckButton/ModalSelectCheckButton';

type ModalEmailProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	setVisibleSubModal: (value: boolean) => void;
	email: ReportModelV3Email | undefined;
	containerId: string | undefined;
};

export const ModalEmail: React.FC<ModalEmailProps> = ({
	visible,
	email,
	setVisible,
	setVisibleSubModal,
	containerId,
}) => {
	const openSubModal = () => {
		setVisibleSubModal(true);
	};

	useEffect(() => {
		setVisibleSubModal(false);
	}, [visible]);

	return <ModalWindow
		visible={visible}
		setVisible={setVisible}
		header={
			<div className={styles.title}>
				<FaHome/>
				<span>Электронный адрес</span>
			</div>
		}
		footerItems={[
			<ModalSelectCheckButton
				setVisible={setVisible}
				openSubModal={openSubModal}
				containerId={containerId}
				key='modalSelectCheckButton'
			/>,
		]}
		footerItemsLocation='after'
		closeOnOutsideClick
		width='min(800px, 90%)'
	>
		<div className={styles['content-group']}>
			<div className={styles.wrapper}>
				{email && (
					<div className={styles.content}>
						{emailFields.map((field, idx) => {
							const emailField = email[field.key];
							if (emailField) {
								return (
									<React.Fragment key={idx}>
										<span className={styles.abbreviation}>
											{field.label}:
										</span>
										<div className={styles['result-container']}>
											<span className={styles.result}>
												{emailField}
											</span>
											{field.clipboard && <CopyToClipboardButton text={emailField}/>}
										</div>
									</React.Fragment>
								);
							}

							return null;
						})}
					</div>
				)}
			</div>
		</div>
	</ModalWindow>;
};
