import React from 'react';
import EditingProfile from './EditingProfile';

type ModalEditingProfileProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ModalEditingProfile: React.FC<ModalEditingProfileProps> = ({
	visible,
	setVisible,
}) => (
	<EditingProfile visible={visible} setVisible={setVisible} />
);
