import React, {useEffect} from 'react';
import styles from '../ModalReport.module.css';
import {ModalWindow} from '../../../';
import {type CheckAddresses} from '../../../../../report/ReportModel';
import {FaHome} from 'react-icons/fa';
import {addressFields} from './AddressFields';
import {CopyToClipboardButton} from '../../../../UiKit/Button';
import {ModalSelectCheckButton} from '../../../../UiKit/Button/ReportButtons/ModalSelectCheckButton/ModalSelectCheckButton';

type ModalCheckAddressesProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	address: CheckAddresses | undefined;
	setVisibleSubModal: (value: boolean) => void;
	containerId: string | undefined;
};

export const ModalCheckAddresses: React.FC<ModalCheckAddressesProps> = ({
	address,
	setVisible,
	visible,
	setVisibleSubModal,
	containerId,
}) => {
	const openSubModal = () => {
		setVisibleSubModal(true);
	};

	useEffect(() => {
		setVisibleSubModal(false);
	}, [visible]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			header={
				<div className={styles.title}>
					<FaHome />
					<span>Адрес</span>
				</div>
			}
			footerItems={[
				<ModalSelectCheckButton
					setVisible={setVisible}
					openSubModal={openSubModal}
					containerId={containerId}
					key='modalSelectCheckButton'
				/>,
			]}
			footerItemsLocation='after'
			closeOnOutsideClick
			width='60%'
		>
			<div>
				<div className={styles.wrapper}>
					{address && (
						<div className={styles.content}>
							{addressFields.map((field, idx) => {
								const addressField = address[field.key];
								if (addressField) {
									return (
										<React.Fragment key={idx}>
											<span className={styles.abbreviation}>
												{field.label}:
											</span>
											<div className={styles['result-container']}>
												<span className={styles.result}>
													{addressField}
												</span>
												{field.clipboard && <CopyToClipboardButton text={addressField.toString()} />}
											</div>
										</React.Fragment>
									);
								}

								return null;
							})}
						</div>
					)}
				</div>
			</div>
		</ModalWindow>
	);
};
