import axios, {type AxiosResponse} from 'axios';
import {
	type GetEmployeesAutofillRequest,
	type GetEmployeesAutofillResponse,
	type DeleteEmployeeRequest,
	type DeleteEmployeeResponse,
	type GetEmployeeRequest,
	type GetEmployeeResponse,
	type PostEmployeesRequest,
	type PostEmployeesResponse,
	type PostEmployeesViaFileRequest,
	type PostEmployeesViaFileResponse,
	type PutEmployeeRequest,
	type PutEmployeeResponse,
} from '../models/IEmployees';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/employees';

// Добавить сотрудника
export async function postEmployees(
	data: PostEmployeesRequest,
): Promise<AxiosResponse<PostEmployeesResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получить сотрудника
export async function getEmployee(
	data: GetEmployeeRequest,
): Promise<AxiosResponse<GetEmployeeResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.employeeId}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получить сотрудников для автозаполнения
export async function getEmployeesAutofill(
	data: GetEmployeesAutofillRequest,
): Promise<AxiosResponse<GetEmployeesAutofillResponse>> {
	return axios.get(`${domain}${urlPrefix}`, {
		params: data,
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Обновить сотрудника
export async function putEmployee(
	data: PutEmployeeRequest,
): Promise<AxiosResponse<PutEmployeeResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.employeeId}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Добавить сотрудников из файла
export async function postEmployeesViaFile(
	data: PostEmployeesViaFileRequest,
): Promise<AxiosResponse<PostEmployeesViaFileResponse>> {
	const formData = new FormData();
	formData.append('file', data.body.file);
	formData.append('department_id', data.body.department_id.toString());

	return axios.post(`${domain}${urlPrefix}/from-file`, formData, {
		headers: {
			Authorization: `Bearer ${getToken().token}`,
			ContentType: 'multipart/form-data',
		},
	});
}

// Удалить сотрудника
export async function deleteEmployee(
	data: DeleteEmployeeRequest,
): Promise<AxiosResponse<DeleteEmployeeResponse>> {
	return axios.delete(`${domain}${urlPrefix}/${data.employeeId}`, {
		headers: {
			Authorization: `Bearer ${getToken().token}`,
		},
	});
}
