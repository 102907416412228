import React from 'react';
import styles from '../../ReportCard.module.css';
import {type ReportModelV3Phone} from '../../../../../../../report/reportModelV3/reportModelV3';
import {Phone} from '../../../../../Message';
import {CopyToClipboardButton} from '../../../../../Button';
import {SelectCheckButton} from '../../../../../Button/ReportButtons';

type ContactCardProps = {
	phone: ReportModelV3Phone;
	tags: string[];
	onClick: () => void;
	onClickSub: () => void;
	containerId: string | undefined;
};

const PhoneCard: React.FC<ContactCardProps> = ({
	phone,
	tags,
	onClick,
	onClickSub,
	containerId,
}) => (
	<div className={`${styles.card} ${styles.click}`} onClick={onClick}>
		<div className={styles.header}>
			{phone.region} {phone.provider}
		</div>
		<div className={styles.content}>
			<div className={styles.info}>
				<span className={`${styles.contact} ${styles['text-overflow']}`}>
					<Phone phone={phone.phone_number} formatPhone/>
				</span>
				<CopyToClipboardButton text={phone.phone_number} format='phone'/>
				<SelectCheckButton containerId={containerId} openSubCheck={onClickSub} />
			</div>
			<div className={styles['tag-group']}>
				{tags.slice(0, 2).map((item, index) => (
					item.trim() !== '' && (
						<div key={index} className={styles.tags}>
							<span>#</span>
							<span>{item}</span>
						</div>
					)
				))}
			</div>
		</div>
	</div>
);

export default PhoneCard;
