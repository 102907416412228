import axios, {type AxiosResponse} from 'axios';
import {
	type PostCandidateRequest,
	type PostCandidateResponse,
	type GetCandidateRequest,
	type GetCandidateResponse,
	type PutCandidateRequest,
	type PutCandidateResponse,
} from '../models/ICandidates';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/candidates';

// Получение кандидата
export async function getCandidate(
	data: GetCandidateRequest,
): Promise<AxiosResponse<GetCandidateResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.candidateId}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Добавление кандидата
export async function postCandidate(
	data: PostCandidateRequest,
): Promise<AxiosResponse<PostCandidateResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Обновление кандидата
export async function putCandidate(
	data: PutCandidateRequest,
): Promise<AxiosResponse<PutCandidateResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.candidateId}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
