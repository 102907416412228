import {postCreatePassport} from '../../../api/checkApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type PostCheckCreatePassportRequest, type PostCheckCreatePassportResponse} from '../../../models/ICheck';

const postCheckCreatePassportAction = createActionTemplateAxios<
PostCheckCreatePassportResponse,
PostCheckCreatePassportRequest>(
	'checks/postCreatePassport/action',
	postCreatePassport,
	'userToken',
);

export default postCheckCreatePassportAction;
