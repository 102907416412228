import React from 'react';
import styles from './SummaryData.module.css';
import {useAppSelector} from '../../../redux/redux-hooks';
import {SummaryDataItem} from './SummaryDataItem/SummaryDataItem';
import {blocksIds} from '../../../report/reportModelEnum';

export const SummaryData = () => {
	const summaryData = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.summary_data);

	if (!summaryData || summaryData.length === 0) {
		return null;
	}

	return (
		<div data-group='report-block' id={blocksIds.summaryData}>
			<div className={styles.content}>
				<h4 className={styles.title}>Сводные данные</h4>
				<div className={styles['summary-data']}>
					{summaryData.map((item, index) => (
						<SummaryDataItem key={index} summaryData={item} />
					))}
				</div>
			</div>
		</div>
	);
};
