import axios, {type AxiosResponse} from 'axios';
import {getGuestToken} from '../../utils/cookies/guestToken/getGuestToken';
import {
	type PostGuestOtpSendRequest,
	type PostGuestOtpSendResponse,
	type GetGuestResponse,
	type PutGuestRequest,
	type PutGuestResponse,
	type PutGuestSignDocsResponse,
	type PutGuestSignDocsRequest,
} from '../../models/Guest/Guest/IGuest';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/guest';

// Получение информации о госте
export async function getGuest(): Promise<AxiosResponse<GetGuestResponse>> {
	return axios.get(`${domain}${urlPrefix}`, {
		headers: {
			Authorization: `Bearer ${getGuestToken().token}`,
		},
	});
}

// Обновление информации о госте
export async function putGuest({body}: PutGuestRequest): Promise<AxiosResponse<PutGuestResponse>> {
	return axios.put(
		`${domain}${urlPrefix}`,
		body,
		{
			headers: {
				Authorization: `Bearer ${getGuestToken().token}`,
			},
		},
	);
}

// Получение otp кода для гостя
export async function postGuestOtpSend({body}: PostGuestOtpSendRequest): Promise<AxiosResponse<PostGuestOtpSendResponse>> {
	return axios.post(`${domain}${urlPrefix}/otp_send`,
		body,
		{
			headers: {
				Authorization: `Bearer ${getGuestToken().token}`,
			},
		});
}

// Подписание документа otp кодом
export async function putGuestSignDocs({body}: PutGuestSignDocsRequest): Promise<AxiosResponse<PutGuestSignDocsResponse>> {
	return axios.put(`${domain}${urlPrefix}/sign_docs`,
		body,
		{
			headers: {
				Authorization: `Bearer ${getGuestToken().token}`,
			},
		});
}
