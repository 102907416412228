import React, {useMemo, useState} from 'react';
import styles from './Nicknames.module.css';
import {MdOutlineDriveFileRenameOutline} from 'react-icons/md';
import {type CheckNicknamesFromContacts, type CheckNicknames} from '../../../../report/ReportModel';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {PossibleNameCard} from '../../../UiKit/Cards/Report/Cards/ContactCards/PossibleNameCard/PossibleNameCard';
import {ModalConfirmationPhone, ModalPossibleName} from '../../../Modal/Report';
import {ReportBlockTemplate} from '..';

type NicknamesProps = {
	nicknames?: CheckNicknames[] | undefined;
};

export const Nicknames: React.FC<NicknamesProps> = () => {
	const nicknames = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.nicknames);
	const [visibleModalPossibleName, setVisibleModalPossibleName] = useState(false);
	const [visibleSubModalPhone, setVisibleSubModalPhone] = useState(false);
	const [selectedContact, setSelectedContact] = useState<CheckNicknamesFromContacts | undefined>(undefined);
	const [selectedContainerId, setSelectedContainerId] = useState<string>();

	const openModalPossibleName = (contact: CheckNicknamesFromContacts, containerId?: string) => {
		setVisibleModalPossibleName(true);
		setSelectedContact(contact);
		setSelectedContainerId(containerId);
	};

	const openSubModalPhone = (contact: CheckNicknamesFromContacts, containerId?: string) => {
		setSelectedContact(contact);
		setVisibleSubModalPhone(true);
		setSelectedContainerId(containerId);
	};

	const nicknameList = useMemo(() => {
		const list: React.ReactNode[] = [];

		if (nicknames && nicknames.data.length > 0) {
			nicknames.data.forEach((nickName, index) => {
				const filterContacts = nickName.item.from_contacts.filter(contact => contact.name && contact.phone);

				if (nickName.item.self.length > 0 || filterContacts.length > 0) {
					list.push(
						<div key={index} className={styles['nick-name']}>
							{filterContacts.length > 0 && (
								<div className={styles['contacts-container']}>
									{filterContacts.map((contact, index) => (
										<PossibleNameCard
											contact={contact}
											key={index}
											onClickSub={() => {
												openSubModalPhone(contact);
											}}
											onCLick={() => {
												openModalPossibleName(contact);
											}}
											containerId={undefined}
										/>
									))}
								</div>
							)}
						</div>,
					);
				}
			});
		}

		return list;
	}, [nicknames]);

	return (
		<ReportBlockTemplate
			blockIcon={MdOutlineDriveFileRenameOutline}
			blockTitle='Псевдонимы'
			alerts={nicknames?.additionalData.alerts}
			styleType='outline'
			blocksId='nicknames'
			visible={nicknameList.length > 0}
		>
			<div className={styles['name-group']}>
				{nicknameList}
			</div>
			<ModalConfirmationPhone
				visible={visibleSubModalPhone}
				setVisible={setVisibleSubModalPhone}
				phone={selectedContact?.phone}
				containerId={selectedContainerId}
			/>
			<ModalPossibleName
				visible={visibleModalPossibleName}
				setVisible={setVisibleModalPossibleName}
				contact={selectedContact}
				setVisibleSubModal={setVisibleSubModalPhone}
				containerId={selectedContainerId}
			/>
		</ReportBlockTemplate>
	);
};
