import {type ResponseStatus} from '../../../models/IAuth';
import {type GetEmployeeResponse} from '../../../models/IEmployees';
import {createSlice} from '@reduxjs/toolkit';
import {getEmployeeAction} from '../../action/employees';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetEmployeeResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getEmployeesSlice = createSlice({
	name: 'employees/getEmployee',
	initialState,
	reducers: {
		getEmployeesResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getEmployeeAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getEmployeeAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(getEmployeeAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getEmployeesResetState} = getEmployeesSlice.actions;
export default getEmployeesSlice.reducer;
