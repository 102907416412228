import React from 'react';
import styles from './Sources.module.css';
import {type Source} from '../../../report/ReportModel';
import {BsFillClipboard2DataFill} from 'react-icons/bs';

type SourcesProps = {
	source: Source;
};

export const Sources: React.FC<SourcesProps> = ({source}) => (
	<div data-group='report-block' id={source.key}>
		<div className={styles.content}>
			<div className={styles.title}>
				<BsFillClipboard2DataFill />
				<span>{source.name}</span>
			</div>

			<div className={styles['source-group']}>
				{source.fields.map((field, index) => (
					<React.Fragment key={index}>
						<span className={styles['source-label']}>
							{field.translation}
						</span>
						<span className={styles['source-field']}>
							{field.value}
						</span>
					</React.Fragment>
				))}
			</div>
		</div>
	</div>
);
