/* eslint-disable @typescript-eslint/naming-convention */
import axios, {type AxiosResponse} from 'axios';
import {
	type GetNavigationDepartmentsRequest,
	type GetNavigationCompaniesResponse,
	type GetNavigationDepartmentsResponse,
	type GetNavigationResponse,
} from '../../models/Navigation/Navigation';
import {getNavigationCookies} from '../../utils/cookies';
import {getToken} from '../../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/navigation';

// Получение информации о текущем контексте
export async function getNavigation(): Promise<AxiosResponse<GetNavigationResponse>> {
	const navigation = getNavigationCookies();
	return axios.get(
		`${domain}${urlPrefix}`,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
				'X-NAVIGATION-COMPANY-HASH': navigation.navigation_company_hash,
				'X-NAVIGATION-DEPARTMENT-HASH': navigation.navigation_department_hash,
			},
		},
	);
}

// Получение компаний с навигационными хешами
export async function getNavigationCompanies(): Promise<AxiosResponse<GetNavigationCompaniesResponse>> {
	return axios.get(
		`${domain}${urlPrefix}/companies`,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
			},
		},
	);
}

// Получение отделов с навигационными ключами
export async function getNavigationDepartments(data: GetNavigationDepartmentsRequest): Promise<AxiosResponse<GetNavigationDepartmentsResponse>> {
	return axios.get(
		`${domain}${urlPrefix}/departments`,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
				'X-NAVIGATION-COMPANY-HASH': data.navigation_company_hash,
			},
		},
	);
}
