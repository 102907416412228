import React, {useEffect, useState} from 'react';
import styles from './Notifications.module.css';
import {setIsFixedSidebar, setIsSoundNotification} from '../../../redux/slice/settingsSlice';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {CheckBoxDevEx} from '../../UiKit/Checkbox';

export const Notifications = () => {
	const dispatch = useAppDispatch();
	const settings = useAppSelector(state => state.settings);

	const [soundNotification, setSoundNotification] = useState(settings.isSoundNotification);

	useEffect(() => {
		dispatch(setIsSoundNotification(soundNotification));
	}, [soundNotification]);

	return (
		<div className={styles.content}>
			<div className={styles.options}>
				<span>Параметры</span>
				<div className={styles['checkbox-group']}>
					<CheckBoxDevEx isChecked={soundNotification} setIsChecked={setSoundNotification} />
					<span>Звук уведомлений</span>
				</div>
			</div>
		</div>
	);
};
