import React from 'react';
import styles from './LinkUrl.module.css';

type LinkUrlProps = {
	url: string;
};

export const LinkUrl: React.FC<LinkUrlProps> = ({url}) => (
	<a href={url} target='_blank' className={styles.link} rel='noreferrer'>
                Перейти по ссылке
	</a>
);
