import {putContact} from '../../../api/contactsApi';
import {type PutContactResponse, type PutContactRequest} from '../../../models/IContacts';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const putContactAction = createActionTemplateAxios<
PutContactResponse,
PutContactRequest>(
	'contacts/putContact/action',
	putContact,
	'userToken',
);

export default putContactAction;
