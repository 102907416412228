import React, {useEffect, useState} from 'react';
import styles from './AddingCandidate.module.css';
import {InputEmail, InputEmployeesAutofill, InputName} from '../../../UiKit/Inputs';
import {type GetEmployeesAutofillItem} from '../../../../models/IEmployees';

type AddingCandidateProps = {
	name: string;
	setName: (value: string) => void;
	lastName: string;
	setLastName: (value: string) => void;
	middleName: string | undefined;
	setMiddleName: (value: string | undefined) => void;
	fromWhomCandidate: React.MutableRefObject<GetEmployeesAutofillItem | undefined>;
	email: string;
	setEmail: (value: string) => void;
	setIsValid: (value: boolean) => void;
	showValidation: boolean;
	visible: boolean;
};

export const AddingCandidate: React.FC<AddingCandidateProps> = ({
	fromWhomCandidate,
	showValidation,
	visible,
	...props
}) => {
	const [isValidLastName, setIsValidLastName] = useState(false);
	const [isValidName, setIsValidName] = useState(false);
	const [isValidMiddleName, setIsValidMiddleName] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);

	useEffect(() => {
		props.setIsValid(isValidLastName && isValidName && isValidMiddleName && isValidEmail);
	}, [isValidLastName,
		isValidName,
		isValidMiddleName,
		isValidEmail]);

	return (
		<div className={styles['adding-candidate']}>
			<span className={`text-title-3 ${styles.title}`}>Личные данные кандидата</span>
			<InputName
				value={props.lastName}
				setValue={props.setLastName}
				title='Фамилия'
				inputStyles={{
					placeholder: 'Фамилия',
				}}
				inputValidation={{
					isRequired: true,
					minLength: 2,
					lettersOnly: true,
					setIsValid: setIsValidLastName,
					isValid: isValidLastName,
					showValidation,
					visible,
				}}
				parts={['SURNAME']}
			/>
			<InputName
				value={props.name}
				setValue={props.setName}
				title='Имя'
				inputStyles={{
					placeholder: 'Имя',
				}}
				inputValidation={{
					isRequired: true,
					minLength: 2,
					lettersOnly: true,
					setIsValid: setIsValidName,
					isValid: isValidName,
					showValidation,
					visible,
				}}
				parts={['NAME']}
			/>
			<InputName
				value={props.middleName}
				setValue={props.setMiddleName}
				title='Отчество'
				inputStyles={{
					placeholder: 'Отчество',
				}}
				inputValidation={{
					minLength: 2,
					lettersOnly: true,
					setIsValid: setIsValidMiddleName,
					isValid: isValidMiddleName,
					showValidation,
					visible,
				}}
				parts={['PATRONYMIC']}
			/>
			<InputEmail
				value={props.email}
				setValue={props.setEmail}
				isValid={isValidEmail}
				setIsValid={setIsValidEmail}
				showValidation={showValidation}
				isRequired
				title='Электронный адрес'
				inputStyles={{
					placeholder: 'Электронный адрес',
				}}
				inputAttr={{
					autoComplete: 'new-password',
				}}
				visible={visible}
			/>
			<InputEmployeesAutofill
				employeeRef={fromWhomCandidate}
				title='Рекомендация'
				inputStyles={{
					placeholder: 'Фамилия рекомендовавшего',
				}}
			/>
		</div>
	);
};
