import React from 'react';
import styles from './CompanyField.module.css';
import {type NavigationCompany} from '../../../../../../models/Navigation/Navigation';
import socratLogo from '../../../../../../assets/logo/socratLogo.svg';
import {TextBox} from 'devextreme-react';

type CompanyFieldProps = {
	selectedCompany: NavigationCompany | undefined;
};

const CompanyField: React.FC<CompanyFieldProps> = ({
	selectedCompany,
}) => (
	<div>
		<div className={styles.container}>
			{selectedCompany ? (
				<>
					<img src={socratLogo} className={styles.logo} />
					<span>
						{selectedCompany.name_short}
					</span>
				</>
			) : (
				<span className={styles.placeholder}>
					Выберите компанию
				</span>
			)}
		</div>
		<TextBox visible={false} />
	</div>
);

export default CompanyField;
