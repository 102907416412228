import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetDepartmentPositionsResponse} from '../../../models/IDepartment';
import {getDepartmentPositionsAction} from '../../action/department';
import {type DepartmentPositionWithEmployee} from '../../../models/Schemas';
import {getFullName} from '../../../utils';

type GetDepartmentPositionsState = {
	responseStatus: ResponseStatus;
	response: GetDepartmentPositionsProceedResponseItem[] | undefined;
};

export type GetDepartmentPositionsProceedResponseItem = DepartmentPositionWithEmployee & {
	fullNameEmployee?: string;
};

const getProcessedResponse = (response: GetDepartmentPositionsResponse) => response.map<GetDepartmentPositionsProceedResponseItem>(
	position => ({
		...position,
		fullNameEmployee: getFullName(position.employee?.first_name, position.employee?.middle_name, position.employee?.last_name, 'LFM'),
	}),
);

const initialState: GetDepartmentPositionsState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const getDepartmentPositionsSlice = createSlice({
	name: 'department/getDepartmentPositions',
	initialState,
	reducers: {
		resetState: () => initialState,
		updatePosition(state, action: PayloadAction<DepartmentPositionWithEmployee>) {
			state.response = state.response?.map(item => {
				if (item.id === action.payload.id) {
					return action.payload;
				}

				return item;
			});
		},
		addPosition(state, action: PayloadAction<DepartmentPositionWithEmployee>) {
			if (state.response) {
				state.response = [...state.response, action.payload];
			} else {
				state.response = [action.payload];
			}
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getDepartmentPositionsAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getDepartmentPositionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = getProcessedResponse(action.payload);
			})
			.addCase(getDepartmentPositionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {resetState, updatePosition, addPosition} = getDepartmentPositionsSlice.actions;
export default getDepartmentPositionsSlice.reducer;
