import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getCheck} from '../../../api/checkApi';
import {type GetCheckResponse} from '../../../models/ICheck';

const getCheckAction = createActionTemplateAxios<GetCheckResponse, void>(
	'checks/getCheck',
	getCheck,
	'userToken',
);

export default getCheckAction;
