import React, {useCallback, useRef} from 'react';
import styles from './ChartPieTemplate.module.css';
import PieChart, {Connector, Label, Legend, Series, Tooltip} from 'devextreme-react/cjs/pie-chart';
import {type Palette} from 'devextreme/viz/pie_chart_types';
import {
	type ChartCommonProps,
	type ChartCustomizeTooltipData,
	type ChartCustomizeTooltipReturn,
} from '..';
import {returnIfDefined} from '../../../../utils/checkTypes/checkNull';

export type CenterRenderData = {
	content: string;
	label: string;
};

export type ChartPieTemplateProps = {
	dataSource: any[];
	argumentField?: string;
	valueField?: string;
	palette?: string[] | Palette;
	centerRenderData?: CenterRenderData;
	customizeLabel?: ((pointInfo: any) => string);
	height?: number | string;
	width?: number | string;
} & ChartCommonProps;

const ChartPieTemplate: React.FC<ChartPieTemplateProps> = ({
	dataSource,
	centerRenderData,
	palette = ['var(--chart-pie-palette-1)', 'var(--chart-pie-palette-2)'],
	tooltip,
	children,
	containerRef,
	height,
	width,
	...props
}) => {
	const pieChartRef = useRef<PieChart>(null);

	const defaultCustomizeTooltip = (data: ChartCustomizeTooltipData): ChartCustomizeTooltipReturn => {
		const argument = returnIfDefined(data.argument, '');
		const valueText = returnIfDefined(data.valueText, '');

		return {
			text: `${argument}: ${valueText}`,
		};
	};

	const center = useCallback(() => {
		if (pieChartRef.current && centerRenderData) {
			const innerRadius = pieChartRef.current?.instance.getInnerRadius();
			const {label, content} = centerRenderData;
			return (<svg className={styles['pie-center']}>
				<circle
					cx='100'
					cy='100'
					r={innerRadius - 6}
				/>
				<text
					textAnchor='middle'
					x='100'
					y='100'
				>
					<tspan
						x='100'
						className={styles['pie-center-content']}
					>{content}</tspan>
					<tspan
						x='100'
						dy='20px'
						className={styles['pie-center-label']}
					>{label}</tspan>
				</text>
			</svg>);
		}

		return null;
	}, [pieChartRef, centerRenderData]);

	return (
		<>
			<PieChart
				dataSource={dataSource}
				resolveLabelOverlapping='shift'
				sizeGroup='piesGroup'
				centerRender={center}
				innerRadius={0.7}
				type='doughnut'
				palette={palette}
				ref={pieChartRef}
				className={styles['pie-chart']}
				height={height}
				width={width}
			>
				<Series
					argumentField={props.argumentField}
					valueField={props.valueField}
				>
					<Label
						visible={true}
						format='fixedPoint'
						customizeText={props.customizeLabel}
						backgroundColor='none'
						font={{
							size: 20,
							family: 'var(--font-regular)',
						}}
					>
						<Connector visible={true}/>
					</Label>
				</Series>
				<Tooltip
					enabled={tooltip?.enabled}
					customizeTooltip={tooltip?.customizeTooltip ?? defaultCustomizeTooltip}
					font={{
						size: 14,
						family: 'var(--font-regular)',
					}}
				/>
				<Legend visible={false}/>
				{children}
			</PieChart>
		</>
	);
};

export default ChartPieTemplate;
