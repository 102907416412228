import {putUser} from '../../../api/usersApi';
import {type PutUserRequest} from '../../../models/IUsers';
import {type User} from '../../../models/Schemas';
import {updatedUser} from '../../slice/users';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const putUserAction = createActionTemplateAxios<User, PutUserRequest>(
	'user/putUser/action',
	putUser,
	'userToken',
	updatedUser,
);

export default putUserAction;
