import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostCandidateResponse} from '../../../models/ICandidates';
import {postCandidateAction} from '../../action/candidates';

type postCandidateSliceState = {
	responseStatus: ResponseStatus;
	response: PostCandidateResponse | undefined;
};

const initialState: postCandidateSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postCandidateSlice = createSlice({
	name: 'candidates/postCandidate',
	initialState,
	reducers: {
		postCandidateResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postCandidateAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postCandidateAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postCandidateAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postCandidateResetState} = postCandidateSlice.actions;
export default postCandidateSlice.reducer;
