import React from 'react';
import {RadioSelector} from '../index';
import {type RadioSelectorItems} from '../RadioSelector/RadioSelector';
import {Label, type LabelCommonProps} from '../../Common';
import styles from './SelectorGender.module.css';

type SelectorGenderProps = {
	setGender: (value: string) => void;
	disabled?: boolean;
	isRequired?: boolean;
	styleType?: 'large' | 'medium' | 'small';
} & LabelCommonProps;

const gender: RadioSelectorItems[] = [{text: 'Мужской', selectedData: 'm'}, {text: 'Женский', selectedData: 'f'}];
export const SelectorGender: React.FC<SelectorGenderProps> = ({
	setGender,
	disabled,
	isRequired,
	styleType,
	titleColor,
	title,
}) => (
	(
		<>
			<Label
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				disabled={disabled}
				isRequired={isRequired}
			/>
			<div className={styles.list}>
				<RadioSelector items={gender} setSelectedData={setGender}/>
			</div>
		</>
	)
);
