import React, {useEffect, useState} from 'react';
import styles from './PersonalDataAgreement152fz.module.css';
import {InputName} from '../../UiKit/Inputs';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import PersonalDataAgreementSignDocuments from './PersonalDataAgreementSignDocuments/PersonalDataAgreementSignDocuments';
import PersonalDataAgreementLinks from './PersonalDataAgreementLinks/PersonalDataAgreementLinks';
import {useEffectHandlerLoadingAllRequest} from '../../../utils/hooks';
import {putGuestAction} from '../../../redux/action/guest';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import Loader from '../../Loader/Loader';

type PersonalDataAgreement152fzProps = {
	onAgreement: () => void;
};

const PersonalDataAgreement152fz: React.FC<PersonalDataAgreement152fzProps> = ({
	onAgreement,
}) => {
	const dispatch = useAppDispatch();
	const getGuest = useAppSelector(state => state.getGuest);
	const putGuest = useAppSelector(state => state.putGuest);

	// ФИО
	const [firstName, setFirstName] = useState<string>();
	const [middleName, setMiddleName] = useState<string>();
	const [lastName, setLastName] = useState<string>();
	const [showValidation, setShowValidation] = useState(false);
	const [isValidName, setIsValidName] = useState<boolean>(false);
	const [isValidMiddleName, setIsValidMiddleName] = useState<boolean>(false);
	const [isValidLastName, setIsValidLastName] = useState<boolean>(false);

	const [isInputOtp, setIsInputOtp] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	const putGuestRequest = () => {
		if (firstName && lastName) {
			return dispatch(putGuestAction({
				body: {
					first_name: firstName,
					last_name: lastName,
					middle_name: middleName ?? null,
				},
			})).requestId;
		}
	};

	const resetComponentStates = () => {
		setFirstName(undefined);
		setMiddleName(undefined);
		setLastName(undefined);
		setShowValidation(false);
		setIsValidName(false);
		setIsValidMiddleName(false);
		setIsValidLastName(false);
		setIsInputOtp(false);
		hiddenLoading();
	};

	useEffect(() => {
		resetComponentStates();
	}, []);

	useEffectHandlerLoadingAllRequest({
		state: getGuest,
		onLoading() {
			showLoading('Получение данных...');
		},
		onStopLoading() {
			hiddenLoading();
		},
		onComplete(response) {
			setFirstName(response?.first_name ?? undefined);
			setLastName(response?.last_name ?? undefined);
			setMiddleName(response?.middle_name ?? undefined);
			if (!response?.middle_name) {
				setIsValidMiddleName(true);
			}
		},
		onError(errorMessage) {
			notifyMessage('error', errorMessage);
		},
	});

	useEffectHandlerLoadingAllRequest({
		state: putGuest,
		onLoading() {
			showLoading('Обновление ФИО...');
		},
		onStopLoading() {
			hiddenLoading();
		},
		onError(errorMessage) {
			notifyMessage('error', errorMessage);
		},
	});

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles['header-container']}>
					Подтверждение согласия на обработку персональных данных
				</div>
				<div className={styles['scrollable-content']}>
					<div className={styles.fio}>
						<InputName
							value={lastName}
							setValue={setLastName}
							inputStyles={{
								placeholder: 'Введите фамилию',
							}}
							inputValidation={{
								isValid: isValidLastName,
								setIsValid: setIsValidLastName,
								lettersOnly: true,
								isRequired: true,
								minLength: 2,
								showValidation,
							}}
							parts={['SURNAME']}
							title='Фамилия'
							disabled={isInputOtp}
						/>
						<InputName
							value={firstName}
							setValue={setFirstName}
							inputStyles={{
								placeholder: 'Введите имя',
							}}
							inputValidation={{
								isValid: isValidName,
								setIsValid: setIsValidName,
								lettersOnly: true,
								isRequired: true,
								minLength: 2,
								showValidation,
							}}
							parts={['NAME']}
							title='Имя'
							disabled={isInputOtp}
						/>
						<InputName
							value={middleName}
							setValue={setMiddleName}
							inputStyles={{
								placeholder: 'Введите отчество',
							}}
							inputValidation={{
								isValid: isValidMiddleName,
								setIsValid: setIsValidMiddleName,
								lettersOnly: true,
								minLength: 2,
								showValidation,
							}}
							parts={['PATRONYMIC']}
							title='Отчество'
							disabled={isInputOtp}
						/>
					</div>
					<PersonalDataAgreementLinks
						putGuestRequest={putGuestRequest}
					/>
					<PersonalDataAgreementSignDocuments
						isValid={isValidName && isValidMiddleName && isValidLastName}
						onVerifyOtpComplete={onAgreement}
						isInputOtp={isInputOtp}
						setIsInputOtp={setIsInputOtp}
						putGuestRequest={putGuestRequest}
					/>
					<Loader isFloating isLoading={isLoading} text={loadingMessage} />
				</div>
			</div>
		</div>
	);
};

export default PersonalDataAgreement152fz;
