import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type PostCommentRequest, type PostCommentResponse} from '../../../models/IComment';
import {postComment} from '../../../api/commentApi';

const postCommentAction = createActionTemplateAxios<
PostCommentResponse,
PostCommentRequest
>('comments/postComment', postComment, 'userToken');

export default postCommentAction;
