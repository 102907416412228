import React, {useEffect, useState} from 'react';
import {type NavigationItem} from '../NavigationData';
import {useAppSelector} from '../../../redux/redux-hooks';
import {useLocation, useNavigate} from 'react-router-dom';
import {checkPermissionPermit} from '../../../utils/permissions/permissions';
import {typePermissions} from '../../../models/Enum';
import {paths} from '../../../paths';
import {headerNavigationData} from './HeaderNavigationData';
import {NavItem} from '../NavItem/NavItem';
import styles from './HeaderNavigation.module.css';

export const HeaderNavigation = () => {
	const [activeItem, setActiveItem] = useState(0);
	const [navItem, setNavItem] = useState<NavigationItem[]>([]);

	const getPermissions = useAppSelector(state => state.getPermissions);

	const navigate = useNavigate();
	const location = useLocation();

	const handleItemClick = (path: string) => {
		navigate(path);
	};

	const classNames = (index: number) => {
		let classNames = `${styles['header-nav_item']}`;
		if (activeItem === index) {
			classNames += ` ${styles.active}`;
		}

		return classNames;
	};

	// Проверка доступности элемента навигации
	const checkAccessNavigationItem = (typePermissions: string, patch: string) => {
		const permissions = checkPermissionPermit(typePermissions, getPermissions.groupPermissions);

		if (!permissions) {
			setNavItem(p => p.filter(item => item.path !== patch));
		}
	};

	// Получение прав
	useEffect(() => {
		if (getPermissions.responseStatus.isLoading) {
			setNavItem([]);
		} else if (getPermissions.responseStatus.isCompleted) {
			setNavItem(headerNavigationData);
			checkAccessNavigationItem(typePermissions.checks_read, paths.checks);
			checkAccessNavigationItem(typePermissions.user_staff_read, paths.subordinate);
		}
	}, [getPermissions.responseStatus.isLoading]);

	// Изменение url
	useEffect(() => {
		const currentPath = location.pathname;
		const regex = /^\/departments\/[^/]+\/positions$/;
		const currentItem = headerNavigationData.findIndex(item => {
			if (item.path === paths.home) {
				return currentPath === item.path;
			}

			if (regex.test(currentPath)) {
				return false;
			}

			return currentPath.startsWith(item.path);
		});
		setActiveItem(currentItem);
	}, [location.pathname]);

	return (
		<nav>
			<ul className={styles.nav}>
				{navItem.map(item => (
					<li
						key={item.id}
						className={classNames(item.id)}
						onClick={() => {
							handleItemClick(item.path);
						}}
					>
						<NavItem item={item} isShowNames={true} />
					</li>
				))}
			</ul>
		</nav>
	);
};
