import {sendSmsRegistration} from '../../../api/usersApi';
import {
	type SmsRegistrationRequest,
	type SmsRegistrationResponse,
} from '../../../models/IUsers';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const sendSmsRegistrationAction = createActionTemplateAxios<
SmsRegistrationResponse,
SmsRegistrationRequest>(
	'auth/sendSmsRegistration/action',
	sendSmsRegistration,
	'userToken',
);

export default sendSmsRegistrationAction;
