import notify from 'devextreme/ui/notify';
import styles from './index.module.css';

type NotificationType = 'info' | 'success' | 'warning' | 'error';
export const notifyMessage = (type: NotificationType, message: string) => {
	notify({
		wrapperAttr: {class: styles['dx-t']},
		message,
		minHeight: 45,
		maxWidth: 500,
		type,
		displayTime: 2500,
		closeOnClick: true,
		animation: {
			show: {
				type: 'fade', duration: 400, from: 0, to: 1,
			},
			hide: {type: 'fade', duration: 40, to: 0},
		},
	}, {
		position: 'bottom center',
		direction: 'up-push',
	});

	return '';
};
