import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {getNavigationAction} from '../../action/navigation';
import {type GetNavigationResponse} from '../../../models/Navigation/Navigation';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetNavigationResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getNavigationSlice = createSlice({
	name: 'navigation/getNavigation',
	initialState,
	reducers: {
		getNavigationResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getNavigationAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getNavigationAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(getNavigationAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getNavigationResetState} = getNavigationSlice.actions;
export default getNavigationSlice.reducer;
