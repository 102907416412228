import type React from 'react';
import {Appearance} from './Appearance/Appearance';
import {Notifications} from './Notifications/Notifications';

export type NavSettings = {
	component: React.ComponentType;
	name: string;
};

export const navSettings: NavSettings[] = [
	{component: Appearance, name: 'Внешний вид'},
	{component: Notifications, name: 'Уведомления'},
];
