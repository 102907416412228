import React from 'react';
import {
	ButtonTemplate,
	type ButtonTemplateProps,
} from '../index';

type CustomButtonProps = ButtonTemplateProps;

export const TitleButton: React.FC<CustomButtonProps> = ({
	onClick,
	color = 'primary-outline',
	styleType = 'ellipse-button',
	borderRadius = '29px',
	text,
	...props
}) => (
	<ButtonTemplate
		onClick={onClick}
		color={color}
		styleType={styleType}
		borderRadius={borderRadius}
		text={text}
		{...props}
	/>
);
