import React from 'react';
import styles from '../BasicInfo.module.css';
import {type ReportModelV3Email} from '../../../../report/reportModelV3/reportModelV3';
import {emailBasicInfoFields} from './EmailBasicInfoFields';
import {Email} from '../../../UiKit/Message';
import {CopyToClipboardButton} from '../../../UiKit/Button';

type EmailBasicInfoProps = {
	email: ReportModelV3Email;
};

const EmailBasicInfo: React.FC<EmailBasicInfoProps> = ({
	email,
}) => (
	<div className={styles.data}>
		{emailBasicInfoFields.map((field, idx) => {
			const emailField = email[field.key];
			if (emailField) {
				return (
					<React.Fragment key={idx}>
						<span className={styles.abbreviation}>
							{field.label}:
						</span>
						<div className={styles['result-container']}>
							{field.key === 'email' ? (
								<Email email={emailField} />
							) : (
								<span className={styles.result}>
									{emailField}
								</span>
							)}
							{field.clipboard && <CopyToClipboardButton text={emailField} />}
						</div>
					</React.Fragment>
				);
			}

			return undefined;
		})}
	</div>
);

export default EmailBasicInfo;
