import React, {useState, useEffect, useRef} from 'react';
import {CheckPersonInputs} from '../../components/CheckPerson/CheckPersonInputs/CheckPersonInputs';
import styles from './Checks.module.css';
import {ChecksTable} from '../../components/Tables/ChecksTable/ChecksTable';
import {ModalCheckCreated} from '../../components/Modal';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getCheckAction} from '../../redux/action/checks';
import {type Check} from '../../models/Schemas';
import {getGroupPermission} from '../../utils';
import {typePermissions} from '../../models/Enum';
import {type CheckPermissions} from '../../components/Modal/ModalCheckCreated/ModalCheckCreated';
import {ButtonTemplate} from '../../components/UiKit/Button';
import Loader from '../../components/Loader/Loader';
import {ErrorMessage} from '../../components/UiKit/Message';
import {CandidateVerificationCard} from '../../components/UiKit/Cards';

// Получение последнего состояния
const defaultCardViewValue = () => {
	const storedValue = localStorage.getItem('cardViewChecks');
	return storedValue === null || storedValue === 'true';
};

export const Checks = () => {
	const [isCardView, setIsCardView] = useState(defaultCardViewValue());
	const [isOpenCheckCreated, setIsOpenCheckCreated] = useState(false);

	const checkPermissions = useRef<CheckPermissions>({});

	const selectedCheck = useRef<Check | undefined>(undefined);

	const meId = useAppSelector(state => state.getUser.user?.id);
	const myDepartmentId = useAppSelector(state => state.getUser.user?.department?.id);
	const getCheck = useAppSelector(state => state.getCheck);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const postCheckCreate = useAppSelector(state => state.postCheckCreate);
	const postCheckStart = useAppSelector(state => state.postCheckStart);
	const postCheckUpdate = useAppSelector(state => state.postCheckUpdate);

	const dispatch = useAppDispatch();

	// Проверка прав
	const checkPermission = (
		permissionType: string,
		isMe: boolean,
		isMyDepartment: boolean,
	) => {
		const permission = getGroupPermission(permissionType, getPermissions.groupPermissions);
		if (permission?.permit) {
			if (permission?.permission_zone === 'all') {
				return true;
			}

			if (permission?.permission_zone === 'only_my_department') {
				if (isMyDepartment) {
					return true;
				}

				return false;
			}

			if (permission?.permission_zone === 'only_owner') {
				if (isMe || isMyDepartment) {
					return true;
				}

				return false;
			}
		}

		return false;
	};

	const openModalStartCheck = (check: Check, permissions: CheckPermissions) => {
		setIsOpenCheckCreated(true);
		selectedCheck.current = check;
		checkPermissions.current = permissions;
	};

	const openModalCreateCheck = () => {
		const isZeroReportCreate = checkPermission(typePermissions.zero_report_create, false, false);
		const isStartCheck = checkPermission(typePermissions.check_start, false, false);
		const isCreateCheckRequest = checkPermission(typePermissions.create_check_request, false, false);

		setIsOpenCheckCreated(true);
		selectedCheck.current = undefined;
		checkPermissions.current = {
			isStartCheck,
			isCreateCheckRequest,
			isZeroReportCreate,
		};
	};

	// Получение карт
	const getCards = () => getCheck.filteredResponse?.map(card => {
		const isMe = card.author_id === meId;
		const isMyDepartment = card.author.department?.id === myDepartmentId;
		const isUpdateCheck = checkPermission(typePermissions.check_update, isMe, isMyDepartment);
		const isStartCheck = checkPermission(typePermissions.check_start, isMe, isMyDepartment);
		const isZeroReportUpdate = checkPermission(typePermissions.zero_report_update, isMe, isMyDepartment);

		return <CandidateVerificationCard
			openModalStartCheck={openModalStartCheck}
			checkPermissions={{
				isUpdateCheck,
				isStartCheck,
				isZeroReportUpdate,
			}}
			key={card.id}
			{...card}
		/>;
	});

	// Обновление проверок при создании или запуске
	useEffect(() => {
		if (postCheckCreate.responseStatus.isCompleted || postCheckStart.responseStatus.isCompleted || postCheckUpdate.responseStatus.isCompleted) {
			void dispatch(getCheckAction());
		}
	}, [
		postCheckCreate.responseStatus.isLoading,
		postCheckStart.responseStatus.isLoading,
		postCheckUpdate.responseStatus.isLoading,
	]);

	useEffect(() => {
		void dispatch(getCheckAction());
	}, []);

	useEffect(() => {
		if (getCheck.responseStatus.isCompleted) {
			const timeout = setTimeout(() => {
				void dispatch(getCheckAction());
			}, 300000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [getCheck.responseStatus.isLoading]);

	useEffect(() => {
		if (!isOpenCheckCreated) {
			selectedCheck.current = undefined;
		}
	}, [isOpenCheckCreated]);

	useEffect(() => {
		localStorage.setItem('cardViewChecks', JSON.stringify(isCardView));
	}, [isCardView]);

	return (
		<>
			<div className={styles.checks}>
				<div className={styles['search-group']}>
					<div className={styles.title}>
						<span className='h1-text'>Проверки</span>
						<ButtonTemplate
							onClick={openModalCreateCheck}
							text='Создать проверку'
							color={'primary'}
						/>
					</div>
					<CheckPersonInputs
						isCardView={isCardView}
						setIsCardView={setIsCardView}
					/>
				</div>
				{isCardView
					? getCheck.filteredResponse && getCheck.filteredResponse.length > 0
						? (
							<div className={styles.container}>
								{getCards()}
							</div>)
						: !getCheck.responseStatus.isLoading && (
							<h2 className={styles['empty-message']}>
							Заявки не найдены
							</h2>
						)
					: (<div className={styles['container-table']}>
						<ChecksTable
							value={getCheck.filteredResponse ?? []}
							openModalStartCheck={openModalStartCheck}
							checkPermission={checkPermission}
						/>
					</div>
					)}
				<Loader
					text='Обновление данных о заявках на проверку...'
					isLoading={getCheck.responseStatus.isLoading && !getCheck.response}
					isFloating
				/>
				<ErrorMessage
					message={getCheck.responseStatus.errorMessage}
					isError={getCheck.responseStatus.isError}
				/>
			</div>
			<ModalCheckCreated
				visible={isOpenCheckCreated}
				setVisible={setIsOpenCheckCreated}
				check={selectedCheck.current}
				permissions={checkPermissions}
			/>
		</>
	);
};
