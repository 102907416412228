import {getUser} from '../../../api/usersApi';
import {type User} from '../../../models/Schemas';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getUserAction = createActionTemplateAxios<User, void>(
	'user/getUser/action',
	getUser,
	'userToken',
);

export default getUserAction;
