import React, {useEffect, useRef, useState} from 'react';
import styles from './CardComments.module.css';
import {UserComment} from '../UserComment/UserComment';
import InputTextArea from '../../UiKit/Inputs/Template/InputTextArea/InputTextArea';
import {ButtonTemplate} from '../../UiKit/Button';
import {MdClose} from 'react-icons/md';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {useParams} from 'react-router-dom';
import {getCommentByIdAction, postCommentAction, putCommentAction} from '../../../redux/action/comments';
import Loader from '../../Loader/Loader';
import {type Comment} from '../../../models/IComment';
import {ErrorMessage} from '../../UiKit/Message';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import {BiComment} from 'react-icons/bi';
import {ExpandingCard} from '../../UiKit/Cards';
import {LuSend} from 'react-icons/lu';
import {useEffectHandlerLoadingCurrentRequest} from '../../../utils/hooks';

export const CardComments = () => {
	const {id} = useParams();
	const endOfCommentsRef = useRef<HTMLDivElement>(null);
	const getCommentsRequestId = useRef<string | undefined>(undefined);
	const putCommentsRequestId = useRef<string | undefined>(undefined);
	const postCommentsRequestId = useRef<string | undefined>(undefined);

	// State
	const dispatch = useAppDispatch();
	const getCommentByIdState = useAppSelector(state => state.getCommentById);
	const putCommentState = useAppSelector(state => state.putComment);
	const postCommentState = useAppSelector(state => state.postComment);
	// Get
	const [comments, setComments] = useState<Comment[]>([]);
	const [isLoadingComments, setIsLoadingComments] = useState(false);
	const [isErrorComments, setIsErrorComments] = useState(false);
	const [commentsError, setCommentsError] = useState('');
	// Put
	const [putComment, setPutComment] = useState<Comment | undefined>(undefined);
	const [isLoadingPutComment, setIsLoadingPutComment] = useState(false);
	const [isPutComment, setIsPutComment] = useState(false);
	// Post
	const [isLoadingPostComment, setIsLoadingPostComment] = useState(false);
	// TextArea
	const [message, setMessage] = useState('');

	// Перейти к обновлению комментария
	const startPutComment = (id: number) => {
		const comment = comments.find(item => item.id === id);

		if (comment) {
			setIsPutComment(true);
			setPutComment(comment);
			setMessage(comment.content);
		}
	};

	// Перейти к отправке комментария
	const startPostComment = () => {
		setIsPutComment(false);
		setMessage('');
	};

	// Отправить запрос на добавление комментария
	const sendRequestPostComment = () => {
		const check_id = id ? parseInt(id, 10) : undefined;

		if (check_id && message) {
			postCommentsRequestId.current = dispatch(postCommentAction({
				content: message,
				check_id,
			})).requestId;
		}
	};

	// Отправить запрос на обновление комментария
	const sendRequestPutComment = () => {
		if (putComment && message) {
			putCommentsRequestId.current = dispatch(putCommentAction({
				comment_id: putComment?.id,
				body: {
					content: message,
				},
			})).requestId;
		}
	};

	// Отправить запрос на получение комментариев
	const sendRequestGetComments = () => {
		const check_id = id ? parseInt(id, 10) : undefined;
		if (check_id) {
			getCommentsRequestId.current = dispatch(getCommentByIdAction({check_id})).requestId;
		}
	};

	// Обработка первого рендер
	useEffect(() => {
		sendRequestGetComments();
		startPostComment();
	}, [id]);

	// Обработка получения списка комментариев
	useEffect(() => {
		const {response, responseStatus} = getCommentByIdState;
		if (getCommentsRequestId.current && getCommentsRequestId.current === responseStatus?.requestId) {
			setIsLoadingComments(responseStatus.isLoading);
			setIsErrorComments(responseStatus.isError);
			setCommentsError(responseStatus.errorMessage);
			if (responseStatus.isCompleted) {
				setComments(response);
			} else if (responseStatus.isError) {
				setComments(response);
				notifyMessage('error', `Ошибка получения комментариев: ${responseStatus.errorMessage}`);
			}
		}
	}, [getCommentByIdState.responseStatus.isLoading]);

	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getCommentsRequestId,
		state: getCommentByIdState,
		onLoading() {
			setIsLoadingComments(true);
			setIsErrorComments(false);
		},
		onStopLoading() {
			setIsLoadingComments(false);
		},
		onComplete: setComments,
		onError(errorMessage, response) {
			setComments(response ?? []);
			notifyMessage('error', `Ошибка получения комментариев: ${errorMessage}`);
		},
	});

	// Обработка обновления комментария
	useEffect(() => {
		const {responseStatus} = putCommentState;
		if (putCommentsRequestId.current && putCommentsRequestId.current === responseStatus?.requestId) {
			setIsLoadingPutComment(responseStatus.isLoading);
			if (responseStatus.isCompleted) {
				sendRequestGetComments();
				startPostComment();
				notifyMessage('success', 'Комментарий обновлён');
			} else if (responseStatus.isError) {
				notifyMessage('error', `Ошибка обновления комментария: ${responseStatus.errorMessage}`);
			}
		}
	}, [putCommentState.responseStatus.isLoading]);

	// Обработка добавления комментария
	useEffect(() => {
		const {responseStatus} = postCommentState;
		if (postCommentsRequestId.current && postCommentsRequestId.current === responseStatus?.requestId) {
			setIsLoadingPostComment(responseStatus.isLoading);
			if (responseStatus.isCompleted) {
				setMessage('');
				sendRequestGetComments();
				notifyMessage('success', 'Комментарий добавлен');
			} else if (responseStatus.isError) {
				notifyMessage('error', `Ошибка добавления комментария: ${responseStatus.errorMessage}`);
			}
		}
	}, [postCommentState.responseStatus.isLoading]);

	useEffect(() => {
		endOfCommentsRef.current?.scrollIntoView();
	}, [comments, endOfCommentsRef.current]);

	return (
		<ExpandingCard
			HeaderIcon={BiComment}
			headerText='Комментарии'
			hederPadding={16}
			contentPadding='12px 18px 10px 10px'
			height='100%'
			isOpened
		>
			<div className={styles.card}>
				<div className={styles['messages-wrapper']}>
					<div className={styles['messages-container']}>
						<div className={styles['messages-list']}>
							{comments.map(item => (
								<UserComment
									key={item.id}
									comment={item}
									editMessage={startPutComment}
									sendRequestGetComments={sendRequestGetComments}
								/>
							))}
						</div>
						<div ref={endOfCommentsRef} />
					</div>
					<Loader isLoading={isLoadingComments} isFloating />
					<ErrorMessage isError={isErrorComments} message={commentsError} />
				</div>
				<div className={styles.footer}>
					{
						isPutComment && (
							<div className={styles['edit-message-container']}>
								<div className={styles['edit-message-header']}>
									<span className={styles['edit-message-title']}>Редактирование</span>
									<MdClose className={styles['edit-message-close']} onClick={startPostComment} />
								</div>
								<span className={styles['edit-message-content']}>{putComment?.content}</span>
							</div>
						)
					}
					<div className={styles['create-message-container']}>
						<div className={styles['create-message-input-container']}>
							<InputTextArea
								value={message}
								setValue={setMessage}
								autoResizeEnabled
								maxHeight={70}
								inputValidation={{
									maxLength: 255,
								}}
								onKeyDown={e => {
									if (e.event?.key === 'Enter' && !e.event.shiftKey) {
										e.event?.preventDefault();
										if (isPutComment) {
											sendRequestPutComment();
										} else {
											sendRequestPostComment();
										}
									}
								}}
							/>
						</div>
						<div className={styles['create-message-button-container']}>
							<ButtonTemplate
								onClick={isPutComment ? sendRequestPutComment : sendRequestPostComment}
								color='dark-blue'
								styleType='icon-bg'
								borderRadius='100%'
								Icon={LuSend}
								iconStyleType='small'
								TooltipMessage={isPutComment ? 'Изменить' : 'Создать'}
								isLoading={isLoadingPutComment}
							/>
						</div>
					</div>
				</div>
			</div>
		</ExpandingCard>
	);
};
