import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../../utils/cookies/token/getToken';
import {type PutReportResolutionRequest, type PutReportResolutionResponse} from '../../models/Report/Resolution/IResolution';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/check/resolution';

// Обновить резолюцию по отчёту
export async function putReportResolution({id, body}: PutReportResolutionRequest): Promise<AxiosResponse<PutReportResolutionResponse>> {
	return axios.put(
		`${domain}${urlPrefix}/${id}`,
		body,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
			},
		},
	);
}
