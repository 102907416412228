import React, {useEffect, useMemo, useState} from 'react';
import styles from './JobsTable.module.css';
import commonTableStyles from '../CommonTableStyles.module.css';
import {IoCloseSharp, IoEyeOutline} from 'react-icons/io5';
import {TiUserAddOutline} from 'react-icons/ti';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../paths';
import ModalAddingForCandidate from '../../Modal/ModalAddingForCandidate';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {getDepartmentPositionsOpenedDeleteItem, getDepartmentPositionsOpenedFilteredResponse, type GetDepartmentPositionsOpenedProcessedResponseItem} from '../../../redux/slice/departmentPositions';
import {TableIconButton} from '../../UiKit/Button';
import {Dropdown, InputDateRange} from '../../UiKit/Inputs';
import {getDepartmentPositionsOpenedAction, postDepartmentPositionsCloseAction} from '../../../redux/action/department_positions';
import {getGroupPermission} from '../../../utils';
import {typePermissions} from '../../../models/Enum';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import {BriefcaseButton} from '../../UiKit/Inputs/Template/InputButtons';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {postDepartmentPositionsCloseResetStatus} from '../../../redux/slice/departmentPositions';
import {stringISODateSorting} from '../../../utils/sorting/dateSorting';
import {type DepartmentPositionsOpened} from '../../../models/IDepartmentPositions';

const JobsTable = () => {
	const [positionName, setPositionName] = useState<string | undefined>(undefined);
	const [urgency, setUrgency] = useState<string | undefined>(undefined);
	const [openedAt, setOpenedAt] = useState<string[]>([]);

	const [visible, setVisible] = useState(false);
	const [jobForAddCandidate, setJobForAddCandidate] = useState<number | undefined>(undefined);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const postChallengers = useAppSelector(state => state.postChallengers);
	const getDepartmentPositionsOpened = useAppSelector(state => state.getDepartmentPositionsOpened);
	const postDepartmentPositionsClose = useAppSelector(state => state.postDepartmentPositionsClose);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const myDepartmentId = useAppSelector(state => state.getUser.user?.department?.id);
	const navigationContext = useAppSelector(state => state.navigationContext);

	// Перейти на страницу вакансии
	const openJobPage = (jobs: DepartmentPositionsOpened) => {
		const IsMyDepartment = jobs.department_id === myDepartmentId;
		const candidateRead = checkPermission(typePermissions.candidate_read, IsMyDepartment);
		if (candidateRead) {
			navigate(paths.JobCandidates.replace(':id', jobs.id.toString()));
		}
	};

	// Отрыть окно добавление кандидата
	const openAddCandidateModal = (id: number) => {
		setJobForAddCandidate(id);
		setVisible(true);
	};

	// Закрытие вакансии
	const closeJob = (positions_id: number) => {
		void dispatch(postDepartmentPositionsCloseAction({positions_id}));
	};

	// Проверка прав
	const checkPermission = (
		permissionType: string,
		isMyDepartment: boolean,
	) => {
		const permission = getGroupPermission(permissionType, getPermissions.groupPermissions);
		if (permission?.permit) {
			if (permission?.permission_zone === 'all') {
				return true;
			}

			if (permission?.permission_zone === 'only_my_department') {
				if (isMyDepartment) {
					return true;
				}

				return false;
			}

			if (permission?.permission_zone === 'only_owner') {
				return true;
			}
		} else {
			return false;
		}
	};

	// Параметры столбцов таблицы
	const columns: ColumnsProps[] = useMemo(() => (
		[
			{
				caption: 'Должность',
				dataField: 'position_name',
			},
			{
				caption: 'Отдел',
				dataField: 'department_name',
				groupIndex: 0,
				groupCellRender(rowData) {
					const items = rowData.row.isExpanded ? rowData.row.data?.items : rowData.row.data?.collapsedItems;
					if (items && items.length > 0) {
						const {department_name, countJobs} = (items[0] as GetDepartmentPositionsOpenedProcessedResponseItem);

						return (
							<div className={styles.department}>
								<span>{department_name}</span>
								<div className={styles.counter}>
									{countJobs}
								</div>
							</div>
						);
					}
				},
			},
			{
				caption: 'Срочность',
				dataField: 'urgency',
				cellRender(rowData) {
					const position = rowData.row.data as GetDepartmentPositionsOpenedProcessedResponseItem;
					return (
						<div className={commonTableStyles['column-text']} data-orange={position.urgency === 'Срочная'}>{position.urgency}</div>
					);
				},
			},
			{
				caption: 'Открыто',
				dataField: 'opened_at',
				sortingMethod: stringISODateSorting,
				cellRender(rowData) {
					const position = rowData.row.data as GetDepartmentPositionsOpenedProcessedResponseItem;
					return position.displayOpenedAt;
				},
			},
			{
				caption: 'Количество кандидатов',
				dataField: 'candidate_count',
			},
			{
				type: 'buttons',
				width: 100,
				cellRender(rowData) {
					const jobs = rowData.row.data as GetDepartmentPositionsOpenedProcessedResponseItem;
					const isMyDepartment = jobs.department_id === myDepartmentId;
					const candidateCreate = checkPermission(typePermissions.candidate_create, isMyDepartment);
					const vacancyClose = checkPermission(typePermissions.vacancy_close, isMyDepartment);
					const candidateRead = checkPermission(typePermissions.candidate_read, isMyDepartment);
					const challengerCreate = checkPermission(typePermissions.challenger_create, isMyDepartment);

					return (
						<div className={commonTableStyles['column-action']}>
							{candidateRead && (
								<TableIconButton
									onClick={() => {
										openJobPage(jobs);
									}}
									Icon={IoEyeOutline}
									TooltipMessage='Просмотреть вакансию'
								/>
							)}
							{candidateCreate && challengerCreate && (
								<TableIconButton
									onClick={() => {
										openAddCandidateModal(jobs.id);
									}}
									Icon={TiUserAddOutline}
									TooltipMessage='Добавить кандидата'
								/>
							)}
							{vacancyClose && (
								<TableIconButton
									onClick={() => {
										closeJob(jobs.id);
									}}
									Icon={IoCloseSharp}
									TooltipMessage='Закрыть вакансию'
								/>
							)}
						</div>
					);
				},
			},
		]
	), [myDepartmentId]);

	// Получение данных при открытии страницы
	useEffect(() => {
		void dispatch(getDepartmentPositionsOpenedAction());
	}, [navigationContext.department]);

	// Получение данных после добавления кандидата
	useEffect(() => {
		if (postChallengers.responseStatus.isCompleted) {
			void dispatch(getDepartmentPositionsOpenedAction());
		}
	}, [postChallengers.responseStatus.isLoading]);

	// Закрытие вакансии
	useEffect(() => {
		if (
			postDepartmentPositionsClose.responseStatus.isCompleted
			&& postDepartmentPositionsClose.response
		) {
			dispatch(
				getDepartmentPositionsOpenedDeleteItem(
					postDepartmentPositionsClose.response.id,
				),
			);
			dispatch(postDepartmentPositionsCloseResetStatus());
			notifyMessage('success', 'Вакансия закрыта');
		} else if (postDepartmentPositionsClose.responseStatus.isError) {
			notifyMessage('error', 'Ошибка. Что-то пошло не так.');
		}
	}, [postDepartmentPositionsClose.responseStatus.isLoading]);

	// Фильтрация данных
	useEffect(() => {
		if (
			getDepartmentPositionsOpened.responseStatus.isCompleted
		) {
			dispatch(
				getDepartmentPositionsOpenedFilteredResponse({
					positionName,
					openedAt,
					urgency,
				}),
			);
		}
	}, [getDepartmentPositionsOpened.responseStatus.isLoading, positionName, openedAt, urgency, navigationContext.department]);

	return (
		<div className={styles['table-container']}>
			<div className={styles.title}>
				<span className='h1-text'>Вакансии</span>
			</div>
			<div className={styles.filters}>
				<Dropdown
					selectedValue={positionName}
					setSelectedValue={setPositionName}
					options={getDepartmentPositionsOpened.filteredOptions.positionNames}
					placeholder={'Должность'}
					emptyMessage={'Не найдено'}
					icons={[BriefcaseButton]}
					showClear
				/>
				<Dropdown
					selectedValue={urgency}
					setSelectedValue={setUrgency}
					options={getDepartmentPositionsOpened.filteredOptions.urgencies}
					placeholder={'Срочность'}
					emptyMessage={'Не найдено'}
					icons={[BriefcaseButton]}
					showClear
				/>
				<div className={styles['filters-wide']}>
					<InputDateRange
						value={openedAt}
						startDatePlaceholder='Дата открытия от'
						endDatePlaceholder='Дата открытия до'
						labelMode='hidden'
						setValue={setOpenedAt}
					/>
				</div>
			</div>
			<div className={styles.table}>
				<Table
					columns={columns}
					dataGridProps={{
						dataSource: getDepartmentPositionsOpened.filteredResponse,
						allowColumnResizing: true,
					}}
					isLoading={getDepartmentPositionsOpened.responseStatus.isLoading}
				/>
			</div>
			<ModalAddingForCandidate
				visible={visible}
				setVisible={setVisible}
				positionId={jobForAddCandidate}
			/>
		</div>
	);
};

export default JobsTable;
