import React, {useEffect, useState} from 'react';
import ModalWindow from '../ModalWindow';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import styles from './ModalConfirmation.module.css';
import {ErrorMessage} from '../../UiKit/Message';
import {Input} from '../../UiKit/Inputs';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';

type ModalConfirmationProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	title?: string;
	text?: string;
	confirmation: () => void;
	confirmationText: string;
	cancel?: () => void;
	cancelText: string;
	errorMessage?: string;
	isError?: boolean;
	isLoading?: boolean;
	loadingMessage?: string;
	children?: React.ReactNode;
	ConfirmationByInput?: string;
	width?: string;
	closeOnOutsideClick?: boolean;
};

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
	visible,
	setVisible,
	title,
	text,
	confirmation,
	confirmationText,
	cancel,
	cancelText,
	errorMessage,
	isError,
	isLoading,
	loadingMessage,
	children,
	ConfirmationByInput,
	width,
	closeOnOutsideClick,
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [value, setValue] = useState('');
	const [isValid, setIsValid] = useState(true);

	const closeModal = () => {
		setVisible(false);
	};

	const CheckInputAndConfirmation = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (value === ConfirmationByInput) {
			setIsValid(true);
			confirmation();
		} else {
			setIsValid(false);
			notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
		}
	};

	useEffect(() => {
		setShowValidation(false);
		setValue('');
		setIsValid(false);
	}, [visible]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			closable={false}
			width={width}
			header={title && <span className={styles.title}>{title}</span>}
			footerItems={[
				<ButtonCancel
					key='ButtonCancel'
					text={cancelText}
					onclickHandler={cancel ?? closeModal}
				/>,
				<ButtonConfirm
					key='ButtonConfirm'
					text={confirmationText}
					onclickHandler={
						ConfirmationByInput ? CheckInputAndConfirmation : confirmation
					}
				/>,
			]}
			isLoading={isLoading}
			loadingMessage={loadingMessage}
			closeOnOutsideClick={closeOnOutsideClick}
		>
			<div className={styles.container}>
				{text && <span className={styles.text}>{text}</span>}
				{ConfirmationByInput && (
					<div className={styles['confirmation-container']}>
						<p className={styles.text}>
							Введите <span className={styles['highlighted-text']}>
								{ConfirmationByInput}
							</span> для подтверждения
						</p>
						<Input
							value={value}
							setValue={setValue}
							inputStyles={{
								placeholder: 'Текст для подтверждения',
							}}
							inputValidation={{
								isValid,
								setIsValid,
								showValidation,
								isRequired: true,
								customPatternRule: [{
									pattern: ConfirmationByInput,
									message: 'Текст не совпадает с текстом для подтверждения',
								}],
								visible,
							}}
						/>
					</div>
				)}
				{children}
				<ErrorMessage
					message={errorMessage ?? 'Ошибка'}
					isError={isError ?? false}
				/>
			</div>
		</ModalWindow>
	);
};

export default ModalConfirmation;
