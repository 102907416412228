import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getAddressFill} from '../../../api/dadata';
import {type GetAddressFillResponse, type GetAddressFillRequest} from '../../../models/Dadata/Address/IDadataAddress';

const getAddressFillAction = createActionTemplateAxios<
GetAddressFillResponse,
GetAddressFillRequest
>('dadata/getAddressFill/action', getAddressFill, 'userOrGuestToken');

export default getAddressFillAction;
