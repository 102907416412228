import React, {useEffect, useRef, useState} from 'react';
import styles from './PersonalDataAgreementLinks.module.css';
import {FaCheckCircle} from 'react-icons/fa';
import {useEffectHandlerLoadingCurrentRequest} from '../../../../utils/hooks';
import {getPersonalizedPdAgreementAction} from '../../../../redux/action/person';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {notifyMessage} from '../../../UiKit/Toast/notifyMessage';
import {downloadDoc} from '../../../../utils/downloadFile';
import publicAssetsLinks from '../../../../links';
import Loader from '../../../Loader/Loader';

type PersonalDataAgreementSignDocumentsProps = {
	putGuestRequest: () => string | undefined;
};

const PersonalDataAgreementSignDocuments: React.FC<PersonalDataAgreementSignDocumentsProps> = ({
	putGuestRequest,
}) => {
	const dispatch = useAppDispatch();
	const putGuest = useAppSelector(state => state.putGuest);
	const getGuest = useAppSelector(state => state.getGuest);
	const getPersonalizedPdAgreement = useAppSelector(state => state.getPersonalizedPdAgreement);
	const getPersonalizedPdAgreementRequestId = useRef<string>();
	const getPersonalizedPdAgreementSocratRequestId = useRef<string>();
	const putGuestRequestId = useRef<string>();
	const requestType = useRef<'socrat' | 'company'>();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();

	const resetComponentStates = () => {
		getPersonalizedPdAgreementRequestId.current = undefined;
		getPersonalizedPdAgreementSocratRequestId.current = undefined;
		putGuestRequestId.current = undefined;
		requestType.current = undefined;
		hiddenLoading();
	};

	useEffect(() => {
		resetComponentStates();
	}, []);

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	const getDoc = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		requestType.current = 'company';
		putGuestRequestId.current = putGuestRequest();
	};

	const getDocSocrat = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		requestType.current = 'socrat';
		putGuestRequestId.current = putGuestRequest();
	};

	// Обработка получения персонализированного согласия на обработку ПД
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getPersonalizedPdAgreementSocratRequestId,
		state: getPersonalizedPdAgreement,
		onLoading() {
			showLoading('Формирование документа...');
		},
		onStopLoading() {
			hiddenLoading();
		},
		onComplete(response) {
			downloadDoc(response, 'Согласие на обработку ПД');
			notifyMessage('success', 'Документ загружен');
		},
		onError(errorMessage) {
			notifyMessage('error', errorMessage);
		},
	});

	// Обработка получения персонализированного согласия на обработку ПД для Сократ
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getPersonalizedPdAgreementRequestId,
		state: getPersonalizedPdAgreement,
		onLoading() {
			showLoading('Формирование документа...');
		},
		onStopLoading() {
			hiddenLoading();
		},
		onComplete(response) {
			downloadDoc(response, 'Согласие на обработку ПД для Сократ');
			notifyMessage('success', 'Документ загружен');
		},
		onError(errorMessage) {
			notifyMessage('error', errorMessage);
		},
	});

	// Обновление гостя
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: putGuestRequestId,
		state: putGuest,
		onComplete() {
			if (requestType.current === 'company') {
				getPersonalizedPdAgreementSocratRequestId.current = dispatch(getPersonalizedPdAgreementAction({})).requestId;
			} else if (requestType.current === 'socrat') {
				getPersonalizedPdAgreementRequestId.current = dispatch(getPersonalizedPdAgreementAction({
					params: {
						socrat: true,
					},
				})).requestId;
			}
		},
	});

	return (
		<div className={styles.docs}>
			<p>Документы:</p>
			<div className={styles['docs-links-list']}>
				<FaCheckCircle className={styles.icon} />
				<a href='#' onClick={getDoc} className={styles.href}>
					{`Согласие на обработку персональных данных ${getGuest.response?.organization_unit_name ?? ''}`}
				</a>
				<FaCheckCircle className={styles.icon} />
				<a href='#' onClick={getDocSocrat} className={styles.href}>
					{'Согласие на обработку персональных данных ООО "Сократ"'}
				</a>
				<FaCheckCircle className={styles.icon} />
				<a href={publicAssetsLinks.privacyPolicy} className={styles.href}>
					Политика конфиденциальности
				</a>
			</div>
			<Loader isFloating isLoading={isLoading} text={loadingMessage} />
		</div>
	);
};

export default PersonalDataAgreementSignDocuments;
