import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {
	type GetUserAuthHistoryRequest,
	type GetUserAuthHistoryResponse,
} from '../../../models/IUsers';
import {getUserAuthHistory} from '../../../api/usersApi';

const getUserAuthHistoryAction = createActionTemplateAxios<
GetUserAuthHistoryResponse,
GetUserAuthHistoryRequest
>('user/getUserAuthHistory/action', getUserAuthHistory, 'userToken');

export default getUserAuthHistoryAction;
