import {type Action, createAsyncThunk} from '@reduxjs/toolkit';
import {type ResponseError} from '../../models/IAuth';
import {type AxiosResponse, type AxiosError} from 'axios';
import {refreshTokenAction} from './auth';
import checkToken from '../../utils/cookies/token/checkToken';
import {type RootState} from '../store';
import {removeToken} from '../../utils/cookies/token/removeToken';
import {paths} from '../../paths';

type UseToken = 'no' | 'userToken' | 'guestToken' | 'userOrGuestToken';

const checkErrorStatus = (error: ResponseError, useToken: UseToken) => {
	if (error.status === 401) {
		if (useToken === 'userToken') {
			removeToken();
			window.location.href = paths.auth;
		}
	}
};

export function createActionTemplateAxios<ResponseType, RequestType>(
	actionName: string,
	requestFunction: (data: RequestType) => Promise<AxiosResponse<ResponseType>>,
	useToken: UseToken,
	action?: (data: ResponseType) => Action<any>,
) {
	return createAsyncThunk<
	ResponseType,
	RequestType,
	{rejectValue: ResponseError}
	>(
		actionName,
		async (data, thunkAPI) => {
			try {
				if (useToken === 'userToken' && !checkToken()) {
					if ((thunkAPI.getState() as RootState).refreshToken.isLoading) {
						const tokenLoaded = new Promise<void>(resolve => {
							const checkTokenLoaded = () => {
								if ((thunkAPI.getState() as RootState).refreshToken.isLoading) {
									setTimeout(checkTokenLoaded, 100);
								} else {
									resolve();
								}
							};

							checkTokenLoaded();
						});

						await tokenLoaded.finally();
					} else {
						await thunkAPI.dispatch(refreshTokenAction()).finally();
					}
				}

				const response = await requestFunction(data);
				if (action) {
					thunkAPI.dispatch(action(response.data));
				}

				return response.data;
			} catch (error) {
				const e = error as AxiosError<{
					message: string;
					errors?: Record<string, string>;
				}>;

				const errorStatus: ResponseError = {
					message: e.response?.data?.message ?? e.message ?? 'Неожиданная ошибка',
					status: e.response?.status ?? e.status,
					errors: e.response?.data?.errors,
				};

				checkErrorStatus(errorStatus, useToken);

				return thunkAPI.rejectWithValue(errorStatus);
			}
		},
	);
}
