/* eslint-disable @typescript-eslint/ban-types */
type formatFullName = 'LFM' | 'LF' | 'LsFsM';
export const getFullName = (
	first: string | undefined | null,
	middle: string | undefined | null,
	last: string | undefined | null,
	format?: formatFullName,
) => {
	if (first ?? middle ?? last) {
		if (format === 'LFM') {
			return `${last} ${first}${middle ? ' ' + middle : ''}`;
		}

		if (format === 'LF') {
			return `${last} ${first}`;
		}

		if (format === 'LsFsM') {
			const initialMiddle = middle ? `${middle[0]}.` : '';
			const initialFirst = first ? `${first[0]}.` : '';
			return `${last} ${initialFirst[0]}. ${initialMiddle}`;
		}

		return `${first}${middle ? ' ' + middle : ''} ${last}`;
	}

	return '';
};

export const getFullNameFromObject = (
	person?: {
		first_name?: string | null;
		middle_name?: string | null;
		last_name?: string | null;
	} | null,
	format?: formatFullName,
) => getFullName(person?.first_name, person?.middle_name, person?.last_name, format);
