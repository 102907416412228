import React, {useEffect} from 'react';
import {useState} from 'react';
import {Login, OtpCode} from '../../components';
import styles from './Auth.module.css';
import logo from '../../assets/login/LogoAuth.png';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {useNavigate} from 'react-router-dom';
import {getUserAction} from '../../redux/action/users';
import Loader from '../../components/Loader/Loader';
import {getToken} from '../../utils/cookies/token/getToken';
import checkToken from '../../utils/cookies/token/checkToken';
import {paths} from '../../paths';

export const Auth: React.FC = () => {
	const [component, setComponent] = useState(!(localStorage.getItem('component') && localStorage.getItem('email')));
	const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
	const {isCompleted, user, isLoading} = useAppSelector(state => state.getUser);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		if (isCompleted && user) {
			navigate(paths.home);
		} else {
			const {token, token_expires, refresh_token, refresh_token_expires} = getToken();
			if ((token && token_expires) || (refresh_token && refresh_token_expires)) {
				if (checkToken !== undefined) {
					void dispatch(getUserAction());
				}
			}
		}
	}, []);

	useEffect(() => {
		if (isCompleted && user) {
			navigate(paths.home);
		}
	}, [isLoading]);

	return (
		<div className={styles.container}>
			<img className={styles['info-company']} src={logo} alt='' />
			{component
				? <Login setComponent={setComponent} email={email} setEmail={setEmail} />
				: <OtpCode setComponent={setComponent} email={email} />
			}
			<Loader text='Проверка авторизации...' isLoading={isLoading} isFloating/>
		</div>
	);
};
