import React from 'react';
import styles from './PersonCard.module.css';
import {type PersonV3} from '../../../../../../report/reportModelV3/reportModelV3';
import {getFullNameFromObject} from '../../../../../../utils';
import {UserIcon} from '../../../../Div';
import {HiOutlineUser} from 'react-icons/hi';
import {IoCalendarOutline} from 'react-icons/io5';
import {getAgeString} from '../../../../../../utils/getAgeString';
import {calculateAge} from '../../../../../../utils/calculateAge';
import {RiWallet3Line} from 'react-icons/ri';
import {IoMdCard} from 'react-icons/io';

type PersonCardProps = {
	person: PersonV3;
};

const PersonCard: React.FC<PersonCardProps> = ({person}) => {
	const fullName = getFullNameFromObject(person.name, 'LFM');

	return (
		<div className={styles.container}>
			<div className={styles.icon}>
				<UserIcon label={person.name.first_name[0]} size='large' />
			</div>
			<div className={styles.content}>
				{fullName && (
					<>
						<div className={styles['field-title']}>
							<HiOutlineUser />
							<span className={styles.abbreviation}>
                                    ФИО:
							</span>
						</div>
						<span className={styles['field-data']}>
							{fullName}
						</span>
					</>
				)}
				{person?.birth_date && (
					<>
						<div className={styles['field-title']}>
							<IoCalendarOutline />
							<span className={styles.abbreviation}>
                                    Дата рождения:
							</span>
						</div>
						<span className={styles['field-data']}>
							{person.birth_date} {getAgeString(calculateAge(person.birth_date))}
						</span>
					</>
				)}
				{person?.inn && (
					<>
						<div className={styles['field-title']}>
							<RiWallet3Line />
							<span className={styles.abbreviation}>
                                    ИНН:
							</span>
						</div>
						<span className={styles['field-data']}>
							{person.inn}
						</span>
					</>
				)}
				{person?.snils && (
					<>
						<div className={styles['field-title']}>
							<IoMdCard />
							<span className={styles.abbreviation}>
                                    СНИЛС:
							</span>
						</div>
						<span className={styles['field-data']}>
							{person.snils}
						</span>
					</>
				)}
			</div>
		</div>
	);
};

export default PersonCard;
