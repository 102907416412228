import type React from 'react';
import DataProfile from './DataProfile';
import LoginHistory from './LoginHistory';

export type ComponentsProfile = {
	component: React.ComponentType;
	name: string;
};

export const components: ComponentsProfile[] = [
	{component: DataProfile, name: 'Данные'},
	{component: LoginHistory, name: 'История входа'},
];
