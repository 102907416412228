import React, {useEffect, useState} from 'react';
import {InputAddress, InputDate, InputName} from '../../../UiKit/Inputs';
import BlockMenu, {type BlockMenuItem} from '../../../UiKit/Dropdown/BlockMenu/BlockMenu';
import styles from './CreateCheckIndividualFirstForm.module.css';
import {
	type InteractiveSwitch,
	type InteractiveFieldValues,
	type InteractiveScreen,
} from '../../../../models/IInteractive';
import {SelectorGender} from '../../../UiKit/Selectors/SelectorGender/SelectorGender';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {useEffectHandlerLoadingAllRequest} from '../../../../utils/hooks';

type CreateCheckIndividualFirstFormProps = {
	fields: React.MutableRefObject<InteractiveFieldValues | undefined>;
	currentScreen: React.MutableRefObject<InteractiveScreen | undefined>;
	currentSwitch?: React.MutableRefObject<InteractiveSwitch | undefined>;
	showValidation: boolean;
	errors?: Record<string, string>;
};

type Country = BlockMenuItem;

const CreateCheckIndividualFirstForm: React.FC<CreateCheckIndividualFirstFormProps> = ({
	fields,
	currentScreen,
	currentSwitch,
	showValidation,
	errors,
}) => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [dateBirth, setDateBirth] = useState<string | undefined>(undefined);
	const [placeOfBirth, setPlaceOfBirth] = useState('');
	const [registrationAddress, setRegistrationAddress] = useState('');

	const getGuest = useAppSelector(state => state.getGuest);

	const [gender, setGender] = useState<string | undefined>();

	const [countries, setCountries] = useState<Country[]>([]);
	const [country, setCountry] = useState<Country>();

	const [isValidFirstName, setIsValidFirstName] = useState(false);
	const [isValidLastName, setIsValidLastName] = useState(false);
	const [isValidMiddleName, setIsValidMiddleName] = useState(false);
	const [isValidDateBirth, setIsValidDateBirth] = useState(false);
	const [isValidPlaceOfBirth, setIsValidPlaceOfBirth] = useState(false);
	const [isValidRegistrationAddress, setIsValidRegistrationAddress] = useState(false);

	// Изменение данных экранна
	useEffect(() => {
		if (currentScreen.current) {
			const fieldFirstName = currentScreen.current.default_values.first_name;
			const fieldLastName = currentScreen.current.default_values.last_name;
			const fieldMiddleName = currentScreen.current.default_values.middle_name;
			const fieldCountry = currentScreen.current.default_values.country;
			const listOfCountries = currentScreen.current.data.countries;
			const fieldDateBirth = currentScreen.current.default_values.birth_date;
			const fieldPlaceOfBirth = currentScreen.current.default_values.place_of_birth;
			const fieldRegistrationAddress = currentScreen.current.default_values.registration_address;
			const fieldGender = currentScreen.current.default_values.gender;

			if (typeof fieldFirstName === 'string') {
				setFirstName(fieldFirstName);
			} else {
				setFirstName('');
			}

			if (typeof fieldLastName === 'string') {
				setLastName(fieldLastName);
			} else {
				setLastName('');
			}

			if (typeof fieldMiddleName === 'string') {
				setMiddleName(fieldMiddleName);
			} else {
				setMiddleName('');
			}

			if (typeof fieldDateBirth === 'string') {
				setDateBirth(fieldDateBirth);
			} else {
				setDateBirth(undefined);
			}

			if (typeof fieldPlaceOfBirth === 'string') {
				setPlaceOfBirth(fieldPlaceOfBirth);
			} else {
				setPlaceOfBirth('');
			}

			if (typeof fieldRegistrationAddress === 'string') {
				setRegistrationAddress(fieldRegistrationAddress);
			} else {
				setRegistrationAddress('');
			}

			if (typeof fieldGender === 'string') {
				setGender(fieldGender);
			} else {
				setGender('');
			}

			if (listOfCountries) {
				const items: Country[] = [];
				for (const key in listOfCountries) {
					if (Object.hasOwn(listOfCountries, key)) {
						items.push({
							key,
							value: listOfCountries[key],
						});
					}
				}

				setCountries(items);

				if (typeof fieldCountry === 'string') {
					setCountry({
						key: fieldCountry,
						value: listOfCountries[fieldCountry],
					});
				}
			}
		} else {
			setCountries([]);
			setCountry(undefined);
		}
	}, [currentScreen.current]);

	// Обновление значений
	useEffect(() => {
		if (fields.current) {
			fields.current.first_name = firstName;
			fields.current.last_name = lastName;
			fields.current.middle_name = middleName;
			fields.current.country = country?.key;
			fields.current.birth_date = dateBirth;
			fields.current.place_of_birth = placeOfBirth;
			fields.current.registration_address = registrationAddress;
			fields.current.gender = gender;
		}
	}, [
		firstName,
		lastName,
		middleName,
		country,
		dateBirth,
		placeOfBirth,
		registrationAddress,
		gender,
	]);

	useEffectHandlerLoadingAllRequest({
		state: getGuest,
		onComplete(response) {
			setFirstName(response?.first_name ?? '');
			setLastName(response?.last_name ?? '');
			setMiddleName(response?.middle_name ?? '');
		},
	});

	return (
		<div className={styles.container}>
			<div className={styles.group}>
				<div className={styles.inputs}>
					<InputName
						value={lastName}
						setValue={setLastName}
						title='Фамилия'
						inputStyles={{
							placeholder: 'Введите фамилию',
						}}
						inputValidation={{
							isValid: isValidLastName,
							setIsValid: setIsValidLastName,
							lettersOnly: true,
							isRequired: true,
							minLength: 2,
							showValidation,
						}}
						parts={['SURNAME']}
						disabled
					/>
					<InputName
						value={firstName}
						title='Имя'
						setValue={setFirstName}
						inputStyles={{
							placeholder: 'Введите имя',
						}}
						inputValidation={{
							isValid: isValidFirstName,
							setIsValid: setIsValidFirstName,
							lettersOnly: true,
							isRequired: true,
							minLength: 2,
							showValidation,
						}}
						parts={['NAME']}
						disabled
					/>
					<InputName
						value={middleName}
						setValue={setMiddleName}
						title='Отчество'
						inputStyles={{
							placeholder: 'Введите отчество',
						}}
						inputValidation={{
							isValid: isValidMiddleName,
							setIsValid: setIsValidMiddleName,
							lettersOnly: true,
							minLength: 2,
							showValidation,
						}}
						parts={['PATRONYMIC']}
						disabled
					/>

					<InputDate
						value={dateBirth}
						setValueString={setDateBirth}
						title='Дата рождения'
						inputValidation={{
							isRequired: true,
							isValid: isValidDateBirth,
							setIsValid: setIsValidDateBirth,
							showValidation,
						}}
						inputStyles={{
							placeholder: 'Введите дату рождения',
						}}
					/>

					<InputAddress
						value={placeOfBirth}
						setValue={setPlaceOfBirth}
						title='Место рождения'
						inputStyles={{
							placeholder: 'Введите место рождения',
						}}
						inputValidation={{
							isValid: isValidPlaceOfBirth,
							setIsValid: setIsValidPlaceOfBirth,
							minLength: 2,
							showValidation,
							isRequired: true,
						}}
					/>

					<InputAddress
						value={registrationAddress}
						setValue={setRegistrationAddress}
						title='Адрес регистрации'
						inputStyles={{
							placeholder: 'Введите адрес регистрации',
						}}
						inputValidation={{
							isValid: isValidRegistrationAddress,
							setIsValid: setIsValidRegistrationAddress,
							minLength: 2,
							showValidation,
							isRequired: true,
						}}
					/>
					<SelectorGender
						setGender={setGender}
						title='Пол'
						isRequired
					/>
				</div>
			</div>
			{countries && (
				<div className={styles.group}>
					<p>Гражданство</p>
					<div>
						<BlockMenu
							items={countries}
							selectedItem={country}
							setSelectedItem={setCountry}
							countVisibleItems={3}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default CreateCheckIndividualFirstForm;
