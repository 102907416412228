import {createActionTemplateAxios} from '../ActionTemplatesRequest';

import {type postNotificationsRequest, type postNotificationsResponse} from '../../../models/INotifications';
import {postNotifications} from '../../../api/notificationsApi';

const postNotificationsAction = createActionTemplateAxios<postNotificationsResponse, postNotificationsRequest>(
	'notifications/postNotifications',
	postNotifications,
	'userToken',
);

export default postNotificationsAction;
