import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetCheckParametersByIdResponse} from '../../../models/ICheck';
import {getCheckParametersByIdAction} from '../../action/checks';

type GetCheckParametersState = {
	responseStatus: ResponseStatus;
	response: GetCheckParametersByIdResponse | undefined;
	processedResponse: GetCheckParametersByIdResponse | undefined;
};

const initialState: GetCheckParametersState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	processedResponse: undefined,
};

const getProcessedResponse = (response: GetCheckParametersByIdResponse) => {
	let {filled_fields} = response;
	filled_fields.sort((a, b) => a.order > b.order ? 1 : -1);
	filled_fields = filled_fields.map(item => (
		{
			...item,
			data: item.data ?? '',
		}
	));
	return {
		...response,
		filled_fields,
	};
};

const getCheckParametersByIdSlice = createSlice({
	name: 'checks/getCheckParametersById',
	initialState,
	reducers: {
		getCheckParametersByIdSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getCheckParametersByIdAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckParametersByIdAction.fulfilled, (state, action) => {
				state.response = action.payload;
				state.processedResponse = getProcessedResponse(action.payload);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckParametersByIdAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {getCheckParametersByIdSliceResetState} = getCheckParametersByIdSlice.actions;
export default getCheckParametersByIdSlice.reducer;
