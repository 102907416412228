import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type Token} from '../../../models/IAuth';
import refreshTokenAction from '../../action/auth/refreshTokenAction';
import {saveToken} from '../../../utils/cookies/token/saveToken';
import {removeToken} from '../../../utils/cookies/token/removeToken';

const initialState: ResponseStatus & {token: Token | undefined} = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	token: undefined,
};

const refreshTokenSlice = createSlice({
	name: 'auth/refreshToken',
	initialState,
	reducers: {
		refreshTokenResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(refreshTokenAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.token = undefined;
			})
			.addCase(refreshTokenAction.fulfilled, (state, action) => {
				saveToken(action.payload);
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.token = action.payload;
			})
			.addCase(refreshTokenAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.status = action.payload?.status;
				state.token = undefined;
				removeToken();
			});
	},
});
export const {refreshTokenResetState} = refreshTokenSlice.actions;
export default refreshTokenSlice.reducer;
