import {postContact} from '../../../api/contactsApi';
import {type PostContactResponse, type PostContactRequest} from '../../../models/IContacts';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postContactAction = createActionTemplateAxios<
PostContactResponse,
PostContactRequest>(
	'contacts/postContact/action',
	postContact,
	'userToken',
);

export default postContactAction;
