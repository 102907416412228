
import {postCheckRestart} from '../../../api/checkApi';
import {type PostCheckRestartRequest, type PostCheckRestartResponse} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckRestartAction = createActionTemplateAxios<
PostCheckRestartResponse,
PostCheckRestartRequest>(
	'checks/postCheckRestart/action',
	postCheckRestart,
	'userToken',
);

export default postCheckRestartAction;
