import React, {useEffect, useRef, useState} from 'react';
import styles from './PhoneNumberRegistration.module.css';
import logo from '../../assets/login/LogoLogin.png';
import arrow from '../../assets/login/arrowLeft.svg';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {type SmsRegistrationRequest} from '../../models/IUsers';
import {sendSmsRegistrationAction} from '../../redux/action/users';
import {smsRegistrationIsCompleteReset} from '../../redux/slice/users/smsRegistrationSlice';
import {useCookies} from 'react-cookie';
import {InputPhone} from '../UiKit/Inputs';
import {ErrorMessage} from '../UiKit/Message';
import {ButtonTemplate} from '../UiKit/Button';
import {type KeyDownEvent} from 'devextreme/ui/text_box';

type PhoneNumberRegistrationProps = {
	nextComponent: () => void;
	previousComponent: () => void;
	phoneNumber: string;
	setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
};

export const PhoneNumberRegistration: React.FC<
PhoneNumberRegistrationProps
> = ({previousComponent, nextComponent, phoneNumber, setPhoneNumber}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [invalidPhoneNumberError, setInvalidPhoneNumberError] = useState('');
	const [cookies] = useCookies(['debug']);
	const [isValid, setIsValid] = useState(false);

	const dispatch = useAppDispatch();

	const {isError, isLoading, isCompleted, errorMessage} = useAppSelector(state => state.smsRegistration);

	const {isStop, isContinuation} = useAppSelector(state => state.timer);

	const handleKeyDown = (e: KeyDownEvent) => {
		// Обработка ввода
		if (e.event?.key === 'Enter') {
			handleSendingNumber();
		}
	};

	const handleSendingNumber = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isValid) {
			if (
				phoneNumber !== localStorage.getItem('lastPhone')
				|| (!isContinuation && isStop)
			) {
				localStorage.setItem('lastPhone', phoneNumber);

				setInvalidPhoneNumberError('');
				const requestOptions: SmsRegistrationRequest = {
					phone: phoneNumber,
					debug: Boolean(cookies.debug),
				};
				void dispatch(sendSmsRegistrationAction(requestOptions));
			} else {
				nextComponent();
			}
		}
	};

	const handleBack = () => {
		dispatch(smsRegistrationIsCompleteReset());
		previousComponent();
	};

	useEffect(() => {
		if (isCompleted && !isError) {
			dispatch(smsRegistrationIsCompleteReset());
			nextComponent();
		}
	}, [isLoading]);

	useEffect(() => {
		if (showValidation) {
			setShowValidation(false);
		}
	}, []);

	return (
		<div className={styles.card}>
			<div className={styles.logo} onClick={handleBack}>
				<img src={arrow} alt='' />
				<img src={logo} alt='' />
			</div>

			<div className={styles['phone-group']}>
				<h2>Введите номер телефона</h2>
				<InputPhone
					value={phoneNumber}
					setValue={setPhoneNumber}
					isValid={isValid}
					isRequired
					setIsValid={setIsValid}
					showValidation={showValidation}
					focusOnInput
					inputStyles={{
						placeholder: 'Номер',
					}}
					onKeyDown={handleKeyDown}
				/>

				<ButtonTemplate
					text=' Подтвердить телефон'
					onClick={handleSendingNumber}
					styleType={'long'}
					color={'primary'}
					disable={!isValid}
				/>
				<ErrorMessage isError={Boolean(invalidPhoneNumberError)} message={invalidPhoneNumberError} />
				<ErrorMessage isError={isError} message={errorMessage} />
			</div>
		</div>
	);
};
