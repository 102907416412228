import {Cookies} from 'react-cookie';
import {type NavigationHash} from '../../../models/Navigation/Navigation';
import {cookiesKeys} from '..';

const getNavigationCookies = (): NavigationHash => {
	const cookies = new Cookies();

	return {
		navigation_company_hash: cookies.get(cookiesKeys.navCompanyHash) as string | undefined,
		navigation_department_hash: cookies.get(cookiesKeys.navDepartmentHash) as string | undefined,
	};
};

export default getNavigationCookies;
