import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type GeTokenPerson152fzRequest, type GetTokenPerson152fzResponse} from '../../../models/Guest/GuestToken/IPerson152fzToken';
import {getTokenPerson152fz} from '../../../api/Person/person152fzApi';

const getTokenPerson152fzAction = createActionTemplateAxios<
GetTokenPerson152fzResponse,
GeTokenPerson152fzRequest
>(
	'person/personToken/action',
	getTokenPerson152fz,
	'no',
);

export default getTokenPerson152fzAction;
