import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {
	getDepartmentPositionsCandidatesAction,
} from '../../../redux/action/department_positions';
import {
	ArrowButton,
	GreenBorderButton,
} from '../../../components/UiKit/Button';
import {useNavigate, useParams} from 'react-router-dom';
import {paths} from '../../../paths';
import JobCandidateTable from '../../../components/Tables/JobCandidateTable/JobCandidateTable';
import {getPositionAction} from '../../../redux/action/department';
import styles from './JobCandidates.module.css';
import Loader from '../../../components/Loader/Loader';
import ModalAddingForCandidate from '../../../components/Modal/ModalAddingForCandidate';
import {DateTime} from 'luxon';
import {getGroupPermission} from '../../../utils';
import {typePermissions} from '../../../models/Enum';

export const JobsCandidates = () => {
	const [positionId, setPositionId] = useState<number | undefined>(undefined);
	const [isCreateCandidate, setIsCreateCandidate] = useState(false);
	const getPositionsCandidates = useAppSelector(
		state => state.getDepartmentPositionsCandidates,
	);
	const getPosition = useAppSelector(state => state.getPosition);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const myDepartmentID = useAppSelector(state => state.getUser.user?.department?.id);
	const [visible, setVisible] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {id} = useParams();

	const back = () => {
		navigate(paths.jobs);
	};

	const addCandidate = () => {
		setVisible(true);
	};

	const positionDiv = () => {
		if (getPosition.responseStatus.isLoading) {
			return (
				<div className={styles['loader-container']}>
					<Loader text='Получение должности...' isLoading/>
				</div>
			);
		}

		return (
			<div className={styles['container-job']}>
				<div className={styles['container-job-header']}></div>
				<div className={styles['container-job-content']}>
					<div>
						<label>Отдел</label>
						<span>{getPosition.response?.department_name}</span>
					</div>
					<div>
						<label>Должность</label>
						<span>{getPosition.response?.position_name}</span>
					</div>
					<div>
						<label>Описание</label>
						<span>{getPosition.response?.position_description}</span>
					</div>
					<div>
						<label>Открыта</label>
						<span>
							{getPosition.response?.opened_at
								? DateTime.fromISO(getPosition.response.opened_at).toRelative()
								: ''}
						</span>
					</div>
					<div>
						<label>Срочность</label>
						<span className={styles['text-highlighted']}>
							{getPosition.response?.urgency}
						</span>
					</div>
					<div>
						<label>Статус</label>
						<span className={styles['text-highlighted']}>
							{getPosition.response?.opened_at ? 'Активна' : 'Закрыта'}
						</span>
					</div>
				</div>
			</div>
		);
	};

	// Проверка прав
	const checkPermission = (
		permissionType: string,
		isMyDepartment: boolean,
	) => {
		const permission = getGroupPermission(permissionType, getPermissions.groupPermissions);
		if (permission?.permit) {
			if (permission?.permission_zone === 'all') {
				return true;
			}

			if (permission?.permission_zone === 'only_my_department') {
				if (isMyDepartment) {
					return true;
				}

				return false;
			}

			if (permission?.permission_zone === 'only_owner') {
				// ИЗМЕНИТЬ
				if (isMyDepartment) {
					return true;
				}

				return false;
			}

			return false;
		}

		return false;
	};

	useEffect(() => {
		if (id) {
			setPositionId(parseInt(id, 10));
		}
	}, []);

	useEffect(() => {
		if (positionId) {
			void dispatch(
				getDepartmentPositionsCandidatesAction({
					positionId,
				}),
			);
			void dispatch(
				getPositionAction({
					position_id: positionId,
				}),
			);
		}
	}, [positionId]);

	useEffect(() => {
		if (getPosition.responseStatus.isCompleted && getPermissions.responseStatus.isCompleted) {
			const isMyDepartment = myDepartmentID === getPosition.response?.department_id;
			setIsCreateCandidate(checkPermission(typePermissions.candidate_create, isMyDepartment));
		} else if (getPosition.responseStatus.isError || getPermissions.responseStatus.isError) {
			setIsCreateCandidate(false);
		}
	}, [
		getPosition.responseStatus.isLoading,
		getPermissions.responseStatus.isLoading,
	]);

	useEffect(() => {
		if (!visible && positionId) {
			void dispatch(
				getDepartmentPositionsCandidatesAction({
					positionId,
				}),
			);
		}
	}, [visible]);

	if (getPosition.responseStatus.isError) {
		return (
			<div>
				<span>{getPosition.responseStatus.errorMessage}</span>
			</div>
		);
	}

	return (
		<>
			<div className={styles.container}>
				<div>
					<ArrowButton
						onclickHandler={back}
						text={getPosition.response?.position_name ?? ''}
						textStyle='h1-text'
					/>
				</div>
				{positionDiv()}
				<div className={styles['container-table-header']}>
					<span className='h1-text'>Кандидаты вакансии</span>
					{isCreateCandidate && <GreenBorderButton
						onClick={addCandidate}
						text={'Добавить кандидата'}
						styleType='medium'
					/>}
				</div>
				<div className={styles['container-table']}>
					<JobCandidateTable />
				</div>
			</div>
			<ModalAddingForCandidate
				visible={visible}
				setVisible={setVisible}
				positionId={getPosition.response?.id}
			/>
		</>
	);
};
