import {MdAccountCircle} from 'react-icons/md';
import {IoNotificationsCircleOutline} from 'react-icons/io5';
import {paths} from '../../../paths';
import {Home} from '../../../pages/Home/index';
import {type NavigationItem} from '../NavigationData';
import {Notifications} from '../../../pages/Notifications/Notifications';
import Departments from '../../../pages/Departments';
import {IoMdAnalytics} from 'react-icons/io';

export const headerNavigationData: NavigationItem[] = [
	{
		id: 0,
		icon: MdAccountCircle,
		text: 'Мой кабинет',
		path: paths.home,
		component: Departments,
		tooltipMessage: 'Кабинет',
	},
	{
		id: 1,
		icon: IoMdAnalytics,
		text: 'Аналитика',
		path: paths.analytics,
		component: Home,
		tooltipMessage: 'Аналитика',
	},
	{
		id: 2,
		icon: IoNotificationsCircleOutline,
		text: 'Уведомления',
		path: paths.notifications,
		component: Notifications,
		tooltipMessage: 'Уведомления',
	},
];
