import React from 'react';
import styles from './Workplaces.module.css';
import {type CheckWorkplaces} from '../../../../report/ReportModel';
import {BsPersonWorkspace} from 'react-icons/bs';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {headersWorkplaces} from './HeadersWorkplaces';
import ReportBlockTemplate from '../ReportBlockTemplate';

type WorkplacesProps = {
	workplaces?: CheckWorkplaces[] | undefined;
};

export const Workplaces: React.FC<WorkplacesProps> = () => {
	const workplaces = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.workplaces);

	return (
		<ReportBlockTemplate
			blocksId='workplaces'
			blockIcon={BsPersonWorkspace}
			blockTitle='Рабочие места'
			alerts={workplaces?.additionalData.alerts}
			visible={workplaces && workplaces?.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['works-group']}>
					{workplaces?.data.map((workplace, index) => (
						<div key={index} className={styles.work}>
							{headersWorkplaces.map((header, headerIndex) => {
								const workplaceField = workplace.item[header.key];
								if (workplaceField && workplace.item[header.key].trim() !== '') {
									return (
										<div key={headerIndex} className={styles.info}>
											<p>{header.label}
												<span>{workplaceField}</span>
											</p>
										</div>
									);
								}

								return null;
							})}
						</div>
					))}
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
