import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutPermissionsRolesResponse} from '../../../models/IPermissions';
import {putPermissionsAction} from '../../action/permissions';

const initialState: {
	responseStatus: ResponseStatus;
	response: PutPermissionsRolesResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const putPermissionsSlice = createSlice({
	name: 'permissions/putPermissions',
	initialState,
	reducers: {
		putPermissionsResetStatus(state) {
			state.responseStatus = initialState.responseStatus;
			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(putPermissionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putPermissionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putPermissionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {putPermissionsResetStatus} = putPermissionsSlice.actions;
export default putPermissionsSlice.reducer;
