import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type UserStaff, type GetUserStaffResponse} from '../../../models/IUsers';
import {getUserStaffAction} from '../../action/users';
import {getFullName} from '../../../utils';
import {contactType} from '../../../models/Enum';
import {DateTime} from 'luxon';

export type UserStaffTableData = UserStaff & {
	fullName: string;
	phone: string;
	email: string;
};

export type filtersUserStaff = {
	fullName?: string;
};

type getUserStaffState = {
	responseStatus: ResponseStatus;
	response: GetUserStaffResponse | undefined;
	tabledData: UserStaffTableData[] | undefined;
	filteredTableData: UserStaffTableData[] | undefined;
};

const initialState: getUserStaffState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	tabledData: undefined,
	filteredTableData: undefined,
};

const getTableData = (response: GetUserStaffResponse) => response.map(item => ({
	...item,
	birth_date: item.birth_date
		? DateTime.fromFormat(item.birth_date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy')
		: '',
	fullName: getFullName(item.first_name, item.middle_name, item.last_name, 'LFM'),
	phone: item.contacts?.find(item => item.contact_type === contactType.phone)?.contact ?? '',
	email: item.contacts?.find(item => item.contact_type === contactType.email)?.contact ?? '',
}));

const getUserStaffSlice = createSlice({
	name: 'getUserStaffSlice',
	initialState,
	reducers: {
		filteredUserStaff(state, action: PayloadAction<filtersUserStaff>) {
			const {fullName} = action.payload;

			state.filteredTableData = state.tabledData?.filter(item => (
				!fullName || item.fullName.toLocaleLowerCase().includes(fullName.toLocaleLowerCase())
			));
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getUserStaffAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getUserStaffAction.fulfilled, (state, action) => {
				state.response = action.payload;
				state.tabledData = getTableData(action.payload);
				state.filteredTableData = state.tabledData;
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getUserStaffAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
				state.response = undefined;
			});
	},
});

export const {filteredUserStaff} = getUserStaffSlice.actions;
export default getUserStaffSlice.reducer;
