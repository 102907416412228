import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {
	type PostInteractiveScreenGoRequest,
	type PostInteractiveScreenGoResponse,
	type PostInteractiveGoRequest,
	type PostInteractiveGoResponse,
	type PostInteractiveFinishRequest,
	type PostInteractiveFinishResponse,
} from '../models/IInteractive';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/interactive';

// Старт или продолжение интерактива
export async function postInteractiveGo(
	data: PostInteractiveGoRequest,
): Promise<AxiosResponse<PostInteractiveGoResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/${data}/go`,
		undefined,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
			},
		},
	);
}

// Переход к экрану интерактива
export async function postInteractiveScreenGo(
	data: PostInteractiveScreenGoRequest,
): Promise<AxiosResponse<PostInteractiveScreenGoResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/${data.id}/screen/${data.screenId}/go/${data.switch}`,
		data.body,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
			},
		},
	);
}

// Остановка интерактива
export async function postInteractiveFinish(
	data: PostInteractiveFinishRequest,
): Promise<AxiosResponse<PostInteractiveFinishResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/${data}/finish`,
		undefined,
		{
			headers: {
				Authorization: `Bearer ${getToken().token}`,
			},
		},
	);
}
