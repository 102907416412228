import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postGuestOtpSend} from '../../../api/Guest/guestApi';
import {type PostGuestOtpSendRequest, type PostGuestOtpSendResponse} from '../../../models/Guest/Guest/IGuest';

const postGuestOtpSendAction = createActionTemplateAxios<
PostGuestOtpSendResponse,
PostGuestOtpSendRequest
>(
	'guest/postGuestOtpSend/action',
	postGuestOtpSend,
	'guestToken',
);

export default postGuestOtpSendAction;
