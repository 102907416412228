import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetCheckParametersResponse} from '../../../models/ICheck';
import {getCheckParametersAction} from '../../action/checks';

type GetCheckParametersState = {
	responseStatus: ResponseStatus;
	response: GetCheckParametersResponse | undefined;
	processedResponse: GetCheckParametersResponse | undefined;
};

const initialState: GetCheckParametersState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	processedResponse: undefined,
};

const getProcessedResponse = (response: GetCheckParametersResponse) => {
	response.fields.sort((a, b) => a.order > b.order ? 1 : -1);

	return response;
};

const getCheckParametersSlice = createSlice({
	name: 'checks/getCheckParameters',
	initialState,
	reducers: {
		getCheckParametersSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getCheckParametersAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckParametersAction.fulfilled, (state, action) => {
				state.response = action.payload;
				state.processedResponse = getProcessedResponse(action.payload);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckParametersAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {getCheckParametersSliceResetState} = getCheckParametersSlice.actions;
export default getCheckParametersSlice.reducer;
