import React from 'react';
import styles from './ToggleCardOrTableView.module.css';
import {IoGridOutline} from 'react-icons/io5';
import {BsGrid3X3} from 'react-icons/bs';

type ToggleCardOrTableViewProps = {
	isCardView: boolean;
	setIsCardView: (value: boolean) => void;
};

const ToggleCardOrTableView: React.FC<ToggleCardOrTableViewProps> = ({
	isCardView,
	setIsCardView,
}) => (
	<div className={styles.container}>
		<div
			className={`${styles.item} ${isCardView ? styles.activeIcon : ''}`}
			onClick={() => {
				setIsCardView(true);
			}}
		>
			<IoGridOutline />
		</div>
		<div
			className={`${styles.item} ${isCardView ? '' : styles.activeIcon}`}
			onClick={() => {
				setIsCardView(false);
			} }
		>
			<BsGrid3X3 />
		</div>
	</div>
);

export default ToggleCardOrTableView;
