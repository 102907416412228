import {type GetDepartmentSubordinateResponse} from '../../../models/IDepartment';
import {getDepartmentSubordinate} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getDepartmentSubordinateAction = createActionTemplateAxios<
GetDepartmentSubordinateResponse,
void>(
	'department/getDepartmentSubordinate/action',
	getDepartmentSubordinate,
	'userToken',
);

export default getDepartmentSubordinateAction;
