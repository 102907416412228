import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetDepartmentSubordinateResponse} from '../../../models/IDepartment';
import {getDepartmentSubordinateAction} from '../../action/department';
import {type EmployeesDepartment, type Department} from '../../../models/Schemas';
import {DateTime} from 'luxon';
import {getFullNameFromObject} from '../../../utils';

type DepartmentSubordinateSliceState = {
	responseStatus: ResponseStatus;
	response: GetDepartmentSubordinateResponse | undefined;
	employeeTableItems: GetDepartmentSubordinateResponseEmployeeTableItem[] | undefined;
	filteredEmployeeTableItems: GetDepartmentSubordinateResponseEmployeeTableItem[] | undefined;
};

export type GetDepartmentSubordinateResponseEmployeeTableItem = {
	department: Department;
	employee?: EmployeesDepartment;
	employeeCount: number;
	employeePhone?: string;
	employeeEmail?: string;
};

const initialState: DepartmentSubordinateSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	employeeTableItems: undefined,
	filteredEmployeeTableItems: undefined,
};

const getEmployeeTable = (response: GetDepartmentSubordinateResponse):
[GetDepartmentSubordinateResponse, GetDepartmentSubordinateResponseEmployeeTableItem[]] => {
	const employeeTableItems: GetDepartmentSubordinateResponseEmployeeTableItem[] = [];

	// ИЗМЕНИТЬ, убрать фильтрацию, когда изменится запрос.
	const firstNode = response.find(node => response.some(node2 => node2.id !== node.parent_id));
	if (firstNode) {
		response = response.filter(item => item.parent_id === firstNode?.id);
	}

	response.forEach(department => {
		if (department.employees.length > 0) {
			department.employees.forEach(
				employee => {
					const birthDate = DateTime.fromFormat(employee.birth_date ?? '', 'yyyy-mm-dd');
					employeeTableItems.push({
						department,
						employee: {
							...employee,
							full_name: employee.full_name ?? getFullNameFromObject(employee),
							birth_date: birthDate.isValid ? birthDate.toFormat('dd.MM.yyyy') : undefined,
						},
						employeeCount: department.employees.length,
						employeePhone: employee.contacts.find(item => item.contact_type === 'phone')?.contact,
						employeeEmail: employee.contacts.find(item => item.contact_type === 'email')?.contact,
					});
				},
			);
		} else {
			employeeTableItems.push({department, employeeCount: 0});
		}
	});

	if (firstNode) {
		response.push(firstNode);
	}

	return [response, employeeTableItems];
};

const DepartmentSubordinateSlice = createSlice({
	name: 'department/getDepartmentSubordinate',
	initialState,
	reducers: {
		getDepartmentSubordinateResetState: () => initialState,
		getDepartmentSubordinateResetStatus(state) {
			state.responseStatus = initialState.responseStatus;
		},
		updateDepartmentSubordinate(state, action: PayloadAction<Department>) {
			const departmentSubordinate = {
				...action.payload,
				employees: [],
			};
			state.response = state.response?.map(item => {
				if (item.id === departmentSubordinate.id) {
					return departmentSubordinate;
				}

				return item;
			});
		},
		addDepartmentSubordinate(state, action: PayloadAction<Department>) {
			const departmentSubordinate = {
				...action.payload,
				employees: [],
			};
			if (state.response) {
				state.response.push(departmentSubordinate);
			} else {
				state.response = [departmentSubordinate];
			}
		},
		filterDepartmentSubordinateTableData(state, action: PayloadAction<string>) {
			const searchTerm = action.payload.toLowerCase();
			if (searchTerm) {
				state.filteredEmployeeTableItems = state.employeeTableItems?.filter(item => (
					item.department.name.toLowerCase().includes(searchTerm) // По имени отдела
					|| Boolean(item.department.director?.full_name?.toLowerCase().includes(searchTerm)) // По имени директора
					|| Boolean(item.employee?.full_name?.toLowerCase().includes(searchTerm)) // По имени сотрудника
					|| Boolean(item.employeeEmail?.toLowerCase().includes(searchTerm)) // По email
					|| Boolean(item.employeePhone?.toLowerCase().includes(searchTerm)) // По телефону
					|| Boolean(item.employee?.department_position?.name?.toLowerCase().includes(searchTerm)) // По должности
					|| Boolean(item.employee?.birth_date?.toLowerCase().includes(searchTerm)) // По дате рождения
					|| item.employeeCount.toString().includes(searchTerm) // По количеству сотрудников
				));
			} else {
				state.filteredEmployeeTableItems = state.employeeTableItems;
			}

			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getDepartmentSubordinateAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getDepartmentSubordinateAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				[state.response, state.employeeTableItems] = getEmployeeTable(action.payload);
				state.filteredEmployeeTableItems = state.employeeTableItems;
			})
			.addCase(getDepartmentSubordinateAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {filterDepartmentSubordinateTableData, getDepartmentSubordinateResetState, getDepartmentSubordinateResetStatus, updateDepartmentSubordinate, addDepartmentSubordinate} = DepartmentSubordinateSlice.actions;
export default DepartmentSubordinateSlice.reducer;
