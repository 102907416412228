import {combineReducers, configureStore} from '@reduxjs/toolkit';
import verifyReducer from './slice/auth/verifySlice';
import timerReducer from './slice/timerSlice';
import {smsReducer} from './slice/auth/smsAuthorizationSlice';
import {organizationReducer, departmentsReducer} from './slice/organization';
import {smsRegistrationReducer} from './slice/users/smsRegistrationSlice';
import smsCodeReducer from './slice/users/smsPhoneNumberSlice';
import createRequestUsersReducer from './slice/users/createRequestUsersSlice';
import themeReducer from './slice/theme/ThemeSlice';
import authNoActivatedUserReducer from './slice/auth/authNoActivatedUserSlice';
import putUserReducer from './slice/users/putUserSlice';
import refreshTokenReducer from './slice/auth/refreshTokenSlice';
import logoutReducer from './slice/auth/logoutSlice';
import {
	deleteEmployeeReducer,
	getEmployeeReducer,
	getEmployeesAutofillReducer,
	postEmployeesReducer,
	postEmployeesViaFileReducer,
	putEmployeeReducer,
} from './slice/employees';
import {
	getDepartmentReducer,
	departmentSubordinateReducer,
	getDepartmentPositionsReducer,
	postDepartmentPositionsReducer,
	putDepartmentPositionsReducer,
	postDepartmentReducer,
	putDepartmentReducer,
	getDepartmentEmployeesReducer,
	getPositionReducer,
	deleteDepartmentReducer,
} from './slice/department';
import {
	getUserByIdReducer,
	getPermissionsReducer,
	getUserAuthHistoryReducer,
	getUserReducer,
	getUserStaffReducer,
	postUsersViaFileReducer,
} from './slice/users';
import {
	getDepartmentPositionsOpenedReducer,
	getDepartmentPositionsCandidatesReducer,
	postDepartmentPositionsOpenReducer,
	postDepartmentPositionsCloseReducer,
} from './slice/departmentPositions';
import {
	getCandidateReducer,
	postCandidateReducer,
	putCandidateReducer,
} from './slice/candidates';
import {
	deleteContactReducer,
	postContactReducer,
	putContactReducer,
} from './slice/contacts';
import {
	postChallengersReducer,
	putChallengerReducer,
	getChallengerReducer,
} from './slice/challengers';
import {
	postUserPermissionsReducer,
	getUserPermissionsReducer,
	putUserPermissionsReducer,
	postOfficialPermissionsReducer,
	putOfficialPermissionsReducer,
	getOfficialPermissionsReducer,
	getPermissionsRolesReducer,
	putPermissionsReducer,
} from './slice/permissions';
import {
	getCheckParametersReducer,
	getCheckReducer,
	getCheckFieldsReducer,
	getCheckParametersByIdReducer,
	postCheckCreateReducer,
	postCheckStartReducer,
	postCheckUpdateReducer,
	getCheckReportByIdReducer,
	postCheckRestartReducer,
	postCheckCreateEmailReducer,
	postCheckCreateAddressReducer,
	postCheckCreatePassportReducer,
	postCheckCreatePhoneReducer,
} from './slice/checks';
import settingsReducer from './slice/settingsSlice';
import navigationContextReducer from './slice/navigationContextSlice';
import {getNotificationsReducer, postNotificationsReducer, putNotificationsByIdReducer} from './slice/notifications';
import {getStatisticsHomeReducer} from './slice/statistics';
import {getNameFillReducer, getAddressFillReducer} from './slice/dadata';
import {deleteCommentByIdReducer, getCommentByIdReducer, postCommentReducer, putCommentReducer} from './slice/comments';
import {postInteractiveFinishReducer, postInteractiveGoReducer, postInteractiveScreenGoReducer} from './slice/Interactive';
import {
	getPerson152fzDocumentsReducer,
	getPersonalizedPdAgreementReducer,
	getTokenPerson152fzReducer,
	postPerson152fzDocumentsReducer,
	postPerson152fzReducer,
} from './slice/person';
import {putReportResolutionReducer} from './slice/report';
import {getNavigationCompaniesReducer, getNavigationDepartmentsReducer, getNavigationReducer} from './slice/navigation';
import {getGuestReducer, putGuestReducer, postGuestOtpSendReducer, putGuestSignDocsReducer} from './slice/guest';

export const rootReducer = combineReducers({
	// Settings and Theme
	settings: settingsReducer,
	theme: themeReducer,

	// Auth
	authNoActivatedUser: authNoActivatedUserReducer,
	verify: verifyReducer,
	refreshToken: refreshTokenReducer,
	smsAuthorization: smsReducer,
	smsRegistration: smsRegistrationReducer,
	logout: logoutReducer,

	// Organization
	organization: organizationReducer,
	departments: departmentsReducer,
	timer: timerReducer,

	// User
	smsCode: smsCodeReducer,
	requestUsers: createRequestUsersReducer,
	getUser: getUserReducer,
	getUserById: getUserByIdReducer,
	postUserViaFile: postUsersViaFileReducer,
	putUser: putUserReducer,
	getUserStaff: getUserStaffReducer,
	getPermissions: getPermissionsReducer,
	getUserAuthHistory: getUserAuthHistoryReducer,

	// Department
	getDepartmentsSubordinate: departmentSubordinateReducer,
	getDepartment: getDepartmentReducer,
	getDepartmentPositions: getDepartmentPositionsReducer,
	getDepartmentEmployees: getDepartmentEmployeesReducer,
	getPosition: getPositionReducer,
	postDepartmentPositions: postDepartmentPositionsReducer,
	postDepartment: postDepartmentReducer,
	putDepartmentPositions: putDepartmentPositionsReducer,
	putDepartment: putDepartmentReducer,
	deleteDepartment: deleteDepartmentReducer,

	// DepartmentPositions
	getDepartmentPositionsOpened: getDepartmentPositionsOpenedReducer,
	getDepartmentPositionsCandidates: getDepartmentPositionsCandidatesReducer,
	postDepartmentPositionsOpen: postDepartmentPositionsOpenReducer,
	postDepartmentPositionsClose: postDepartmentPositionsCloseReducer,

	// Candidates
	getCandidate: getCandidateReducer,
	postCandidate: postCandidateReducer,
	putCandidate: putCandidateReducer,

	// Contacts
	postContact: postContactReducer,
	putContact: putContactReducer,
	deleteContact: deleteContactReducer,

	// Employees
	postEmployees: postEmployeesReducer,
	postEmployeesViaFile: postEmployeesViaFileReducer,
	getEmployee: getEmployeeReducer,
	getEmployeesAutofill: getEmployeesAutofillReducer,
	putEmployee: putEmployeeReducer,
	deleteEmployee: deleteEmployeeReducer,

	// Challengers
	postChallengers: postChallengersReducer,
	putChallenger: putChallengerReducer,
	getChallenger: getChallengerReducer,

	// Permissions
	postUserPermissions: postUserPermissionsReducer,
	getUserPermissions: getUserPermissionsReducer,
	putUserPermissions: putUserPermissionsReducer,
	postOfficialPermissions: postOfficialPermissionsReducer,
	putOfficialPermissions: putOfficialPermissionsReducer,
	getOfficialPermissions: getOfficialPermissionsReducer,
	getPermissionsRoles: getPermissionsRolesReducer,
	putPermissions: putPermissionsReducer,

	// Checks
	getCheckParameters: getCheckParametersReducer,
	getCheckParametersById: getCheckParametersByIdReducer,
	getCheck: getCheckReducer,
	getCheckFields: getCheckFieldsReducer,
	postCheckCreate: postCheckCreateReducer,
	postCheckStart: postCheckStartReducer,
	postCheckUpdate: postCheckUpdateReducer,
	postCheckRestart: postCheckRestartReducer,
	getCheckReportById: getCheckReportByIdReducer,

	// Report Resolution
	putReportResolution: putReportResolutionReducer,

	// Checks sub
	postCheckCreateEmail: postCheckCreateEmailReducer,
	postCheckCreateAddress: postCheckCreateAddressReducer,
	postCheckCreatePassport: postCheckCreatePassportReducer,
	postCheckCreatePhone: postCheckCreatePhoneReducer,

	// Notifications
	getNotifications: getNotificationsReducer,
	putNotificationsById: putNotificationsByIdReducer,
	postNotifications: postNotificationsReducer,

	// Statistic
	getStatisticsHome: getStatisticsHomeReducer,

	// DaData
	getNameFill: getNameFillReducer,
	getAddressFill: getAddressFillReducer,

	// Comments
	getCommentById: getCommentByIdReducer,
	postComment: postCommentReducer,
	putComment: putCommentReducer,
	deleteCommentById: deleteCommentByIdReducer,

	// Interactive
	postInteractiveGo: postInteractiveGoReducer,
	postInteractiveFinish: postInteractiveFinishReducer,
	postInteractiveScreenGo: postInteractiveScreenGoReducer,

	// Person
	getTokenPerson152fz: getTokenPerson152fzReducer,
	getPerson152fzDocuments: getPerson152fzDocumentsReducer,
	getPersonalizedPdAgreement: getPersonalizedPdAgreementReducer,
	postPerson152fzDocuments: postPerson152fzDocumentsReducer,
	postPerson152fz: postPerson152fzReducer,

	// Guest
	getGuest: getGuestReducer,
	putGuest: putGuestReducer,
	postGuestOtpSend: postGuestOtpSendReducer,
	putGuestSignDocs: putGuestSignDocsReducer,

	// Navigation
	getNavigation: getNavigationReducer,
	getNavigationDepartments: getNavigationDepartmentsReducer,
	getNavigationCompanies: getNavigationCompaniesReducer,
	navigationContext: navigationContextReducer,
});

export const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
