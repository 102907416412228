import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type User} from '../../../models/Schemas';
import {getUserAction} from '../../action/users';

const initialState: ResponseStatus & {user: User | undefined} = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	requestId: undefined,
	user: undefined,
};

const createGetUserSlice = createSlice({
	name: 'getUserSlice',
	initialState,
	reducers: {
		updatedUser(state, action: PayloadAction<User>) {
			state.user = action.payload;
		},
		resetGetUserState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getUserAction.pending, (state, action) => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.requestId = action.meta.requestId;
			})
			.addCase(getUserAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.requestId = action.meta.requestId;
				state.user = action.payload;
			})
			.addCase(getUserAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.status = action.payload?.status;
				state.user = undefined;
				state.requestId = action.meta.requestId;
			});
	},
});
export const {updatedUser, resetGetUserState} = createGetUserSlice.actions;
export default createGetUserSlice.reducer;
