import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postUserPermissions} from '../../../api/permissionsApi';
import {
	type PostUserPermissionsResponse,
	type PostUserPermissionsRequest,
} from '../../../models/IPermissions';

const postUserPermissionsAction = createActionTemplateAxios<
PostUserPermissionsResponse,
PostUserPermissionsRequest
>('permissions/postUserPermission/action', postUserPermissions, 'userToken');

export default postUserPermissionsAction;
