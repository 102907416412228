import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetDepartmentPositionsCandidatesResponse, type DepartmentPositionsCandidates} from '../../../models/IDepartmentPositions';
import {getDepartmentPositionsCandidatesAction} from '../../action/department_positions';
import {getFullName} from '../../../utils';
import {contactType} from '../../../models/Enum';
import {type Contact} from '../../../models/Schemas';

export type GetDepartmentPositionsCandidatesProceedResponseItem = {
	response: DepartmentPositionsCandidates;
	personFullName: string;
	authorFullName: string;
	firstContact: Contact | undefined;
};

type GetDepartmentPositionsCandidatesSliceState = {
	responseStatus: ResponseStatus;
	processedResponse: GetDepartmentPositionsCandidatesProceedResponseItem[];
};

const initialState: GetDepartmentPositionsCandidatesSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	processedResponse: [],
};

const getProceedResponse = (
	response: GetDepartmentPositionsCandidatesResponse,
): GetDepartmentPositionsCandidatesProceedResponseItem[] => response.map(
	item => {
		let firstContact;

		if (item.person.contacts.length > 0) {
			firstContact = item.person.contacts.find(item => item.contact_type === contactType.phone) ?? item.person.contacts[0];
		}

		return {
			response: item,
			personFullName: getFullName(item.person.first_name, item.person.middle_name, item.person.last_name, 'LFM'),
			authorFullName: getFullName(item.author.first_name, item.author.middle_name, item.author.last_name, 'LFM'),
			firstContact,
		};
	});

const getDepartmentPositionsCandidatesSlice = createSlice({
	name: 'departmentPosition/getDepartmentPositionsCandidates',
	initialState,
	reducers: {
		resetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getDepartmentPositionsCandidatesAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getDepartmentPositionsCandidatesAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.processedResponse = getProceedResponse(action.payload);
			})
			.addCase(getDepartmentPositionsCandidatesAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {resetState} = getDepartmentPositionsCandidatesSlice.actions;
export default getDepartmentPositionsCandidatesSlice.reducer;
