import React, {type ReactNode, useCallback, useMemo, useState} from 'react';
import styles from './CheckContactDetails.module.css';
import {HiOutlineDeviceMobile} from 'react-icons/hi';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {ModalEmail, ModalPhone} from '../../../Modal/Report';
import {type ReportModelV3Email, type ReportModelV3Phone} from '../../../../report/reportModelV3/reportModelV3';
import {ContactCard, EmailCard, PhoneCard} from '../../../UiKit/Cards';
import {ModalConfirmationEmail, ModalConfirmationPhone} from '../../../Modal/Report';
import {ReportBlockTemplate} from '..';

type SelectedPhone = ReportModelV3Phone & {tags: string[]};

export const CheckContactDetails = () => {
	const [visibleModalPhone, setVisibleModalPhone] = useState(false);
	const [visibleSubModalPhone, setVisibleSubModalPhone] = useState(false);

	const [visibleModalEmail, setVisibleModalEmail] = useState(false);
	const [visibleSubModalEmail, setVisibleSubModalEmail] = useState(false);

	const [selectedPhone, setSelectedPhone] = useState<SelectedPhone | undefined>(undefined);
	const [selectedEmail, setSelectedEmail] = useState<ReportModelV3Email | undefined>(undefined);

	const [selectedContainerId, setSelectedContainerId] = useState<string>();

	const contacts = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.contacts);
	const contactsPhoneV3 = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.contactsPhonesV3);
	const contactsEmailV3 = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.contactsEmailsV3);
	const nicknames = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.nicknames);

	const openModalPhone = (phone: SelectedPhone, containerId?: string) => {
		setSelectedPhone(phone);
		setVisibleModalPhone(true);
		setSelectedContainerId(containerId);
	};

	const openSubModalPhone = (phone: SelectedPhone, containerId?: string) => {
		setSelectedPhone(phone);
		setVisibleSubModalPhone(true);
		setSelectedContainerId(containerId);
	};

	const openModalEmail = (email: ReportModelV3Email, containerId?: string) => {
		setSelectedEmail(email);
		setVisibleModalEmail(true);
		setSelectedContainerId(containerId);
	};

	const openSubModalEmail = (email: ReportModelV3Email, containerId?: string) => {
		setSelectedEmail(email);
		setVisibleSubModalEmail(true);
		setSelectedContainerId(containerId);
	};

	const findTags = useCallback((phone: string) => {
		const tags: string[] = [];

		if (nicknames && nicknames.data.length > 0) {
			nicknames.data.forEach(nickname => {
				nickname.item.from_contacts.forEach(item => {
					if (item.phone.replace(/\D/g, '') === phone.replace(/\D/g, '')) {
						tags.push(item.name);
					}
				});
			});
		}

		return tags;
	}, [nicknames]);

	const [phones, emails] = useMemo(() => {
		const mobileOrPhoneContacts = contacts?.data.filter(contact => contact.item?.type === 'mobile' || contact.item?.type === 'phone') ?? [];
		const emailContacts = contacts?.data.filter(contact => contact.item?.type === 'email') ?? [];
		let phones: ReactNode[] = [];
		let emails: ReactNode[] = [];

		if (mobileOrPhoneContacts.length > 0) {
			phones = mobileOrPhoneContacts.map((contact, index) => (
				<ContactCard
					key={`v1-${index}`}
					contact={contact.item}
				/>
			));
		}

		if (emailContacts.length > 0) {
			emails = emailContacts.map((contact, index) => (
				<ContactCard
					key={`v1-${index}`}
					contact={contact.item}
				/>
			));
		}

		return [phones, emails];
	}, [contacts]);

	const phonesV3 = useMemo(() => {
		let phoneV3: ReactNode[] = [];
		if (contactsPhoneV3 && contactsPhoneV3.data.length > 0) {
			phoneV3 = contactsPhoneV3.data.map((phone, index) => {
				const tags = findTags(phone.item.phone_number);
				const modalPhone = {...phone.item, tags};
				return <PhoneCard
					key={`v3-${index}`}
					phone={phone.item}
					onClick={() => {
						openModalPhone(modalPhone, phone.containerId);
					}}
					tags={tags}
					onClickSub={() => {
						openSubModalPhone(modalPhone, phone.containerId);
					}}
					containerId={phone.containerId}
				/>;
			});
		}

		return phoneV3;
	}, [contactsPhoneV3, nicknames]);

	const emailV3 = useMemo(() => {
		let emailV3: ReactNode[] = [];
		if (contactsEmailV3 && contactsEmailV3.data.length > 0) {
			emailV3 = contactsEmailV3.data.map((email, index) => (
				<EmailCard
					key={`v3-${index}`}
					email={email.item}
					onClick={() => {
						openModalEmail(email.item, email.containerId);
					}}
					onClickSub={() => {
						openSubModalEmail(email.item, email.containerId);
					}}
					containerId={email.containerId}
				/>
			));
		}

		return emailV3;
	}, [contactsEmailV3]);

	return (
		<ReportBlockTemplate
			blockIcon={HiOutlineDeviceMobile}
			blockTitle='Контакты'
			alerts={[
				...contacts?.additionalData.alerts ?? [],
				...contactsPhoneV3?.additionalData.alerts ?? [],
				...contactsEmailV3?.additionalData.alerts ?? [],
			]}
			styleType='outline'
			blocksId='contacts'
			visible={phones.length > 0 || emails.length > 0 || phonesV3.length > 0 || emailV3.length > 0}
		>
			<div className={styles.content}>
				{(phones.length > 0 || phonesV3.length > 0) && (
					<div className={styles['group-container']}>
						<span className={styles['group-title']}>Номера телефона:</span>
						<div className={styles.group}>
							{phones}
							{phonesV3}
						</div>
					</div>
				)}
				{(emails.length > 0 || (contactsEmailV3 && contactsEmailV3.data.length > 0)
				) && (
					<div className={styles['group-container']}>
						<span className={styles['group-title']}>Эл. адреса:</span>
						<div className={styles.group}>
							{emails}
							{emailV3}
						</div>
					</div>
				)}
			</div>
			<ModalEmail
				visible={visibleModalEmail}
				setVisible={setVisibleModalEmail}
				email={selectedEmail}
				setVisibleSubModal={setVisibleSubModalEmail}
				containerId={selectedContainerId}
			/>
			<ModalConfirmationEmail
				visible={visibleSubModalEmail}
				setVisible={setVisibleSubModalEmail}
				email={selectedEmail?.email}
				containerId={selectedContainerId}
			/>
			<ModalPhone
				visible={visibleModalPhone}
				setVisible={setVisibleModalPhone}
				phone={selectedPhone}
				setVisibleSubModal={setVisibleSubModalPhone}
				containerId={selectedContainerId}
			/>
			<ModalConfirmationPhone
				visible={visibleSubModalPhone}
				setVisible={setVisibleSubModalPhone}
				phone={selectedPhone?.phone_number}
				containerId={selectedContainerId}
			/>
		</ReportBlockTemplate >
	);
};
