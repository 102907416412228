import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostDepartmentPositionsCloseResponse} from '../../../models/IDepartmentPositions';
import {postDepartmentPositionsCloseAction} from '../../action/department_positions';

type PostDepartmentPositionsCloseState = {
	responseStatus: ResponseStatus;
	response: PostDepartmentPositionsCloseResponse | undefined;
};

const initialState: PostDepartmentPositionsCloseState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postDepartmentPositionsCloseSlice = createSlice({
	name: 'departmentPosition/postDepartmentPositionsClose',
	initialState,
	reducers: {
		postDepartmentPositionsCloseResetStatus(state) {
			state.responseStatus = initialState.responseStatus;
			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(postDepartmentPositionsCloseAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(
				postDepartmentPositionsCloseAction.fulfilled,
				(state, action) => {
					state.responseStatus.isLoading = false;
					state.responseStatus.isCompleted = true;
					state.responseStatus.isError = false;
					state.response = action.payload;
				},
			)
			.addCase(postDepartmentPositionsCloseAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postDepartmentPositionsCloseResetStatus} = postDepartmentPositionsCloseSlice.actions;
export default postDepartmentPositionsCloseSlice.reducer;
