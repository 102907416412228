import React, {useCallback, useRef, useState} from 'react';
import styles from './Help.module.css';
import {type IconType} from 'react-icons';
import {IoHelpCircleSharp} from 'react-icons/io5';
import TooltipTemplate from '../TooltipMessage/TooltipTemplate';

type Colors = 'lavender' | 'lavender-600' | 'asphalt-text' | 'main-text';

type HelpProps = {
	tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
	Icon?: IconType;
	title?: string;
	children?: React.ReactNode;
	message?: string;
	textType?: Colors;
	iconType?: Colors;
	activeIconType?: Colors;
	maxWidth?: string;
};

const Help: React.FC<HelpProps> = ({
	tooltipPosition = 'top',
	Icon = IoHelpCircleSharp,
	textType = 'main-text',
	iconType = 'asphalt-text',
	activeIconType = 'lavender',
	maxWidth = '50%',
	...props
}) => {
	const helpRef = useRef<HTMLDivElement>(null);
	const [visible, setVisible] = useState(false);

	const onShowing = useCallback(() => {
		setVisible(true);
	}, []);
	const onHiding = useCallback(() => {
		setVisible(false);
	}, []);

	return (
		<>
			<div
				className={styles.container}
				ref={helpRef}
			>
				{props.title && <span className={styles[textType]}>{props.title}</span>}
				<Icon className={visible ? styles[activeIconType] : styles[iconType]} />
			</div>
			<TooltipTemplate
				position={tooltipPosition}
				targetRef={helpRef}
				onShowing={onShowing}
				onHiding={onHiding}
				maxWidth={maxWidth}
			>
				<>
					<p className={styles.message}>
						{props.message}
					</p>
					{props.children}
				</>
			</TooltipTemplate>
		</>
	);
};

export default Help;
