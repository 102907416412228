import React, {useEffect, useState, type ReactNode} from 'react';
import styles from './TooltipTemplate.module.css';
import {Tooltip} from 'devextreme-react';
import {type Position} from 'devextreme/common';
import {type PositionConfig} from 'devextreme/animation/position';

export type TooltipPosition = Position | PositionConfig;

type TooltipTemplateProps = {
	targetRef: React.RefObject<Element>;
	showTooltip?: boolean;
	position?: TooltipPosition;
	maxWidth?: string | number;
	children: ReactNode;
	onShowing?: () => void;
	onShown?: () => void;
	onHiding?: () => void;
	onHidden?: () => void;
};

const TooltipTemplate: React.FC<TooltipTemplateProps> = ({
	targetRef,
	showTooltip = true,
	position = 'top',
	maxWidth = '70%',
	children,
	...props
}) => {
	const [target, setTarget] = useState<Element | undefined>(undefined);

	useEffect(() => {
		setTarget(targetRef.current ?? undefined);
	}, [targetRef.current]);

	return (
		<Tooltip
			showEvent='mouseenter'
			hideEvent='mouseleave'
			hideOnOutsideClick={false}
			wrapperAttr={{
				class: styles['alert-wrapper'],
			}}
			position={position}
			target={target}
			maxWidth={maxWidth}
			disabled={!(children && showTooltip)}
			{...props}
		>
			{children}
		</Tooltip>
	);
};

export default TooltipTemplate;

