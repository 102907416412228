import React from 'react';
import styles from './Microlans.module.css';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {GrMoney} from 'react-icons/gr';
import {ReportBlockTemplate} from '..';

export const Microlans = () => {
	const microlans = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.microlans);

	return (
		<ReportBlockTemplate
			blockIcon={GrMoney}
			blockTitle='Микрозаймы'
			alerts={microlans?.additionalData.alerts}
			blocksId='microlans'
			visible={microlans && microlans.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['microlans-groups']}>
					{microlans?.data.map((microloan, index) => (
						<div key={index} className={styles.microloan}>

							{microloan.item.broker_name && (
								<div className={styles.info}>
									<p>
                                    Микрофинансовая организация:
										<span>{microloan.item.broker_name}</span>
									</p>
								</div>
							)}

							{microloan.item.date && (
								<div className={styles.info}>
									<p>
                                    Дата:
										<span>{microloan.item.date}</span>
									</p>
								</div>
							)}

							{microloan.item.summ && (
								<div className={styles.info}>
									<p>
                                    Сумма:
										<span>{microloan.item.summ} ₽</span>
									</p>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
