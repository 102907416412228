import React, {useEffect, useMemo, useState} from 'react';
import {UserIcon} from '../../Div';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {useNavigate} from 'react-router-dom';
import {getFullName} from '../../../../utils';
import styles from './DropdownProfile.module.css';
import {Button, DropDownBox, TextBox} from 'devextreme-react';
import {paths} from '../../../../paths';
import {logoutAction} from '../../../../redux/action/auth';
import {IoChevronUpOutline} from 'react-icons/io5';
import {ModalConfirmation} from '../../../Modal';

type ListItem = {
	text: string;
	onClick: () => void;
	className?: string;
};

const DropdownDefault: React.FC<unknown> = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const listItems = useMemo<ListItem[]>(() =>
		[
			{
				text: 'Профиль',
				onClick() {
					navigate(paths.profile);
				},
			},
			{
				text: 'Уведомления',
				onClick() {
					navigate(paths.notifications);
				},
			},
			{
				text: 'Настройки',
				onClick() {
					navigate(paths.settings);
				},
			},
			{
				text: 'Выход',
				onClick() {
					setVisible(true);
				},
				className: styles.logout,
			},
		], []);

	const [isOpen, setIsOpen] = useState(false);
	const [name, setName] = useState('');
	const [position, setPosition] = useState('');
	const getUser = useAppSelector(state => state.getUser);
	const [visible, setVisible] = useState(false);

	const logout = useAppSelector(state => state.logout);

	const getOut = () => {
		void dispatch(logoutAction());
	};

	const fieldRender = () => (
		<div className={styles.profile}>
			<div className={styles['profile-text']}>
				<span className={styles.name}>{name}</span>
				<span className={styles.position}>{position}</span>
			</div>
			<UserIcon label={name[0]} round size='medium' />
			<TextBox className='dx-hidden' />
		</div>
	);

	const contentRender = () => (
		<ul className={styles.list}>
			{listItems.map((item, index) => (
				<Button
					stylingMode='text'
					key={index}
					{...item}
				/>
			))}
		</ul>
	);

	const dropDownButtonRender = () => (
		<IoChevronUpOutline className={`${styles.toggle} ${isOpen && styles['toggle-open']}`} />
	);

	useEffect(() => {
		if (getUser.isCompleted && getUser.user) {
			const {first_name, middle_name, last_name, department_position} = getUser.user;
			setName(getFullName(first_name, middle_name, last_name, 'LF'));
			setPosition(department_position?.name ?? '');
		}
	}, [getUser.isLoading]);

	return (
		<>
			<DropDownBox
				fieldRender={fieldRender}
				contentRender={contentRender}
				dropDownButtonRender={dropDownButtonRender}
				onOpened={() => {
					setIsOpen(true);
				}}
				onClosed={() => {
					setIsOpen(false);
				}}
				stylingMode='filled'
				className={styles['drop-down-box']}
			/>
			<ModalConfirmation
				visible={visible}
				setVisible={setVisible}
				confirmation={getOut}
				confirmationText='Да'
				cancelText='Нет'
				isLoading={logout.isLoading}
				isError={logout.isError}
				loadingMessage='Выход из системы...'
				closeOnOutsideClick
			>
				<span className={styles['logout-text']}>Вы уверены, что хотите выйти?</span>
			</ModalConfirmation>
		</>
	);
};

export default DropdownDefault;
