import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../InputDevextreme.module.css';
import buttonStyles from './ButtonInput.module.css';
import {TextBox, Validator} from 'devextreme-react';
import {emailButton, searchButton} from '../InputButtons';
import {
	type InputStyles,
	type InputButtons,
	type InputMask,
	type InputButton,
} from '../InputsTypes';
import Loader from '../../../../Loader/Loader';
import {type ValidatedEvent} from 'devextreme/ui/validator';
import {RequiredRule} from 'devextreme-react/cjs/validator';
import {Label, type LabelCommonProps} from '../../../Common';

export type InputProps = {
	value: string | undefined;
	styleType?: 'large' | 'medium' | 'small';
	disabled?: boolean;

	inputStyles?: InputStyles;
	inputMask?: InputMask;
	inputButtons?: InputButtons;
	onClick?: () => void;
	isLoading?: boolean;
} & DropdownValidation & LabelCommonProps;

type DropdownValidation = {
	isValid?: boolean;
	setIsValid?: (value: boolean) => void;
	isRequired?: boolean;
	showValidation?: boolean;
	visible?: boolean;
	validationMessagePosition?: 'top' | 'bottom' | 'left' | 'right';
};

const ButtonInput: React.FC<InputProps> = ({
	value,
	styleType = 'small',
	title,
	titleColor = 'asphalt',
	inputStyles,
	inputMask,
	inputButtons,
	onClick,
	isLoading = false,
	isRequired = false,
	setIsValid,
	isValid = true,
	visible,
	showValidation,
	validationMessagePosition = 'top',
	disabled,
}) => {
	const [firstFocusOut, setFirstFocusOut] = useState(false);
	const inputRef = useRef<TextBox>(null);
	const validatorRef = useRef<Validator>(null);
	const wrapperClassName = `${styles.wrapper} ${inputButtons ? ' ' + styles.icons : styles['not-icons']}`;
	const inputClassName = `${styles['input-dx']} ${styles[styleType]} ${buttonStyles['input-button']}`;

	const buttons = useMemo(() => {
		const buttons: InputButton[] = inputButtons?.buttons ?? [];
		if (inputButtons?.searchButton) {
			buttons.push(searchButton);
		}

		if (inputButtons?.emailButton) {
			buttons.push(emailButton);
		}

		if (buttons.length > 0) {
			return buttons;
		}

		return undefined;
	}, [inputButtons]);

	// Изменения состояния валидации
	const onValidated = useCallback((e: ValidatedEvent) => {
		if (setIsValid) {
			setIsValid(e.isValid ?? false);
		}
	}, [setIsValid]);

	const onFocusOut = useCallback(() => {
		if (!firstFocusOut) {
			setFirstFocusOut(true);
		}
	}, [firstFocusOut, setFirstFocusOut]);

	// Валидация при изменении значений
	useEffect(() => {
		if (validatorRef.current) {
			validatorRef.current.instance.validate();
		}
	}, [value]);

	useEffect(() => {
		if (showValidation === false || visible === false) {
			setFirstFocusOut(false);
		}
	}, [showValidation, visible]);

	return (
		<>
			<Label
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				isRequired={isRequired}
			/>
			<div className={wrapperClassName} onClick={onClick}>
				<TextBox
					ref={inputRef}
					className={inputClassName}
					value={value}
					buttons={buttons}
					onInput={e => {
						e.event?.preventDefault();
					}}
					validationMessagePosition={validationMessagePosition}
					isValid={firstFocusOut || showValidation ? isValid : true}
					onFocusOut={onFocusOut}
					{...inputMask}
					{...inputStyles}
					disabled={disabled}
				>
					<Validator
						ref={validatorRef}
						onValidated={onValidated}
					>
						{isRequired && <RequiredRule
							message='Обязательное поле'
						/>}
					</Validator>
				</TextBox>
				<Loader isFloating isLoading={isLoading} size='small' borderRadius='8px' />
			</div>
		</>
	);
};

export default ButtonInput;
