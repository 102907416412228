import React from 'react';
import {InputName} from '../../../../UiKit/Inputs';

type EditingFullNameProps = {
	name: string;
	surname: string;
	middleName: string;
	isValidName: boolean;
	isValidSurname: boolean;
	isValidMiddleName: boolean;
	showValidation: boolean;
	visible: boolean;
	setName: (value: string) => void;
	setSurname: (value: string) => void;
	setMiddleName: (value: string) => void;
	setIsValidName: (value: boolean) => void;
	setIsValidMiddleName: (value: boolean) => void;
	setIsValidSurname: (value: boolean) => void;
};
export const EditingFullName: React.FC<EditingFullNameProps> = ({
	...props
}) => (
	<>
		<InputName
			value={props.surname}
			setValue={props.setSurname}
			title='Фамилия'
			inputStyles={{
				placeholder: 'Фамилия',
			}}
			inputValidation={{
				isValid: props.isValidSurname,
				setIsValid: props.setIsValidSurname,
				isRequired: true,
				lettersOnly: true,
				minLength: 2,
				showValidation: props.showValidation,
				visible: props.visible,
			}}
			parts={['SURNAME']}
		/>
		<InputName
			value={props.name}
			setValue={props.setName}
			title='Имя'
			inputStyles={{
				placeholder: 'Имя',
			}}
			inputValidation={{
				isValid: props.isValidName,
				setIsValid: props.setIsValidName,
				isRequired: true,
				lettersOnly: true,
				minLength: 2,
				showValidation: props.showValidation,
				visible: props.visible,
			}}
			parts={['NAME']}
		/>
		<InputName
			value={props.middleName}
			setValue={props.setMiddleName}
			title='Отчество'
			inputStyles={{
				placeholder: 'Отчество',
			}}
			inputValidation={{
				isValid: props.isValidMiddleName,
				setIsValid: props.setIsValidMiddleName,
				lettersOnly: true,
				minLength: 2,
				showValidation: props.showValidation,
				visible: props.visible,
			}}
			parts={['PATRONYMIC']}
		/>
	</>
);
