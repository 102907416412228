import React from 'react';
import styles from './ChipStatus.module.css';

type ChipStatusProps = {
	text: string;
	type: 'blue' | 'green' | 'orange' | 'violet';
};

const ChipStatus: React.FC<ChipStatusProps> = ({text, type}) => (
	<div className={`${styles.chip} ${styles[type]}`}>
		<span className={styles.text}>
			{text}
		</span>
	</div>
);

export default ChipStatus;
