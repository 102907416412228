/* eslint-disable @typescript-eslint/naming-convention */
import axios, {type AxiosResponse} from 'axios';
import {
	type GetDepartmentPositionsCandidatesRequest,
	type GetDepartmentPositionsCandidatesResponse,
	type GetDepartmentPositionsOpenedResponse,
	type PostDepartmentPositionsCloseRequest,
	type PostDepartmentPositionsCloseResponse,
	type PostDepartmentPositionsOpenRequest,
	type PostDepartmentPositionsOpenResponse,
} from '../models/IDepartmentPositions';
import {getToken} from '../utils/cookies/token/getToken';
import {getNavigationCookies} from '../utils/cookies';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/department/positions';

// Получение всех вакансий
export async function getDepartmentPositionsOpened(): Promise<
AxiosResponse<GetDepartmentPositionsOpenedResponse>
> {
	const navigation = getNavigationCookies();
	return axios.get(`${domain}${urlPrefix}/opened`, {
		headers: {Authorization: `Bearer ${getToken().token}`,
			'X-NAVIGATION-COMPANY-HASH': navigation.navigation_company_hash,
			'X-NAVIGATION-DEPARTMENT-HASH': navigation.navigation_department_hash,
		},

	});
}

// Получение кандидатов на вакансию
export async function getDepartmentPositionsCandidates(
	data: GetDepartmentPositionsCandidatesRequest,
): Promise<AxiosResponse<GetDepartmentPositionsCandidatesResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.positionId}/candidates`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Открыть вакансию
export async function postDepartmentPositionsOpen(
	data: PostDepartmentPositionsOpenRequest,
): Promise<AxiosResponse<PostDepartmentPositionsOpenResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/${data.positions_id}/open`,
		{},
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

// Закрыть вакансию
export async function postDepartmentPositionsClose(
	data: PostDepartmentPositionsCloseRequest,
): Promise<AxiosResponse<PostDepartmentPositionsCloseResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/${data.positions_id}/close`,
		{},
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}
