import styles from './Login.module.css';
import React, {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {sendSmsAuthorizationAction} from '../../redux/action/auth';
import {isCompleteReset} from '../../redux/slice/auth/smsAuthorizationSlice';
import logo from '../../assets/login/LogoLogin.png';
import {useCookies} from 'react-cookie';
import {startTimerAction} from '../../redux/action/timerAction';
import {type TimerAction} from '../../models/Timer';
import {Input} from '../UiKit/Inputs';
import {ErrorMessage} from '../UiKit/Message';
import {ButtonTemplate} from '../UiKit/Button';
import Loader from '../Loader/Loader';
import {type KeyDownEvent} from 'devextreme/ui/text_box';

type LoginProps = {
	setComponent: React.Dispatch<React.SetStateAction<boolean>>;
	email: string;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const Login: React.FC<LoginProps> = ({
	setComponent,
	email,
	setEmail,
}) => {
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [showValidation, setShowValidation] = useState(false);

	const {isError, errorMessage, isCompleted, isLoading, sms_sent_at} = useAppSelector(
		state => state.smsAuthorization,
	);
	const {isContinuation, isStop} = useAppSelector(state => state.timer);

	const [cookies] = useCookies(['debug']);

	const dispatch = useAppDispatch();

	const handleSubmit = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		// Проверка почты и отправка запроса
		if (isValidEmail) {
			if (email !== localStorage.getItem('lastEmailSmsSend')
			|| (!isContinuation && isStop)
			) {
				localStorage.setItem('lastEmailSmsSend', email);
				void dispatch(
					sendSmsAuthorizationAction({
						email,
						debug: Boolean(cookies.debug),
					}),
				);
			} else {
				localStorage.setItem('component', 'false');
				localStorage.setItem('email', email.toString());
				setComponent(false);
			}
		}
	};

	const handleResponse = () => {
		// Переход к следующему компоненту
		if (!isError) {
			localStorage.setItem('component', 'false');
			localStorage.setItem('email', email.toString());
			setComponent(false);
		}
	};

	const handleKeyDown = (e: KeyDownEvent) => {
		// Обработка ввода
		if (e.event?.key === 'Enter') {
			handleSubmit();
		}
	};

	useEffect(() => {
		setShowValidation(false);
	}, []);

	useEffect(() => {
		// Обработка запроса
		if (isCompleted) {
			dispatch(isCompleteReset());
			const requestOptions: TimerAction = {
				value: 60,
				startDate: sms_sent_at!,
				isContinuation: false,
			};
			void dispatch(startTimerAction(requestOptions));
			handleResponse();
		}
	}, [isLoading]);

	return (
		<div className={styles.card}>
			<div className={styles.logo}>
				<img src={logo} alt='' />
				<span>Экосистема продуктов для бизнеса</span>
			</div>

			<h2>Вход в личный кабинет</h2>

			<form
				onSubmit={e => {
					e.preventDefault();
				}}
				className={styles.login}
				noValidate
				autoComplete='on'
				name='login'
			>
				<Input
					value={email}
					setValue={setEmail}
					styleType='large'
					focusOnInput
					inputValidation={{
						isValid: isValidEmail,
						setIsValid: setIsValidEmail,
						isRequired: true,
						isEmail: true,
						showValidation,
					}}
					inputStyles={{
						placeholder: 'Электронный адрес',
						labelMode: 'floating',
					}}
					onKeyDown={handleKeyDown}
					inputAttr={{
						type: 'email',
						name: 'email',
						id: 'email',
						autoComplete: 'email',
					}}
				/>
				<ButtonTemplate
					type='submit'
					onClick={handleSubmit}
					text='Войти'
					styleType='long'
					color={'primary'}
				/>
				<ErrorMessage message={errorMessage} isError={isError} />
			</form>
			<Loader text='Проверка электронного адреса...' isLoading={isLoading} isFloating/>
		</div>
	);
};
