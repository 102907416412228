import React, {useCallback} from 'react';
import {type KeyDownEvent} from 'devextreme/ui/text_box';
import Input from '../../Template/Input/Input';
import {type InputStyles, type InputAttr} from '../../Template/InputsTypes';

type InputPhoneProps = {
	value: string;
	setValue: (value: string) => void;
	isValid: boolean;
	setIsValid: (value: boolean) => void;
	showValidation: boolean;
	isRequired?: boolean;

	inputStyles?: InputStyles;
	inputAttr?: InputAttr;
	validationMessagePosition?: 'top' | 'left' | 'right' | 'bottom';
	title?: string;
	styleType?: 'large' | 'medium' | 'small';
	focusOnInput?: boolean;
	disabled?: boolean;
	onKeyDown?: (e: KeyDownEvent) => void;
};

const InputPhone: React.FC<InputPhoneProps> = ({
	value,
	setValue,
	isValid,
	setIsValid,
	showValidation,
	isRequired = false,
	validationMessagePosition = 'top',
	...props
}) => {
	const setCleanValue = useCallback((value: string) => {
		const cleanValue = value.replace(/[^\d]/g, '');
		setValue(cleanValue);
	}, [setValue]);

	return (
		<Input
			value={value}
			setValue={setCleanValue}
			inputMask={{
				mask: '+7 (000) 000-00-00',
				useMaskedValue: true,
			}}
			inputValidation={{
				setIsValid,
				isValid,
				minLength: 11,
				isRequired,
				validationMessagePosition,
				showValidation,
			}}
			{...props}
		/>
	);
};

export default InputPhone;
