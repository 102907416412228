import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putPermissions} from '../../../api/permissionsApi';
import {
	type PutPermissionsRolesRequest,
	type PutPermissionsRolesResponse,
} from '../../../models/IPermissions';

const putPermissionsAction = createActionTemplateAxios<
PutPermissionsRolesResponse,
PutPermissionsRolesRequest
>('permissions/putPermissions/action', putPermissions, 'userToken');

export default putPermissionsAction;
