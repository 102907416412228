import {type ResponseStatus} from '../../../models/IAuth';
import {type PutEmployeeResponse} from '../../../models/IEmployees';
import {createSlice} from '@reduxjs/toolkit';
import {putEmployeeAction} from '../../action/employees';

const initialState: {
	responseStatus: ResponseStatus;
	response: PutEmployeeResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const putEmployeesSlice = createSlice({
	name: 'employees/putEmployee',
	initialState,
	reducers: {
		putEmployeeResetStatus(state) {
			state.responseStatus = initialState.responseStatus;
			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(putEmployeeAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putEmployeeAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putEmployeeAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {putEmployeeResetStatus} = putEmployeesSlice.actions;
export default putEmployeesSlice.reducer;
