import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetPermissionsResponse} from '../../../models/IUsers';
import {getPermissionsAction} from '../../action/users';
import {type Permission} from '../../../models/Schemas';
import {type GroupsPermissions} from '../../../models/Enum';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetPermissionsResponse | undefined;
	groupPermissions: GroupsPermissions | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
	groupPermissions: undefined,
};

const getGroupPermissions = (permissions: Permission[]) => permissions.reduce<GroupsPermissions>((groups, item) => {
	const key = item.permission_type;
	if (!groups[key]) {
		groups[key] = item;
	}

	return groups;
}, {});

const getPermissionsSlice = createSlice({
	name: 'user/getPermissions',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getPermissionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getPermissionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
				state.groupPermissions = getGroupPermissions(action.payload);
			})
			.addCase(getPermissionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export default getPermissionsSlice.reducer;
