import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type Organization} from '../../../models/IOrganization';
import getOrganizationAction from '../../action/organization/getOrganizationAction';

type OrganizationSliceState = ResponseStatus & {organization: Organization | undefined};

const initialState: OrganizationSliceState = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	organization: undefined,
	status: undefined,
};

const organizationSlice = createSlice({
	name: 'organization/getDepartments',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getOrganizationAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
			})
			.addCase(getOrganizationAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.organization = action.payload;
			})
			.addCase(getOrganizationAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.status = action.payload?.status;
			});
	},
});

export default organizationSlice.reducer;
