import {LuClipboardList} from 'react-icons/lu';
import {paths} from '../../paths';
import {GrGroup} from 'react-icons/gr';
import Checks from '../../pages/Checks';
import {type IconType} from 'react-icons/lib';
import type React from 'react';
import Subordinate from '../../pages/Subordinate';
import {HiOutlineBriefcase} from 'react-icons/hi';
import {TiFlowMerge} from 'react-icons/ti';
import {Jobs} from '../../pages/Jobs';
import DepartmentPositions from '../../pages/DepartmentPositions';

export type NavigationItem = {
	id: number;
	icon: IconType;
	text: string;
	path: string;
	component: React.ComponentType;
	tooltipMessage: string;
};

export const navigationData: NavigationItem[] = [
	{
		id: 0,
		icon: LuClipboardList,
		text: 'Проверки',
		path: paths.checks,
		component: Checks,
		tooltipMessage: 'Проверки',
	},
	{
		id: 1,
		icon: GrGroup,
		text: 'Сотрудники',
		path: paths.subordinate,
		component: Subordinate,
		tooltipMessage: 'Сотрудники',
	},
	{
		id: 2,
		icon: TiFlowMerge,
		text: 'Отдел',
		path: paths.departmentPositions,
		component: DepartmentPositions,
		tooltipMessage: 'Отдел',
	},
	{
		id: 3,
		icon: HiOutlineBriefcase,
		text: 'Вакансии',
		path: paths.jobs,
		component: Jobs,
		tooltipMessage: 'Вакансии',
	},
];
