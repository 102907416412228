import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetCheckReportByIdResponse} from '../../../models/ICheck';
import {getCheckReportByIdAction} from '../../action/checks';
import {getProcessedCheckReportV3} from '../../../report/reportModelV3/getProcessedCheckReportV3';
import {blocksIds} from '../../../report/reportModelEnum';
import {type ProcessedCheckReport} from '../../../report/ProcessedReport/ProcessedReportTypes';
import {type Alert} from '../../../report/reportModelV3/reportModelV3';

type NavItems = {
	id: string;
	name: string;
	alerts: Alert[];
};

type CheckReportType = 'Структурированный отчет' | 'Полные данные';

type FiltersCheckReport = {
	senderType: CheckReportType[];
};

type GetCheckReportByIdState = {
	responseStatus: ResponseStatus;
	response: GetCheckReportByIdResponse | undefined;
	processedResponse: ProcessedCheckReport | undefined;
	navigationItems: NavItems[];
	navSourceItems: NavItems[];
	filters: FiltersCheckReport;
};

const initialState: GetCheckReportByIdState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	processedResponse: undefined,
	navigationItems: [],
	navSourceItems: [],
	filters: {
		senderType: ['Структурированный отчет', 'Полные данные'],
	},
};

// eslint-disable-next-line complexity
const filterNavigationItems = (response: ProcessedCheckReport) => {
	const newItems: NavItems[] = [];

	const {report_data} = response;

	if (report_data.mainInf) {
		newItems.push({
			id: blocksIds.basicsInfo,
			name: 'Основная информация',
			alerts: report_data.mainAlerts,
		});
	}

	if (report_data.summary_data && report_data.summary_data.length > 0) {
		newItems.push({
			id: blocksIds.summaryData,
			name: 'Сводные данные',
			alerts: [],
		});
	}

	if (report_data.persons && report_data.persons.data.length > 0) {
		newItems.push({
			id: blocksIds.persons,
			name: 'Люди',
			alerts: report_data.persons.additionalData.alerts,
		});
	}

	if (report_data.name_history && report_data.name_history.data.length > 0) {
		newItems.push({
			id: blocksIds.nameHistory,
			name: 'Возможные имена',
			alerts: report_data.name_history.additionalData.alerts,
		});
	}

	if (report_data.address && report_data.address.data.length > 0) {
		newItems.push({
			id: blocksIds.address,
			name: 'Адреса',
			alerts: report_data.address.additionalData.alerts,
		});
	}

	if (
		Boolean(report_data.contacts && report_data.contacts.data.length > 0)
		|| Boolean(report_data.contactsPhonesV3 && report_data.contactsPhonesV3.data.length > 0)
		|| (report_data.contactsEmailsV3 && report_data.contactsEmailsV3.data.length > 0)
	) {
		newItems.push({
			id: blocksIds.contacts,
			name: 'Контакты',
			alerts: [
				...(report_data.contacts?.additionalData.alerts ?? []),
				...(report_data.contactsPhonesV3?.additionalData.alerts ?? []),
				...(report_data.contactsEmailsV3?.additionalData.alerts ?? []),
			],
		});
	}

	if (report_data.accounts && report_data.accounts.data.length > 0) {
		newItems.push({
			id: blocksIds.accounts,
			name: 'Аккаунты',
			alerts: report_data.accounts.additionalData.alerts,
		});
	}

	if (report_data.socialAccounts && report_data.socialAccounts.data.length > 0) {
		newItems.push({
			id: blocksIds.socialAccounts,
			name: 'Социальные аккаунты',
			alerts: report_data.socialAccounts.additionalData.alerts,
		});
	}

	if (report_data.advertisements && report_data.advertisements.data.length > 0) {
		newItems.push({
			id: blocksIds.advertisements,
			name: 'Объявления',
			alerts: report_data.advertisements.additionalData.alerts,
		});
	}

	if (report_data.registries && report_data.registries.data.length > 0) {
		newItems.push({
			id: blocksIds.registries,
			name: 'Реестры',
			alerts: report_data.registries.additionalData.alerts,
		});
	}

	if (Boolean(report_data.documents && report_data.documents.data.length > 0)
		|| Boolean(report_data.documentsV3 && report_data.documentsV3.data.length > 0)
	) {
		newItems.push({
			id: blocksIds.documents,
			name: 'Документы',
			alerts: [...(report_data.documents?.additionalData.alerts ?? []),
				...(report_data.documentsV3?.additionalData.alerts ?? [])],
		});
	}

	if (report_data.court_decisions && report_data.court_decisions.data.length > 0) {
		newItems.push({
			id: blocksIds.courtDecisions,
			name: 'Исполнительные делопроизводства',
			alerts: report_data.court_decisions.additionalData.alerts,
		});
	}

	if (report_data.workplaces && report_data.workplaces.data.length > 0) {
		newItems.push({
			id: blocksIds.workplaces,
			name: 'Рабочие места',
			alerts: report_data.workplaces.additionalData.alerts,
		});
	}

	if (report_data.transports && report_data.transports.data.length > 0) {
		newItems.push({
			id: blocksIds.transports,
			name: 'Транспортные средства',
			alerts: report_data.transports.additionalData.alerts,
		});
	}

	if (report_data.nicknames && report_data.nicknames.data.length > 0) {
		const nickName = report_data.nicknames.data.filter(nickName => (
			nickName.item.from_contacts.filter(contact => contact.name && contact.phone).length > 0
			|| nickName.item.self.length > 0));

		if (nickName.length > 0) {
			newItems.push({
				id: blocksIds.nicknames,
				name: 'Псевдонимы',
				alerts: report_data.nicknames?.additionalData.alerts ?? [],
			});
		}
	}

	if (report_data.public_groups && report_data.public_groups.data.length > 0) {
		newItems.push({
			id: blocksIds.publicGroups,
			name: 'Публичные группы',
			alerts: report_data.public_groups.additionalData.alerts,
		});
	}

	if (report_data.microlans && report_data.microlans.data.length > 0) {
		newItems.push({
			id: blocksIds.microlans,
			name: 'Микрозаймы',
			alerts: report_data.microlans.additionalData.alerts,
		});
	}

	return newItems;
};

const filterNavSourceItems = (response: ProcessedCheckReport) => {
	const newSourceItems: NavItems[] = [];

	const {report_data} = response;

	if (report_data.mainInf) {
		newSourceItems.push({
			id: blocksIds.basicsInfo,
			name: 'Основная информация',
			alerts: report_data.mainAlerts,
		});
	}

	if (report_data.raw_data && report_data.raw_data.length > 0) {
		report_data.raw_data.forEach(item => {
			if (item.name) {
				newSourceItems.push({
					id: item.key,
					name: item.name,
					alerts: [],
				});
			}
		});
	}

	return newSourceItems;
};

const getCheckReportByIdSlice = createSlice({
	name: 'checks/getCheckReportById',
	initialState,
	reducers: {
		getCheckReportByIdResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getCheckReportByIdAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckReportByIdAction.fulfilled, (state, action) => {
				state.response = action.payload;
				state.processedResponse = getProcessedCheckReportV3(action.payload);
				state.navigationItems = filterNavigationItems(state.processedResponse);
				state.navSourceItems = filterNavSourceItems(state.processedResponse);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getCheckReportByIdAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {getCheckReportByIdResetState} = getCheckReportByIdSlice.actions;
export default getCheckReportByIdSlice.reducer;
