import React, {useEffect, useRef, type ChangeEvent, type KeyboardEvent} from 'react';
import styles from './SixDigitalCode.module.css';

type SixDigitalCodeProps = {
	code: string[];
	setCode: React.Dispatch<React.SetStateAction<string[]>>;
	isError: boolean;
	visible?: boolean;
};

export const SixDigitalCode: React.FC<SixDigitalCodeProps> = ({
	code,
	setCode,
	isError,
	visible,
}) => {
	const inputRefs = useRef<Array<HTMLInputElement | undefined>>([]);

	const isDigit = (value: string) => /^\d$/.test(value);

	const updateCode = (index: number, value: string) => {
		const newCode = [...code];
		newCode[index] = value;
		setCode(newCode);
	};

	const focusInput = (index: number) => {
		const input = inputRefs.current[index];
		if (input) {
			input.focus();
			input.select();
		}
	};

	const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;

		if (isDigit(value)) {
			updateCode(index, value);

			if (index < code.length - 1 && value !== '') {
				inputRefs.current[index + 1]?.focus();
			}
		} else if (value === '') {
			updateCode(index, value);
			if (index > 0 && inputRefs.current[index - 1]) {
				focusInput(index - 1);
				e.target.blur();
			}
		}
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace' && code[index] === '') {
			if (index > 0 && inputRefs.current[index - 1]) {
				focusInput(index - 1);
				e.currentTarget.blur();
			}
		} else if (isDigit(e.key) && code[index] !== '' && code[index + 1] === '') {
			if (index < 6 && inputRefs.current[index + 1]) {
				focusInput(index + 1);
				e.currentTarget.blur();
			}
		}
	};

	const handlePaste = (e: React.ClipboardEvent, index: number) => {
		e.preventDefault();
		const pastedData = e.clipboardData.getData('text');
		const newCode = [...code];
		let j = 0;

		for (let i = index; i < code.length && j < pastedData.length;) {
			if (isDigit(pastedData[j])) {
				newCode[i] = pastedData[j];
				i++;
			}

			j++;
		}

		setCode(newCode);
	};

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				inputRefs.current[0]?.focus();
			}, 400);
		}
	}, [visible]);

	useEffect(() => {
		// Установите фокус на 1 input при загрузке страницы
		inputRefs.current[0]?.focus();
	}, []);

	return (
		<div className={styles['input-code']}>
			{code.map((digit, index) => (
				<input
					key={index}
					aria-label={index.toString()}
					type='text'
					name='otp'
					autoComplete='off'
					maxLength={1}
					className={isError ? styles.error : ''}
					value={digit}
					onChange={e => {
						handleChange(index, e);
					}}
					onKeyDown={e => {
						handleKeyDown(e, index);
					}}
					onPaste={e => {
						handlePaste(e, index);
					}}
					ref={ref => {
						(inputRefs.current[index] = ref ?? undefined);
					}}
				/>
			))}
		</div>
	);
};
