import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostDepartmentResponse} from '../../../models/IDepartment';
import {postDepartmentAction} from '../../action/department';

type postDepartmentSliceState = {
	responseStatus: ResponseStatus;
	response: PostDepartmentResponse | undefined;
};

const initialState: postDepartmentSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postDepartmentSlice = createSlice({
	name: 'department/postDepartment',
	initialState,
	reducers: {
		postDepartmentResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postDepartmentAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postDepartmentAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postDepartmentAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postDepartmentResetState} = postDepartmentSlice.actions;
export default postDepartmentSlice.reducer;
