import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './Contacts.module.css';
import {type Contact} from '../../models/Schemas';
import {contactType} from '../../models/Enum';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {deleteContactAction} from '../../redux/action/contacts';
import {ModalConfirmation} from '../Modal';
import {Email, Phone} from '../UiKit/Message';
import {RiDeleteBin5Line} from 'react-icons/ri';
import {formatPhoneNumber} from '../../utils';

type ContactsType = {
	contacts?: Contact[];
	contactDelete: boolean;
	CustomHeader?: React.ReactNode;
};

const Contacts: React.FC<ContactsType> = ({
	contacts,
	contactDelete,
	CustomHeader,
}) => {
	// Контакты
	const [currentContacts, setCurrentContacts] = useState<Contact[]>([]);
	const [contact, setContact] = useState<Contact | undefined>(undefined);
	const [isOpenModalDeleteContact, setIsOpenModalDeleteContact] = useState(false);

	const deleteContactState = useAppSelector(state => state.deleteContact);

	const dispatch = useAppDispatch();

	// Открытие окна подтверждения удаления контакта
	const openModalDeleteContact = (contact: Contact) => {
		if (contactDelete) {
			setContact(contact);
			setIsOpenModalDeleteContact(true);
		}
	};

	// Открытие окна подтверждения удаления контакта
	const deleteContact = () => {
		if (contact) {
			void dispatch(deleteContactAction({id: contact.id}));
		}
	};

	// Вывод контакта в зависимости от типа
	const getFormatContact = (contactRow: Contact) => {
		const {contact_type, contact} = contactRow;
		if (contact_type === contactType.email) {
			return <Email email={contact} />;
		}

		if (contact_type === contactType.phone) {
			return <Phone phone={contact} formatPhone />;
		}
	};

	// Генерация контактов
	const contactsList = useMemo(() => {
		if (currentContacts.length > 0) {
			return <>
				{currentContacts.map(item => (
					<li key={item.id} className={styles['contacts-item']}>
						{getFormatContact(item)}
						<span className={styles['ellipsis-text']}>
							{item.description}
						</span>
						{contactDelete && <div className={styles['delete-icon']}>
							<RiDeleteBin5Line
								onClick={() => {
									openModalDeleteContact(item);
								}}
							/>
						</div>}
					</li>
				))}
			</>;
		}

		return <span className={styles.title}>Контактные данные не указаны</span>;
	}, [currentContacts]);

	// Удаление контакта
	useEffect(() => {
		if (deleteContactState.responseStatus.isCompleted) {
			setIsOpenModalDeleteContact(false);
			setCurrentContacts(currentContacts.filter(item => item.id !== contact?.id));
			setContact(undefined);
		}
	}, [deleteContactState.responseStatus.isLoading]);

	// Обновление контактов
	useEffect(() => {
		if (contacts && contacts.length > 1) {
			setCurrentContacts([...contacts].sort((a, b) => a.contact_type > b.contact_type ? 1 : -1));
		} else {
			setCurrentContacts(contacts ?? []);
		}
	}, [contacts]);

	return (
		<>
			<div className={styles.contacts}>
				{CustomHeader ?? <span>Контактные данные</span>}
				<ul className={styles['item-list']}>
					{contactsList}
				</ul>
			</div>
			{contactDelete && <ModalConfirmation
				visible={isOpenModalDeleteContact}
				setVisible={setIsOpenModalDeleteContact}
				confirmation={deleteContact}
				confirmationText='Удалить'
				cancelText='Отмена'
				isLoading={deleteContactState.responseStatus.isLoading}
				loadingMessage={`Удаление контакта ${contact?.contact}`}
				isError={deleteContactState.responseStatus.isError}
				errorMessage={deleteContactState.responseStatus.errorMessage}
			>
				<div className={styles['delete-message']}>
					<span>Вы уверены, что хотите удалить контакт
						<span className={styles['delete-contact']}>
							{` ${
								contact?.contact_type === contactType.phone
									? formatPhoneNumber(contact.contact)
									: contact?.contact
							} `}
						</span>?
					</span>
				</div>
			</ModalConfirmation>}
		</>
	);
};

export default Contacts;
