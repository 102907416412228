import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postCandidate} from '../../../api/candidatesApi';
import {type PostCandidateRequest, type PostCandidateResponse} from '../../../models/ICandidates';

const postCandidateAction = createActionTemplateAxios<
PostCandidateResponse,
PostCandidateRequest>(
	'candidates/postCandidate/action',
	postCandidate,
	'userToken',
);

export default postCandidateAction;
