import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './DataProfile.module.css';
import {useAppSelector} from '../../../redux/redux-hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {type Contact} from '../../../models/Schemas';
import {Email, Phone} from '../../UiKit/Message';
import {ModalEditContacts} from '../../Modal';
import {HiOutlinePencilAlt} from 'react-icons/hi';
import {formatPhoneNumber} from '../../../utils';
import {Contacts} from '../../Contacts';
import {typePermissions} from '../../../models/Enum';
import {checkPermissionProfile} from '../../../utils/permissions/permissions';
import {type EditContactsPermissions} from '../../Modal/ModalEditContacts/ModalEditContacts';
import {SplitLine} from '../../UiKit/Div';
import {calculateAge} from '../../../utils/calculateAge';
import {paths} from '../../../paths';
import {getAgeString} from '../../../utils/getAgeString';

export const DataProfile = () => {
	const {id} = useParams();
	const navigate = useNavigate();

	const {user, isLoading, isCompleted} = useAppSelector(state => state.getUser);
	const getEmployeeById = useAppSelector(state => state.getEmployee);
	const getUserById = useAppSelector(state => state.getUserById);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const organizationName = useAppSelector(state => state.organization.organization?.name);

	const [departmentName, setDepartmentName] = useState<string>();
	const [departmentPosition, setDepartmentPosition] = useState<string>();
	const [roleName, setRoleName] = useState<string>();
	const [dateBirth, setDateBirth] = useState<string>();

	const editContactsPermissions = useRef<EditContactsPermissions>({
		isCreate: false,
		isDelete: false,
		isUpdate: false,
	});

	const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
	const [userPhone, setUserPhone] = useState<string | undefined>(undefined);

	const [contacts, setContacts] = useState<Contact[] | undefined>(undefined);
	const [visibleEditContacts, setVisibleEditContacts] = useState(false);

	// Права
	const [employeeRead, setEmployeeRead] = useState(false);

	// Проверка прав
	const checkPermissions = () => {
		const {groupPermissions} = getPermissions;
		const isMe = id === undefined;
		const isMyDepartment = isMe || getEmployeeById.response?.department_id === user?.department?.id;

		setEmployeeRead(checkPermissionProfile(typePermissions.employee_read, groupPermissions, isMe, isMyDepartment));
		editContactsPermissions.current = {
			isCreate: checkPermissionProfile(typePermissions.contact_create, groupPermissions, isMe, isMyDepartment),
			isDelete: checkPermissionProfile(typePermissions.contact_delete, groupPermissions, isMe, isMyDepartment),
			isUpdate: checkPermissionProfile(typePermissions.contact_update, groupPermissions, isMe, isMyDepartment),
		};
	};

	// Доступно изменение контактов
	const isEditContact = () => (
		editContactsPermissions.current.isCreate
		|| editContactsPermissions.current.isDelete
		|| editContactsPermissions.current.isUpdate) && employeeRead;

	const formatDate = (dateBirth: string | undefined) => {
		if (!dateBirth) {
			return '';
		}

		const date = dateBirth.split('-');
		return date.reverse().join('.');
	};

	const getContacts = (contacts: Contact[] | undefined) => {
		setContacts(contacts);
	};

	const getUserEmailAndPhone = () => {
		if (id) {
			if (getEmployeeById.response?.user_id && getUserById.responseStatus.isCompleted) {
				setUserEmail(getUserById.response?.email);
				setUserPhone(formatPhoneNumber(getUserById.response?.phone_number));
			} else {
				setUserEmail(undefined);
				setUserPhone(undefined);
			}
		} else {
			setUserEmail(user?.email);
			setUserPhone(user?.phone_number);
		}
	};

	const contactsHeader = useMemo(() => {
		let editContact: React.ReactNode;

		if (isEditContact()) {
			editContact = <div
				className={styles.action}
				onClick={() => {
					setVisibleEditContacts(true);
				}}
			>
				<span>Редактировать контакты</span>
				<HiOutlinePencilAlt />
			</div>;
		}

		return (
			<div className={styles['contacts-header-container']}>
				<SplitLine />
				<div>
					<span>Контактные данные</span>
					{editContact}
				</div>
			</div>
		);
	}, [contacts, editContactsPermissions]);

	// Перейти в свой департамент
	const goToDepartment = () => {
		if (id) {
			if (getEmployeeById.response?.department_id) {
				const updatedPath = paths.departmentPositions.replace(':id', (getEmployeeById.response.department_id).toString());
				navigate(updatedPath);
			}
		}

		if (user?.department?.id) {
			const updatedPath = paths.departmentPositions.replace(':id', (user.department.id).toString());
			navigate(updatedPath);
		}
	};

	// Отредактированная дата рождения
	const getBirthDate = () => {
		if (id) {
			if (getEmployeeById.response?.birth_date) {
				return `${formatDate(getEmployeeById.response.birth_date)} 
				(${getAgeString(calculateAge(formatDate(getEmployeeById.response.birth_date)))})`;
			}

			return '-';
		}

		if (user?.birth_date) {
			return `${formatDate(user.birth_date)} 
			(${getAgeString(calculateAge(formatDate(user.birth_date)))})`;
		}

		return '-';
	};

	// Получение данных о сотруднике
	useEffect(() => {
		if (id && getEmployeeById.responseStatus.isCompleted) {
			const {response} = getEmployeeById;
			getUserEmailAndPhone();
			getContacts(response?.contacts);
			checkPermissions();

			setDepartmentName(response?.department?.name);
			setDepartmentPosition(response?.department_position?.name);
			setDateBirth(getBirthDate());
		}
	}, [getEmployeeById.response]);

	// Получение Данных о пользователе
	useEffect(() => {
		if (getUserById.responseStatus.isCompleted) {
			const {response} = getUserById;

			getUserEmailAndPhone();

			setRoleName(response?.role_name);
		}
	}, [getUserById.responseStatus.isLoading]);

	// Получение данных о себе
	useEffect(() => {
		if (id === undefined && isCompleted) {
			getUserEmailAndPhone();
			getContacts(user?.contacts);
			checkPermissions();
		}
	}, [isLoading]);

	return (
		<>
			<div className={styles.content}>
				<div className={styles.info}>
					<span className={styles.title}>
					Компания
					</span>
					<span>
						{organizationName}
					</span>
					{(id ? departmentName : user?.department?.name) && (
						<>
							<span className={styles.title}>Отдел</span>
							<span className={styles.department} onClick={goToDepartment}>{id ? departmentName : user?.department?.name}</span>
						</>
					)}

					{(id ? departmentPosition : user?.department_position?.name) && (
						<>
							<span className={styles.title}>Должность</span>
							<span>{id ? departmentPosition : user?.department_position?.name}</span>
						</>
					)}
					{(id ? roleName : user?.role_name) && (
						<>
							<span className={styles.title}>Роль</span>
							<span>{id ? roleName : user?.role_name}</span>
						</>
					)}

					<span className={styles.title}>
						Дата рождения
					</span>
					<span>
						{id ? dateBirth : getBirthDate()}
					</span>
					{userEmail && <>
						<span className={styles.title}>
							Электронный адрес
						</span>
						<Email email={userEmail} />
					</>}
					{userPhone && <>
						<span className={styles.title}>
							Телефон
						</span>
						<Phone phone={userPhone} formatPhone/>
					</>}
				</div>

				<Contacts
					contacts={contacts}
					contactDelete={editContactsPermissions.current.isDelete}
					CustomHeader={contactsHeader}
				/>
			</div>
			{isEditContact() && <ModalEditContacts
				visible={visibleEditContacts}
				setVisible={setVisibleEditContacts}
				employeeId={id ? parseInt(id, 10) : user?.employee_id ?? undefined}
				permissions={editContactsPermissions}
			/>}
		</>
	);
};
