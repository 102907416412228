import React, {useCallback, useEffect, useState} from 'react';
import {Input} from '../../../UiKit/Inputs';
import styles from './CreateCheckIndividualEmailForm.module.css';
import {
	type InteractiveSwitch,
	type InteractiveFieldValues,
	type InteractiveScreen,
} from '../../../../models/IInteractive';

type CreateCheckIndividualEmailFormProps = {
	fields: React.MutableRefObject<InteractiveFieldValues | undefined>;
	currentScreen: React.MutableRefObject<InteractiveScreen | undefined>;
	currentSwitch?: React.MutableRefObject<InteractiveSwitch | undefined>;
	showValidation: boolean;
	errors?: Record<string, string>;
};

const CreateCheckIndividualEmailForm: React.FC<CreateCheckIndividualEmailFormProps> = ({
	fields,
	currentScreen,
	currentSwitch,
	showValidation,
	errors,
}) => {
	const [email, setEmail] = useState('');
	const [isValid, setIsValid] = useState(false);

	// Изменение данных экранна
	useEffect(() => {
		if (currentScreen.current) {
			const fieldEmail = currentScreen.current.default_values.email;

			if (typeof fieldEmail === 'string') {
				setEmail(fieldEmail);
			} else {
				setEmail('');
			}
		}
	}, [currentScreen.current]);

	// Обновление значений
	useEffect(() => {
		if (fields.current) {
			fields.current.email = email;
		}
	}, [email]);

	return (
		<div className={styles.container}>
			<div className={styles.group}>
				<h1 className={styles.title}>
					Электронный адрес
				</h1>
				<div className={styles.inputs}>
					<Input
						value={email}
						setValue={setEmail}
						inputStyles={{
							placeholder: 'Введите электронный адрес',
						}}
						inputValidation={{
							isValid,
							setIsValid,
							isRequired: true,
							showValidation,
							isEmail: true,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CreateCheckIndividualEmailForm;
