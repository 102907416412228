import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postEmployeesViaFile} from '../../../api/employeesApi';
import {
	type PostEmployeesViaFileRequest,
	type PostEmployeesViaFileResponse,
} from '../../../models/IEmployees';

const postEmployeesViaFileAction = createActionTemplateAxios<
PostEmployeesViaFileResponse,
PostEmployeesViaFileRequest
>('employees/postEmployeesViaFile/action', postEmployeesViaFile, 'userToken');

export default postEmployeesViaFileAction;
