import {type Permission} from './Schemas';

// Контакты
export const contactType = {
	email: 'email',
	phone: 'phone',
};

// Права
export const permissionLevel = {
	all: 'all',
	only_my_department: 'only_my_department',
	only_owner: 'only_owner',
};

export type GroupsPermissions = Record<string, Permission>;

export const typePermissions = {
	// Department
	department_create: 'department_create',
	department_update: 'department_update',
	department_delete: 'department_delete',
	department_read: 'department_read',
	department_subordinate_read: 'department_subordinate_read',
	department_employees_read: 'department_employees_read',
	// User
	user_create: 'user_create',
	user_update: 'user_update',
	user_read: 'user_read',
	user_staff_read: 'user_staff_read',
	user_mass_create: 'user_mass_create',
	user_archive: 'user_archive',
	user_dearchive: 'user_dearchive',
	// Contact
	contact_create: 'contact_create',
	contact_update: 'contact_update',
	contact_delete: 'contact_delete',
	// Position
	position_create: 'position_create',
	position_update: 'position_update',
	position_delete: 'position_delete',
	positions_read: 'positions_read',
	// Candidate
	candidate_create: 'candidate_create',
	candidate_read: 'candidate_read',
	candidate_update: 'candidate_update',
	// Check
	checks_read: 'checks_read',
	check_result_read: 'check_result_read',
	check_start: 'check_start',
	check_update: 'check_update',
	zero_report_create: 'zero_report_create',
	zero_report_update: 'zero_report_update',
	report_read: 'report_read',
	create_check_request: 'create_check_request',
	update_check_request: 'update_check_request',
	employee_check: 'employee_check',
	user_check: 'user_check',
	candidate_check: 'candidate_check',
	// Employee
	employee_read: 'employee_read',
	employee_create: 'employee_create',
	employee_mass_create: 'employee_mass_create',
	employee_update: 'employee_update',
	employee_archive: 'employee_archive',
	employee_dearchive: 'employee_dearchive',

	vacancy_open: 'vacancy_open',
	vacancy_close: 'vacancy_close',

	challenger_create: 'challenger_create',
	challenger_update: 'challenger_update',
	challenger_read: 'challenger_read',

	role_update: 'role_update',
};

// Статус проверки
export const checkStatus: Record<string, string> = {
	wait: 'Ожидает проверки',
	collect: 'Сбор данных',
	in_progress: 'На проверке',
	verified: 'Проверен',
};

// Важность уведомлений
export type NotificationsSeverity = {
	key: 'low' | 'normal' | 'high';
	text: 'Низкий' | 'Средний' | 'Высокий';
};

export const optionsNotificationsSeverity: NotificationsSeverity[] = [
	{
		key: 'low',
		text: 'Низкий',
	},
	{
		key: 'normal',
		text: 'Средний',
	},
	{
		key: 'high',
		text: 'Высокий',
	},
];

// Тип уведомлений
export type NotificationTypes = {
	key: 'department' | 'user' | 'organization' | 'role';
	text: 'Отдел' | 'Пользователь' | 'Организация' | 'роль';
};

export const optionsNotificationTypes: NotificationTypes[] = [
	{
		key: 'organization',
		text: 'Организация',
	},
	{
		key: 'department',
		text: 'Отдел',
	},
];
