import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getDepartmentPositionsCandidates} from '../../../api/departmentPositionsApi';
import {type GetDepartmentPositionsCandidatesRequest, type GetDepartmentPositionsCandidatesResponse} from '../../../models/IDepartmentPositions';

const getDepartmentPositionsCandidatesAction = createActionTemplateAxios<
GetDepartmentPositionsCandidatesResponse,
GetDepartmentPositionsCandidatesRequest>(
	'departmentPositions/getDepartmentPositionsCandidates/action',
	getDepartmentPositionsCandidates,
	'userToken',
);

export default getDepartmentPositionsCandidatesAction;
