import React, {type ReactNode, useEffect, useState} from 'react';
import styles from './ModalNotification.module.css';
import ModalWindow from '../../ModalWindow';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {type NotificationTypes, optionsNotificationsSeverity} from '../../../../models/Enum';
import {type Notification} from '../../../../models/INotifications';
import {putNotificationsByIdAction} from '../../../../redux/action/notifications';
import {UserIcon} from '../../../UiKit/Div';
import {getNotificationsUpdateUnReadCount} from '../../../../redux/slice/notifications';

type ModalCreateNotificationProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	notification: Notification | undefined;
};
const optionsNotificationTypes: NotificationTypes[] = [

];

const getNotificationType = (type: string | undefined) => {
	const notificationType = optionsNotificationTypes.find(item => item.key === type);

	return notificationType?.text ?? '';
};

const getNotificationSeverity = (severity: string | undefined) => {
	if (severity) {
		const notificationSeverity = optionsNotificationsSeverity.find(item => item.key === severity)?.text ?? '';

		return (
			<span className={`${styles.severity} ${styles[severity]}`}>
				{notificationSeverity} приоритет
			</span>
		);
	}

	return null;
};

const getNotificationCreator = (creator: string | undefined, department: string | undefined) => {
	if (creator) {
		return (
			<div className={styles.creator}>
				<UserIcon label={creator[0]} round size='medium' />
				<div className={styles.user}>
					<span>{creator}</span>
					{department && <span className={styles.department}>{department}</span>}
				</div>
			</div>
		);
	}

	return (
		<div className={styles.creator}>
			<span className={styles.system}>Системное уведомление</span>
		</div>
	);
};

const ModalNotification: React.FC<ModalCreateNotificationProps> = ({...props}) => {
	const dispatch = useAppDispatch();
	const putNotificationsById = useAppSelector(state => state.putNotificationsById);

	const [severity, setSeverity] = useState<ReactNode>(null);
	const [type, setType] = useState('');
	const [creator, setCreator] = useState<ReactNode>(null);

	const cleanWindowData = () => {
		setSeverity(getNotificationSeverity(props.notification?.severity));
		setType(getNotificationType(props.notification?.type));
		setSeverity(null);
		setType('');
		setCreator(null);
	};

	useEffect(() => {
		cleanWindowData();
		if (props.visible) {
			if (props.notification?.viewed === false) {
				void dispatch(putNotificationsByIdAction({id: props.notification.id}));
			}

			setSeverity(getNotificationSeverity(props.notification?.severity));
			setType(getNotificationType(props.notification?.type));
			setCreator(getNotificationCreator(props.notification?.creator.full_name, props.notification?.creator.department_name));
		}
	}, [props.visible]);

	useEffect(() => {
		if (props.visible) {
			if (putNotificationsById.responseStatus.isCompleted
				&& putNotificationsById.response?.id === props.notification?.id
				&& props.notification?.viewed === false
			) {
				dispatch(getNotificationsUpdateUnReadCount(props.notification.id));
			}
		}
	}, [putNotificationsById.responseStatus.isLoading]);

	return (
		<ModalWindow
			width='40%'
			header={
				<div className={styles.header}>
					<h1 className={styles.title}>Уведомление</h1>
					<div className={styles['notification-details']}>
						<div>
							{creator}
						</div>
						<div>
							{severity}
							<span className={styles.time}>{props.notification?.created_date}</span>
						</div>
					</div>
				</div>
			}
			visible={props.visible}
			setVisible={props.setVisible}
			closable
			closeOnOutsideClick
		>
			<div className={styles.container}>
				<p className={styles.text}>
					{props.notification?.text}
				</p>
			</div>
		</ModalWindow>
	);
};

export default ModalNotification;
