import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import {type NavigationCompany, type NavigationDepartment} from '../../models/Navigation/Navigation';

type NavigationContextState = {
	company?: NavigationCompany;
	department?: NavigationDepartment;
};

const initialState: NavigationContextState = {};

const navigationContextSlice = createSlice({
	name: 'navigationContext',
	initialState,
	reducers: {
		setNavigationContext(state, action: PayloadAction<NavigationContextState>) {
			state = action.payload;

			return state;
		},
	},
});

export const {setNavigationContext} = navigationContextSlice.actions;
export default navigationContextSlice.reducer;
