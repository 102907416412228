import {
	type SmsAuthorizationResponse,
	type SmsAuthorizationRequest,
} from '../../../models/IAuth';
import {sendSmsAuthorization} from '../../../api/authApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const sendSmsAuthorizationAction = createActionTemplateAxios<
SmsAuthorizationResponse,
SmsAuthorizationRequest>(
	'auth/sendSmsAuthorization/action',
	sendSmsAuthorization,
	'no',
);

export default sendSmsAuthorizationAction;
