import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putCandidate} from '../../../api/candidatesApi';
import {type PutCandidateRequest, type PutCandidateResponse} from '../../../models/ICandidates';

const putCandidateAction = createActionTemplateAxios<
PutCandidateResponse,
PutCandidateRequest>(
	'candidates/putCandidate/action',
	putCandidate,
	'userToken',
);

export default putCandidateAction;
