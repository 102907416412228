import React, {type ReactNode} from 'react';
import styles from './CardTemplate.module.css';

type CardTemplateProps = {
	children: ReactNode;
	width?: string | number;
	height?: string | number;
	className?: string;
	padding?: string | number;
	styleType?: CardTemplateStyleType;
};

type CardTemplateStyleType = 'check' | 'employee' | 'default';

export const CardTemplate: React.FC<CardTemplateProps> = ({
	children,
	width,
	height,
	className,
	padding,
	styleType = 'default',
}) => (
	<div
		className={`${styles.card} ${styles[styleType]} ${className}`}
		style={{
			width,
			height,
			padding,
		}}
	>
		{children}
	</div>
);
