import React from 'react';
import styles from './Counter.module.css';
import {ChartIcon, SalesIcon} from '../../../../assets/icons';

type CounterProps = {
	count?: number | string;
	title: string;
};

const Counter: React.FC<CounterProps> = ({...props}) => (
	<div className={`${styles.container}`}>
		<div className={styles.content}>
			<div className={styles.title}>
				<SalesIcon className={styles['title-icon']} />
				<span>{props.title}</span>
			</div>
			<span className={styles.counter} title={props.count?.toString()}>
				{props.count}
			</span>
		</div>
		<ChartIcon className={styles.chart} />
	</div>
);

export default Counter;
