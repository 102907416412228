import React, {type ReactNode} from 'react';
import styles from './ModalSubCheck.module.css';
import {ModalWindow} from '../../index';
import socratLogoIcon from '../../../../assets/logo/socratLogo.svg';

type ModalConfirmationSubCheckProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	header: ReactNode;
	footerItems: ReactNode[];
	children: ReactNode;
	isLoading: boolean;
	loadingMessage: string;
	width?: string;
	minWidth?: string | number;
	height?: string;
};

const ModalConfirmationSubCheck: React.FC<ModalConfirmationSubCheckProps> = ({
	setVisible,
	visible,
	header,
	footerItems,
	isLoading,
	loadingMessage,
	children,
	width = '60%',
	minWidth,
	height,
}) => (
	<ModalWindow
		visible={visible}
		setVisible={setVisible}
		isLoading={isLoading}
		loadingMessage={loadingMessage}
		header={header}
		footerItems={[
			<div key='footer' className={styles.footer}>
				<div className={styles['footer-button']}>
					{footerItems}
				</div>
				<div className={styles.banner}>
					<div className={styles.logo}>
						<img src={socratLogoIcon} alt='logo' />
					</div>
					<span className={styles['text-1']}>
						Дополнительная проверка не гарантирует получение новых данных.
					</span>
					<p className={styles['text-2']}>
						При выполнении запроса с вас будут списаны деньги в соответствии с вашим тарифом
					</p>
				</div>
			</div>,
		]}
		closeOnOutsideClick
		width={width}
		minWidth={minWidth}
		height={height}
	>
		<div className={styles.wrapper}>
			{children}
		</div>
	</ModalWindow>
);

export default ModalConfirmationSubCheck;
