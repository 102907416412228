import React, {useEffect, useRef, useState} from 'react';
import styles from './TitleCardDepartment.module.css';
import {UserIcon} from '../../Div';
import {getFullName} from '../../../../utils';
import {TitleButton} from '../../Button';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {getDepartmentAction} from '../../../../redux/action/department';
import {type Department} from '../../../../models/Schemas';
import {getDepartmentResetState} from '../../../../redux/slice/department';
import Loader from '../../../Loader/Loader';
import {ErrorMessage} from '../../Message';
import {useEffectHandlerLoadingCurrentRequest} from '../../../../utils/hooks';

type TitleCardDepartmentProps = {
	departmentId?: number;
	departmentRead?: boolean;
	onClick: () => void;
	hiddenLoader?: boolean;
};

export const TitleCardDepartment: React.FC<TitleCardDepartmentProps> = ({
	departmentRead,
	onClick,
	departmentId,
	hiddenLoader,
}) => {
	const [departmentData, setDepartmentData] = useState<Department | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const getDepartment = useAppSelector(state => state.getDepartment);
	const getDepartmentRequestId = useRef<string | undefined>(undefined);
	const dispatch = useAppDispatch();

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	// Отобразить ошибку
	const showError = (message?: string) => {
		setIsError(true);
		setErrorMessage(message);
	};

	// Скрыть ошибку
	const hiddenError = () => {
		setIsError(false);
		setErrorMessage(undefined);
	};

	// Сброс состояний компонента
	const resetComponentStates = () => {
		hiddenError();
		hiddenLoading();

		getDepartmentRequestId.current = undefined;
		dispatch(getDepartmentResetState());
	};

	useEffect(() => {
		resetComponentStates();
		if (departmentId) {
			getDepartmentRequestId.current = dispatch(getDepartmentAction({departmentId})).requestId;
		}
	}, [departmentId]);

	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getDepartmentRequestId,
		state: getDepartment,
		onLoading() {
			showLoading('Загрузка отдела...');
			hiddenError();
		},
		onStopLoading: hiddenLoading,
		onComplete: setDepartmentData,
		onError: showError,
		onOtherRequestId(state) {
			if (state.responseStatus.isCompleted && state.response?.id === departmentId) {
				setDepartmentData(getDepartment.response);
			}
		},
	});

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<span>Мой отдел</span>
				{departmentRead
                    && <TitleButton onClick={onClick} text='Просмотреть мой отдел'/>
				}
			</div>

			<div className={styles['my-department']}>

				<div className={styles.info}>
					<span className={styles.abbreviation}>Отдел</span>
					<span className={styles.department}>{departmentData?.name}</span>
				</div>

				<div className={styles.info}>
					<span className={styles.abbreviation}>Руководитель</span>
					<div className={styles.user}>
						{departmentData?.director && (
							<>
								<UserIcon size='small' label={departmentData.director.last_name[0]}/>
								<span className={styles['user-name']}>
									{getFullName(
										departmentData.director.first_name,
										departmentData.director.middle_name,
										departmentData.director.last_name,
									)}
								</span>
							</>
						)}
					</div>
				</div>

				<div className={styles.info}>
					<span className={styles.abbreviation}>Описание</span>
					<span className={styles.text}>{departmentData?.description ?? ''}</span>
				</div>
			</div>
			{!hiddenLoader && isLoading && (
				<Loader
					isLoading={isLoading}
					text={loadingMessage}
					isFloating
					shading='full'
				/>
			)}
			<ErrorMessage isError={isError} message={errorMessage}/>
		</div>
	);
};
