import {putReportResolution} from '../../../../api/Report/resolutionApi';
import {type PutReportResolutionRequest, type PutReportResolutionResponse} from '../../../../models/Report/Resolution/IResolution';
import {createActionTemplateAxios} from '../../ActionTemplatesRequest';

const putReportResolutionAction = createActionTemplateAxios<
PutReportResolutionResponse,
PutReportResolutionRequest
>(
	'report/putResolution/action',
	putReportResolution,
	'userToken',
);

export default putReportResolutionAction;
