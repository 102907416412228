import React from 'react';
import styles from './SplitLine.module.css';
type SplitLineProps = {
	className?: string;
};

const SplitLine: React.FC<SplitLineProps> = ({className}) => (
	<div className={`${styles['split-line']} ${className ?? ''}`}></div>
);

export default SplitLine;
