import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {deleteEmployee} from '../../../api/employeesApi';
import {
	type DeleteEmployeeRequest,
	type DeleteEmployeeResponse,
} from '../../../models/IEmployees';

const deleteEmployeeAction = createActionTemplateAxios<
DeleteEmployeeResponse,
DeleteEmployeeRequest
>('employees/deleteEmployee/action', deleteEmployee, 'userToken');

export default deleteEmployeeAction;
