import React, {useEffect, useMemo, useRef, useState} from 'react';
import commonTableStyles from '../CommonTableStyles.module.css';
import {ModalForPositions} from '../../Modal';
import {type DepartmentPositionWithEmployee} from '../../../models/Schemas';
import {useNavigate, useParams} from 'react-router-dom';
import {paths} from '../../../paths';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {TableIconButton} from '../../UiKit/Button';
import {CiEdit} from 'react-icons/ci';
import {IoCloseSharp, IoAddSharp} from 'react-icons/io5';
import {
	postDepartmentPositionsCloseAction,
	postDepartmentPositionsOpenAction,
} from '../../../redux/action/department_positions';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import {
	postDepartmentPositionsCloseResetStatus,
	postDepartmentPositionsOpenResetStatus,
} from '../../../redux/slice/departmentPositions';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {type GetDepartmentPositionsProceedResponseItem} from '../../../redux/slice/department';
import {getDepartmentPositionsAction} from '../../../redux/action/department';

type PositionTableProps = {
	position?: GetDepartmentPositionsProceedResponseItem[];
	departmentId: number | undefined;
	isLading: boolean;
	positionUpdate: boolean;
	positionDelete: boolean;
	vacancyOpen: boolean;
	vacancyClose: boolean;
	isModalOpen: boolean;
};

const PositionTable: React.FC<PositionTableProps> = ({
	position,
	departmentId,
	isLading,
	positionUpdate,
	vacancyOpen,
	vacancyClose, isModalOpen,
}) => {
	const [visible, setVisible] = useState(false);
	const [selectPosition, setSelectPosition] = useState<DepartmentPositionWithEmployee>();

	const isLoadingCloseJob = useRef<Record<number, boolean>>({});
	const isLoadingOpenJob = useRef<Record<number, boolean>>({});

	const getUser = useAppSelector(state => state.getUser);
	const postDepartmentPositionsOpen = useAppSelector(state => state.postDepartmentPositionsOpen);
	const postDepartmentPositionsClose = useAppSelector(state => state.postDepartmentPositionsClose);
	const putDepartmentPositions = useAppSelector(state => state.putDepartmentPositions);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {id} = useParams();

	const handlerUpdatePosition = (position: GetDepartmentPositionsProceedResponseItem) => {
		setSelectPosition(position);
		setVisible(true);
	};

	const openEmployeeProfile = (id: number) => {
		navigate(paths.profileUser.replace(':id', id.toString()));
	};

	// Закрытие вакансии
	const closeJob = (positions_id: number) => {
		isLoadingCloseJob.current[positions_id] = true;
		isLoadingOpenJob.current[positions_id] = false;

		void dispatch(postDepartmentPositionsCloseAction({positions_id}));
	};

	// Открытие вакансии
	const openJob = (positions_id: number) => {
		isLoadingOpenJob.current[positions_id] = true;
		isLoadingCloseJob.current[positions_id] = false;
		void dispatch(postDepartmentPositionsOpenAction({positions_id}));
	};

	useEffect(() => {
		const loadingStatuses = [
			postDepartmentPositionsOpen.responseStatus.isLoading,
			postDepartmentPositionsClose.responseStatus.isLoading,
			putDepartmentPositions.responseStatus.isLoading,
		];

		const completedStatuses = [
			postDepartmentPositionsOpen.responseStatus.isCompleted,
			postDepartmentPositionsClose.responseStatus.isCompleted,
			putDepartmentPositions.responseStatus.isCompleted,
		];

		const isLoading = loadingStatuses.some(status => status);
		const isCompleted = completedStatuses.some(status => status);

		if (!isLoading && isCompleted) {
			if (id) {
				void dispatch(getDepartmentPositionsAction({departmentId: parseInt(id, 10)}));
			}
		}
	}, [
		postDepartmentPositionsOpen.responseStatus.isLoading,
		postDepartmentPositionsClose.responseStatus.isLoading,
		putDepartmentPositions.responseStatus.isLoading,
	]);

	// Параметры столбцов таблицы
	const columns: ColumnsProps[] = useMemo(() => (
		[
			{
				caption: 'Должность',
				dataField: 'name',
			},
			{
				caption: 'Кто занимает',
				dataField: 'fullNameEmployee',
				cellRender(rowData) {
					const {employee, fullNameEmployee, opened_at} = rowData.row.data as GetDepartmentPositionsProceedResponseItem;
					if (employee) {
						return (
							<div
								className={commonTableStyles['table-link']}
								onClick={() => {
									openEmployeeProfile(employee.id);
								}}
								data-orange={Boolean(opened_at)}
							>
								<span
									className={`${commonTableStyles['column-text']} ${employee?.user_id ? commonTableStyles['mint-text'] : ''}`}
								>
									{fullNameEmployee}
								</span>
								{getUser.user?.department_position?.employee_id === employee?.id
									&& (
										<span>{'(Вы)'}</span>
									)
								}
							</div>
						);
					}

					return (<div data-orange={Boolean(opened_at)} />);
				},
			},
			{
				type: 'buttons',
				width: 100,
				cellRender(rowData) {
					const position = rowData.row.data as GetDepartmentPositionsProceedResponseItem;
					return (
						<div className={commonTableStyles['column-action']}>
							{positionUpdate && (
								<TableIconButton
									onClick={() => {
										handlerUpdatePosition(position);
									}}
									Icon={CiEdit}
									TooltipMessage='Редактировать вакансию'
								/>
							)}
							{(position.opened_at
								? vacancyClose && (
									<TableIconButton
										onClick={() => {
											closeJob(position.id);
										}}
										Icon={IoCloseSharp}
										TooltipMessage='Закрыть вакансию'
										isLoading={
											isLoadingCloseJob.current[position.id]
										}
									/>
								)
								: vacancyOpen && (
									<TableIconButton
										onClick={() => {
											openJob(position.id);
										}}
										Icon={IoAddSharp}
										TooltipMessage='Открыть вакансию'
										isLoading={
											isLoadingOpenJob.current[position.id]
										}
									/>
								))}
						</div>
					);
				},
			},
		]
	), [position, getUser.user]);

	useEffect(() => {
		if (postDepartmentPositionsClose.responseStatus.isCompleted && postDepartmentPositionsClose.response) {
			isLoadingCloseJob.current[postDepartmentPositionsClose.response.id] = false;
			if (!(isModalOpen || visible)) {
				notifyMessage('success', 'Вакансия закрыта');
				dispatch(postDepartmentPositionsCloseResetStatus());
			}
		} else if (!(isModalOpen || visible) && postDepartmentPositionsClose.responseStatus.isError) {
			notifyMessage('error', 'Ошибка. Что-то пошло не так.');
		}
	}, [postDepartmentPositionsClose.responseStatus.isCompleted]);

	useEffect(() => {
		if (postDepartmentPositionsOpen.responseStatus.isCompleted && postDepartmentPositionsOpen.response) {
			isLoadingOpenJob.current[postDepartmentPositionsOpen.response.id] = false;
			if (!(isModalOpen || visible)) {
				notifyMessage('success', 'Вакансия открыта');
				dispatch(postDepartmentPositionsOpenResetStatus());
			}
		} else if (!(isModalOpen || visible) && postDepartmentPositionsOpen.responseStatus.isError) {
			notifyMessage('error', 'Ошибка. Что-то пошло не так.');
		}
	}, [postDepartmentPositionsOpen.responseStatus.isCompleted]);

	return (
		<>
			<Table
				columns={columns}
				isLoading={isLading}
				dataGridProps={{
					dataSource: position,
					allowColumnResizing: true,
					hoverStateEnabled: true,
					onRowDblClick(e) {
						handlerUpdatePosition(e.data as GetDepartmentPositionsProceedResponseItem);
					},
				}}
			/>
			<ModalForPositions
				visible={visible}
				setVisible={setVisible}
				position={selectPosition}
				departmentId={departmentId}
			/>
		</>
	);
};

export default PositionTable;
