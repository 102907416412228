import {verification} from '../../../api/authApi';
import {
	type Token,
	type VerificationRequest,
} from '../../../models/IAuth';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const verifyAction = createActionTemplateAxios<
Token,
VerificationRequest>(
	'auth/verify/action',
	verification,
	'no',
);

export default verifyAction;
