import {createSlice} from '@reduxjs/toolkit';
import {verifyAction} from '../../action/auth';
import {type ResponseStatus, type Token} from '../../../models/IAuth';
import {saveToken} from '../../../utils/cookies/token/saveToken';

type Verify = ResponseStatus & {token: Token | undefined};

const initialState: Verify = {
	isLoading: false,
	isCompleted: false,
	isError: false,
	errorMessage: '',
	token: undefined,
};

const verifySlice = createSlice({
	name: 'auth/verify',
	initialState,
	reducers: {
		verifyReset: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(verifyAction.pending, state => {
				state.isCompleted = false;
				state.isLoading = true;
				state.isError = false;
				state.errorMessage = '';
				state.token = undefined;
			})
			.addCase(verifyAction.fulfilled, (state, action) => {
				saveToken(action.payload);
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.token = action.payload;
			})
			.addCase(verifyAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.token = undefined;
			});
	},
});

export const {verifyReset} = verifySlice.actions;
export default verifySlice.reducer;
