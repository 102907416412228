import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getPermissionsRoles} from '../../../api/permissionsApi';
import {
	type GetPermissionsRolesRequest,
	type GetPermissionsRolesResponse,
} from '../../../models/IPermissions';

const getPermissionsRolesAction = createActionTemplateAxios<
GetPermissionsRolesResponse,
GetPermissionsRolesRequest
>('permissions/getPermissionsRoles/action', getPermissionsRoles, 'userToken');

export default getPermissionsRolesAction;
