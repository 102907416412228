import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {postInteractiveScreenGoAction} from '../../action/Interactive';
import {type PostInteractiveScreenGoResponse} from '../../../models/IInteractive';

const initialState: {
	responseStatus: ResponseStatus;
	response: PostInteractiveScreenGoResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const postInteractiveScreenGoSlice = createSlice({
	name: 'interactive/postInteractiveScreenGo',
	initialState,
	reducers: {
		postInteractiveScreenGoResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postInteractiveScreenGoAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postInteractiveScreenGoAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(postInteractiveScreenGoAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {postInteractiveScreenGoResetState} = postInteractiveScreenGoSlice.actions;
export default postInteractiveScreenGoSlice.reducer;
