import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostContactResponse} from '../../../models/IContacts';
import {postContactAction} from '../../action/contacts';

type postContactSliceState = {
	responseStatus: ResponseStatus;
	response: PostContactResponse | undefined;
};

const initialState: postContactSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postContactSlice = createSlice({
	name: 'contacts/postContact',
	initialState,
	reducers: {
		postContactSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postContactAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postContactAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postContactAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postContactSliceResetState} = postContactSlice.actions;
export default postContactSlice.reducer;
