import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {getAddressFillAction} from '../../action/dadata';
import {type GetAddressFillResponse} from '../../../models/Dadata/Address/IDadataAddress';

type GetNameFillSliceState = {
	responseStatus: ResponseStatus;
	response: GetAddressFillResponse;
};

const initialState: GetNameFillSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
		requestId: undefined,
	},
	response: {
		suggestions: [],
	},
};

const getAddressFillSlice = createSlice({
	name: 'dadata/getAddressFill',
	initialState,
	reducers: {
		getAddressFillResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getAddressFillAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = undefined;
			})
			.addCase(getAddressFillAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getAddressFillAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
				state.responseStatus.requestId = action.meta.requestId;
			});
	},
});

export const {getAddressFillResetState} = getAddressFillSlice.actions;
export default getAddressFillSlice.reducer;
