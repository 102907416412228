import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {authNoActivatedUserAction} from '../../redux/action/auth';
import {type PostAuthNoActivatedUserRequest} from '../../models/IAuth';
import {paths} from '../../paths';

export const AuthNoActivatedUserPage = () => {
	const {id, hash} = useParams();
	const navigate = useNavigate();
	const [cookies] = useCookies([
		'debug',
	]);
	const {isLoading, isCompleted, isError} = useAppSelector(
		state => state.authNoActivatedUser,
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (id && hash) {
			const data: PostAuthNoActivatedUserRequest = {
				user_id: Number(id),
				hash,
				debug: Boolean(cookies.debug),
			};
			void dispatch(authNoActivatedUserAction(data));
		}
	}, []);

	useEffect(() => {
		if (isCompleted) {
			navigate(paths.registration);
		}
	}, [isLoading]);
	return <div></div>;
};
