import axios, {type AxiosResponse} from 'axios';
import {
	type GetChallengerRequest,
	type GetChallengerResponse,
	type PostChallengersRequest,
	type PostChallengersResponse,
	type PutChallengerRequest,
	type PutChallengerResponse,
} from '../models/IChallengers';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/challengers';

export async function postChallengers(
	data: PostChallengersRequest,
): Promise<AxiosResponse<PostChallengersResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function putChallenger(
	data: PutChallengerRequest,
): Promise<AxiosResponse<PutChallengerResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.challenger_id}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Используется как get
export async function getChallenger(
	data: GetChallengerRequest,
): Promise<AxiosResponse<GetChallengerResponse>> {
	return axios.post(`${domain}/api/challenger`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
