import React, {type ReactNode, useCallback, useRef, useState} from 'react';
import styles from './MenuButton.module.css';
import {DropDownBox, TextBox} from 'devextreme-react';

type MenuButtonProps = {
	contentRender: (ref?: React.RefObject<DropDownBox>) => ReactNode;
	buttonRender?: (isOpen: boolean) => ReactNode;
	visible?: boolean;
};

const fieldRender = () => (<TextBox className='dx-hidden' />);

const MenuButton: React.FC<MenuButtonProps> = ({
	contentRender,
	buttonRender,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<DropDownBox>(null);
	const dropDownButtonRender = useCallback(() => {
		if (buttonRender) {
			return buttonRender(isOpen);
		}
	}, [isOpen, buttonRender]);

	return (
		<>
			<DropDownBox
				contentRender={() => contentRender(ref)}
				fieldRender={fieldRender}
				dropDownButtonRender={dropDownButtonRender}
				onOpened={() => {
					setIsOpen(true);
				}}
				onClosed={() => {
					setIsOpen(false);
				}}
				stylingMode='filled'
				className={styles['drop-down-box']}
				dropDownOptions={{
					width: 'max-content',
					maxWidth: '90dvw',
					wrapperAttr: {
						class: styles['popup-wrapper'],
					},
				}}
				ref={ref}
				{...props}
			/>
		</>
	);
};

export default MenuButton;
