export function checKAdditionalValidation(key: string, errors: Record<string, string>): [boolean, string] {
	if (errors) {
		if (key === 'passport_series' || key === 'passport_number') {
			key = 'passport';
		}

		const error = errors[key];
		if (error) {
			return [false, error[0]];
		}
	}

	return [true, ''];
}
