import React, {useRef, useState, useEffect} from 'react';
import styles from '../ModalConfirmationEmail/ModalConfirmationEmail.module.css';
import {useAppDispatch, useAppSelector} from '../../../../../redux/redux-hooks';
import postCheckCreateAddressAction from '../../../../../redux/action/checks/postCheckCreateAddressAction';
import {notifyMessage} from '../../../../UiKit/Toast/notifyMessage';
import {postCheckCreateAddressSliceResetState} from '../../../../../redux/slice/checks';
import ModalConfirmationSubCheck from '../ModalConfirmationSubCheck';
import {ButtonConfirm} from '../../../../UiKit/Button';
import {type CheckAddresses} from '../../../../../report/ReportModel';

type ModalConfirmationAddressProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	address: CheckAddresses | undefined;
	containerId: string | undefined;
};

const ModalConfirmationAddress: React.FC<ModalConfirmationAddressProps> = ({address, visible, setVisible, containerId}) => {
	const [isLoading, setIsLoading] = useState(false);
	const postCheckCreateAddressRequestId = useRef<string | undefined>(undefined);

	const getUser = useAppSelector(state => state.getUser);
	const postCheckCreateAddress = useAppSelector(state => state.postCheckCreateAddress);
	const getCheckReportById = useAppSelector(state => state.getCheckReportById);

	const dispatch = useAppDispatch();

	const sendPostCheckCreateAddress = () => {
		const parent_check_id = getCheckReportById.response?.id;
		const author_id = getUser.user?.id;
		const addressFinalResult = address?.result?.trim();

		if (addressFinalResult && parent_check_id && author_id && containerId) {
			const employee_id = getCheckReportById.response?.employee?.id ?? null;
			const candidate_id = getCheckReportById.response?.candidate?.id ?? null;
			const person_id = getCheckReportById.response?.person?.id ?? null;

			postCheckCreateAddressRequestId.current = dispatch(postCheckCreateAddressAction({
				employee_id,
				candidate_id,
				person_id,
				author_id,
				check_type_id: null,
				parent_check_id,
				address: addressFinalResult,
				container_id: containerId,
			})).requestId;
		}
	};

	useEffect(() => {
		if (postCheckCreateAddressRequestId
			&& postCheckCreateAddressRequestId.current === postCheckCreateAddress.responseStatus.requestId) {
			if (postCheckCreateAddress.responseStatus.isCompleted) {
				notifyMessage('success', 'Дополнительная проверка успешно запущена.');
				setVisible(false);
			} else if (postCheckCreateAddress.responseStatus.isLoading) {
				setIsLoading(true);
			} else if (postCheckCreateAddress.responseStatus.isError) {
				notifyMessage('error', postCheckCreateAddress.responseStatus.errorMessage);
				setVisible(false);
			}
		}
	}, [postCheckCreateAddress.responseStatus.isLoading]);

	useEffect(() => {
		dispatch(postCheckCreateAddressSliceResetState());
		postCheckCreateAddressRequestId.current = undefined;

		if (!visible) {
			setIsLoading(false);
		}
	}, [visible]);

	return (
		<ModalConfirmationSubCheck
			visible={visible}
			setVisible={setVisible}
			header={
				<span className={styles.header}>
					Запуск дополнительной проверки по адресу
				</span>
			}
			footerItems={[
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={sendPostCheckCreateAddress}
					text={'Подтвердить проверку'}
				/>,
			]}
			isLoading={isLoading}
			loadingMessage='Запуск дополнительной проверки'
			width='50%'
			minWidth={400}
		>
			<div>
				{address && (
					<div className={styles.container}>
						<span>
							Запустить запрос на проверку по адресу <span className={styles.result}>{address.result}</span>?
						</span>
					</div>
				)}
			</div>
		</ModalConfirmationSubCheck>
	);
};

export default ModalConfirmationAddress;
