import React, {useEffect, useState} from 'react';
import styles from './CardProfile.module.css';
import {HiOutlinePencilAlt} from 'react-icons/hi';
import {TiUserDelete} from 'react-icons/ti';
import {components} from './ComponentsProfile';
import {type ComponentsProfile} from './ComponentsProfile';
import ModalEditingProfile from '../Modal/ModalEditingProfile';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {
	deleteEmployeeAction,
	getEmployeeAction,
} from '../../redux/action/employees';
import Loader from '../Loader/Loader';
import ModalEditingEmployee from '../Modal/ModalEditingEmployee';
import {getUserAction, getUserAuthHistoryAction, getUserByIdAction} from '../../redux/action/users';
import {ModalConfirmation} from '../Modal';
import {getFullName} from '../../utils';
import {getEmployeesResetState} from '../../redux/slice/employees';
import {typePermissions} from '../../models/Enum';
import {getUserAuthHistoryResetState, resetGetUserByIdState} from '../../redux/slice/users';
import {UserIcon} from '../UiKit/Div';
import {checkPermissionProfile} from '../../utils/permissions/permissions';
import {paths} from '../../paths';
import {notifyMessage} from '../UiKit/Toast/notifyMessage';

export const CardProfile = () => {
	const {id} = useParams();

	const [activeItem, setActiveItem] = useState<ComponentsProfile | undefined>(components[0]);
	const [availableComponents, setAvailableComponents] = useState<ComponentsProfile[] | undefined>(undefined);

	const [visible, setVisible] = useState(false);
	const [isOpenDeleteEmployee, setIsOpenDeleteEmployee] = useState(false);
	const [fullName, setFullName] = useState('');
	const [lastAuth, setLastAuth] = useState('');

	const getPermissions = useAppSelector(state => state.getPermissions);
	const getEmployeeById = useAppSelector(state => state.getEmployee);
	const getUserById = useAppSelector(state => state.getUserById);
	const getUser = useAppSelector(state => state.getUser);
	const getUserAuthHistory = useAppSelector(state => state.getUserAuthHistory);
	const deleteEmployee = useAppSelector(state => state.deleteEmployee);
	const putEmployee = useAppSelector(state => state.putEmployee);

	const [employeeRead, setEmployeeRead] = useState(false);
	const [employeeDelete, setEmployeeDelete] = useState(false);
	const [employeeUpdate, setEmployeeUpdate] = useState(false);

	const [userRead, setUserRead] = useState(false);
	const [userDelete, setUserDelete] = useState(false);
	const [userUpdate, setUserUpdate] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// Вкладки
	const handleItemClick = (component: ComponentsProfile) => {
		setActiveItem(component);
	};

	// Удаление
	const conformationDelete = () => {
		if (id) {
			void dispatch(deleteEmployeeAction({employeeId: parseInt(id, 10)}));
		}
	};

	// Проверка прав
	const checkPermissions = () => {
		const {groupPermissions} = getPermissions;
		const isMe = id === undefined;
		const isMyDepartment = isMe || getEmployeeById.response?.department_id === getUser.user?.department?.id;

		setUserUpdate(checkPermissionProfile(typePermissions.user_update, groupPermissions, isMe, isMyDepartment));
		setEmployeeUpdate(checkPermissionProfile(typePermissions.employee_update, groupPermissions, isMe, isMyDepartment));
		setUserDelete(checkPermissionProfile(typePermissions.user_archive, groupPermissions, isMe, isMyDepartment));
		setEmployeeDelete(checkPermissionProfile(typePermissions.employee_archive, groupPermissions, isMe, isMyDepartment));
	};

	// Изменение id
	useEffect(() => {
		checkPermissions();

		setFullName('');
		setLastAuth('');

		void dispatch(getUserAuthHistoryResetState());
		void dispatch(getEmployeesResetState());
		void dispatch(resetGetUserByIdState());

		if (id === undefined) {
			// Мой профиль
			void dispatch(getUserAction());
		} else if (parseInt(id, 10) === getUser.user?.employee_id) {
			// Мой id
			navigate(paths.profile);
		} else {
			// Чужой id
			void dispatch(getEmployeeAction({employeeId: parseInt(id, 10)}));
		}
	}, [id]);

	// Изменение прав
	useEffect(() => {
		if (getPermissions.responseStatus.isCompleted) {
			checkPermissions();
		}
	}, [getPermissions.responseStatus.isLoading]);

	// Получение пользователя или сотрудника
	useEffect(() => {
		if (id === undefined) {
			if (getUser.isCompleted && getUser.user) {
				void dispatch(getUserAuthHistoryAction({user_id: getUser.user.id}));
				setFullName(
					getFullName(
						getUser.user?.first_name,
						getUser.user?.middle_name,
						getUser.user?.last_name,
						'LFM',
					),
				);
			}
		} else if (
			getEmployeeById.responseStatus.isCompleted
      && getEmployeeById.response
		) {
			if (
				getEmployeeById.response.user_id === null
				|| getEmployeeById.response.user_id === undefined
			) {
				setLastAuth('');
			} else {
				void dispatch(getUserByIdAction({userId: getEmployeeById.response.user_id}));
				void dispatch(getUserAuthHistoryAction({user_id: getEmployeeById.response.user_id}));
			}

			setFullName(
				getFullName(
					getEmployeeById.response?.first_name,
					getEmployeeById.response?.middle_name,
					getEmployeeById.response?.last_name,
					'LFM',
				),
			);
		}
	}, [getEmployeeById.response, getUser.user]);

	// Получение последней авторизации
	useEffect(() => {
		if (
			getUserAuthHistory.responseStatus.isCompleted
      && getUserAuthHistory.lastAuth
		) {
			setLastAuth(`Последняя авторизация: ${getUserAuthHistory.lastAuth}`);
		}
	}, [getUserAuthHistory.responseStatus.isLoading]);

	// Удаление сотрудника
	useEffect(() => {
		if (deleteEmployee.responseStatus.isCompleted) {
			setIsOpenDeleteEmployee(false);
			navigate(-1);
			notifyMessage('success', getEmployeeById.response?.user_id
				? 'Пользователь успешно удален'
				: 'Сотрудник успешно удален');
		} else if (deleteEmployee.responseStatus.isError) {
			notifyMessage('error', 'Ошибка. Что-то пошло не так.');
		}
	}, [deleteEmployee.responseStatus.isLoading]);

	// Переключение вкладок
	useEffect(() => {
		if (id) {
			if (
				getUser.user?.department?.director?.id === getUser.user?.employee_id
				&& getUserById.response?.id
			) {
				setAvailableComponents(components);
			} else {
				setAvailableComponents(
					components.filter(item => item.name !== 'История входа'),
				);
			}
		} else {
			setAvailableComponents(components);
		}
	}, [components]);

	// Обновление сотрудника
	useEffect(() => {
		if (!visible && id) {
			void dispatch(getEmployeeAction({employeeId: parseInt(id, 10)}));
		}
	}, [visible]);

	if (getEmployeeById.responseStatus.isError && !visible) {
		return <p>{getEmployeeById.responseStatus.errorMessage}</p>;
	}

	if (getUser.isError && !visible) {
		return <p>{getUser.errorMessage}</p>;
	}

	return (
		<>
			<div className={styles.content}>
				<span className='h1-text'>Профиль</span>
				<div className={styles.card}>
					<div>
						<div className={styles['profile-group']}>
							<div className={styles.profile}>
								<UserIcon label={fullName[0]} round/>
								<div className={styles['profile-info']}>
									<span>{fullName}</span>
									<span>{lastAuth}</span>
								</div>
							</div>
							<div>
								{((id && employeeUpdate) ?? (!id && userUpdate))
								&& <div
									className={styles.action}
									onClick={() => {
										setVisible(true);
									}}
								>
									<span>Редактировать</span>
									<HiOutlinePencilAlt />
								</div>}
								{id && employeeDelete && <div
									className={`${styles.action} ${styles['action-delete']}`}
									onClick={() => {
										setIsOpenDeleteEmployee(true);
									}}
								>
									<span>Удалить сотрудника</span>
									<TiUserDelete />
								</div>}
							</div>
						</div>
						<ul className={styles.nav}>
							{availableComponents?.map((item: ComponentsProfile) => (
								<li
									key={item.name}
									className={`${styles['nav-item']} ${item === activeItem ? styles.active : ''}`}
									onClick={() => {
										handleItemClick(item);
									}}
								>
									{item.name}
								</li>
							))}
						</ul>
					</div>
					<div className={styles['tabs-container']}>
						{activeItem && React.createElement(activeItem.component)}
					</div>
				</div>
				<Loader
					text='Получение данных о сотруднике...'
					isFloating
					isLoading={
						getEmployeeById.responseStatus.isLoading
						|| getUserById.responseStatus.isLoading
					}
				/>
				<Loader
					text='Получение данных о пользователе...'
					isFloating
					isLoading={getUser.isLoading}
				/>
			</div>
			{id ? (
				<>
					<ModalEditingEmployee
						visible={visible}
						setVisible={setVisible}
						employeeId={parseInt(id, 10)}
					/>
					<ModalConfirmation
						visible={isOpenDeleteEmployee}
						setVisible={setIsOpenDeleteEmployee}
						confirmation={conformationDelete}
						confirmationText={'Удалить сотрудника'}
						cancelText={'Отмена'}
						ConfirmationByInput={fullName}
						title='Удаление сотрудника'
						errorMessage={deleteEmployee.responseStatus.errorMessage}
						isError={deleteEmployee.responseStatus.isError}
						isLoading={deleteEmployee.responseStatus.isLoading}
						loadingMessage='Удаление сотрудника'
					/>
				</>
			) : (
				<ModalEditingProfile setVisible={setVisible} visible={visible} />
			)}
		</>
	);
};
