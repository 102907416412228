import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {postGuestOtpSendAction} from '../../action/guest';
import {type PostGuestOtpSendResponse} from '../../../models/Guest/Guest/IGuest';

const initialState: {
	responseStatus: ResponseStatus;
	response: PostGuestOtpSendResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const postGuestOtpSendSlice = createSlice({
	name: 'guest/postGuestOtpSend',
	initialState,
	reducers: {
		postGuestOtpSendResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postGuestOtpSendAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postGuestOtpSendAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(postGuestOtpSendAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {postGuestOtpSendResetState} = postGuestOtpSendSlice.actions;
export default postGuestOtpSendSlice.reducer;
