import React from 'react';
import styles from '../../../ReportCard.module.css';
import {type ReportModelV3PassportRf} from '../../../../../../../../report/reportModelV3/reportModelV3';
import {CopyToClipboardButton} from '../../../../../../Button';
import {SelectCheckButton} from '../../../../../../Button/ReportButtons';

type PassportCardV3Props = {
	passport: ReportModelV3PassportRf;
	onClick: () => void;
	onClickSub: () => void;
	containerId: string | undefined;
};

export const PassportCardV3: React.FC<PassportCardV3Props> = ({
	passport,
	onClick,
	onClickSub,
	containerId,
}) => (
	<div className={`${styles.card} ${styles.click}`} onClick={onClick}>
		<div className={styles.header}>
			<span>Паспорт</span>
			<span>{passport.full_name}</span>
		</div>
		<div className={styles.content}>
			<div className={styles.info}>
				<span className={styles.document}>
					{passport.passport}
				</span>
				{passport.passport && <CopyToClipboardButton text={passport.passport}/>}
				<SelectCheckButton containerId={containerId} openSubCheck={onClickSub}/>
			</div>
		</div>
	</div>
);
