import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {putOfficialPermissions} from '../../../api/permissionsApi';
import {
	type PutOfficialPermissionsResponse,
	type PutOfficialPermissionsRequest,
} from '../../../models/IPermissions';

const putOfficialPermissionsAction = createActionTemplateAxios<
PutOfficialPermissionsResponse,
PutOfficialPermissionsRequest
>('permissions/putOfficialPermissions/action', putOfficialPermissions, 'userToken');

export default putOfficialPermissionsAction;
