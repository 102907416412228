import React, {useEffect, useState} from 'react';
import styles from './DepartmentPositions.module.css';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {
	getDepartmentAction,
	getDepartmentEmployeesAction,
	getDepartmentPositionsAction,
	getDepartmentSubordinateAction,
} from '../../redux/action/department';
import {useNavigate, useParams} from 'react-router-dom';
import {paths} from '../../paths';
import {
	GreenBorderButton,
	ArrowButton,
	DefaultButton,
} from '../../components/UiKit/Button';
import PositionTable from '../../components/Tables/PositionTable/PositionTable';
import EmployeesTable from '../../components/Tables/EmployeesTable/EmployeesTable';
import {ModalForPositions} from '../../components/Modal';
import {getFullName} from '../../utils';
import Loader from '../../components/Loader/Loader';
import ModalAddDepartment from '../../components/Modal/ModalAddDepartment';
import {type Department} from '../../models/Schemas';
import {typePermissions} from '../../models/Enum';
import {ErrorMessage} from '../../components/UiKit/Message';
import {getGroupPermission} from '../../utils';

export const DepartmentPositions = () => {
	const [visible, setVisible] = useState(false);
	const [isOpenEditDepartment, setIsOpenEditDepartment] = useState(false);
	const [departmentId, setDepartmentId] = useState<number | undefined>(undefined);
	const [department, setDepartment] = useState<Department | undefined>(undefined);

	const [departmentRead, setDepartmentRead] = useState(false);
	const [departmentUpdate, setDepartmentUpdate] = useState(false);
	const [positionsRead, setPositionsRead] = useState(false);
	const [positionUpdate, setPositionUpdate] = useState(false);
	const [positionDelete, setPositionDelete] = useState(false);
	const [positionCreate, setPositionCreate] = useState(false);
	const [vacancyOpen, setVacancyOpen] = useState(false);
	const [vacancyClose, setVacancyClose] = useState(false);
	const [employeeRead, setEmployeeRead] = useState(false);
	const [departmentEmployeesRead, setDepartmentEmployeesRead] = useState(false);
	const [isCheckRequestCreate, setIsCheckRequestCreate] = useState(false);
	const [isZeroReportCreate, setIsZeroReportCreate] = useState(false);
	const [isSubordinateDepartment, setIsSubordinateDepartment] = useState(false);
	const [isCheckUser, setIsCheckUser] = useState(false);
	const [isCheckEmployee, setIsCheckEmployee] = useState(false);
	const [checkStart, setCheckStart] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');

	const dispatch = useAppDispatch();
	const getDepartmentPositions = useAppSelector(state => state.getDepartmentPositions);
	const getDepartmentEmployees = useAppSelector(state => state.getDepartmentEmployees);
	const putDepartment = useAppSelector(state => state.putDepartment);
	const getUser = useAppSelector(state => state.getUser);
	const getDepartmentsSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const getDepartment = useAppSelector(state => state.getDepartment);

	const {id} = useParams();
	const navigate = useNavigate();

	// Переход к подчиненным отделам
	const back = () => {
		navigate(paths.departments);
	};

	// Открытие модальное окно добавить позицию
	const HandlerAddPositions = () => {
		setVisible(true);
	};

	// Получение полного имени
	const getDirectorFullName = () => {
		const director = department?.director;
		if (director) {
			return getFullName(
				director.first_name,
				director.middle_name,
				director.last_name,
				'LFM',
			);
		}
	};

	// Запрос данных об отделе
	const dispatchDepartment = () => {
		if (departmentId) {
			void dispatch(getDepartmentAction({departmentId}));
			void dispatch(getDepartmentPositionsAction({departmentId}));
			void dispatch(getDepartmentEmployeesAction({departmentId}));
		}
	};

	// Установка права
	const setPermission = (permissionType: string, set: React.Dispatch<React.SetStateAction<boolean>>) => {
		const permission = getGroupPermission(permissionType, getPermissions.groupPermissions);
		if (permission?.permit) {
			if (permission?.permission_zone === 'all') {
				set(true);
			} else if (permission?.permission_zone === 'only_my_department') {
				set(departmentId === getUser.user?.department?.id);
			} else if (permission?.permission_zone === 'only_owner') {
				set(isSubordinateDepartment);
			}
		} else {
			set(false);
		}
	};

	// Установка прав
	const setPermissions = () => {
		setPermission(typePermissions.vacancy_open, setVacancyOpen);
		setPermission(typePermissions.vacancy_close, setVacancyClose);
		setPermission(typePermissions.position_update, setPositionUpdate);
		setPermission(typePermissions.position_delete, setPositionDelete);
		setPermission(typePermissions.position_create, setPositionCreate);
		setPermission(typePermissions.employee_read, setEmployeeRead);
		setPermission(typePermissions.department_delete, setDepartmentUpdate);
		setPermission(typePermissions.create_check_request, setIsCheckRequestCreate);
		setPermission(typePermissions.zero_report_create, setIsZeroReportCreate);
		setPermission(typePermissions.user_check, setIsCheckUser);
		setPermission(typePermissions.employee_check, setIsCheckEmployee);
		setPermission(typePermissions.department_employees_read, setDepartmentEmployeesRead);
		setPermission(typePermissions.positions_read, setPositionsRead);
		setPermission(typePermissions.check_start, setCheckStart);
	};

	const getContainerClassNames = () => {
		const classNames = styles.container;
		if (!departmentEmployeesRead && (positionsRead || positionCreate)) {
			return classNames.concat(' ', styles['grid-only-positions']);
		}

		if (!(positionsRead || positionCreate) && departmentEmployeesRead) {
			return classNames.concat(' ', styles['grid-only-employees']);
		}

		return classNames;
	};

	// Есть права на чтение отдела
	const accessAllowed = () => {
		setDepartmentRead(true);
		setPermissions();
		dispatchDepartment();
		setIsLoading(false);
		setLoadingMessage('');
	};

	// Нет прав на чтение отдела
	const accessDenied = () => {
		setIsLoading(false);
		setLoadingMessage('');
		setDepartmentRead(false);
		back();
	};

	// Проверка прав на доступ к отделу
	const checkPermissions = () => {
		if (departmentId !== undefined && getPermissions.response) {
			setLoadingMessage('Проверка доступа...');
			const departmentRead = getGroupPermission(typePermissions.department_read, getPermissions.groupPermissions);

			if (departmentRead?.permit) {
				if (departmentRead.permission_zone === 'only_my_department') {
					if (departmentId === getUser.user?.department?.id) {
						accessAllowed();
					} else {
						accessDenied();
					}
				} else if (departmentRead.permission_zone === 'only_owner') {
					if (isSubordinateDepartment) {
						accessAllowed();
					} else {
						accessDenied();
					}
				} else if (departmentRead?.permission_zone === 'all') {
					accessAllowed();
				} else {
					accessDenied();
				}
			} else {
				accessDenied();
			}
		}
	};

	// Открытие модального окна обновления отдела
	const openEditDepartment = () => {
		setIsOpenEditDepartment(true);
	};

	// Получение id
	useEffect(() => {
		if (id) {
			void dispatch(getDepartmentSubordinateAction());
			setDepartmentId(parseInt(id, 10));
		}
	}, []);

	// Получение данных об отделе
	useEffect(() => {
		if (!isOpenEditDepartment) {
			setIsLoading(true);
			setLoadingMessage('Получение данных...');
			if (
				getPermissions.responseStatus.isCompleted
				&& getUser.isCompleted
				&& getDepartmentsSubordinate.responseStatus.isCompleted
				&& departmentId !== undefined
			) {
				setIsSubordinateDepartment(Boolean(
					getDepartmentsSubordinate.response?.find(item => item.id === departmentId)));
				checkPermissions();
			}
		}
	}, [getPermissions.responseStatus.isLoading,
		getUser.isLoading,
		getDepartmentsSubordinate.responseStatus.isLoading,
		departmentId]);

	// Обработка получения отдела
	useEffect(() => {
		if (getDepartment.responseStatus.isCompleted) {
			setDepartment(getDepartment.response);
		}
	}, [getDepartment.responseStatus.isLoading]);

	useEffect(() => {
		if (departmentId && departmentRead && putDepartment.responseStatus.isCompleted) {
			void dispatch(getDepartmentAction({departmentId}));
		}
	}, [putDepartment.responseStatus.isLoading]);

	return (
		<div className={getContainerClassNames()}>
			<Loader isFloating isLoading={isLoading} text={loadingMessage} />
			<div className={styles['container-department']}>
				<div className={styles.header}>
					<ArrowButton
						text={department?.name}
						onclickHandler={back}
						textStyle='h1-text'
					/>
					{departmentUpdate && (
						<DefaultButton
							onClick={openEditDepartment}
							text={'Изменить отдел'}
							styleType='medium'
						/>
					)}
				</div>
				<div className={styles.department}>
					<section>
						<label>Директор</label>
						<span>{getDirectorFullName()}</span>
					</section>
					<section>
						<label>Описание</label>
						<span>{department?.description}</span>
					</section>
				</div>
				<ErrorMessage
					isError={getDepartment.responseStatus.isError}
					message={getDepartment.responseStatus.errorMessage}
				/>
				<Loader
					isLoading={getDepartment.responseStatus.isLoading && !isOpenEditDepartment}
					isFloating
					text='Получение информации об отделе...'
				/>
			</div>
			{departmentEmployeesRead && (
				<div className={`${styles['table-container']} ${styles.employees}`}>
					<span className={`h1-text ${styles.title}`}>Сотрудники</span>
					<div className={styles.table}>
						<EmployeesTable
							employees={getDepartmentEmployees.response}
							isLoading={getDepartmentEmployees.responseStatus.isLoading}
							employeeRead={employeeRead}
							isCheckRequestCreate={isCheckRequestCreate}
							isZeroReportCreate={isZeroReportCreate}
							isCheckUser={isCheckUser}
							isCheckEmployee={isCheckEmployee}
							checkStart={checkStart}
						/>
					</div>
				</div>
			)}
			{(positionsRead || positionCreate) && (
				<div className={`${styles['table-container']} ${styles.positions}`}>
					<div className={styles['title-group']}>
						<span className={`h1-text ${styles.title}`}>Должности</span>
						{positionCreate && (
							<GreenBorderButton
								text={'Добавить должность'}
								onClick={HandlerAddPositions}
								styleType='medium'
							/>
						)}
					</div>
					{positionsRead && (
						<div className={styles.table}>
							<PositionTable
								position={getDepartmentPositions.response}
								departmentId={getDepartment.response?.id}
								isLading={getDepartmentPositions.responseStatus.isLoading}
								positionUpdate={positionUpdate}
								positionDelete={positionDelete}
								vacancyOpen={vacancyOpen}
								vacancyClose={vacancyClose}
								isModalOpen={visible}
							/>
						</div>
					)}
				</div>
			)}
			<ModalForPositions
				visible={visible}
				setVisible={setVisible}
				departmentId={department?.id}
			/>
			<ModalAddDepartment
				visible={isOpenEditDepartment}
				setVisible={setIsOpenEditDepartment}
				departmentId={department?.id}
			/>
		</div>
	);
};
