import {postCheckUpdate} from '../../../api/checkApi';
import {
	type PostCheckUpdateRequest,
	type PostCheckUpdateResponse,
} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckUpdateAction = createActionTemplateAxios<
PostCheckUpdateResponse,
PostCheckUpdateRequest>(
	'checks/postCheckUpdate/action',
	postCheckUpdate,
	'userToken',
);

export default postCheckUpdateAction;
