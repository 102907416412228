import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postDepartmentPositionsClose} from '../../../api/departmentPositionsApi';
import {
	type PostDepartmentPositionsCloseResponse,
	type PostDepartmentPositionsCloseRequest,
} from '../../../models/IDepartmentPositions';

const postDepartmentPositionsCloseAction = createActionTemplateAxios<
PostDepartmentPositionsCloseResponse,
PostDepartmentPositionsCloseRequest
>(
	'departmentPositions/postDepartmentPositionsClose/action',
	postDepartmentPositionsClose,
	'userToken',
);

export default postDepartmentPositionsCloseAction;
