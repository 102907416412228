import React from 'react';
import styles from './PossibleNames.module.css';
import {HiOutlineUser} from 'react-icons/hi';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {ReportBlockTemplate} from '..';

export const PossibleNames = () => {
	const nameHistory = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.name_history);

	return (
		<ReportBlockTemplate
			blockIcon={HiOutlineUser}
			blockTitle='Возможные имена'
			alerts={nameHistory?.additionalData.alerts}
			blocksId='name_history'
			visible={nameHistory && nameHistory.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles.names}>
					<div className={styles.info}>
						<p>ФИО:
							<div className={styles['info-group']}>
								{nameHistory?.data?.map(({item}, index) => (
									<span key={index}>{item.nameHistoryFullName}</span>
								))}
							</div>
						</p>
					</div>
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
