import React from 'react';
import {InputAutocomplete, type InputAutocompleteCommonProps} from '../..';
import {useAppSelector, useAppDispatch} from '../../../../../redux/redux-hooks';
import {getNameFillAction} from '../../../../../redux/action/dadata';
import {type NameParts} from '../../../../../models/Dadata/IDadata';

type InputNameProps = {
	value: string | undefined;
	setValue: (value: string) => void;
	parts?: NameParts[];
} & InputAutocompleteCommonProps;

const InputName: React.FC<InputNameProps> = ({
	value,
	setValue,
	parts,
	...props
}) => {
	const getNameFill = useAppSelector(state => state.getNameFill);
	const dispatch = useAppDispatch();

	const requestAutocompleteData = () => {
		if (value && value.length > 1) {
			return dispatch(getNameFillAction({
				value,
				parts,
			})).requestId;
		}
	};

	return (
		<InputAutocomplete
			value={value}
			setValue={setValue}
			requestAutocompleteData={requestAutocompleteData}
			responseAutocompleteData={getNameFill.response.suggestions}
			responseStatus={getNameFill.responseStatus}
			{...props}
		/>
	);
};

export default InputName;
