import React, {useState, useEffect, useRef} from 'react';
import styles from './OtpInput.module.css';

type OtpInputProps = {
	length: number;
	onComplete?: (otp: string) => void;
	onEditOtp?: (otp: string | undefined) => void;
};

const OtpInput: React.FC<OtpInputProps> = ({
	length,
	onComplete,
	onEditOtp,
}) => {
	const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
	// eslint-disable-next-line @typescript-eslint/ban-types
	const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		let {value} = e.target;

		if (value.length === 2) {
			value = value.replace(otp[index], '');
		}

		if (/^[0-9]$/.test(value) || value === '') {
			const newOtp = [...otp];
			newOtp[index] = value;
			setOtp(newOtp);

			if (value && index < length - 1) {
				inputRefs.current[index + 1]?.focus();
			} else if (value === '' && index > 0) {
				inputRefs.current[index - 1]?.focus();
			}

			if (onEditOtp) {
				onEditOtp(newOtp.join(''));
			}

			if (newOtp.every(digit => digit !== '')) {
				if (onComplete) {
					onComplete(newOtp.join(''));
				}
			}
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace' && !otp[index] && index > 0) {
			inputRefs.current[index - 1]?.focus();
		} else if (e.key === 'ArrowLeft' && index > 0) {
			inputRefs.current[index - 1]?.focus();
		} else if (e.key === 'ArrowRight' && index < length - 1) {
			inputRefs.current[index + 1]?.focus();
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pastedData = e.clipboardData.getData('text');
		const digits = pastedData.split('').filter(char => /^[0-9]$/.test(char)).slice(0, length);

		const newOtp = [...otp];
		digits.forEach((digit, i) => {
			if (i < length) {
				newOtp[i] = digit;
				inputRefs.current[i]?.focus();
			}
		});

		setOtp(newOtp);

		if (onEditOtp) {
			onEditOtp(newOtp.join(''));
		}

		if (newOtp.every(digit => digit !== '')) {
			if (onComplete) {
				onComplete(newOtp.join(''));
			}
		}
	};

	useEffect(() => {
		inputRefs.current = inputRefs.current.slice(0, length);
	}, [length]);

	return (
		<div className={styles['input-code']}>
			{otp.map((digit, index) => (
				<input
					key={index}
					type='text'
					value={digit}
					onChange={e => {
						handleChange(e, index);
					}}
					onKeyDown={e => {
						handleKeyDown(e, index);
					}}
					onPaste={e => {
						handlePaste(e);
					}}
					ref={el => {
						inputRefs.current[index] = el;
					}}
					inputMode='numeric'
					autoComplete='new-password'
				/>
			))}
		</div>
	);
};

export default OtpInput;
