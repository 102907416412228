import {type GetUserStaffResponse} from '../../../models/IUsers';
import {getUserStaff} from '../../../api/usersApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getUserStaffAction = createActionTemplateAxios<
GetUserStaffResponse,
void>(
	'user/getUserStaff/action',
	getUserStaff,
	'userToken',
);

export default getUserStaffAction;
