import React from 'react';
import styles from './EditingRoles.module.css';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../redux/redux-hooks';
import {putPermissionsAction} from '../../../../../redux/action/permissions';
import {type Role} from '../../../../../models/Schemas';

type EditingRolesProps = {
	roleType: Role | undefined;
	setRoleType: (value: Role) => void;
	roleOptions: Role[] | undefined;
	isLoading: boolean;
};

export const EditingRoles: React.FC<EditingRolesProps> = ({
	roleType,
	setRoleType,
	roleOptions,
	isLoading,
}) => {
	const dispatch = useAppDispatch();

	const {user} = useAppSelector(state => state.getUser);

	return (
		<>
			<span className={styles.title}>Роль</span>
			<span className={styles.role}>{user?.role_name}</span>
		</>
	);
};
