import React, {type ReactElement} from 'react';
import styles from './AccountLink.module.css';
import {TbUserQuestion} from 'react-icons/tb';
import {type SocialAccount as TypeSocialAccount} from '../../../../report/Entity';
import {getSocialAccountLinks} from './SocialAccountLinks';
import {type SocialAccountTypes} from '../../../../report/reportModelEnum';
import {WhatsappIcon} from '../../Icons';

type AccountLinkProps = {
	socialAccount: TypeSocialAccount;
};

const socialAccountIcons: Record<SocialAccountTypes, ReactElement | undefined> = {
	whatsapp: WhatsappIcon,
};

const SocialAccount: React.FC<AccountLinkProps> = ({socialAccount}) => {
	const linkData = getSocialAccountLinks(socialAccount);
	const icon = (socialAccount.type && socialAccountIcons[socialAccount.type]) ?? <TbUserQuestion />;

	if (linkData?.href) {
		return (
			<>
				<a
					href={linkData?.href}
					className={`${styles.link}`}
					target='_blank'
					rel='noreferrer'
					onClick={e => {
						e.stopPropagation();
					}}
				>
					{icon}
					<span>
						{linkData?.text}
					</span>
				</a>
			</>

		);
	}

	return (
		<span className={`${styles.link} ${styles['nick-name']}`}>
			{icon}
			<span>
				{linkData.text}
			</span>
		</span>
	);
};

export default SocialAccount;
