import React from 'react';
import styles from './SocialAccounts.module.css';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {ReportBlockTemplate} from '..';
import {MdOutlineAccountCircle} from 'react-icons/md';
import {SocialAccount} from '../../../UiKit/Message';

const SocialAccounts = () => {
	const socialAccounts = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.socialAccounts);

	return (
		<ReportBlockTemplate
			blocksId='social_accounts'
			blockIcon={MdOutlineAccountCircle}
			blockTitle='Социальные аккаунты'
			visible={socialAccounts?.data && socialAccounts.data.length > 0}
			alerts={socialAccounts?.additionalData.alerts}
		>
			<div className={styles.container}>
				{socialAccounts?.data.map(({item}, index) => <SocialAccount key={index} socialAccount={item} />)}
			</div>
		</ReportBlockTemplate>
	);
};

export default SocialAccounts;
