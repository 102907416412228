import {Cookies} from 'react-cookie';
import {type NavigationHash} from '../../../models/Navigation/Navigation';
import {cookiesKeys} from '..';

const saveNavigationCookies = (navigationHash: NavigationHash) => {
	const cookies = new Cookies();
	const maxAge = 34186698000; // 13 месяцев

	if (navigationHash.navigation_company_hash) {
		cookies.set(
			cookiesKeys.navCompanyHash,
			navigationHash.navigation_company_hash,
			{
				path: '/',
				maxAge,
				sameSite: 'strict',
				secure: true,
			},
		);
	} else {
		cookies.remove(cookiesKeys.navCompanyHash);
	}

	if (navigationHash.navigation_department_hash) {
		cookies.set(
			cookiesKeys.navDepartmentHash,
			navigationHash.navigation_department_hash,
			{
				path: '/',
				maxAge,
				sameSite: 'strict',
				secure: true,
			},
		);
	} else {
		cookies.remove(cookiesKeys.navDepartmentHash);
	}
};

export default saveNavigationCookies;
