import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutChallengerResponse} from '../../../models/IChallengers';
import {putChallengerAction} from '../../action/challengers';

type PutChallengerSliceState = {
	responseStatus: ResponseStatus;
	response: PutChallengerResponse | undefined;
};

const initialState: PutChallengerSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const putChallengerSlice = createSlice({
	name: 'challengers/putChallenger',
	initialState,
	reducers: {
		putChallengerResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(putChallengerAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putChallengerAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putChallengerAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {putChallengerResetState} = putChallengerSlice.actions;
export default putChallengerSlice.reducer;
