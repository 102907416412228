import React, {useEffect, useRef} from 'react';
import styles from './Home.module.css';
import {Counter, WidgetCard} from '../../components/UiKit/Widget';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getStatisticsHomeAction} from '../../redux/action/statistics';
import {ChartCandidateInMonth, ChartChecksInDay, ChartStatusChecks} from '../../components/Charts';
import Loader from '../../components/Loader/Loader';

const Home = () => {
	const dispatch = useAppDispatch();
	const getStatisticsHome = useAppSelector(state => state.getStatisticsHome);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		void dispatch(getStatisticsHomeAction());
	}, []);

	return (
		<>
			<div
				className={styles.metrics}
				ref={containerRef}
			>
				<span className={`h1-text ${styles.title}`}>Статистика</span>
				<Counter
					count={getStatisticsHome.response?.checks_count}
					title={'Всего заявок'}
				/>
				<Counter
					count={getStatisticsHome.response?.checks_not_ready}
					title={'Требуется проверить'}
				/>
				<Counter
					count={getStatisticsHome.response?.checks_ready}
					title={'Проверено'}
				/>
				<WidgetCard title='Проверок в день' className={styles.chart}>
					<ChartChecksInDay
						dataSource={getStatisticsHome.response?.checks_per_day}
						containerRef={containerRef}
					/>
				</WidgetCard>
				<WidgetCard title={'Кандидатов в месяц'} className={styles.chart}>
					<ChartCandidateInMonth
						dataSource={getStatisticsHome.response?.candidate_per_month}
						MaxValue={getStatisticsHome.maxCandidatePerMonth}
						containerRef={containerRef}
					/>
				</WidgetCard>
				<WidgetCard title={'Проверено/не проверено'}>
					<ChartStatusChecks
						checksReady={getStatisticsHome.response?.checks_ready}
						checksNotReady={getStatisticsHome.response?.checks_not_ready}
						containerRef={containerRef}
					/>
				</WidgetCard>
			</div>
			<Loader
				isLoading={getStatisticsHome.responseStatus.isLoading}
				isFloating
				text='Обновление статистики...'
				shading={getStatisticsHome.response ? 'partial' : 'full'}
			/>
		</>
	);
};

export default Home;
