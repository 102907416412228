import {Cookies} from 'react-cookie';
import {type GuestToken} from '../../../models/Guest/GuestToken/IPerson152fzToken';
import cookiesKeys from '../cookiesKeys';

export const getGuestToken = () => {
	const cookies = new Cookies();

	const token: GuestToken = {
		token: cookies.get(cookiesKeys.guestToken) as string,
		expires_at: cookies.get(cookiesKeys.guestTokenExpiresDate) as string,
	};

	return token;
};
