import axios, {type AxiosResponse} from 'axios';
import {
	type PutContactRequest,
	type PutContactResponse,
	type PostContactRequest,
	type PostContactResponse,
	type DeleteContactRequest,
	type DeleteContactResponse,
} from '../models/IContacts';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/contacts';

// Добавление контакта
export async function postContact(
	data: PostContactRequest,
): Promise<AxiosResponse<PostContactResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Обновление контакта
export async function putContact(
	data: PutContactRequest,
): Promise<AxiosResponse<PutContactResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.contactId}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function deleteContact(
	data: DeleteContactRequest,
): Promise<AxiosResponse<DeleteContactResponse>> {
	return axios.delete(`${domain}${urlPrefix}/${data.id}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
