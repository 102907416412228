import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../InputDevextreme.module.css';
import InputTextAreaStyles from './InputTextArea.module.css';
import {TextArea, Validator} from 'devextreme-react';
import {type TextBoxType, type KeyDownEvent} from 'devextreme/ui/text_box_types';
import {emailButton, searchButton} from '../InputButtons';
import {
	type InputValidation,
	type InputStyles,
	type InputAttr,
	type InputButtons,
	type InputMask,
	type InputButton,
} from '../InputsTypes';
import {CustomRule, PatternRule, RequiredRule, StringLengthRule} from 'devextreme-react/cjs/validator';
import {Label, type LabelCommonProps} from '../../../Common';

export type InputProps = {
	value: string;
	setValue: (value: string) => void;
	height?: string | number;
	maxHeight?: string | number;
	autoResizeEnabled?: boolean;

	onEnterKey?: (e: KeyDownEvent) => void;
	onKeyDown?: (e: KeyDownEvent) => void;
	styleType?: 'large' | 'medium' | 'small';

	focusOnInput?: boolean;
	disabled?: boolean;

	inputStyles?: InputStyles & InputStylesAdditional;
	inputValidation?: InputValidation & InputValidationAdditional;
	inputMask?: InputMask;
	inputAttr?: InputAttr;
	inputButtons?: InputButtons;
} & LabelCommonProps;

type InputStylesAdditional = {
	mode?: TextBoxType;
};

export type InputValidationAdditional = {
	isEmail?: boolean;
};

const InputTextArea: React.FC<InputProps> = ({
	value,
	setValue,
	height = 140,
	maxHeight,
	autoResizeEnabled,
	styleType = 'small',
	title,
	titleColor = 'asphalt',
	focusOnInput,
	disabled,
	inputStyles,
	inputValidation,
	inputMask,
	inputAttr,
	inputButtons,
	onKeyDown,
	onEnterKey,
}) => {
	const [firstInput, setFirstInput] = useState(false);
	const [firstFocusOut, setFirstFocusOut] = useState(false);

	const inputRef = useRef<TextArea>(null);
	const validatorRef = useRef<Validator>(null);
	const wrapperClassName = `${styles.wrapper}`;
	const inputClassName = `${styles['input-dx']} ${styles[styleType]}`;

	const validationMessagePosition = inputValidation?.validationMessagePosition ?? 'top';
	const validationMessageMode = inputValidation?.validationMessageMode ?? 'auto';

	const validation = useMemo(() => {
		if (inputValidation) {
			const {
				setIsValid,
				isRequired,
				requiredLength,
				minLength,
				lettersOnly,
				customPatternRule,
				isEmail,
				customRule,
			} = inputValidation;
			return (
				<Validator
					ref={validatorRef}
					onValidated={e => {
						if (setIsValid) {
							setIsValid(e.isValid ?? false);
						}
					}}
				>
					{isRequired
						&& <RequiredRule
							message='Поле должно быть заполнено'
						/>}
					{requiredLength
						&& <StringLengthRule
							min={requiredLength}
							max={requiredLength}
							message={`Длина поля должна быть ${requiredLength}`}
						/>}
					{minLength
						&& <StringLengthRule
							min={minLength}
							ignoreEmptyValue
							message={`Минимальная длина поля ${minLength}`}
						/>}
					{lettersOnly
						&& <PatternRule
							pattern={/^[a-zA-Zа-яА-ЯёЁ]+$/}
							message='Поле должно содержать только буквы'
						/>}
					{isEmail
						&& <PatternRule
							pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/}
							message='Неправильный электронный адрес'
						/>}
					{customPatternRule?.map((item, index) => (
						<PatternRule key={'patternRule' + index} {...item} />
					))}
					{customRule?.map((item, index) => (
						<CustomRule key={`CustomRule ${index}`} {...item} />
					))}
				</Validator>
			);
		}

		return undefined;
	}, [inputValidation?.setIsValid,
		inputValidation?.isRequired,
		inputValidation?.requiredLength,
		inputValidation?.minLength,
		inputValidation?.lettersOnly,
		inputValidation?.customPatternRule,
		inputValidation?.customRule]);

	const maxLength = inputValidation?.requiredLength ?? inputValidation?.maxLength;

	const buttons = useMemo(() => {
		const buttons: InputButton[] = inputButtons?.buttons ?? [];
		if (inputButtons?.searchButton) {
			buttons.push(searchButton);
		}

		if (inputButtons?.emailButton) {
			buttons.push(emailButton);
		}

		if (buttons.length > 0) {
			return buttons;
		}

		return undefined;
	}, [inputButtons]);

	const onValueChange = useCallback((value: string) => {
		setValue(value);
	}, [setValue]);

	const onInput = useCallback(() => {
		if (!firstInput) {
			setFirstInput(true);
		}
	}, [setFirstInput]);

	const onFocusOut = useCallback(() => {
		if (!firstFocusOut) {
			setFirstFocusOut(true);
		}
	}, [firstFocusOut, setFirstFocusOut]);

	useEffect(() => {
		if (focusOnInput) {
			inputRef.current?.instance.focus();
		}
	}, [focusOnInput]);

	useEffect(() => {
		if (validatorRef) {
			validatorRef.current?.instance.validate();
		}
	}, []);

	useEffect(() => {
		if (inputValidation?.showValidation === false || inputValidation?.visible === false) {
			setFirstInput(false);
			setFirstFocusOut(false);
		}
	}, [inputValidation?.showValidation, inputValidation?.visible]);

	return (
		<>
			{title
				&& (
					<div className={InputTextAreaStyles['title-wrapper']}>
						<Label
							title={title}
							titleColor={titleColor}
							styleType={styleType}
							disabled={disabled}
							isRequired={inputValidation?.isRequired}
						/>
					</div>
				)
			}
			<div className={wrapperClassName}>
				<TextArea
					ref={inputRef}
					className={inputClassName}
					value={value}
					onValueChange={onValueChange}
					disabled={disabled}
					validationMessageMode={validationMessageMode}
					validationMessagePosition={validationMessagePosition}
					maxLength={maxLength}
					buttons={buttons}
					onKeyDown={onKeyDown}
					onInput={onInput}
					onFocusOut={onFocusOut}
					valueChangeEvent='keyup'
					height={autoResizeEnabled ? undefined : height}
					maxHeight={maxHeight}
					autoResizeEnabled={autoResizeEnabled}
					isValid={firstInput || inputValidation?.showValidation
						? inputValidation?.isValid ?? true
						: true}
					inputAttr={{...inputAttr}}
					onEnterKey={onEnterKey}
					{...inputMask}
					{...inputStyles}
				>
					{validation}
				</TextArea>
			</div>
		</>
	);
};

export default InputTextArea;
