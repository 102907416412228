import React from 'react';
import styles from './Label.module.css';

export type LabelCommonProps = {
	title?: string;
	titleColor?: 'main' | 'asphalt';
};

type LabelProps = LabelCommonProps & {
	isRequired?: boolean;
	styleType?: 'large' | 'medium' | 'small';
	disabled?: boolean;
};

const Label: React.FC<LabelProps> = ({
	styleType = 'small',
	title,
	titleColor = 'asphalt',
	disabled,
	isRequired,
}) => (
	<>
		{title && (
			<label className={`${styles.title} ${styles[styleType]} ${styles[titleColor]}`}>
				{title}
				{!disabled && isRequired && <span className={styles.required}>*</span>}
			</label>
		)}
	</>
);

export default Label;
