import React, {type ReactNode, useEffect, useState} from 'react';
import styles from './ExpandingCard.module.css';
import {IoChevronUpOutline} from 'react-icons/io5';
import {type IconType} from 'react-icons';
import {type Alert} from '../../../../../report/reportModelV3/reportModelV3';
import AlertMessage from '../../../Message/AlertMessage/AlertMessage';

type DetailsProps = {
	isOpened?: boolean;
	HeaderIcon: IconType;
	headerText: string;
	styleType?: 'outline' | 'fill';
	alerts?: Alert[];
	summaryText?: string;
	openedSummaryText?: string;
	ToggleIcon?: IconType;
	children: ReactNode;
	color?: 'green' | 'lavender';
	height?: number | string;
	hederPadding?: number | string;
	contentPadding?: number | string;
};

const ExpandingCard: React.FC<DetailsProps> = ({
	isOpened = false,
	HeaderIcon,
	headerText,
	alerts,
	summaryText,
	openedSummaryText = summaryText,
	ToggleIcon = IoChevronUpOutline,
	children,
	styleType = 'fill',
	height,
	hederPadding = 24,
	contentPadding = 24,
}) => {
	const [isOpen, setIsOpen] = useState(isOpened);

	const onClickDetails = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (isOpen !== isOpened) {
			setIsOpen(isOpened);
		}
	}, [isOpened]);

	return (
		<div
			className={`${styles.card} ${styles[styleType]}`}
			style={{height: isOpen ? height : undefined}}
			data-open={isOpen}
		>
			<div
				className={styles['header-wrapper']}
				onClick={onClickDetails}
				style={{padding: hederPadding}}
			>
				<div className={styles.header}>
					<div className={styles.title}>
						<HeaderIcon />
						<span>{headerText}</span>
					</div>
					<AlertMessage alerts={alerts} />
				</div>
				<div className={styles['toggle-container']}>
					<span>{isOpen ? openedSummaryText : summaryText}</span>
					<ToggleIcon className={styles.toggle} />
				</div>
			</div>
			{isOpen && (
				<div
					className={styles.content}
					style={{padding: contentPadding}}
				>
					{children}
				</div>
			)}
		</div>
	);
};

export default ExpandingCard;
