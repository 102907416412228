import React, {useEffect, useState} from 'react';
import styles from './CheckPersonInputs.module.css';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {filteredCheckResponse, type ProcessedChecksResponse} from '../../../redux/slice/checks';
import {Dropdown, InputDateRange} from '../../UiKit/Inputs';
import {FactCheckButton, userButton} from '../../UiKit/Inputs/Template/InputButtons';
import {ToggleCardOrTableView} from '../../UiKit/Button';

type CheckPersonInputsProps = {
	isCardView: boolean;
	setIsCardView: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CheckPersonInputs: React.FC<CheckPersonInputsProps> = ({
	isCardView,
	setIsCardView,
}) => {
	const [selectedItem, setSelectedItem] = useState(0);
	const dispatch = useAppDispatch();

	const [checkName, setCheckName] = useState<string | undefined>(undefined);
	const [optionsCheckName, setOptionsCheckName] = useState<string[] | undefined>(undefined);
	const [statusName, setStatusName] = useState<string | undefined>(undefined);
	const [optionsStatusName, setOptionsStatusName] = useState<string[] | undefined>(undefined);

	const [authorFullName, setAuthorFullName] = useState<string | undefined>(undefined);
	const [optionsAuthor, setOptionsAuthor] = useState<string[] | undefined>(undefined);

	const [reportCreatedAt, setReportCreatedAt] = useState<string[]>([]);
	const [checkCreatedAt, setCheckCreatedAt] = useState<string[]>([]);

	const getCheck = useAppSelector(state => state.getCheck);

	const handleIconToggle = () => {
		setIsCardView(prevState => !prevState);
	};

	const handleItemClick = (index: number) => {
		if (optionsStatusName) {
			setSelectedItem(index);
			setStatusName(optionsStatusName[index]);
		}
	};

	// Установка значений для фильтров
	const setFiltersOptions = (checkResponse: ProcessedChecksResponse[]) => {
		const authors: string[] = [];
		const checkNames: string[] = [];
		const reportCreatedAts: string[] = [];
		const checkCreatedAts: string[] = [];
		const status: string[] = ['Все'];

		checkResponse.forEach(item => {
			if (!authors.includes(item.authorFullName)) {
				authors.push(item.authorFullName);
			}

			if (!status.includes(item.statusName)) {
				status.push(item.statusName);
			}

			if (!checkNames.includes(item.name)) {
				checkNames.push(item.name);
			}

			if (!reportCreatedAts.includes(item.report_created_at)) {
				reportCreatedAts.push(item.report_created_at);
			}

			if (!checkCreatedAts.includes(item.check_created_at)) {
				checkCreatedAts.push(item.check_created_at);
			}
		});

		setOptionsAuthor(authors);
		setOptionsStatusName(status);
		setOptionsCheckName(checkNames);
	};

	// Обработка получения списка проверок
	useEffect(() => {
		const {isCompleted} = getCheck.responseStatus;
		const {processedResponse} = getCheck;

		if (isCompleted && processedResponse) {
			setFiltersOptions(processedResponse);
		}
	}, [getCheck.responseStatus.isLoading]);

	useEffect(() => {
		if (getCheck.responseStatus.isCompleted) {
			dispatch(filteredCheckResponse({
				authorFullName,
				checkName,
				statusName: statusName === 'Все' ? undefined : statusName,
				checkCreatedAt,
				reportCreatedAt,
				verdict: '',
			}));
		}
	}, [
		checkName,
		statusName,
		authorFullName,
		reportCreatedAt,
		checkCreatedAt,
		getCheck.responseStatus.isLoading,
	]);

	return (
		<>
			<ul className={styles.status}>
				{optionsStatusName?.map((item, index) => {
					const isActive = selectedItem === index;
					const isDisabled = getCheck.responseStatus.isLoading;

					return (
						<li
							key={index}
							className={`${styles['status-item']} ${isActive ? styles.active : ''} ${isDisabled ? styles.disabled : ''}`}
							onClick={() => {
								if (!isDisabled) {
									handleItemClick(index);
								}
							}}
						>
							{item}
						</li>
					);
				})}
			</ul>

			<div className={styles.content}>
				<div className={styles.filters}>
					<div className={styles['filter-medium']}>
						<Dropdown
							selectedValue={checkName}
							setSelectedValue={setCheckName}
							icons={[FactCheckButton]}
							options={optionsCheckName}
							placeholder='Название проверки'
							emptyMessage='Не найдено'
							showClear
						/>
					</div>
					<div className={styles['filter-medium']}>
						<Dropdown
							selectedValue={authorFullName}
							setSelectedValue={setAuthorFullName}
							icons={[userButton]}
							options={optionsAuthor}
							placeholder='От кого заявка'
							emptyMessage='Не найдено'
							showClear
						/>
					</div>
					<div className={styles['filters-wide']}>
						<InputDateRange
							value={reportCreatedAt}
							startDatePlaceholder='Проверка от'
							endDatePlaceholder='Проверка до'
							labelMode='hidden'
							setValue={setReportCreatedAt}
						/>
					</div>
					<div className={styles['filters-wide']}>
						<InputDateRange
							value={checkCreatedAt}
							startDatePlaceholder='Запрос от'
							endDatePlaceholder='Запрос до'
							labelMode='hidden'
							setValue={setCheckCreatedAt}
						/>
					</div>
				</div>
				<ToggleCardOrTableView
					isCardView={isCardView}
					setIsCardView={handleIconToggle}
				/>
			</div>
		</>
	);
};
