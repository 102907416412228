import React, {type RefObject} from 'react';
import {
	type ChartSeriesList,
	ChartTemplate,
	type ChartCommonSeriesSettingsProps,
	type ChartTooltip,
} from '../../UiKit/Charts';

const commonSeriesSettings: ChartCommonSeriesSettingsProps = {
	valueField: 'count',
	argumentField: 'day',
};

const tooltip: ChartTooltip = {
	enabled: true,
	argumentLabel: 'Дата',
	valueLabel: 'Проверки',
};

const series: ChartSeriesList[] = [
	{
		seriesProps: {
			color: 'var(--chart-are-default-1)',
			type: 'line',
			showInLegend: false,
			selectionMode: 'none',
			hoverMode: 'none',
		},
		pointProps: {
			visible: false,
		},
	},
	{
		seriesProps: {
			gradientCalor: {
				base: 'white',
				colors: [{
					offset: '0%',
					color: 'var(--chart-are-default-1)',
				},
				{
					offset: '98%',
					color: 'var(--chart-are-default-2)',
				}],
				rotationAngle: 180,
				type: 'linear',
			},
			type: 'area',
			showInLegend: false,
			selectionMode: 'allSeriesPoints',
			hoverMode: 'nearestPoint',
		},
		pointProps: {
			visible: true,
			size: 10,
			color: '#3354F4',
			hoverStyle: {
				border: {
					color: 'white',
				},
				color: '#3354F4',
			},
		},
	},
];

type ChartChecksInDayProps = {
	dataSource: any[] | undefined;
	containerRef?: RefObject<HTMLDivElement>;
};

const ChartChecksInDay: React.FC<ChartChecksInDayProps> = ({dataSource, containerRef}) => (
	<ChartTemplate
		dataSource={dataSource ?? []}
		commonSeriesSettings={commonSeriesSettings}
		tooltip={tooltip}
		series={series}
		containerRef={containerRef}
		height={230}
		valueAxis={{
			allowDecimals: false,
		}}
		argumentAxis={{
			valueMarginsEnabled: false,
		}}
	/>
);

export default ChartChecksInDay;
