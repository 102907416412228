import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostCheckCreateAddressResponse} from '../../../models/ICheck';
import {postCheckCreateAddressAction} from '../../action/checks';

type postCheckCreateAddressState = {
	responseStatus: ResponseStatus;
	response: PostCheckCreateAddressResponse | undefined;
};

const initialState: postCheckCreateAddressState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
		requestId: undefined,
	},
	response: undefined,
};

const posCheckCreateAddressSlice = createSlice({
	name: 'checks/postCreateAddress',
	initialState,
	reducers: {
		postCheckCreateAddressSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postCheckCreateAddressAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.errorMessage = '';
				state.responseStatus.status = undefined;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postCheckCreateAddressAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.errorMessage = '';
				state.responseStatus.status = undefined;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(postCheckCreateAddressAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postCheckCreateAddressSliceResetState} = posCheckCreateAddressSlice.actions;
export default posCheckCreateAddressSlice.reducer;
