import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getGuest} from '../../../api/Guest/guestApi';
import {type GetGuestResponse} from '../../../models/Guest/Guest/IGuest';

const getGuestAction = createActionTemplateAxios<
GetGuestResponse,
void
>(
	'guest/getGuest/action',
	getGuest,
	'guestToken',
);

export default getGuestAction;
