import {
	type PutDepartmentPositionsRequest,
	type PutDepartmentPositionsResponse,
} from '../../../models/IDepartment';
import {putDepartmentPositions} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const putDepartmentPositionsAction = createActionTemplateAxios<
PutDepartmentPositionsResponse,
PutDepartmentPositionsRequest>(
	'department/putDepartmentPositions/action',
	putDepartmentPositions,
	'userToken',
);

export default putDepartmentPositionsAction;
