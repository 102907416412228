import React from 'react';
import styles from './ArrowButton.module.css';
import {HiOutlineArrowSmLeft} from 'react-icons/hi';

type ArrowButtonProps = {
	onclickHandler: () => void;
	text?: string;
	buttonStyle?: string;
	arrowStyle?: string;
	textStyle?: string;
};

const ArrowButton: React.FC<ArrowButtonProps> = ({
	onclickHandler,
	text,
	buttonStyle,
	arrowStyle,
	textStyle,
}) => (
	<button
		className={buttonStyle ?? styles['arrow-button']}
		onClick={onclickHandler}
	>
		<HiOutlineArrowSmLeft className={arrowStyle ?? styles['arrow-icon']}/>
		{text && <span className={textStyle ?? styles.text}>{text}</span>}
	</button>
);

export default ArrowButton;
