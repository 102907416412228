import React from 'react';
import ButtonTemplate from '../../ButtonTemplate/ButtonTemplate';

type ButtonConfirmProps = {
	onclickHandler: () => void;
	text: string;
	type?: 'button' | 'submit' | 'reset';
	disable?: boolean;
};

const ButtonConfirm: React.FC<ButtonConfirmProps> = ({
	onclickHandler,
	text,
	type, disable,
}) => (
	<ButtonTemplate
		type={type}
		onClick={onclickHandler}
		text={text}
		styleType='medium'
		color={'primary'}
		width='min(240px, 100%)'
		disable={disable}
	/>
);

export default ButtonConfirm;
