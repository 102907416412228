import {createSlice} from '@reduxjs/toolkit';
import {
	type Token,
	type ResponseStatus,
} from '../../../models/IAuth';
import {authNoActivatedUserAction} from '../../action/auth';
import {saveToken} from '../../../utils/cookies/token/saveToken';

type authNoActivatedUser = ResponseStatus & {token: Token | undefined};

const initialState: authNoActivatedUser = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	status: undefined,
	token: undefined,
};

const authNoActivatedUserSlice = createSlice({
	name: 'auth/authNoActivatedUser',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(authNoActivatedUserAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.token = undefined;
			})
			.addCase(authNoActivatedUserAction.fulfilled, (state, action) => {
				saveToken(action.payload);
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.token = action.payload;
			})
			.addCase(authNoActivatedUserAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.token = undefined;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.status = action.payload?.status;
			});
	},
});

export default authNoActivatedUserSlice.reducer;
