import React from 'react';
import {type IconType} from 'react-icons';
import styles from './ContextMenuItem.module.css';

export type ContextMenuItemProps = {
	text?: string;
	Icon?: IconType;
	onClick: () => void;
};

const ContextMenuItem: React.FC<ContextMenuItemProps> = ({
	text,
	Icon,
	onClick,
}) => (
	<div
		className={styles.container}
		onClick={onClick}
	>
		{Icon && (
			<Icon />
		)}
		{text && (
			<p>
				{text}
			</p>
		)}
	</div>
);

export default ContextMenuItem;
