import styles from './OtpCode.module.css';
import arrow from '../../assets/login/arrowLeft.svg';
import SixDigitalCode from './SixDigitalCode';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {verifyAction} from '../../redux/action/auth';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import {verifyReset} from '../../redux/slice/auth/verifySlice';
import {type VerificationRequest} from '../../models/IAuth';
import {startTimerAction} from '../../redux/action/timerAction';
import {type TimerAction} from '../../models/Timer';
import {sendSmsAuthorizationAction} from '../../redux/action/auth';
import {stopTimer} from '../../redux/slice/timerSlice';
import Loader from '../Loader/Loader';
import {ErrorMessage} from '../UiKit/Message';
import {ButtonTemplate} from '../UiKit/Button';
import {paths} from '../../paths';

type OtpCodeProps = {
	setComponent: React.Dispatch<React.SetStateAction<boolean>>;
	email: string;
};

export const OtpCode: React.FC<OtpCodeProps> = ({setComponent, email}) => {
	const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);
	const [cookies] = useCookies(['debug']);

	const [isDisabledSendCode, setIsDisabledSendCode] = useState(true);

	const dispatch = useAppDispatch();
	const {isLoading, isCompleted, isError, errorMessage} = useAppSelector(
		state => state.verify,
	);
	const {phone_last_charts, sms_sent_at, code_number} = useAppSelector(
		state => state.smsAuthorization,
	);
	const isLoadingSmsAuthorization = useAppSelector(
		state => state.smsAuthorization.isLoading,
	);
	const isCompletedSmsAuthorization = useAppSelector(
		state => state.smsAuthorization.isCompleted,
	);
	const {isStop, value, isContinuation} = useAppSelector(
		state => state.timer,
	);

	const navigate = useNavigate();

	useEffect(() => {
		if (phone_last_charts) {
			localStorage.setItem('phoneLastCharts', phone_last_charts);
		}
	}, [phone_last_charts]);

	const getPhoneFirstCharts = () => {
		if (phone_last_charts !== '') {
			return phone_last_charts.slice(0, 2);
		}

		return localStorage.getItem('phoneLastCharts')?.slice(0, 2);
	};

	const getPhoneLastCharts = () => {
		if (phone_last_charts !== '') {
			return phone_last_charts.slice(2);
		}

		return localStorage.getItem('phoneLastCharts')?.slice(2);
	};

	const cleanCode = () => {
		setCode(['', '', '', '', '', '']);
		dispatch(verifyReset());
	};

	const checkCode = () => {
		// Отправка кода и почты на сервер
		const enteredCode: string = code.join('');
		const requestOptions: VerificationRequest = {
			code: enteredCode,
			email,
			debug: Boolean(cookies.debug),
		};
		void dispatch(verifyAction(requestOptions));
	};

	const checkCodeFull = () => {
		// Проверка что код введён полностью
		for (const item of code) {
			if (item === '') {
				return false;
			}
		}

		return true;
	};

	useEffect(() => {
		if (checkCodeFull()) {
			setIsDisabledSendCode(false);
			checkCode();
		} else {
			setIsDisabledSendCode(true);
		}
	}, [code]);

	const startTimer = () => {
		// Запуск таймера если остановлен или новая смс
		if (isContinuation) {
			const requestOptions: TimerAction = {
				value,
				startDate: 0,
				isContinuation,
			};
			void dispatch(startTimerAction(requestOptions));
		} else if (sms_sent_at) {
			const requestOptions: TimerAction = {
				value: 60,
				startDate: sms_sent_at,
				isContinuation,
			};
			void dispatch(startTimerAction(requestOptions));
		}
	};

	const getNewSms = () => {
		// Запросить код в смс
		cleanCode();
		void dispatch(
			sendSmsAuthorizationAction({
				email,
				debug: Boolean(cookies.debug),
			}),
		);
	};

	useEffect(() => {
		// Продолжить таймера, если остановлен
		if (isContinuation) {
			startTimer();
		}
	}, [isContinuation]);

	useEffect(() => {
		// Обработка получения смс
		if (isCompletedSmsAuthorization) {
			localStorage.setItem('codeNumber', code_number);
			startTimer();
		}
	}, [isLoadingSmsAuthorization]);

	const goBack = () => {
		// Переход к компоненту ввода почты
		localStorage.removeItem('component');
		setComponent(true);
	};

	const handleResponse = () => {
		dispatch(verifyReset());
		dispatch(stopTimer());
		localStorage.removeItem('codeNumber');
		localStorage.removeItem('component');
		localStorage.removeItem('email');
		localStorage.removeItem('smsNumber');
		localStorage.removeItem('phoneLastCharts');
		localStorage.removeItem('lastEmailSmsSend');
		navigate(paths.home);
	};

	useEffect(() => {
		if (isCompleted) {
			handleResponse();
		}
	}, [isLoading]);

	const formatTime = (time: number) => {
		if (time > 59) {
			const minutes = Math.floor(time / 60);
			const seconds = time % 60;
			return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
		}

		return time < 10 ? `0${time}` : time.toString();
	};

	const getCodeNumber = () => {
		// Получение номера смс
		if (code_number !== '') {
			return code_number;
		}

		return localStorage.getItem('codeNumber');
	};

	return (
		<div className={styles.container}>
			<div className={styles.back} onClick={goBack}>
				<img src={arrow} alt='' />
				<span>Назад</span>
			</div>
			<div className={styles.card}>
				<div className={styles.title}>
					<h2>Введите код из СМС №{getCodeNumber()}</h2>

					<p>
            На ваш номер телефона{' '}
						<strong>
              +7(9**) ***-{getPhoneFirstCharts()}-{getPhoneLastCharts()}
						</strong>{' '}
            отправлен 6 значный код для входа в личный кабинет, введите его ниже
					</p>
				</div>

				<SixDigitalCode code={code} setCode={setCode} isError={isError} />

				<div className={styles['btn-group']}>
					<ButtonTemplate
						onClick={checkCode}
						disable={isDisabledSendCode}
						role='send-code'
						color='primary'
						text='Подтвердить'
					/>
					<button
						className={styles['btn-timer']}
						onClick={getNewSms}
						disabled={!isStop}
					>
						<span>Отправить новый код</span>{' '}
						<span>
							{value > 59 ? `0${formatTime(value)}` : `00:${formatTime(value)}`}
						</span>
					</button>

					<ErrorMessage isError={isError} message={errorMessage} />
				</div>
			</div>
			<Loader isFloating isLoading={isLoading} text='Авторизация...' />
			<Loader isFloating isLoading={isLoadingSmsAuthorization} text='Отправка кода...' />
		</div>
	);
};
