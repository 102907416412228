import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Label as CustomLabel, type LabelCommonProps} from '../../../Common';
import {RangeSlider, type RangeSliderTypes, Label, Tooltip} from 'devextreme-react/range-slider';
import styles from './InputRange.module.css';

type InputRangeProps = {
	styleType?: 'large' | 'medium' | 'small';
	disabled?: boolean;
	startValue: number;
	endValue: number;
	min?: number;
	max?: number;
	isRequired?: boolean;
	onValueChange?: (start: number, end: number) => void;
} & LabelCommonProps;

export const InputRange: React.FC<InputRangeProps> = ({
	title,
	titleColor = 'asphalt',
	styleType = 'small',
	disabled,
	startValue,
	endValue,
	min = 0,
	max = 100,
	isRequired,
	onValueChange, // Добавляем пропс onValueChange
}) => {
	const [currentStartValue, setCurrentStartValue] = useState(startValue);
	const [currentEndValue, setCurrentEndValue] = useState(endValue);
	const inputRangeClassName = `${styles['dx-input-range']}`;

	const ref = useRef<RangeSlider>(null);

	const onRangeChanged = useCallback((data: RangeSliderTypes.ValueChangedEvent) => {
		if (typeof data.start === 'number') {
			setCurrentStartValue(data.start);
		}

		if (typeof data.end === 'number') {
			setCurrentEndValue(data.end);
		}

		if (onValueChange && typeof data.start === 'number' && typeof data.end === 'number') {
			onValueChange(data.start, data.end);
		}
	}, [setCurrentStartValue, setCurrentEndValue, onValueChange]);

	useEffect(() => {
		ref.current?.instance.repaint();
	}, []);

	return (
		<>
			<CustomLabel
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				disabled={disabled}
				isRequired={isRequired}
			/>
			<RangeSlider
				min={min}
				max={max}
				start={currentStartValue}
				end={currentEndValue}
				onValueChanged={onRangeChanged}
				ref={ref}
				className={inputRangeClassName}
			>
				<Tooltip enabled={true} showMode='always' position='top'/>
				<Label visible={true} position='top'/>
			</RangeSlider>
		</>
	);
};
