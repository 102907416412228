import {logout} from '../../../api/authApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const logoutAction = createActionTemplateAxios<void, void>(
	'auth/logout/action',
	logout,
	'userToken',
);

export default logoutAction;
