import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {createRequestUsersAction} from '../../action/users';
import {type CreateUsersResponse} from '../../../models/IUsers';

const initialState: {
	responseStatus: ResponseStatus;
	response: CreateUsersResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const createRequestUsersSlice = createSlice({
	name: 'createUsers',
	initialState,
	reducers: {
		createRequestUsersResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(createRequestUsersAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(createRequestUsersAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(createRequestUsersAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});
export const {createRequestUsersResetState} = createRequestUsersSlice.actions;
export default createRequestUsersSlice.reducer;
