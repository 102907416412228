import React, {useState, useEffect} from 'react';
import styles from './AdditionalContacts.module.css';
import {IoClose} from 'react-icons/io5';
import {typeSelectEmailOrPhoneNumber} from './TypeSelectEmailOrPhoneNumber';
import {Dropdown, Input, InputPhone} from '../../../UiKit/Inputs';

export type AdditionalContactProps = {
	removeContact: (index: number) => void;
	index: number;
	handleChangeContacts: (contact: ItemContact) => void;
	itemContact?: ItemContact;
	isDelete: boolean;
	isUpdate: boolean;
	showValidation: boolean;
	key: string | number;
};

export type ItemContact = {
	isValid: boolean;
	item: {
		contactId?: number;
		contact_type: string;
		contact: string;
		description: string;
		key: number;
	};
};

export const AdditionalContacts: React.FC<AdditionalContactProps> = ({
	removeContact,
	index,
	handleChangeContacts,
	itemContact,
	isDelete,
	isUpdate,
	showValidation,
}) => {
	const [isValid, setIsValid] = useState(itemContact?.isValid ?? false);
	const [contact, setContact] = useState(itemContact?.item.contact ?? '');
	const [description, setDescription] = useState(itemContact?.item.description ?? '');
	const [contactType, setContactType] = useState<string>(
		typeSelectEmailOrPhoneNumber.find(
			item => item.data === itemContact?.item.contact_type,
		)?.data ?? typeSelectEmailOrPhoneNumber[0].data,
	);
	const [isValidContactType, setIsValidContactType] = useState(false);

	useEffect(() => {
		if (contactType !== undefined) {
			handleChangeContacts({
				isValid: typeof itemContact?.item.contactId === 'number'
					? isValid
					: isValid && isValidContactType,
				item: {
					contact,
					contact_type: contactType,
					description,
					key: index,
					contactId: itemContact?.item.contactId,
				}});
		}
	}, [contact, contactType, description, isValid, isValidContactType]);

	return (
		<div className={styles.content}>
			{isDelete && <div className={styles['btn-close']}>
				<IoClose
					onClick={() => {
						removeContact(index);
					}}
				/>
			</div>}
			<div className={styles['input-group']}>
				<Dropdown
					selectedValue={contactType}
					setSelectedValue={setContactType}
					options={typeSelectEmailOrPhoneNumber}
					displayValueKey='label'
					setValueKey='data'
					placeholder='Тип контакта'
					emptyMessage='Данные не найдены'
					title='Тип контакта'
					readOnly={itemContact?.item.contactId !== undefined}
					isValid={isValidContactType}
					setIsValid={setIsValidContactType}
					isRequired
					showValidation
				/>
				{contactType === 'phone' && (
					<InputPhone
						value={contact}
						isRequired
						setValue={setContact}
						isValid={isValid}
						showValidation={showValidation}
						setIsValid={setIsValid}
						disabled={!isUpdate}
						title='Телефон'
						inputStyles={{
							placeholder: 'Телефон',
						}}
					/>
				)}
				{contactType === 'email' && (
					<Input
						value={contact}
						setValue={setContact}
						disabled={!isUpdate}
						title='Электронный адрес'
						inputStyles={{
							placeholder: 'Электронный адрес',
						}}
						inputValidation={{
							isRequired: true,
							minLength: 2,
							isEmail: true,
							setIsValid,
							isValid,
							showValidation,
						}}
					/>
				)}
				<Input
					value={description}
					setValue={setDescription}
					disabled={!isUpdate}
					title='Описание'
					inputStyles={{
						placeholder: 'Описание',
					}}
				/>
			</div>
		</div>
	);
};
