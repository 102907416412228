import React, {useEffect, useState} from 'react';
import styles from './AddUsers.module.css';
import {HiOutlineArrowSmLeft, HiOutlineDownload} from 'react-icons/hi';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../paths';
import {typePermissions} from '../../models/Enum';
import {useAppSelector} from '../../redux/redux-hooks';
import {getGroupPermission} from '../../utils';
import {AddUsersOrEmployeesViaFile} from '../../components/AddUsersOrEmployeesViaFile';
import {EmailsUsersTables} from '../../components/Tables';
import publicAssetsLinks from '../../links';

const AddUsers = () => {
	const navigate = useNavigate();
	const handleClickBack = () => {
		navigate(paths.subordinate);
	};

	const [userCreate, setUserCreate] = useState(false);
	const [userMassCreate, setUserMassCreate] = useState(false);
	const getPermissions = useAppSelector(state => state.getPermissions);

	const permissions = () => {
		if (getPermissions.responseStatus.isCompleted) {
			const userCreate = getGroupPermission(typePermissions.user_create, getPermissions.groupPermissions)?.permit ?? false;
			const userMassCreate = getGroupPermission(typePermissions.user_mass_create, getPermissions.groupPermissions)?.permit ?? false;

			if (userCreate || userMassCreate) {
				setUserCreate(userCreate);
				setUserMassCreate(userMassCreate);
			} else {
				navigate(paths.subordinate);
			}
		}
	};

	useEffect(() => {
		permissions();
	}, [getPermissions.response, getPermissions.responseStatus.isLoading]);

	useEffect(() => {
		permissions();
	}, []);

	return (
		<div className={styles.content}>
			<div className={styles.back}>
				<HiOutlineArrowSmLeft onClick={handleClickBack} />
				<span className='h1-text'>Добавить пользователей</span>
			</div>
			{userCreate && (
				<EmailsUsersTables />
			)}
			{userMassCreate && (
				<>
					<div className={styles.header}>
						<div className={styles.download}>
							<a href={publicAssetsLinks.createUsers}>Скачать шаблон</a>
							<HiOutlineDownload />
						</div>
					</div>
					<AddUsersOrEmployeesViaFile isAddUsers={true} />
				</>
			)}
		</div>
	);
};

export default AddUsers;
