import React, {useCallback, useMemo, useRef, useEffect, type ReactNode} from 'react';
import styles from './ModalWindow.module.css';
import Loader from '../Loader/Loader';
import {Button, Popup} from 'devextreme-react';
import {ToolbarItem} from 'devextreme-react/cjs/popup';

export type ModalWindowProps = {
	header?: ReactNode;
	visible: boolean;
	setVisible: (value: boolean) => void;
	children?: ReactNode;
	width?: string | number;
	height?: string | number;
	minWidth?: string | number;
	minHeight?: string | number;
	maxWidth?: string | number;
	maxHeight?: string | number;
	position?: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'left bottom' | 'left top' | 'right bottom' | 'right top';
	footerItems?: ReactNode[];
	footerItemsLocation?: 'center' | 'after' | 'before';
	isLoading?: boolean;
	loadingMessage?: string;
	isResizable?: boolean;
	isDraggable?: boolean;
	closable?: boolean;
	closeOnOutsideClick?: boolean;
	onCloseButtonClick?: () => void;
	onClose?: () => void;
};

const ModalWindow: React.FC<ModalWindowProps> = ({
	header,
	footerItems,
	footerItemsLocation = 'center',
	visible,
	setVisible,
	children,
	width = 'max-content',
	height = 'auto',
	minWidth,
	minHeight,
	maxWidth = '90%',
	maxHeight = '90%',
	position,
	isLoading = false,
	loadingMessage,
	isResizable,
	isDraggable = false,
	closable,
	closeOnOutsideClick = false,
	onCloseButtonClick,
	onClose,
}) => {
	const modalRef = useRef<Popup>(null);

	const closeWindow = useCallback(() => {
		setVisible(false);
		if (onCloseButtonClick) {
			onCloseButtonClick();
		}
	}, [setVisible]);

	const onCloseWindow = useCallback(() => {
		setTimeout(() => {
			setVisible(false);
		}, 10);
		if (onClose) {
			onClose();
		}
	}, [setVisible]);

	const footerItem = useMemo(() => (
		footerItems && <ToolbarItem
			toolbar='bottom'
			location={footerItemsLocation}
			render={() => (
				<div className={styles.footer}>
					{footerItems?.map(item => item)}
				</div>
			)}
		/>
	), [footerItems]);

	const titleRender = () => (
		<div className={styles.title}>
			<div className={styles['header-wrapper']}>
				{header}
			</div>
			<Button icon='close' onClick={closeWindow} stylingMode='text' className={styles['close-btn']} />
		</div>
	);

	useEffect(() => {
		if (!isLoading) {
			modalRef.current?.instance.repaint();
		}
	}, [isLoading]);

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				modalRef.current?.instance.repaint();
			}, 100);
		}
	}, [visible]);

	return (
		<>
			<Popup
				titleRender={titleRender}
				showTitle={Boolean(header) || closable}
				showCloseButton={closable}
				resizeEnabled={isResizable}
				dragEnabled={isDraggable}
				position={position}
				width={width}
				height={height}
				maxHeight={maxHeight}
				maxWidth={maxWidth}
				minHeight={minHeight}
				minWidth={minWidth}
				onHidden={onCloseWindow}
				visible={visible}
				enableBodyScroll
				hideOnParentScroll
				wrapperAttr={{class: styles.modal}}
				ref={modalRef}
				hideOnOutsideClick={closeOnOutsideClick}
			>
				{footerItem}
				<Loader text={loadingMessage} isFloating isLoading={isLoading} />
				{children}
			</Popup>
		</>
	);
};

export default ModalWindow;
