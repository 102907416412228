import {type ResponseStatus} from '../../../models/IAuth';
import {type PostEmployeesViaFileResponse} from '../../../models/IEmployees';
import {createSlice} from '@reduxjs/toolkit';
import {postEmployeesViaFileAction} from '../../action/employees';

const initialState: {
	responseStatus: ResponseStatus;
	response: PostEmployeesViaFileResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const postEmployeesViaFileSlice = createSlice({
	name: 'employees/postEmployeesViaFile',
	initialState,
	reducers: {
		postEmployeesViaFileResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postEmployeesViaFileAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postEmployeesViaFileAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(postEmployeesViaFileAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {postEmployeesViaFileResetState} = postEmployeesViaFileSlice.actions;
export default postEmployeesViaFileSlice.reducer;
