import {createActionTemplateAxios} from '../ActionTemplatesRequest';

import {type GetNotificationsResponse} from '../../../models/INotifications';
import {getNotifications} from '../../../api/notificationsApi';

const getNotificationsAction = createActionTemplateAxios<GetNotificationsResponse, void>(
	'notifications/getNotifications',
	getNotifications,
	'userToken',
);

export default getNotificationsAction;
