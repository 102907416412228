import React from 'react';
import ButtonTemplate, {type ButtonTemplateProps} from '../ButtonTemplate/ButtonTemplate';

type DefaultButtonProps = {
	IconPosition?: 'left' | 'right';
} & ButtonTemplateProps;

const DefaultButton: React.FC<DefaultButtonProps> = ({
	onClick,
	text,
	IconPosition,
	Icon,
	...props
}) => (
	<ButtonTemplate
		onClick={onClick}
		color={'primary'}
		{...props}
	>
		{IconPosition === 'left' && Icon && <Icon />}
		<span>{text}</span>
		{IconPosition === 'right' && Icon && <Icon />}
	</ButtonTemplate>
);

export default DefaultButton;
