import axios, {type AxiosResponse} from 'axios';
import {
	type SmsAuthorizationRequest,
	type VerificationRequest,
	type Token,
	type SmsAuthorizationResponse,
	type PostAuthNoActivatedUserRequest,
} from '../models/IAuth';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/auth';

// Подтверждение кода из смс при входе в аккаунт
export async function verification(
	data: VerificationRequest,
): Promise<AxiosResponse<Token>> {
	return axios.post(`${domain}${urlPrefix}/otp/verify`, data);
}

// Авторизация по секретному ключу (для неактивированных пользователей)
export async function postAuthNoActivatedUser(
	data: PostAuthNoActivatedUserRequest,
): Promise<AxiosResponse<Token>> {
	return axios.post(`${domain}${urlPrefix}/hash`, data);
}

// Отправка смс для входа в аккаунт
export async function sendSmsAuthorization(
	data: SmsAuthorizationRequest,
): Promise<AxiosResponse<SmsAuthorizationResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/otp/send`,
		data,
	);
}

// Обновление токена
export async function refreshToken(): Promise<AxiosResponse<Token>> {
	return axios.post(`${domain}${urlPrefix}/refresh`, {refresh_token: getToken().refresh_token});
}

// Выход из аккаунта
export async function logout(): Promise<AxiosResponse<void>> {
	return axios.get(`${domain}${urlPrefix}/logout`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
