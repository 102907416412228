import {getNavigationCompanies} from '../../../api/Navigation/navigationApi';
import {type GetNavigationCompaniesResponse} from '../../../models/Navigation/Navigation';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getNavigationCompaniesAction = createActionTemplateAxios<
GetNavigationCompaniesResponse,
void
>('navigation/getNavigationCompanies/action', getNavigationCompanies, 'userToken');

export default getNavigationCompaniesAction;
