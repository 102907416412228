import {useEffect} from 'react';
import type React from 'react';
import {type ResponseStatus} from '../../../../models/IAuth';

type state<ResponseType> = {
	responseStatus: ResponseStatus;
	response: ResponseType;
};

type useEffectHandlerLoadingCurrentRequestType<ResponseType> = {
	requestIdRef: React.MutableRefObject<string | undefined>;
	state: state<ResponseType>;
	onLoading?: () => void;
	onStopLoading?: () => void;
	onComplete?: (response: ResponseType) => void;
	onError?: (errorMessage: string, response?: ResponseType) => void;
	onOtherRequestId?: (state: state<ResponseType>) => void;
};

const useEffectHandlerLoadingCurrentRequest = <T>({
	requestIdRef,
	state,
	onLoading,
	onStopLoading,
	onComplete,
	onError,
	onOtherRequestId,
}: useEffectHandlerLoadingCurrentRequestType<T>) => {
	useEffect(() => {
		const {requestId} = state.responseStatus;

		if (requestIdRef.current === undefined || requestId !== requestIdRef.current) {
			if (onOtherRequestId) {
				onOtherRequestId(state);
			}

			return;
		}

		const {isCompleted, isError, isLoading, errorMessage} = state.responseStatus;

		if (isLoading) {
			if (onLoading) {
				onLoading();
			}
		} else {
			requestIdRef.current = undefined;

			if (onStopLoading) {
				onStopLoading();
			}

			if (isCompleted) {
				if (onComplete) {
					onComplete(state.response);
				}
			} else if (isError) {
				if (onError) {
					onError(errorMessage);
				}
			}
		}
	}, [
		state.responseStatus.isLoading,
		state.responseStatus.requestId,
	],
	);
};

export default useEffectHandlerLoadingCurrentRequest;
