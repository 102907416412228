import React, {useEffect, useState} from 'react';
import {type Field} from '../ModalCheckCreated';
import {InputDate} from '../../../UiKit/Inputs';
import {type CheckFieldData, type SendCheckField} from '../../../../models/Schemas';
import {DateTime} from 'luxon';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {checKAdditionalValidation} from '.';

type InputFieldProps = {
	updateValue: (sendCheckField: SendCheckField, isValid: boolean) => void;
	initValue: CheckFieldData;
	showValidation: boolean;
} & Field;

const convertValueToString = (value: CheckFieldData) => {
	if (typeof (value) === 'string') {
		if (DateTime.fromFormat(value, 'yyyy-MM-dd').isValid) {
			return value;
		}

		const date = DateTime.fromFormat(value, 'dd.MM.yyyy');
		if (date.isValid) {
			const formatDate = date.toFormat('yyyy-MM-dd');

			return formatDate;
		}
	}

	return undefined;
};

const InputDateField: React.FC<InputFieldProps> = ({...props}) => {
	const [value, setValue] = useState<string | undefined>(convertValueToString(props.initValue));

	const [isValid, setIsValid] = useState(false);

	const [isValidAdditional, setIsValidAdditional] = useState(true);
	const [invalidMessageAdditional, setInvalidMessageAdditional] = useState('');
	const postCheckCreateErrors = useAppSelector(state => state.postCheckCreate.responseStatus.errors);
	const postCheckUpdateErrors = useAppSelector(state => state.postCheckUpdate.responseStatus.errors);
	const postCheckStartErrors = useAppSelector(state => state.postCheckStart.responseStatus.errors);

	useEffect(() => {
		let formatValue;
		if (value) {
			const date = DateTime.fromFormat(value, 'yyyy-MM-dd');
			formatValue = date.toFormat('dd.MM.yyyy');
		} else {
			formatValue = '';
		}

		const sendCheckField: SendCheckField = {
			id: props.field.id,
			value: props.field.value,
			data: formatValue,
		};

		props.updateValue(sendCheckField, isValid);
	}, [value, isValid]);

	useEffect(() => {
		if (!isValidAdditional) {
			setIsValidAdditional(true);
		}
	}, [value]);

	useEffect(() => {
		if (postCheckCreateErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckCreateErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckCreateErrors]);

	useEffect(() => {
		if (postCheckUpdateErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckUpdateErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckUpdateErrors]);

	useEffect(() => {
		if (postCheckStartErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckStartErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckStartErrors]);

	return (
		<InputDate
			value={value}
			setValueString={setValue}
			title={props.field.name}
			inputMask={{
				mask: props.mask,
			}}
			inputStyles={{
				placeholder: props.field.name,
			}}
			inputValidation={{
				isRequired: props.isAllRequired || props.isRequired,
				setIsValid,
				isValid,
				showValidation: props.showValidation,
				customRule: [{
					message: invalidMessageAdditional,
					validationCallback() {
						return isValidAdditional;
					},
				}],
			}}
		/>
	);
};

export default InputDateField;
