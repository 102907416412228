import React, {useEffect, useRef, useState} from 'react';
import styles from './Layout.module.css';
import {Navigation} from '../../components';
import {Outlet, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getPermissionsAction, getUserAction} from '../../redux/action/users';
import {paths} from '../../paths';
import {getOrganizationAction} from '../../redux/action/organization';
import Loader from '../../components/Loader/Loader';
import {resetGetUserState} from '../../redux/slice/users/getUserSlice';
import {refreshTokenResetState} from '../../redux/slice/auth/refreshTokenSlice';
import NotFoundPage from '../NotFoundPage';
import {DropdownProfile} from '../../components/UiKit/Dropdown';
import companyLogo from '../../assets/sidebar/companyLogo.svg';
import {getNotificationsAction} from '../../redux/action/notifications';
import {DropDownNotifications} from '../../components/UiKit/Dropdown';
import {GiHamburgerMenu} from 'react-icons/gi';
import SelectContextNavigation from '../../components/UiKit/Dropdown/SelectContextNavigation/SelectContextNavigation';
import {HeaderNavigation} from '../../components/Navigation/HeaderNavigation/HeaderNavigation';

const audio = new Audio('/audio/sound.mp3');

export const Layout = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const getPermissions = useAppSelector(state => state.getPermissions);
	const refreshToken = useAppSelector(state => state.refreshToken);
	const logout = useAppSelector(state => state.logout);
	const settings = useAppSelector(state => state.settings);

	const [fixSidebar, setFixSidebar] = useState(settings.isFixedSidebar);
	const [isOpenSideBar, setIsOpenSideBar] = useState(settings.isFixedSidebar);
	const [isAccess, setIsAccess] = useState(false);
	const [isErrorPage, setIsErrorPage] = useState(false);
	const {isCompleted, isError, isLoading, user, status, errorMessage} = useAppSelector(
		state => state.getUser,
	);

	const getNotifications = useAppSelector(state => state.getNotifications);

	// Вызов уведомлений каждые 30 сек
	useEffect(() => {
		void dispatch(getNotificationsAction());

		const intervalId = setInterval(() => {
			void dispatch(getNotificationsAction());
		}, 30 * 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	// Воспроизведение звука уведомлений
	useEffect(() => {
		if (getNotifications.responseStatus.isCompleted && settings.isSoundNotification) {
			const intervalId = setInterval(() => {
				audio.play().then(() => {
					clearInterval(intervalId);
				}).catch(err => {
					// Ошибка воспроизведения звука:
				});
			}, 500);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [getNotifications.newNotificationId]);

	const toggleSideBar = () => {
		if (!fixSidebar) {
			setIsOpenSideBar(!isOpenSideBar);
		}
	};

	useEffect(() => {
		// Обработка получения информации о пользователе
		if (
			(isError && status === 409)
			|| status === 406
			|| (isCompleted && user?.is_activated !== true)
		) {
			dispatch(resetGetUserState());
			navigate(paths.registration);
		}

		if (isError && status === 401) {
			dispatch(resetGetUserState());
			navigate(paths.auth);
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && !getPermissions.responseStatus.isLoading) {
			if (isCompleted && getPermissions.responseStatus.isCompleted) {
				setIsAccess(true);
			} else if (isError || getPermissions.responseStatus.isError) {
				setIsErrorPage(true);
			}
		}
	}, [isLoading, getPermissions.responseStatus.isLoading]);

	useEffect(() => {
		void dispatch(getUserAction());
		void dispatch(getOrganizationAction());
		void dispatch(getPermissionsAction());
	}, []);

	useEffect(() => {
		if (refreshToken.isCompleted && refreshToken.token) {
			dispatch(refreshTokenResetState());
		}

		if (refreshToken.isError) {
			dispatch(refreshTokenResetState());
			navigate(paths.auth);
		}
	}, [refreshToken.isLoading]);

	useEffect(() => {
		setFixSidebar(settings.isFixedSidebar);
	}, [settings.isFixedSidebar]);

	if (isErrorPage) {
		return <NotFoundPage
			status={isError ? status : getPermissions.responseStatus.status}
			message={isError ? errorMessage : getPermissions.responseStatus.errorMessage}
		/>;
	}

	if (isAccess) {
		return (
			<div className={styles.container}>
				<div
					className={`${styles.sidebar} ${(fixSidebar || isOpenSideBar) && styles['sidebar-opened']}`}
				>
					<SelectContextNavigation
						showSelect={settings.isFixedSidebar || isOpenSideBar}
						toggleSideBar={toggleSideBar}
					/>
					<Navigation showNames={settings.isFixedSidebar || isOpenSideBar}/>
				</div>
				<div className={styles.header}>
					<div className={styles['header-left']}>
						{!fixSidebar && <GiHamburgerMenu onClick={toggleSideBar} className={styles['sidebar-button']} />}
						<img src={companyLogo} alt=''></img>
						<div className={styles['header-nav']}>
							<HeaderNavigation />
						</div>
					</div>
					<div className={styles['dropdown-group']}>
						<DropDownNotifications />
						<DropdownProfile />
					</div>
				</div>
				<div className={styles['content-wrapper']}>
					<div className={styles.content}>
						<Outlet />
					</div>
				</div>
				<Loader text='Выход' isFloating isLoading={logout.isLoading} />
			</div>
		);
	}

	return (
		<div className={styles['container-loader']}>
			<Loader text='Получение данных о пользователе...' isFloating isLoading={isLoading && getPermissions.responseStatus.isLoading}/>
		</div>
	);
};
