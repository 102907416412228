import React from 'react';
import styles from './ModalSixDigitalCode.module.css';
import OtpCodeRegister from '../../OtpCode/OtpCodeRegister';
import {ModalWindow} from '../index';
import {useAppSelector} from '../../../redux/redux-hooks';

type ModalSixDigitalCodeProps = {
	visibleModalCode: boolean;
	setVisibleModalCode: React.Dispatch<React.SetStateAction<boolean>>;
	phone: string;
	closingModals: () => void;
};
export const ModalSixDigitalCode: React.FC<ModalSixDigitalCodeProps> = ({
	visibleModalCode,
	setVisibleModalCode,
	phone,
	closingModals,
}) => {
	const {code_number} = useAppSelector(
		state => state.smsRegistration,
	);

	const getCodeNumber = () => {
		// Получение номера смс
		if (code_number !== '') {
			return code_number;
		}

		return localStorage.getItem('codeNumber');
	};

	return (
		<ModalWindow
			visible={visibleModalCode}
			setVisible={setVisibleModalCode}
			closable
			width='min(450px, 90%)'
			header={<h2 className={styles.title}>Введите код из СМС №{getCodeNumber()}</h2>}
		>
			<div className={styles.content}>
				<OtpCodeRegister
					phone={phone}
					isEditingPhoneNumber
					closingModals={closingModals}
					isModal
					visible={visibleModalCode}
				/>
			</div>
		</ModalWindow>
	);
};
