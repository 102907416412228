import {paths} from '../../../paths';
import {getToken} from './getToken';
import {removeToken} from './removeToken';

export const checkToken = () => {
	const token = getToken();
	const date = new Date(new Date().getTime() + (60 * 60 * 100));

	if (token.token && token.token_expires) {
		if (new Date(token.token_expires) > date) {
			// Токена актуален
			return true;
		}
	}

	if (token.refresh_token && token.refresh_token_expires) {
		if (token.refresh_token && new Date(token.refresh_token_expires) > date) {
			// Токена устарел
			return false;
		}
	}

	// Токена нет
	removeToken();
	window.location.href = paths.auth;
};

export default checkToken;
