
import {postCheckCreate} from '../../../api/checkApi';
import {type postCheckCreateRequest, type postCheckCreateResponse} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckCreateAction = createActionTemplateAxios<
postCheckCreateResponse,
postCheckCreateRequest>(
	'checks/postCheckCreate/action',
	postCheckCreate,
	'userToken',
);

export default postCheckCreateAction;
