import React, {useEffect, useRef, useState} from 'react';
import styles from './Person.module.css';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../paths';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getPerson152fzDocumentsAction} from '../../redux/action/person';
import Loader from '../../components/Loader/Loader';
import {ErrorMessage} from '../../components/UiKit/Message';
import {getPerson152fzDocumentsResetState} from '../../redux/slice/person';
import {useEffectHandlerLoadingAllRequest, useEffectHandlerLoadingCurrentRequest} from '../../utils/hooks';
import PersonalDataAgreement152fz from '../../components/Forms/PersonalDataAgreement152fz/PersonalDataAgreement152fz';
import {getGuestAction} from '../../redux/action/guest';
import CreateCheckIndividualMainForm
	from '../../components/Forms/CreateCheckIndividual/MainForm/CreateCheckIndividualMainForm';

import logo from '../../assets/sidebar/companyLogo.svg';
import {PersonNavigation} from '../../components/Navigation';

const Person = () => {
	const getPerson152fzDocumentsRequestId = useRef<string>();
	const [cookies] = useCookies(['token_guest']);
	const [isDataProcessingAllowed, setIsDataProcessingAllowed] = useState<boolean>();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const [visibleCheckIndividualMainForm, setVisibleCheckIndividualMainForm] = useState(true);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const getPerson152fzDocuments = useAppSelector(state => state.getPerson152fzDocuments);
	const getGuest = useAppSelector(state => state.getGuest);

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	// Отобразить ошибку
	const showError = (message?: string) => {
		setIsError(true);
		setErrorMessage(message);
	};

	// Скрыть ошибку
	const hiddenError = () => {
		setIsError(false);
		setErrorMessage(undefined);
	};

	// Сброс состояний компонента
	const resetComponentStates = () => {
		hiddenError();
		hiddenLoading();

		getPerson152fzDocumentsRequestId.current = undefined;
		dispatch(getPerson152fzDocumentsResetState());
		setIsDataProcessingAllowed(undefined);
	};

	const getAgreement = () => {
		// Запрос на получение документов согласия на обработку ПД
		getPerson152fzDocumentsRequestId.current = dispatch(getPerson152fzDocumentsAction({
			params: {type: 'pd_agreement'},
		})).requestId;
	};

	// Первый рендер компонента
	useEffect(() => {
		resetComponentStates();
		if (cookies.token_guest) {
			getAgreement();
		} else {
			navigate(paths.home);
		}
	}, []);

	// Получение документов согласия на обработку ПД
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getPerson152fzDocumentsRequestId,
		state: getPerson152fzDocuments,
		onLoading() {
			showLoading('Проверка согласия на обработку персональных данных...');
			hiddenError();
		},
		onStopLoading: hiddenLoading,
		onComplete(response) {
			setIsDataProcessingAllowed(response && response.length > 0);
			void dispatch(getGuestAction());
		},
		onError(errorMessage) {
			setIsDataProcessingAllowed(undefined);
			showError(errorMessage);
		},
	});

	// Получение Информации о госте
	useEffectHandlerLoadingAllRequest({
		state: getGuest,
	});

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img src={logo} alt='Лого'/>
			</div>
			{!isError && (
				<div className={styles.content}>
					{
						isDataProcessingAllowed
							? (
								<div className={styles.form}>
									<div className={styles.sidebar}>
										<PersonNavigation/>
									</div>
									<CreateCheckIndividualMainForm
										visible={visibleCheckIndividualMainForm}
										setVisible={setVisibleCheckIndividualMainForm}
									/>
								</div>
							)
							: (
								<PersonalDataAgreement152fz
									onAgreement={getAgreement}
								/>
							)
					}

				</div>
			)}
			<Loader
				isLoading={isLoading}
				text={loadingMessage}
				isFloating
				shading='full'
			/>
			<ErrorMessage
				message={errorMessage}
				isError={true}
			/>
		</div>
	);
};

export default Person;
