import React, {useEffect, useRef, useState} from 'react';
import styles from './ModalAddDepartment.module.css';
import {ModalWindow} from '..';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {
	getDepartmentAction,
	getDepartmentEmployeesAction,
	getDepartmentSubordinateAction,
	postDepartmentAction,
	putDepartmentAction,
} from '../../../redux/action/department';
import {
	getDepartmentResetState,
	postDepartmentResetState,
	putDepartmentResetState,
} from '../../../redux/slice/department';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {Dropdown, type DropdownDefaultValue, Input, TextArea} from '../../UiKit/Inputs';
import {ErrorMessage} from '../../UiKit/Message';
import {type Department} from '../../../models/Schemas';
import {getFullName} from '../../../utils';
import {SplitLine} from '../../UiKit/Div';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import {SelectDepartment} from '../../CardRegister/SelectDepartment';
import {useEffectHandlerLoadingCurrentRequest} from '../../../utils/hooks';

type ModalEditDepartmentProps = {
	departmentId?: number;
	parentDepartment?: Department;
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	parentDepartmentOptions?: Department[];
	useParentDepartmentOptions?: boolean;
	company_id?: number;
};

export const ModalEditDepartment: React.FC<ModalEditDepartmentProps> = ({
	departmentId,
	parentDepartment,
	visible,
	setVisible,
	parentDepartmentOptions,
	useParentDepartmentOptions,
	company_id,
}) => {
	const getDepartmentRequestId = useRef<string>();
	const getDepartmentsSubordinateRequestId = useRef<string>();
	const getDepartmentEmployeesRequestId = useRef<string>();
	const postDepartmentRequestId = useRef<string>();
	const putDepartmentRequestId = useRef<string>();

	const [showValidation, setShowValidation] = useState(false);
	const [name, setName] = useState('');
	const [isValid, setIsValid] = useState<boolean>(false);

	const [description, setDescription] = useState('');
	const [directorId, setDirectorId] = useState<number | undefined>(undefined);
	const [selectedParentId, setSelectedParentId] = useState<number | undefined>(undefined);
	const [optionsParentDepartment, setOptionsParentDepartment] = useState<Department[] | undefined>(undefined);
	const [optionsDirector, setOptionsDirector] = useState<DropdownDefaultValue[] | undefined>(undefined);

	const [isLoading, setIsLoading] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState<string>();
	const [isErrorDepartment, setIsErrorDepartment] = useState<boolean>(false);
	const [errorMessageDepartment, setErrorMessageDepartment] = useState<string>();

	const dispatch = useAppDispatch();
	const getDepartment = useAppSelector(state => state.getDepartment);
	const getDepartmentsSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const getDepartmentEmployees = useAppSelector(state => state.getDepartmentEmployees);
	const postDepartment = useAppSelector(state => state.postDepartment);
	const putDepartment = useAppSelector(state => state.putDepartment);
	const {organization} = useAppSelector(state => state.organization);

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	// Отобразить ошибку
	const showErrorDepartment = (message?: string) => {
		setIsErrorDepartment(true);
		setErrorMessageDepartment(message);
	};

	// Скрыть ошибку
	const hiddenErrorDepartment = () => {
		setIsErrorDepartment(false);
		setErrorMessageDepartment(undefined);
	};

	// Сброс состояний компонента
	const resetComponentStates = () => {
		hiddenErrorDepartment();
		hiddenLoading();

		setName('');
		setDescription('');
		setSelectedParentId(undefined);
		setDirectorId(undefined);
		setIsValid(false);
		setOptionsParentDepartment(undefined);
		setOptionsDirector(undefined);
		setShowValidation(false);

		getDepartmentRequestId.current = undefined;
		getDepartmentsSubordinateRequestId.current = undefined;
		getDepartmentEmployeesRequestId.current = undefined;
		postDepartmentRequestId.current = undefined;
		putDepartmentRequestId.current = undefined;
	};

	// Обработка отмены изменений
	const handleCancel = () => {
		setVisible(false);
	};

	// Выбор обработчика для принятия изменений
	const selectingSaveHandler = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isValid) {
			if (departmentId) {
				// Обновление отдела
				void dispatch(
					putDepartmentAction({
						body: {
							name,
							description,
							director_id: directorId ?? null,
						},
						department_id: departmentId,
					}),
				);
			} else if (organization) {
				// Добавление отдела
				void dispatch(
					postDepartmentAction({
						body: {
							name,
							description,
							organization_id: organization.id,
							director_id: directorId ?? null,
							parent_id: selectedParentId ?? null,
							is_filial: false,
							company_id,
						},
					}),
				);
			}
		} else {
			notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
		}
	};

	// Обработка закрытия и открытия окна
	useEffect(() => {
		if (visible) {
			if (departmentId) {
				getDepartmentRequestId.current = dispatch(getDepartmentAction({departmentId})).requestId;
				getDepartmentEmployeesRequestId.current = dispatch(getDepartmentEmployeesAction({departmentId})).requestId;
			} else {
				if (!useParentDepartmentOptions) {
					getDepartmentsSubordinateRequestId.current = dispatch(getDepartmentSubordinateAction()).requestId;
				}

				if (parentDepartment) {
					setSelectedParentId(parentDepartment.id);
				}
			}
		} else {
			resetComponentStates();
		}
	}, [visible]);

	// Получение списка подчинённых департаментов
	useEffect(() => {
		if (getDepartmentsSubordinate.response) {
			setOptionsParentDepartment(getDepartmentsSubordinate.response);
		} else {
			setOptionsParentDepartment(undefined);
		}
	}, [getDepartmentsSubordinate.response]);

	// Получение списка сотрудников отдела
	useEffect(() => {
		if (getDepartmentEmployees.responseStatus.isCompleted && getDepartmentEmployees.response) {
			setOptionsDirector(
				getDepartmentEmployees.response.map(item => ({
					label: getFullName(item.first_name, item.middle_name, item.last_name),
					data: item.id,
				})),
			);
		} else {
			setOptionsDirector(undefined);
		}
	}, [getDepartmentEmployees.responseStatus.isLoading]);

	// Получение данных об отделе
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: getDepartmentRequestId,
		state: getDepartment,
		onComplete(response) {
			if (response) {
				setName(response.name);
				setDescription(response.description);
				setDirectorId(response.director?.id);
				setSelectedParentId(response.parent_id ?? undefined);
				dispatch(getDepartmentResetState());
			} else {
				setName('');
				setDescription('');
				setDirectorId(undefined);
				setSelectedParentId(undefined);
			}
		},
		onError: showErrorDepartment,
		onStopLoading: hiddenLoading,
		onLoading() {
			showLoading('Получение информации об отделе...');
			hiddenErrorDepartment();
		},
	});

	// Добавление отдела
	useEffect(() => {
		if (postDepartment.responseStatus.isLoading) {
			setLoadingMessage('Добавление отдела');
			setIsLoading(true);
		} else {
			setLoadingMessage('');
			setIsLoading(false);
			if (postDepartment.responseStatus.isCompleted) {
				setVisible(false);
				dispatch(postDepartmentResetState());
				notifyMessage('success', 'Отдел создан успешно');
			}
		}
	}, [postDepartment.responseStatus.isLoading]);

	// Обновление отдела
	useEffect(() => {
		if (putDepartment.responseStatus.isLoading) {
			setLoadingMessage('Обновление отдела');
			setIsLoading(true);
		} else {
			setLoadingMessage('');
			setIsLoading(false);
			if (putDepartment.responseStatus.isCompleted) {
				setVisible(false);
				dispatch(putDepartmentResetState());
				notifyMessage('success', 'Информация об отделе обновлена');
			}
		}
	}, [putDepartment.responseStatus.isLoading]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			isLoading={isLoading}
			loadingMessage={loadingMessage}
			width='min(750px, 90%)'
			height='auto'
			closable
			closeOnOutsideClick
			footerItems={[
				<ButtonCancel key='ButtonCancel' text='Отменить' onclickHandler={handleCancel} />,
				<ButtonConfirm
					key='ButtonConfirm'
					text={departmentId ? 'Сохранить' : 'Добавить отдел'}
					onclickHandler={selectingSaveHandler}
				/>,
			]}
			header={(
				<div className={styles.header}>
					<h1>
						{departmentId ? 'Изменение информации об отделе' : 'Добавить отдел'}
					</h1>
					<span>
						Для {departmentId ? 'редактирования ' : 'создания нового '}
						отдела введите необходимые данные
					</span>
				</div>
			)}
		>
			<div className={styles.container}>
				<div className={styles.content}>
					<Input
						value={name}
						setValue={setName}
						title='Название отдела'
						inputStyles={{
							placeholder: 'Название отдела',
						}}
						inputValidation={{
							isRequired: true,
							setIsValid,
							isValid,
							minLength: 2,
							showValidation,
							visible,
						}}

					/>
					<SplitLine />
					<SplitLine />
					<TextArea
						value={description}
						setValue={setDescription}
						title='Описание отдела'
						inputStyles={{
							placeholder: 'Описание',
						}}
					/>
					{departmentId === undefined ? (
						!useParentDepartmentOptions && <SelectDepartment
							selectedId={selectedParentId}
							setSelectedId={setSelectedParentId}
							departmentOptions={optionsParentDepartment}
							placeholder='Выбрать отдел'
							title='Родительский отдел'
							visible={visible}
							showClear
							departmentOptionsIsLoading={getDepartmentsSubordinate.responseStatus.isLoading && !isLoading}
						/>
					) : (
						<Dropdown
							selectedValue = {directorId}
							setSelectedValue = {setDirectorId}
							options = {optionsDirector}
							displayValueKey = 'label'
							setValueKey = 'data'
							placeholder = 'Выбрать директора'
							emptyMessage = 'Сотрудники не найдены'
							title = 'Директор'
							showClear
							isLoading = {getDepartmentEmployees.responseStatus.isLoading && !isLoading}
						/>
					)}
				</div>
				<ErrorMessage
					message={errorMessageDepartment}
					isError={isErrorDepartment}
				/>
				<ErrorMessage
					message={getDepartmentEmployees.responseStatus.errorMessage}
					isError={getDepartmentEmployees.responseStatus.isError}
				/>
				<ErrorMessage
					message={putDepartment.responseStatus.errorMessage}
					isError={putDepartment.responseStatus.isError}
				/>
				<ErrorMessage
					message={postDepartment.responseStatus.errorMessage}
					isError={postDepartment.responseStatus.isError}
				/>
			</div>
		</ModalWindow>
	);
};

export default ModalEditDepartment;
