import React from 'react';
import styles from './CheckboxFiled.module.css';
import {CheckBoxDevEx, type CheckboxTemplateProps} from '..';
import {type IconType} from 'react-icons';
import {FaInfo} from 'react-icons/fa6';
import {Help} from '../../Message';

type CheckboxFiledProps = {
	label: string;
	styleType?: 'large' | 'medium' | 'small' | 'long';
	HelpIcon?: IconType;
	helpMessage?: string;
	helpTitle?: string;
} & CheckboxTemplateProps;

const CheckboxFiled: React.FC<CheckboxFiledProps> = ({styleType = 'long', HelpIcon = FaInfo, ...props}) => {
	const handleCheckBoxClick = () => {
		props.setChecked(!props.checked);
	};

	return (
		<div
			className={`${styles.container} ${styles[`${styleType}-input`]} ${props.checked && styles.checked}`}
			onClick={handleCheckBoxClick}
		>
			<div className={`${styles['checkbox-container']}`}>
				<CheckBoxDevEx
					isChecked={props.checked}
				/>
				<label className={styles[`${styleType}-text`]}>{props.label}</label>
			</div>
			{props.helpMessage
				&& <Help
					Icon={HelpIcon}
					message={props.helpMessage}
				/>}
		</div>
	);
};

export default CheckboxFiled;
