import React, {useRef} from 'react';
import {TooltipTemplate} from '../../UiKit/Message';
import {type NavigationItem} from '../NavigationData';
import styles from './NavItem.module.css';

type NavItemProps = {
	item: NavigationItem;
	isShowNames: boolean;
};

export const NavItem: React.FC<NavItemProps> = ({item, isShowNames}) => {
	const iconRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<div className={styles['icon-wrapper']} ref={iconRef}>
				<item.icon className={styles.icon} />
			</div>
			{isShowNames
				? (<span className={styles.text}>{item.text}</span>)
				: (<TooltipTemplate
					targetRef={iconRef}
					position='top'
				>
					<span>{item.tooltipMessage}</span>
				</TooltipTemplate>)
			}
		</>
	);
};
