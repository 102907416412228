import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostCheckCreatePassportResponse} from '../../../models/ICheck';
import {postCheckCreatePassportAction} from '../../action/checks';

type postCheckCreatePassportState = {
	responseStatus: ResponseStatus;
	response: PostCheckCreatePassportResponse | undefined;
};

const initialState: postCheckCreatePassportState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
		requestId: undefined,
	},
	response: undefined,
};

const postCheckCreatePassportSlice = createSlice({
	name: 'checks/postCreatePassport',
	initialState,
	reducers: {
		postCheckCreatePassportSliceResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postCheckCreatePassportAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.errorMessage = '';
				state.responseStatus.status = undefined;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postCheckCreatePassportAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.errorMessage = '';
				state.responseStatus.status = undefined;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(postCheckCreatePassportAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postCheckCreatePassportSliceResetState} = postCheckCreatePassportSlice.actions;
export default postCheckCreatePassportSlice.reducer;
