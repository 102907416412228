import {createSlice} from '@reduxjs/toolkit';
import {sendSmsAuthorizationAction} from '../../action/auth';
import {
	type ResponseStatus,
	type SmsAuthorizationResponse,
} from '../../../models/IAuth';

type Sms = ResponseStatus & SmsAuthorizationResponse;

const smsState: Sms = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	phone_last_charts: '',
	sms_sent_at: undefined,
	code_number: '',
	message: '',
};

const smsAuthorizationSlice = createSlice({
	name: 'auth/sendSmsAuthorization',
	initialState: smsState,
	reducers: {
		isCompleteReset(state) {
			state.isCompleted = false;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(sendSmsAuthorizationAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.phone_last_charts = '';
				state.sms_sent_at = undefined;
				state.code_number = '';
				state.message = '';
			})
			.addCase(sendSmsAuthorizationAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.phone_last_charts = action.payload.phone_last_charts;
				state.sms_sent_at = action.payload.sms_sent_at! * 1000;
				state.message = action.payload.message;
				state.code_number = action.payload.code_number;
			})
			.addCase(sendSmsAuthorizationAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.phone_last_charts = '';
				state.sms_sent_at = undefined;
				state.code_number = '';
			});
	},
});

export const smsReducer = smsAuthorizationSlice.reducer;
export const {isCompleteReset} = smsAuthorizationSlice.actions;
