const downloadDoc = (url: string | undefined, linkName: string) => {
	if (url) {
		const link = document.createElement('a');
		link.href = url;
		link.download = `${linkName}.docx`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};

export default downloadDoc;
