import React, {type ReactNode} from 'react';
import {ExpandingCard} from '../../UiKit/Cards';
import {type Alert} from '../../../report/reportModelV3/reportModelV3';
import {type IconType} from 'react-icons';
import {type BlocksIdsType} from '../../../report/reportModelEnum';

type ReportBlockTemplateProps = {
	alerts?: Alert[];
	children?: ReactNode;
	blocksId: BlocksIdsType;
	blockIcon: IconType;
	blockTitle: string;
	visible: boolean | undefined;
	styleType?: 'fill' | 'outline' | undefined;
};

const ReportBlockTemplate: React.FC<ReportBlockTemplateProps> = ({
	children,
	alerts,
	blocksId,
	blockIcon,
	blockTitle,
	visible,
	styleType,
}) => (
	<>
		{visible && (
			<div data-group='report-block' id={blocksId}>
				<ExpandingCard
					HeaderIcon={blockIcon}
					headerText={blockTitle}
					alerts={alerts}
					isOpened
					styleType={styleType}
				>
					{children}
				</ExpandingCard>
			</div>
		)}
	</>
);

export default ReportBlockTemplate;
