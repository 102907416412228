import React from 'react';
import {ButtonTemplate, type ButtonTemplateProps} from '../../index';
import {TbReportSearch} from 'react-icons/tb';

type GoToSubCheckButtonProps = ButtonTemplateProps;

export const GoToSubCheckButton: React.FC<GoToSubCheckButtonProps> = ({
	color = 'primary-transparent',
	iconStyleType = 'medium',
	styleType = 'icon',
	TooltipMessage = 'Открыть отчет подпроверки',
	...props
}) => (
	<ButtonTemplate
		color={color}
		iconStyleType={iconStyleType}
		styleType={styleType}
		Icon={TbReportSearch}
		TooltipMessage={TooltipMessage}
		{...props}
	/>
);
