import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetUserPermissionsResponse} from '../../../models/IPermissions';
import {getUserPermissionsAction} from '../../action/permissions';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetUserPermissionsResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getUserPermissionsSlice = createSlice({
	name: 'permissions/getUserPermissions',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getUserPermissionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getUserPermissionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(getUserPermissionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export default getUserPermissionsSlice.reducer;
