import {postUsersViaFile} from '../../../api/usersApi';
import {
	type PostUsersViaFileRequest,
	type PostUsersViaFileResponse,
} from '../../../models/IUsers';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postUsersViaFileAction = createActionTemplateAxios<
PostUsersViaFileResponse,
PostUsersViaFileRequest
>('auth/postUserViaFile/action', postUsersViaFile, 'userToken');

export default postUsersViaFileAction;
