import React, {useEffect, useState} from 'react';
import {type Field} from '../ModalCheckCreated';
import {InputName} from '../../../UiKit/Inputs';
import {type CheckFieldData, type SendCheckField} from '../../../../models/Schemas';
import {type NameParts} from '../../../../models/Dadata/IDadata';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {checKAdditionalValidation} from '.';

type InputAutocompleteFieldProps = {
	updateValue: (sendCheckField: SendCheckField, isValid: boolean) => void;
	initValue: CheckFieldData;
	showValidation: boolean;
} & Field;

const convertValueToString = (value: CheckFieldData, index: number): string => {
	if (typeof (value) === 'string') {
		return value;
	}

	if (typeof (value) === 'number') {
		return value.toString();
	}

	if (Array.isArray(value)) {
		return convertValueToString(value[index], index);
	}

	return '';
};

const convertStringToValue = (stringValue: string, valueType: string | undefined): CheckFieldData => {
	const trimmingValue = stringValue.trim();

	if (valueType === 'number' || valueType === 'range') {
		return parseInt(trimmingValue, 10);
	}

	return trimmingValue;
};

const getParts = (type?: string): NameParts[] | undefined => {
	if (type === 'text_middle_name') {
		return ['PATRONYMIC'];
	}

	if (type === 'text_first_name') {
		return ['NAME'];
	}

	if (type === 'text_last_name') {
		return ['SURNAME'];
	}

	return undefined;
};

const InputAutocompleteField: React.FC<InputAutocompleteFieldProps> = ({...props}) => {
	const [value, setValue] = useState(convertValueToString(props.initValue, 1));
	const [isValid, setIsValid] = useState(false);

	const [isValidAdditional, setIsValidAdditional] = useState(true);
	const [invalidMessageAdditional, setInvalidMessageAdditional] = useState('');
	const postCheckCreateErrors = useAppSelector(state => state.postCheckCreate.responseStatus.errors);
	const postCheckUpdateErrors = useAppSelector(state => state.postCheckUpdate.responseStatus.errors);
	const postCheckStartErrors = useAppSelector(state => state.postCheckStart.responseStatus.errors);

	useEffect(() => {
		const cleanedValue = convertStringToValue(value, props.type);

		const sendCheckField: SendCheckField = {
			id: props.field.id,
			value: props.field.value,
			data: cleanedValue,
		};

		props.updateValue(sendCheckField, isValid);
	}, [value, isValid]);

	useEffect(() => {
		if (!isValidAdditional) {
			setIsValidAdditional(true);
		}
	}, [value]);

	useEffect(() => {
		if (postCheckCreateErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckCreateErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckCreateErrors]);

	useEffect(() => {
		if (postCheckUpdateErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckUpdateErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckUpdateErrors]);

	useEffect(() => {
		if (postCheckStartErrors) {
			const [isValidAdditional, invalidMessageAdditional] = checKAdditionalValidation(props.field.value, postCheckStartErrors);
			setIsValidAdditional(isValidAdditional);
			setInvalidMessageAdditional(invalidMessageAdditional);
		}
	}, [postCheckStartErrors]);

	return (
		<InputName
			value={value}
			setValue={setValue}
			title={props.field.name}
			inputMask={{
				mask: props.mask,
			}}
			inputStyles={{
				placeholder: props.field.name,
			}}
			inputValidation={{
				isRequired: props.isAllRequired || props.isRequired,
				isEmail: props.type === 'text_email',
				setIsValid,
				showValidation: props.showValidation,
				isValid,
				customRule: [{
					message: invalidMessageAdditional,
					validationCallback() {
						return isValidAdditional;
					},
				}],
			}}
			parts={getParts(props.type)}
		/>
	);
};

export default InputAutocompleteField;
