import React, {useEffect, useState} from 'react';
import {GoToSubCheckButton} from '../GoToSubCheckButton/GoToSubCheckButton';
import {SubCheckButton} from '../index';
import {useAppSelector} from '../../../../../redux/redux-hooks';
import {type SubCheckReport} from '../../../../../models/ICheck';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../../../paths';

type SelectCheckButtonProps = {
	containerId: string | undefined;
	openSubCheck: () => void;
};

export const SelectCheckButton: React.FC<SelectCheckButtonProps> = ({openSubCheck, containerId}) => {
	const subCheck = useAppSelector(state => state.getCheckReportById.processedResponse?.subchecks);
	const [selectedSubCheck, setSelectedSubCheck] = useState<SubCheckReport | undefined>(undefined);
	const navigate = useNavigate();

	useEffect(() => {
		if (subCheck && containerId) {
			setSelectedSubCheck(subCheck[containerId]);
		}
	}, [subCheck]);

	const goToSubCheck = () => {
		if (typeof selectedSubCheck?.check_id === 'number') {
			navigate(paths.report.replace(':id', selectedSubCheck.check_id.toString()));
		}
	};

	return (
		<>
			{containerId && (
				selectedSubCheck
					? <GoToSubCheckButton onClick={goToSubCheck} isLoading={selectedSubCheck.check_status !== 'verified'}/>
					: <SubCheckButton onClick={openSubCheck}/>
			)
			}
		</>
	);
};
