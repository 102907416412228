import React, {type ChangeEvent, useState, useRef, useEffect} from 'react';
import {BsCloudUpload} from 'react-icons/bs';
import {FiX} from 'react-icons/fi';
import {HiOutlineDocumentDownload} from 'react-icons/hi';
import styles from './AddUsersOrEmployeesViaFile.module.css';
import {type Permission, type Department} from '../../models/Schemas';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {postUsersViaFileAction} from '../../redux/action/users';
import {postEmployeesViaFileAction} from '../../redux/action/employees';
import Loader from '../Loader/Loader';
import {ErrorMessage} from '../UiKit/Message';
import {departmentsResetState} from '../../redux/slice/organization';
import {getDepartmentSubordinateResetState} from '../../redux/slice/department';
import {getGroupPermission} from '../../utils';
import {typePermissions} from '../../models/Enum';
import {getDepartmentsAction} from '../../redux/action/organization';
import {getDepartmentSubordinateAction} from '../../redux/action/department';
import {notifyMessage} from '../UiKit/Toast/notifyMessage';
import SelectDepartment from '../CardRegister/SelectDepartment/SelectDepartment';
import {ButtonTemplate} from '../UiKit/Button';
import {postEmployeesViaFileResetState} from '../../redux/slice/employees';
import {postUsersViaFileResetState} from '../../redux/slice/users';
import {ButtonInput} from '../UiKit/Inputs';

type AddUsersOrEmployeesViaFileProps = {
	isAddUsers: boolean;
};

type ResponseType = {
	invited: number;
	errors: Record<string, string>;
};

const AddUsersOrEmployeesViaFile: React.FC<AddUsersOrEmployeesViaFileProps> = ({isAddUsers}) => {
	const currentRequestId = useRef<string | undefined>(undefined);
	const [showValidation, setShowValidation] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
	const [error, setError] = useState<string | undefined>();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [loadMessage, setLoadMessage] = useState('');
	const [countInvited, setCountInvited] = useState<number | undefined>(undefined);
	const [emailsError, setEmailsError] = useState<string[]>([]);

	const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>(undefined);
	const [selectedDepartmentIsValid, setSelectedDepartmentIsValid] = useState<boolean>(false);
	const [departmentOptions, setDepartmentOptions] = useState<Department[] | undefined>(undefined);
	const [disableSelect, setDisableSelect] = useState(true);
	const [massCreate, setMassCreate] = useState<Permission | undefined>(undefined);

	const departmentsAll = useAppSelector(state => state.departments);
	const getDepartmentsSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const myDepartment = useAppSelector(state => state.getUser.user?.department);
	const navigationContext = useAppSelector(state => state.navigationContext);

	const postUserViaFile = useAppSelector(state => state.postUserViaFile);

	const postEmployeesViaFile = useAppSelector(state => state.postEmployeesViaFile);

	const dispatch = useAppDispatch();

	const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}

		if (file && file.name.endsWith('.csv')) {
			setSelectedFile(file);
			setError(undefined);
		} else {
			setSelectedFile(undefined);
			setError('Пожалуйста, выберите файл с расширением .csv');
		}
	};

	const handleSelectFileClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const onDropFile = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];

		if (file && file.name.endsWith('.csv')) {
			setSelectedFile(file);
			setError(undefined);
		} else {
			setSelectedFile(undefined);
			setError('Пожалуйста, выберите файл с расширением .csv');
		}
	};

	const handleSendFile = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (selectedDepartmentIsValid && selectedDepartment && selectedFile) {
			if (isAddUsers) {
				setLoadMessage('Добавление пользователей...');
				currentRequestId.current = dispatch(
					postUsersViaFileAction({
						body: {file: selectedFile, department_id: selectedDepartment.id},
					}),
				).requestId;
			} else {
				setLoadMessage('Добавление сотрудников...');
				currentRequestId.current = dispatch(
					postEmployeesViaFileAction({
						body: {file: selectedFile, department_id: selectedDepartment.id},
					}),
				).requestId;
			}
		}
	};

	const getMessage = (response: ResponseType | undefined) => {
		if (response) {
			const {invited, errors} = response;
			const listError: string[] = [];
			for (const key in errors) {
				if (Object.hasOwn(errors, key)) {
					const message = errors[key];
					listError.push(`${key}: ${message}`);
				}
			}

			setCountInvited(invited);
			setEmailsError(listError);
			if (listError.length > 0) {
				notifyMessage('error',
					`Ошибка добавления ${isAddUsers ? 'пользователей' : 'сотрудников'}. Проверьте корректность заполнения шаблона`);
			} else {
				notifyMessage('success',
					isAddUsers ? 'Приглашения на регистрацию успешно отправлены'
						: 'Профили сотрудников созданы успешно');
			}
		}
	};

	const handleDeleteFile = () => {
		setSelectedFile(undefined);
		setError(undefined);
	};

	useEffect(() => {
		dispatch(postEmployeesViaFileResetState());
		dispatch(postUsersViaFileResetState());
		handleDeleteFile();
		if (showValidation) {
			setShowValidation(false);
		}
	}, []);

	useEffect(() => {
		if (postUserViaFile.responseStatus.isCompleted && currentRequestId.current && currentRequestId.current === postUserViaFile.responseStatus.requestId) {
			currentRequestId.current = undefined;
			getMessage(postUserViaFile.response);
		}
	}, [postUserViaFile.responseStatus.isLoading]);

	useEffect(() => {
		if (postEmployeesViaFile.responseStatus.isCompleted && currentRequestId.current === postEmployeesViaFile.responseStatus.requestId) {
			currentRequestId.current = undefined;
			getMessage(postEmployeesViaFile.response);
		}
	}, [postEmployeesViaFile.responseStatus.isLoading]);

	// Проверка прав на отдел
	useEffect(() => {
		void dispatch(getDepartmentSubordinateResetState());
		void dispatch(departmentsResetState());

		if (getPermissions.responseStatus.isCompleted) {
			const permission = getGroupPermission(
				isAddUsers
					? typePermissions.user_mass_create
					: typePermissions.employee_mass_create,
				getPermissions.groupPermissions);
			setMassCreate(permission);
			if (permission) {
				if (permission.permission_zone === 'all') {
					void dispatch(getDepartmentsAction());
				} else if (permission.permission_zone === 'only_my_department') {
					if (myDepartment) {
						setDepartmentOptions([myDepartment]);
						setSelectedDepartment(myDepartment);
					}

					setDisableSelect(true);
				} else if (permission.permission_zone === 'only_owner') {
					void dispatch(getDepartmentSubordinateAction());
				}
			}
		}
	}, [getPermissions.responseStatus.isLoading]);

	// Получение всех отделов
	useEffect(() => {
		if (departmentsAll.isCompleted && massCreate?.permission_zone === 'all') {
			setDepartmentOptions(departmentsAll.departments?.data);
			setDisableSelect(false);
		}
	}, [departmentsAll.isLoading, massCreate]);

	// Получение подчинённых отделов
	useEffect(() => {
		if (getDepartmentsSubordinate.responseStatus.isCompleted && massCreate?.permission_zone === 'only_owner') {
			setDepartmentOptions(getDepartmentsSubordinate.response);
			setDisableSelect(false);
		}
	}, [getDepartmentsSubordinate.responseStatus.isLoading, massCreate]);

	useEffect(() => {
		setSelectedDepartment(navigationContext.department);
		setSelectedDepartmentIsValid(true);
	}, [navigationContext.department]);

	return (
		<div className={styles.content}>
			<div className={styles['department-group']}>
				<div className={styles.department}>
					<ButtonInput
						value={selectedDepartment?.name}
						disabled
						title='отдел'
						isRequired
						inputStyles={{
							placeholder: 'Отдел',
						}}
					/>
				</div>

				<div
					className={styles['drop-zone']}
					onDragOver={e => {
						e.preventDefault();
					}}
					onDrop={onDropFile}
				>
					<BsCloudUpload />
					<div
						className={styles['select-file']}
						onDragOver={e => {
							e.preventDefault();
						}}
						onDrop={onDropFile}
					>
						<span onClick={handleSelectFileClick}>Выберите файл</span>
						<input
							type='file'
							id='fileInput'
							style={{display: 'none'}}
							accept='.csv'
							onChange={onFileInputChange}
							ref={fileInputRef}
						/>
						<span>или перетащите сюда</span>
					</div>
				</div>

				{selectedFile && !error && (
					<div className={styles.file}>
						<div className={styles['file-name']}>
							<HiOutlineDocumentDownload />
							<span>{selectedFile.name}</span>
						</div>
						<FiX onClick={handleDeleteFile} />
					</div>
				)}
			</div>
			<div className={styles['btn-group']}>
				<ButtonTemplate
					onClick={handleSendFile}
					color='primary'
					text='Загрузить из файла'
					disable={!selectedFile}
				/>
			</div>
			<ErrorMessage
				message={error ?? ''}
				isError={Boolean(error)}
			/>
			<ErrorMessage
				message={postUserViaFile.responseStatus.errorMessage}
				isError={postUserViaFile.responseStatus.isError}
			/>
			<ErrorMessage
				message={postEmployeesViaFile.responseStatus.errorMessage}
				isError={postEmployeesViaFile.responseStatus.isError}
			/>
			{postUserViaFile.responseStatus.isCompleted || postEmployeesViaFile.responseStatus.isCompleted
				? <div className={styles.message}>
					<span className={styles.ok}>
						{`Успешно выполнено ${countInvited} запросов`}
					</span>
					{emailsError.map((item, index) => (
						<span key={index} className={styles.error}>
							{item}
						</span>))}
				</div>
				: null}
			<Loader
				text={loadMessage}
				isFloating
				isLoading={postEmployeesViaFile.responseStatus.isLoading || postUserViaFile.responseStatus.isLoading}
			/>
		</div>
	);
};

export default AddUsersOrEmployeesViaFile;
