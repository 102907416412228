import {type HeadersCheckReport} from '../../../../report/ReportModel';
import {type ReportModelV3PassportRf} from '../../../../report/reportModelV3/reportModelV3';

export const passportRFBasicInfoFields: Array<HeadersCheckReport<keyof ReportModelV3PassportRf>> = [
	{label: 'ФИО', key: 'full_name'},
	{label: 'Страна', key: 'country'},
	{label: 'Серия и номер', key: 'passport'},
	{label: 'Дата рождения', key: 'birth_date'},
	{label: 'Дата выдачи', key: 'issue_date'},
	{label: 'Адрес регистрации', key: 'registration_address'},
	{label: 'Выдан', key: 'issuing_authority'},
	{label: 'Код подразделения', key: 'department_code'},
	{label: 'Дополнительная информация', key: 'additional_info'},
];
