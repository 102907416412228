import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postPerson152fz} from '../../../api/Person/person152fzApi';
import {
	type PostPerson152fzResponse, type PostPerson152fzRequest,
} from '../../../models/Person/PersonDocuments/IPersonDocuments';

const postPerson152fzAction = createActionTemplateAxios<
PostPerson152fzResponse,
PostPerson152fzRequest
>(
	'person/postPerson152fz/action',
	postPerson152fz,
	'userToken',
);

export default postPerson152fzAction;
