import React, {useEffect, useRef, useState} from 'react';
import styles from './SelectContextNavigation.module.css';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {getNavigationAction, getNavigationCompaniesAction, getNavigationDepartmentsAction} from '../../../../redux/action/navigation';
import {SelectDepartment} from '../../../CardRegister/SelectDepartment';
import {type NavigationCompany, type NavigationDepartment} from '../../../../models/Navigation/Navigation';
import {saveNavigationCookies} from '../../../../utils/cookies/';
import SelectNavigationCompany from './SelectNavigationCompany/SelectNavigationCompany';
import {LuPanelLeftOpen} from 'react-icons/lu';
import {TooltipTemplate} from '../../Message';
import {useEffectHandlerLoadingAllRequest} from '../../../../utils/hooks';
import Loader from '../../../Loader/Loader';
import {setNavigationContext} from '../../../../redux/slice/navigationContextSlice';

type SelectContextNavigationProps = {
	showSelect: boolean;
	toggleSideBar: () => void;
};

const SelectContextNavigation: React.FC<SelectContextNavigationProps> = ({
	showSelect,
	toggleSideBar,
}) => {
	const dispatch = useAppDispatch();
	const getNavigationCompanies = useAppSelector(state => state.getNavigationCompanies);
	const getNavigationDepartments = useAppSelector(state => state.getNavigationDepartments);
	const getNavigation = useAppSelector(state => state.getNavigation);

	const postDepartment = useAppSelector(state => state.postDepartment);
	const putDepartment = useAppSelector(state => state.putDepartment);

	const containerRef = useRef<HTMLDivElement>(null);
	const departmentIsUpdate = useRef<boolean>(false);
	const companyHash = useRef<string>();
	const lastCompany = useRef<NavigationCompany>();
	const lastDepartment = useRef<NavigationDepartment>();

	const [openSelectDepartment, setOpenSelectDepartment] = useState<boolean>(false);
	const [selectedCompany, setSelectedCompany] = useState<NavigationCompany>();
	const [department, setDepartment] = useState<NavigationDepartment>();

	const onSelectCompany = (company: NavigationCompany) => {
		setSelectedCompany(company);
		companyHash.current = company.navigation_hash;
		setDepartment(undefined);
		setOpenSelectDepartment(true);
	};

	const onSelectDepartment = (department: NavigationDepartment | undefined) => {
		lastCompany.current = selectedCompany;
		lastDepartment.current = department;
		setDepartment(department);
		departmentIsUpdate.current = true;
	};

	// При первом рендер
	useEffect(() => {
		void dispatch(getNavigationAction());
		void dispatch(getNavigationCompaniesAction());
	}, []);

	// Обработка получения текущего контекста
	useEffectHandlerLoadingAllRequest({
		state: getNavigation,
		onComplete(response) {
			companyHash.current = response?.navigation_company_hash;
			lastCompany.current = response?.company
				? {
					...response.company,
					navigation_hash: response.navigation_company_hash,
				}
				: undefined;
			lastDepartment.current = response?.department
				? {
					...response.department,
					navigation_hash: response.navigation_department_hash,
				}
				: undefined;
			setSelectedCompany(lastCompany.current);
			setDepartment(lastDepartment.current);
			if (!lastDepartment.current) {
				dispatch(setNavigationContext({
					company: selectedCompany,
					department,
				}));
				saveNavigationCookies({
					navigation_company_hash: lastCompany.current?.navigation_hash,
				});
			}
		},
	});

	// При изменении отдела
	useEffect(() => {
		if (department) {
			saveNavigationCookies({
				navigation_company_hash: selectedCompany?.navigation_hash,
				navigation_department_hash: department?.navigation_hash,
			});
			dispatch(setNavigationContext({
				company: selectedCompany,
				department,
			}));
		}
	}, [department]);

	// При открытии/закрытии модального окна выбора отдела
	useEffect(() => {
		if (openSelectDepartment) {
			void dispatch(getNavigationDepartmentsAction({navigation_company_hash: companyHash.current}));
		} else if (departmentIsUpdate.current) {
			departmentIsUpdate.current = false;
		} else {
			const {response} = getNavigation;
			setSelectedCompany(lastCompany.current);
			setDepartment(lastDepartment.current);
		}
	}, [openSelectDepartment]);

	useEffectHandlerLoadingAllRequest({
		state: postDepartment,
		onComplete() {
			void dispatch(getNavigationDepartmentsAction({navigation_company_hash: companyHash.current}));
		},
	});

	useEffectHandlerLoadingAllRequest({
		state: putDepartment,
		onComplete() {
			void dispatch(getNavigationDepartmentsAction({navigation_company_hash: companyHash.current}));
		},
	});

	if (showSelect) {
		return (
			<div className={styles.container} ref={containerRef}>
				{
					(selectedCompany
				?? (getNavigationCompanies.response && getNavigationCompanies.response?.length > 0))
				&& (
					<SelectNavigationCompany
						selectedCompany={selectedCompany}
						setSelectedCompany={onSelectCompany}
					/>
				)}
				{(
					selectedCompany
					?? (!getNavigationCompanies.response
					|| getNavigationCompanies.response?.length === 0)
				) && (
					<SelectDepartment
						selectedItem={department}
						setSelectedItem={e => {
							onSelectDepartment(e as NavigationDepartment);
						}}
						departmentOptions={getNavigationDepartments.response ?? []}
						departmentOptionsIsLoadingOnlyModal={getNavigationDepartments.responseStatus.isLoading}
						stylingMode='underlined'
						isOpenModal={openSelectDepartment}
						setIsOpenModal={setOpenSelectDepartment}
						allowAddDepartments
						company_id={selectedCompany?.id}
					/>
				)}
				<Loader isFloating isLoading={getNavigation.responseStatus.isLoading} />
			</div>
		);
	}

	return (
		<div>
			<div onClick={toggleSideBar} className={styles.container} ref={containerRef}>
				<LuPanelLeftOpen />
			</div>
			<TooltipTemplate
				targetRef={containerRef}
			>
				Раскрыть навигацию
			</TooltipTemplate>
		</div>

	);
};

export default SelectContextNavigation;
