import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {type GetPerson152fzDocumentsResponse} from '../../../models/Person/PersonDocuments/IPersonDocuments';
import {getPerson152fzDocumentsAction} from '../../action/person';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetPerson152fzDocumentsResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getPerson152fzDocumentsSlice = createSlice({
	name: 'person/getPerson152fzDocuments',
	initialState,
	reducers: {
		getPerson152fzDocumentsResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getPerson152fzDocumentsAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getPerson152fzDocumentsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(getPerson152fzDocumentsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getPerson152fzDocumentsResetState} = getPerson152fzDocumentsSlice.actions;
export default getPerson152fzDocumentsSlice.reducer;
