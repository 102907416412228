import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {putGuestAction} from '../../action/guest';
import {type PutGuestResponse} from '../../../models/Guest/Guest/IGuest';

const initialState: {
	responseStatus: ResponseStatus;
	response: PutGuestResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const putGuestSlice = createSlice({
	name: 'guest/putGuest',
	initialState,
	reducers: {
		putGuestResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(putGuestAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(putGuestAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(putGuestAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {putGuestResetState} = putGuestSlice.actions;
export default putGuestSlice.reducer;
