import {
	type GetDepartmentPositionsRequest,
	type GetDepartmentPositionsResponse,
} from '../../../models/IDepartment';
import {getDepartmentPositions} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getDepartmentPositionsAction = createActionTemplateAxios<
GetDepartmentPositionsResponse,
GetDepartmentPositionsRequest>(
	'department/getDepartmentPositions/action',
	getDepartmentPositions,
	'userToken',
);

export default getDepartmentPositionsAction;
