import React, {useMemo, useRef} from 'react';
import styles from './ChartTemplate.module.css';
import {Chart} from 'devextreme-react';
import {
	ArgumentAxis,
	CommonSeriesSettings,
	type IArgumentAxisProps,
	type IValueAxisProps,
	Point,
	Series,
	Tooltip,
	ValueAxis,
} from 'devextreme-react/cjs/chart';
import {type GradientColor, registerGradient, type SeriesPoint} from 'devextreme/common/charts';
import {type ChartCommonProps, type ChartCustomizeTooltipData, type ChartCustomizeTooltipReturn} from '..';

export type ChartPointProps = {
	visible: boolean;
	size?: number;
	color?: string;
	hoverStyle?: {
		border: {
			color: string;
		};
		color: string;
	};
};

export type ChartSeriesProps = {
	color?: string;
	gradientCalor?: {
		base: string;
		type: 'linear' | 'radial';
		colors: GradientColor[];
		rotationAngle: number;
	};
	showInLegend?: boolean;
	type: 'line' | 'area' | 'bar';
	selectionMode?: 'allSeriesPoints' | 'none';
	hoverMode?: 'allSeriesPoints' | 'nearestPoint' | 'none';
	valueField?: string;
	argumentField?: string;
	cornerRadius?: number;
	barWidth?: number;
	width?: number;
};

export type ChartSeriesList = {
	seriesProps: ChartSeriesProps;
	pointProps?: ChartPointProps;
};

export type ChartCommonSeriesSettingsProps = {
	valueField: string;
	argumentField: string;
};

export type CustomizePointData = {
	value: any;
};

export type ChartTemplateProps = {
	dataSource: any[];
	commonSeriesSettings: ChartCommonSeriesSettingsProps;
	series: ChartSeriesList[];
	customizePoint?: (customizePointData: CustomizePointData) => SeriesPoint;
	height?: number | string;
	width?: number | string;
	valueAxis?: IValueAxisProps;
	argumentAxis?: IArgumentAxisProps;
} & ChartCommonProps;

const ChartTemplate: React.FC<ChartTemplateProps> = ({
	dataSource,
	commonSeriesSettings,
	series,
	tooltip,
	children,
	customizePoint,
	containerRef,
	height,
	width,
	valueAxis,
	argumentAxis,
}) => {
	const chartRef = useRef<Chart>(null);

	const defaultCustomizeTooltip = (data: ChartCustomizeTooltipData): ChartCustomizeTooltipReturn => {
		const argument = String(data?.argument ?? '');
		const valueText = String(data?.valueText ?? '');

		return {
			text: `
			${tooltip?.argumentLabel}: ${argument}
			${tooltip?.valueLabel}: ${valueText}`,
		};
	};

	const seriesList = useMemo(() => series.map((item, index) => {
		const {seriesProps, pointProps} = item;
		const {gradientCalor} = seriesProps;

		const seriesColor = gradientCalor ? {
			base: gradientCalor?.base,
			fillId: registerGradient(gradientCalor?.type, {
				colors: gradientCalor.colors,
				rotationAngle: gradientCalor.rotationAngle,
			}),
		} : seriesProps.color;

		return (
			<Series
				key={'series' + index}
				color={seriesColor}
				{...seriesProps}
			>
				{pointProps && <Point {...pointProps}/>}
			</Series>
		);
	}), [series]);

	return (
		<>
			<Chart
				dataSource={dataSource}
				ref={chartRef}
				className={styles.chart}
				customizePoint={customizePoint}
				stickyHovering
				height={height}
				width={width}
			>
				<CommonSeriesSettings
					{...commonSeriesSettings}
				/>
				<ValueAxis
					label={{
						font: {
							family: 'var(--font-regular)',
						},
					}}
					{...valueAxis}
				/>
				<ArgumentAxis
					label={{
						font: {
							family: 'var(--font-regular)',
						},
					}}
					{...argumentAxis}
				/>
				{seriesList}
				<Tooltip
					enabled={tooltip?.enabled}
					customizeTooltip={tooltip?.customizeTooltip ?? defaultCustomizeTooltip}
					font={{
						size: 14,
						family: 'var(--font-regular)',
					}}
				/>
				{children}
			</Chart>
		</>
	);
};

export default ChartTemplate;
