import React from 'react';
import styles from './RadioSelectorItem.module.css';

type RadioSelectorItemProps = {
	text: string;
	selectedItemKey: number | undefined;
	setSelectedItemKey: (value: number | undefined) => void;
	itemKey: number;
};

const RadioSelectorItem: React.FC<RadioSelectorItemProps> = ({
	text,
	selectedItemKey,
	setSelectedItemKey,
	itemKey,
}) => {
	const isSelect = selectedItemKey === itemKey;

	const onSelect = () => {
		if (selectedItemKey !== itemKey) {
			setSelectedItemKey(itemKey);
		}
	};

	return (
		<label onClick={onSelect} className={styles.container} data-checked={isSelect}>
			<input
				className={styles['radio-input']}
				type='radio'
				checked={isSelect}
				onChange={onSelect}
			/>
			{text}
		</label>
	);
};

export default RadioSelectorItem;
