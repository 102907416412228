import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {
	type GetChallengerRequest,
	type GetChallengerResponse,
} from '../../../models/IChallengers';
import {getChallenger} from '../../../api/challengersApi';

const getChallengerAction = createActionTemplateAxios<
GetChallengerResponse,
GetChallengerRequest
>('challengers/getChallenger', getChallenger, 'userToken');

export default getChallengerAction;
