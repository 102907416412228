import {type CheckAddresses, type HeadersCheckReport} from '../../../../report/ReportModel';

export const addressBasicInfo: Array<HeadersCheckReport<keyof CheckAddresses>> = [
	{label: 'Полный адрес', key: 'result', clipboard: true},
	{label: 'Страна', key: 'country'},
	{label: 'Индекс', key: 'index'},
	{label: 'Федеральный округ', key: 'federal_district'},
	{label: 'Регион', key: 'region'},
	{label: 'Область', key: 'area'},
	{label: 'Город', key: 'city'},
	{label: 'Улица', key: 'street'},
	{label: 'Строение', key: 'building'},
	{label: 'Дом', key: 'house'},
	{label: 'Подъезд', key: 'porch'},
	{label: 'Этаж', key: 'floor'},
	{label: 'Помещение', key: 'apartment'},
	{label: 'КЛАДР региона', key: 'region_kladr_id'},
	{label: 'КЛАДР  области', key: 'area_kladr_id'},
	{label: 'КЛАДР города', key: 'city_kladr_id'},
	{label: 'КЛАДР района', key: 'city_district_kladr_id'},
	{label: 'КЛАДР улицы', key: 'street_kladr_id'},
	{label: 'КЛАДР дома', key: 'house_kladr_id'},
];
