import {getNavigation} from '../../../api/Navigation/navigationApi';
import {type GetNavigationResponse} from '../../../models/Navigation/Navigation';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getNavigationAction = createActionTemplateAxios<
GetNavigationResponse,
void
>('navigation/getNavigation/action', getNavigation, 'userToken');

export default getNavigationAction;
