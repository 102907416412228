import React from 'react';
import styles from './UserIcon.module.css';

type UserIconProps = {
	label?: string;
	size?: 'large' | 'medium' | 'small';
	round?: boolean;
	onClick?: () => void;
};

const UserIcon: React.FC<UserIconProps> = ({
	label = '',
	size = 'medium',
	round = false,
	onClick,
}) => {
	const getRootClassnames = () => {
		let className = `${styles.container} ${styles[size]}`;
		if (round) {
			className += ` ${styles.round}`;
		}

		if (onClick) {
			className += ` ${styles.click}`;
		}

		return className;
	};

	return (
		<div className={getRootClassnames()} onClick={onClick}>
			<span className={styles['icon-label']}>{label}</span>
		</div>
	);
};

export default UserIcon;
