import React from 'react';
import styles from '../BasicInfo.module.css';
import {addressBasicInfo} from './AddressBasicInfoFields';
import {CopyToClipboardButton} from '../../../UiKit/Button';
import {type CheckAddresses} from '../../../../report/ReportModel';

type AddressBasicInfoProps = {
	address: CheckAddresses;
};

const AddressBasicInfo: React.FC<AddressBasicInfoProps> = ({
	address,
}) => (
	<div className={styles.data}>
		{addressBasicInfo.map((field, idx) => {
			const addressField = address[field.key];
			if (addressField) {
				return (
					<React.Fragment key={idx}>
						<span className={styles.abbreviation}>
							{field.label}:
						</span>
						<div className={styles['result-container']}>
							<span className={styles.result}>
								{addressField}
							</span>
							{field.clipboard && <CopyToClipboardButton text={addressField} />}
						</div>
					</React.Fragment>
				);
			}

			return undefined;
		})}
	</div>
);

export default AddressBasicInfo;
