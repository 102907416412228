import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postOfficialPermissions} from '../../../api/permissionsApi';
import {
	type PostOfficialPermissionsResponse,
	type PostOfficialPermissionsRequest,
} from '../../../models/IPermissions';

const postOfficialPermissionsAction = createActionTemplateAxios<
PostOfficialPermissionsResponse,
PostOfficialPermissionsRequest
>('permissions/postOfficialPermissions/action', postOfficialPermissions, 'userToken');

export default postOfficialPermissionsAction;
