import React, {type ReactElement} from 'react';
import styles from './AccountLink.module.css';
import {formatPhoneNumber} from '../../../../utils';
import {type AccountTypes} from '../../../../report/reportModelEnum';
import {odnoklassnikiIcon, telegramIcon, viberIcon, vkIcon, WhatsappIcon} from '../../Icons';
import {TbUserQuestion} from 'react-icons/tb';

type AccountLinkProps = {
	link: string;
	linkStyle?: string;
	type?: AccountTypes;
	textLink?: string;
	userName?: string;
};

const icons: Record<AccountTypes, ReactElement> = {
	vk: vkIcon,
	vk_email: vkIcon,
	vk_phone: vkIcon,
	whatsapp_phone: WhatsappIcon,
	ok_phoneapp: odnoklassnikiIcon,
	ok: odnoklassnikiIcon,
	viber_phone: viberIcon,
	viber: viberIcon,
	telegram: telegramIcon,
	default: <TbUserQuestion />,
};

// eslint-disable-next-line complexity
const getAccountLink = (
	type: string | AccountTypes,
	id = '',
	textLink?: string,
	nickname = '',
) => {
	// Является ли значение ссылкой
	const isLink = (value: string | undefined) => value && (
		value.startsWith('http://')
		|| value.startsWith('https://')
		|| value.startsWith('mailto:')
		|| value.startsWith('tel:')
		|| value.startsWith('viber://')
	);

	let href;
	let text;

	if (isLink(id)) {
		href = id;
		text = id;
	} else if (isLink(nickname)) {
		href = nickname;
		text = nickname;
	} else {
		switch (type) {
			case 'vk':
				if (id) {
					href = `https://vk.com/id${id}`;
				}

				text = (nickname || href) ?? 'vk';
				break;
			case 'vk_email':
				if (id) {
					href = `mailto:${id}`;
				}

				text = id || nickname;
				break;
			case 'vk_phone':
				if (id) {
					href = `tel:${id}`;
				}

				text = formatPhoneNumber(id || nickname);
				break;
			case 'whatsapp_phone':
				if (id || nickname) {
					href = `https://wa.me/${id || nickname}`;
				}

				text = formatPhoneNumber(nickname || id);
				break;
			case 'ok':
				if (id) {
					href = `https://ok.ru/profile/${id}`;
				}

				text = (nickname || href) ?? 'ok';
				break;
			case 'ok_phoneapp':
				if (id) {
					href = `https://ok.ru/profile/${id}`;
				}

				text = formatPhoneNumber(nickname || id);
				break;
			case 'viber':
			case 'viber_phone':
				if (id || nickname) {
					href = `viber://chat?number=${id || nickname}`;
				}

				text = formatPhoneNumber(id || nickname);
				break;
			case 'telegram':
				href = `https://t.me/${id || nickname}`;
				text = id || nickname;
				break;
			default:
				return null;
		}
	}

	return {href, text: textLink ?? text};
};

const AccountLink: React.FC<AccountLinkProps> = ({
	link,
	linkStyle,
	type = 'default',
	textLink,
	userName,
}) => {
	const linkData = getAccountLink(type, link, textLink, userName);
	const icon = icons[type] ?? icons.default;

	if (linkData?.href) {
		return (
			<>
				<a
					href={linkData?.href}
					className={`${styles.link} ${linkStyle}`}
					target='_blank'
					rel='noreferrer'
					onClick={e => {
						e.stopPropagation();
					}}
				>
					{icon}
					<span>
						{linkData?.text}
					</span>
				</a>
			</>
		);
	}

	return (
		<span className={`${styles.link} ${styles['nick-name']} ${linkStyle}`}>
			{icon}
			<span>
				{userName}
			</span>
		</span>
	);
};

export default AccountLink;
