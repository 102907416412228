import React, {useEffect, useState} from 'react';
import {Input, InputAddress, InputPhone} from '../../../UiKit/Inputs';
import styles from './CreateCheckIndividualAddressForm.module.css';
import {
	type InteractiveSwitch,
	type InteractiveFieldValues,
	type InteractiveScreen,
} from '../../../../models/IInteractive';

type CreateCheckIndividualAddressFormProps = {
	fields: React.MutableRefObject<InteractiveFieldValues | undefined>;
	currentScreen: React.MutableRefObject<InteractiveScreen | undefined>;
	currentSwitch?: React.MutableRefObject<InteractiveSwitch | undefined>;
	showValidation: boolean;
	errors?: Record<string, string>;
};

const CreateCheckIndividualAddressForm: React.FC<CreateCheckIndividualAddressFormProps> = ({
	fields,
	currentScreen,
	currentSwitch,
	showValidation,
	errors,
}) => {
	const [address, setAddress] = useState('');
	const [isValid, setIsValid] = useState(false);

	// Изменение данных экранна
	useEffect(() => {
		if (currentScreen.current) {
			const fieldAddress = currentScreen.current.default_values.address;

			if (typeof fieldAddress === 'string') {
				setAddress(fieldAddress);
			} else {
				setAddress('');
			}
		}
	}, [currentScreen.current]);

	// Обновление значений
	useEffect(() => {
		if (fields.current) {
			fields.current.address = address;
		}
	}, [address]);

	return (
		<div className={styles.container}>
			<div className={styles.group}>
				<h1 className={styles.title}>
					Адрес
				</h1>
				<div className={styles.inputs}>
					<InputAddress
						value={address}
						setValue={setAddress}
						inputStyles={{
							placeholder: 'Введите адрес',
						}}
						inputValidation={{
							isValid,
							setIsValid,
							isRequired: true,
							showValidation,
							isEmail: true,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CreateCheckIndividualAddressForm;
