import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostUserPermissionsResponse} from '../../../models/IPermissions';
import {postUserPermissionsAction} from '../../action/permissions';

const initialState: {
	responseStatus: ResponseStatus;
	response: PostUserPermissionsResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const postUserPermissionsSlice = createSlice({
	name: 'permissions/postUserPermissions',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(postUserPermissionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postUserPermissionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postUserPermissionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export default postUserPermissionsSlice.reducer;
