import React from 'react';
import styles from '../BasicInfo.module.css';
import {type ReportModelV3Phone} from '../../../../report/reportModelV3/reportModelV3';
import {phoneBasicInfoFields} from './PhoneBasicInfoFields';
import {Phone} from '../../../UiKit/Message';
import {CopyToClipboardButton} from '../../../UiKit/Button';

type PhoneBasicInfoProps = {
	phone: ReportModelV3Phone;
};

const PhoneBasicInfo: React.FC<PhoneBasicInfoProps> = ({
	phone,
}) => (
	<div className={styles.data}>
		{phoneBasicInfoFields.map((field, idx) => {
			const phoneField = phone[field.key];
			if (phoneField) {
				return (
					<React.Fragment key={idx}>
						<span className={styles.abbreviation}>
							{field.label}:
						</span>
						<div className={styles['result-container']}>
							{field.key === 'phone_number' ? (
								<Phone phone={phoneField} formatPhone />
							) : (
								<span className={styles.result}>
									{phoneField}
								</span>
							)}
							{field.clipboard && <CopyToClipboardButton text={phoneField} format='phone' />}
						</div>
					</React.Fragment>
				);
			}

			return undefined;
		})}
	</div>
);

export default PhoneBasicInfo;
