import React, {type ReactNode, useMemo} from 'react';
import styles from './Accounts.module.css';
import {type CheckAccounts} from '../../../../report/ReportModel';
import {MdOutlineAccountCircle} from 'react-icons/md';
import {useAppSelector} from '../../../../redux/redux-hooks';
import {AccountLink} from '../../../UiKit/Message';
import ReportBlockTemplate from '../ReportBlockTemplate';

type AccountsProps = {
	accounts?: CheckAccounts[] | undefined;
};

export const Accounts: React.FC<AccountsProps> = () => {
	const accounts = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.accounts);

	const accountLinks = useMemo(() => {
		const links: ReactNode[] = [];
		if (accounts?.data) {
			accounts.data.forEach((account, index) => {
				if (account.item.id || account.item.nick_name) {
					links.push(
						<div key={index} className={styles['nick-name']}>
							<div className={styles.info}>
								<AccountLink link={account.item.id} type={account.item.type} userName={account.item.nick_name}/>
							</div>
						</div>,
					);
				}
			});
		}

		return links;
	}, [accounts]);

	return (
		<ReportBlockTemplate
			blockIcon={MdOutlineAccountCircle}
			blockTitle='Аккаунты'
			alerts={accounts?.additionalData.alerts}
			blocksId='accounts'
			visible={accountLinks.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['name-group']}>
					{accountLinks}
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
