import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {deleteDepartment} from '../../../api/departmentApi';
import {
	type DeleteDepartmentRequest,
	type DeleteDepartmentResponse,
} from '../../../models/IDepartment';

const deleteDepartmentAction = createActionTemplateAxios<
DeleteDepartmentResponse,
DeleteDepartmentRequest
>('department/deleteDepartment/action', deleteDepartment, 'userToken');

export default deleteDepartmentAction;
