import React, {useEffect, useRef, useState} from 'react';
import styles from './UserComment.module.css';
import {UserIcon} from '../../UiKit/Div';
import {getFullName} from '../../../utils';
import {MdDelete, MdEdit} from 'react-icons/md';
import {type Comment} from '../../../models/IComment';
import {DateTime} from 'luxon';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import Loader from '../../Loader/Loader';
import {deleteCommentByIdAction} from '../../../redux/action/comments';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';
import ContextMenuTemplate from '../../UiKit/ContextMenu/ContextMenu';

type UserCommentProps = {
	comment: Comment;
	editMessage: (id: number) => void;
	sendRequestGetComments: () => void;
};

export const UserComment: React.FC<UserCommentProps> = ({
	comment,
	editMessage,
	sendRequestGetComments,
}) => {
	const deleteCommentsRequestId = useRef<string | undefined>(undefined);
	const dispatch = useAppDispatch();
	const deleteCommentByIdState = useAppSelector(state => state.deleteCommentById);
	const user = useAppSelector(state => state.getUser.user);

	const [isMyComment, setIsMyComment] = useState(false);
	const [isLoadingDeleteComment, setIsLoadingDeleteComment] = useState(false);

	const isMe = user?.id === comment.user_id;

	// Начать изменять комментарий
	const editMessageCallback = () => {
		if (isMyComment) {
			editMessage(comment.id);
		}
	};

	// Отправить запрос на удаление комментария
	const sendRequestDeleteComment = () => {
		if (isMyComment) {
			deleteCommentsRequestId.current = dispatch(deleteCommentByIdAction({
				comment_id: comment.id,
			})).requestId;
		}
	};

	// Получение даты создания/изменения комментария
	const getDate = () => {
		const format = 'dd.MM.yyyy в HH:mm';

		if (comment.updated_at && comment.updated_at !== comment.created_at) {
			return `Изменено ${DateTime.fromISO(comment.updated_at).toFormat(format)}`;
		}

		return DateTime.fromISO(comment.created_at ?? '').toFormat(format);
	};

	// Обработка изменения комментария или пользователя
	useEffect(() => {
		if (user?.id === comment.user_id) {
			setIsMyComment(true);
		} else {
			setIsMyComment(false);
		}
	}, [comment.user_id, user]);

	// Обработка удаления комментария
	useEffect(() => {
		const {responseStatus} = deleteCommentByIdState;
		if (deleteCommentsRequestId.current && deleteCommentsRequestId.current === responseStatus?.requestId) {
			setIsLoadingDeleteComment(responseStatus.isLoading);
			if (responseStatus.isCompleted) {
				sendRequestGetComments();
				notifyMessage('success', 'Комментарий удалён');
			} else if (responseStatus.isError) {
				notifyMessage('error', `Ошибка удаления комментария: ${responseStatus.errorMessage}`);
			}
		}
	}, [deleteCommentByIdState.responseStatus.isLoading]);

	return (
		<div
			className={styles.wrapper}
			data-is-my-comment={isMe}
		>
			<div
				className={`${styles.container}`}
				id={`comment-${comment.id}`}
			>
				<p className={styles.message}>
					{comment.content}
				</p>

				<div className={styles.user}>
					{!isMe && (
						<>
							<div className={styles.avatar}>
								<UserIcon
									size='small'
									label={comment.user?.last_name[0]}
									round
								/>
							</div>
							<span className={styles.header}>
								{getFullName(
									comment.user?.first_name,
									comment.user?.middle_name,
									comment.user?.last_name,
									'LsFsM',
								)}
							</span>
						</>
					)}
				</div>
				<div className={styles.date}>
					<span>
						{getDate()}
					</span>
				</div>
				<Loader
					isFloating
					isLoading={isLoadingDeleteComment}
					borderRadius={isMe ? '12px 0 12px 12px' : '0 12px 12px 12px'}
				/>
			</div>
			{isMe && <ContextMenuTemplate
				targetId={`comment-${comment.id}`}
				items={[
					{
						Icon: MdEdit,
						text: 'Изменить',
						closeMenuOnClick: true,
						onClick() {
							editMessageCallback();
						},
					},
					{
						Icon: MdDelete,
						text: 'Удалить',
						closeMenuOnClick: true,
						onClick() {
							sendRequestDeleteComment();
						},
					},
				]}
			/>}
		</div>
	);
};
