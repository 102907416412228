import React from 'react';
import styles from './AccountsIcons.module.css';
import {FaOdnoklassniki, FaTelegramPlane, FaViber, FaVk, FaWhatsapp} from 'react-icons/fa';

export const vkIcon = (
	<div className={`${styles.icon} ${styles.vk}`}>
		<FaVk />
	</div>
);

export const WhatsappIcon = (
	<div className={`${styles.icon} ${styles.whatsapp}`}>
		<FaWhatsapp />
	</div>
);

export const odnoklassnikiIcon = (
	<div className={`${styles.icon} ${styles.odnoklassniki}`}>
		<FaOdnoklassniki />
	</div>
);

export const viberIcon = (
	<div className={`${styles.icon} ${styles.viber}`}>
		<FaViber />
	</div>
);

export const telegramIcon = (
	<div className={`${styles.icon} ${styles.telegram}`}>
		<FaTelegramPlane />
	</div>
);
