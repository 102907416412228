/* eslint-disable @typescript-eslint/ban-types */
export type AccountTypes = 'vk' | 'whatsapp_phone' | 'ok' | 'ok_phoneapp' | 'viber' | 'viber_phone' | 'vk_email' | 'vk_phone' | 'telegram' | 'default';
export type SocialAccountTypes = 'whatsapp';

export type CheckRegistriesType = 'terrorist_person' | 'fns_disqualified' | 'mvd_wanted' | 'fsin_person' | 'minjust_person';

export type SummaryDataType = 'point' | 'checkbox';

export type SummaryDataStatus = true | false | null | undefined;

export type SubCheckStatus = 'in_progress' | 'verified' | 'collect' | 'wait';

export type BlocksIdsType = (
'basics_info'
| 'summary_data'
| 'address'
| 'accounts'
| 'documents'
| 'transports'
| 'contacts'
| 'advertisements'
| 'court_decisions'
| 'workplaces'
| 'microlans'
| 'name_history'
| 'nicknames'
| 'public_groups'
| 'registries'
| 'social_accounts'
| 'persons'
);

export const blocksIds = {
	basicsInfo: 'basics_info',
	summaryData: 'summary_data',
	address: 'address',
	documents: 'documents',
	transports: 'transports',
	contacts: 'contacts',
	advertisements: 'advertisements',
	courtDecisions: 'court_decisions',
	workplaces: 'workplaces',
	microlans: 'microlans',
	nameHistory: 'name_history',
	nicknames: 'nicknames',
	publicGroups: 'public_groups',
	registries: 'registries',
	accounts: 'accounts',
	socialAccounts: 'social_accounts',
	persons: 'persons',

};
