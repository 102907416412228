import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getUserPermissions} from '../../../api/permissionsApi';
import {
	type GetUserPermissionsResponse,
	type GetUserPermissionsRequest,
} from '../../../models/IPermissions';

const getUserPermissionsAction = createActionTemplateAxios<
GetUserPermissionsResponse,
GetUserPermissionsRequest
>('permissions/getUserPermission/action', getUserPermissions, 'userToken');

export default getUserPermissionsAction;
