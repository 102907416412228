import React from 'react';
import styles from './InputEmployeesAutofillItem.module.css';
import {type GetEmployeesAutofillItem} from '../../../../../../models/IEmployees';

type InputEmployeesAutofillProps = GetEmployeesAutofillItem;

const InputEmployeesAutofillItem: React.FC<InputEmployeesAutofillProps> = ({
	id,
	name,
	department,
}) => (
	<div className={styles.container}>
		<span className={styles.name}>{name}</span>
		<span className={styles.department}>{department}</span>
	</div>
);

export default InputEmployeesAutofillItem;
