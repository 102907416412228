import React, {useEffect, useState} from 'react';
import styles from './ModalEditingPhoneNumber.module.css';
import {sendSmsRegistrationAction} from '../../../redux/action/users';
import {useCookies} from 'react-cookie';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import ModalSixDigitalCode from '../ModalSixDigitalCode';
import {ModalWindow} from '../index';
import {ErrorMessage} from '../../UiKit/Message';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {InputPhone} from '../../UiKit/Inputs';
import {smsRegistrationIsCompleteReset} from '../../../redux/slice/users/smsRegistrationSlice';
import {type KeyDownEvent} from 'devextreme/ui/text_box';

type ModalEditingPhoneNumberProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	visibleModalCode: boolean;
	setVisibleModalCode: React.Dispatch<React.SetStateAction<boolean>>;
	closingModals: () => void;
};

export const ModalEditingPhoneNumber: React.FC<
ModalEditingPhoneNumberProps
> = ({
	visible,
	setVisible,
	setVisibleModalCode,
	closingModals,
	visibleModalCode,
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [cookies] = useCookies(['debug']);
	const [isValid, setIsValid] = useState(false);
	const {isError, isLoading, isCompleted, errorMessage} = useAppSelector(
		state => state.smsRegistration,
	);

	const dispatch = useAppDispatch();

	const handleSendingNumber = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isValid) {
			void dispatch(
				sendSmsRegistrationAction({
					phone: phoneNumber,
					debug: Boolean(cookies.debug),
				}),
			);
		}
	};

	const handleKeyDown = (e: KeyDownEvent) => {
		if (e.event?.key === 'Enter' && isValid) {
			handleSendingNumber();
		}
	};

	useEffect(() => {
		if (isCompleted) {
			setVisibleModalCode(true);
			smsRegistrationIsCompleteReset();
		}
	}, [isLoading]);

	useEffect(() => {
		if (visible) {
			setPhoneNumber('');
		} else {
			setPhoneNumber('');
			setIsValid(false);
			setShowValidation(false);
		}
	}, [visible]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			width='35%'
			closable
			header={(
				<div className={styles.title}>
					<h4>Введите номер телефона</h4>
					<span>Введите номер телефона, который будет привязан к аккаунту</span>
				</div>
			)}
			footerItems={[
				<ButtonCancel
					key={'ButtonCancel'}
					onclickHandler={() => {
						setVisible(false);
					}}
					text='Отмена'
				/>,
				<ButtonConfirm
					key={'ButtonConfirm'}
					onclickHandler={handleSendingNumber}
					text={'Получить код'}
				/>,
			]}
		>
			<div className={styles.content}>
				<InputPhone
					value={phoneNumber}
					setValue={setPhoneNumber}
					isRequired
					isValid={isValid}
					setIsValid={setIsValid}
					showValidation={showValidation}
					inputStyles={{
						placeholder: 'Номер',
					}}
					onKeyDown={handleKeyDown}
					validationMessagePosition='bottom'
				/>
				<ErrorMessage isError={isError} message={errorMessage} />
				<ModalSixDigitalCode
					visibleModalCode={visibleModalCode}
					setVisibleModalCode={setVisibleModalCode}
					phone={phoneNumber}
					closingModals={closingModals}
				/>
			</div>
		</ModalWindow>
	);
};
