import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

type settingsState = {
	isFixedSidebar: boolean;
	isSoundNotification: boolean;
};
const initialState: settingsState = {
	isFixedSidebar: Boolean(localStorage.getItem('fixSidebar')),
	isSoundNotification: defaultNotificationSound(),
};

function defaultNotificationSound() {
	const sound = localStorage.getItem('soundNotification');
	if (sound) {
		if (sound === 'true') {
			return true;
		}

		return false;
	}

	return true;
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setIsFixedSidebar(state, action: PayloadAction<boolean>) {
			if (action.payload) {
				localStorage.setItem('fixSidebar', 'true');
			} else {
				localStorage.removeItem('fixSidebar');
			}

			state.isFixedSidebar = action.payload;
		},
		setIsSoundNotification(state, action: PayloadAction<boolean>) {
			if (action.payload) {
				localStorage.setItem('soundNotification', 'true');
			} else {
				localStorage.setItem('soundNotification', 'false');
			}

			state.isSoundNotification = action.payload;
		},
	},
});

export const {setIsFixedSidebar, setIsSoundNotification} = settingsSlice.actions;
export default settingsSlice.reducer;
