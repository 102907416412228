import React, {useEffect, useRef, useState} from 'react';
import styles from './ReportNavigation.module.css';
import {useAppSelector} from '../../../redux/redux-hooks';
import AlertMessage from '../../UiKit/Message/AlertMessage/AlertMessage';

type ReportNavigationProps = {
	scrollContainer: React.RefObject<HTMLDivElement>;
	selectedItem: number;
};

export const ReportNavigation: React.FC<ReportNavigationProps> = ({scrollContainer, selectedItem}) => {
	const [currentVisibleItems, setCurrentVisibleItems] = useState<string>();
	const lastScrollTop = useRef(-1);
	const getCheckReport = useAppSelector(state => state.getCheckReportById);

	const navRef = useRef<HTMLUListElement>(null);

	const navigation = useAppSelector(state => state.getCheckReportById);

	const itemsToRender = selectedItem === 0 ? navigation.navigationItems : navigation.navSourceItems;

	const handleItemClick = (href: string) => {
		window.location.href = href;
	};

	// Отслеживание отображаемых разделов
	useEffect(() => {
		const scrollItemsId: string[] = [];
		lastScrollTop.current = -1;

		const observer = new IntersectionObserver(entries => {
			const scrollTop = scrollContainer.current?.scrollTop;
			if (scrollTop !== undefined) {
				if (lastScrollTop.current === -1) {
					// Первый запуск
					lastScrollTop.current = scrollTop;
					setCurrentVisibleItems(entries[0]?.target.id);
				} else {
					// Обработка изменения положения элементов в контейнере
					const scrollDirection = scrollTop < lastScrollTop.current ? 'up' : 'down';
					lastScrollTop.current = scrollTop;

					if (scrollDirection === 'down') {
						// Скролл вниз
						const entry = entries.reverse().find(item => !item.isIntersecting && item.boundingClientRect.bottom < item.rootBounds!.bottom);
						if (entry) {
							const currentIndex = scrollItemsId.findIndex(item => item === entry.target.id);
							setCurrentVisibleItems(scrollItemsId[Math.min(currentIndex + 1, scrollItemsId.length - 1)]);
						}
					} else if (scrollDirection === 'up') {
						// Скролл вверх
						const entry = entries.find(item => item.isIntersecting && item.boundingClientRect.bottom < item.rootBounds!.bottom);
						if (entry) {
							setCurrentVisibleItems(entry.target.id);
						}
					}
				}
			}
		}, {
			root: scrollContainer.current,
			threshold: 0,
		});

		// Получение контейнеров с разделами
		const elements = scrollContainer.current?.querySelectorAll('[data-group="report-block"]');
		if (elements) {
			elements.forEach(element => {
				scrollItemsId.push(element.id);
				observer.observe(element);
			});
		}

		return () => {
			observer.disconnect();
		};
	}, [scrollContainer.current, selectedItem, getCheckReport.processedResponse]);

	useEffect(() => {
		if (navRef.current) {
			const item = navRef.current.querySelector(`[id=nav-${currentVisibleItems}]`);
			if (item) {
				const timeout = setTimeout(() => {
					item.scrollIntoView({block: 'center'});
				}, 600);
				return () => {
					clearTimeout(timeout);
				};
			}
		}
	}, [currentVisibleItems]);

	return (
		<div className={styles.content}>
			<span>Структура отчета</span>

			<ul className={styles.nav} ref={navRef}>
				{itemsToRender.map((item, index) => (
					<li
						key={index}
						className={styles['nav-item']}
						onClick={() => {
							handleItemClick(`#${item.id}`);
						}}
						id={`nav-${item.id}`}
					>
						<span className={`${styles.circle} ${currentVisibleItems === item.id ? styles['active-circle'] : ''}`} />

						<a href={`#${item.id}`} className={currentVisibleItems === item.id ? styles.active : ''}>
							{item.name}
						</a>
						<AlertMessage alerts={item.alerts} showTooltip={false} />
					</li>
				))}
			</ul>

		</div>
	);
};
