import React, {useEffect, useRef, useState} from 'react';
import styles from './ContextMenu.module.css';
import {ContextMenu} from 'devextreme-react/context-menu';
import {Item} from 'devextreme-react/context-menu';
import ContextMenuItem, {type ContextMenuItemProps} from './ContextMenuItem/ContextMenuItem';

type ContextMenuProps = {
	targetId: string;
	targetRef?: React.RefObject<Element>;
	items: ItemProps[];
};

type ItemProps = ContextMenuItemProps & {
	closeMenuOnClick?: boolean;
};

const ContextMenuTemplate: React.FC<ContextMenuProps> = ({
	targetRef,
	targetId,
	items,
}) => {
	const contextMenuRef = useRef<ContextMenu>(null);
	const [target, setTarget] = useState<Element | undefined>(undefined);

	useEffect(() => {
		setTarget(targetRef?.current ?? undefined);
	}, [targetRef?.current]);

	return (
		<ContextMenu
			ref={contextMenuRef}
			target={`#${targetId}`}
			elementAttr={{
				class: styles['dx-context-menu'],
			}}
		>
			{items.map((item, index) => (
				<Item
					key={index}
					closeMenuOnClick={item.closeMenuOnClick}
					render={() => <ContextMenuItem {...item}/>}
				/>
			))}
		</ContextMenu>
	);
};

export default ContextMenuTemplate;
