import React, {useEffect, useMemo, useState, type ReactNode} from 'react';
import styles from './ModalSelectDepartment.module.css';
import ModalWindow from '../ModalWindow';
import {type TreeListColumnsProps, TreeTable} from '../../UiKit/Tables';
import {type EventOnClickRow} from '../../UiKit/Tables/TableType';
import {Item, SearchPanel, Toolbar} from 'devextreme-react/cjs/tree-list';
import {ButtonTemplate, GreenBorderButton} from '../../UiKit/Button';
import {MdClear} from 'react-icons/md';
import {type Department} from '../../../models/Schemas';
import {FaCheck} from 'react-icons/fa';

type ModalSelectDepartmentProps = {
	nodes: any[] | undefined;
	parentIdExpr: string;
	keyExpr: string;
	selectedRowKeys: any[];
	defaultExpandedRowKeys?: any[];
	onRowDblClick: (rowData: EventOnClickRow) => void;
	visible: boolean;
	setVisible: (value: boolean) => void;
	isLoading?: boolean;
	showClear?: boolean;
	onClear: () => void;
	footerItems?: ReactNode[];
	openAddDepartmentModal?: (department?: Department) => void;
};

const ModalSelectDepartment: React.FC<ModalSelectDepartmentProps> = ({
	visible,
	setVisible,
	nodes,
	parentIdExpr,
	keyExpr,
	selectedRowKeys,
	defaultExpandedRowKeys,
	onRowDblClick,
	isLoading,
	showClear,
	onClear,
	openAddDepartmentModal,
}) => {
	const [selectedDepartmentForAdd, setSelectedDepartmentForAdd] = useState<Department>();
	const [expandedRowsKeys, setExpandedRowsKeys] = useState<Department[]>([]);

	const columns: TreeListColumnsProps[] = useMemo(() => [
		{
			dataField: 'name',
			cellRender(rowData) {
				const department = rowData.row.data as Department;
				return (
					<div
						className={styles.row}
						data-row-primary={openAddDepartmentModal && selectedDepartmentForAdd?.id === department.id}
					>
						<p>{department.name}</p>
						{selectedRowKeys[0] === department.id && (
							<div className={styles['selected-row']}>
								<FaCheck className={styles['selected-row-icon']}/>
								<p>Выбранный отдел</p>
							</div>
						)}
					</div>
				);
			},
		},
	], [selectedDepartmentForAdd, selectedRowKeys]);

	useEffect(() => {
		setSelectedDepartmentForAdd(undefined);
	}, [visible]);

	const onRowClick = (e: EventOnClickRow) => {
		const department = e.data as Department;
		setSelectedDepartmentForAdd(item => {
			if (department.id === item?.id) {
				return undefined;
			}

			return department;
		});
	};

	useEffect(() => {
		setExpandedRowsKeys(defaultExpandedRowKeys ?? []);
	}, [defaultExpandedRowKeys]);

	return (
		<ModalWindow
			header={<span className={styles.title}>Список отделов</span>}
			visible={visible}
			setVisible={setVisible}
			width='50%'
			height='70%'
			closable
			isLoading={isLoading}
		>
			<div className={styles.container}>
				{visible && <TreeTable
					columns={columns}
					treeListProps={{
						dataSource: nodes,
						parentIdExpr,
						keyExpr,
						hoverStateEnabled: true,
						activeStateEnabled: false,
						showColumnHeaders: false,
						onRowClick,
						onRowDblClick,
						selectedRowKeys,
						autoExpandAll: true,
						expandedRowKeys: expandedRowsKeys,
					}}
					visible={visible}
					type='list'
					onExpandedRowKeysChange={setExpandedRowsKeys}
				>
					<SearchPanel
						visible={true}
						width='100%' />
					<Toolbar
						visible={true}
					>
						<Item name='searchPanel' location='center' cssClass={styles['search-panel']} />
						{showClear && (
							<Item location='center' cssClass={styles.clear}>
								<ButtonTemplate
									onClick={onClear}
									color='primary'
									styleType='small-table'
								>
									<MdClear />
								</ButtonTemplate>
							</Item>
						)}
						{openAddDepartmentModal && (
							<Item location='center' cssClass={styles['add-department-button']}>
								<GreenBorderButton
									onClick={() => {
										openAddDepartmentModal(selectedDepartmentForAdd);
									}}
									text='Добавить отдел' />
							</Item>
						)}
					</Toolbar>
				</TreeTable>}
			</div>
		</ModalWindow>
	);
};

export default ModalSelectDepartment;
