import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {
	type PutNotificationsByIdRequest,
	type PutNotificationsByIdResponse,
} from '../../../models/INotifications';
import {putNotificationsById} from '../../../api/notificationsApi';

const putNotificationsByIdAction = createActionTemplateAxios<
PutNotificationsByIdResponse,
PutNotificationsByIdRequest
>(
	'notifications/putNotificationsById',
	putNotificationsById,
	'userToken',
);

export default putNotificationsByIdAction;
