import React, {useState, useEffect} from 'react';
import styles from './Notifications.module.css';
import {ButtonTemplate} from '../../components/UiKit/Button';
import {LuFilter} from 'react-icons/lu';
import {IoMdCheckmark} from 'react-icons/io';
import {CustomNotificationItem} from '../../components/Notification/CustomNotificationItem';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {
	getNotificationsAllRead,
	getNotificationsFilters,
	type SenderType,
} from '../../redux/slice/notifications/getNotificationsSlice';
import {ModalCreateNotification, ModalNotification} from '../../components/Modal/Notifications';
import {putNotificationsByIdAction} from '../../redux/action/notifications';
import {type Notification} from '../../models/INotifications';

export const Notifications = () => {
	const [selectedItem, setSelectedItem] = useState(0);
	const [visibleOpenModal, setVisibleOpenModal] = useState(false);

	const [senderType, setSenderType] = useState<SenderType>('Все');

	const [selectedNotifications, setSelectedNotifications] = useState<Notification | undefined>(undefined);
	const [visibleModalNotification, setVisibleModalNotification] = useState(false);

	const getNotifications = useAppSelector(state => state.getNotifications);
	const dispatch = useAppDispatch();

	const handleItemClick = (index: number, senderType: SenderType) => {
		setSenderType(senderType);
		setSelectedItem(index);
	};

	const openModalCreateNotification = () => {
		setVisibleOpenModal(true);
	};

	const readAllNotifications = () => {
		if (getNotifications.filteredResponse) {
			getNotifications.filteredResponse.forEach(notification => {
				if (!notification.viewed) {
					void dispatch(putNotificationsByIdAction({id: notification.id}));
				}
			});
			void dispatch(getNotificationsAllRead());
		}
	};

	const onNotificationClick = (itemData: Notification) => {
		setVisibleModalNotification(true);
		setSelectedNotifications(itemData);
	};

	useEffect(() => {
		if (getNotifications.responseStatus.isCompleted) {
			dispatch(getNotificationsFilters(senderType));
		}
	}, [senderType, getNotifications.responseStatus.isLoading]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<span className='h1-text'>Уведомления</span>
				<ButtonTemplate
					onClick={openModalCreateNotification}
					color='primary'
					text='Создать уведомление'/>
			</div>

			<ul className={styles.nav}>
				{getNotifications.filters.senderType.map((item, index) => (
					<li key={index}
						className={`${styles['nav-item']} ${selectedItem === index ? styles.active : ''}`}
						onClick={() => {
							handleItemClick(index, item);
						}}
					>
						{item}
					</li>
				))}
			</ul>

			{getNotifications.filteredResponse.length > 0 ? (
				<div className={styles.content}>
					<div className={styles.filters}>

						<div className={styles.filter}>
							<LuFilter/>
							<span>Фильтр</span>
						</div>

						<ButtonTemplate
							onClick={readAllNotifications}
							color='primary-outline'
							text='Отметить все прочитанными'
							Icon={IoMdCheckmark}
							className={styles['read-all']}
						/>
					</div>

					<div className={styles.notifications}>
						{getNotifications.filteredResponse.map((item, index) => (
							<div className={styles.notification} key={index} onClick={() => {
								onNotificationClick(item);
							}}>
								{!item.viewed && <span></span>}
								<CustomNotificationItem item={item} isDropdown={false}/>
							</div>
						))}
					</div>
				</div>
			) : (
				<h2>Уведомления не найдены</h2>
			)}
			<ModalNotification
				visible={visibleModalNotification}
				setVisible={setVisibleModalNotification}
				notification={selectedNotifications}
			/>
			<ModalCreateNotification visible={visibleOpenModal} setVisible={setVisibleOpenModal} />
		</div>
	);
};
