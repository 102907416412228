import React, {type ReactNode} from 'react';
import styles from './CreateIndividualDataForm.module.css';
import Loader from '../../../Loader/Loader';

type CreateIndividualDataFormProps = {
	children: ReactNode;
	isLoading?: boolean;
	width?: number | string;
	footerItems?: ReactNode[];
};

export const CreateIndividualDataForm: React.FC<CreateIndividualDataFormProps> = ({children, isLoading, width, footerItems}) => (
	<div className={styles.form} style={{width}}>

		<div className={styles.header}>
			<span>Шаг 1</span>
			<span>Основная информация</span>
		</div>

		<div className={styles.content}>
			{children}
		</div>
		<div className={styles['btn-group']}>
			{footerItems}
		</div>
		<Loader
			isLoading={isLoading}
			isFloating
			text='Загрузка данных...'
		/>
	</div>
);
