import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './CheckReport.module.css';
import {
	CheckContactDetails,
	ExecutiveOfficeWork,
	ReportNavigation,
	SearchReport,
	Documents,
	Advertisements,
	Transports,
	Workplaces,
	Nicknames,
	PossibleNames,
	BasicInfo,
	SummaryData,
	Accounts,
	PublicGroups,
	CheckAddress,
	PersonsReportBlock,
	Microlans,
	Sources,
	ReportHeader,
} from '../../components/CheckReport';
import {useNavigate, useParams} from 'react-router-dom';
import {paths} from '../../paths';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {getCheckReportByIdAction} from '../../redux/action/checks';
import Loader from '../../components/Loader/Loader';
import {CardComments} from '../../components/Comments/CardComments/CardComments';
import {getCommentByIdAction} from '../../redux/action/comments';
import {ErrorMessage} from '../../components/UiKit/Message';
import {type ResponseStatus} from '../../models/IAuth';
import {
	getCheckReportByIdResetState,
	postCheckCreateAddressSliceResetState,
	postCheckCreateEmailSliceResetState,
	postCheckCreatePassportSliceResetState,
	postCheckCreatePhoneSliceResetState,
} from '../../redux/slice/checks';
import SocialAccounts from '../../components/CheckReport/Blocks/SocialAccounts/SocialAccounts';
import ResolutionStatus from '../../components/CheckReport/ResolutionStatus/ResolutionStatus';

const basicReport = (
	<>
		<PersonsReportBlock />
		<PossibleNames />
		<CheckAddress />
		<CheckContactDetails />
		<Accounts />
		<SocialAccounts />
		<Advertisements />
		<SearchReport />
		<Documents />
		<ExecutiveOfficeWork />
		<Workplaces />
		<Transports />
		<Nicknames />
		<PublicGroups />
		<Microlans />
	</>
);

const CheckReport = () => {
	const [selectedItem, setSelectedItem] = useState(0);
	const scrollContainer = useRef<HTMLDivElement>(null);

	const getCheckReport = useAppSelector(state => state.getCheckReportById);

	const postCheckCreatePassport = useAppSelector(state => state.postCheckCreatePassport);
	const postCheckCreateAddress = useAppSelector(state => state.postCheckCreateAddress);
	const postCheckCreatePhone = useAppSelector(state => state.postCheckCreatePhone);
	const postCheckCreateEmail = useAppSelector(state => state.postCheckCreateEmail);

	const checkCreatedIds = useRef<string[]>([]);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {id} = useParams();

	const back = () => {
		const parentCheckId = getCheckReport.processedResponse?.parent_check_id;
		if (typeof parentCheckId === 'number') {
			navigate(paths.report.replace(':id', parentCheckId.toString()));
		} else {
			navigate(paths.checks);
		}
	};

	// Отчистка отчета
	const cleanReportVariables = () => {
		dispatch(getCheckReportByIdResetState());
		dispatch(postCheckCreatePassportSliceResetState());
		dispatch(postCheckCreateAddressSliceResetState());
		dispatch(postCheckCreatePhoneSliceResetState());
		dispatch(postCheckCreateEmailSliceResetState());
		checkCreatedIds.current = [];
		setSelectedItem(0);
	};

	// Переключение по вкладкам
	const handleTabsClick = (index: number) => {
		setSelectedItem(index);
	};

	const sourcesReport = useMemo(() => (
		<>
			{getCheckReport.processedResponse?.report_data.raw_data?.map(data => (
				<Sources source={data} key={data.key} />
			))}
		</>
	), [getCheckReport.processedResponse]);

	// Запрос данных о проверке
	useEffect(() => {
		cleanReportVariables();
		if (id) {
			const check_id = parseInt(id, 10);
			void dispatch(getCheckReportByIdAction({check_id}));
			void getCommentByIdAction({check_id});
		}
	}, [id]);

	// Проверка готовности отчёта
	useEffect(() => {
		if (getCheckReport.responseStatus.isCompleted && id) {
			if (getCheckReport.response?.status !== 'verified') {
				back();
			}

			const timeOut = setTimeout(() => {
				const subChecks = getCheckReport.response?.subchecks;
				if (subChecks) {
					let isLoading = false;
					for (const subCheck in subChecks) {
						if (Object.hasOwn(subChecks, subCheck)) {
							if (subChecks[subCheck]?.check_status !== 'verified') {
								isLoading = true;
								break;
							}
						}
					}

					if (isLoading) {
						void dispatch(getCheckReportByIdAction({check_id: parseInt(id, 10)}));
					} else {
						clearInterval(timeOut);
					}
				}
			}, 60000);

			return () => {
				clearInterval(timeOut);
			};
		}
	}, [getCheckReport.responseStatus.isLoading]);

	const checkCreatedRequestId = (responseStatus: ResponseStatus) => {
		const {requestId, isLoading} = responseStatus;

		if (requestId) {
			if (isLoading) {
				if (checkCreatedIds.current.find(item => item === requestId) === undefined) {
					checkCreatedIds.current.push(requestId);
				}
			} else {
				const idFindIndex = checkCreatedIds.current.findIndex(item => item === requestId);
				if (idFindIndex !== -1) {
					checkCreatedIds.current.splice(idFindIndex, 1);
				}
			}
		}
	};

	useEffect(() => {
		checkCreatedRequestId(postCheckCreatePhone.responseStatus);
		checkCreatedRequestId(postCheckCreateEmail.responseStatus);
		checkCreatedRequestId(postCheckCreateAddress.responseStatus);
		checkCreatedRequestId(postCheckCreatePassport.responseStatus);

		if (checkCreatedIds.current.length === 0 && id) {
			void dispatch(getCheckReportByIdAction({check_id: parseInt(id, 10)}));
		}
	}, [
		postCheckCreatePhone.responseStatus.isLoading,
		postCheckCreateEmail.responseStatus.isLoading,
		postCheckCreateAddress.responseStatus.isLoading,
		postCheckCreatePassport.responseStatus.isLoading,
	]);

	if (getCheckReport.responseStatus.isError) {
		return (
			<div className={styles.error}>
				<ErrorMessage isError={true} message={getCheckReport.responseStatus.errorMessage} fontSize='font-inherit' />
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<ReportHeader back={back} />
			</div>
			<div className={styles.content} ref={scrollContainer}>
				<ResolutionStatus />
				<BasicInfo />
				<SummaryData />
				<ul className={styles.nav}>
					{getCheckReport.filters.senderType.map((item, index) => (
						<li
							key={index}
							onClick={() => {
								handleTabsClick(index);
							}}
							className={`${styles['nav-item']} ${selectedItem === index ? styles.active : ''}`}
						>{item}</li>
					))}
				</ul>
				{selectedItem === 0 && basicReport}
				{selectedItem === 1 && sourcesReport}
			</div>
			<div className={styles.menu}>
				<ReportNavigation
					scrollContainer={scrollContainer}
					selectedItem={selectedItem}
				/>
			</div>
			<div className={styles.comments}>
				<CardComments />
			</div>
			<Loader
				isLoading={getCheckReport.responseStatus.isLoading && !getCheckReport.response}
				shading='full'
				isFloating
				text='Загрузка отчета...'
			/>
		</div>
	);
};

export default CheckReport;

