import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {
	type GetOfficialPermissionsRequest,
	type GetOfficialPermissionsResponse,
	type GetPermissionsRolesRequest,
	type GetPermissionsRolesResponse,
	type GetUserPermissionsRequest,
	type GetUserPermissionsResponse,
	type PostOfficialPermissionsRequest,
	type PostOfficialPermissionsResponse,
	type PostUserPermissionsRequest,
	type PostUserPermissionsResponse,
	type PutOfficialPermissionsRequest,
	type PutOfficialPermissionsResponse,
	type PutPermissionsRolesRequest,
	type PutPermissionsRolesResponse,
	type PutUserPermissionsRequest,
	type PutUserPermissionsResponse,
} from '../models/IPermissions';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/permissions';

export async function postUserPermissions(
	data: PostUserPermissionsRequest,
): Promise<AxiosResponse<PostUserPermissionsResponse>> {
	return axios.post(`${domain}${urlPrefix}/user_permissions`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function getUserPermissions(
	data: GetUserPermissionsRequest,
): Promise<AxiosResponse<GetUserPermissionsResponse>> {
	return axios.get(
		`${domain}${urlPrefix}/user_permissions/${data.permissions_id}`,
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

export async function putUserPermissions(
	data: PutUserPermissionsRequest,
): Promise<AxiosResponse<PutUserPermissionsResponse>> {
	return axios.put(
		`${domain}${urlPrefix}/user_permissions/${data.permissions_id}`,
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

export async function postOfficialPermissions(
	data: PostOfficialPermissionsRequest,
): Promise<AxiosResponse<PostOfficialPermissionsResponse>> {
	return axios.post(`${domain}${urlPrefix}/official_permissions`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function putOfficialPermissions(
	data: PutOfficialPermissionsRequest,
): Promise<AxiosResponse<PutOfficialPermissionsResponse>> {
	return axios.put(
		`${domain}${urlPrefix}/official_permissions/${data.official_permissions_id}`,
		data.body,
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

export async function getOfficialPermissions(
	data: GetOfficialPermissionsRequest,
): Promise<AxiosResponse<GetOfficialPermissionsResponse>> {
	return axios.get(
		`${domain}${urlPrefix}/official_permissions/${data.official_permissions_id}`,
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

// Получение ролей в организации
export async function getPermissionsRoles(
	data: GetPermissionsRolesRequest,
): Promise<AxiosResponse<GetPermissionsRolesResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.organization_id}/roles`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Обновление роли пользователя
export async function putPermissions(
	data: PutPermissionsRolesRequest,
): Promise<AxiosResponse<PutPermissionsRolesResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.user_id}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
