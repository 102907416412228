import {useEffect} from 'react';
import {type ResponseStatus} from '../../../../models/IAuth';

type useEffectHandlerLoadingAllRequestType<ResponseType> = {
	state: {
		responseStatus: ResponseStatus;
		response: ResponseType;
	};
	onLoading?: () => void;
	onStopLoading?: () => void;
	onComplete?: (response: ResponseType) => void;
	onError?: (errorMessage: string) => void;
};

const useEffectHandlerLoadingAllRequest = <T>({
	state,
	onLoading,
	onStopLoading,
	onComplete,
	onError,
}: useEffectHandlerLoadingAllRequestType<T>) => {
	useEffect(() => {
		const {isCompleted, isError, isLoading, errorMessage} = state.responseStatus;

		if (isLoading) {
			if (onLoading) {
				onLoading();
			}
		} else {
			if (onStopLoading) {
				onStopLoading();
			}

			if (isCompleted) {
				if (onComplete) {
					onComplete(state.response);
				}
			} else if (isError) {
				if (onError) {
					onError(errorMessage);
				}
			}
		}
	}, [
		state.responseStatus.isLoading,
		state.responseStatus.requestId,
	],
	);
};

export default useEffectHandlerLoadingAllRequest;
