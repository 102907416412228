import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {type GetStatisticsResponse} from '../models/IStatistics';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/statistics';

/** Получение статистики для главной */
export async function getStatisticsHome(): Promise<AxiosResponse<GetStatisticsResponse>> {
	return axios.get(`${domain}${urlPrefix}/home`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
