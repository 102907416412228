import React from 'react';
import styles from '../../ReportCard.module.css';
import {Phone} from '../../../../../Message';
import {CopyToClipboardButton} from '../../../../../Button';
import {type CheckNicknamesFromContacts} from '../../../../../../../report/ReportModel';
import {SelectCheckButton} from '../../../../../Button/ReportButtons';

type PossibleNameCardProps = {
	contact: CheckNicknamesFromContacts;
	onCLick: () => void;
	onClickSub: () => void;
	containerId: string | undefined;
};

export const PossibleNameCard: React.FC<PossibleNameCardProps> = ({
	contact,
	onCLick,
	onClickSub,
	containerId,
}) => (
	<div className={`${styles.card} ${styles.click}`} onClick={onCLick}>
		<div className={styles.header}>
			<span>Из контактной книжки</span>
		</div>
		<div className={styles.content}>
			<div className={styles.info}>
				<span className={`${styles.contact} ${styles['text-overflow']}`}>
					<Phone phone={contact.phone} formatPhone/>
				</span>
				<CopyToClipboardButton text={contact.phone} format='phone'/>
				<SelectCheckButton containerId={containerId} openSubCheck={onClickSub}/>
			</div>
			<div className={styles['tag-group']}>
				<div className={styles.tags}>
					<span>#</span>
					<span>{contact.name}</span>
				</div>
			</div>
		</div>
	</div>
);
