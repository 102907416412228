import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import {type TimerPayload} from '../../models/Timer';

type Timer = {
	value: number;
	isStop: boolean;
	isContinuation: boolean;
};

const saveTimer = (payload: TimerPayload) => {
	localStorage.setItem('startDate', payload.startDate.toString());
	localStorage.setItem('value', payload.value.toString());
};

const clearTimer = () => {
	localStorage.removeItem('startDate');
	localStorage.removeItem('value');
};

const getState = () => {
	const startDate = localStorage.getItem('startDate');
	const value = localStorage.getItem('value');
	if (startDate && value) {
		const creationDate = new Date(parseInt(startDate, 10)).getTime();
		const difference = Math.floor((new Date().getTime() - creationDate) / 1000);
		const time = parseInt(value, 10) - difference;
		if (time > 0) {
			return {
				value: time,
				isStop: true,
				isContinuation: true,
			};
		}
	}

	return {
		value: 0,
		isStop: true,
		isContinuation: false,
	};
};

const initialState: Timer = getState();

const timerSlice = createSlice({
	name: 'timer',
	initialState,
	reducers: {
		setTimer(state, action: PayloadAction<TimerPayload>) {
			state.value = action.payload.value;
			saveTimer(action.payload);
			state.isStop = false;
			state.isContinuation = false;
		},
		continueTimer(state) {
			state.isStop = false;
			state.isContinuation = false;
		},
		updateTimer(state, action: PayloadAction<number>) {
			state.value = action.payload;
		},
		stopTimer(state) {
			state.value = 0;
			clearTimer();
			state.isStop = true;
			state.isContinuation = false;
		},
	},
});

export const {stopTimer, updateTimer, setTimer, continueTimer}
  = timerSlice.actions;
export default timerSlice.reducer;
