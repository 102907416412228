import React, {useState, useEffect} from 'react';
import styles from './ModalForPositions.module.css';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {
	postDepartmentPositionsAction,
	putDepartmentPositionsAction,
} from '../../../redux/action/department';
import {type DepartmentPositionWithEmployee} from '../../../models/Schemas';
import ModalWindow from '../ModalWindow';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {ErrorMessage} from '../../UiKit/Message';
import {Dropdown, type DropdownDefaultValue, Input, TextArea} from '../../UiKit/Inputs';
import {getFullName} from '../../../utils';
import {
	postDepartmentPositionsCloseAction,
	postDepartmentPositionsOpenAction,
} from '../../../redux/action/department_positions';
import ButtonTemplate from '../../UiKit/Button/ButtonTemplate/ButtonTemplate';
import {SplitLine} from '../../UiKit/Div';
import {DateTime} from 'luxon';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';

type ModalForPositionsProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	position?: DepartmentPositionWithEmployee;
	departmentId: number | undefined;
};

export const ModalForPositions: React.FC<ModalForPositionsProps> = ({
	visible,
	setVisible,
	position,
	departmentId,
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValidName, setIsValidName] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState<string>('');
	const [openedAt, setOpenedAt] = useState<string | undefined>(undefined);
	const [urgency, setUrgency] = useState<string | undefined>(undefined);
	const [selectedEmployee, setSelectedEmployee] = useState<number | undefined>(undefined);
	const [optionsEmployee, setOptionsEmployee] = useState<DropdownDefaultValue[] | undefined>(undefined);
	const [loadingMessage, setLoadingMessage] = useState('');

	const {responseStatus} = useAppSelector(
		state => state.postDepartmentPositions,
	);

	const responseStatusUpdated = useAppSelector(
		state => state.putDepartmentPositions.responseStatus,
	);
	const getDepartmentEmployees = useAppSelector(
		state => state.getDepartmentEmployees,
	);

	const postDepartmentPositionsOpen = useAppSelector(
		state => state.postDepartmentPositionsOpen,
	);
	const postDepartmentPositionsClose = useAppSelector(
		state => state.postDepartmentPositionsClose,
	);

	const dispatch = useAppDispatch();

	// Принятие изменений
	const handleSave = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isValidName) {
			if (position) {
				void dispatch(
					putDepartmentPositionsAction({
						body: {
							name,
							description: description ?? null,
							opened_at: openedAt ?? null,
							employee_id: selectedEmployee ?? null,
							urgency: urgency ?? null,
						},
						position_id: position.id,
					}),
				);
			} else if (departmentId) {
				void dispatch(
					postDepartmentPositionsAction({
						body: {
							name,
							description: description ?? null,
							employee_id: selectedEmployee ?? null,
						},
						department_id: departmentId,
					}),
				);
			}
		}
	};

	// Отмена изменений
	const handleCancel = () => {
		setVisible(false);
	};

	// Открытие позиции
	const openPosition = () => {
		if (position) {
			void dispatch(
				postDepartmentPositionsOpenAction({positions_id: position.id}),
			);
		}
	};

	// Закрытие позиции
	const closePosition = () => {
		if (position) {
			setOpenedAt(undefined);
			void dispatch(
				postDepartmentPositionsCloseAction({positions_id: position.id}),
			);
		}
	};

	// Блок открытия вакансии
	const openAtDiv = () => {
		if (openedAt) {
			const openedDate = DateTime.fromISO(openedAt);
			const date = openedDate.toLocaleString(DateTime.DATE_SHORT);
			const time = openedDate.toLocaleString(DateTime.TIME_SIMPLE);
			return (
				<>
					<span>{`Вакансия открыта ${date} в ${time}`}</span>
					<ButtonTemplate
						onClick={closePosition}
						text='Закрыть вакансию'
						color='green-outline'
						styleType='long'
					/>
				</>
			);
		}

		return (
			<ButtonTemplate
				onClick={openPosition}
				text='Открыть вакансию'
				color='green-outline'
				styleType='long'
			/>
		);
	};

	// Открытие позиции
	useEffect(() => {
		if (visible) {
			if (
				postDepartmentPositionsOpen.responseStatus.isCompleted
				&& postDepartmentPositionsOpen.response?.opened_at
			) {
				const formatDate = new Date(
					postDepartmentPositionsOpen.response.opened_at,
				).toISOString();
				setOpenedAt(formatDate);
				notifyMessage('success', 'Вакансия открыта');
			} else if (postDepartmentPositionsOpen.responseStatus.isError) {
				notifyMessage('error', 'Ошибка. Что-то пошло не так.');
			}

			if (postDepartmentPositionsOpen.responseStatus.isLoading) {
				setLoadingMessage('Открытие вакансии');
			}
		}
	}, [postDepartmentPositionsOpen.responseStatus.isLoading]);

	// Закрытие позиции
	useEffect(() => {
		if (visible) {
			if (postDepartmentPositionsClose.responseStatus.isCompleted) {
				setOpenedAt(undefined);
				notifyMessage('success', 'Вакансия закрыта');
			} else if (postDepartmentPositionsClose.responseStatus.isError) {
				notifyMessage('error', 'Ошибка. Что-то пошло не так.');
			}

			if (postDepartmentPositionsClose.responseStatus.isLoading) {
				setLoadingMessage('Закрытие вакансии');
			}
		}
	}, [postDepartmentPositionsClose.responseStatus.isLoading]);

	// Получение списка сотрудников отдела
	useEffect(() => {
		if (getDepartmentEmployees.response) {
			setOptionsEmployee(
				getDepartmentEmployees.response.map(item => ({
					label: getFullName(item.first_name, item.middle_name, item.last_name),
					data: item.id,
				})),
			);
		} else {
			setOptionsEmployee(undefined);
		}
	}, [getDepartmentEmployees.response]);

	// Обработка выполнения запросов
	useEffect(() => {
		if (visible) {
			if (responseStatus.isCompleted || responseStatusUpdated.isCompleted) {
				setVisible(false);
				notifyMessage('success', position ? 'Информация о должности обновлена'
					: 'Должность создана успешно');
			} else if (responseStatus.isLoading) {
				setLoadingMessage('Добавление должности');
			} else if (responseStatusUpdated.isLoading) {
				setLoadingMessage('Обновление должности');
			} else if (responseStatus.isError || responseStatusUpdated.isError) {
				notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
			}
		}
	}, [responseStatus.isLoading, responseStatusUpdated.isLoading]);

	// Изменение состояний при открытии и закрытии окна
	useEffect(() => {
		if (visible && position) {
			setName(position.name);
			setDescription(position.description ?? '');
			setOpenedAt(position.opened_at ?? undefined);
			if (position.employee) {
				const {employee} = position;
				setSelectedEmployee(employee.id);
			} else {
				setSelectedEmployee(undefined);
			}

			setUrgency(position.urgency ?? undefined);
		} else {
			setName('');
			setDescription('');
			setOpenedAt(undefined);
			setSelectedEmployee(undefined);
			setUrgency(undefined);
			setShowValidation(false);
		}
	}, [visible]);

	return (
		<ModalWindow
			width='58%'
			height='auto'
			header={
				<div className={styles.title}>
					<h1>{position ? 'Обновить' : 'Создать'} должность</h1>
					<span>
					Для {position ? 'обновления' : 'создания новой'} должности введите
					необходимые данные
					</span>
				</div>
			}
			closable
			closeOnOutsideClick
			isLoading={responseStatus.isLoading || responseStatusUpdated.isLoading}
			visible={visible}
			setVisible={setVisible}
			loadingMessage={loadingMessage}
			footerItems={[
				<ButtonCancel key='ButtonCancel' text='Отменить' onclickHandler={handleCancel} />,
				<ButtonConfirm key='ButtonConfirm' text='Сохранить' onclickHandler={handleSave} />,
			]}
		>
			<div className={styles.content}>
				<div className={styles['input-group']}>
					<Input
						value={name}
						setValue={setName}
						title='Название должности'
						inputStyles={{
							placeholder: 'Должность',
						}}
						inputValidation={{
							isRequired: true,
							minLength: 2,
							isValid: isValidName,
							setIsValid: setIsValidName,
							showValidation,
							visible,
						}}
					/>
					<SplitLine />
					<SplitLine />
					<TextArea
						value={description}
						setValue={setDescription}
						inputStyles={{
							placeholder: 'Введите описание',
						}}
						title='Описание'
					/>
					<Dropdown
						selectedValue={selectedEmployee}
						setSelectedValue={setSelectedEmployee}
						displayValueKey='label'
						setValueKey='data'
						title='Сотрудник'
						options={optionsEmployee}
						placeholder={'Выберите сотрудника'}
						emptyMessage={'Сотрудники не найдены'}
						showClear
					/>
				</div>
				{position ? (
					<div className={styles['position-open-at']}>{openAtDiv()}</div>
				) : null}
				<ErrorMessage
					isError={responseStatus.isError}
					message={responseStatus.errorMessage}
				/>
				<ErrorMessage
					isError={responseStatusUpdated.isError}
					message={responseStatusUpdated.errorMessage}
				/>
			</div>
		</ModalWindow>
	);
};
