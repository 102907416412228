import React from 'react';
import {type Check} from '../../../../models/Schemas';
import {type CheckPermissions} from '../../../Modal/ModalCheckCreated/ModalCheckCreated';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../../../paths';
import {ButtonTemplate} from '../../Button';

type ActionsCheckProps = {
	status: string;
	isCard: boolean;
	check: Check | undefined;
	openModalStartCheck: (
		check: Check,
		checkPermissions: CheckPermissions,
	) => void;
	checkPermissions: CheckPermissions;
};

export const ActionsCheck: React.FC<ActionsCheckProps> = ({
	isCard,
	status,
	check,
	openModalStartCheck,
	checkPermissions,
}) => {
	const navigate = useNavigate();

	const actions = (status: string) => {
		if (status === 'in_progress' || status === 'verified') {
			return (
				<ButtonTemplate
					onClick={() => {
						if (check) {
							navigate(
								paths.report.replace(
									':id',
									check.id.toString(),
								),
							);
						}
					}}
					color='gray'
					text='Отчет'
					styleType={isCard ? 'medium-large' : 'small-table'}
				/>
			);
		}

		if (status === 'collect') {
			return (
				<ButtonTemplate
					onClick={() => null}
					color='gray'
					text='Сбор данных'
					disable
					styleType={isCard ? 'medium-large' : 'small-table'}
				/>
			);
		}

		if (status === 'wait') {
			return (
				<ButtonTemplate
					onClick={() => {
						if (check) {
							openModalStartCheck(check, checkPermissions);
						}
					}}
					color='gray'
					text='Проверить'
					styleType={isCard ? 'medium-large' : 'small-table'}
				/>
			);
		}

		return null;
	};

	return <>{actions(status)}</>;
};
