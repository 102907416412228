import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../InputDevextreme.module.css';
import {DateBox, Validator} from 'devextreme-react';
import {searchButton} from '../InputButtons';
import {type DateType, type KeyDownEvent} from 'devextreme/ui/date_box';
import {type TextBoxType} from 'devextreme/ui/text_box';
import {
	type InputValidation,
	type InputStyles,
	type InputMask,
	type InputAttr,
	type InputButtons,
	type InputButton,
} from '../InputsTypes';
import {CustomRule, PatternRule, RequiredRule} from 'devextreme-react/cjs/validator';
import {Label, type LabelCommonProps} from '../../../Common';

const maxDate = new Date();
const minDate = new Date();
minDate.setFullYear(maxDate.getFullYear() - 120);

export type InputDateProps = {
	value: Date | number | string | undefined;
	setValueDate?: (value: Date | undefined) => void;
	setValueNumber?: (value: number | undefined) => void;
	setValueString?: (value: string | undefined) => void;
	onKeyDown?: (e: KeyDownEvent) => void;
	type?: DateType;
	dateSerializationFormat?: 'yyyy-MM-dd' | 'yyyy-MM-ddTHH:mm:ss' | 'yyyy-MM-ddTHH:mm:ssZ';

	styleType?: 'large' | 'medium' | 'small';
	focusOnInput?: boolean;
	disabled?: boolean;

	inputStyles?: InputStyles & InputStylesAdditional;
	inputValidation?: InputValidation;
	inputMask?: InputMask;
	inputAttr?: InputAttr;
	inputButtons?: InputButtons;
} & LabelCommonProps;

type InputStylesAdditional = {
	mode?: TextBoxType;
};

const InputDate: React.FC<InputDateProps> = ({
	value,
	setValueDate,
	setValueNumber,
	setValueString,
	dateSerializationFormat = 'yyyy-MM-dd',
	styleType = 'small',
	type = 'date',
	title,
	titleColor = 'asphalt',
	focusOnInput,
	disabled,
	inputStyles,
	inputValidation,
	inputMask,
	inputAttr,
	inputButtons,
	onKeyDown,
}) => {
	const [firstInput, setFirstInput] = useState(false);
	const [firstFocusOut, setFirstFocusOut] = useState(false);

	const dateBoxRef = useRef<DateBox>(null);
	const validatorRef = useRef<Validator>(null);

	const wrapperClassName = `${styles.wrapper} ${inputButtons ? ' ' + styles.icons : styles['not-icons']}`;
	const inputClassName = `${styles['input-dx']} ${styles[styleType]}`;

	const validationMessagePosition = inputValidation?.validationMessagePosition ?? 'top';

	const validationMessageMode = inputValidation?.validationMessageMode ?? 'auto';

	const validation = useMemo(() => {
		if (inputValidation) {
			const {
				setIsValid,
				isRequired,
				customPatternRule,
				customRule,
			} = inputValidation;
			return (
				<Validator
					ref={validatorRef}
					onValidated={e => {
						if (setIsValid) {
							setIsValid(e.isValid ?? false);
						}
					}}
				>
					{isRequired
						&& <RequiredRule
							message='Поле должно быть заполнено'
						/>}
					{customPatternRule?.map((item, index) => (
						<PatternRule key={'patternRule' + index} {...item} />
					))}
					{customRule?.map((item, index) => (
						<CustomRule key={`CustomRule ${index}`} {...item} />
					))}
				</Validator>
			);
		}

		return undefined;
	}, [inputValidation?.setIsValid,
		inputValidation?.isRequired,
		inputValidation?.requiredLength,
		inputValidation?.minLength,
		inputValidation?.lettersOnly,
		inputValidation?.customPatternRule,
		inputValidation?.customRule]);

	const buttons = useMemo(() => {
		const buttons: InputButton[] = inputButtons?.buttons ?? [];
		if (inputButtons?.searchButton) {
			buttons.push(searchButton);
		}

		if (buttons.length > 0) {
			return buttons;
		}

		return undefined;
	}, [inputButtons]);

	const onValueChange = useCallback((value: Date | number | string) => {
		if (setValueDate && (typeof (value) === 'object' || value === null)) {
			setValueDate(value ?? undefined);
		} else if (setValueString && (typeof (value) === 'string' || value === null)) {
			setValueString(value ?? undefined);
		} else if (setValueNumber && (typeof (value) === 'number' || value === null)) {
			setValueNumber(value ?? undefined);
		}
	}, [setValueDate, setValueString, setValueNumber]);

	const onInput = useCallback(() => {
		if (!firstInput) {
			setFirstInput(true);
		}
	}, [firstInput, setFirstInput]);

	const validate = useCallback(() => {
		if (validatorRef) {
			validatorRef.current?.instance.validate();
		}
	}, []);

	const onFocusOut = useCallback(() => {
		if (!firstFocusOut) {
			setFirstFocusOut(true);
		}

		validate();
	}, [firstFocusOut, setFirstFocusOut, validate]);

	useEffect(() => {
		if (focusOnInput) {
			dateBoxRef.current?.instance.focus();
		}
	}, [focusOnInput]);

	useEffect(() => {
		validate();
	}, []);

	useEffect(() => {
		if (inputValidation?.showValidation === false || inputValidation?.visible === false) {
			setFirstInput(false);
			setFirstFocusOut(false);
		}
	}, [inputValidation?.showValidation, inputValidation?.visible]);

	return (
		<>
			<Label
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				disabled={disabled}
				isRequired={inputValidation?.isRequired}
			/>
			<div className={wrapperClassName}>
				<DateBox
					ref={dateBoxRef}
					type={type}
					className={inputClassName}
					dropDownOptions={{
						wrapperAttr: {
							class: styles['calendar-dropdown'],
						},
					}}
					value={value}
					onValueChange={onValueChange}
					onKeyDown={onKeyDown}
					applyValueMode='instantly'
					showClearButton
					useMaskBehavior
					showMaskMode='always'
					displayFormat='dd.MM.yyyy'
					dateSerializationFormat={dateSerializationFormat}
					min={minDate}
					max={maxDate}
					defaultValue={value}
					disabled={disabled}
					onClosed={validate}
					onChange={validate}
					onFocusOut={onFocusOut}
					onInput={onInput}
					validationMessageMode={validationMessageMode}
					validationMessagePosition={validationMessagePosition}
					isValid={
						firstInput || inputValidation?.showValidation
							? inputValidation?.isValid ?? true
							: true
					}
					buttons={buttons}
					inputAttr={{...inputAttr, autocomplete: 'new-password'}}
					{...inputMask}
					{...inputStyles}
				>
					{validation}
				</DateBox>
			</div>
		</>
	);
};

export default InputDate;
