import React from 'react';
import styles from './ButtonCancel.module.css';
import {ButtonTemplate, type ButtonTemplateColor} from '../..';

type ButtonCancelProps = {
	onclickHandler: () => void;
	text: string;
	stylesButton?: string;
	color?: ButtonTemplateColor;
	type?: 'button' | 'submit' | 'reset';
};

const ButtonCancel: React.FC<ButtonCancelProps> = ({
	onclickHandler,
	color = 'gray',
	text,
	type,
}) => (
	<ButtonTemplate
		type={type}
		onClick={onclickHandler}
		text={text}
		styleType='medium'
		color={color}
		width='min(240px, 100%)'
	/>
);

export default ButtonCancel;
