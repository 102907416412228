import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {
	type postCheckCreateRequest,
	type postCheckCreateResponse,
	type GetCheckParametersResponse,
	type GetCheckResponse,
	type GetCheckFieldsResponse,
	type GetCheckParametersByIdRequest,
	type GetCheckParametersByIdResponse,
	type postCheckStartRequest,
	type postCheckStartResponse,
	type PostCheckUpdateResponse,
	type PostCheckUpdateRequest,
	type GetCheckReportByIdResponse,
	type GetCheckReportByIdRequest,
	type PostCheckRestartRequest,
	type PostCheckRestartResponse,
	type PostCheckCreateEmailRequest,
	type PostCheckCreateEmailResponse,
	type PostCheckCreateAddressRequest,
	type PostCheckCreateAddressResponse,
	type PostCheckCreatePassportRequest,
	type PostCheckCreatePassportResponse,
	type PostCheckCreatePhoneRequest,
	type PostCheckCreatePhoneResponse,
} from '../models/ICheck';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/check';

// Получение параметров для проверок
export async function getCheckParameters(): Promise<AxiosResponse<GetCheckParametersResponse>> {
	return axios.get(`${domain}${urlPrefix}/parameters`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение заполненных параметров для проверки
export async function getCheckParametersById(data: GetCheckParametersByIdRequest): Promise<AxiosResponse<GetCheckParametersByIdResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.CheckId}/fields`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение только полей для проверки
export async function getCheckFields(): Promise<AxiosResponse<GetCheckFieldsResponse>> {
	return axios.get(`${domain}${urlPrefix}/fields`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Создание проверки
export async function postCheckCreate(data: postCheckCreateRequest): Promise<AxiosResponse<postCheckCreateResponse>> {
	return axios.post(`${domain}${urlPrefix}/create`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Запуск проверки
export async function postCheckStart(data: postCheckStartRequest): Promise<AxiosResponse<postCheckStartResponse>> {
	return axios.post(`${domain}${urlPrefix}/${data.CheckId}/start`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение списка проверок
export async function getCheck(): Promise<AxiosResponse<GetCheckResponse>> {
	return axios.get(`${domain}${urlPrefix}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Обновление проверки
export async function postCheckUpdate(data: PostCheckUpdateRequest): Promise<AxiosResponse<PostCheckUpdateResponse>> {
	return axios.post(`${domain}${urlPrefix}/${data.check_id}/update_start`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение отчета о проверки
export async function getCheckReportById(data: GetCheckReportByIdRequest): Promise<AxiosResponse<GetCheckReportByIdResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.check_id}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Перезапуск проверки
export async function postCheckRestart(data: PostCheckRestartRequest): Promise<AxiosResponse<PostCheckRestartResponse>> {
	return axios.post(`${domain}${urlPrefix}/restart/${data.checkId}`, undefined, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Проверка по почте
export async function postCreateEmail(data: PostCheckCreateEmailRequest): Promise<AxiosResponse<PostCheckCreateEmailResponse>> {
	return axios.post(`${domain}${urlPrefix}/create_email`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Проверка по адресу
export async function postCreateAddress(data: PostCheckCreateAddressRequest): Promise<AxiosResponse<PostCheckCreateAddressResponse>> {
	return axios.post(`${domain}${urlPrefix}/create_address`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Проверка по паспорту
export async function postCreatePassport(data: PostCheckCreatePassportRequest): Promise<AxiosResponse<PostCheckCreatePassportResponse>> {
	return axios.post(`${domain}${urlPrefix}/create_passport`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Проверка по номеру
export async function postCreatePhone(data: PostCheckCreatePhoneRequest): Promise<AxiosResponse<PostCheckCreatePhoneResponse>> {
	return axios.post(`${domain}${urlPrefix}/create_phone`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

