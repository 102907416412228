import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getCheckReportById} from '../../../api/checkApi';
import {type GetCheckReportByIdRequest, type GetCheckReportByIdResponse} from '../../../models/ICheck';

const getCheckReportByIdAction = createActionTemplateAxios<
GetCheckReportByIdResponse,
GetCheckReportByIdRequest
>(
	'checks/getCheckReportById',
	getCheckReportById,
	'userToken',
);

export default getCheckReportByIdAction;
