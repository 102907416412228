import {getUserById} from '../../../api/usersApi';
import {type GetUserByIdRequest} from '../../../models/IUsers';
import {type User} from '../../../models/Schemas';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getUserByIdAction = createActionTemplateAxios<User, GetUserByIdRequest>(
	'user/getUserById/action',
	getUserById,
	'userToken',
);

export default getUserByIdAction;
