import React, {useEffect, useState} from 'react';
import styles from './Navigation.module.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {navigationData, type NavigationItem} from './NavigationData';
import {useAppSelector} from '../../redux/redux-hooks';
import {typePermissions} from '../../models/Enum';
import {paths} from '../../paths';
import {checkPermissionPermit} from '../../utils/permissions/permissions';
import {NavItem} from './NavItem/NavItem';

type NavigationProps = {
	showNames?: boolean;
};

const Navigation: React.FC<NavigationProps> = ({showNames = false}) => {
	const [activeItem, setActiveItem] = useState(0);
	const [navItem, setNavItem] = useState<NavigationItem[]>([]);
	const [isShowNames, setIsShowNames] = useState(showNames);

	const getPermissions = useAppSelector(state => state.getPermissions);
	const navigationContext = useAppSelector(state => state.navigationContext);

	const navigate = useNavigate();
	const location = useLocation();

	const handleItemClick = (path: string) => {
		if (path === paths.departmentPositions) {
			if (navigationContext.department?.id) {
				navigate(path.replace(':id', navigationContext.department.id.toString()));
			}
		} else {
			navigate(path);
		}
	};

	const classNames = (index: number) => {
		let classNames = `${styles['nav-item']}`;
		if (activeItem === index) {
			classNames += ` ${styles.active}`;
		}

		return classNames;
	};

	// Проверка доступности элемента навигации
	const checkAccessNavigationItem = (typePermissions: string, patch: string) => {
		const permissions = checkPermissionPermit(typePermissions, getPermissions.groupPermissions);

		if (!permissions) {
			setNavItem(p => p.filter(item => item.path !== patch));
		}
	};

	// Получение прав
	useEffect(() => {
		if (getPermissions.responseStatus.isLoading) {
			setNavItem([]);
		} else if (getPermissions.responseStatus.isCompleted) {
			setNavItem(navigationData);
			checkAccessNavigationItem(typePermissions.checks_read, paths.checks);
			checkAccessNavigationItem(typePermissions.user_staff_read, paths.subordinate);
		}
	}, [getPermissions.responseStatus.isLoading]);

	useEffect(() => {
		const currentPath = location.pathname;
		const currentItem = navigationData.findIndex(item => {
			if (item.path === paths.home) {
				return currentPath === item.path;
			}

			if (item.path === paths.departmentPositions) {
				const regex = /^\/departments\/[^/]+\/positions$/;
				return regex.test(currentPath);
			}

			return currentPath.startsWith(item.path);
		});
		setActiveItem(currentItem);
	}, [location.pathname]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShowNames(showNames);
		}, 400);

		return () => {
			clearTimeout(timer);
		};
	}, [showNames]);

	return (
		<nav>
			<ul className={styles.nav}>
				{navItem.map(item => (
					<li
						key={item.id}
						className={classNames(item.id)}
						onClick={() => {
							handleItemClick(item.path);
						}}
					>
						<NavItem item={item} isShowNames={isShowNames} />
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Navigation;
