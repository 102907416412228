import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {
	type PostChallengersRequest,
	type PostChallengersResponse,
} from '../../../models/IChallengers';
import {postChallengers} from '../../../api/challengersApi';

const postChallengersAction = createActionTemplateAxios<
PostChallengersResponse,
PostChallengersRequest
>('challengers/postChallengers', postChallengers, 'userToken');

export default postChallengersAction;
