import React from 'react';
import styles from './ErrorMessage.module.css';

type ErrorMessageProps = {
	message?: string | string[];
	isError: boolean;
	fontSize?: 'font-inherit' | 'font-default';
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({
	message,
	isError,
	fontSize = 'font-default',
}) => {
	const getError = () => {
		if (isError && message) {
			if (Array.isArray(message)) {
				return (
					<div>
						{message.map((item, index) => (
							<span key={index} className={`${styles.error} ${styles[fontSize]}`}>
								{item}
							</span>))}
					</div>);
			}

			return (
				<span className={`${styles.error} ${styles[fontSize]}`}>
					{message}
				</span>
			);
		}
	};

	return (
		<>
			{getError()}
		</>
	);
};

export default ErrorMessage;
