import {
	type GetPositionRequest,
	type GetPositionResponse,
} from '../../../models/IDepartment';
import {getPosition} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getPositionAction = createActionTemplateAxios<
GetPositionResponse,
GetPositionRequest>(
	'department/getPosition/action',
	getPosition,
	'userToken',
);

export default getPositionAction;
