import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../InputDevextreme.module.css';
import {Autocomplete, Validator} from 'devextreme-react';
import {emailButton, searchButton} from '../InputButtons';
import {
	type InputValidation,
	type InputStyles,
	type InputAttr,
	type InputButtons,
	type InputMask,
	type InputButton,
} from '../InputsTypes';
import {type ItemClickEvent, type FocusInEvent, type KeyDownEvent} from 'devextreme/ui/autocomplete';
import {type TextBoxType} from 'devextreme/ui/text_box';
import {CustomRule, PatternRule, RequiredRule, StringLengthRule} from 'devextreme-react/cjs/validator';
import {Label, type LabelCommonProps} from '../../../Common';
import {type ResponseStatus} from '../../../../../models/IAuth';

export type InputAutocompleteProps = {
	value: string | undefined;
	setValue: (value: string) => void;

	requestAutocompleteData: () => string | undefined;
	responseAutocompleteData: any[] | undefined;
	responseStatus: ResponseStatus;
	itemRender?: (...params: any) => React.ReactNode;
	onOut?: () => void;
	onItemSelect?: (item: any) => void;
} & InputAutocompleteCommonProps;

type InputStylesAdditional = {
	mode?: TextBoxType;
	dropDownOptions?: {
		height?: number | string;
		minHeight?: number | string;
		maxHeight?: number | string;
	};
};

export type InputAutocompleteCommonProps = {
	maxItemCount?: number;
	onKeyDown?: (e: KeyDownEvent) => void;
	styleType?: 'large' | 'medium' | 'small';

	focusOnInput?: boolean;
	disabled?: boolean;

	inputStyles?: InputStyles & InputStylesAdditional;
	inputValidation?: InputValidation & InputValidationAdditional;
	inputMask?: InputMask;
	inputAttr?: InputAttr;
	inputButtons?: InputButtons;
} & LabelCommonProps;

export type InputValidationAdditional = {
	isEmail?: boolean;
};

const InputAutocomplete: React.FC<InputAutocompleteProps> = ({
	value,
	setValue,
	maxItemCount,
	onOut,
	onItemSelect,
	itemRender,
	requestAutocompleteData,
	responseAutocompleteData,
	responseStatus,
	styleType = 'small',
	title,
	titleColor = 'asphalt',
	focusOnInput,
	disabled,
	inputStyles,
	inputValidation,
	inputMask,
	inputAttr,
	inputButtons,
	onKeyDown,
}) => {
	const requestId = useRef<string[]>([]);
	const inputRef = useRef<Autocomplete>(null);
	const isFocused = useRef(false);
	const validatorRef = useRef<Validator>(null);
	const isSelectedValue = useRef(false);
	const [autocompleteData, setAutocompleteData] = useState<any[]>();
	const [firstInput, setFirstInput] = useState(false);
	const [firstFocusOut, setFirstFocusOut] = useState(false);

	const wrapperClassName = `${styles.wrapper} ${inputButtons ? ' ' + styles.icons : styles['not-icons']}`;
	const inputClassName = `${styles['input-dx']} ${styles[styleType]}`;

	const validationMessagePosition = inputValidation?.validationMessagePosition ?? 'top';
	const validationMessageMode = inputValidation?.validationMessageMode ?? 'auto';

	const validation = useMemo(() => {
		if (inputValidation) {
			const {
				setIsValid,
				isRequired,
				requiredLength,
				minLength,
				lettersOnly,
				isEmail,
				customPatternRule,
				customRule,
			} = inputValidation;
			return (
				<Validator
					ref={validatorRef}
					onValidated={e => {
						if (setIsValid) {
							setIsValid(e.isValid ?? false);
						}
					}}
					validationGroup=''
				>
					{isRequired
						&& <RequiredRule
							message='Поле должно быть заполнено'
						/>}
					{requiredLength
						&& <StringLengthRule
							min={requiredLength}
							max={requiredLength}
							message={`Длина поля должна быть ${requiredLength}`}
						/>}
					{minLength
						&& <StringLengthRule
							min={minLength}
							ignoreEmptyValue
							message={`Минимальная длина поля ${minLength}`}
						/>}
					{lettersOnly
						&& <PatternRule
							pattern={/^[a-zA-Zа-яА-ЯёЁ]+(-[a-zA-Zа-яА-ЯёЁ]+)*$/}
							message='Поле должно содержать только буквы'
						/>}
					{isEmail
						&& <PatternRule
							pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/}
							message='Неправильный электронный адрес'
						/>}
					{customPatternRule?.map((item, index) => (
						<PatternRule key={'patternRule' + index} {...item} />
					))}
					{customRule?.map((item, index) => (
						<CustomRule key={`CustomRule ${index}`} {...item} />
					))}
				</Validator>
			);
		}

		return undefined;
	}, [inputValidation?.setIsValid,
		inputValidation?.isRequired,
		inputValidation?.requiredLength,
		inputValidation?.minLength,
		inputValidation?.lettersOnly,
		inputValidation?.isEmail,
		inputValidation?.customPatternRule,
		inputValidation?.customRule]);

	const maxLength = inputValidation?.requiredLength ?? inputValidation?.maxLength;

	// Получение массива кнопок
	const buttons = useMemo(() => {
		const buttons: InputButton[] = inputButtons?.buttons ?? [];
		if (inputButtons?.searchButton) {
			buttons.push(searchButton);
		}

		if (inputButtons?.emailButton) {
			buttons.push(emailButton);
		}

		if (buttons.length > 0) {
			return buttons;
		}

		return undefined;
	}, [inputButtons]);

	// Изменение значения
	const onValueChange = useCallback((value: string) => {
		setValue(value ?? '');
	}, [setValue]);

	// ОБработка ввода
	const onInput = useCallback((e: any) => {
		isSelectedValue.current = false; // Значение введено

		if (!firstInput) {
			setFirstInput(true);
		}
	}, [setFirstInput]);

	// Фокус
	const onFocusIn = useCallback((e: FocusInEvent) => {
		isFocused.current = true;

		if (value && autocompleteData && autocompleteData.length > 0) {
			inputRef.current?.instance.open();
		}
	}, [value, autocompleteData]);

	// Потеря фокуса
	const onFocusOut = useCallback((e: FocusInEvent) => {
		isFocused.current = false;

		if (!firstFocusOut) {
			setFirstFocusOut(true);
		}

		if (onOut) {
			onOut();
		}

		inputRef.current?.instance.close();
	}, [onOut]);

	// Нажатие на элемент списка
	const onItemClick = useCallback((e: ItemClickEvent) => {
		if (onItemSelect) {
			onItemSelect(e.itemData);
		}

		isSelectedValue.current = true; // Значение выбрано
		inputRef.current?.instance.close();
	}, [onItemSelect]);

	// Авто фокус
	useEffect(() => {
		if (focusOnInput) {
			inputRef.current?.instance.focus();
		}
	}, [focusOnInput]);

	// Начальная валидация
	useEffect(() => {
		if (validatorRef) {
			validatorRef.current?.instance.validate();
		}
	}, []);

	// Получение данных для автозаполнения
	useEffect(() => {
		if (value && isFocused.current && !isSelectedValue.current) {
			const timeout = setTimeout(() => {
				const requestIdAutocompleteData = requestAutocompleteData();
				if (requestIdAutocompleteData) {
					requestId.current.push(requestIdAutocompleteData);
				}
			}, 200);

			return () => {
				clearTimeout(timeout);
			};
		}

		inputRef.current?.instance.close();
	}, [value]);

	// Обновления списка id
	useEffect(() => {
		const index = requestId.current.findIndex(item => item === responseStatus.requestId);
		if (index !== -1
			&& responseStatus.isCompleted
		) {
			requestId.current.splice(0, index + 1);
			setAutocompleteData(responseAutocompleteData);
		}
	}, [responseStatus]);

	useEffect(() => {
		if (value && isFocused.current && autocompleteData && autocompleteData.length > 0) {
			inputRef.current?.instance.open();
		}
	}, [autocompleteData]);

	useEffect(() => {
		if (inputValidation?.showValidation === false || inputValidation?.visible === false) {
			setFirstInput(false);
			setFirstFocusOut(false);
		}
	}, [inputValidation?.showValidation, inputValidation?.visible]);

	return (
		<>
			<Label
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				disabled={disabled}
				isRequired={inputValidation?.isRequired}
			/>
			<div>
				<div>
					<div className={wrapperClassName}>
						<Autocomplete
							dataSource={autocompleteData}
							searchEnabled={false}
							valueExpr={'value'}
							ref={inputRef}
							className={inputClassName}
							value={value}
							onValueChange={onValueChange}
							disabled={disabled}
							validationMessageMode={validationMessageMode}
							validationMessagePosition={validationMessagePosition}
							maxLength={maxLength}
							maxItemCount={maxItemCount}
							buttons={buttons}
							inputAttr={{...inputAttr, autocomplete: 'new-password'}}
							onKeyDown={onKeyDown}
							onFocusIn={onFocusIn}
							onFocusOut={onFocusOut}
							onItemClick={onItemClick}
							onInput={onInput}
							isValid={
								firstInput || inputValidation?.showValidation
									? inputValidation?.isValid ?? true
									: true
							}
							itemRender={itemRender}
							{...inputMask}
							{...inputStyles}
						>
							{validation}
						</Autocomplete>
					</div>
				</div>
			</div>
		</>
	);
};

export default InputAutocomplete;
