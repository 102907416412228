import React from 'react';
import styles from './Advertisements.module.css';
import {type CheckAdvertisements} from '../../../../report/ReportModel';
import {MdContactMail} from 'react-icons/md';
import {useAppSelector} from '../../../../redux/redux-hooks';
import ReportBlockTemplate from '../ReportBlockTemplate';

type AdvertisementsProps = {
	advertisements?: CheckAdvertisements[] | undefined;
};

export const Advertisements: React.FC<AdvertisementsProps> = () => {
	const advertisements = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.advertisements);

	return (
		<ReportBlockTemplate
			blockIcon={MdContactMail}
			blockTitle='Объявления'
			alerts={advertisements?.additionalData.alerts}
			blocksId='advertisements'
			visible={advertisements && advertisements?.data.length > 0}
		>
			<div className={styles.content}>
				<div className={styles['advertisements-group']}>
					{advertisements?.data.map((advertisement, index) => (
						<div key={index} className={styles.advertisements}>
							{advertisement.item.name && (
								<div className={styles.info}>
									<p>Наименование:
										<span>{advertisement.item.name}</span>
									</p>
								</div>
							)}

							{advertisement.item.url && (
								<div className={styles.info}>
									<p>Ссылка:
										<span>{advertisement.item.url}</span>
									</p>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</ReportBlockTemplate>
	);
};
