import axios, {type AxiosResponse} from 'axios';
import {
	type Organization,
	type getDepartmentsResponse,
} from '../models/IOrganization';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/organization';

export async function getOrganization(): Promise<AxiosResponse<Organization>> {
	return axios.get(
		`${domain}${urlPrefix}`,
		{headers: {Authorization: `Bearer ${getToken().token}`}},
	);
}

export async function getDepartments(): Promise<AxiosResponse<getDepartmentsResponse>> {
	return axios.get(
		`${domain}${urlPrefix}/departments`,
		{headers: {Authorization: `Bearer ${getToken().token}`}},
	);
}
