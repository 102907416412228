import {type Organization} from '../../../models/IOrganization';
import {getOrganization} from '../../../api/organizationApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getOrganizationAction = createActionTemplateAxios<
Organization,
void>(
	'organization/getOrganization/action',
	getOrganization,
	'userToken',
);

export default getOrganizationAction;
