import React, {useEffect, useState} from 'react';
import {InputPhone} from '../../../UiKit/Inputs';
import styles from './CreateCheckIndividualPhoneForm.module.css';
import {
	type InteractiveSwitch,
	type InteractiveFieldValues,
	type InteractiveScreen,
} from '../../../../models/IInteractive';

type CreateCheckIndividualPhoneFormProps = {
	fields: React.MutableRefObject<InteractiveFieldValues | undefined>;
	currentScreen: React.MutableRefObject<InteractiveScreen | undefined>;
	currentSwitch?: React.MutableRefObject<InteractiveSwitch | undefined>;
	showValidation: boolean;
	errors?: Record<string, string>;
};

const CreateCheckIndividualPhoneForm: React.FC<CreateCheckIndividualPhoneFormProps> = ({
	fields,
	currentScreen,
	currentSwitch,
	showValidation,
	errors,
}) => {
	const [phone, setPhone] = useState('');
	const [isValid, setIsValid] = useState(false);

	// Изменение данных экранна
	useEffect(() => {
		if (currentScreen.current) {
			const fieldPhone = currentScreen.current.default_values.phone;

			if (typeof fieldPhone === 'string') {
				setPhone(fieldPhone);
			} else {
				setPhone('');
			}
		}
	}, [currentScreen.current]);

	// Обновление значений
	useEffect(() => {
		if (fields.current) {
			fields.current.phone = phone;
		}
	}, [phone]);

	return (
		<div className={styles.container}>
			<div className={styles.group}>
				<h1 className={styles.title}>
					Номер телефона
				</h1>
				<div className={styles.inputs}>
					<InputPhone
						value={phone}
						setValue={setPhone}
						inputStyles={{
							placeholder: 'Введите номер телефона',
						}}
						isValid={isValid}
						setIsValid={setIsValid}
						isRequired
						showValidation={showValidation}
					/>
				</div>
			</div>
		</div>
	);
};

export default CreateCheckIndividualPhoneForm;
