import {
	type PostAuthNoActivatedUserRequest,
	type Token,
} from '../../../models/IAuth';
import {postAuthNoActivatedUser} from '../../../api/authApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const authNoActivatedUserAction = createActionTemplateAxios<
Token,
PostAuthNoActivatedUserRequest>(
	'auth/authNoActivatedUser/action',
	postAuthNoActivatedUser,
	'no',
);

export default authNoActivatedUserAction;
