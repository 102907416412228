import React from 'react';
import {type KeyDownEvent} from 'devextreme/ui/text_box';
import Input from '../../Template/Input/Input';
import {type InputAttr, type InputStyles} from '../../Template/InputsTypes';

type InputPhoneProps = {
	value: string;
	setValue: (value: string) => void;
	isValid: boolean;
	setIsValid: (value: boolean) => void;
	showValidation: boolean;
	isRequired?: boolean;
	visible?: boolean;

	title?: string;
	inputStyles?: InputStyles;
	inputAttr?: InputAttr;
	styleType?: 'large' | 'medium' | 'small';
	focusOnInput?: boolean;
	disabled?: boolean;
	onKeyDown?: (e: KeyDownEvent) => void;
};

const InputEmail: React.FC<InputPhoneProps> = ({
	value,
	setValue,
	isValid,
	setIsValid,
	showValidation,
	isRequired = false,
	visible,
	inputAttr,
	...props
}) => (
	<Input
		value={value}
		setValue={setValue}
		inputValidation={{
			setIsValid,
			isValid,
			isRequired,
			showValidation,
			isEmail: true,
			visible,
		}}
		inputAttr={{...inputAttr, autoComplete: 'email'}}
		{...props}
	/>
);

export default InputEmail;
