
import {getCheckFields} from '../../../api/checkApi';
import {type GetCheckFieldsResponse} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getCheckFieldsAction = createActionTemplateAxios<
GetCheckFieldsResponse,
void>(
	'checks/getCheckFieldsAction/action',
	getCheckFields,
	'userToken',
);

export default getCheckFieldsAction;
