import React, {useEffect, useState} from 'react';
import RadioSelectorItem from './RadioSelectorItem/RadioSelectorItem';

type RadioSelectorProps = {
	items: RadioSelectorItems[];
	setSelectedData: (value: any) => void;
	visible?: boolean;
};

export type RadioSelectorItems = {
	text: string;
	selectedData: any;
};

const RadioSelector: React.FC<RadioSelectorProps> = ({
	items,
	visible,
	setSelectedData,
}) => {
	const [selectedItemKey, setSelectedItemKey] = useState<number>();

	useEffect(() => {
		if (selectedItemKey === undefined) {
			setSelectedData(undefined);
		} else {
			const item = items[selectedItemKey];
			if (item) {
				setSelectedData(item.selectedData);
			}
		}
	}, [selectedItemKey]);

	useEffect(() => {
		setSelectedItemKey(undefined);
	}, [visible]);

	return (
		<>
			{items.map((item, index) => (
				<RadioSelectorItem
					key={index}
					itemKey={index}
					selectedItemKey={selectedItemKey}
					setSelectedItemKey={setSelectedItemKey}
					{...item}
				/>
			))}
		</>
	);
};

export default RadioSelector;
