
import {getCheckParameters} from '../../../api/checkApi';
import {type GetCheckParametersResponse} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getCheckParametersAction = createActionTemplateAxios<
GetCheckParametersResponse,
void>(
	'checks/getCheckParameters/action',
	getCheckParameters,
	'userToken',
);

export default getCheckParametersAction;
