import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutCommentResponse} from '../../../models/IComment';
import {putCommentAction} from '../../action/comments';

type PutCommentSliceState = {
	responseStatus: ResponseStatus;
	response: PutCommentResponse | undefined;
};

const initialState: PutCommentSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const putCommentSlice = createSlice({
	name: 'comments/putComment',
	initialState,
	reducers: {
		putCommentResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(putCommentAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(putCommentAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(putCommentAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {putCommentResetState} = putCommentSlice.actions;

export default putCommentSlice.reducer;
