import React, {useEffect} from 'react';
import styles from '../ModalReport.module.css';
import {ModalWindow} from '../../../';
import {passportFieldsV3} from './passportFields';
import {type ReportModelV3PassportRf} from '../../../../../report/reportModelV3/reportModelV3';
import {IoDocument} from 'react-icons/io5';
import {CopyToClipboardButton} from '../../../../UiKit/Button';
import {
	ModalSelectCheckButton,
} from '../../../../UiKit/Button/ReportButtons/ModalSelectCheckButton/ModalSelectCheckButton';

type ModalCheckAddressesProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	setVisibleSubModal: (valude: boolean) => void;
	passport: ReportModelV3PassportRf | undefined;
	containerId: string | undefined;
};

export const ModalPassport: React.FC<ModalCheckAddressesProps> = ({
	passport,
	setVisible,
	setVisibleSubModal,
	visible,
	containerId,
}) => {
	const openSubModal = () => {
		setVisibleSubModal(true);
	};

	useEffect(() => {
		setVisibleSubModal(false);
	}, [visible]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			header={
				<div className={styles.title}>
					<IoDocument/>
					<span>Паспорт</span>
				</div>
			}
			footerItems={[
				<ModalSelectCheckButton
					setVisible={setVisible}
					openSubModal={openSubModal}
					containerId={containerId}
					key='modalSelectCheckButton'
				/>,
			]}
			footerItemsLocation='after'
			closeOnOutsideClick
			width='min(800px, 90%)'
		>
			<div className={styles.wrapper}>
				{passport && (
					<div className={styles.content}>
						{passportFieldsV3.map((field, idx) => {
							const passportField = passport[field.key];
							if (typeof passportField === 'string' && passportField) {
								return (
									<React.Fragment key={idx}>
										<span className={styles.abbreviation}>
											{field.label}:
										</span>
										<div className={styles['result-container']}>
											<span className={styles.result}>
												{passportField}
											</span>
											{field.clipboard && <CopyToClipboardButton text={passportField}/>}
										</div>
									</React.Fragment>
								);
							}

							return null;
						})}
						<span className={styles.abbreviation}>Действительность:</span>
						<span className={styles.result}>{passport.is_valid ? 'Действителен' : 'Недействителен'}</span>
					</div>
				)}
			</div>
		</ModalWindow>
	);
};
