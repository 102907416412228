import React from 'react';
import styles from './PersonPhoto.module.css';
import {type PersonV3} from '../../../../../report/reportModelV3/reportModelV3';
import {UserIcon} from '../../../../UiKit/Div';
import {useAppSelector} from '../../../../../redux/redux-hooks';

type PersonBasicInfProps = {
	person: PersonV3;
};

const PersonPhoto: React.FC<PersonBasicInfProps> = ({
	person,
}) => {
	const registries = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.registries);
	const urlPhoto = registries?.data.find(registry => registry.item.url_photo)?.item.url_photo;

	if (urlPhoto) {
		return (
			<img src={urlPhoto} alt='Фото' className={styles['wanted-photo']}/>
		);
	}

	return (<UserIcon label={person?.name.last_name[0]} size='large' />);
};

export default PersonPhoto;
