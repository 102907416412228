import {type ResponseStatus} from '../../../models/IAuth';
import {type GetEmployeesAutofillResponse} from '../../../models/IEmployees';
import {createSlice} from '@reduxjs/toolkit';
import {getEmployeesAutofillAction} from '../../action/employees';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetEmployeesAutofillResponse | undefined;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
};

const getEmployeesAutofillSlice = createSlice({
	name: 'employees/getEmployeesAutofill',
	initialState,
	reducers: {
		getEmployeesAutofillResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getEmployeesAutofillAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getEmployeesAutofillAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
				state.response = action.payload;
			})
			.addCase(getEmployeesAutofillAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getEmployeesAutofillResetState} = getEmployeesAutofillSlice.actions;
export default getEmployeesAutofillSlice.reducer;
