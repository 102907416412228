import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type User} from '../../../models/Schemas';
import {putUserAction} from '../../action/users';

const initialState: ResponseStatus & {user: User | undefined} = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	user: undefined,
};

const createPutUserSlice = createSlice({
	name: 'putUserSlice',
	initialState,
	reducers: {
		isCompletedReset(state) {
			state.isCompleted = false;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(putUserAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.user = undefined;
			})
			.addCase(putUserAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.user = action.payload;
			})
			.addCase(putUserAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.status = action.payload?.status;
				state.user = undefined;
			});
	},
});

export const {isCompletedReset} = createPutUserSlice.actions;
export default createPutUserSlice.reducer;
