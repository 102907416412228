export const statusCandidate = [
	{label: 'В работе', data: 'in_progress'},
	{label: 'Ожидает звонка', data: 'wait_call'},
	{label: 'Ожидаем ответа кандидата', data: 'wait_candidate_answer'},
	{label: 'Ожидает решения руководителя', data: 'wait_decision'},
	{label: 'Ожидаем информацию от кандидата', data: 'wait_candidate_info'},
	{label: 'Ожидает проверки', data: 'wait_check'},
	{label: 'Приглашен на собеседование', data: 'invited'},
	{label: 'Нанят на работу', data: 'hired'},
	{label: 'Отказ', data: 'rejection'},
	{label: 'Отказ кандидата', data: 'candidate_rejection'},
];
