import {type SocialAccount} from '../../../../report/Entity';
import {type SocialAccountTypes} from '../../../../report/reportModelEnum';
import {formatPhoneNumber} from '../../../../utils';

const socialAccountLinks: Record<SocialAccountTypes, ((socialAccount: SocialAccount) => string | undefined) | undefined> = {
	whatsapp({phone_number}) {
		if (phone_number) {
			return `https://wa.me/${phone_number}`;
		}
	},
};

const socialAccountGetText: Record<SocialAccountTypes, ((socialAccount: SocialAccount) => string | undefined) | undefined> = {
	whatsapp({phone_number}) {
		if (phone_number) {
			return formatPhoneNumber(phone_number);
		}
	},
};

export const getSocialAccountLinks = (socialAccount: SocialAccount) => {
	const getHref = (socialAccount.type && socialAccountLinks[socialAccount.type]) ?? undefined;
	const getText = (socialAccount.type && socialAccountGetText[socialAccount.type]) ?? undefined;

	if (getHref && getText) {
		return {
			text: getText(socialAccount),
			href: getHref(socialAccount),
		};
	}

	return {
		text: `Не обработанный тип ${socialAccount.type}`,
	};
};
