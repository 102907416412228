import axios, {type AxiosResponse} from 'axios';
import {
	type PutUserRequest,
	type ConfirmationPhoneNumber,
	type CreateUsers,
	type SmsRegistrationResponse,
	type SmsRegistrationRequest,
	type GetUserStaffResponse,
	type GetUserByIdRequest,
	type CreateUsersResponse,
	type PostUsersViaFileRequest,
	type PostUsersViaFileResponse,
	type GetPermissionsResponse,
	type GetUserAuthHistoryRequest,
	type GetUserAuthHistoryResponse,
} from '../models/IUsers';
import {type User} from '../models/Schemas';
import {type Token} from '../models/IAuth';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/user';

// Получить данные о себе
export async function getUser(): Promise<AxiosResponse<User>> {
	return axios.get(`${domain}${urlPrefix}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получить данные о другом пользователе
export async function getUserById(
	data: GetUserByIdRequest,
): Promise<AxiosResponse<User>> {
	return axios.get(`${domain}${urlPrefix}/${data.userId}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function postUsersViaFile(
	data: PostUsersViaFileRequest,
): Promise<AxiosResponse<PostUsersViaFileResponse>> {
	const formData = new FormData();
	formData.append('file', data.body.file);
	formData.append('department_id', data.body.department_id.toString());

	return axios.post(`${domain}${urlPrefix}`, formData, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Новый Обновление информации о себе
export async function putUser(
	data: PutUserRequest,
): Promise<AxiosResponse<User>> {
	return axios.put(`${domain}${urlPrefix}`, data.user, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function createRequestUsers(
	data: CreateUsers,
): Promise<AxiosResponse<CreateUsersResponse>> {
	const response: AxiosResponse<CreateUsersResponse> = await axios.post(
		`${domain}${urlPrefix}/create_request`,
		{
			email: data.email,
		},
		{headers: {Authorization: `Bearer ${getToken().token}`}},
	);
	return response;
}

export async function sendSmsRegistration(
	data: SmsRegistrationRequest,
): Promise<AxiosResponse<SmsRegistrationResponse>> {
	const response: AxiosResponse<SmsRegistrationResponse> = await axios.post(
		`${domain}${urlPrefix}/contact/phone/otp_send`,
		{
			phone: data.phone,
			debug: data.debug,
		},
		{headers: {Authorization: `Bearer ${getToken().token}`}},
	);
	return response;
}

export async function sendSmsCodeRegistration(
	data: ConfirmationPhoneNumber,
): Promise<AxiosResponse<Token>> {
	const response: AxiosResponse<Token> = await axios.put(
		`${domain}${urlPrefix}/contact/phone/otp_verify`,
		data,
		{headers: {Authorization: `Bearer ${getToken().token}`}},
	);
	return response;
}

export async function getUserStaff(): Promise<
AxiosResponse<GetUserStaffResponse>
> {
	const response: AxiosResponse<GetUserStaffResponse> = await axios.get(
		`${domain}${urlPrefix}/staff`,
		{headers: {Authorization: `Bearer ${getToken().token}`}},
	);

	return response;
}

export async function getPermissions(): Promise<
AxiosResponse<GetPermissionsResponse>
> {
	return axios.get(`${domain}${urlPrefix}/permissions`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

export async function getUserAuthHistory(
	data: GetUserAuthHistoryRequest,
): Promise<AxiosResponse<GetUserAuthHistoryResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.user_id}/auth_history`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
