import {getNavigationDepartments} from '../../../api/Navigation/navigationApi';
import {type GetNavigationDepartmentsRequest, type GetNavigationDepartmentsResponse} from '../../../models/Navigation/Navigation';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getNavigationDepartmentsAction = createActionTemplateAxios<
GetNavigationDepartmentsResponse,
GetNavigationDepartmentsRequest
>('navigation/getNavigationDepartments/action', getNavigationDepartments, 'userToken');

export default getNavigationDepartmentsAction;
