/* eslint-disable @typescript-eslint/ban-types */
import {DateTime} from 'luxon';

const parseDate = (stringDate: string | undefined | null) => {
	if (stringDate) {
		const formats = [
			'yyyy-MM-dd',
			'dd.MM.yyyy',
			'dd.MM.yyyy, hh:mm',
		];

		for (const format of formats) {
			const luxonDate = DateTime.fromFormat(stringDate, format);
			if (luxonDate.isValid) {
				return luxonDate.toJSDate();
			}
		}
	}
};

const parseISODate = (stringDate: string | undefined | null) => {
	if (stringDate) {
		const luxonDate = DateTime.fromISO(stringDate);
		if (luxonDate.isValid) {
			return luxonDate.toJSDate();
		}
	}
};

const compareDate = (date1: Date | undefined, date2: Date | undefined) => {
	if (!date1 && !date2) {
		return 0;
	}

	if (!date1) {
		return 1;
	}

	if (!date2) {
		return -1;
	}

	if (date1 < date2) {
		return -1;
	}

	if (date1 > date2) {
		return 1;
	}

	return 0;
};

export const stringDateSorting = (stringDate1: string | undefined | null, stringDate2: string | undefined | null) => {
	const date1 = parseDate(stringDate1);
	const date2 = parseDate(stringDate2);

	return compareDate(date1, date2);
};

export const stringISODateSorting = (stringDate1: string | undefined | null, stringDate2: string | undefined | null) => {
	const date1 = parseISODate(stringDate1);
	const date2 = parseISODate(stringDate2);

	return compareDate(date1, date2);
};
