import React, {useEffect, useRef, useState} from 'react';
import styles from './ModalReportUpdateResolution.module.css';
import ModalWindow from '../../ModalWindow';
import {Input} from '../../../UiKit/Inputs';
import RadioSelector, {type RadioSelectorItems} from '../../../UiKit/Selectors/RadioSelector/RadioSelector';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {putReportResolutionAction} from '../../../../redux/action/report';
import {useParams} from 'react-router-dom';
import {putReportResolutionResetState} from '../../../../redux/slice/report';
import {ButtonCancel, ButtonConfirm} from '../../../UiKit/Button';
import {ErrorMessage} from '../../../UiKit/Message';
import {getCheckReportByIdAction} from '../../../../redux/action/checks';

type ModalUpdateReportResolutionProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
};

const choiceItems: RadioSelectorItems[] = [
	{
		text: 'Отказать',
		selectedData: false,
	},
	{
		text: 'Согласовать',
		selectedData: true,
	},
];

const ModalUpdateReportResolution: React.FC<ModalUpdateReportResolutionProps> = ({
	visible,
	setVisible,
}) => {
	const putReportResolutionRequestId = useRef<string>();
	const [comment, setComment] = useState<string>('');
	const [result, setResult] = useState<boolean>();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const putReportResolution = useAppSelector(state => state.putReportResolution);
	const dispatch = useAppDispatch();
	const {id} = useParams();

	const selectResolution = (value: any) => {
		setResult(value as (boolean | undefined));
	};

	// Обновить резолюцию
	const updateResolution = () => {
		if (id && result !== undefined && (result || (!result && comment !== ''))) {
			putReportResolutionRequestId.current = dispatch(putReportResolutionAction({
				id: parseInt(id, 10),
				body: {
					resolution_comment: comment === '' ? null : comment,
					resolution_result: result,
				},
			})).requestId;
		}
	};

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	// Отобразить ошибку
	const showError = (message?: string) => {
		setIsError(true);
		setErrorMessage(message);
	};

	// Скрыть ошибку
	const hiddenError = () => {
		setIsError(false);
		setErrorMessage(undefined);
	};

	// Сброс состояний компонента
	const resetComponentStates = () => {
		hiddenError();
		hiddenLoading();

		putReportResolutionRequestId.current = undefined;
		dispatch(putReportResolutionResetState());

		setComment('');
		setResult(undefined);
	};

	// Очистка окна
	useEffect(() => {
		resetComponentStates();
	}, [visible]);

	// Обновление резолюции
	useEffect(() => {
		const {requestId, isCompleted, isError, isLoading, errorMessage} = putReportResolution.responseStatus;

		if (requestId !== putReportResolutionRequestId.current) {
			return;
		}

		if (isLoading) {
			showLoading('Внесение резолюции');
			hiddenError();
		} else {
			putReportResolutionRequestId.current = undefined;
			hiddenLoading();
			if (isCompleted) {
				if (id) {
					void dispatch(getCheckReportByIdAction({check_id: parseInt(id, 10)}));
				}

				setVisible(false);
			} else if (isError) {
				showError(errorMessage);
			}
		}
	}, [putReportResolution.responseStatus.isLoading]);

	return (
		<ModalWindow
			visible={visible}
			setVisible={setVisible}
			footerItems={[
				<ButtonCancel
					key='ButtonCancel'
					onclickHandler={() => {
						setVisible(false);
					}}
					text='Закрыть'
				/>,
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={updateResolution}
					text='Отправить'
					disable={result === undefined || (!result && comment === '')}
				/>,
			]}
			header
			loadingMessage={loadingMessage}
			isLoading={isLoading}
			width={'min(680px, 90%)'}
			closable
			closeOnOutsideClick
		>
			<div className={styles.container}>
				<div className={styles.choice}>
					<p className={styles['choice-question']}>
						Согласован ли найм сотрудника со стороны СБ?
					</p>
					<div className={styles['choice-list']}>
						<RadioSelector
							items={choiceItems}
							setSelectedData={selectResolution}
							visible={visible}
						/>
					</div>
				</div>
				<div className={styles.comment}>
					<p className={styles['comment-header']}>
						Опишите причину
					</p>
					<div className={styles['comment-input-container']}>
						<Input
							value={comment}
							setValue={setComment}
							titleColor='main'
							inputStyles={{
								placeholder: 'Комментарий',
							}}
							inputValidation={{
								isRequired: result === false,
								isValid: !(result === false && comment === ''),
								showValidation: true,
							}}
						/>
					</div>
				</div>
				<ErrorMessage isError={isError} message={errorMessage} />
			</div>
		</ModalWindow>
	);
};

export default ModalUpdateReportResolution;
