export const headersTransports: Record<string, string> = {
	last_owner: 'Последний владелец:',
	type: 'Тип:',
	number: 'Номер:',
	model: 'Марка:',
	address: 'Адрес:',
	color: 'Цвет:',
	year: 'Год:',
	pts: 'Номер ПТС:',
	pts_date: 'Дата ПТС:',
	sts: 'СТС:',
	vin: 'VIN Номер:',
	engine_number: 'Номер двигателя:',
	chassis_number: 'Номер шасси:',
	car_body_number: 'Номер кузова:',
	mass: 'Масса:',
	load_capacity: 'Грузоподъемность:',
	power: 'Мощность двигателя:',
	drive: 'Привод:',
	previous_numbers: 'Предыдущие номера:',
	owners: 'Владельцы:',
};

