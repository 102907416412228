import React from 'react';
import {IoAdd} from 'react-icons/io5';
import styles from './GreenBorderButton.module.css';
import ButtonTemplate, {type ButtonTemplateStyleType} from '../ButtonTemplate/ButtonTemplate';

type GreenBorderButtonProps = {
	onClick: () => void;
	text: string;
	styleType?: ButtonTemplateStyleType;
};

const GreenBorderButton: React.FC<GreenBorderButtonProps> = ({
	onClick,
	text,
	styleType,
}) => (
	<ButtonTemplate
		onClick={onClick}
		color={'green-outline'}
		borderRadius='16px'
		styleType={styleType}
	>
		<span>{text}</span>
		<IoAdd className={styles.icon}/>
	</ButtonTemplate>
);

export default GreenBorderButton;
