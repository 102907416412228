import {createSlice} from '@reduxjs/toolkit';
import {sendSmsRegistrationAction} from '../../action/users';
import {type ResponseStatus} from '../../../models/IAuth';
import {type SmsRegistrationResponse} from '../../../models/IUsers';

type Sms = ResponseStatus & SmsRegistrationResponse;

const initialState: Sms = {
	isCompleted: false,
	isLoading: false,
	isError: false,
	errorMessage: '',
	phone_last_charts: '',
	sms_sent_at: undefined,
	code_number: '',
	message: '',
};

const smsSlice = createSlice({
	name: 'smsRegistration',
	initialState,
	reducers: {
		smsRegistrationIsCompleteReset(state) {
			state.isCompleted = false;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(sendSmsRegistrationAction.pending, state => {
				state.isLoading = true;
				state.isCompleted = false;
				state.isError = false;
				state.phone_last_charts = '';
				state.sms_sent_at = undefined;
			})
			.addCase(sendSmsRegistrationAction.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isCompleted = true;
				state.isError = false;
				state.phone_last_charts = action.payload.phone_last_charts;
				state.sms_sent_at = action.payload.sms_sent_at! * 1000;
				state.code_number = action.payload.code_number;
				state.message = action.payload.message;
			})
			.addCase(sendSmsRegistrationAction.rejected, (state, action) => {
				state.isLoading = false;
				state.isCompleted = false;
				state.isError = true;
				state.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.phone_last_charts = '';
				state.sms_sent_at = undefined;
			});
	},
});

export const smsRegistrationReducer = smsSlice.reducer;
export const {smsRegistrationIsCompleteReset} = smsSlice.actions;
