import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {SelectBox, Validator} from 'devextreme-react';
import styles from '../InputDevextreme.module.css';
import {type ValidatedEvent} from 'devextreme/ui/validator';
import {RequiredRule} from 'devextreme-react/cjs/validator';
import Loader from '../../../../Loader/Loader';
import {type InputButton} from '../InputsTypes';
import {Label, type LabelCommonProps} from '../../../Common';
import {type template} from 'devextreme/core/templates/template';
import {type DxElement, type UserDefinedElement} from 'devextreme/core/element';

export type DropdownDefaultValue = {
	label: string;
	data: any;
};

type DropdownProps = DropdownValue & DropdownStyles & DropdownValidation & DropDownRender;

type DropdownValue = {
	selectedValue: any;
	setSelectedValue: (value: any) => void;
	displayValueKey?: string;
	setValueKey?: string;
	options: any[] | undefined;
};

type DropdownStyles = {
	width?: string | number;
	readOnly?: boolean;
	isLoading?: boolean;
	emptyMessage?: string;
	placeholder?: string;
	styleType?: 'large' | 'medium' | 'small';
	icons?: InputButton[];
	showClear?: boolean;
} & LabelCommonProps;

type DropdownValidation = {
	isValid?: boolean;
	setIsValid?: (value: boolean) => void;
	isRequired?: boolean;
	showValidation?: boolean;
	visible?: boolean;
	invalidMessagePosition?: 'top' | 'bottom' | 'left' | 'right' | 'mouse';
};

type DropDownRender = {
	itemRender?: ((...params: any) => React.ReactNode) | undefined;
};

const Dropdown: React.FC<DropdownProps> = ({
	selectedValue,
	setSelectedValue,
	displayValueKey,
	setValueKey,
	showClear,
	emptyMessage,
	options,
	isValid = true,
	setIsValid,
	showValidation,
	isRequired,
	visible,
	title,
	titleColor = 'asphalt',
	styleType = 'small',
	isLoading,
	placeholder,
	icons,
	readOnly,
	width,
	itemRender,
}) => {
	const [firstChange, setFirstChange] = useState(false);
	const [firstFocusOut, setFirstFocusOut] = useState(false);

	const isFocused = useRef(false);
	const selectBoxRef = useRef<SelectBox>(null);
	const validatorRef = useRef<Validator>(null);
	const wrapperClassName = `${styles.wrapper} ${icons ? ' ' + styles.icons : styles['not-icons']}`;
	const inputClassName = `${styles['input-dx']} ${styles[styleType]} `;

	// Получение массива кнопок
	const buttons = useMemo(() => {
		const buttons: InputButton[] = [];

		if (icons) {
			buttons.push(...icons);
		}

		if (showClear) {
			buttons.push({name: 'clear'});
		}

		buttons.push({name: 'dropDown'});

		return buttons;
	}, [icons, showClear]);

	// Изменение выбранного значения
	const onValueChanged = useCallback((e: any) => {
		if (!firstChange && isFocused.current) {
			setFirstChange(true);
		}

		setSelectedValue(e);
	}, [firstChange, setFirstChange, setSelectedValue]);

	// Изменения состояния валидации
	const onValidated = useCallback((e: ValidatedEvent) => {
		if (setIsValid) {
			setIsValid(e.isValid ?? false);
		}
	}, [setIsValid]);

	const onFocusOut = useCallback(() => {
		isFocused.current = false;

		if (!firstFocusOut) {
			setFirstFocusOut(true);
		}
	}, [firstFocusOut, setFirstFocusOut]);

	const onFocusIn = useCallback(() => {
		isFocused.current = true;
	}, [firstFocusOut, setFirstFocusOut]);

	// Валидация при изменении значений
	useEffect(() => {
		if (validatorRef.current) {
			validatorRef.current.instance.validate();
		}
	}, [selectedValue, options]);

	useEffect(() => {
		if (showValidation === false || visible === false) {
			setFirstFocusOut(false);
			setFirstChange(false);
		}
	}, [showValidation, visible]);

	return (
		<>
			<Label
				title={title}
				titleColor={titleColor}
				styleType={styleType}
				disabled={readOnly}
				isRequired={isRequired}
			/>
			<div className={wrapperClassName}>
				<SelectBox
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					value={selectedValue}
					onValueChange={onValueChanged}
					onFocusIn={onFocusIn}
					onFocusOut={onFocusOut}
					isValid={
						!readOnly
						&& (firstChange || showValidation)
							? isValid
							: true}
					items={options}
					displayExpr={displayValueKey}
					valueExpr={setValueKey}
					noDataText={emptyMessage}
					ref={selectBoxRef}
					searchEnabled
					searchMode='contains'
					showClearButton={showClear}
					className={inputClassName}
					placeholder={placeholder}
					buttons={buttons}
					disabled={readOnly}
					width={width}
					itemRender={itemRender}
				>
					<Validator
						ref={validatorRef}
						onValidated={onValidated}
					>
						{isRequired && <RequiredRule
							message='Обязательное поле'
						/>}
					</Validator>
				</SelectBox>
				<Loader isFloating isLoading={isLoading ?? false} size='small' borderRadius='8px' />
			</div>
		</>
	);
};

export default Dropdown;
