/* eslint-disable @typescript-eslint/ban-types */
import {getFullName} from '../../utils';
import {
	type CheckTransports,
	type CheckCourtDecisions,
	type CheckRegistries,
	type CheckDocuments,
	type Microlans,
	type CheckNameHistory,
} from '../ReportModel';
import {type ReportModelV3PassportRf, type SummaryData} from './reportModelV3';
import {DateTime} from 'luxon';

export function processNameHistory(item: CheckNameHistory) {
	return {
		...item,
		nameHistoryFullName: getFullName(item.first_name, item.middle_name, item.last_name, 'LFM'),
	};
}

export function processCourtDecision(courtDecisions: CheckCourtDecisions, index: number): CheckCourtDecisions {
	let bailiffTotal: number;

	if (typeof courtDecisions.bailiff_total === 'string' && courtDecisions.bailiff_total !== '') {
		bailiffTotal = parseFloat(courtDecisions.bailiff_total);
	} else if (typeof courtDecisions.bailiff_total === 'number') {
		bailiffTotal = courtDecisions.bailiff_total;
	} else {
		bailiffTotal = 0;
	}

	const finalBailiffTotal = isNaN(bailiffTotal) ? 0 : bailiffTotal;

	return {
		...courtDecisions,
		debtor_name: {
			...courtDecisions.debtor_name,
			fullName: getFullName(courtDecisions.debtor_name.first_name, courtDecisions.debtor_name.middle_name, courtDecisions.debtor_name.last_name, 'LFM'),
		},
		bailiff_total: finalBailiffTotal,
		rowNumber: index + 1,
	};
}

export function processTransport(transport: CheckTransports): CheckTransports {
	return ({
		...transport,
		last_owner: {
			...transport.last_owner,
			full_name: getFullName(transport.last_owner.first_name, transport.last_owner.middle_name, transport.last_owner.last_name, 'LFM'),
		},
		owners: transport.owners.map(item => ({
			...item,
			full_name: getFullName(item.first_name, item.middle_name, item.last_name, 'LFM'),
		})),
	});
}

export function processRegistry(registry: CheckRegistries): CheckRegistries {
	if (registry.type === 'mvd_wanted') {
		if (registry.photo) {
			if (!registry.photo.startsWith('https:') && !registry.photo.startsWith('http:')) {
				return {
					...registry,
					url_photo: `https:${registry.photo}`,
				};
			}

			if (registry.photo.startsWith('http:')) {
				return {
					...registry,
					url_photo: registry.photo.replace('http:', 'https:'),
				};
			}

			return {
				...registry,
				url_photo: registry.photo,
			};
		}
	}

	return registry;
}

export function processDocument(document: CheckDocuments): CheckDocuments {
	document.passport = `${document.series} ${document.number}`;

	return document;
}

export function processDocumentV3(document: ReportModelV3PassportRf): ReportModelV3PassportRf {
	document.passport = `${document.series} ${document.number}`;
	document.full_name = getFullName(document.first_name, document.second_name, document.last_name, 'LsFsM');

	return document;
}

export function processMicroloan(microloan: Microlans): Microlans {
	let date: string | undefined;

	if (typeof microloan.date === 'string') {
		const luxonDate = DateTime.fromFormat(microloan.date, 'yyyy-MM-dd');

		if (luxonDate.isValid) {
			date = luxonDate.toFormat('dd.MM.yyyy');
		} else {
			date = microloan.date;
		}
	}

	return {
		...microloan,
		date,
	};
}

export function processSummaryData(summaryDataArray: SummaryData[] | undefined | null): SummaryData[] {
	if (summaryDataArray) {
		return summaryDataArray.map(summaryData => {
			if (summaryData.status === null) {
				summaryData.status = undefined;
			}

			return summaryData;
		});
	}

	return [];
}
