import React, {useMemo} from 'react';
import commonTableStyles from '../CommonTableStyles.module.css';
import {useAppSelector} from '../../../redux/redux-hooks';
import {TableIconButton} from '../../UiKit/Button';
import {type EditContactsPermissions} from '../../Modal/ModalEditContacts/ModalEditContacts';
import {Email, Phone} from '../../UiKit/Message';
import {type CheckPermissions} from '../../Modal/ModalCheckCreated/ModalCheckCreated';
import {checkPermission} from '../../../utils/permissions/permissions';
import {type ColumnsProps, Table} from '../../UiKit/Tables';
import {type UserStaffTableData} from '../../../redux/slice/users';
import {UserIcon} from '../../UiKit/Div';
import {FiEdit2, FiSearch, FiUser} from 'react-icons/fi';
import {LiaUserEditSolid} from 'react-icons/lia';
import {typePermissions} from '../../../models/Enum';
import {stringDateSorting} from '../../../utils/sorting/dateSorting';
import {type GetDepartmentEmployeesProcessedResponseItem} from '../../../redux/slice/department';

type PositionTableProps = {
	value: GetDepartmentEmployeesProcessedResponseItem[] | undefined;
	openProfile: (id: number) => void;
	openEditContact: (id: number, permissions: EditContactsPermissions) => void;
	openCreateCheck: (id: number, permissions: CheckPermissions) => void;
	openEditProfile: (id: number) => void;
};

const SubordinateEmployeeTable: React.FC<PositionTableProps> = ({
	value,
	openProfile,
	openEditContact,
	openCreateCheck,
	openEditProfile,
}) => {
	const myId = useAppSelector(state => state.getUser.user?.employee_id);
	const myDepartmentId = useAppSelector(state => state.getUser.user?.department?.id);
	const {groupPermissions} = useAppSelector(state => state.getPermissions);

	const columns: ColumnsProps[] = useMemo(() => [
		{
			caption: 'ФИО',
			dataField: 'fullName',
			allowSorting: true,
			width: 120,
			minWidth: 120,
			cellRender(rowData) {
				const employee = rowData.row.data as UserStaffTableData;
				const isMyDepartment = employee.department.id === myDepartmentId;
				const isMe = myId === employee.id;

				const isRead = checkPermission(
					employee.user_id
						? typePermissions.user_read : typePermissions.employee_read,
					{
						groupPermissions,
						isMe,
						isMyDepartment,
					},
				);

				return (
					<div
						className={commonTableStyles['table-link']}
						onClick={() => {
							if (isRead) {
								openProfile(employee.id);
							}
						}}
					>
						<UserIcon
							label={employee.first_name[0]}
							round
							size='small'
						/>
						<span
							className={`${commonTableStyles['column-text']} ${employee.user_id ? commonTableStyles['mint-text'] : ''}`}
						>
							{employee.fullName}
						</span>
					</div>
				);
			},
		},
		{
			caption: 'Должность',
			dataField: 'department_position.name',
			minWidth: 100,
			allowSorting: true,
		},
		{
			caption: 'Контактные данные',
			allowSorting: false,
			minWidth: 100,
			cellRender(rowData) {
				const employee = rowData.row.data as UserStaffTableData;
				return employee.phone === ''
					? <Email email={employee.email} />
					: <Phone phone={employee.phone} formatPhone />;
			},
		},
		{
			caption: 'Дата рождения',
			dataField: 'birth_date',
			allowSorting: true,
			minWidth: 100,
			sortingMethod: stringDateSorting,
		},
		{
			width: 120,
			minWidth: 120,
			type: 'buttons',
			allowSorting: false,
			cellRender(rowData) {
				const employee = rowData.row.data as UserStaffTableData;
				const parametersCheckPermission = {
					groupPermissions,
					isMyDepartment: employee.department.id === myDepartmentId,
					isMe: myId === employee.id,
				};

				const isUpdate = checkPermission(
					employee.user_id
						? typePermissions.user_update : typePermissions.employee_update,
					parametersCheckPermission,
				);
				const isRead = checkPermission(
					employee.user_id
						? typePermissions.user_read : typePermissions.employee_read,
					parametersCheckPermission,
				);

				const isCheckRequestCreate = checkPermission(
					typePermissions.create_check_request,
					parametersCheckPermission,
				);
				const isZeroReportCreate = checkPermission(
					typePermissions.zero_report_create,
					parametersCheckPermission,
				);

				const employeeRead = checkPermission(
					typePermissions.employee_read,
					parametersCheckPermission,
				);
				const contactDelete = checkPermission(
					typePermissions.contact_delete,
					parametersCheckPermission,
				);
				const contactUpdate = checkPermission(
					typePermissions.contact_update,
					parametersCheckPermission,
				);
				const contactCreate = checkPermission(
					typePermissions.contact_create,
					parametersCheckPermission,
				);

				const checkStart = checkPermission(typePermissions.check_start, parametersCheckPermission);

				const isEditContacts = employeeRead && (contactDelete || contactUpdate || contactCreate);
				const isCheck = isUpdate && (isZeroReportCreate || isCheckRequestCreate);
				const permissions: CheckPermissions = {
					isCreateCheckRequest: isCheckRequestCreate,
					isZeroReportCreate,
					isStartCheck: checkStart,
				};

				return (
					<div className={commonTableStyles['column-action']}>
						{isRead && (
							<TableIconButton
								onClick={() => {
									openProfile(employee.id);
								}}
								Icon={FiUser}
								TooltipMessage='Просмотреть профиль'
							/>
						)}
						{isUpdate && isRead && (
							<TableIconButton
								onClick={() => {
									openEditProfile(employee.id);
								}}
								Icon={LiaUserEditSolid}
								TooltipMessage='Редактировать профиль'
							/>
						)}
						{isEditContacts && (
							<TableIconButton
								onClick={() => {
									openEditContact(
										employee.id,
										{
											isDelete: contactDelete,
											isUpdate: contactUpdate,
											isCreate: contactCreate,
										},
									);
								}}
								Icon={FiEdit2}
								TooltipMessage='Редактировать контакты'
							/>
						)}
						{isCheck && (
							<TableIconButton
								onClick={() => {
									openCreateCheck(employee.id, permissions);
								}}
								Icon={FiSearch}
								TooltipMessage='Создать заявку на проверку'
							/>
						)}
					</div>
				);
			},
		},
	], []);

	return (
		<>
			<Table
				columns={columns}
				dataGridProps={{
					dataSource: value,
					allowColumnResizing: true,
				}}
			/>
		</>
	);
};

export default SubordinateEmployeeTable;
