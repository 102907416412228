import React from 'react';
import {useAppSelector} from '../../../redux/redux-hooks';
import PersonBasicInf from './Person/PersonBasicInf';
import {blocksIds} from '../../../report/reportModelEnum';
import AlertMessage from '../../UiKit/Message/AlertMessage/AlertMessage';
import {BsPersonFillCheck} from 'react-icons/bs';
import styles from './BasicInfo.module.css';
import PhoneBasicInfo from './Phone/PhoneBasicInfo';
import AddressBasicInfo from './Address/AddressBasicInfo';
import EmailBasicInfo from './Email/EmailBasicInfo';
import PassportRFBasicInfo from './PassportRF/PassportRFBasicInfo';

export const BasicInfo = () => {
	const mainInf = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.mainInf);
	const mainAlerts = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.mainAlerts);

	const mainBlock = () => {
		switch (mainInf?.entity_type) {
			case 'phone':
				return <PhoneBasicInfo phone={mainInf.entity_data} />;
			case 'email':
				return <EmailBasicInfo email={mainInf.entity_data} />;
			case 'address':
				return <AddressBasicInfo address={mainInf.entity_data} />;
			case 'passport_rf':
				return <PassportRFBasicInfo passport={mainInf.entity_data} />;
			case 'car':
				return undefined;
			case 'person':
				return <PersonBasicInf person={mainInf.entity_data} />;
			default:
				return undefined;
		}
	};

	return (
		<div data-group='report-block' id={blocksIds.basicsInfo}>
			<div className={styles.content}>
				<div className={styles.header}>
					<div className={styles.title}>
						<BsPersonFillCheck />
						<span>Основная информация</span>
					</div>
					<AlertMessage alerts={mainAlerts} />
				</div>
				{mainBlock()}
			</div>
		</div>
	);
};
