import React from 'react';
import styles from '../../../ReportCard.module.css';
import {type CheckDocuments} from '../../../../../../../../report/ReportModel';

type PassportCardV1Props = {
	passport: CheckDocuments;
};

export const PassportCardV1: React.FC<PassportCardV1Props> = ({
	passport,
}) => (
	<div className={styles.card}>
		<div className={styles.header}>
			<span>Паспорт</span>
		</div>
		<div className={styles.content}>
			<div className={styles.info}>
				<span className={styles.document}>
					{passport.passport}
				</span>
			</div>
		</div>
	</div>
);
