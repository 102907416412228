import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {postPerson152fzDocumentsAction} from '../../action/person';

const initialState: {
	responseStatus: ResponseStatus;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
};

const postPerson152fzDocumentsSlice = createSlice({
	name: 'person/postPerson152fzDocuments',
	initialState,
	reducers: {
		postPerson152fzDocumentsResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postPerson152fzDocumentsAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postPerson152fzDocumentsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(postPerson152fzDocumentsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.requestId = action.meta.requestId;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {postPerson152fzDocumentsResetState} = postPerson152fzDocumentsSlice.actions;
export default postPerson152fzDocumentsSlice.reducer;
