import axios, {type AxiosResponse} from 'axios';
import {
	type PostDepartmentRequest,
	type PostDepartmentResponse,
	type GetDepartmentPositionsRequest,
	type GetDepartmentPositionsResponse,
	type GetDepartmentRequest,
	type GetDepartmentResponse,
	type GetDepartmentSubordinateResponse,
	type PostDepartmentPositionsRequest,
	type PostDepartmentPositionsResponse,
	type PutDepartmentPositionsRequest,
	type PutDepartmentPositionsResponse,
	type PutDepartmentRequest,
	type PutDepartmentResponse,
	type GetDepartmentEmployeesResponse,
	type GetDepartmentEmployeesRequest,
	type GetPositionRequest,
	type GetPositionResponse,
	type DeleteDepartmentRequest,
	type DeleteDepartmentResponse,
} from '../models/IDepartment';
import {getToken} from '../utils/cookies/token/getToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/department';

// Получение подчиненных департаментов
export async function getDepartmentSubordinate(): Promise<
AxiosResponse<GetDepartmentSubordinateResponse>
> {
	return axios.get(`${domain}${urlPrefix}/subordinate`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение департамента
export async function getDepartment(
	data: GetDepartmentRequest,
): Promise<AxiosResponse<GetDepartmentResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.departmentId}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Добавление департамента
export async function postDepartment(
	data: PostDepartmentRequest,
): Promise<AxiosResponse<PostDepartmentResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение должностей департамента
export async function getDepartmentPositions(
	data: GetDepartmentPositionsRequest,
): Promise<AxiosResponse<GetDepartmentPositionsResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.departmentId}/positions`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение должности
export async function getPosition(
	data: GetPositionRequest,
): Promise<AxiosResponse<GetPositionResponse>> {
	return axios.get(`${domain}${urlPrefix}/positions/${data.position_id}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Добавление должности в департамент
export async function postDepartmentPositions(
	data: PostDepartmentPositionsRequest,
): Promise<AxiosResponse<PostDepartmentPositionsResponse>> {
	return axios.post(
		`${domain}${urlPrefix}/${data.department_id}/positions`,
		data.body,
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

// Обновление должности в департаменте
export async function putDepartmentPositions(
	data: PutDepartmentPositionsRequest,
): Promise<AxiosResponse<PutDepartmentPositionsResponse>> {
	return axios.put(
		`${domain}${urlPrefix}/positions/${data.position_id}`,
		data.body,
		{
			headers: {Authorization: `Bearer ${getToken().token}`},
		},
	);
}

// Обновление информации о департаменте
export async function putDepartment(
	data: PutDepartmentRequest,
): Promise<AxiosResponse<PutDepartmentResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.department_id}`, data.body, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Получение сотрудников департамента
export async function getDepartmentEmployees(
	data: GetDepartmentEmployeesRequest,
): Promise<AxiosResponse<GetDepartmentEmployeesResponse>> {
	return axios.get(`${domain}${urlPrefix}/${data.departmentId}/employees`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Удаление департамента
export async function deleteDepartment(
	data: DeleteDepartmentRequest,
): Promise<AxiosResponse<DeleteDepartmentResponse>> {
	return axios.delete(`${domain}${urlPrefix}/${data.departmentId}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
		data: data.body,
	});
}
