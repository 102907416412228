import React, {useCallback} from 'react';
import {CheckBox} from 'devextreme-react';
import {type CheckBoxTypes} from 'devextreme-react/check-box';
import styles from './CheckBoxDevEx.module.css';

type CheckBoxDevExProps = {
	isChecked: boolean;
	setIsChecked?: React.Dispatch<React.SetStateAction<boolean>>;
	text?: string;
};

export const CheckBoxDevEx: React.FC<CheckBoxDevExProps> = ({isChecked, setIsChecked, text}) => {
	const onValueChanged = useCallback((e: CheckBoxTypes.ValueChangedEvent) => {
		if (setIsChecked) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			setIsChecked(e.value);
		}
	}, []);

	return (
		<div>
			<CheckBox
				value={isChecked}
				onValueChanged={onValueChanged}
				text={text}
				className={styles.checkbox}
			/>
		</div>
	);
};
