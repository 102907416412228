import {postInteractiveScreenGo} from '../../../api/interactive';
import {type PostInteractiveScreenGoResponse, type PostInteractiveScreenGoRequest} from '../../../models/IInteractive';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postInteractiveScreenGoAction = createActionTemplateAxios<
PostInteractiveScreenGoResponse,
PostInteractiveScreenGoRequest
>('Interactive/postInteractiveScreenGo/action', postInteractiveScreenGo, 'userToken');

export default postInteractiveScreenGoAction;
