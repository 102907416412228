import {type ResponseStatus} from '../../../models/IAuth';
import {createSlice} from '@reduxjs/toolkit';
import {type GetStatisticsResponse} from '../../../models/IStatistics';
import {getStatisticsHomeAction} from '../../action/statistics';
import {DateTime} from 'luxon';

const initialState: {
	responseStatus: ResponseStatus;
	response: GetStatisticsResponse | undefined;
	maxCandidatePerMonth: number;
} = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
	},
	response: undefined,
	maxCandidatePerMonth: 0,
};

const getStatistics = (response: GetStatisticsResponse) => {
	response.candidate_per_month = response.candidate_per_month.map(item => ({
		...item,
		month: DateTime.fromISO(item.month).toLocaleString({
			year: 'numeric',
			month: 'long',
		}),
	})).reverse();
	response.checks_per_day = response.checks_per_day.map(item => ({
		...item,
		day: DateTime.fromISO(item.day).toLocaleString(DateTime.DATE_FULL),
	})).reverse();

	return response;
};

const getMaxCandidatePerMonth = (response: GetStatisticsResponse) => Math.max(...response.candidate_per_month.map(item => item.count));

const getStatisticsHomeSlice = createSlice({
	name: 'statistics/getStatisticsHome/slice',
	initialState,
	reducers: {
		getStatisticsHomeResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getStatisticsHomeAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getStatisticsHomeAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = getStatistics(action.payload);
				state.maxCandidatePerMonth = getMaxCandidatePerMonth(action.payload);
			})
			.addCase(getStatisticsHomeAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
			});
	},
});

export const {getStatisticsHomeResetState} = getStatisticsHomeSlice.actions;
export default getStatisticsHomeSlice.reducer;
