import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {
	type PutChallengerRequest,
	type PutChallengerResponse,
} from '../../../models/IChallengers';
import {putChallenger} from '../../../api/challengersApi';

const putChallengerAction = createActionTemplateAxios<
PutChallengerResponse,
PutChallengerRequest
>('challengers/putChallengers', putChallenger, 'userToken');

export default putChallengerAction;
