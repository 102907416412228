import React, {useState} from 'react';
import styles from './PersonBirthPlace.module.css';
import stylesBasicInfo from '../../BasicInfo.module.css';
import {useAppSelector} from '../../../../../redux/redux-hooks';
import {MdOutlinePlace} from 'react-icons/md';
import {ModalCheckAddresses, ModalConfirmationAddress} from '../../../../Modal/Report';
import {CopyToClipboardButton} from '../../../../UiKit/Button';
import {SelectCheckButton} from '../../../../UiKit/Button/ReportButtons';

const PersonBirthPlace = () => {
	const [visibleModalCheckAddress, setVisibleModalCheckAddress] = useState(false);
	const [visibleModalSubCheckFromAddress, setVisibleModalSubCheckFromAddress] = useState(false);
	const birth_place = useAppSelector(state => state.getCheckReportById.processedResponse?.report_data.birth_place);

	const openModalCheckAddresses = () => {
		setVisibleModalCheckAddress(true);
	};

	const openModalSubCheckFromAddress = () => {
		setVisibleModalSubCheckFromAddress(true);
	};

	const address = birth_place?.data[0]?.item;
	const containerId = birth_place?.data[0]?.containerId;

	if (address?.result) {
		return (
			<>
				<div className={stylesBasicInfo.info}>
					<MdOutlinePlace className={stylesBasicInfo.icon} />
					<span className={stylesBasicInfo.abbreviation}>
							Место рождения:
					</span>
				</div>
				<div>
					<div className={styles['list-item-content']}>
						<span
							onClick={openModalCheckAddresses}
							className={`${stylesBasicInfo.result} ${stylesBasicInfo.click}`}
						>
							{address.result}
						</span>
						<div className={styles['list-item-actions']}>
							<CopyToClipboardButton
								text={address.result}
							/>
							<SelectCheckButton
								openSubCheck={openModalSubCheckFromAddress}
								containerId={containerId}
							/>
						</div>
					</div>
					<ModalCheckAddresses
						visible={visibleModalCheckAddress}
						setVisible={setVisibleModalCheckAddress}
						address={address}
						setVisibleSubModal={setVisibleModalSubCheckFromAddress}
						containerId={containerId}
					/>
					<ModalConfirmationAddress
						visible={visibleModalSubCheckFromAddress}
						setVisible={setVisibleModalSubCheckFromAddress}
						address={address}
						containerId={containerId}
					/>
				</div>
			</>
		);
	}

	return null;
};

export default PersonBirthPlace;
