import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {postDepartmentPositionsAction} from '../../action/department';
import {type PostDepartmentPositionsResponse} from '../../../models/IDepartment';

type PostDepartmentPositionsState = {
	responseStatus: ResponseStatus;
	response: PostDepartmentPositionsResponse | undefined;
};

const initialState: PostDepartmentPositionsState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postDepartmentPositionsSlice = createSlice({
	name: 'department/postDepartmentPositions',
	initialState,
	reducers: {
		postDepartmentPositionsResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(postDepartmentPositionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postDepartmentPositionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postDepartmentPositionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postDepartmentPositionsResetState} = postDepartmentPositionsSlice.actions;
export default postDepartmentPositionsSlice.reducer;
