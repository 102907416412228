import React, {useEffect, useState} from 'react';
import styles from './AddEmployeesForm.module.css';
import {type Permission, type Department} from '../../models/Schemas';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import {postEmployeesAction} from '../../redux/action/employees';
import Loader from '../Loader/Loader';
import {ErrorMessage} from '../UiKit/Message';
import {ButtonInput, InputDate, InputName} from '../UiKit/Inputs';
import {getGroupPermission} from '../../utils';
import {typePermissions} from '../../models/Enum';
import {getDepartmentsAction} from '../../redux/action/organization';
import {getDepartmentSubordinateAction} from '../../redux/action/department';
import {notifyMessage} from '../UiKit/Toast/notifyMessage';
import SelectDepartment from '../CardRegister/SelectDepartment/SelectDepartment';
import {ButtonTemplate} from '../UiKit/Button';

const AddEmployeesForm = () => {
	const [name, setName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [dateBirth, setDateBirth] = useState<string | undefined>(undefined);

	const [showValidation, setShowValidation] = useState(false);
	const [isValidName, setIsValidName] = useState<boolean>(false);
	const [isValidMiddleName, setIsValidMiddleName] = useState<boolean>(false);
	const [isValidLastName, setIsValidLastName] = useState<boolean>(false);
	const [isValidDateBirth, setIsValidDateBirth] = useState<boolean>(false);
	const [selectedDepartmentIsValid, setSelectedDepartmentIsValid] = useState<boolean>(false);

	const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>(undefined);
	const [departmentOptions, setDepartmentOptions] = useState<Department[] | undefined>(undefined);
	const [disableSelect, setDisableSelect] = useState(true);
	const [employeeCreate, setEmployeeCreate] = useState<Permission | undefined>(undefined);

	const departmentsAll = useAppSelector(state => state.departments);
	const getDepartmentsSubordinate = useAppSelector(state => state.getDepartmentsSubordinate);
	const getPermissions = useAppSelector(state => state.getPermissions);
	const myDepartment = useAppSelector(state => state.getUser.user?.department);
	const navigationContext = useAppSelector(state => state.navigationContext);

	const postEmployees = useAppSelector(state => state.postEmployees);

	const dispatch = useAppDispatch();

	const handleSubmit = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (selectedDepartment && selectedDepartmentIsValid && isValidName && isValidMiddleName && isValidLastName && isValidDateBirth) {
			void dispatch(
				postEmployeesAction({
					body: {
						first_name: name,
						last_name: lastName,
						middle_name: middleName,
						department_id: selectedDepartment.id,
						birth_date: dateBirth,
					},
				}),
			);
		} else {
			notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
		}
	};

	useEffect(() => {
		if (postEmployees.responseStatus.isCompleted) {
			setName('');
			setMiddleName('');
			setLastName('');
			setSelectedDepartment(undefined);
			setDateBirth(undefined);
			setShowValidation(false);
			notifyMessage('success', 'Профиль сотрудника создан успешно');
		}
	}, [postEmployees.responseStatus.isLoading]);

	// Проверка прав на отдел
	useEffect(() => {
		if (getPermissions.responseStatus.isCompleted) {
			const permission = getGroupPermission(typePermissions.employee_create, getPermissions.groupPermissions);
			setEmployeeCreate(permission);
			if (permission) {
				if (permission.permission_zone === 'all') {
					void dispatch(getDepartmentsAction());
				} else if (permission.permission_zone === 'only_my_department') {
					if (myDepartment) {
						setDepartmentOptions([myDepartment]);
						setSelectedDepartment(myDepartment);
					}

					setDisableSelect(true);
				} else if (permission.permission_zone === 'only_owner') {
					void dispatch(getDepartmentSubordinateAction());
				}
			}
		}
	}, [getPermissions.responseStatus.isLoading]);

	// Получение всех отделов
	useEffect(() => {
		if (departmentsAll.isCompleted
			&& employeeCreate?.permission_zone === 'all') {
			setDepartmentOptions(departmentsAll.departments?.data);
			setDisableSelect(false);
		}
	}, [departmentsAll.isLoading, employeeCreate]);

	// Получение подчинённых отделов
	useEffect(() => {
		if (getDepartmentsSubordinate.responseStatus.isCompleted
			&& employeeCreate?.permission_zone === 'only_owner') {
			setDepartmentOptions(getDepartmentsSubordinate.response);
			setDisableSelect(false);
		}
	}, [getDepartmentsSubordinate.responseStatus.isLoading, employeeCreate]);

	useEffect(() => {
		if (showValidation) {
			setShowValidation(false);
		}
	}, []);

	useEffect(() => {
		setSelectedDepartment(navigationContext.department);
		setSelectedDepartmentIsValid(true);
	}, [navigationContext.department]);

	return (
		<div className={styles.content}>
			<div className={styles.employees}>
				<div className={styles['input-group']}>
					<InputName
						value={lastName}
						setValue={setLastName}
						inputStyles={{
							placeholder: 'Фамилия',
						}}
						inputValidation={{
							isValid: isValidLastName,
							setIsValid: setIsValidLastName,
							lettersOnly: true,
							isRequired: true,
							minLength: 2,
							showValidation,
						}}
						parts={['SURNAME']}
						title='Фамилия'
					/>
					<InputName
						value={name}
						setValue={setName}
						inputStyles={{
							placeholder: 'Имя',
						}}
						inputValidation={{
							isValid: isValidName,
							setIsValid: setIsValidName,
							lettersOnly: true,
							isRequired: true,
							minLength: 2,
							showValidation,
						}}
						parts={['NAME']}
						title='Имя'
					/>
					<InputName
						value={middleName}
						setValue={setMiddleName}
						inputStyles={{
							placeholder: 'Отчество',
						}}
						inputValidation={{
							isValid: isValidMiddleName,
							setIsValid: setIsValidMiddleName,
							lettersOnly: true,
							minLength: 2,
						}}
						parts={['PATRONYMIC']}
						title='Отчество'
					/>

					<InputDate
						value={dateBirth}
						setValueString={setDateBirth}
						inputStyles={{
							placeholder: 'Дата рождения',
						}}
						inputValidation={{
							isValid: isValidDateBirth,
							setIsValid: setIsValidDateBirth,
							showValidation,
						}}
						title='Дата рождения'
					/>
					<ButtonInput
						value={selectedDepartment?.name}
						disabled
						title='отдел'
						isRequired
						inputStyles={{
							placeholder: 'Отдел',
						}}
					/>
				</div>
			</div>
			<ErrorMessage
				message={postEmployees.responseStatus.errorMessage}
				isError={postEmployees.responseStatus.isError}
			/>
			<div className={styles['btn-group']}>
				<ButtonTemplate onClick={handleSubmit} color='primary' text='Добавить' />
			</div>
			<Loader
				text='Добавление сотрудников...'
				isFloating
				isLoading={postEmployees.responseStatus.isLoading}
			/>
		</div>
	);
};

export default AddEmployeesForm;
