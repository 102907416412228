import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getPerson152fzDocuments} from '../../../api/Person/person152fzApi';
import {type GetPerson152fzDocumentsResponse, type GetPerson152fzDocumentsRequest} from '../../../models/Person/PersonDocuments/IPersonDocuments';

const getPerson152fzDocumentsAction = createActionTemplateAxios<
GetPerson152fzDocumentsResponse,
GetPerson152fzDocumentsRequest
>(
	'person/getPerson152fzDocuments/action',
	getPerson152fzDocuments,
	'guestToken',
);

export default getPerson152fzDocumentsAction;
