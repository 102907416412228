import React, {type ReactNode, useState} from 'react';
import styles from './Details.module.css';
import {IoChevronUpOutline} from 'react-icons/io5';
import {type IconType} from 'react-icons';

type DetailsProps = {
	isOpened?: boolean;
	summaryWrapper?: (summary: ReactNode) => ReactNode;
	summaryText?: string;
	openedSummaryText?: string;
	ToggleIcon?: IconType;
	children: ReactNode;
	color?: 'green' | 'lavender';
};

const Details: React.FC<DetailsProps> = ({
	isOpened = false,
	summaryWrapper,
	summaryText,
	openedSummaryText = summaryText,
	ToggleIcon = IoChevronUpOutline,
	children,
	color = 'green',
}) => {
	const [isOpen, setIsOpen] = useState(isOpened);

	const summary = () => (
		<div className={styles['toggle-container']} onClick={onClickDetails}>
			<span>{isOpen ? openedSummaryText : summaryText}</span>
			<ToggleIcon className={styles.toggle} />
		</div>
	);

	const onClickDetails = () => {
		setIsOpen(!isOpen);
	};

	return (
		<details
			open={isOpen}
			className={styles.criteria}
			onClick={e => {
				e.preventDefault();
			}}
		>
			<summary className={styles[color]}>
				{summaryWrapper ? summaryWrapper(summary()) : summary()}
			</summary>
			{children}
		</details>
	);
};

export default Details;
