import {type PostDepartmentRequest, type PostDepartmentResponse} from '../../../models/IDepartment';
import {postDepartment} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {addDepartmentSubordinate} from '../../slice/department';

const postDepartmentAction = createActionTemplateAxios<
PostDepartmentResponse,
PostDepartmentRequest>(
	'department/postDepartment/action',
	postDepartment,
	'userToken',
	addDepartmentSubordinate,
);

export default postDepartmentAction;
