import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutNotificationsByIdResponse} from '../../../models/INotifications';
import {putNotificationsByIdAction} from '../../action/notifications';

type PutNotificationsByIdSliceState = {
	responseStatus: ResponseStatus;
	response: PutNotificationsByIdResponse | undefined;
	processedResponse: PutNotificationsByIdResponse | undefined;
};

const initialState: PutNotificationsByIdSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
	processedResponse: undefined,
};

const putNotificationsByIdSlice = createSlice({
	name: 'notifications/getNotifications',
	initialState,
	reducers: {
		putNotificationsByIdResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(putNotificationsByIdAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putNotificationsByIdAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putNotificationsByIdAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
                    = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {putNotificationsByIdResetState} = putNotificationsByIdSlice.actions;

export default putNotificationsByIdSlice.reducer;
