import {type GetDepartmentRequest, type GetDepartmentResponse} from '../../../models/IDepartment';
import {getDepartment} from '../../../api/departmentApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getDepartmentAction = createActionTemplateAxios<
GetDepartmentResponse,
GetDepartmentRequest>(
	'department/getDepartment/action',
	getDepartment,
	'userToken',
);

export default getDepartmentAction;
