import {Cookies} from 'react-cookie';
import {type Token} from '../../../models/IAuth';
import cookiesKeys from '../cookiesKeys';

export const saveToken = (token: Token) => {
	const cookies = new Cookies();
	const tokenExpiresDate = new Date(token.token_expires);
	const refreshTokenExpiresDate = new Date(token.refresh_token_expires);

	cookies.set(cookiesKeys.userToken, token.token, {
		path: '/',
		expires: tokenExpiresDate,
		sameSite: 'strict',
		secure: true,
	});
	cookies.set(cookiesKeys.userTokenExpires, token.token_expires, {
		path: '/',
		expires: tokenExpiresDate,
		sameSite: 'strict',
		secure: true,
	});
	cookies.set(cookiesKeys.userRefreshToken, token.refresh_token, {
		path: '/',
		expires: refreshTokenExpiresDate,
		sameSite: 'strict',
		secure: true,
	});
	cookies.set(cookiesKeys.userRefreshTokenExpires, token.refresh_token_expires, {
		path: '/',
		expires: refreshTokenExpiresDate,
		sameSite: 'strict',
		secure: true,
	});
};
