/* eslint-disable @typescript-eslint/naming-convention */
import {type PostInteractiveGoResponse} from '../../../../models/IInteractive';

export const mockNameScreen: PostInteractiveGoResponse = {
	interactive_data: {
		slug: 'person_check',
		total_screens: 5,
	},
	current_screen: {
		screen_id: 'name_screen',
		settings: {
			country_required: true,
		},
		default_values: {
			country: '15',
		},
		switch: {
			back: {
				fields: {},
			},
			next: {
				fields: {
					first_name: {
						type: 'string',
						value: 'Иван',
						required: true,
					},
					last_name: {
						type: 'string',
						required: true,
					},
					middle_name: {
						type: 'string',
						required: false,
					},
					birth_date: {
						type: 'string',
						required: true,
					},
					place_of_birth: {
						type: 'string',
						required: true,
					},
					registration_address: {
						type: 'string',
						required: true,
					},
					gender: {
						type: 'string',
						required: true,
					},
					country: {
						type: 'enum',
						value: [],
						required: false,
					},
				},
			},
		},
		is_first: false,
		is_last: false,
		data: {
			countries: {
				1: 'Россия',
				2: 'Беларусь',
				3: 'Украина',
				4: 'США',
				5: 'Китай',
				6: 'Индия',
				7: 'Бразилия',
				8: 'Австралия',
				9: 'Канада',
				10: 'Германия',
				11: 'Франция',
				12: 'Италия',
				13: 'Япония',
				14: 'Мексика',
				15: 'Южная Корея',
				16: 'Великобритания',
				17: 'Индонезия',
			},
			dadata_domain: '',
			link: '',
			image: '',
		},
	},
};

export const mockAgeScreen: PostInteractiveGoResponse = {
	interactive_data: {
		slug: 'person_check',
		total_screens: 5,
	},
	current_screen: {
		screen_id: 'second_screen',
		settings: {
			country_required: true,
		},
		default_values: {
		},
		switch: {
			next: {
				fields: {
				},
			},
			back: {
				fields: {},
			},
		},
		is_first: false,
		is_last: false,
		data: {
		},
	},
};

export const mockPhone: PostInteractiveGoResponse = {
	interactive_data: {
		slug: 'person_check',
		total_screens: 5,
	},
	current_screen: {
		screen_id: 'phone_screen',
		settings: {
			country_required: true,
		},
		default_values: {
			phone: '79999999999',
		},
		switch: {
			next: {
				fields: {
					phone: {
						type: 'string',
						value: '',
						required: true,
					},
				},
			},
			back: {
				fields: {},
			},
		},
		is_first: false,
		is_last: false,
		data: {
		},
	},
};

export const mockEmail: PostInteractiveGoResponse = {
	interactive_data: {
		slug: 'person_check',
		total_screens: 5,
	},
	current_screen: {
		screen_id: 'email_screen',
		settings: {
			country_required: true,
		},
		default_values: {
			email: 'm@m.ru',
		},
		switch: {
			next: {
				fields: {
					email: {
						type: 'string',
						value: '',
						required: true,
					},
				},
			},
			back: {
				fields: {},
			},
		},
		is_first: false,
		is_last: false,
		data: {
		},
	},
};

export const mockAddress: PostInteractiveGoResponse = {
	interactive_data: {
		slug: 'person_check',
		total_screens: 5,
	},
	current_screen: {
		screen_id: 'address_screen',
		settings: {
			country_required: true,
		},
		default_values: {
			address: '',
		},
		switch: {
			next: {
				fields: {
					address: {
						type: 'string',
						value: '',
						required: true,
					},
				},
			},
			back: {
				fields: {},
			},
		},
		is_first: false,
		is_last: false,
		data: {
		},
	},
};
