import React from 'react';
import {InputAutocomplete, type InputAutocompleteCommonProps} from '../..';
import {useAppDispatch, useAppSelector} from '../../../../../redux/redux-hooks';
import {getAddressFillAction} from '../../../../../redux/action/dadata';

type InputAddressProps = {
	value: string | undefined;
	setValue: (value: string) => void;
} & InputAutocompleteCommonProps;

const InputAddress: React.FC<InputAddressProps> = ({
	value,
	setValue,
	...props
}) => {
	const getAddressFill = useAppSelector(state => state.getAddressFill);

	const dispatch = useAppDispatch();

	const requestAutocompleteData = () => {
		if (value && value.length > 1) {
			return dispatch(getAddressFillAction({value})).requestId;
		}
	};

	return (
		<InputAutocomplete
			value={value}
			setValue={setValue}
			requestAutocompleteData={requestAutocompleteData}
			responseAutocompleteData={getAddressFill.response.suggestions}
			responseStatus={getAddressFill.responseStatus}
			{...props}
		/>
	);
};

export default InputAddress;
