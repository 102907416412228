import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {
	type postNotificationsRequest,
	type postNotificationsResponse,
	type GetNotificationsResponse,
	type PutNotificationsByIdRequest,
	type PutNotificationsByIdResponse,
} from '../models/INotifications';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/notifications';

// Вызов уведомлений
export async function getNotifications(): Promise<AxiosResponse<GetNotificationsResponse>> {
	return axios.get(`${domain}${urlPrefix}`, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Прочтение уведомления
export async function putNotificationsById(data: PutNotificationsByIdRequest): Promise<AxiosResponse<PutNotificationsByIdResponse>> {
	return axios.put(`${domain}${urlPrefix}/${data.id}`, undefined, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}

// Создание уведомления
export async function postNotifications(data: postNotificationsRequest): Promise<AxiosResponse<postNotificationsResponse>> {
	return axios.post(`${domain}${urlPrefix}`, data, {
		headers: {Authorization: `Bearer ${getToken().token}`},
	});
}
