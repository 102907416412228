import React from 'react';
import {ButtonTemplate, type ButtonTemplateProps} from '..';

type TableIconButtonProps = ButtonTemplateProps;

const TableIconButton: React.FC<TableIconButtonProps> = ({
	styleType = 'icon',
	color = 'asphalt-transparent',
	iconStyleType = 'small',
	TooltipPosition = 'left',
	...props}) => (
	<ButtonTemplate
		color={color}
		styleType={styleType}
		iconStyleType={iconStyleType}
		TooltipPosition={TooltipPosition}
		{...props}
	/>
);

export default TableIconButton;
