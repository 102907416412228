import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {postEmployees} from '../../../api/employeesApi';
import {
	type PostEmployeesRequest,
	type PostEmployeesResponse,
} from '../../../models/IEmployees';

const postEmployeesAction = createActionTemplateAxios<
PostEmployeesResponse,
PostEmployeesRequest
>('employees/postEmployees/action', postEmployees, 'userToken');

export default postEmployeesAction;
