import React, {useState} from 'react';
import styles from './CardSettings.module.css';
import {navSettings, type NavSettings} from './NavSettings';

export const CardSettings = () => {
	const [activeItem, setActiveItem] = useState<NavSettings | undefined>(
		navSettings[0],
	);

	const handleItemClick = (navSettings: NavSettings) => {
		setActiveItem(navSettings);
	};

	return (
		<div>
			<ul className={styles.nav}>
				{navSettings?.map((item: NavSettings) => (
					<li
						key={item.name}
						className={`${styles['nav-item']} ${item === activeItem ? styles.active : ''}`}
						onClick={() => {
							handleItemClick(item);
						}}
					>
						{item.name}
					</li>
				))}
			</ul>
			{activeItem && React.createElement(activeItem.component)}
		</div>
	);
};
