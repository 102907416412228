import React, {useState, useEffect} from 'react';
import styles from './CardRegister.module.css';
import FieldsEmployee from './FieldsEmployee';
import {useAppDispatch, useAppSelector} from '../../redux/redux-hooks';
import SelectDepartment from './SelectDepartment/SelectDepartment';
import {putUserAction} from '../../redux/action/users';
import {isCompletedReset} from '../../redux/slice/users/putUserSlice';
import {type Department} from '../../models/Schemas';
import {ErrorMessage} from '../UiKit/Message';
import {ButtonTemplate} from '../UiKit/Button';
import Loader from '../Loader/Loader';

type CardRegisterProps = {
	nextComponent: () => void;
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	surname: string;
	setSurname: React.Dispatch<React.SetStateAction<string>>;
	patronymic: string;
	setPatronymic: React.Dispatch<React.SetStateAction<string>>;
	dateBirth: string | undefined;
	setDateBirth: (Value: string | undefined) => void;
	department: Department | undefined;
	setDepartment: React.Dispatch<React.SetStateAction<Department | undefined>>;
};

export const CardRegister: React.FC<CardRegisterProps> = ({
	nextComponent,
	name,
	setName,
	surname,
	setSurname,
	patronymic,
	setPatronymic,
	dateBirth,
	setDateBirth,
	department,
	setDepartment,
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isValidName, setIsValidName] = useState(false);
	const [isValidSurname, setIsValidSurname] = useState(false);
	const [isValidPatronymic, setIsValidPatronymic] = useState(false);
	const [isValidData, setIsValidData] = useState(false);

	const {isError, isCompleted, isLoading, errorMessage, organization}
    = useAppSelector(state => state.organization);

	const user = useAppSelector(state => state.getUser);
	const updateUser = useAppSelector(state => state.putUser);

	// Выполнение запроса при открытии страницы
	const dispatch = useAppDispatch();

	const handlerDataOnPage = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (isCompleted) {
			if (dateBirth && isValidName && isValidSurname && isValidPatronymic && isValidData && user.user) {
				void dispatch(putUserAction({
					user: {
						first_name: name,
						middle_name: patronymic,
						last_name: surname,
						birth_date: dateBirth,
						department_id: department?.id,
					},
				}));
			}
		}
	};

	useEffect(() => {
		dispatch(isCompletedReset());

		if (showValidation) {
			setShowValidation(false);
		}
	}, []);

	useEffect(() => {
		if (updateUser.isCompleted) {
			dispatch(isCompletedReset());
			nextComponent();
		}
	}, [updateUser.isLoading]);

	return (
		<div className={styles.card}>
			<h2>Регистрация</h2>

			<FieldsEmployee
				name={name}
				surname={surname}
				patronymic={patronymic}
				dateBirth={dateBirth}
				isValidDate={isValidData}
				isValidName={isValidName}
				isValidSurname={isValidSurname}
				isValidPatronymic={isValidPatronymic}
				showValidation={showValidation}
				setName={setName}
				setPatronymic={setPatronymic}
				setSurname={setSurname}
				setIsValidName={setIsValidName}
				setIsValidSurname={setIsValidSurname}
				setIsValidPatronymic={setIsValidPatronymic}
				setDateBirth={setDateBirth}
				setIsValidDate={setIsValidData}
			/>

			<div className={styles.department}>
				<h4>Выберите отдел</h4>
				<SelectDepartment
					selectedItem={department}
					setSelectedItem={setDepartment}
					useDepartments
				/>
			</div>

			<ButtonTemplate
				onClick={handlerDataOnPage}
				styleType={'long'}
				color={'primary'}
				text='Далее'
			/>

			<Loader text='Получение данных о пользователе...' isLoading={user.isLoading || isLoading} isFloating />
			<Loader text='Обновление данных о пользователе...' isLoading={updateUser.isLoading} isFloating/>
			<ErrorMessage isError={isError} message={errorMessage} />
			<ErrorMessage isError={user.isError} message={user.errorMessage} />
			<ErrorMessage isError={updateUser.isError} message={updateUser.errorMessage} />
		</div>
	);
};
