import React, {useState, useEffect, useRef, lazy, Suspense} from 'react';
import styles from '../../UiKit/Inputs/Template/InputDevextreme.module.css';
import {type Department} from '../../../models/Schemas';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {getDepartmentsAction} from '../../../redux/action/organization';
import {ButtonInput} from '../../UiKit/Inputs';
import ModalSelectDepartment from '../../Modal/ModalSelectDepartment/ModalSelectDepartment';
import Loader from '../../Loader/Loader';
const ModalAddDepartment = lazy(async () => import('../../Modal/ModalAddDepartment/ModalAddDepartment'));

type SelectDepartmentProps = {
	selectedItem?: Department | undefined;
	setSelectedItem?: (value: Department | undefined) => void;
	selectedId?: number | undefined;
	setSelectedId?: (value: number | undefined) => void;
	title?: string;
	titleColor?: 'main' | 'asphalt';
	useDepartments?: boolean;
	departmentOptions?: Department[];
	departmentOptionsIsLoading?: boolean;
	departmentOptionsIsLoadingOnlyModal?: boolean;
	placeholder?: string;
	disableSelect?: boolean;
	isValid?: boolean;
	setSelectedDepartmentIsValid?: (value: boolean) => void;
	showValidation?: boolean;
	isRequired?: boolean;
	styleType?: 'large' | 'medium' | 'small';
	showClear?: boolean;
	visible?: boolean;
	stylingMode?: 'outlined' | 'underlined' | 'filled';
	isOpenModal?: boolean;
	setIsOpenModal?: (visibility: boolean) => void;
	onChangeVisibilityModal?: (visibility: boolean) => void;
	allowAddDepartments?: boolean;
	company_id?: number;
};

const getDefaultExpandedRowKeys = (departments: Department[] | undefined, department: Department | undefined): number[] => {
	if (!departments || !department) {
		return [];
	}

	const rowKeys: number[] = [];
	let currentDepartment: Department | undefined = department;
	while (currentDepartment) {
		const parentId: number | undefined = currentDepartment.parent_id ?? undefined;
		if (parentId) {
			currentDepartment = departments.find(item => item.id === parentId);
			if (currentDepartment) {
				rowKeys.push(currentDepartment.id);
			}
		} else {
			break;
		}
	}

	return rowKeys;
};

const SelectDepartment: React.FC<SelectDepartmentProps> = ({
	selectedItem,
	setSelectedItem,
	selectedId,
	setSelectedId,
	departmentOptions,
	departmentOptionsIsLoading,
	useDepartments = false,
	disableSelect = false,
	setSelectedDepartmentIsValid,
	isRequired,
	isValid,
	showValidation,
	title,
	titleColor = 'asphalt',
	styleType = 'small',
	visible,
	showClear,
	stylingMode,
	isOpenModal,
	departmentOptionsIsLoadingOnlyModal,
	setIsOpenModal,
	onChangeVisibilityModal,
	allowAddDepartments = false,
	company_id,
}) => {
	const [selectedName, setSelectedName] = useState(
		selectedItem?.name
		?? departmentOptions?.find(item => item.id === selectedId)?.name);
	const [selectedRowsKey, setSelectedRowsKey] = useState<number[]>([]);
	const [nodes, setNodes] = useState<Department[]>([]);
	const [visibleAddDepartmentModal, setVisibleAddDepartmentModal] = useState(false);
	const [selectedDepartmentForAdd, setSelectedDepartmentForAdd] = useState<Department>();

	const expectedRequestId = useRef<string | undefined>(undefined);
	const {isLoading, isCompleted, departments, requestId} = useAppSelector(state => state.departments);

	const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState<number[]>([]);
	const [isOpen, setIsOpen] = useState(false);

	const dispatch = useAppDispatch();

	const setVisibleModal = (visible: boolean) => {
		setIsOpen(visible);
		if (setIsOpenModal) {
			setIsOpenModal(visible);
		}
	};

	const openAddDepartmentModal = (department?: Department) => {
		setVisibleAddDepartmentModal(true);
		setSelectedDepartmentForAdd(department);
	};

	useEffect(() => {
		// Запрос списка департаментов
		if (useDepartments) {
			expectedRequestId.current = dispatch(getDepartmentsAction()).requestId;
		}
	}, []);

	useEffect(() => {
		let department: Department | undefined;
		if (selectedId) {
			department = departmentOptions?.find(item => item.id === selectedId);
		} else if (selectedItem) {
			department = selectedItem;
		}

		setSelectedName(department?.name);
		setSelectedRowsKey(department ? [department.id] : []);

		if (!useDepartments) {
			setDefaultExpandedRowKeys(getDefaultExpandedRowKeys(departmentOptions, department) ?? []);
		}
	}, [selectedId, selectedItem, departmentOptions]);

	useEffect(() => {
		if (useDepartments && expectedRequestId.current && expectedRequestId.current === requestId && isCompleted) {
			expectedRequestId.current = undefined;
			setSelectedName(selectedItem?.name);
			setSelectedRowsKey(selectedItem ? [selectedItem.id] : []);
			setDefaultExpandedRowKeys(getDefaultExpandedRowKeys(departments?.data, selectedItem) ?? []);
		}
	}, [isLoading]);

	useEffect(() => {
		if (visible === false) {
			setSelectedName('');
			setSelectedRowsKey([]);
			setDefaultExpandedRowKeys([]);
			expectedRequestId.current = undefined;
		}
	}, [visible]);

	useEffect(() => {
		if (isOpenModal) {
			setIsOpen(isOpenModal);
		}
	}, [isOpenModal]);

	useEffect(() => {
		if (onChangeVisibilityModal) {
			onChangeVisibilityModal(isOpen);
		}
	}, [isOpen]);

	useEffect(() => {
		setSelectedRowsKey(selectedItem
			? [selectedItem.id]
			: selectedId
				? [selectedId]
				: []);
		setNodes((useDepartments ? departments?.data : departmentOptions) ?? []);
		if (useDepartments) {
			setDefaultExpandedRowKeys(departments?.data?.map(item => item.id) ?? []);
		} else {
			setDefaultExpandedRowKeys(departmentOptions?.map(item => item.id) ?? []);
		}
	}, [useDepartments, departments?.data, departmentOptions]);

	return (
		<>
			{title
				&& (
					<span className={`${styles.title} ${styles[styleType]} ${styles[titleColor]}`}>
						{title}
						{isRequired && <span className={styles.required}>*</span>}
					</span>
				)
			}
			<div>
				<ButtonInput
					value={selectedName}
					onClick={() => {
						setIsOpen(true);
						if (setIsOpenModal) {
							setIsOpenModal(true);
						}
					}}
					isLoading={
						departmentOptionsIsLoadingOnlyModal === undefined
							? useDepartments ? isLoading : departmentOptionsIsLoading : false
					}
					inputStyles={{
						placeholder: 'Выберите  отдел',
						stylingMode,
					}}
					titleColor={titleColor}
					disabled={disableSelect}
					isRequired={isRequired}
					isValid={isValid}
					setIsValid={setSelectedDepartmentIsValid}
					showValidation={showValidation}
					visible={visible}
				/>
				<ModalSelectDepartment
					visible={isOpen}
					setVisible={setVisibleModal}
					nodes={nodes}
					parentIdExpr={'parent_id'}
					keyExpr={'id'}
					selectedRowKeys={selectedRowsKey}
					defaultExpandedRowKeys={defaultExpandedRowKeys}
					onRowDblClick={rowData => {
						const department = rowData.data as Department | undefined;

						if (setSelectedItem) {
							setSelectedItem(department);
						}

						if (setSelectedId) {
							setSelectedId(department?.id);
						}

						setIsOpen(false);
					}}
					openAddDepartmentModal={openAddDepartmentModal}
					showClear={showClear}
					onClear={() => {
						setSelectedName(undefined);
						setSelectedRowsKey([]);
						if (setSelectedItem) {
							setSelectedItem(undefined);
						}

						if (setSelectedId) {
							setSelectedId(undefined);
						}
					}}
					isLoading={useDepartments ? isLoading : departmentOptionsIsLoading ?? departmentOptionsIsLoadingOnlyModal}
				/>
				{allowAddDepartments && (
					<Suspense fallback={<Loader isLoading/>}>
						<ModalAddDepartment
							visible={visibleAddDepartmentModal}
							setVisible={setVisibleAddDepartmentModal}
							parentDepartment={selectedDepartmentForAdd}
							company_id={company_id}
							useParentDepartmentOptions
							parentDepartmentOptions={nodes}
						/>
					</Suspense>
				)}
			</div>
		</>
	);
};

export default SelectDepartment;
