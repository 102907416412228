import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {
	type DepartmentPositionsOpened,
	type GetDepartmentPositionsOpenedResponse,
} from '../../../models/IDepartmentPositions';
import {getDepartmentPositionsOpenedAction} from '../../action/department_positions';
import {DateTime} from 'luxon';

export type filtersDepartmentPositionsOpened = {
	positionName: string | undefined;
	openedAt: string[];
	urgency: string | undefined;
};

type filterFieldsOptionsDepartmentPositionsOpened = {
	positionNames: string[];
	departmentNames: string[];
	urgencies: string[];
};

export type GetDepartmentPositionsOpenedProcessedResponseItem = DepartmentPositionsOpened & {
	displayOpenedAt: string;
	countJobs: number;
};

type GetDepartmentPositionsSliceState = {
	responseStatus: ResponseStatus;
	processedResponse: GetDepartmentPositionsOpenedProcessedResponseItem[];
	filteredOptions: filterFieldsOptionsDepartmentPositionsOpened;
	filteredResponse: GetDepartmentPositionsOpenedProcessedResponseItem[];
};

const initialState: GetDepartmentPositionsSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	processedResponse: [],
	filteredResponse: [],
	filteredOptions: {
		positionNames: [],
		departmentNames: [],
		urgencies: [],
	},
};

const getProcessedResponse = (response: GetDepartmentPositionsOpenedResponse) => response.map<GetDepartmentPositionsOpenedProcessedResponseItem>(item => ({
	...item,
	urgency: item.urgency ?? '-',
	displayOpenedAt: DateTime.fromISO(item.opened_at).toRelative() ?? '',
	countJobs: response.filter(jobs => jobs.department_id === item.department_id).length,
}));

const getFiltersOptions = (response: GetDepartmentPositionsOpenedResponse) => {
	const filteredOptions: filterFieldsOptionsDepartmentPositionsOpened = {
		positionNames: [],
		departmentNames: [],
		urgencies: [],
	};

	response.forEach(item => {
		if (!filteredOptions.departmentNames.includes(item.department_name)) {
			filteredOptions.departmentNames.push(item.department_name);
		}

		if (item.urgency && !filteredOptions.urgencies.includes(item.urgency)) {
			filteredOptions.urgencies.push(item.urgency);
		}

		if (!filteredOptions.positionNames.includes(item.position_name)) {
			filteredOptions.positionNames.push(item.position_name);
		}
	});

	filteredOptions.positionNames = filteredOptions.positionNames.sort();
	filteredOptions.departmentNames = filteredOptions.departmentNames.sort();
	filteredOptions.urgencies = filteredOptions.urgencies.sort();

	return filteredOptions;
};

const getDepartmentPositionsOpened = createSlice({
	name: 'departmentPosition/getDepartmentPositionsOpened',
	initialState,
	reducers: {
		getDepartmentPositionsOpenedResetState: () => initialState,
		getDepartmentPositionsOpenedDeleteItem(state, action: PayloadAction<number>) {
			if (state.processedResponse) {
				const index = state.processedResponse.findIndex(item => item.id === action.payload);

				if (index !== -1) {
					state.processedResponse.splice(index, 1);
				}
			}

			if (state.filteredResponse) {
				const index = state.filteredResponse.findIndex(item => item.id === action.payload);

				if (index !== -1) {
					state.filteredResponse.splice(index, 1);
				}
			}
		},
		getDepartmentPositionsOpenedFilteredResponse(state, action: PayloadAction<filtersDepartmentPositionsOpened>) {
			const {positionName, openedAt, urgency} = action.payload;
			const [fistOpenedAt, secondOpenedAt] = openedAt.map(item => item ? new Date(item) : undefined);

			state.filteredResponse = state.processedResponse.filter(item => {
				const itemOpenedAt = new Date(item.opened_at);

				return (
					(!positionName || item.position_name === positionName)
				&&	(!urgency || item.urgency === urgency)
				&&	(!fistOpenedAt || itemOpenedAt >= fistOpenedAt)
				&&	(!secondOpenedAt || itemOpenedAt <= secondOpenedAt)
				);
			});
			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getDepartmentPositionsOpenedAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getDepartmentPositionsOpenedAction.fulfilled, (state, action) => {
				state.processedResponse = getProcessedResponse(action.payload);
				state.filteredResponse = state.processedResponse;
				state.filteredOptions = getFiltersOptions(state.processedResponse);
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
			})
			.addCase(getDepartmentPositionsOpenedAction.rejected, (state, action) => {
				state.processedResponse = [];
				state.filteredOptions = {
					positionNames: [],
					departmentNames: [],
					urgencies: [],
				};
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {
	getDepartmentPositionsOpenedResetState,
	getDepartmentPositionsOpenedDeleteItem,
	getDepartmentPositionsOpenedFilteredResponse,
} = getDepartmentPositionsOpened.actions;
export default getDepartmentPositionsOpened.reducer;
