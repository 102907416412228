import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type GetCandidateResponse} from '../../../models/ICandidates';
import {getCandidateAction} from '../../action/candidates';

type GetCandidateSliceState = {
	responseStatus: ResponseStatus;
	response: GetCandidateResponse | undefined;
};

const initialState: GetCandidateSliceState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const getCandidateSlice = createSlice({
	name: 'candidates/getCandidate',
	initialState,
	reducers: {
		getCandidateResetState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getCandidateAction.pending, (state, action) => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getCandidateAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
				state.responseStatus.requestId = action.meta.requestId;
			})
			.addCase(getCandidateAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
				state.responseStatus.requestId = action.meta.requestId;
			});
	},
});

export const {getCandidateResetState} = getCandidateSlice.actions;
export default getCandidateSlice.reducer;
