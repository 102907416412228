import {
	type getDepartmentsResponse,
} from '../../../models/IOrganization';
import {getDepartments} from '../../../api/organizationApi';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getDepartmentsAction = createActionTemplateAxios<
getDepartmentsResponse,
void>(
	'organization/getDepartments/action',
	getDepartments,
	'userToken',
);

export default getDepartmentsAction;
