import {type HeadersCheckReport} from '../../../../../report/ReportModel';
import {type ReportModelV3Phone} from '../../../../../report/reportModelV3/reportModelV3';

export const phoneFields: Array<HeadersCheckReport<keyof ReportModelV3Phone>> = [
	{label: 'Номер телефона', key: 'phone_number', clipboard: true},
	{label: 'Код страны', key: 'country_code'},
	{label: 'Городской код', key: 'city_code'},
	{label: 'Номер', key: 'number'},
	{label: 'Дополнение', key: 'extension'},
	{label: 'Тип', key: 'type'},
	{label: 'Провайдер', key: 'provider'},
	{label: 'Страна', key: 'country'},
	{label: 'Регион', key: 'region'},
	{label: 'Город', key: 'city'},
	{label: 'Часовой пояс', key: 'timezone'},
];

