import React, {useState, useCallback, useEffect} from 'react';
import styles from './CreateCheckIndividualSecondForm.module.css';
import {InputRange} from '../../../UiKit/Inputs/Template/InputRange/InputRange';
import {Dropdown, InputDate} from '../../../UiKit/Inputs';
import {type InteractiveFieldValues, type InteractiveScreen} from '../../../../models/IInteractive';
import {ButtonTemplate} from '../../../UiKit/Button';

type CreateCheckIndividualSecondFormProps = {
	fields: React.MutableRefObject<InteractiveFieldValues | undefined>;
	currentScreen: React.MutableRefObject<InteractiveScreen | undefined>;
	showValidation: boolean;
	errors?: Record<string, string>;
};
// УБРАТЬ, когда будут прилетать реальные данные
const mockRegion = ['Московская область', 'Ивановская область'];

const CreateCheckIndividualSecondForm: React.FC<CreateCheckIndividualSecondFormProps> = ({
	fields,
	currentScreen,
	showValidation,
}) => {
	const [dateBirth, setDateBirth] = useState<string | undefined>(undefined);
	const [isValidDate, setIsValidDate] = useState(false);
	const [region, setRegion] = useState<string | undefined>(undefined);
	const [optionsCheckName, setOptionsCheckName] = useState<string[] | undefined>(undefined);
	const [showRangeSlider, setShowRangeSlider] = useState(false);
	const [rangeSliderValues, setRangeSliderValues] = useState({start: 18, end: 65});

	const handleButtonClick = useCallback(() => {
		setShowRangeSlider(!showRangeSlider);
		setDateBirth(undefined);
		setIsValidDate(false);
	}, [showRangeSlider]);

	const handleRangeChange = useCallback((start: number, end: number) => {
		setRangeSliderValues({start, end});
	}, [setRangeSliderValues]);

	useEffect(() => {
		if (currentScreen.current) {
			// ИЗМЕНИТЬ, на нужные ключи
			const fieldDateBirth = currentScreen.current.default_values.date_birth;
			const fieldStartAge = currentScreen.current.default_values.start_age;
			const fieldEndAge = currentScreen.current.default_values.end_age;
			const fieldRegion = currentScreen.current.default_values.region;

			if (typeof fieldDateBirth === 'string') {
				// ИЗМЕНИТЬ, проверить дату на нужный формат
				setDateBirth(fieldDateBirth);
			} else {
				setDateBirth(undefined);
			}

			if (typeof fieldStartAge === 'number' && typeof fieldEndAge === 'number') {
				setRangeSliderValues({start: fieldStartAge, end: fieldEndAge});
			} else {
				setRangeSliderValues({start: 18, end: 65});
			}

			if (typeof fieldRegion === 'string') {
				setRegion(fieldRegion);
			} else {
				setRegion(undefined);
			}
		}
	}, [currentScreen.current]);

	useEffect(() => {
		if (fields.current) {
			// ИЗМЕНИТЬ, на нужные ключи
			fields.current.date_birth = dateBirth;
			fields.current.start_age = rangeSliderValues.start;
			fields.current.end_age = rangeSliderValues.end;
			fields.current.region = region;
		}
	}, [dateBirth, rangeSliderValues.start, rangeSliderValues.end, region]);

	return (
		<div>
			<div className={styles['grid-container']}>
				{!showRangeSlider && (
					<>
						<InputDate
							value={dateBirth}
							setValueString={setDateBirth}
							title='Дата рождения'
							inputValidation={{
								isRequired: true,
								setIsValid: setIsValidDate,
								isValid: isValidDate,
								showValidation,
							}}
							disabled={showRangeSlider}
						/>
						<ButtonTemplate
							color='primary-transparent'
							text='Не знаю'
							onClick={handleButtonClick}
						/>
					</>
				)}
				{showRangeSlider && (
					<>
						<InputRange
							startValue={rangeSliderValues.start}
							endValue={rangeSliderValues.end}
							min={0}
							max={120}
							title='Диапозон возраста'
							isRequired={true}
							onValueChange={handleRangeChange}
						/>
						<ButtonTemplate
							color='primary-transparent'
							text='Скрыть'
							onClick={handleButtonClick}
						/>
					</>
				)}
				<Dropdown
					selectedValue={region}
					setSelectedValue={setRegion}
					title='Регион проживания'
					options={mockRegion}
					placeholder='Регион проживания'
					emptyMessage='Не найдено'
					showClear
				/>
			</div>
		</div>
	);
};

export default CreateCheckIndividualSecondForm;
