import React, {useState} from 'react';
import styles from '../../CheckReport/ReportNavigation/ReportNavigation.module.css';

const navItems = [
	{id: 'basic-info', name: 'Основная информация'},
	{id: 'documents', name: 'Документы'},
	{id: 'education', name: 'Образование'},
	{id: 'contacts', name: 'Контакты'},
	{id: 'data-confirmation', name: 'Потдверждение данных'},
];

export const PersonNavigation = () => {
	const [currentVisibleItems, setCurrentVisibleItems] = useState(navItems[0].id);

	const handleItemClick = (id: string) => {
		setCurrentVisibleItems(id);
	};

	return (
		<div className={styles.content}>
			<span>Список документов</span>

			<ul className={styles.nav}>
				{navItems.map(item => (
					<li
						key={item.id}
						className={styles['nav-item']}
						onClick={() => {
							handleItemClick(item.id);
						}} // Передаем id элемента
						id={`nav-${item.id}`}
					>
						<span className={`${styles.circle} ${currentVisibleItems === item.id ? styles['active-circle'] : ''}`} />

						<a href={`#${item.id}`} className={currentVisibleItems === item.id ? styles.active : ''}>
							{item.name}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};
