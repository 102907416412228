import React, {useEffect, useRef, useState} from 'react';
import styles from './ModalCheckCreated.module.css';
import ModalWindow, {type ModalWindowProps} from '../ModalWindow';
import {Details} from '../../UiKit/Container';
import {DateTime} from 'luxon';
import {ErrorMessage, Help} from '../../UiKit/Message';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import CommentsAndEvents from '../../CommentsAndEvents';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {
	getCheckFieldsAction,
	getCheckParametersAction,
	getCheckParametersByIdAction,
	postCheckCreateAction,
	postCheckStartAction,
	postCheckUpdateAction,
} from '../../../redux/action/checks';
import {
	type CheckParameter,
	type CheckField,
	type Check,
	type FilledCheckField,
	type SelectedCheckParameter,
	type SendCheckParameter,
	type SendCheckField,
	type CheckFieldData,
} from '../../../models/Schemas';
import {SplitLine} from '../../UiKit/Div';
import {CriterionField, InputAutocompleteField, InputDateField, InputField, InputNumberField} from './Field';
import {Input} from '../../UiKit/Inputs';
import {getCandidateAction} from '../../../redux/action/candidates';
import {getEmployeeAction} from '../../../redux/action/employees';
import {
	getCheckFieldsSliceResetState,
	getCheckParametersSliceResetState,
	postCheckCreateSliceResetState,
	postCheckStartSliceResetState,
	postCheckUpdateSliceResetState,
} from '../../../redux/slice/checks';
import {getCheckParametersByIdSliceResetState} from '../../../redux/slice/checks/getCheckParametersByIdSlice';
import ModalConfirmation from '../ModalConfirmation/ModalConfirmation';
import {getEmployeesResetState} from '../../../redux/slice/employees';
import {getCandidateResetState} from '../../../redux/slice/candidates/getCandidateSlice';
import {notifyMessage} from '../../UiKit/Toast/notifyMessage';

export type CheckPermissions = {
	isUpdateCheck?: boolean;
	isStartCheck?: boolean;
	isCreateCheckRequest?: boolean;
	isUpdateCheckRequest?: boolean;
	isZeroReportCreate?: boolean;
	isZeroReportUpdate?: boolean;
};
type ModalCheckCreatedProps = {
	employeeId?: number;
	candidateId?: number;
	personId?: number;
	check?: Check;
	permissions: React.MutableRefObject<CheckPermissions>;
} & ModalWindowProps;

export type Field = {
	field: CheckField;
	mask?: string;
	type?: string;
	isRequired?: boolean;
	isAllRequired: boolean;
	requiredCheckBoxId: number[];
};

type InputFieldsValue = Record<
number,
{sendCheckField: SendCheckField; isValid: boolean}
>;

export type CheckBox = {
	field: CheckParameter;
	isChecked: boolean;
};

type CheckBoxValue = Record<number, SendCheckParameter>;

type AutoNameInputs = {
	last_name?: string;
	first_name?: string;
	middle_name?: string;
	birth_date?: string;
};
type AutoCompleteInputs = Record<string, CheckFieldData>;
type AutoCompleteParameters = Record<string, boolean>;

const ModalCheckCreated: React.FC<ModalCheckCreatedProps> = ({
	employeeId,
	candidateId,
	personId,
	check,
	permissions,
	...propsModalWindow
}) => {
	const [showValidation, setShowValidation] = useState(false);
	const [isOpenConfirmationWindow, setIsOpenConfirmationWindow] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const show = false;

	// Поля ввода
	const [name, setName] = useState('');
	const [autoName, setAutoName] = useState('');
	const autoNameFields = useRef<AutoNameInputs>({});

	const personFields = useRef<AutoCompleteInputs>({});
	const initFilledFields = useRef<AutoCompleteInputs>({});
	const initSelectedParameters = useRef<AutoCompleteParameters>({});
	const [fields, setFields] = useState<Field[]>([]);
	const fieldsValue = useRef<InputFieldsValue>({});

	// Поля критериев
	const [checkBox, setCheckBox] = useState<CheckBox[]>([]);
	const checkBoxValue = useRef<CheckBoxValue>({});

	// Комментарий
	const comment = useRef('');

	const [isChangesInputs, setIsChangesInputs] = useState(false);

	// Состояния
	const getCheckParameters = useAppSelector(
		state => state.getCheckParameters,
	);
	const getCheckParametersById = useAppSelector(
		state => state.getCheckParametersById,
	);

	const getCheckFields = useAppSelector(state => state.getCheckFields);
	const getCandidate = useAppSelector(state => state.getCandidate);
	const getEmployee = useAppSelector(state => state.getEmployee);
	const postCheckCreate = useAppSelector(state => state.postCheckCreate);
	const author_id = useAppSelector(state => state.getUser.user?.id);
	const postCheckUpdate = useAppSelector(state => state.postCheckUpdate);
	const postCheckStart = useAppSelector(state => state.postCheckStart);

	const dispatch = useAppDispatch();

	// Получение списка полей
	const getFieldsList = (fields: CheckField[]) =>
		fields.map(item => ({
			field: item,
			mask: item.mask,
			type: item.type,
			isAllRequired: item.required,
			requiredCheckBoxId: [],
		}));

	// Получение начальных значений заполненных полей
	const getFilledFieldsList = (fields: FilledCheckField[]) => {
		fields.forEach(item => {
			initFilledFields.current[item.value] = item.data;
		});
	};

	// Получение списка параметров
	const getParametersList = (parameters: CheckParameter[]) =>
		parameters.map(item => ({
			field: item,
			isChecked: false,
		}));

	// Получение изначально выбранных параметров
	const getSelectedParametersList = (
		parameters: SelectedCheckParameter[],
	) => {
		parameters.forEach(item => {
			initSelectedParameters.current[item.value] = true;
		});
	};

	// Получение списков полей ввода
	const getInputsList = (
		fields: CheckField[],
		parameters?: CheckParameter[],
	) => {
		setFields(getFieldsList(fields));
		setCheckBox(parameters ? getParametersList(parameters) : []);
	};

	// Отмена создания
	const cancel = () => {
		propsModalWindow.setVisible(false);
	};

	// Отмена создания
	const cleanWindowsData = () => {
		setShowValidation(false);
		dispatch(getEmployeesResetState());
		dispatch(getCandidateResetState());
		dispatch(getCheckParametersByIdSliceResetState());
		dispatch(getCheckParametersSliceResetState());
		dispatch(getCheckFieldsSliceResetState());
		dispatch(postCheckCreateSliceResetState());
		dispatch(postCheckStartSliceResetState());
		dispatch(postCheckUpdateSliceResetState());
		personFields.current = {};
		initSelectedParameters.current = {};
		initSelectedParameters.current = {};
		autoNameFields.current = {};
		setAutoName('');
		setIsOpenConfirmationWindow(false);
		setLoadingMessage('');
		setName('');
		setFields([]);
		setCheckBox([]);
		fieldsValue.current = {};
		checkBoxValue.current = {};
	};

	// Получение отправляемых полей
	const getInputDataJson = () => {
		const current = Object.values(fieldsValue.current);

		return current.map(item => item.sendCheckField);
	};

	// Получение отправляемых параметров
	const getInputParametersJson = () => {
		const current = Object.values(checkBoxValue.current);

		return current.filter(item => item.data);
	};

	// Проверка что все поля валидны
	const checkInputsValid = () => {
		const current = Object.values(fieldsValue.current);

		return current.find(item => !item.isValid) === undefined;
	};

	// Проверка изменения поля
	const checkChangedInput = (sendCheckField: SendCheckField) => {
		if (getCheckParametersById.processedResponse) {
			const field = getCheckParametersById.processedResponse.filled_fields.find(item => item.id === sendCheckField.id);

			return field?.data !== sendCheckField.data;
		}

		return false;
	};

	// Создание проверки
	const createCheck = () => {
		if (author_id !== undefined && permissions.current.isCreateCheckRequest) {
			setLoadingMessage('Создания заявки');
			void dispatch(
				postCheckCreateAction({
					name,
					author_id,
					data: getInputDataJson(),
					employee_id: employeeId ?? null,
					candidate_id: candidateId ?? null,
					parameters: getInputParametersJson(),
					person_id: personId ?? null,
					check_type_id: 1,
				}),
			);
		}
	};

	// Запуск проверки
	const startCheck = () => {
		if (check && permissions.current.isStartCheck) {
			setLoadingMessage('Запуск проверки');
			if (isChangesInputs) {
				if (permissions.current.isUpdateCheck) {
					void dispatch(
						postCheckUpdateAction({
							check_id: check.id,
							body: {
								parameters: getInputParametersJson(),
								data: getInputDataJson(),
							},
						}),
					);
				}
			} else {
				void dispatch(
					postCheckStartAction({
						CheckId: check.id,
						body: {parameters: getInputParametersJson()},
					}),
				);
			}
		}
	};

	// Кнопка подтверждения
	const confirmation = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (name && checkInputsValid()) {
			dispatch(postCheckStartSliceResetState());
			dispatch(postCheckUpdateSliceResetState());
			if (check) {
				setIsOpenConfirmationWindow(true);
			} else {
				createCheck();
			}
		} else {
			notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
		}
	};

	const changeComment = (str: string) => {
		comment.current = str;
	};

	// Генерация названия проверки
	const updateAutoName = () => {
		let newAutoName = '';

		if (autoNameFields.current.last_name) {
			newAutoName = autoNameFields.current.last_name;
		}

		if (autoNameFields.current.first_name) {
			if (newAutoName) {
				newAutoName = newAutoName.concat(' ', autoNameFields.current.first_name[0], '.');
			} else {
				newAutoName = autoNameFields.current.first_name;
			}
		}

		if (autoNameFields.current.middle_name) {
			if (newAutoName) {
				newAutoName = newAutoName.concat(' ', autoNameFields.current.middle_name[0], '.');
			} else {
				newAutoName = autoNameFields.current.middle_name;
			}
		}

		if (autoNameFields.current.birth_date) {
			if (newAutoName) {
				newAutoName = newAutoName.concat(' ', autoNameFields.current.birth_date);
			} else {
				newAutoName = autoNameFields.current.birth_date;
			}
		}

		if (autoName === name || name === '') {
			setName(newAutoName);
			setAutoName(newAutoName);
		} else {
			setAutoName(newAutoName);
		}
	};

	// Обновление значения критерия
	const updateCheckBox = (sendCheckParameter: SendCheckParameter) => {
		const checkBoxIndex = checkBox.findIndex(
			item => item.field.id === sendCheckParameter.id,
		);
		if (checkBoxIndex !== -1) {
			checkBoxValue.current[sendCheckParameter.id] = sendCheckParameter;
			const {required_fields} = checkBox[checkBoxIndex].field;
			setFields(p => {
				if (sendCheckParameter.data) {
					// Если чекбокс = true
					required_fields.forEach(item => {
						const index = p.findIndex(
							findItem => findItem.field.id === item.id,
						);

						if (index === -1) {
							// Если поле не существует, добавляем новое поле в список
							p.push({
								requiredCheckBoxId: [sendCheckParameter.id],
								field: item,
								isRequired: true,
								isAllRequired: false,
							});
						} else {
							// Если поле уже существует, добавляем id в массив requiredCheckBoxId
							p[index].requiredCheckBoxId.push(
								sendCheckParameter.id,
							);
							p[index].isRequired = true;
						}
					});
				} else {
					// Если чекбокс = false
					required_fields.forEach(item => {
						const index = p.findIndex(
							findItem => findItem.field.id === item.id,
						);

						if (index !== -1) {
							// Если поле существует, убираем id из requiredCheckBoxId
							p[index].requiredCheckBoxId = p[
								index
							].requiredCheckBoxId.filter(
								checkBoxId =>
									checkBoxId !== sendCheckParameter.id,
							);
							if (p[index].requiredCheckBoxId.length === 0) {
								// Если нету requiredCheckBoxId, убираем поле из обязательных
								p[index].isRequired = false;
							}
						}
					});
				}

				return [...p];
			});
		}
	};

	// Обновление значения поля ввода
	const updateInput = (sendCheckField: SendCheckField, isValid: boolean) => {
		setIsChangesInputs(checkChangedInput(sendCheckField));
		const {value} = sendCheckField;
		if (value === 'last_name'
			|| value === 'first_name'
			|| value === 'middle_name'
			|| value === 'birth_date'
		) {
			autoNameFields.current[value] = sendCheckField.data?.toString() ?? '';
			updateAutoName();
		}

		fieldsValue.current[sendCheckField.id] = {sendCheckField, isValid};
	};

	// Создание полей ввода
	const createInputs = () =>
		fields.map((item, index) => {
			const initValue
                = (check ? initFilledFields.current[item.field.value] : personFields.current[item.field.value]) ?? '';
			if (item.type === 'range' || item.type === 'number') {
				return (
					<InputNumberField
						key={index}
						initValue={initValue}
						updateValue={updateInput}
						showValidation={showValidation}
						{...item}
					/>
				);
			}

			if (item.type === 'date') {
				return (
					<InputDateField
						key={index}
						initValue={initValue}
						updateValue={updateInput}
						showValidation={showValidation}
						{...item}
					/>
				);
			}

			if (item.type === 'text_middle_name'
				|| item.type === 'text_first_name'
				|| item.type === 'text_last_name') {
				return (
					<InputAutocompleteField
						key={index}
						initValue={initValue}
						updateValue={updateInput}
						showValidation={showValidation}
						{...item}
					/>
				);
			}

			return (
				<InputField
					key={index}
					initValue={initValue}
					updateValue={updateInput}
					showValidation={showValidation}
					{...item}
				/>
			);
		});

	// Создание чекбоксов для параметров
	const createCheckboxes = () =>
		checkBox.map((item, index) => {
			const initValue = check
				? initSelectedParameters.current[item.field.value] ?? false
				: false;
			return (
				item.field.is_visible && (
					<CriterionField
						key={index}
						initValue={initValue}
						updateValue={updateCheckBox}
						{...item}
					/>
				)
			);
		});

	// Открытие и закрытие окна
	useEffect(() => {
		cleanWindowsData();
		if (propsModalWindow.visible) {
			setLoadingMessage('Получение данных формы');

			if (check) {
				if (
					Boolean(permissions.current.isStartCheck)
					|| Boolean(permissions.current.isUpdateCheck)
					|| permissions.current.isZeroReportUpdate) {
					setName(check.name);
					void dispatch(
						getCheckParametersByIdAction({CheckId: check.id}),
					);
					void dispatch(getCheckParametersAction());
				}
			} else {
				if (permissions.current.isStartCheck) {
					void dispatch(getCheckParametersAction());
				} else if (permissions.current.isCreateCheckRequest) {
					void dispatch(getCheckFieldsAction());
				}

				if (candidateId) {
					void dispatch(getCandidateAction({candidateId}));
				} else if (employeeId) {
					void dispatch(getEmployeeAction({employeeId}));
				}
			}
		} else {
			permissions.current = {};
		}
	}, [propsModalWindow.visible]);

	// Заполнение формы после получения всех данных
	useEffect(() => {
		if (check) {
			if (
				getCheckParameters.responseStatus.isCompleted
                && getCheckParametersById.responseStatus.isCompleted
                && getCheckParameters.processedResponse
                && getCheckParametersById.processedResponse
			) {
				getFilledFieldsList(
					getCheckParametersById.processedResponse.filled_fields,
				);
				getSelectedParametersList(
					getCheckParametersById.processedResponse.selected_parameters,
				);
				getInputsList(
					getCheckParameters.processedResponse.fields,
					getCheckParameters.processedResponse.parameters,
				);
			}
		} else if (
			getCandidate.responseStatus.isCompleted
            || getEmployee.responseStatus.isCompleted
			|| (candidateId === undefined && employeeId === undefined)
		) {
			if (
				getCheckParameters.responseStatus.isCompleted
                && getCheckParameters.processedResponse
			) {
				getInputsList(
					getCheckParameters.processedResponse.fields,
					getCheckParameters.processedResponse.parameters,
				);
			} else if (
				getCheckFields.responseStatus.isCompleted
                && getCheckFields.processedResponse
			) {
				getInputsList(getCheckFields.processedResponse);
			}
		}
	}, [
		getCheckParameters.responseStatus.isLoading,
		getCheckParametersById.responseStatus.isLoading,
		getCheckFields.responseStatus.isLoading,
		getCandidate.responseStatus.isLoading,
		getEmployee.responseStatus.isLoading,
	]);

	// Получение кандидата
	useEffect(() => {
		if (getCandidate.responseStatus.isCompleted && getCandidate.response) {
			const {response} = getCandidate;
			personFields.current.first_name = response.first_name;
			personFields.current.last_name = response.last_name;
			personFields.current.middle_name = response.middle_name;
		}
	}, [getCandidate.responseStatus.isLoading]);

	// Получение сотрудника
	useEffect(() => {
		if (getEmployee.responseStatus.isCompleted && getEmployee.response) {
			const {response} = getEmployee;
			personFields.current.first_name = response.first_name;
			personFields.current.last_name = response.last_name;
			personFields.current.middle_name = response.middle_name;
			personFields.current.birth_date = response.birth_date;
		}
	}, [getEmployee.responseStatus.isLoading]);

	// Создания заявки на проверку
	useEffect(() => {
		if (postCheckCreate.responseStatus.isCompleted || postCheckUpdate.responseStatus.isCompleted || postCheckStart.responseStatus.isCompleted) {
			if (propsModalWindow.visible) {
				const message = check ? 'Проверка успешно запущена' : 'Заявка на проверку успешно создана';
				notifyMessage('success', message);
			}

			propsModalWindow.setVisible(false);
		}

		if (postCheckCreate.responseStatus.errors ?? postCheckUpdate.responseStatus.errors ?? postCheckStart.responseStatus.errors) {
			notifyMessage('error', 'Ошибка. Проверьте корректность вводимых данных');
		}
	}, [postCheckCreate.responseStatus.isLoading, postCheckUpdate.responseStatus.isLoading, postCheckStart.responseStatus.isLoading]);

	// Изменение названия проверки
	useEffect(() => {
		if (name === '') {
			updateAutoName();
		}
	}, [name]);

	return (
		<>
			<ModalWindow
				width='70%'
				height='auto'
				closable
				isLoading={
					getCheckParameters.responseStatus.isLoading
					|| getCheckFields.responseStatus.isLoading
                    || getCandidate.responseStatus.isLoading
                    || getEmployee.responseStatus.isLoading
                    || postCheckCreate.responseStatus.isLoading
                    || getCheckParametersById.responseStatus.isLoading
					|| postCheckUpdate.responseStatus.isLoading
					|| postCheckStart.responseStatus.isLoading
				}
				loadingMessage={loadingMessage}
				header={(
					<div>
						<h1 className={styles.title}>Запрос на проверку</h1>
						<span className={styles.date}>
							{DateTime.now().toLocaleString()}
						</span>
					</div>
				)}
				footerItems={[
					<ButtonCancel key='ButtonCancel' text='Отмена' onclickHandler={cancel} />,
					<ButtonConfirm key='ButtonConfirm' onclickHandler={confirmation} text={
						check
							? isChangesInputs ? 'Обновить и запустить' : 'Подтвердить и начать проверку'
							: 'Создать заявку'
					} />,
				]}
				{...propsModalWindow}
			>
				<div className={styles.container}>
					<div className={styles.inputs}>
						<Input
							value={name}
							setValue={setName}
							title='Название проверки'
							inputStyles={{
								placeholder: 'Название',
							}}
							disabled={Boolean(check)}
						/>
						<SplitLine /><SplitLine />
						<span>Личные данные</span>
						<div></div>
						{createInputs()}
					</div>
					{checkBox.length > 0 && (
						<>
							<SplitLine />
							<Details isOpened summaryText={'Критерии поиска'}>
								<div className={styles.checkboxes}>
									{createCheckboxes()}
								</div>
							</Details>
							<Help
								title='Как использовать'
								message='При выборе критериев поиска будут подсвечены обязательные поля ввода данных, при вводе данных ниже отображаются доступные критерии  поиска. Критерии можно выбрать в зависимости от данных на входе'
							/>
						</>
					)}
					<ErrorMessage
						message={getCheckParameters.responseStatus.errorMessage}
						isError={getCheckParameters.responseStatus.isError}
					/>
					<ErrorMessage
						message={getCheckParametersById.responseStatus.errorMessage}
						isError={getCheckParametersById.responseStatus.isError}
					/>
					<ErrorMessage
						message={getCheckFields.responseStatus.errorMessage}
						isError={getCheckFields.responseStatus.isError}
					/>
					<ErrorMessage
						message={getEmployee.responseStatus.errorMessage}
						isError={getEmployee.responseStatus.isError}
					/>
					<ErrorMessage
						message={getCandidate.responseStatus.errorMessage}
						isError={getCandidate.responseStatus.isError}
					/>
					<ErrorMessage
						message={postCheckCreate.responseStatus.errorMessage}
						isError={postCheckCreate.responseStatus.isError}
					/>
					{show && <>
						<SplitLine />
						<CommentsAndEvents
							comment={comment.current}
							setComment={changeComment}
						/>
					</>}
				</div>
			</ModalWindow>
			<ModalConfirmation
				visible={isOpenConfirmationWindow}
				setVisible={setIsOpenConfirmationWindow}
				confirmation={startCheck}
				text='Вы уверены, что хотите начать проверку?'
				confirmationText={'Запустить проверку'}
				cancelText={'Отмена запуска проверки'}
			>
				<ErrorMessage
					message={postCheckStart.responseStatus.errorMessage}
					isError={postCheckStart.responseStatus.isError}
				/>
				<ErrorMessage
					message={postCheckUpdate.responseStatus.errorMessage}
					isError={postCheckUpdate.responseStatus.isError}
				/>
			</ModalConfirmation>
		</>
	);
};

export default ModalCheckCreated;
