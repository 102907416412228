import {getCheckParametersById} from '../../../api/checkApi';
import {type GetCheckParametersByIdRequest, type GetCheckParametersByIdResponse} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const getCheckParametersByIdAction = createActionTemplateAxios<
GetCheckParametersByIdResponse,
GetCheckParametersByIdRequest>(
	'checks/getCheckParametersById/action',
	getCheckParametersById,
	'userToken',
);

export default getCheckParametersByIdAction;
