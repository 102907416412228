import {Cookies} from 'react-cookie';
import cookiesKeys from '../cookiesKeys';

export const removeToken = () => {
	const cookies = new Cookies();

	cookies.remove(cookiesKeys.userToken);
	cookies.remove(cookiesKeys.userRefreshToken);
	cookies.remove(cookiesKeys.userTokenExpires);
	cookies.remove(cookiesKeys.userRefreshTokenExpires);
};
