
import {postCheckStart} from '../../../api/checkApi';
import {type postCheckStartRequest, type postCheckStartResponse} from '../../../models/ICheck';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const postCheckStartAction = createActionTemplateAxios<
postCheckStartResponse,
postCheckStartRequest>(
	'checks/postCheckStart/action',
	postCheckStart,
	'userToken',
);

export default postCheckStartAction;
