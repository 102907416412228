import React, {type RefObject, useCallback} from 'react';
import {
	type ChartSeriesList,
	ChartTemplate,
	type ChartCommonSeriesSettingsProps,
	type ChartTooltip,
} from '../../UiKit/Charts';
import {Margin} from 'devextreme-react/cjs/chart';

const commonSeriesSettings: ChartCommonSeriesSettingsProps = {
	valueField: 'count',
	argumentField: 'month',
};

const tooltip: ChartTooltip = {
	enabled: true,
	argumentLabel: 'Месяц',
	valueLabel: 'Кандидаты',
};

const series: ChartSeriesList[] = [
	{
		seriesProps: {
			color: 'var(--chart-bar-default)',
			type: 'bar',
			showInLegend: false,
			cornerRadius: 4,
		},
	},
];

type ChartChecksInDayProps = {
	dataSource: any[] | undefined;
	MaxValue: number;
	containerRef?: RefObject<HTMLDivElement>;
};

const ChartCandidateInMonth: React.FC<ChartChecksInDayProps> = ({dataSource, MaxValue, containerRef}) => {
	const customizePoint = useCallback((arg: {value: number}) => {
		if (arg.value === MaxValue) {
			return {
				color: 'var(--chart-bar-mint)',
				hoverStyle: {color: 'var(--chart-bar-mint)'},
			};
		}

		return {};
	}, [MaxValue]);

	return (
		<ChartTemplate
			dataSource={dataSource ?? []}
			commonSeriesSettings={commonSeriesSettings}
			tooltip={tooltip}
			series={series}
			customizePoint={customizePoint}
			containerRef={containerRef}
			height={230}
			valueAxis={{allowDecimals: false}}
		/>
	);
};

export default ChartCandidateInMonth;
