import axios, {type AxiosResponse} from 'axios';
import {getToken} from '../utils/cookies/token/getToken';
import {
	type GetNameFillRequest,
	type GetNameFillResponse,
} from '../models/Dadata/IDadata';
import {type GetAddressFillRequest, type GetAddressFillResponse} from '../models/Dadata/Address/IDadataAddress';
import {getGuestToken} from '../utils/cookies/guestToken/getGuestToken';

const domain = process.env.REACT_APP_HOST_API;
const urlPrefix = '/api/standardization/dadata';

// Получение части из ФИО
export async function getNameFill(
	data: GetNameFillRequest,
): Promise<AxiosResponse<GetNameFillResponse>> {
	let {token} = getToken();
	if (!token) {
		token = getGuestToken().token;
	}

	return axios.get(`${domain}${urlPrefix}/name_fill`, {
		params: data,
		headers: {Authorization: `Bearer ${token}`},
	});
}

// Получение адреса
export async function getAddressFill(data: GetAddressFillRequest): Promise<AxiosResponse<GetAddressFillResponse>> {
	let {token} = getToken();
	if (!token) {
		token = getGuestToken().token;
	}

	return axios.get(`${domain}${urlPrefix}/address_fill`, {
		params: data,
		headers: {Authorization: `Bearer ${token}`},
	});
}
