import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {type GetNameFillRequest, type GetNameFillResponse} from '../../../models/Dadata/IDadata';
import {getNameFill} from '../../../api/dadata';

const getNameFillAction = createActionTemplateAxios<
GetNameFillResponse,
GetNameFillRequest
>('dadata/getNameFill/action', getNameFill, 'userOrGuestToken');

export default getNameFillAction;
