import React from 'react';
import ButtonTemplate from '../../ButtonTemplate/ButtonTemplate';
import {MdOutlineSearch} from 'react-icons/md';

type DefaultButtonProps = {
	onClick: () => void;
};

const SubCheckButton: React.FC<DefaultButtonProps> = ({
	onClick,
}) => (
	<ButtonTemplate
		onClick={onClick}
		color={'primary-transparent'}
		iconStyleType='medium'
		styleType='icon'
		Icon={MdOutlineSearch}
		TooltipMessage='Запустить подпроверку'
	/>
);

export default SubCheckButton;
