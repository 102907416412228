import {createActionTemplateAxios} from '../ActionTemplatesRequest';
import {getOfficialPermissions} from '../../../api/permissionsApi';
import {
	type GetOfficialPermissionsResponse,
	type GetOfficialPermissionsRequest,
} from '../../../models/IPermissions';

const getOfficialPermissionsAction = createActionTemplateAxios<
GetOfficialPermissionsResponse,
GetOfficialPermissionsRequest
>('permissions/getOfficialPermissions/action', getOfficialPermissions, 'userToken');

export default getOfficialPermissionsAction;
