import React, {useEffect, useRef, useState} from 'react';
import AddingCandidate from './AddingCandidate';
import AdditionalContact from '../ModalEditContacts/AdditionalContacts';
import {IoAdd} from 'react-icons/io5';
import styles from './ModalAddingForCandidate.module.css';
import {useAppDispatch, useAppSelector} from '../../../redux/redux-hooks';
import {postCandidateAction} from '../../../redux/action/candidates';
import {postContactAction} from '../../../redux/action/contacts';
import {postChallengersAction} from '../../../redux/action/challengers';
import ModalWindow from '../ModalWindow';
import {ErrorMessage} from '../../UiKit/Message';
import {ButtonCancel, ButtonConfirm} from '../../UiKit/Button';
import {type AdditionalContactProps, type ItemContact} from '../ModalEditContacts/AdditionalContacts/AdditionalContacts';
import {postCandidateResetState} from '../../../redux/slice/candidates';
import {postContactSliceResetState} from '../../../redux/slice/contacts';
import {postChallengersResetState} from '../../../redux/slice/challengers';
import {type GetEmployeesAutofillItem} from '../../../models/IEmployees';

type ModalAddingForCandidateProps = {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	positionId: number | undefined;
};

export const ModalAddingForCandidate: React.FC<
ModalAddingForCandidateProps
> = ({visible, setVisible, positionId}) => {
	// Список контактов
	const [showValidation, setShowValidation] = useState(false);
	const [contactComponent, setContactComponent] = useState<AdditionalContactProps[]>([]);
	const [contacts, setContacts] = useState<ItemContact[]>([]);
	const [KeyContactCard, setKeyContactCard] = useState<number>(0);
	const [currentContacts, setCurrentContacts] = useState<number | undefined>(undefined);

	// Поля формы
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [middleName, setMiddleName] = useState<string>();
	const fromWhomCandidate = useRef<GetEmployeesAutofillItem>();
	const [email, setEmail] = useState('');

	const [isValid, setIsValid] = useState(true);
	const isAddedCandidate = useRef(false);
	const isAddedChallenger = useRef(false);
	const lastAddedContact = useRef<number | undefined>(undefined);
	const [isRequestsLoading, setIsRequestsLoading] = useState(false);
	const [currentRequestLoading, setCurrentRequestLoading] = useState('');

	// Redux
	const dispatch = useAppDispatch();
	const employeeId = useAppSelector(state => state.getUser.user?.employee_id);
	const organizationId = useAppSelector(state => state.organization.organization?.id);
	const candidate = useAppSelector(state => state.postCandidate);
	const postContact = useAppSelector(state => state.postContact);
	const authorId = useAppSelector(state => state.getUser.user?.employee_id);
	const postChallengers = useAppSelector(state => state.postChallengers);

	// Изменение контакта
	const handleChangeContacts = (contact: ItemContact) => {
		setContacts(prevContacts => {
			const contactIndex = prevContacts.findIndex(c => c.item.key === contact.item.key);
			if (contactIndex !== -1) {
				const newContacts = [...prevContacts];
				newContacts[contactIndex] = contact;
				return newContacts;
			}

			return [...prevContacts, contact];
		});
	};

	// Проверка валидности измененных контактов
	const checkContacts = () => contacts.find(item => !item.isValid) === undefined;

	// Остановка загрузки
	const stopLoading = () => {
		setIsRequestsLoading(false);
		setCurrentRequestLoading('');
	};

	// Отобразить загрузку
	const startLoading = (loadingMessage: string) => {
		setIsRequestsLoading(true);
		setCurrentRequestLoading(loadingMessage);
	};

	// Очистка данных модального окна
	const clearModalData = () => {
		// Redux
		dispatch(postCandidateResetState());
		dispatch(postChallengersResetState());
		dispatch(postContactSliceResetState());

		// Список контактов
		setKeyContactCard(0);
		setCurrentContacts(undefined);
		setContacts([]);
		setContactComponent([]);

		// Поля формы
		setName('');
		setLastName('');
		setMiddleName('');
		fromWhomCandidate.current = undefined;
		setIsValid(false);
		setShowValidation(false);
		setEmail('');

		// Загрузка
		stopLoading();
		isAddedCandidate.current = false;
		isAddedChallenger.current = false;
		lastAddedContact.current = undefined;
	};

	// Закрытие модального окна
	const closeModal = () => {
		setVisible(false);
	};

	// Добавление кандидата
	const handleSubmit = () => {
		if (!showValidation) {
			setShowValidation(true);
		}

		if (employeeId && organizationId && isValid && checkContacts()) {
			if (!isAddedCandidate.current) {
				startLoading('Добавление кандидата');
				void dispatch(
					postCandidateAction({
						body: {
							first_name: name,
							last_name: lastName,
							middle_name: middleName ?? null,
							email,
							author_id: employeeId,
							recommender_id: fromWhomCandidate.current?.id ?? null,
							organization_id: organizationId,
						},
					}),
				);
			} else if (!isAddedChallenger.current) {
				dispatchChallenger();
			} else if (lastAddedContact.current !== undefined) {
				dispatchContact(lastAddedContact.current);
			}
		}
	};

	// Добавление контакта
	const dispatchContact = (indexContact: number) => {
		lastAddedContact.current = indexContact;
		const contact = contacts[indexContact];
		setCurrentContacts(indexContact);

		if (candidate.response) {
			startLoading('Добавление контактов');
			void dispatch(
				postContactAction({
					body: {
						contact_type: contact.item.contact_type,
						contact: contact.item.contact,
						description: contact.item.description,
						candidate_id: candidate.response.id,
					},
				}),
			);
		}
	};

	// Добавление кандидата в вакансию
	const dispatchChallenger = () => {
		if (
			candidate.response
			&& typeof (authorId) === 'number'
			&& typeof (positionId) === 'number'
		) {
			startLoading('Добавление кандидата в вакансию');
			void dispatch(
				postChallengersAction({
					body: {
						candidate_id: candidate.response.id,
						position_id: positionId,
						author_id: authorId,
					},
				}),
			);
		}
	};

	// Удаление контакта
	const removeContact = (indexToRemove: number) => {
		setContactComponent(p => p.filter(item => item.key !== indexToRemove));
		setContacts(p =>
			p.filter(item => item?.item.key !== indexToRemove),
		);
	};

	// Добавление контакта
	const addContact = () => {
		setContactComponent(prevContacts => [
			...prevContacts,
			{
				key: KeyContactCard,
				index: KeyContactCard,
				removeContact,
				handleChangeContacts,
				isDelete: true,
				isUpdate: true,
				showValidation,
			},
		]);
		setKeyContactCard(prevState => prevState + 1);
	};

	// Действия при добавлении кандидата
	useEffect(() => {
		if (visible && candidate.responseStatus.isCompleted) {
			isAddedCandidate.current = true;
			dispatchChallenger();
		} else if (candidate.responseStatus.isError) {
			stopLoading();
		}
	}, [candidate.responseStatus.isLoading]);

	// Действия при добавлении кандидата в вакансию
	useEffect(() => {
		if (visible && postChallengers.responseStatus.isCompleted) {
			isAddedChallenger.current = true;
			if (contacts.length === 0) {
				closeModal();
			} else {
				dispatchContact(0);
			}
		} else if (postChallengers.responseStatus.isError) {
			stopLoading();
		}
	}, [postChallengers.responseStatus.isLoading]);

	// Действия при добавлении контакта
	useEffect(() => {
		if (
			postContact.responseStatus.isCompleted && currentContacts !== undefined
		) {
			if (currentContacts < contacts.length - 1) {
				dispatchContact(currentContacts + 1);
			} else {
				closeModal();
			}
		} else if (postContact.responseStatus.isError) {
			stopLoading();
		}
	}, [postContact.responseStatus.isLoading]);

	// Действия при открытии закрытии окна
	useEffect(() => {
		clearModalData();
	}, [visible]);

	useEffect(() => {
		setContactComponent(p => p.map(item => {
			item.showValidation = showValidation;

			return item;
		}));
	}, [showValidation]);

	return (
		<ModalWindow
			width='min(90%, 600px)'
			closable
			visible={visible}
			setVisible={setVisible}
			isLoading={isRequestsLoading}
			loadingMessage={currentRequestLoading}
			header={<span className='text-title-1'>Добавить кандидата</span>}
			closeOnOutsideClick
			footerItems={[
				<ButtonCancel
					key='ButtonCancel'
					onclickHandler={closeModal}
					text='Назад'
				/>,
				<ButtonConfirm
					key='ButtonConfirm'
					onclickHandler={handleSubmit}
					text='Отправить'
				/>,
			]}
		>
			<div className={styles.content}>
				<AddingCandidate
					name={name}
					setName={setName}
					lastName={lastName}
					setLastName={setLastName}
					middleName={middleName}
					setMiddleName={setMiddleName}
					fromWhomCandidate={fromWhomCandidate}
					setIsValid={setIsValid}
					showValidation={showValidation}
					visible={visible}
					email={email}
					setEmail={setEmail}
				/>
				{contactComponent.map((contactProps, index) => (
					<div key={contactProps.key}>
						<span className='text-title-3'>Контакт №{index + 1}</span>
						<AdditionalContact {...contactProps}/>
					</div>
				))}
				<div className={styles['add-contact']} >
					<div className={styles['add-button']} onClick={addContact}>
						<IoAdd />
						<span className='medium-text'>Добавить дополнительный контакт</span>
					</div>
				</div>
				<ErrorMessage
					message={candidate.responseStatus.errorMessage}
					isError={candidate.responseStatus.isError}
				/>
				<ErrorMessage
					message={postChallengers.responseStatus.errorMessage}
					isError={postChallengers.responseStatus.isError}
				/>
				<ErrorMessage
					message={postContact.responseStatus.errorMessage}
					isError={postContact.responseStatus.isError}
				/>
			</div>
		</ModalWindow>
	);
};
