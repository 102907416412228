import {type ConfirmationPhoneNumber} from '../../../models/IUsers';
import {sendSmsCodeRegistration} from '../../../api/usersApi';
import {type Token} from '../../../models/IAuth';
import {createActionTemplateAxios} from '../ActionTemplatesRequest';

const sendSmsPhoneNumberAction = createActionTemplateAxios<
Token,
ConfirmationPhoneNumber>(
	'auth/sendSmsPhoneNumber/action',
	sendSmsCodeRegistration,
	'userToken',
);

export default sendSmsPhoneNumberAction;
