import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PostDepartmentPositionsOpenResponse} from '../../../models/IDepartmentPositions';
import {postDepartmentPositionsOpenAction} from '../../action/department_positions';

type PostDepartmentPositionsOpenState = {
	responseStatus: ResponseStatus;
	response: PostDepartmentPositionsOpenResponse | undefined;
};

const initialState: PostDepartmentPositionsOpenState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const postDepartmentPositionsOpenSlice = createSlice({
	name: 'departmentPosition/postDepartmentPositionsOpen',
	initialState,
	reducers: {
		postDepartmentPositionsOpenResetStatus(state) {
			state.responseStatus = initialState.responseStatus;
			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(postDepartmentPositionsOpenAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(postDepartmentPositionsOpenAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(postDepartmentPositionsOpenAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {postDepartmentPositionsOpenResetStatus} = postDepartmentPositionsOpenSlice.actions;
export default postDepartmentPositionsOpenSlice.reducer;
