import React, {useEffect, useRef, useState} from 'react';
import styles from './PersonalDataAgreementSignDocuments.module.css';
import {InputPhone} from '../../../UiKit/Inputs';
import OtpInput from '../../../UiKit/Inputs/Template/OtpInput/OtpInput';
import {DefaultButton} from '../../../UiKit/Button';
import {CheckBoxDevEx} from '../../../UiKit/Checkbox';
import {useAppDispatch, useAppSelector} from '../../../../redux/redux-hooks';
import {useEffectHandlerLoadingCurrentRequest} from '../../../../utils/hooks';
import {postGuestOtpSendAction, putGuestSignDocsAction} from '../../../../redux/action/guest';
import Loader from '../../../Loader/Loader';
import {notifyMessage} from '../../../UiKit/Toast/notifyMessage';
import {useCookies} from 'react-cookie';

type PersonalDataAgreementSignDocumentsProps = {
	isValid: boolean;
	onSendOtp?: () => void;
	onVerifyOtp?: () => void;
	onVerifyOtpComplete?: () => void;
	isInputOtp: boolean;
	setIsInputOtp: (value: boolean) => void;
	putGuestRequest: () => string | undefined;
};

const PersonalDataAgreementSignDocuments: React.FC<PersonalDataAgreementSignDocumentsProps> = ({
	isValid,
	onSendOtp,
	onVerifyOtp,
	onVerifyOtpComplete,
	isInputOtp,
	setIsInputOtp,
	putGuestRequest,
}) => {
	const [cookies] = useCookies(['debugGuest']);
	const dispatch = useAppDispatch();
	const putGuest = useAppSelector(state => state.putGuest);
	const postGuestOtpSend = useAppSelector(state => state.postGuestOtpSend);
	const putGuestSignDocs = useAppSelector(state => state.putGuestSignDocs);
	const putGuestRequestId = useRef<string>();
	const postGuestOtpSendRequestId = useRef<string>();
	const putGuestSignDocsRequestId = useRef<string>();
	const [phone, setPhone] = useState('');
	const [isValidPhone, setIsValidPhone] = useState(false);
	const [isAgreement, setIsAgreement] = useState(false);

	const [smsNumber, setSmsNumber] = useState<string>();
	const [phoneLastCharts, setPhoneLastCharts] = useState<string>('');
	const [otp, setOtp] = useState<string>();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState<string>();

	const sendOtp = () => {
		putGuestRequestId.current = putGuestRequest();
	};

	const verifyOtp = () => {
		if (otp) {
			putGuestSignDocsRequestId.current = dispatch(putGuestSignDocsAction({
				body: {
					phone,
					code: otp,
					debug: Boolean(cookies.debugGuest),
				},
			})).requestId;
		}
	};

	const resetComponentStates = () => {
		putGuestRequestId.current = undefined;
		postGuestOtpSendRequestId.current = undefined;
		putGuestSignDocsRequestId.current = undefined;
		setPhone('');
		setIsValidPhone(false);
		setIsAgreement(false);
		setIsInputOtp(false);

		setSmsNumber(undefined);
		setPhoneLastCharts('');
		setOtp(undefined);
		hiddenLoading();
	};

	// Отобразить загрузку
	const showLoading = (message?: string) => {
		setIsLoading(true);
		setLoadingMessage(message);
	};

	// Скрыть загрузку
	const hiddenLoading = () => {
		setIsLoading(false);
		setLoadingMessage(undefined);
	};

	useEffect(() => {
		resetComponentStates();
	}, []);

	// Обновление гостя
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: putGuestRequestId,
		state: putGuest,
		onComplete() {
			postGuestOtpSendRequestId.current = dispatch(postGuestOtpSendAction({
				body: {
					phone,
					debug: Boolean(cookies.debugGuest),
				},
			})).requestId;
		},
	});

	// Подписание документов
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: putGuestSignDocsRequestId,
		state: putGuestSignDocs,
		onLoading() {
			showLoading('Подписание согласия на обработку персональных данных...');
		},
		onStopLoading() {
			hiddenLoading();
		},
		onComplete(response) {
			if (onVerifyOtpComplete) {
				onVerifyOtpComplete();
			}

			notifyMessage('success', 'Согласие на обработку персональных данных получено');
		},
		onError(errorMessage) {
			if (onVerifyOtpComplete) {
				onVerifyOtpComplete();
			}

			notifyMessage('error', errorMessage);
		},
	});

	// Отправка otp кода
	useEffectHandlerLoadingCurrentRequest({
		requestIdRef: postGuestOtpSendRequestId,
		state: postGuestOtpSend,
		onLoading() {
			showLoading('Отправка смс с кодом...');
		},
		onStopLoading() {
			hiddenLoading();
		},
		onComplete(response) {
			if (response) {
				const {phone_last_charts, code_number} = response;
				setIsInputOtp(true);
				setPhoneLastCharts(phone_last_charts);
				setSmsNumber(code_number);

				if (onSendOtp) {
					onSendOtp();
				}

				notifyMessage('success', 'СМС отправлено');
			}
		},
		onError(errorMessage) {
			notifyMessage('error', errorMessage);
		},
	});

	return (
		<div className={styles.container}>
			{isInputOtp ? (
				<div className={styles['otp-container']}>
					<div className={styles['otp-description']}>
						<p>
							Введите код из СМС №{smsNumber}
						</p>
						<p>
							На номер телефона +7(9**) ***-{phoneLastCharts.substring(0, 2)}-{phoneLastCharts.substring(2)} отправлено смс с кодом
						</p>
					</div>
					<OtpInput
						length={6}
						onEditOtp={setOtp}
					/>
				</div>
			) : (
				<div className={styles.phone}>
					<p className={styles['title-group']}>
              На указанный номер телефона придёт код, для подписания документов
					</p>
					<InputPhone
						value={phone}
						setValue={setPhone}
						isRequired
						isValid={isValidPhone}
						setIsValid={setIsValidPhone}
						showValidation={false}
						title='Номер телефона'
						inputStyles={{
							placeholder: 'Введите номер телефона',
						}}
					/>
				</div>
			)}
			{!isInputOtp && (
				<CheckBoxDevEx
					isChecked={isAgreement}
					setIsChecked={setIsAgreement}
					text='Даю согласие на обработку персональных данных'
				/>
			)}
			<div className={styles['sign-pd-button']}>
				{isInputOtp ? (
					<DefaultButton
						onClick={verifyOtp}
						text='Подписать простой электронной подписью'
						disable={!(isValid && isValidPhone)}
					/>
				) : (
					<DefaultButton
						onClick={sendOtp}
						text='Получить код для подписания'
						disable={!(isValid && isValidPhone && isAgreement)}
					/>
				)}
			</div>
			<Loader isFloating isLoading={isLoading} text={loadingMessage} />
		</div>
	);
};

export default PersonalDataAgreementSignDocuments;
