import {createSlice} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type PutDepartmentPositionsResponse} from '../../../models/IDepartment';
import {putDepartmentPositionsAction} from '../../action/department';

type PutDepartmentPositionsState = {
	responseStatus: ResponseStatus;
	response: PutDepartmentPositionsResponse | undefined;
};

const initialState: PutDepartmentPositionsState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const putDepartmentPositionsSlice = createSlice({
	name: 'department/putDepartmentPositions',
	initialState,
	reducers: {putDepartmentPositionsResetState: () => initialState},
	extraReducers(builder) {
		builder
			.addCase(putDepartmentPositionsAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(putDepartmentPositionsAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(putDepartmentPositionsAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {putDepartmentPositionsResetState} = putDepartmentPositionsSlice.actions;

export default putDepartmentPositionsSlice.reducer;
