import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type ResponseStatus} from '../../../models/IAuth';
import {type User} from '../../../models/Schemas';
import {getUserByIdAction} from '../../action/users';

type GetUserByIdState = {
	responseStatus: ResponseStatus;
	response: User | undefined;
};

const initialState: GetUserByIdState = {
	responseStatus: {
		isCompleted: false,
		isLoading: false,
		isError: false,
		errorMessage: '',
		status: undefined,
	},
	response: undefined,
};

const createGetUserByIdSlice = createSlice({
	name: 'user/getUserById',
	initialState,
	reducers: {
		resetGetUserByIdState: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getUserByIdAction.pending, state => {
				state.responseStatus.isLoading = true;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = false;
			})
			.addCase(getUserByIdAction.fulfilled, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = true;
				state.responseStatus.isError = false;
				state.response = action.payload;
			})
			.addCase(getUserByIdAction.rejected, (state, action) => {
				state.responseStatus.isLoading = false;
				state.responseStatus.isCompleted = false;
				state.responseStatus.isError = true;
				state.responseStatus.errorMessage
          = action.payload?.message ?? 'Неожиданная ошибка';
				state.responseStatus.status = action.payload?.status;
			});
	},
});

export const {resetGetUserByIdState} = createGetUserByIdSlice.actions;
export default createGetUserByIdSlice.reducer;
